import React from 'react';
import classes from 'classnames';

import SVG from 'components/SVG';

import './Step.scss';

type StepProps = {
  label: string;
  step: number;
  numSteps: number;
  active: boolean;
  completed: boolean;
  onClick?: (step: number) => void;
  direction?: 'horizontal' | 'vertical';
  styles?: {
    stepStyle?: React.CSSProperties;
    wrapperStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    circleStyle?: React.CSSProperties;
    numberStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    separatorStyle?: React.CSSProperties;
  };
  classNames?: {
    stepClass?: string;
    wrapperClass?: string;
    iconClass?: string;
    circleClass?: string;
    numberClass?: string;
    labelClass?: string;
    separatorClass?: string;
  };
};

const Step = ({
  label,
  step,
  numSteps,
  active,
  completed,
  onClick,
  direction,
  styles = {},
  classNames = {},
}: StepProps) => {
  const {
    stepStyle,
    wrapperStyle,
    iconStyle,
    circleStyle,
    numberStyle,
    labelStyle,
    separatorStyle,
  } = styles;
  const {
    stepClass,
    wrapperClass,
    iconClass,
    circleClass,
    numberClass,
    labelClass,
    separatorClass,
  } = classNames;
  const isLastStep = step + 1 === numSteps;
  const isVertical = direction === 'vertical';

  function handleClick() {
    if (onClick) {
      onClick(step);
    }
  }

  return (
    <div
      className={classes('step', { 'step--vertical': isVertical }, stepClass)}
      style={stepStyle}
      onClick={handleClick}
    >
      <div
        className={classes(
          {
            'step__wrapper--vertical': isVertical,
            'step__wrapper--horizontal': !isVertical,
          },
          wrapperClass
        )}
        style={wrapperStyle}
      >
        <SVG
          icon="check_circle"
          className={classes(
            'step__icon',
            { 'step--hidden': !completed },
            iconClass
          )}
          styles={iconStyle}
        />
        <div
          className={classes(
            'step__circle',
            {
              'step--active': active,
              'step__circle--vertical': isVertical,
              'step--hidden': completed,
            },
            circleClass
          )}
          style={circleStyle}
        >
          <span
            className={classes(
              'step__number',
              {
                step__active: active,
              },
              numberClass
            )}
            style={numberStyle}
          >
            {step + 1}
          </span>
        </div>
        <div
          className={classes(
            'step__label',
            {
              'step__label--completed': completed || active,
              'step__label--vertical': isVertical,
              'step__label--vertical-completed': isVertical && completed,
            },
            labelClass
          )}
          style={labelStyle}
        >
          {label}
        </div>
        {!isLastStep && (
          <div
            className={classes(
              'step__seperator',
              {
                'step__seperator--active': completed,
                'step__seperator--vertical': isVertical,
                'step__seperator--vertical-active': isVertical && completed,
              },
              separatorClass
            )}
            style={separatorStyle}
          />
        )}
      </div>
    </div>
  );
};

export default Step;
