import { isNull } from 'lodash';
import { DateTime, Info } from 'luxon';
import { Day, StateProps } from './Calendar.types';

export const Actions = {
  SET_INIT: 'SET_INIT',
  SET_MONTH: 'SET_MONTH',
  SET_DATE: 'SET_DATE',
  SET_YEAR: 'SET_YEAR',
};

export const getDaysOfWeek = () => {
  const days: Array<string> = [];
  const startDate = [6, 0, 1, 2, 3, 4, 5];
  startDate.forEach((day) => {
    return days.push(Info.weekdays('short')[day].slice(0, 1));
  });
  return days;
};

const compareDays = (start: DateTime, end: DateTime) => {
  return start.toFormat('dd MM yyyy') === end.toFormat('dd MM yyyy');
};

const constructDayObj = (
  day: DateTime,
  dateFormat: string,
  date: DateTime,
  isAutOfMonth = false
) => ({
  day: day.toFormat(dateFormat),
  date: day,
  isActive: compareDays(date, day),
  isAutOfMonth,
});

export const getDays = (
  date: DateTime,
  selectedDate: DateTime
): Array<Day[]> => {
  const dateFormat = 'dd';
  const monthStart = date.startOf('month');
  const monthEnd = date.endOf('month');

  const startMonthDay = parseInt(monthStart.toFormat('c'));

  const days = new Array(startMonthDay > 0 ? startMonthDay : 6).fill(null);
  const emptyLength = days.length;

  let day = monthStart;

  while (day < monthEnd) {
    days.push(constructDayObj(day, dateFormat, selectedDate));
    day = day.plus({ days: 1 });
  }
  const rows = new Array(Math.ceil(days.length / 7)).fill([]);

  const slicedRows = rows.map(() => days.splice(0, 7));

  const res = slicedRows.map((item) => {
    const itemLength = item.length;
    const neededLength = 7;
    //Construct month ahead days array
    if (itemLength < neededLength) {
      for (let i = 1; i <= neededLength - itemLength; i++) {
        const newDate = monthEnd.plus({ days: i });
        item.push(constructDayObj(newDate, dateFormat, selectedDate, true));
      }
      return item;
    }
    return item.map((day, idx) => {
      //Construct month before days array
      if (isNull(day)) {
        const currentIdx = emptyLength - idx;
        const newDate = monthStart.plus({ days: -currentIdx });
        return constructDayObj(newDate, dateFormat, selectedDate, true);
      }
      return day;
    });
  });

  return res;
};

export const initialState: StateProps = {
  maxDate: null,
  minDate: null,
  disablePast: false,
  selectedDate: DateTime.local(),
  selectedMonth: DateTime.local(),
  selectedYear: DateTime.local(),
  disabledDays: [],
};

export const isDayDisabled = (day: Day, state: StateProps) => {
  if (!day) return true;
  if (day) {
    const { maxDate, minDate, disablePast, disabledDays } = state;
    let isMaxDate = false;
    let isMinDate = false;
    let isDisablePast = false;
    let isDisabledDays = false;

    if (maxDate) {
      isMaxDate = day.date >= maxDate;
    }
    if (minDate) {
      const isSameStartDay = compareDays(minDate, day.date);
      isMinDate = !isSameStartDay && day.date < minDate;
    }
    if (disablePast) {
      isDisablePast = day.date < DateTime.local();
    }
    if (disabledDays) {
      isDisabledDays = disabledDays.includes(
        day.date.toFormat('YYYY-MM-DD') as never
      );
    }

    return isMaxDate || isMinDate || isDisablePast || isDisabledDays;
  }
};
