import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import {
  apm,
  Request,
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { MarketType } from 'types';
import { FlexPricingEvent, FlexPricingEventRaw } from 'types/pricing-events';

import fileExportSave from 'helpers/downloadFile';

import { processRawFlexEvents } from '../helpers/dataProcessers';
import EventsTable from '../EventsTable';
import { EventsTableColumns } from '../EventsTable/EventsTable';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'routes/LongTermContracts/components/Modal';

import './IntentPage.scss';

function IntentPage(): JSX.Element {
  const { programID } = useParams<{
    programID: string;
    feederID: string;
  }>();
  const { selectedProgram: program, getFeeder } = useProgramsContext();

  const market = MarketType.DAYAHEAD;

  const newStartTime = {
    hour: 0,
    minute: 0,
    second: 0,
  };

  const newEndTime = {
    hour: 23,
    minute: 59,
    second: 59,
  };

  const [startDate, setStartDate] = useState<DateTime>(
    program
      ? DateTime.local().setZone(program.timezone).set(newStartTime)
      : DateTime.local().set(newStartTime)
  );

  const [updateEventsTableTrigger, triggerUpdateEventsTable] = useState(false);
  const [confirmingCancelEvent, setConfirmingCancelEvent] = useState(false);
  const [cancelPricingEventID, setCancelPricingEventID] = useState('');
  const [cancelStateType, setCancelStateType] = useState('');
  const [cancelReason, setCancelReason] = useState('');

  const IntentColumns: EventsTableColumns[] = [
    'time',
    'bidOffer',
    'feeder',
    'quantity',
    'quantityKVAR',
    'availability',
    'utilization',
    'responder',
    'arrow',
    'requestor',
    'status',
    'serviceType',
    'actions',
    'clickIcon',
  ];

  const NMFIntentColumns: EventsTableColumns[] = [
    'time',
    'bidOffer',
    'feeder',
    'quantity',
    'availability',
    'utilization',
    'responder',
    'arrow',
    'requestor',
    'status',
    'serviceType',
    'actions',
    'clickIcon',
  ];

  const confirmCancelEvent = async (
    pricingEventID: string,
    state_type: string,
    reason: string
  ) => {
    setConfirmingCancelEvent(true);
    setCancelPricingEventID(pricingEventID);
    setCancelStateType(state_type);
    setCancelReason(reason);
  };

  const updateEvent = async (
    pricingEventID: string,
    state_type: string,
    reason: string
  ) => {
    const request = new Request(
      `/api/dsp/program/${programID}/pricing_event/${pricingEventID}/state`
    );

    try {
      await request.post({
        state_type,
        reason,
      });
      // Request succeeded, trigger reload of data
      triggerUpdateEventsTable(!updateEventsTableTrigger);
      setConfirmingCancelEvent(false);
    } catch (error: any) {
      apm.captureError(error);
    }
  };

  const {
    data: events,
  }: {
    data: FlexPricingEvent[];
    loading: boolean;
    refetch: () => void;
  } = useRequestEffect({
    url: `/api/dsp/program/${programID}/flex_pricing_events`,
    method: 'get',
    initialData: [],
    params: {
      start_time: startDate.toISO(),
      end_time: startDate.set(newEndTime).toISO(),
      market_type: market,
    },
    blockRequest: () => !program,
    refetchOnChange: [programID, market, startDate, updateEventsTableTrigger],
    dataTransform: (data) => {
      return data.map((pe: FlexPricingEventRaw) =>
        processRawFlexEvents(pe, program || null, market, getFeeder)
      );
    },
    toast: {
      error: 'Failed to load flex pricing events',
    },
  });

  const { makeRequest: runExport } = useRequest(
    `/api/dsp/program/${programID}/export_flex_pricing_events`
  );

  const exportIntentsToDispatch = async (
    startTime: DateTime | undefined,
    endTime: DateTime | undefined
  ) => {
    await runExport({
      method: 'get',
      params: {
        /* only export closest hour if only start or end time selected */
        start_time: startTime
          ? startDate.set({
              hour: startTime.hour,
              minute: startTime.minute,
              second: startTime.second,
            })
          : endTime
          ? startDate.set({
              hour: endTime.hour - 1,
              minute: endTime.minute,
              second: endTime.second,
            })
          : startDate.set(newStartTime).toISO(),
        end_time: endTime
          ? startDate.set({
              hour: endTime.hour,
              minute: endTime.minute,
              second: endTime.second,
            })
          : startTime
          ? startDate.set({
              hour: startTime.hour + 1,
              minute: startTime.minute,
              second: startTime.second,
            })
          : startDate.set(newEndTime).toISO(),
        market_type: market,
      },
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(
          data,
          headers,
          `program-${programID}-intents-to-dispatch.csv`
        );
      },
      onError: undefined,
      toast: {
        error: 'Could not export intents to dispatch.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  return (
    <div className="intent-page">
      {program && (
        <EventsTable
          showColumns={
            process.env.REACT_APP_IS_NMF ? NMFIntentColumns : IntentColumns
          }
          type="Intent to Dispatch"
          data={events || []}
          program={program}
          updateEvent={confirmCancelEvent}
          dateFilterState={{
            filterDate: startDate,
            setFilterDate: setStartDate,
          }}
          downloadTable={exportIntentsToDispatch}
        />
      )}
      <Modal
        active={confirmingCancelEvent}
        reverseFooterButtons={true}
        hideClose
        cancelProps={{ label: 'No' }}
        confirmProps={{
          label: 'Yes',
          onClick: () => {
            updateEvent(cancelPricingEventID, cancelStateType, cancelReason);
          },
        }}
        onClose={() => {
          setConfirmingCancelEvent(false);
          setCancelPricingEventID('');
          setCancelStateType('');
          setCancelReason('');
        }}
        title="Are you sure you want to create FIAT cancellation request?"
      >
        <span className={'intent-page__modal-text'}>
          Note: You will not be able to undo this request.
        </span>
      </Modal>
    </div>
  );
}

export default IntentPage;
