import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';

import FormLabel from 'components/FormLabel';
import { RadioButtonsProps } from './RadioButtons.types';
import RadioButton from './components/RadioButton';
import './RadioButtons.scss';

const RadioButtons = ({
  options,
  onChange,
  id,
  label,
  labelPosition = 'end',
  helperText,
  type = 'primary',
  size = 'small',
  defaultSelected,
  disableAnimation = false,
  classNames,
  styles,
}: RadioButtonsProps) => {
  const radioGroup = `${id}-radio-group`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <FormLabel htmlFor={radioGroup}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={radioGroup}
        name={radioGroup}
        onChange={handleChange}
        defaultValue={defaultSelected && defaultSelected.value}
        classes={{
          root: 'radio-buttons',
        }}
      >
        {options.map(({ value, label, disabled, tooltip }, idx) => (
          <RadioButton
            key={idx}
            label={label}
            value={value}
            id={`${id}-option-${idx}`}
            disabled={disabled}
            disableAnimation={disableAnimation}
            type={type}
            size={size}
            tooltip={tooltip}
            labelPosition={labelPosition}
            classNames={classNames}
            styles={styles}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </div>
  );
};

export default RadioButtons;
