import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Cell } from 'react-table';

import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
import SVG from 'components/SVG';
import Tag from 'components/Tag';

import {
  TableData,
  columns,
} from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';

import './NeedsAnalysisTable.scss';

interface NeedsAnalysisTableParams {
  data: TableData[];
  placeHolderRow?: () => React.ReactElement;
  showColumns: columns[];
}

export const NeedsAnalysisTable = ({
  data,
  placeHolderRow,
  showColumns,
}: NeedsAnalysisTableParams) => {
  const history = useHistory();
  const location = useLocation();

  const renderPeertToNetworkHeader = (label: string) => (
    <span className="needs-analysis-table__header-p2n">{label}</span>
  );

  const renderHeader = (label: string, text: string) => (
    <Tooltip content={text} arrow={true} theme="light">
      <span className="needs-analysis-table__header-content">
        {label}
        <SVG
          icon="info_outlined"
          className={`needs-analysis-table__info`}
          fontSize="14px"
          round
        />
      </span>
    </Tooltip>
  );

  const renderEnrollmentType = (cellInfo: any) => (
    <div className="needs-analysis-table__text">{cellInfo.cell.value}</div>
  );

  const renderDayWeekAhead = (cellInfo: any) => (
    <div className="needs-analysis-table__text">
      <Tag
        content={
          cellInfo.cell.value === 0
            ? '      -'
            : cellInfo.cell.value > 99
            ? `${cellInfo.cell.value}%`
            : `  ${cellInfo.cell.value}%`
        }
        customClasses={{
          tagClass:
            'needs-analysis-table__tag needs-analysis-table__tag--darkened',
        }}
      ></Tag>
    </div>
  );

  const columns = useMemo(() => {
    const availableColumns = {
      peerToNetwork: {
        Header: renderPeertToNetworkHeader('Peer to Network'),
        accessor: 'peerToNetwork',
        Cell: renderEnrollmentType,
      },
      weekAhead: {
        Header: renderHeader(
          'Week ahead',
          'Procurement volume % approch details will be mentioned here'
        ),
        accessor: 'weekAhead',
        Cell: renderDayWeekAhead,
      },
      dayAhead: {
        Header: renderHeader(
          'Day ahead',
          'Procurement volume % approch details will be mentioned here'
        ),
        accessor: 'dayAhead',
        Cell: renderDayWeekAhead,
      },
      actions: {
        Header: '',
        accessor: 'serviceType',
        Cell: () => (
          <div style={{ marginTop: '8px' }}>
            <SVG icon="ChevronRightIcon" fontSize="16" />
          </div>
        ),
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [showColumns]);

  const onRowClick = (serviceType: string) =>
    history.push(`${location.pathname}/${serviceType}`);

  /**
   * Custom function to handle cell rendering.
   * Handles the logic for coloring specific columns.
   * @param {Row} row - object containing information about the row being rendered.
   * @param index - cell index in the row
   * @param tableRowClass - custom general row class.
   * @param tableCellClass - custom general cell class.
   * @returns - row
   */
  function renderCells(
    row: any,
    index: number,
    tableRowClass: string | undefined,
    tableCellClass: string | undefined
  ) {
    return (
      <div
        onClick={() => onRowClick(row.original.serviceType)}
        className={`
        ${tableRowClass} 
        needs-analysis-table--clickable
      `}
        {...row.getRowProps()}
        key={index}
      >
        {row.cells.map((cell: Cell, index: number) => {
          return (
            <div
              {...cell.getCellProps()}
              key={index}
              className={tableCellClass}
            >
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    );
  }

  const TableStyles = {
    tableClass: 'needs-analysis-table',
    tableHeaderRowClass: 'needs-analysis-table__header-row',
    tableHeaderCellClass: 'needs-analysis-table__header-cell',
    tableBodyRowClass: 'needs-analysis-table__body-row',
    tableBodyCellClass: 'needs-analysis-table__body-cell',
  };

  return (
    <Table
      disableSort
      data={data}
      columns={columns}
      customClasses={TableStyles}
      placeHolderRow={placeHolderRow}
      customRowRenderFunction={renderCells}
    />
  );
};
