import React, { memo } from 'react';

import { Day, DaysRowProps } from '../Calendar.types';
import { DayCell } from '../DayCell';
import './DaysRow.scss';

const DaysRow = ({ row, onSelect, selectedDate, state }: DaysRowProps) => {
  const isAllEmpty = row.filter((i) => i !== null).length === 0;
  return (
    <div className="days-row">
      {!isAllEmpty &&
        row.map((day: Day, index: number) => (
          <DayCell
            key={index}
            day={day}
            onSelect={onSelect}
            selectedDate={selectedDate}
            state={state}
          />
        ))}
    </div>
  );
};

export default memo(DaysRow);
