import React, { ReactChild, ReactNode } from 'react';
import cn from 'classnames';

import { default as MUIButton } from '@mui/material/Button';
import Tooltip from 'components/Tooltip';
import FadeLoader from 'components/FadeLoader';

import './Button.scss';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  // this prop is here to expand colour choices in the future.
  color?: 'primary';
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'medium';
  loading?: boolean;
  disabled?: boolean;
  href?: string;
  startIcon?: ReactChild;
  endIcon?: ReactNode;
  tooltip?: string;
  tooltipTheme?: 'light' | 'dark';
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  title?: string;
  id?: string;
  key?: string;
  name?: string;
  form?: string;
  disabledAnimation?: boolean;
  disabledInteractions?: boolean;
  htmlButtonType?: 'button' | 'submit' | 'reset';
  margin?: string;
  customClasses?: {
    customButtonClass?: string;
    customLoaderClass?: string;
    customLoaderSpokeClass?: string;
  };
  customStyles?: {
    customButtonStyles?: React.CSSProperties;
  };
};

export const Button: React.FC<ButtonProps> = ({
  onClick,
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  loading = false,
  disabled = false,
  tooltipTheme = 'light',
  tooltipPlacement,
  startIcon,
  endIcon,
  children,
  href,
  tooltip,
  title,
  id,
  key,
  name,
  form,
  disabledAnimation = false,
  disabledInteractions = false,
  htmlButtonType = 'button',
  margin,
  customClasses = {},
  customStyles = {},
}) => {
  function generateButton() {
    const { customButtonClass, customLoaderClass, customLoaderSpokeClass } =
      customClasses;
    const { customButtonStyles } = customStyles;
    return (
      <MUIButton
        onClick={onClick}
        color={color}
        variant={variant}
        size={size}
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        disableRipple={disabledAnimation}
        disableFocusRipple
        href={href}
        title={title}
        id={id}
        key={key}
        name={name}
        form={form}
        type={htmlButtonType}
        className={customButtonClass}
        style={{ margin: `${margin}`, ...customButtonStyles }}
        classes={{
          // can use classes to toggle between types of contained ie error or primary
          // can use classes library to toggle between light and dark if needed
          root: cn('button', {
            'button--non-interactive': disabledInteractions,
          }),
          contained: cn('button__contained', `button__contained--${color}`),
          outlined: cn('button__outlined', `button__outlined--${color}`),
          text: cn('button__text', `button__text--${color}`),
          startIcon: cn('button__icon-start', {
            'button--hidden': loading && !disabled,
          }),
          endIcon: cn('button__icon-end', {
            'button--hidden': loading && !disabled,
          }),
        }}
      >
        <FadeLoader
          customClasses={{
            loaderClass: cn(
              { 'button--hidden': !loading || disabled },
              customLoaderClass
            ),
            spokeClass: `button__${variant}--${color}-loading ${customLoaderSpokeClass}`,
          }}
        />
        <span className={cn({ 'button--hidden': loading && !disabled })}>
          {children}
        </span>
      </MUIButton>
    );
  }

  if (tooltip) {
    return (
      <Tooltip
        arrow
        theme={tooltipTheme}
        content={tooltip}
        placement={tooltipPlacement}
      >
        {generateButton()}
      </Tooltip>
    );
  }
  return generateButton();
};
