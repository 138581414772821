import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import { useUserContext } from 'contexts/UserContext';

import RouterSwitch from 'components/RouterSwitch';
import LoadingSpinner from 'components/LoadingSpinner';
import SettlementSummaryPage from './routes/SettlementSummaryPage';
import SettlementSingle from './SettlementSingle';

import './Settlement.scss';

const Settlement = () => {
  const { hasAllPermissions } = useAuthContext();
  const { user: currentUser, userIsDso, userHasTenant } = useUserContext();
  const location = useLocation();

  const canViewSummary =
    currentUser && userIsDso() && !hasAllPermissions(['market_participant']);

  function mainRouteRender() {
    if (!currentUser) {
      return <LoadingSpinner />;
    }
    if (canViewSummary) {
      return <SettlementSummaryPage />;
    }
    if (!userHasTenant()) {
      return <></>;
    }
    return (
      <Redirect
        to={`${location.pathname}/tenant/${currentUser.tenants[0].id}`}
      />
    );
  }

  return (
    <div className="settlement">
      <RouterSwitch>
        <Route exact path="/program/:programID/settlement/tenant/:tenantID">
          <SettlementSingle />
        </Route>
        <Route path="/program/:programID/settlement">{mainRouteRender()}</Route>
      </RouterSwitch>
    </div>
  );
};

export default Settlement;
