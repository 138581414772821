import { flexContract, serviceType } from 'types/contract-managment';
import { sortContractData } from './dataSorting';

const filtersTypesList = {
  p2p: [serviceType.MEC, serviceType.MIC],
  p2n: [
    serviceType.SustainBilateral,
    serviceType.SustainExportManagement,
    serviceType.SustainPeakManagement,
    serviceType.DynamicPeakManagement,
    serviceType.SecurePeakManagement,
  ],
};

const contractFilters = (
  type: 'p2p' | 'p2n',
  tenantID: string,
  isLoading: boolean,
  isDso: boolean,
  contracts?: flexContract[]
) => {
  if (!contracts) return [];
  let slicedContracts: flexContract[] = [];
  if (contracts && !isLoading && !isDso) {
    slicedContracts = contracts.filter(
      (contract) =>
        (contract.response.responder?.id === tenantID ||
          contract.request.requestor?.id === tenantID) &&
        filtersTypesList[type].includes(contract.request.service)
    );
  } else if (contracts && !isLoading && isDso) {
    slicedContracts = contracts.filter((contract) =>
      filtersTypesList[type].includes(contract.request.service)
    );
  }

  return sortContractData(slicedContracts);
};

export default contractFilters;
