import React from 'react';
import { Route } from 'react-router-dom';

import RouterSwitch from 'components/RouterSwitch';

import { Participants } from './Routes/Participants';

import './ParticipantsAndDers.scss';

export const sorter = Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export enum Routes {
  participants = '/participants-and-ders/participants',
  participant = '/participants-and-ders/participants/:tenantId',
}

export const ParticipantsAndDers = () => {
  return (
    <div className="participants-and-ders">
      <RouterSwitch>
        {
          <Route exact path={Routes.participants}>
            <Participants />
          </Route>
        }
        <Route path={Routes.participant}>
          <Participants />
        </Route>
      </RouterSwitch>
    </div>
  );
};
