import React from 'react';

import './FormLabel.scss';
interface Props {
  htmlFor: string;
  styles?: React.CSSProperties;
  required?: boolean;
}

const FormLabel: React.FC<Props> = ({
  htmlFor,
  styles = {},
  children,
  required = false,
}) => (
  <label
    className={`form-label ${required ? 'required' : ''}`}
    style={styles}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

export default FormLabel;
