import React, { useMemo } from 'react';

import { SelectOption } from 'types';
import Table from 'components/Table';
import SelectCell from './components/SelectCell';
import SwitchCell from './components/SwitchCell';
import MessageCell from './components/MessageCell';
import ActionCell from './components/ActionCell';

import {
  recipientList,
  recurrenceList,
  serviceList,
} from '../../EmailNotifications.logic';
import {
  NotificationItemProp,
  NotificationRulesProp,
  TableColumnsList,
} from '../../EmailNotifications.types';

import './NotificationTable.scss';

interface NotificationTableProps {
  data: NotificationItemProp[];
  settings: NotificationRulesProp;
  onMessageShow: (content: Record<string, any>) => void;
  onPreview: (content: Record<string, any>) => void;
  onRemove: (id: string) => void;
  onSwitchChange: (data: NotificationItemProp) => void;
  onFieldChange: (
    data: NotificationItemProp,
    typeId: string,
    value: string | boolean | Record<string, any>,
    name: string
  ) => void;
}

export const getValue = (list: SelectOption[], target: string) => {
  return list.find((item) => item.value === target);
};

const filterList = (list: SelectOption[], settings: Array<any>) =>
  list.filter((i) => settings.includes(i.value));

const checkFieldErrors = (
  data: NotificationItemProp,
  isRecipientType: boolean,
  isServiceType: boolean,
  isRecurrence: boolean
) => {
  return {
    recipient_type: isRecipientType && data.recipient_type === '',
    recurrence: isRecurrence && data.delay === null,
    service_type: isServiceType && data.service_type === '',
  };
};

const NotificationTable = ({
  data,
  settings,
  onMessageShow,
  onPreview,
  onRemove,
  onSwitchChange,
  onFieldChange,
}: NotificationTableProps) => {
  const filteredServiceList = filterList(serviceList, settings.service_type);
  const filteredRecipientList = filterList(recipientList, settings.recipient);
  const isRecipientType = filteredRecipientList.length > 1;
  const isServiceType = filteredServiceList.length > 1;
  const isRecurrence = settings.is_reminder;

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: TableColumnsList.active,
        Cell: ({ row: { original } }: any) => (
          <SwitchCell
            id={original.id}
            enabled={original.active}
            onClick={() => {
              const errors = checkFieldErrors(
                original,
                isRecipientType,
                isServiceType,
                isRecurrence
              );
              const { recurrence, recipient_type, service_type } = errors;
              if (original.newId) {
                onFieldChange(
                  original,
                  original.triggering_event_type,
                  errors,
                  'errors'
                );
              }
              if (!recurrence && !recipient_type && !service_type) {
                onSwitchChange({
                  ...original,
                  [TableColumnsList.recipient_type]:
                    original.recipient_type || filteredRecipientList[0].value,
                  [TableColumnsList.service_type]:
                    original.service_type || filteredServiceList[0].value,
                });
              }
            }}
          />
        ),
        maxWidth: 0,
        minWidth: 100,
      },
      {
        Header: 'Message',
        accessor: TableColumnsList.message,
        Cell: ({ row: { original } }: any) => (
          <MessageCell
            message={original.body}
            onClick={() => onMessageShow(original)}
          />
        ),
        maxWidth: 0,
        minWidth: 100,
      },
      isRecipientType
        ? {
            Header: 'Recipient',
            accessor: TableColumnsList.recipient_type,
            Cell: ({ row: { original } }: any) => (
              <SelectCell
                isError={original?.errors?.recipient_type}
                options={filteredRecipientList}
                placeholder="Select recipient"
                defaultValue={getValue(recipientList, original.recipient_type)}
                value={getValue(recipientList, original.recipient_type)}
                onChange={(value) =>
                  onFieldChange(
                    original,
                    original.triggering_event_type,
                    value,
                    TableColumnsList.recipient_type
                  )
                }
              />
            ),
            maxWidth: 0,
            minWidth: 240,
          }
        : { Header: '', accessor: 'default' },
      isServiceType
        ? {
            Header: 'Service type',
            accessor: TableColumnsList.service_type,
            Cell: ({ row: { original } }: any) => (
              <SelectCell
                isError={original?.errors?.service_type}
                options={filteredServiceList}
                placeholder="Select service type"
                defaultValue={getValue(serviceList, original.service_type)}
                value={getValue(recipientList, original.service_type)}
                onChange={(value) =>
                  onFieldChange(
                    original,
                    original.triggering_event_type,
                    value,
                    TableColumnsList.service_type
                  )
                }
              />
            ),
            maxWidth: 0,
            minWidth: 240,
          }
        : { Header: '', accessor: 'default2' },
      isRecurrence && settings.is_reminder
        ? {
            Header: 'Recurrence',
            accessor: TableColumnsList.recurrence,
            Cell: ({ row: { original } }: any) => (
              <SelectCell
                isError={original?.errors?.recurrence}
                options={recurrenceList}
                placeholder="Select recurrence"
                defaultValue={getValue(recurrenceList, original.delay)}
                value={getValue(recipientList, original.delay)}
                onChange={(value) =>
                  onFieldChange(
                    original,
                    original.triggering_event_type,
                    value,
                    'delay'
                  )
                }
              />
            ),
            maxWidth: 0,
            minWidth: 240,
          }
        : { Header: '', accessor: 'default3' },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <ActionCell
            isDisabledRemove={!!original.newId}
            isDisabledPreview={!!original.body}
            onPreview={() => onPreview(original)}
            onRemove={() => onRemove(original.id)}
          />
        ),
      },
    ],
    [
      filteredRecipientList,
      filteredServiceList,
      settings.is_reminder,
      onMessageShow,
      onPreview,
      onRemove,
      onFieldChange,
      onSwitchChange,
      isRecipientType,
      isServiceType,
      isRecurrence,
    ]
  );

  return (
    <Table
      disableSort
      tableType="Flex"
      data={data}
      columns={columns}
      customClasses={{
        tableClass: 'notification-table',
        tableHeaderClass: 'notification-table__header',
        tableHeaderCellClass: 'notification-table__header-cell',
        tableBodyRowClass: 'notification-table__body-row',
        tableBodyCellClass: 'notification-table__body-cell',
      }}
    />
  );
};

export default NotificationTable;
