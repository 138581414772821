import React, { memo, useMemo } from 'react';
import { DateTime } from 'luxon';

import { getDays } from '../Calendar.logic';
import { DaysProps } from '../Calendar.types';
import { DaysRow } from '../DaysRow';

const Days = ({ state, onSelect, selectedDate }: DaysProps) => {
  const rows = useMemo(
    () => getDays(state.selectedMonth || DateTime.local(), selectedDate),
    [state.selectedMonth, selectedDate]
  );

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {rows.map((row, index) => (
        <DaysRow
          key={index}
          row={row}
          state={state}
          onSelect={onSelect}
          selectedDate={state.selectedDate}
        />
      ))}
    </div>
  );
};

export default memo(Days);
