import React, { useState, ChangeEvent } from 'react';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import { useUserContext } from 'contexts/UserContext';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';

import { env } from 'helpers/env';

import Checkbox from 'components/Checkbox';
import SearchInput from 'components/SearchInput';
import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import SVG from 'components/SVG';

import {
  UploadType,
  useEnrollmentAPI,
} from 'routes/Program/routes/Enrollment/api';
import { useTableRows } from './Enrollment.logic';
import { UploadDownloadButtons } from './UploadDownloadButtons';
import { UploadModal } from './UploadModal';

import './Enrollment.scss';

const { isWSC } = env;

export default function ContainerEnrollment() {
  const { userIsDso } = useUserContext();
  const isDso = userIsDso();
  const [showUploadScalarModal, setShowUploadScalarModal] =
    useState<boolean>(false);
  const [showUploadPrimariesModal, setShowUploadPrimariesModal] =
    useState<boolean>(false);
  const [uploadType, setUploadType] = useState<UploadType | undefined>();

  const {
    programID,
    containers,
    loading,
    updating,
    toggleContainerEnrollment,
    toggleDEREnrollment,
    refetchDERs,
  } = useEnrollmentAPI();

  const { rows, expandedRows, expandContainer } = useTableRows(containers);

  const { hasAllPermissions } = useAuthContext();
  const checkBoxDisabled = !hasAllPermissions([
    'manage_utility_program_enrollment',
  ]);

  return (
    <HeaderLayout className="enrollment-container" title="Feeders">
      {loading && <LoadingSpinner />}
      {!loading && (
        <div>
          <div className="dataset-upload">
            {isDso && (
              <UploadDownloadButtons
                setShowUploadPrimariesModal={setShowUploadPrimariesModal}
                setShowUploadModal={setShowUploadScalarModal}
                setUploadType={setUploadType}
              />
            )}
          </div>
          <div className="table-container">
            <ReactTable
              data={rows}
              columns={[
                {
                  Header: 'Enrolled',
                  id: 'enrolled',
                  Cell: ({
                    original: { containerID, enrolled, id, type, isContainer },
                  }: any) =>
                    type.toLowerCase() !== 'primary' &&
                    !(isWSC && type.toLowerCase() !== 'feeder') && (
                      <Checkbox
                        checked={enrolled}
                        disabled={checkBoxDisabled}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          isContainer
                            ? toggleContainerEnrollment(event, id)
                            : toggleDEREnrollment(event, containerID, id);
                          refetchDERs();
                        }}
                      ></Checkbox>
                    ),
                  width: 85,
                },
                {
                  expander: true,
                  width: 65,
                  Expander: ({
                    original: { isContainer, enrolled, id, type },
                  }: any) => {
                    const isExpanded = !!expandedRows[id];
                    return enrolled && isContainer ? (
                      <div
                        className={classNames(
                          'rt-expander',
                          isExpanded && '-open',
                          type.toLowerCase() === 'primary' &&
                            'rt-expander--primary'
                        )}
                        onClick={() => expandContainer(id)}
                      >
                        &bull;
                      </div>
                    ) : null;
                  },
                  style: {
                    fontSize: 25,
                    padding: '0',
                    textAlign: 'center',
                    userSelect: 'none',
                  },
                },
                {
                  Header: 'Name',
                  accessor: 'name',
                  filterable: true,
                  filterMethod: (filter: any, row: any) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  Filter: (cellInfo) => (
                    <SearchInput
                      onChange={(e) => cellInfo.onChange(e.target.value)}
                      placeholder="Name"
                    />
                  ),
                  Cell: ({
                    original: { name, primary, isContainer, type },
                  }: any) => {
                    return (
                      <div
                        className={`${primary && 'table-cell--primary'} ${
                          primary &&
                          type.toLowerCase() === 'primary' &&
                          'table-cell--bold'
                        }`}
                      >
                        {primary && !isContainer && (
                          <SVG
                            icon="CornerDownRightIcon"
                            fontSize="16px"
                            styles={{ marginRight: '12px' }}
                          />
                        )}
                        {name}
                      </div>
                    );
                  },
                },
                {
                  Cell: ({
                    original: { containerID, enrolled, id, isContainer, type },
                  }: any) => {
                    if (
                      !enrolled &&
                      type.toLowerCase() !== 'primary' &&
                      !isWSC
                    ) {
                      return (
                        <a href="#/" className="disabled-link">
                          <SVG
                            icon="Edit3Icon"
                            className="modal-content-icon"
                          />
                        </a>
                      );
                    }
                    const link = isContainer
                      ? `/program/${programID}/enrollment/feeder/${id}`
                      : `/program/${programID}/enrollment/asset/${containerID}/${id}`;
                    return type.toLowerCase() !== 'primary' &&
                      !(isWSC && type.toLowerCase() !== 'feeder') ? (
                      <Link to={link}>
                        <SVG icon="Edit3Icon" className="modal-content-icon" />
                      </Link>
                    ) : (
                      <div className="empty-icon-spacer"></div>
                    );
                  },
                },
                {
                  Header: 'Type',
                  accessor: 'type',
                  filterable: true,
                  filterMethod: (filter: any, row: any) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  Filter: (cellInfo) => (
                    <SearchInput
                      onChange={(e) => cellInfo.onChange(e.target.value)}
                      placeholder="Type"
                    />
                  ),
                },
              ]}
              pageSize={rows.length || 3}
              showPagination={false}
              loading={updating}
            />
          </div>
        </div>
      )}
      {(setShowUploadScalarModal || showUploadPrimariesModal) && (
        <UploadModal
          containers={containers}
          showUploadScalarModal={showUploadScalarModal}
          setShowUploadScalarModal={setShowUploadScalarModal}
          showUploadPrimariesModal={showUploadPrimariesModal}
          setShowUploadPrimariesModal={setShowUploadPrimariesModal}
          uploadType={uploadType}
        />
      )}
    </HeaderLayout>
  );
}
