import { DateTime } from 'luxon';
import { Program } from 'contexts/ProgramsContext';

import {
  VintagedBidOfferRaw,
  VintagedBidOffer,
} from 'types/vintaged-bid-offers';

import { Tenant } from 'types/tenant';
import { Feeder } from 'types/feeder';
import { MarketType } from 'types';

import {
  processRawFlexContract,
  getServiceType,
} from 'routes/LongTermContracts/helpers/dataProcessers';
import { FlexPricingEvent, FlexPricingEventRaw } from 'types/pricing-events';

export function ProcessRawVBOs(
  data: VintagedBidOfferRaw,
  tenants: Tenant[] | undefined,
  program: Program | null,
  getFeeder: (id: string | null | undefined) => Feeder | null | undefined
): VintagedBidOffer {
  const {
    id,
    der_id,
    der_rdf_id,
    duration,
    market_type,
    price,
    quantity,
    start_time,
    quantity_type,
    type,
    contract,
  } = data;

  const startTime = program
    ? DateTime.fromISO(start_time).setZone(program.timezone)
    : DateTime.fromISO(start_time);
  const endTime = startTime.plus({ seconds: duration });

  const feeder = getFeeder(contract.feeder_id);

  const parsedContract = processRawFlexContract(contract, tenants, program);

  return {
    id,
    derID: der_id,
    derRdfID: der_rdf_id,
    duration,
    marketType: market_type,
    price,
    powerRequired: quantity,
    quantityType: quantity_type,
    endTime,
    startTime,
    type,
    feederName: feeder ? feeder.name : '',
    status: parsedContract.status,
    reactivePowerRequired: 0,
    availabilityPrice: parsedContract.response.availability,
    utilizationPrice: parsedContract.response.utilization,
    serviceType: parsedContract.request.service,
    requestor: {
      id: parsedContract.request.requestor?.id,
      name: parsedContract.request.requestorName,
      image: parsedContract.request.requestorImage,
      der: { name: '', derType: '' },
    },
    responder: {
      id: parsedContract.response.responder?.id,
      name: parsedContract.response.responderName,
      image: parsedContract.response.responderImage,
      der: {
        name: parsedContract.response.derName,
        derType: parsedContract.response.derType,
      },
    },
  };
}

export function processRawFlexEvents(
  data: FlexPricingEventRaw,
  program: Program | null,
  market: MarketType,
  getFeeder: (id: string | null | undefined) => Feeder | null | undefined
): FlexPricingEvent {
  const {
    id,
    type,
    market_type,
    der,
    program_id,
    start_time,
    power_required,
    reactive_power_required,
    unit_price,
    created_time,
    status,
    service_type,
    requestor,
    responder,
    feeder_rdf_id,
    availability_price,
    utilization_price,
    opf_completed,
  } = data;

  const eventServiceType = getServiceType(service_type);

  const feeder = getFeeder(feeder_rdf_id);

  const startTime = program
    ? DateTime.fromISO(start_time).setZone(program.timezone)
    : DateTime.fromISO(start_time);

  const processedDER = {
    name: der.name,
    derType: der.der_type,
  };

  let seconds = 3600;
  if (market === MarketType.SAMEDAY) {
    seconds = program?.sameday_event_duration || 3600;
  }

  // switching signs of powerRequired and reactivePowerRequired
  const new_power_required = (parseFloat(power_required) * -1).toString();
  const new_reactive_power_required = (
    parseFloat(reactive_power_required) * -1
  ).toString();

  return {
    id,
    type,
    marketType: market_type,
    der: processedDER,
    programID: program_id,
    startTime,
    endTime: startTime.plus({ seconds }),
    powerRequired: new_power_required,
    reactivePowerRequired: new_reactive_power_required,
    unitPrice: unit_price,
    createdTime: created_time,
    status,
    serviceType: eventServiceType,
    requestor: {
      name: requestor.name,
      id: requestor.id,
      image: '',
      der: { name: '', derType: '' },
    },
    responder: {
      name: responder.name,
      id: responder.id,
      image: '',
      der: processedDER,
    },
    feederID: feeder_rdf_id,
    feederName: feeder ? feeder.name : '',
    availabilityPrice: availability_price,
    utilizationPrice: utilization_price,
    opfFailure: opf_completed,
  };
}
