import React from 'react';

import './Acknowledgements.scss';

const Acknowledgements = () => {
  return (
    <div className="acknowledgements">
      <h1 className="acknowledgements__header">Acknowledgements</h1>
      <h2 className="acknowledgements__sub-header acknowledgements--red">
        Warning! Links on this page are to third party websites!
      </h2>
      <h2 className="acknowledgements__sub-header">Libraries:</h2>
      <div className="acknowledgements__item">
        {'This product uses the '}
        <a
          href="https://github.com/browserslist/caniuse-lite"
          target="_blank"
          rel="noreferrer"
        >
          {'caniuse-lite'}
        </a>
        {' library by '}
        <a href="https://caniuse.com/" target="_blank" rel="noreferrer">
          {'caniuse'}
        </a>
        {' which is licensed under '}
        <a
          href="https://creativecommons.org/licenses/by/4.0/"
          target="_blank"
          rel="noreferrer"
        >
          {'CC BY 4.0'}
        </a>
        {'.'}
      </div>
    </div>
  );
};

export default Acknowledgements;
