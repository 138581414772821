import React from 'react';
import { DateTime } from 'luxon';

import {
  flexRequest,
  peakType,
  SelectDays,
  serviceType,
  ServiceWindow,
} from 'types/contract-managment';
import { Feeder } from 'types/feeder';

import { getServiceTimeRangeDetails, isMidnight } from 'helpers/time';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';

export interface Props {
  show: boolean;
  updateParent: (value: boolean) => void;
  programID: string;
  request: flexRequest;
  setSubmittingResponses: (value: boolean) => void;
  derId: string | undefined | null;
}

export const renderZone = (feederId: string, feeders: Feeder[]) => {
  const feeder = feeders.find((feeder) => feeder.id === feederId);

  return (
    <span className="view-flex-auction-info__text view-flex-auction-info--no-border">
      {feeder?.name}
    </span>
  );
};

export const renderServiceType = (service: serviceType) => {
  return (
    <span className="view-flex-auction-info__text view-flex-auction-info--no-border">
      {service}
    </span>
  );
};

export const renderServicePeriod = (startDate: DateTime, endDate: DateTime) => {
  return `${startDate?.toLocaleString({
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })} - ${endDate
    .minus({
      days: isMidnight(endDate) ? 1 : 0,
    })
    ?.toLocaleString({
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })}`;
};

export const renderTimeRange = (
  peak: peakType,
  customDays: SelectDays | null,
  serviceWindows: ServiceWindow[]
) => {
  const { days, startTime, endTime } = getServiceTimeRangeDetails(
    peak,
    customDays,
    serviceWindows[0].startTime,
    serviceWindows[0].endTime
  );

  return (
    <Grid container spacing={1}>
      <Grid item>{days.join(' | ')}</Grid>
      <Grid item>
        (
        {`${startTime === '24:00' ? '00:00' : startTime} - ${
          endTime === '00:00' ? '24:00' : endTime
        }`}
        )
      </Grid>
    </Grid>
  );
};
