import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

import {
  useAuthContext,
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { CurrentUser } from 'types/user';
import { enrollmentType } from 'types/contract-managment';

// Updateable properties of a user
export interface UserArgs {
  toc_accepted: boolean;
}

interface UserContextType {
  loading: boolean;
  user: CurrentUser | null;
  displayNavigation: boolean;
  updateCurrentUser(args: UserArgs): void;
  toggleNavMenu(): void;
  displayTOCModal: boolean;
  toggleTOCModal(): void;
  userIsDso(): boolean;
  getUserDerFeederId(assetId: string): string;
  userHasTenant(): boolean;
  getUserTenantId(): string | undefined;
  getUserEnrollmentType(): string;
  isNotEnrolledP2P: boolean;
  isNotEnrolledP2N: boolean;
}

export const UserContext = createContext<UserContextType>({
  loading: true,
  user: null,
  displayNavigation: true,
  updateCurrentUser() {},
  toggleNavMenu() {},
  displayTOCModal: false,
  toggleTOCModal() {},
  userIsDso: () => false,
  getUserDerFeederId: (assetId) => '',
  userHasTenant: () => false,
  getUserTenantId: () => undefined,
  getUserEnrollmentType: () => '',
  isNotEnrolledP2P: false,
  isNotEnrolledP2N: false,
});

interface UsersContextProviderProps {
  children: ReactNode;
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      `useUserContext must be used within a UsersContextProvider`
    );
  }
  return context;
};

const UsersContextProvider = ({ children }: UsersContextProviderProps) => {
  const { isAuthenticated, isLoading } = useAuthContext();
  const [user, setUser] = useState<CurrentUser | null>(null);
  const [displayNavigation, setDispplayNavigation] = useState<boolean>(true);
  const [displayTOCModal, setDisplayTOCModal] = useState<boolean>(false);

  const { loading } = useRequestEffect<CurrentUser>({
    url: '/api/dsp/users/current_user',
    method: 'put',
    refetchOnChange: [isLoading, isAuthenticated],
    blockRequest: () => isLoading || !isAuthenticated,
    onSuccess: (data) => {
      if (data) {
        setUser(data);
        // Check if NMF and if user accepted the TOC
        if (process.env.REACT_APP_IS_NMF === 'true' && !data.toc_accepted) {
          setDisplayTOCModal(true);
        }
      }
    },
  });

  const { makeRequest: runUpdate } = useRequest<CurrentUser>(
    '/api/dsp/users/current_user'
  );
  const updateCurrentUser = async (args: UserArgs) => {
    await runUpdate({
      method: 'put',
      body: args,
      toast: {
        error: 'Failed to update user',
      },
      onSuccess: (data) => {
        if (data) {
          setUser(data);
        }
      },
    });
  };

  const toggleNavMenu = () => {
    setDispplayNavigation(!displayNavigation);
  };

  const toggleTOCModal = () => {
    setDisplayTOCModal(!displayTOCModal);
  };

  const userIsDso = (): boolean => {
    if (!user) {
      return false;
    }
    try {
      return user.tenants[0].is_dso;
    } catch {
      return false;
    }
  };

  const getUserDerFeederId = (assetId: string): string => {
    try {
      const foundDer = user?.tenants[0].ders.find(
        (der) => der.rdf_id === assetId
      );
      return foundDer?.info.feeder.id || '';
    } catch {
      return '';
    }
  };

  const userHasTenant = (): boolean => {
    return !!(user?.tenants && user.tenants.length > 0);
  };

  const getUserTenantId = (): string | undefined => {
    try {
      return user?.tenants[0].id;
    } catch {
      return '';
    }
  };

  const getUserEnrollmentType = useMemo(
    () => (): string => {
      try {
        const isEnableP2P = user?.tenants[0].enable_p2p;
        const isEnableP2N = user?.tenants[0].enable_p2n;
        return isEnableP2P && isEnableP2N
          ? enrollmentType.Both
          : isEnableP2P
          ? enrollmentType.P2P
          : isEnableP2N
          ? enrollmentType.P2N
          : enrollmentType.None;
      } catch {
        return '';
      }
    },
    [user?.tenants]
  );

  const isNotEnrolledP2P =
    getUserEnrollmentType() !== enrollmentType.P2P &&
    getUserEnrollmentType() !== enrollmentType.Both;

  const isNotEnrolledP2N =
    getUserEnrollmentType() !== enrollmentType.P2N &&
    getUserEnrollmentType() !== enrollmentType.Both;

  return (
    <UserContext.Provider
      value={{
        loading,
        user,
        displayNavigation,
        updateCurrentUser,
        toggleNavMenu,
        displayTOCModal,
        toggleTOCModal,
        userIsDso,
        getUserDerFeederId,
        userHasTenant,
        getUserTenantId,
        getUserEnrollmentType,
        isNotEnrolledP2P,
        isNotEnrolledP2N,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = UsersContextProvider;
