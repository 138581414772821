import React, { memo } from 'react';

import IconButton from 'components/IconButton';
import { CalendarHeaderProps, PickType } from '../Calendar.types';
import { PickDate } from '../PickDate';
import { setDate, setMonth } from '../reducer/actions';

import './CalendarHeader.scss';

const CalendarHeader = ({
  state,
  dispatch,
  disabledMonthPicker,
  minDate,
  maxDate,
}: CalendarHeaderProps) => {
  const selectedDate = state.selectedMonth;

  const nextMonthHandler = () => {
    const nextMonth = selectedDate.plus({ month: 1 });
    dispatch(setMonth(nextMonth));
  };
  const prevMonthHandler = () => {
    const prevMonth = selectedDate.plus({ month: -1 });
    dispatch(setMonth(prevMonth));
  };

  const setDateHandler = (type: PickType, value: number) => {
    const newDate = selectedDate.set({
      [type]: value,
    });
    dispatch(setDate(newDate));
  };

  return (
    <div className="calendar-header">
      <div className="calendar-header__label">
        <PickDate
          type="month"
          onSubmit={setDateHandler}
          currentDate={selectedDate}
        />
        <PickDate
          type="year"
          onSubmit={setDateHandler}
          currentDate={selectedDate}
          className="calendar-header__year"
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="calendar-header__nav">
        <IconButton
          onClick={prevMonthHandler}
          variant="nestedIconButton"
          icon="ChevronLeftIcon"
          disabled={disabledMonthPicker}
        />
        <IconButton
          onClick={nextMonthHandler}
          variant="nestedIconButton"
          icon="ChevronRightIcon"
          disabled={disabledMonthPicker}
        />
      </div>
    </div>
  );
};

export default memo(CalendarHeader);
