import React from 'react';
import { useHistory } from 'react-router';
import { DateTime } from 'luxon';
import {
  useRequest,
  useAuthContext,
} from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';

import fileExportSave from 'helpers/downloadFile';

// import Switch from 'components/Switch';
import Button from 'components/Button';
import SVG from 'components/SVG';

import './SettlementHeader.scss';

type SettlementHeaderProps = {
  children?: React.ReactElement | null | false;
  selectedRowCount: number; // disables button if <= 0
  selectedTenants: string[];
  startDate: DateTime;
  title?: string;
  backButtonTitle?: string;
  showButtons?: {
    showExportButton?: boolean;
    showImportButton?: boolean;
    showPublishButton?: boolean;
  };
  summaryPage?: boolean;
  refetchSummaries: () => void;
  toggleImportModal: () => void;
};

const Settlement = ({
  children,
  selectedRowCount,
  selectedTenants,
  startDate,
  title,
  backButtonTitle,
  showButtons = {},
  summaryPage = true,
  refetchSummaries,
  toggleImportModal,
}: SettlementHeaderProps) => {
  const { showExportButton, showImportButton, showPublishButton } = showButtons;
  const history = useHistory();
  const {
    selectedProgram: program,
    selectedProgram,
    selectedFeeder,
  } = useProgramsContext();
  const { hasAllPermissions } = useAuthContext();

  const {
    makeRequest: makePublishSettlementRequest,
    loading: publishSettlementLoading,
  } = useRequest(
    `/api/dsp/program/${selectedProgram?.program_id}/feeder/${selectedFeeder?.id}/flex/settlement/publish`
  );

  const handlePublishSettlement = () => {
    makePublishSettlementRequest({
      method: 'post',
      body: {
        start_time: startDate.toISO(),
        tenant_ids: selectedTenants,
      },
      onSuccess: refetchSummaries,
      toast: {
        success: 'Settlements for selected tenants successfully published!',
        error: (res) => {
          let message = '';
          if (res?.response?.data) {
            message = res.response?.data?.message;
          }
          if (!message) {
            message =
              'Something went wrong publishing settlements for the selected tenants.';
          }
          return message;
        },
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { makeRequest: measurementExportRequest } = useRequest(
    `/api/dsp/measurements/tenant/export`
  );

  async function runMeasurementExport(tenantID: string) {
    await measurementExportRequest({
      method: 'get',
      params: {
        tenant_id: tenantID,
        program_id: program?.program_id,
        start_time: startDate.toJSDate().toISOString(),
        end_time: startDate
          .set({
            day: startDate.daysInMonth,
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .toJSDate()
          .toISOString(),
      },
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(data, headers, `Measurement-Data-${tenantID}.zip`);
      },
      onError: undefined,
      toast: {
        error: 'Could not export measurement data.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  }

  function exportMeasurementOnClick() {
    selectedTenants.forEach((tenantID) => {
      runMeasurementExport(tenantID);
    });
  }

  const disabled = selectedRowCount <= 0;

  return (
    <div className="settlement-header">
      {title && <span className="settlement-header__title">{title}</span>}
      {backButtonTitle && hasAllPermissions(['view_settlements_summary']) && (
        <Button
          variant="text"
          onClick={() =>
            history.push(`/program/${program?.program_id}/settlement`)
          }
          startIcon={<SVG icon="ChevronLeftIcon" fontSize="16px" />}
        >
          {backButtonTitle || 'test'}
        </Button>
      )}
      <div className="settlement-header__right">
        {/*<Switch
          id={'settlement-all-show-graph'}
          checked={false}
          onClick={() => console.log('test')}
          label="Show Graph"
          disabled
        />*/}
        {showExportButton && (
          <Button
            onClick={exportMeasurementOnClick}
            variant={!summaryPage ? 'outlined' : undefined}
            disabled={disabled}
          >
            Export Measurement Data
          </Button>
        )}
        {showImportButton && (
          <Button
            onClick={toggleImportModal}
            variant={!summaryPage ? 'outlined' : undefined}
            disabled={disabled}
          >
            Import Baseline
          </Button>
        )}
        {showPublishButton && (
          <Button
            onClick={handlePublishSettlement}
            variant={!summaryPage ? 'outlined' : undefined}
            disabled={disabled}
            loading={publishSettlementLoading}
          >
            Publish Settlement
          </Button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Settlement;
