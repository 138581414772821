import React from 'react';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { AnyObject } from 'yup/lib/types';

import {
  ContractModelOptions,
  peakTypeRaw,
  peakType,
  SelectDays,
} from 'types/contract-managment';

import Tooltip from 'components/Tooltip';

export const schema = (
  closeDate: DateTime,
  deadlineDate: DateTime,
  isMEC: boolean,
  isMIC: boolean
) =>
  yup.object().shape({
    contractModel: yup.string().required(),
    feeder: yup.object().required('Select a feeder.'),
    industryActor: yup
      .object()
      .nullable()
      .when('contractModel', {
        is: ContractModelOptions.Direct,
        then: yup.object().required('Select an industry actor.'),
      }),
    startDate: yup
      .date()
      .required('Start date is required.')
      .test(
        'after-close',
        'Select a start date that is after the close date.',
        (value): boolean => {
          if (!value) {
            return false;
          }
          const diff = DateTime.fromJSDate(value).diff(
            closeDate.startOf('day'),
            ['days']
          );
          const res = diff.get('days') >= 0;
          return res;
        }
      )
      .test(
        'mec-within-deadline',
        `Select a start date that is after ${deadlineDate.toLocaleString({
          weekday: 'long',
          month: 'long',
          day: '2-digit',
        })}.`,
        (value): boolean => {
          if (!isMEC && !isMIC) return true;
          if (!value) {
            return false;
          }
          const res = DateTime.fromJSDate(value) >= deadlineDate;
          return res;
        }
      ),
    endDate: yup
      .date()
      .required('End date is required.')
      .test(
        'after-start',
        'Select a end date that is or after the start date.',
        (rawEndDate, ctx): boolean => {
          const rawStartDate = ctx.parent.startDate;
          if (!rawEndDate || !rawStartDate) {
            return false;
          }
          const startDate = DateTime.fromJSDate(rawStartDate).startOf('day');
          const endDate = DateTime.fromJSDate(rawEndDate).startOf('day');
          const diff = endDate.diff(startDate, ['days']);
          const res = diff.get('days') >= 0;
          return res;
        }
      ),
    serviceWindow: yup.string().required(),
    serviceDays: yup
      .object()
      .nullable()
      .when('serviceWindow', {
        is: peakTypeRaw.Custom,
        then: yup
          .object()
          .test(
            'one-selected',
            'You must select at least one day.',
            (value) => value && Object.values(value).some(Boolean)
          ),
      })
      .test(
        'select-day-in-window',
        'Select a day of the week that is within the service window.',
        (selectedDays, ctx): boolean => {
          const rawStartDate = ctx.parent.startDate;
          const rawEndDate = ctx.parent.endDate;
          if (!rawEndDate || !rawStartDate || !selectedDays) {
            return false;
          }
          const startDate = DateTime.fromJSDate(rawStartDate).startOf('day');
          const endDate = DateTime.fromJSDate(rawEndDate).startOf('day');
          // if start and end days or >=7 days appart all days are valid
          if (endDate.diff(startDate, 'days').days >= 7) {
            return true;
          }
          return validateSelectedDays(startDate, endDate, selectedDays);
        }
      ),
    startTime: yup
      .date()
      .required('Service start time is required.')
      .test(
        'after-close-time',
        'Select a start time that occurs after the closing time.',
        (rawStartTime, ctx): boolean => {
          const rawStartDate = ctx.parent.startDate;
          if (!rawStartDate || !rawStartTime) {
            return false;
          }
          const startTime = DateTime.fromJSDate(rawStartTime);
          const start = DateTime.fromJSDate(rawStartDate).startOf('day').set({
            hour: startTime.hour,
            minute: startTime.minute,
          });
          // start date and time and must be greater then close date and time
          const diff = start.diff(closeDate, ['minutes']);
          const res = diff.get('minutes') >= 1;
          return res;
        }
      ),
    endTime: yup
      .date()
      .required('Service end time is is required.')
      .test(
        'after-start-time',
        'Select an end date and time that occurs at least one hour after the start date and time.',
        (rawEndTime, ctx): boolean => {
          const rawStartDate = ctx.parent.startDate;
          const rawStartTime = ctx.parent.startTime;
          const rawEndDate = ctx.parent.endDate;
          if (!rawEndTime || !rawStartDate || !rawStartTime || !rawEndDate) {
            return false;
          }
          const startTime = DateTime.fromJSDate(rawStartTime);
          const start = DateTime.fromJSDate(rawStartDate).startOf('day').set({
            hour: startTime.hour,
            minute: startTime.minute,
          });
          const endTime = DateTime.fromJSDate(rawEndTime);
          const end = DateTime.fromJSDate(rawEndDate)
            .startOf('day')
            .set({
              hour: endTime.hour === 0 ? 24 : endTime.hour,
              minute: endTime.minute,
            });
          // end date and time must occur after start date and time.
          const diff = end.diff(start, ['hours']);
          const res = diff.get('hours') >= 1;
          return res;
        }
      ),
    bidOffer: yup
      .string()
      .required('Select if this is a bid or offer request.'),
    serviceType: yup
      .object()
      .required('Select a service type')
      .test(
        'select service type not enrolled',
        'Service type not enrolled on P2P or P2N services',
        (val) => !val.disabled
      ),
    distributedResource: yup
      .object()
      .test(
        'is-mec-selected',
        `DER enrolled on ${isMIC ? 'MIC' : 'MEC'} service type is required`,
        (val) => {
          if ((isMEC || isMIC) && !val?.value) return false;
          return true;
        }
      ),
  });

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: '#ECF3FA',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#54595E',
    fontSize: 12,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? '#d4dfe8' : '#54595E',
    fontSize: 12,
    display: 'flex',
    maxWidth: '210px',
  }),
};

export function updateSelectedDays(
  startDate: DateTime,
  endDate: DateTime,
  selectedDays: SelectDays
): SelectDays {
  if (!selectedDays) return selectedDays;
  // get week day for start and end
  const startDay = startDate.weekdayLong.toLowerCase();
  const endDay = endDate.weekdayLong.toLowerCase();
  const days = Object.keys(selectedDays);
  const startIndex = days.indexOf(startDay);
  const endIndex = days.indexOf(endDay);
  /**
   * If the week days in the start and end date are sequential in the array:
   * Items selected and not between them (uninclusive) are invalid.
   */
  if (startIndex < endIndex) {
    days.forEach((day, index) => {
      const isSelected = selectedDays[day];
      if ((index < startIndex || index > endIndex) && isSelected) {
        selectedDays[day] = false;
      }
    });
  }
  /**
   * If the week days of the start and end date are not sequential in the array:
   * Items between them (uninclusive) are invalid.
   */
  days.forEach((day, index) => {
    const isSelected = selectedDays[day];
    if (index < startIndex && index > endIndex && isSelected) {
      selectedDays[day] = false;
    }
  });
  return selectedDays;
}

function validateSelectedDays(
  startDate: DateTime,
  endDate: DateTime,
  selectedDays: AnyObject
): boolean {
  // get week day for start and end
  const startDay = startDate.weekdayLong.toLowerCase();
  const endDay = endDate.weekdayLong.toLowerCase();
  const days = Object.keys(selectedDays);
  const startIndex = days.indexOf(startDay);
  const endIndex = days.indexOf(endDay);
  /**
   * If the week days in the start and end date are sequential in the array:
   * Items selected and not between them (uninclusive) are invalid.
   */
  if (startIndex < endIndex) {
    return days.every((day, index) => {
      const isSelected = selectedDays[day];
      if ((index < startIndex || index > endIndex) && isSelected) {
        return false;
      }
      return true;
    });
  }
  /**
   * If the week days of the start and end date are not sequential in the array:
   * Items between them (uninclusive) are invalid.
   */
  return days.every((day, index) => {
    const isSelected = selectedDays[day];
    if (index < startIndex && index > endIndex && isSelected) {
      return false;
    }
    return true;
  });
}

export const serviceWindowTypes = [
  {
    id: peakTypeRaw.Peak,
    label: (
      <div className="create-request-service__radio-text flex-request-form__tooltip">
        <Tooltip
          arrow
          content="Mon – Fri  07:00 - 21:59, non-Holidays"
          theme="light"
        >
          {peakType.Peak}
        </Tooltip>
      </div>
    ),
    disabled: true,
  },
  {
    id: peakTypeRaw.OffPeak,
    label: (
      <div className="create-request-service__radio-text flex-request-form__tooltip">
        <Tooltip
          arrow
          content="Mon – Fri  22:00 - 06:59, Sat, Sun, Holidays"
          theme="light"
        >
          {peakType.OffPeak}
        </Tooltip>
      </div>
    ),
    disabled: true,
  },
  {
    id: peakTypeRaw.twentyFourSeven,
    label: (
      <div className="create-request-service__radio-text flex-request-form__tooltip">
        <Tooltip arrow content="All day, every day" theme="light">
          {peakType.twentyFourSeven}
        </Tooltip>
      </div>
    ),
    disabled: true,
  },
  {
    id: peakTypeRaw.Custom,
    label: (
      <div className="create-request-service__radio-text flex-request-form__tooltip">
        <Tooltip arrow content="Create custom service window" theme="light">
          {peakType.Custom}
        </Tooltip>
      </div>
    ),
  },
];

export const contractModelOptions = [
  { label: 'Pool', id: ContractModelOptions.Pool },
  { label: 'Direct', id: ContractModelOptions.Direct },
];

export const minDate = (closeDate: DateTime, timeGate: DateTime) => {
  const today = DateTime.local();
  const nextWeek = closeDate
    .plus({ days: 7 })
    .startOf('week')
    .minus({ days: 1 });
  const nextWeekPlus = closeDate
    .plus({ days: 14 })
    .startOf('week')
    .minus({ days: 1 });
  const isLessThanTimeGate =
    today.startOf('day') <= timeGate.startOf('day') &&
    today.get('hour') < timeGate.hour;

  return isLessThanTimeGate ? nextWeek : nextWeekPlus;
};
