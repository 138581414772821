import { useState } from 'react';
import { StepperStep } from '.';

interface useStepperProps {
  defaultSteps: StepperStep[];
}

const useStepper = ({ defaultSteps }: useStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<StepperStep[]>(defaultSteps);

  function updateStep(
    updatedSteps: StepperStep[],
    step: number,
    active: boolean | undefined = undefined,
    completed: boolean | undefined = undefined
  ) {
    updatedSteps[step] = {
      ...updatedSteps[step],
      active: active !== undefined ? active : updatedSteps[step].active,
      completed:
        completed !== undefined ? completed : updatedSteps[step].completed,
    };
  }

  function onNextClick() {
    const updatedSteps = [...steps];
    if (totalSteps !== activeStep + 1) {
      // complete and go to next step
      updateStep(updatedSteps, activeStep, false, true);
      updateStep(updatedSteps, activeStep + 1, true);
      setActiveStep(activeStep + 1);
      setSteps(updatedSteps);
    } else {
      // complete the active step
      updateStep(updatedSteps, activeStep, true, true);
      setSteps(updatedSteps);
    }
  }

  function onBackClick() {
    const updatedSteps = [...steps];
    if (activeStep !== 0) {
      if (activeStep === totalSteps - 1 && steps[activeStep].completed) {
        // if last step and completed undo the current step without going back
        updateStep(updatedSteps, activeStep, true, false);
        setSteps(updatedSteps);
      } else {
        // go back a step
        updateStep(updatedSteps, activeStep, false, false);
        updateStep(updatedSteps, activeStep - 1, true, false);
        setActiveStep(activeStep - 1);
        setSteps(updatedSteps);
      }
    }
  }

  function handleStepClick(stepNum: number) {
    if (stepNum < activeStep) {
      // jump to the clicked step uncompleting everything else
      const updatedSteps = [...steps];
      updatedSteps.forEach((step, index) => {
        if (index > stepNum && index <= activeStep) {
          updateStep(updatedSteps, index, false, false);
        }
        if (index === stepNum) {
          updateStep(updatedSteps, index, true, false);
        }
      });
      setActiveStep(stepNum);
      setSteps(updatedSteps);
    }
  }

  function setDefaultSteps() {
    setActiveStep(0);
    steps.map((step, index) => {
      let active = false;
      if (index === 0) {
        active = true;
      }
      return {
        ...step,
        active,
        completed: false,
      };
    });
  }

  const totalSteps = steps.length;
  const lastStep = totalSteps - 1;

  const progress = ((activeStep + 1) / totalSteps) * 100;

  return {
    steps,
    activeStep,
    setDefaultSteps,
    onNextClick,
    onBackClick,
    handleStepClick,
    progress,
    lastStep,
  };
};

export default useStepper;
