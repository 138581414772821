import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { DateTime } from 'luxon';
import cn from 'classnames';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { env } from 'helpers/env';

import LoggedInUserInfo from 'components/LoggedInUserInfo';
import Clock from 'components/Clock';
import IconButton from '../IconButton';

import './Header.scss';

interface HeaderProps {
  onLoginPage: boolean;
  programId: string | null;
  setSelectedProgram: (id: string) => void;
}

const Header: FunctionComponent<HeaderProps> = ({
  onLoginPage,
  programId,
  setSelectedProgram,
}) => {
  const { programs, feeders, setCurrentFeeder, selectedFeeder } =
    useProgramsContext();
  const { toggleNavMenu } = useUserContext();
  const programOptions = programs.map(({ program_id, name }) => ({
    label: name,
    value: program_id,
  }));
  const feederOptions = feeders.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const time = DateTime.local();
  const { isNMF, isWSC } = env;
  const logoSrc = isNMF ? '/gridos_logo_white.svg' : '/gridos_logo_light.png';
  const logoSsen = isNMF ? '/SSEN_Logo_white.svg' : '/SSEN_Logo_inversed.svg';

  let siteTitle = '';
  if (isNMF) siteTitle = 'Neutral Market Facilitator (NMF)';
  else if (isWSC) siteTitle = 'Whole System Coordinator (WSC)';

  // Logic for which routes to show feeder selector.
  function displayFeederSelector(path: string) {
    if (new RegExp('/program/[a-zA-Z0-9-]*/settlement').test(path)) {
      return true;
    }
    return false;
  }

  const location = useLocation();
  const renderFeederSelector: boolean = displayFeederSelector(
    location.pathname
  );

  return (
    <div className={cn('header', { 'header--nmf': isNMF })}>
      <div className={cn('header__row', { 'header__row--nmf': isNMF })}>
        <div className="header__row-left">
          <img className="header__logo" alt="Logo" src={logoSrc} />
          <img className="header__logo" alt="Logo" src={logoSsen} />
          {siteTitle && <span className="header__row-title">{siteTitle}</span>}
        </div>
        <div className="header__row-right">
          {!onLoginPage && (
            <>
              <Clock datetime={time} />
              <LoggedInUserInfo
                classNames={{ loggedInUserInfoClass: 'header__user-info' }}
                lightText={isNMF}
              />
            </>
          )}
        </div>
      </div>
      <div className="header__row header__row-bottom">
        {!onLoginPage && (
          <IconButton
            icon="MenuIcon"
            onClick={() => toggleNavMenu()}
            iconClass="header__open-nav-icon"
            customClasses={{
              customButtonClass: 'header__open-nav',
            }}
          />
        )}
        <div className="header__row-left">
          {!onLoginPage && (
            <div className="header__program-select">
              <Select
                // Force a rerender of select component when ProgramID changes & goes to null
                key={`react-select__${programId}`}
                isClearable
                isMulti={false}
                options={programOptions}
                onChange={(option) => {
                  // @ts-ignore
                  setSelectedProgram(option ? option.value : null);
                }}
                placeholder="Select Program"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 10,
                  }),
                }}
                value={programOptions.find(
                  ({ value }) => value === programId || null
                )}
              />
            </div>
          )}
          {!onLoginPage && renderFeederSelector && (
            <div className="header__program-select">
              <Select
                // Force a rerender of select component when ProgramID changes & goes to null
                key={`react-select__${programId}`}
                isClearable
                isMulti={false}
                options={feederOptions}
                onChange={(option) => {
                  // @ts-ignore
                  setCurrentFeeder(option ? option.value : null);
                }}
                placeholder="Select Feeder"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 10,
                  }),
                }}
                value={feederOptions.find(
                  ({ value }) => value === selectedFeeder?.id || null
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
