import React from 'react';
import classes from 'classnames';

import Avatar from 'components/Avatar';

import './UserProfile.scss';

interface UserProfileProps {
  name: string;
  src?: string;
  subText?: string;
  darkSubText?: boolean;
  size?: 'small';
  classNames?: {
    profileClass?: string;
    nameClass?: string;
    subTextClass?: string;
    avatarClass?: string;
    avatarImageClass?: string;
  };
  styles?: {
    profileStyles?: React.CSSProperties;
    nameStyles?: React.CSSProperties;
    subTextStyles?: React.CSSProperties;
  };
}

function UserProfile({
  name,
  src,
  subText,
  darkSubText = false,
  size,
  classNames = {},
  styles = {},
}: UserProfileProps) {
  const {
    profileClass,
    nameClass,
    subTextClass,
    avatarClass,
    avatarImageClass,
  } = classNames;
  const { profileStyles, nameStyles, subTextStyles } = styles;

  return (
    <div
      className={classes(
        'user-profile',
        { [`user-profile--${size}`]: size },
        profileClass
      )}
      style={profileStyles}
    >
      <Avatar
        src={src}
        className={classes(
          'user-profile__avatar',
          { [`user-profile__avatar--${size}`]: size },
          avatarClass
        )}
        imageClass={classes(
          'user-profile__image',
          { [`user-profile__image--${size}`]: size },
          avatarImageClass
        )}
      />
      <div className="user-profile__details">
        <span
          className={classes(
            'user-profile__name',
            { [`user-profile__name--${size}`]: size },
            nameClass
          )}
          style={nameStyles}
        >
          {name}
        </span>
        {subText && (
          <span
            className={classes(
              'user-profile__subText',
              { 'user-profile__subText--dark': darkSubText },
              { [`user-profile__subText--${size}`]: size },
              subTextClass
            )}
            style={subTextStyles}
          >
            {subText}
          </span>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
