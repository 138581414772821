import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SunIcon = ({
  title,
  titleId,
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill={color} fillRule="nonzero">
      <path d="M9.8 14.6C7.2 14.6 5 12.5 5 9.8 5 7.2 7.1 5 9.8 5c2.6 0 4.8 2.1 4.8 4.8 0 2.6-2.2 4.8-4.8 4.8Zm0-8.3c-2 0-3.5 1.5-3.5 3.5s1.6 3.5 3.5 3.5c2 0 3.5-1.6 3.5-3.5 0-2-1.5-3.5-3.5-3.5ZM9.8 2.9c-.3 0-.6-.3-.6-.6V.6c0-.3.3-.6.6-.6s.6.3.6.6v1.7c0 .3-.3.6-.6.6ZM9.8 19.6c-.3 0-.6-.3-.6-.6v-1.7c0-.3.3-.6.6-.6s.6.3.6.6V19c0 .3-.3.6-.6.6ZM4.5 5.1c-.2 0-.3-.1-.4-.2L2.9 3.8c-.2-.2-.2-.6 0-.9.2-.3.6-.2.9 0L5 4.1c.2.2.2.6 0 .9-.2.1-.3.1-.5.1ZM16.3 16.9c-.2 0-.3-.1-.4-.2l-1.2-1.2c-.2-.2-.2-.6 0-.9.2-.2.6-.2.9 0l1.2 1.2c.2.2.2.6 0 .9-.2.1-.4.2-.5.2ZM2.3 10.4H.6c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h1.7c.3 0 .6.3.6.6s-.3.6-.6.6ZM19 10.4h-1.7c-.3 0-.6-.3-.6-.6s.3-.6.6-.6H19c.3 0 .6.3.6.6s-.3.6-.6.6ZM3.3 16.9c-.2 0-.3-.1-.4-.2-.2-.2-.2-.6 0-.9l1.2-1.2c.2-.2.6-.2.9 0 .3.2.2.6 0 .9l-1.2 1.2c-.2.1-.3.2-.5.2ZM15.1 5.1c-.2 0-.3-.1-.4-.2-.2-.2-.2-.6 0-.9l1.1-1.1c.2-.2.6-.2.9 0 .3.2.2.6 0 .9L15.5 5c-.1.1-.2.1-.4.1Z" />
    </g>
  </svg>
);

export default SunIcon;
