import React, { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  useAuthContext,
  useRequest,
} from '@opusonesolutions/gridos-app-framework';

import { useUserContext } from 'contexts/UserContext';

import fileExportSave from 'helpers/downloadFile';

import SVG from 'components/SVG';
import IconButton from 'components/IconButton';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';

import './TocModal.scss';

const TocModal = () => {
  const { logout } = useAuthContext();
  const [unreadToc, setUnreadToc] = useState(true);
  const { displayTOCModal, toggleTOCModal } = useUserContext();
  const { makeRequest: getTC } = useRequest(`/api/dsp/toc`);

  const [numPages, setNumPages] = useState<number>(1);
  // required to load the pdf properly.
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const downloadToc = async () => {
    await getTC({
      method: 'get',
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(data, headers, 'terms_and_conditions.pdf');
      },
      onError: undefined,
      toast: {
        error: 'Could not download terms and conditions.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000,
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const { makeRequest: acceptToc } = useRequest(`/api/dsp/users/current_user`);

  const userAcceptTOC = async () => {
    await acceptToc({
      method: 'put',
      body: { toc_accepted: true },
      blockRequest: undefined,
      onSuccess: (data: JSON, headers: Record<string, unknown>) => {
        toggleTOCModal();
      },
      onError: undefined,
      toast: {
        error: 'Could not accept terms and conditions.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000, // 2 min timeout
      responseType: 'json',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const onScroll = () => {
    if (scrollContainerRef && scrollContainerRef.current) {
      const scrollPercentage = Math.round(
        (scrollContainerRef.current.clientHeight /
          (scrollContainerRef.current.scrollHeight -
            scrollContainerRef.current.scrollTop)) *
          100
      );
      const bottom = scrollPercentage === 100;
      if (bottom) {
        setUnreadToc(false);
      }
    }
  };

  function renderTOC() {
    const elements: any = [];
    for (let index = 1; index <= numPages; index++) {
      elements.push(
        <Page
          scale={1}
          key={`TOC-page-${index}`}
          pageNumber={index}
          className="toc-modal__body--content-pdf-document-page"
        />
      );
    }
    return elements;
  }

  return (
    <Modal
      active={displayTOCModal}
      height="550px"
      width="683px"
      onClose={logout}
      cancelProps={{ label: 'Decline' }}
      confirmProps={{
        label: 'Accept',
        disabled: unreadToc,
        onClick: userAcceptTOC,
      }}
      hideClose
      reverseFooterButtons={true}
      alignFooterButtons="center"
      disableBackdropClick={true}
      classes={{ contentClass: 'toc-modal__content' }}
    >
      <div>
        <div className="toc-modal__header">
          <div className="toc-modal__title">
            <SVG icon="Edit3Icon" />
            <h3 className="toc-modal__title--copy">Terms and conditions</h3>
          </div>
          <IconButton
            icon="DownloadIcon"
            customClasses={{
              customButtonClass: 'toc-modal__title__block__download',
            }}
            onClick={() => downloadToc()}
          />
        </div>
        <div
          className="toc-modal__body"
          ref={scrollContainerRef}
          onScroll={onScroll}
        >
          <div className="toc-modal__body--content">
            <Document
              className="toc-modal__body--content-pdf-document"
              file="/terms_and_conditions.pdf"
              onLoadSuccess={(pdf) => setNumPages(pdf.numPages)}
            >
              {numPages && renderTOC()}
            </Document>
          </div>
        </div>
        <h6 className="toc-modal__disclaimer">
          Note: You will not be able to use the platform if you decline the
          terms and conditions.
        </h6>
      </div>
    </Modal>
  );
};

export default TocModal;
