import React, { useState } from 'react';
import classNames from 'classnames';

import './SVG.scss';

type IconParams = {
  icon: string;
  fontSize?: string;
  round?: boolean;
  color?: string;
  className?: string;
  styles?: React.CSSProperties;
};

/**
 * Handles generating material icons.
 */
function SVG({
  className,
  icon,
  fontSize,
  round = false,
  color,
  styles,
}: IconParams): React.ReactElement {
  const ImportedSVGRef =
    React.useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);
  const [isMaterial, setIsMaterial] = useState(false);

  const defaultIconClass = {
    fontSize: fontSize !== undefined ? fontSize : '',
    color,
    height: fontSize !== undefined ? fontSize : '',
    width: fontSize !== undefined ? fontSize : '',
    ...styles,
  };

  React.useEffect((): void => {
    const importSVG = async (icon: string): Promise<void> => {
      setLoading(true);
      try {
        ImportedSVGRef.current = (
          await import(`../../SVG/${icon}.tsx`)
        ).default;
      } catch (err) {
        try {
          ImportedSVGRef.current = (
            await import(`../../SVG/Icons/${icon}.tsx`)
          ).default;
        } catch (err) {
          setIsMaterial(true);
        } finally {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    };
    importSVG(icon);
  }, [icon]);

  if (loading) {
    return <div style={{ width: '1em', height: '1em', fontSize }} />;
  }

  if (!loading && ImportedSVGRef.current) {
    const { current: ImportedSVG } = ImportedSVGRef;
    return (
      <i
        className={classNames('svg', { 'svg--round': round }, className)}
        style={defaultIconClass}
      >
        <ImportedSVG
          fontSize={fontSize}
          color={color}
          className={classNames('svg__default', className)}
          style={styles}
        />
      </i>
    );
  }

  if (!loading && isMaterial) {
    return (
      <i
        className={classNames(
          'svg--material',
          'svg',
          { 'svg--round': round },
          className
        )}
        style={defaultIconClass}
      >
        {icon}
      </i>
    );
  }

  return <></>;
}

export default SVG;
