import React from 'react';
import { DateTime } from 'luxon';
import { TableInstance } from 'react-table';

import { jsDateToDateTimeProgramTimezone } from 'helpers/time';
// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'components/OldDatePicker';
import Select from 'components/OldSelect';
import IconButton from 'components/IconButton';

import './EventsTableFilters.scss';

type timelineOptions = {
  label: string;
  value: string;
};

export type EventsTableFilterProps = {
  filterDate: DateTime;
  type: 'test' | 'Intent to Dispatch';
  timelineFormat: timelineOptions[];
  setTimeline: (option: string) => void;
  filterStartTime: DateTime | undefined;
  filterEndTime: DateTime | undefined;
  downloadTable:
    | ((
        startTime?: DateTime | undefined,
        endTime?: DateTime | undefined
      ) => void)
    | undefined;
};

function EventsTableFilters({
  filterDate,
  type,
  timelineFormat,
  setTimeline,
  filterEndTime,
  filterStartTime,
  downloadTable,
}: EventsTableFilterProps) {
  return (instance: TableInstance) => {
    const { setFilter } = instance;
    return (
      <div className="events-table-filters">
        <div className="events-table-filters__filter new-look">
          <OldDatePicker
            date={filterDate}
            clearable={false}
            onClose={(d: DateTime) =>
              setFilter('startTime', { value: d, frame: 'day' })
            }
            showArrows={type === 'Intent to Dispatch' ? true : false}
            placeholder="Event Date"
            options={{
              enableSeconds: false,
              enableTime: false,
              formatDate: (date) => {
                const dt = jsDateToDateTimeProgramTimezone(date);
                return dt.toFormat('LLL d, yyyy');
              },
              minuteIncrement: 60,
            }}
            customClasses={{
              inputClass: 'events-table-filters__text',
            }}
            isControlled={false}
            useUTC={false}
            onClear={() =>
              setFilter('startTime', { value: undefined, frame: 'day' })
            }
          />
        </div>
        <div className="events-table-filters__filter events-table-filters__filter-select new-look">
          <Select
            placeholder="View"
            defaultValue={timelineFormat[0]}
            onChange={(e) => {
              setTimeline(e.value);
            }}
            isMulti={false}
            isClearable={false}
            options={timelineFormat}
            classes={{
              labelClass: 'events-table-filters__text--light',
            }}
          />
        </div>
        <div className="events-table-filters__filter new-look">
          <OldDatePicker
            date={filterStartTime}
            clearable={true}
            onClose={(d: DateTime) =>
              setFilter('startTime', { value: d, frame: 'startTime' })
            }
            placeholder="Start Time"
            showArrows={type === 'Intent to Dispatch' ? true : false}
            options={{
              defaultDate: undefined,
              noCalendar: true,
              formatDate: (date) => {
                const dt = jsDateToDateTimeProgramTimezone(date);
                return dt.toFormat('H:mm');
              },
              enableTime: true,
              minuteIncrement: 60,
            }}
            customClasses={{
              inputClass: 'events-table-filters__text',
            }}
            isControlled={false}
            useUTC={false}
            onClear={() =>
              setFilter('startTime', { value: undefined, frame: 'startTime' })
            }
          />
        </div>
        <div className="events-table-filters__filter new-look">
          <OldDatePicker
            date={filterEndTime}
            clearable={true}
            onClose={(d: DateTime) =>
              setFilter('startTime', { value: d, frame: 'endTime' })
            }
            placeholder="End Time"
            showArrows={type === 'Intent to Dispatch' ? true : false}
            options={{
              defaultDate: undefined,
              noCalendar: true,
              enableSeconds: false,
              enableTime: true,
              formatDate: (date) => {
                const dt = jsDateToDateTimeProgramTimezone(date);
                return dt.toFormat('H:mm');
              },
              time_24hr: true,
              minuteIncrement: 60,
            }}
            customClasses={{
              inputClass: 'events-table-filters__text',
            }}
            isControlled={false}
            useUTC={false}
            onClear={() =>
              setFilter('startTime', { value: undefined, frame: 'endTime' })
            }
          />
        </div>
        <div className="events-table-filters__actions">
          <div className="events-table-filters__actions-bottom">
            <IconButton icon="SearchIcon" onClick={() => {}} />
            {!!downloadTable && (
              <IconButton
                icon="DownloadIcon"
                onClick={() => {
                  downloadTable(filterStartTime, filterEndTime);
                }}
                tooltip=""
                disabled={!downloadTable}
              />
            )}
            <Select
              placeholder="Filter"
              defaultValue={{ value: 'filter', label: 'Filter' }}
              onChange={(e) => {}}
              isMulti={false}
              isClearable={false}
              options={undefined}
              classes={{
                labelClass: 'events-table-filters__text--light',
                wrapperClass:
                  'events-table-filters__actions-bottom-filter-select',
              }}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default EventsTableFilters;
