import React, { useState, SetStateAction, Dispatch } from 'react';
import { AxiosError } from 'axios';
import { apm, useRequest } from '@opusonesolutions/gridos-app-framework';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Tenant } from 'types/tenant';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Switch from 'components/Switch';

import { Routes } from 'routes/ParticipantsAndDers/Routes/Participants/Participants';

import './ParticipantCreate.scss';

export type ParticipantCreateProps = {
  setIsCreatingNewTenant: Dispatch<SetStateAction<boolean>>;
  setCurrentTenant: Dispatch<SetStateAction<Tenant | undefined>>;
  refetchTenants: () => void;
};

export const ParticipantCreate = ({
  setIsCreatingNewTenant,
  setCurrentTenant,
  refetchTenants,
}: ParticipantCreateProps) => {
  const history = useHistory();
  // @ts-ignore
  const [newTenant, setNewTenant] = useState<Tenant | null>({
    enable_p2n: true,
    enable_p2p: true,
    is_dso: false,
  });
  const [saving, setSaving] = useState(false);
  const [saveMessages, setSaveMessages] = useState<{
    [key: string]: Array<string>;
  }>({});

  const formComplete =
    newTenant?.name &&
    newTenant?.phone_number &&
    newTenant?.address_line_1 &&
    newTenant?.locality &&
    newTenant?.administrative_area &&
    newTenant?.country;

  const schema = () =>
    yup.object().shape({
      name: yup.string().required(),
      phoneNumber: yup.number().required(),
      streetAddress: yup.string().required(),
      locality: yup.string().required(),
      adminArea: yup.string().required(),
      postalCode: yup.string().required(),
      country: yup.string().required(),
    });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const disableCreate = () => {
    reset();
    setIsCreatingNewTenant(false);
    history.push('/participants-and-ders/participants');
  };

  const updateKey = (key: keyof Tenant, value: any) => {
    // @ts-ignore
    setNewTenant({
      ...newTenant,
      [key]: value,
    });
  };

  const { makeRequest: makeCreateTenantRequest } =
    useRequest('/api/dsp/tenants');

  const createTenant = async () => {
    setSaving(true);
    setSaveMessages({});
    await makeCreateTenantRequest({
      method: 'post',
      body: newTenant,
      toast: {
        success: `${newTenant?.name} is now a market participant`,
        error: `Error creating ${newTenant?.name || 'participant'}.`,
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: (tenant) => {
        setSaving(false);
        setIsCreatingNewTenant(false);
        refetchTenants();
        setCurrentTenant(undefined);
        history.push(
          `/participants-and-ders/participants/${tenant.id}/overview`
        );
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 400) {
          setSaveMessages(error.response.data.message);
        }
        apm.captureError(error);
        setSaving(false);
      },
    });

    setSaving(false);
  };

  function renderCreateMode() {
    return (
      <form
        id="create-new-tenant"
        onSubmit={handleSubmit(createTenant)}
        className="participant-overview__bottom-edit"
      >
        <Checkbox
          label="Participant is a DSO"
          checked={newTenant?.is_dso || false}
          onChange={() => updateKey('is_dso', !newTenant?.is_dso)}
        />
        <br />
        <div className="participant-create__label">Name</div>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="name"
              value={value}
              placeholder="Enter participant name"
              onChange={(value) => {
                updateKey('name', value);
                onChange(value);
              }}
              className="participant-overview__input"
              invalid={!!saveMessages.name || errors?.name?.message}
              validationMessage={
                saveMessages.name ? saveMessages.name[0] : errors?.name?.message
              }
              resetEditState={saving}
            />
          )}
        />
        <div className="participant-create__label">Phone number</div>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="phone_number"
              value={value}
              placeholder="Enter phone number"
              onChange={(value) => {
                updateKey('phone_number', value);
                onChange(value);
              }}
              className="participant-overview__input"
              resetEditState={saving}
              invalid={
                !!saveMessages.phoneNumber || errors?.phoneNumber?.message
              }
              validationMessage={
                saveMessages.name
                  ? saveMessages.phoneNumber[0]
                  : errors?.phoneNumber?.message
              }
            />
          )}
        />
        <div className="participant-create__label">Street address</div>
        <Controller
          name="streetAddress"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="address_line_1"
              value={value}
              placeholder="Enter street address"
              onChange={(value) => {
                updateKey('address_line_1', value);
                onChange(value);
              }}
              className="participant-overview__input"
              resetEditState={saving}
              invalid={errors?.streetAddress?.message}
              validationMessage={errors?.streetAddress?.message}
            />
          )}
        />
        <div className="participant-overview__row">
          <div>
            <div className="participant-create__label">City</div>
            <Controller
              name="locality"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  disabled={saving}
                  id="locality"
                  value={value}
                  placeholder="Enter city"
                  onChange={(value) => {
                    updateKey('locality', value);
                    onChange(value);
                  }}
                  className="participant-overview__input"
                  resetEditState={saving}
                  invalid={errors?.locality?.message}
                  validationMessage={errors?.locality?.message}
                />
              )}
            />
          </div>
          <div>
            <div className="participant-create__label participant-overview__input--horizontal">
              State or province
            </div>
            <Controller
              name="adminArea"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  disabled={saving}
                  id="administrative_area"
                  value={value}
                  placeholder="Enter state or province"
                  onChange={(value) => {
                    updateKey('administrative_area', value);
                    onChange(value);
                  }}
                  className="participant-overview__input participant-overview__input--horizontal"
                  resetEditState={saving}
                  invalid={errors?.adminArea?.message}
                  validationMessage={errors?.adminArea?.message}
                />
              )}
            />
          </div>
          <div>
            <div className="participant-create__label participant-overview__input--horizontal">
              Zip or Postal code
            </div>
            <Controller
              name="postalCode"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  disabled={saving}
                  id="postal_code"
                  value={value}
                  placeholder="Enter postal code"
                  onChange={(value) => {
                    updateKey('postal_code', value);
                    onChange(value);
                  }}
                  className="participant-overview__input participant-overview__input--horizontal"
                  resetEditState={saving}
                  invalid={errors?.postalCode?.message}
                  validationMessage={errors?.postalCode?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="participant-create__label">Country</div>
        <Controller
          name="country"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="country"
              value={value}
              placeholder="Enter country"
              onChange={(value) => {
                updateKey('country', value);
                onChange(value);
              }}
              className="participant-overview__input"
              resetEditState={saving}
              invalid={errors?.country?.message}
              validationMessage={errors?.country?.message}
            />
          )}
        />
        <div className="participant-create__service-type">
          <div className="participant-create__label participant-create__service-type-label">
            Service type
          </div>
          <Switch
            label="Peer to network"
            type="primary"
            id="p2n-switch"
            checked={newTenant?.enable_p2n || false}
            onClick={() => {
              updateKey('enable_p2n', !newTenant?.enable_p2n);
            }}
            styles={{
              switchStyles: { marginBottom: '8px' },
            }}
          />
          <Switch
            label="Peer to peer"
            type="primary"
            id="p2p-switch"
            checked={newTenant?.enable_p2p || false}
            onClick={() => {
              updateKey('enable_p2p', !newTenant?.enable_p2p);
            }}
          />
        </div>
        <div className="participant-overview__actions">
          <Button
            customStyles={{
              customButtonStyles: { minWidth: '132px' },
            }}
            onClick={createTenant}
            disabled={!formComplete}
          >
            Add participant
          </Button>
          <Button
            customStyles={{
              customButtonStyles: { minWidth: '132px' },
            }}
            variant="outlined"
            onClick={disableCreate}
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }

  return (
    <div className="participant-overview participant-overview--no-margin">
      <div className="participant-create__title">Add new participant</div>
      <Route exact path={Routes.create}>
        {renderCreateMode()}
      </Route>
    </div>
  );
};
