import { DateTime, Duration, DurationLikeObject } from 'luxon';

import { Program } from 'contexts/ProgramsContext';

import { DERType } from 'types/der';
import {
  flexContract,
  flexContractRaw,
  flexItemStatus,
  flexItemStatusRaw,
  flexRequest,
  flexRequestRaw,
  flexResponse,
  flexResponseRaw,
  flexType,
  peakType,
  peakTypeRaw,
  serviceHelperText,
  serviceType,
  serviceTypeRaw,
  ServiceWindow,
  ServiceWindowRaw,
  generationMethod,
  generationMethodRaw,
  enrollmentType,
} from 'types/contract-managment';
import { Tenant } from 'types/tenant';

/**
 * Determines the service used in the flex request.
 */
export function getServiceType(flexServiceType: serviceTypeRaw) {
  let service = serviceType.SustainPeakManagement;
  if (flexServiceType === serviceTypeRaw.SustainBilateral) {
    service = serviceType.SustainBilateral;
  }
  if (flexServiceType === serviceTypeRaw.SustainExportManagement) {
    service = serviceType.SustainExportManagement;
  }
  if (flexServiceType === serviceTypeRaw.MEC) {
    service = serviceType.MEC;
  }
  if (flexServiceType === serviceTypeRaw.MIC) {
    service = serviceType.MIC;
  }
  if (flexServiceType === serviceTypeRaw.DynamicPeakManagement) {
    service = serviceType.DynamicPeakManagement;
  }
  if (flexServiceType === serviceTypeRaw.SecurePeakManagement) {
    service = serviceType.SecurePeakManagement;
  }
  return service;
}

export function getServiceTypeRaw(flexServiceType: serviceType) {
  let service = serviceTypeRaw.SustainPeakManagement;
  if (flexServiceType === serviceType.SustainBilateral) {
    service = serviceTypeRaw.SustainBilateral;
  }
  if (flexServiceType === serviceType.SustainExportManagement) {
    service = serviceTypeRaw.SustainExportManagement;
  }
  if (flexServiceType === serviceType.MEC) {
    service = serviceTypeRaw.MEC;
  }
  if (flexServiceType === serviceType.MIC) {
    service = serviceTypeRaw.MIC;
  }
  if (flexServiceType === serviceType.DynamicPeakManagement) {
    service = serviceTypeRaw.DynamicPeakManagement;
  }
  if (flexServiceType === serviceType.SecurePeakManagement) {
    service = serviceTypeRaw.SecurePeakManagement;
  }
  return service;
}

const filtersTypesList = {
  p2p: [serviceTypeRaw.MEC, serviceTypeRaw.MIC],
  p2n: [
    serviceTypeRaw.SustainBilateral,
    serviceTypeRaw.SustainExportManagement,
    serviceTypeRaw.SustainPeakManagement,
    serviceTypeRaw.DynamicPeakManagement,
    serviceTypeRaw.SecurePeakManagement,
  ],
};

const getServiceEnrollment = (flexServiceType: serviceTypeRaw): string =>
  filtersTypesList.p2p.includes(flexServiceType)
    ? enrollmentType.P2P
    : enrollmentType.P2N;

/**
 * Determines the status of the flex request or flex response.
 */
function getStatus(
  flexItem: flexRequestRaw | flexResponseRaw | flexContractRaw
) {
  let evaluation: flexItemStatus = flexItemStatus.pending;
  if (flexItem.status === flexItemStatusRaw.processing)
    evaluation = flexItemStatus.processing;
  if (flexItem.status === flexItemStatusRaw.cancelled)
    evaluation = flexItemStatus.cancelled;
  if (flexItem.status === flexItemStatusRaw.rejected)
    evaluation = flexItemStatus.rejected;
  if (flexItem.status === flexItemStatusRaw.accepted)
    evaluation = flexItemStatus.accepted;
  if (flexItem.status === flexItemStatusRaw.expired)
    evaluation = flexItemStatus.expired;
  return evaluation;
}

function getGenerationMethod(method: generationMethodRaw) {
  if (method === generationMethodRaw.manual) {
    return generationMethod.manual;
  }
  if (method === generationMethodRaw.forecastBased) {
    return generationMethod.forecastBased;
  }

  return generationMethod.systemGenerated;
}

function hasActiveContract(
  contracts: flexContractRaw[] | undefined
): boolean | undefined {
  if (contracts) {
    return contracts.some(
      (contract) =>
        contract.status === flexItemStatusRaw.accepted ||
        contract.status === flexItemStatusRaw.pending
    );
  }
}

export function getServiceHelperText(type: peakType): serviceHelperText {
  if (type === peakType.Peak) {
    return serviceHelperText.Peak;
  } else if (type === peakType.OffPeak) {
    return serviceHelperText.OffPeak;
  } else if (type === peakType.Custom) {
    return serviceHelperText.Custom;
  }
  return serviceHelperText.twentyFourSeven;
}

function getStartAndEndTimes(serviceWindows: ServiceWindow[]): ServiceWindow {
  return {
    startTime: serviceWindows[0]?.startTime || DateTime.local(),
    endTime:
      serviceWindows[serviceWindows.length - 1]?.endTime || DateTime.local(),
  };
}

function convertISOToDatetime(serviceWindow: ServiceWindowRaw): ServiceWindow {
  return {
    startTime: DateTime.fromISO(serviceWindow.start_time),
    endTime: DateTime.fromISO(serviceWindow.end_time),
  };
}

/**
 * Finds the participant associated with the DER.
 * 1. Loops through each participants DER list until it finds the der based on rdf_id
 * 2. The for loop figures out the der type.
 */
const getTenantAndDerInfo = (tenants?: Tenant[], derRdfId?: string | null) => {
  const derInfo = {
    derRDFID: '',
    derType: DERType.undefined,
    derName: '',
  };
  const result = tenants?.find((tenant) =>
    tenant.ders.find((der) => {
      if (der.rdf_id === derRdfId) {
        derInfo.derRDFID = der.rdf_id;
        derInfo.derName = der.info.name;
        for (const _key in DERType) {
          const key = _key as keyof typeof DERType;
          if (DERType[key] === der.info.der_type) {
            derInfo.derType = DERType[key];
            break;
          }
        }
        return true;
      }
      return false;
    })
  );
  return { tenant: result, ...derInfo };
};

/**
 * Handles processing raw flex requests from the backend into a format usable by the front end.
 */
export function processRawFlexRequest(
  flexRequest: flexRequestRaw,
  tenants: Tenant[] | undefined,
  program: Program | null,
  hasContract?: boolean
): flexRequest {
  const { derName, derRDFID, derType } = getTenantAndDerInfo(
    tenants,
    flexRequest?.requestor_der_rdf_id
  );

  const requestor = tenants?.find(
    (tenant) => tenant.id === flexRequest.requestor_id
  );
  const serviceWindows = flexRequest.service_windows.map(convertISOToDatetime);
  const { startTime, endTime } = getStartAndEndTimes(serviceWindows);
  const lastUpdated = program
    ? DateTime.fromISO(flexRequest.updated_at).setZone(program.timezone)
    : DateTime.fromISO(flexRequest.updated_at);

  // Adding 1 day to duration to satisfy the inclusive start date as part of the duration
  const durationObject = Duration.fromMillis(
    endTime.toMillis() - startTime.toMillis()
  )
    .shiftTo('years', 'months', 'days', 'hours', 'minutes')
    .plus({ days: 1 });

  const pluralRules = new Intl.PluralRules('en-US');
  function pluralize(count: number, singular: string, plural: string) {
    const grammaticalNumber = pluralRules.select(count);
    switch (grammaticalNumber) {
      case 'one':
        return count + ' ' + singular;
      case 'other':
        return count + ' ' + plural;
      default:
        return count + ' ' + plural;
    }
  }

  const formatDuration = (durationObj: DurationLikeObject) => {
    const { years, months, days, hours, minutes } = durationObj;
    const parts = [];
    if (years) parts.push(pluralize(years, 'year', 'years'));
    if (months) parts.push(pluralize(months, 'month', 'months'));
    if (days) parts.push(pluralize(days, 'day', 'days'));
    if (hours) parts.push(pluralize(hours, 'hour', 'hours'));
    if (minutes) parts.push(pluralize(minutes, 'minute', 'minutes'));

    return parts.join(' ');
  };

  const duration = formatDuration(durationObject.toObject());

  let peak: peakType = peakType.OffPeak;
  if (flexRequest.peak_type === peakTypeRaw.twentyFourSeven) {
    peak = peakType.twentyFourSeven;
  }
  if (flexRequest.peak_type === peakTypeRaw.Peak) peak = peakType.Peak;
  if (flexRequest.peak_type === peakTypeRaw.Custom) peak = peakType.Custom;

  const service = getServiceType(flexRequest.service_type);
  const serviceEnrollment = getServiceEnrollment(flexRequest.service_type);
  const status: flexItemStatus = getStatus(flexRequest);
  const method: generationMethod = getGenerationMethod(
    flexRequest.generation_method
  );

  const activeContract = hasContract
    ? hasContract
    : hasActiveContract(flexRequest.contracts);

  let flex: flexType = flexType.BID;
  if (flexRequest.flex_type === 'OFFER') flex = flexType.OFFER;

  return {
    id: flexRequest.id,
    derId: flexRequest.requestor_der_rdf_id,
    startTime,
    endTime,
    peak,
    duration,
    flex,
    quantity: flexRequest.quantity,
    availability: flexRequest.availability_price_ceiling,
    utilization: flexRequest.utilization_price_ceiling,
    service,
    serviceEnrollment,
    requestorName: requestor ? requestor.name : '',
    requestorImage: '',
    requestor: requestor,
    status,
    expiryDate: program
      ? DateTime.fromISO(flexRequest.close_time).setZone(program.timezone)
      : DateTime.fromISO(flexRequest.close_time),
    participantId: flexRequest.participant_id,
    feederId: flexRequest.feeder_id,
    primary: flexRequest.primary,
    customPeakDays: flexRequest.custom_peak_days,
    hasActiveContract: activeContract,
    lastUpdated: lastUpdated,
    serviceWindows,
    generationMethod: method,
    hours: flexRequest?.hours,
    openTime: flexRequest?.open_time
      ? program
        ? DateTime.fromISO(flexRequest.open_time).setZone(program.timezone)
        : DateTime.fromISO(flexRequest.open_time)
      : undefined,
    derName,
    derRDFID,
    derType,
  };
}

/**
 * Handles processing raw flex requests from the backend into a format usable by the front end.
 */
export function processRawFlexResponse(
  flexResponse: flexResponseRaw,
  tenants: Tenant[] | undefined,
  request: flexRequest,
  excludeRequest?: boolean
): flexResponse {
  const { tenant, derName, derRDFID, derType } = getTenantAndDerInfo(
    tenants,
    flexResponse?.der_rdf_id
  );

  const status: flexItemStatus = getStatus(flexResponse);

  const processedResponse: flexResponse = {
    id: flexResponse.id,
    quantity: flexResponse.quantity,
    availability: flexResponse.availability_price,
    utilization: flexResponse.utilization_price,
    acceptance: 'Full',
    derRDFID,
    derType,
    derName,
    responderName: tenant ? tenant.name : '',
    responderImage: '',
    responder: tenant,
    status,
    serviceType: request.service,
  };

  if (!excludeRequest) {
    processedResponse['request'] = request;
  }

  return processedResponse;
}

export function processRawFlexContract(
  contract: flexContractRaw,
  tenants: Tenant[] | undefined,
  program: Program | null
): flexContract {
  const request = processRawFlexRequest(
    { ...contract.request, primary: contract.primary },
    tenants,
    program,
    true
  );
  const response = processRawFlexResponse(
    contract.response,
    tenants,
    request,
    true
  );
  const updatedAt = program
    ? DateTime.fromISO(contract.updated_at).setZone(program.timezone)
    : DateTime.fromISO(contract.updated_at);

  return {
    feederID: contract.feeder_id,
    id: contract.id,
    programID: contract.program_id,
    request,
    response,
    status: getStatus(contract),
    updates: contract.updates || null,
    updatesCount: contract.updates?.length || 0,
    updatedAt,
    primary: contract.primary,
  };
}
