import React from 'react';
import { useHistory, useRouteMatch, match } from 'react-router';
import cn from 'classnames';

import Button from 'components/Button';

import './NavButtons.scss';

export type Route = {
  path: string;
  label: string;
  useIsExact?: boolean;
};

export type NavButtonProps = {
  routes: Route[];
  baseRoute: string;
  classNames?: {
    navClass?: string;
    wrapperClass?: string;
    buttonClass?: string;
  };
};

export function NavButtons({
  routes,
  baseRoute,
  classNames = {},
}: NavButtonProps) {
  const history = useHistory();

  const { navClass, wrapperClass, buttonClass } = classNames;

  const navigate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const buttonID = e.currentTarget.id;
    history.push(`${baseRoute}/${buttonID}`);
  };

  function RenderButton(route: Route) {
    const { path, label, useIsExact } = route;
    let isActive: match<Record<string, never>> | null | boolean = useRouteMatch(
      `${baseRoute}/${path}`
    );
    if (useIsExact && isActive) {
      isActive = isActive.isExact;
    }
    return (
      <div
        key={label}
        className={cn(
          'nav-buttons__wrapper',
          {
            'nav-buttons--active': isActive,
          },
          wrapperClass
        )}
      >
        <Button
          id={path}
          variant="text"
          onClick={navigate}
          disabledAnimation
          customClasses={{
            customButtonClass: cn(
              'nav-buttons__button',
              {
                'nav-buttons__button--active': isActive,
              },
              buttonClass
            ),
          }}
        >
          {label}
        </Button>
      </div>
    );
  }

  return (
    <div className={cn('nav-buttons', navClass)}>
      {routes.map((route) => RenderButton(route))}
    </div>
  );
}
