import React from 'react';
import classes from 'classnames';
import { FieldValues, useForm, UseFormReset } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { DER } from 'types/der';

import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Link from 'components/Link';
import ErrorMessage from 'components/ErrorMessage';
import SVG from 'components/SVG';
import ModalSection from 'routes/LongTermContracts/components/ModalSection';

import './MeasurementUploadForm.scss';

enum Fields {
  Files = 'files',
}

const schema = Yup.object().shape({
  [Fields.Files]: Yup.object()
    .test(
      'has-file',
      'You must upload at least one file.',
      (value: FilesField): boolean => {
        return Object.values(value || {}).some((val) => val && val.length > 0);
      }
    )
    .nullable(),
});

type FilesField = {
  [derRdfId: string]: FileList | [];
};

type Props = {
  ders: DER[];
  loading: boolean;
  onSubmit: (data: any, reset: UseFormReset<FieldValues>) => void;
  onCancel: () => void;
};

function inputCanShow(fileWatcher: FilesField, id: string): boolean {
  return (
    !fileWatcher ||
    (fileWatcher && !fileWatcher[id]) ||
    (fileWatcher && fileWatcher[id] && fileWatcher[id].length < 1)
  );
}

const MeasurementUploadForm: React.FC<Props> = ({
  ders,
  loading,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const fileWatcher: FilesField = watch(Fields.Files);

  const removeFilesFromDer = (derId: string) => {
    setValue(`${Fields.Files}[${derId}]`, null, { shouldValidate: true });
    trigger(Fields.Files);
  };

  const addFilesToDer = (derId: string, files: FileList | null) => {
    setValue(`${Fields.Files}[${derId}]`, files, { shouldValidate: true });
    trigger(Fields.Files);
  };

  const submit = (data: { [x: string]: any }) => {
    const filtered: FilesField = {};
    for (const key in data.files) {
      if (data.files[key] && data.files[key].length > 0) {
        filtered[key] = data.files[key];
      }
    }
    onSubmit(filtered, reset);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="measurement-upload-form">
      <ModalSection
        title="Measurement Data"
        icon={
          <SVG
            icon="Edit3Icon"
            className="measurement-upload-form__panel-icon"
          />
        }
      >
        <div className="measurement-upload-form__top-row">
          <span className="measurement-upload-form__subtitle">Your DERs</span>
          <Link
            href={`${process.env.PUBLIC_URL}/measurement-example.csv`}
            rel="noreferrer"
            download
            target="_blank"
          >
            Download template file
          </Link>
        </div>
        <div className="measurement-upload-form__ders">
          {(ders || []).map((der) => {
            const showInput = inputCanShow(fileWatcher, der.rdf_id);
            return (
              <div key={der.id} className="measurement-upload-form__row">
                <div className="measurement-upload-form__half">
                  <span className="measurement-upload-form__form-fieldname">
                    {der.info.name}
                  </span>
                </div>

                <div className="measurement-upload-form__half">
                  <label
                    htmlFor={der.rdf_id}
                    className={classes({
                      'measurement-upload-form__form-label': showInput,
                      'measurement-upload-form__hide': !showInput,
                    })}
                  >
                    <span className="measurement-upload-form__form-icon-wrap">
                      <SVG
                        icon="DownloadIcon"
                        className="measurement-upload-form__form-icon"
                      />
                    </span>
                    <input
                      className="measurement-upload-form__form-input"
                      id={der.rdf_id}
                      type="file"
                      multiple
                      accept=".csv"
                      onChange={(event) => {
                        addFilesToDer(der.rdf_id, event.target.files);
                      }}
                      onClick={(event) => {
                        event.currentTarget.value = '';
                      }}
                    />
                  </label>
                  <span className="measurement-upload-form__file">
                    {fileWatcher && fileWatcher[der.rdf_id]
                      ? [...fileWatcher[der.rdf_id]].map((file: File) => (
                          <span
                            key={file.name}
                            className="measurement-upload-form__file-single"
                          >
                            {file.name}
                            <br />
                          </span>
                        ))
                      : ''}
                  </span>
                  {!showInput && (
                    <IconButton
                      icon="DeleteIcon"
                      customClasses={{
                        customButtonClass: 'measurement-upload-form__delete',
                      }}
                      fontSize="12px"
                      onClick={() => removeFilesFromDer(der.rdf_id)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {errors && errors.files && (
          <ErrorMessage>{errors.files.message}</ErrorMessage>
        )}
      </ModalSection>
      <div className="measurement-upload-form__buttons">
        <Button
          variant="outlined"
          title="Cancel"
          htmlButtonType="button"
          onClick={() => {
            ders.map((der) => {
              removeFilesFromDer(der.rdf_id);
              return null;
            });
            onCancel();
          }}
          loading={loading}
        >
          Cancel
        </Button>
        <Button title="Submit" htmlButtonType="submit" loading={loading}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default MeasurementUploadForm;
