import React from 'react';
import { Route } from 'react-router-dom';

import { useUserContext } from 'contexts/UserContext';

import RouterSwitch from 'components/RouterSwitch';
import Program from './Program';
import Enrollment from './routes/Enrollment';
import OperationalView from './routes/OperationalView';
import Reports from './routes/Reports';
import Settings from './routes/Settings';
import LongTermContracts from 'routes/LongTermContracts';
import MarketSchedules from 'routes/MarketSchedules';
import Settlement from 'routes/Settlement';
import RequestsResponses from 'routes/RequestsResponses';

import { env } from 'helpers/env';

const ProgramContainer = () => {
  const isFlexAuction = env.isNMF || env.isWSC;
  const { userIsDso, userHasTenant } = useUserContext();

  return (
    <RouterSwitch>
      <Route exact path="/program/:programID">
        <Program />
      </Route>
      {userHasTenant() && (
        <Route path="/program/:programID/enrollment">
          <Enrollment />
        </Route>
      )}
      {isFlexAuction && (
        <Route path="/program/:programID/long-term-contracts">
          <LongTermContracts />
        </Route>
      )}
      {isFlexAuction && (
        <Route path="/program/:programID/requests-responses">
          <RequestsResponses />
        </Route>
      )}
      <Route path="/program/:programID/schedules">
        <MarketSchedules />
      </Route>
      <Route exact path="/program/:programID/live">
        <OperationalView />
      </Route>
      <Route path="/program/:programID/reports">
        <Reports />
      </Route>
      {userIsDso() && (
        <Route path="/program/:programID/settings">
          <Settings />
        </Route>
      )}
      <Route path="/program/:programID/settlement">
        <Settlement />
      </Route>
    </RouterSwitch>
  );
};

export default ProgramContainer;
