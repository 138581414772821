import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { Program, useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { getCurrencySymbol } from 'helpers/locale';
import { getTimeGateDateTime } from 'helpers/time';

import {
  ContractModelOptions,
  feederOption,
  flexTypeRaw,
  generationMethodRaw,
  serviceTypeRaw,
  siaListItem,
} from 'types/contract-managment';

import Stepper, { useStepper } from 'components/Stepper';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import DialogHeader from 'components/Dialog/DialogHeader';
import DialogFooter from 'components/Dialog/DialogFooter';
import DialogBody from 'components/Dialog/DialogBody';

import CreateRequestSetup from './CreateRequestSetup';
import CreateRequestService from './CreateRequestService';
import CreateRequestQuantity from './CreateRequestQuantity';
import CreateRequestReview from './CreateRequestReview';

import {
  CreateRequestProps,
  CreateRequestFormData,
} from './CreateRequest.types';
import { minDate } from './CreateRequestService/CreateRequestService.logic';
import {
  defaultFormState,
  generateServiceWindows,
  responseAssetsFiltered,
  setIndustryActors,
  defaultSteps,
  getFormattedTime,
  getDateTimeFromDateString,
} from './CreateRequest.logic';

import './CreateRequest.scss';

const CreateRequest = ({
  open,
  onClose,
  programID,
  currentUser,
  tenants,
  refreshTrigger,
  setJobId,
  defaultValues,
  isUpdate = false,
}: CreateRequestProps) => {
  const { selectedProgram, feeders } = useProgramsContext();
  const [formState, setFormState] = useState<CreateRequestFormData>(
    defaultValues || defaultFormState
  );

  const { userIsDso, getUserTenantId } = useUserContext();
  const { addToast } = useToasts();

  const program = selectedProgram as Program;
  const isDso = userIsDso();
  const { currencyFormatter } = useLocaleFormatter(
    program?.currency,
    program?.locale
  );
  const currencySymbol = getCurrencySymbol(currencyFormatter);
  const industryActors = setIndustryActors(tenants, currentUser.id);

  const {
    steps,
    activeStep,
    onNextClick,
    onBackClick,
    handleStepClick,
    progress,
    lastStep,
    setDefaultSteps,
  } = useStepper({
    defaultSteps,
  });

  const { makeRequest: submitRequest } = useRequest(
    `/api/dsp/program/${programID}/flex/request`
  );

  const { makeRequest: updateRequest } = useRequest(
    `/api/dsp/program/${programID}/flex/requests/${defaultValues?.id}`
  );

  const feederList: feederOption[] = feeders.reduce(
    (feederList: feederOption[], feeder) => {
      if (feeder.enrolled) {
        feederList.push({
          label: feeder.name,
          value: feeder.id,
          primaries: feeder.primaries,
        });
      }
      return feederList;
    },
    [] as feederOption[]
  );

  const {
    hours,
    quantity,
    feeder,
    primary,
    startDate,
    endDate,
    serviceDays,
    startTime,
    endTime,
    contractModel,
    industryActor,
    availabilityPrice,
    utilizationPrice,
    bidOffer,
    distributedResource,
    openDate,
    closeDate,
    serviceType,
    serviceWindow,
    quantityGeneration,
    includeActive,
    includePending,
    procurementVolume,
  } = formState;

  useEffect(() => {
    if (!hours && !quantity) {
      setFormState((prev) => ({
        ...prev,
        systemCalculate: false,
      }));
    }
  }, [hours, quantity, setFormState]);

  const onCloseClick = () => {
    setFormState(defaultFormState);
    setDefaultSteps();
    onClose();
  };

  const onFieldChangeHandler = (
    name: keyof CreateRequestFormData,
    value: any
  ) => {
    switch (name) {
      case 'openDate' ||
        'closeDate' ||
        'openTime' ||
        'closeTime' ||
        'startDate' ||
        'startTime' ||
        'endDate' ||
        'endTime':
        setFormState((prev) => ({
          ...prev,
          [name]: getFormattedTime(value),
        }));
        break;
      case 'availabilityPrice' || 'utilizationPrice':
        setFormState((prev) => ({
          ...prev,
          [name]: value || 0,
        }));
        break;
      default:
        setFormState((prev) => ({
          ...prev,
          [name]: value,
        }));
        break;
    }
  };

  const isMEC =
    !isDso && (!serviceType || serviceType?.value === serviceTypeRaw.MEC);

  const onSetupSubmit = (data: { [x: string]: any }) => {
    const {
      closeDate,
      closeAfter,
      openDate,
      openTime,
      closeTime,
      recurrence,
      quantityGeneration,
    } = data;

    const newOpenTime = getDateTimeFromDateString(openTime);
    const newOpenDate = getDateTimeFromDateString(openDate).startOf('day').set({
      hour: newOpenTime.hour,
      minute: newOpenTime.minute,
    });
    const newCloseTime = getDateTimeFromDateString(closeTime);
    const newCloseDate = getDateTimeFromDateString(closeDate)
      .startOf('day')
      .set({
        hour: newCloseTime.hour,
        minute: newCloseTime.minute,
      });
    const deadlineDate =
      (!serviceType && isDso) || !isMEC
        ? newCloseDate
        : minDate(
            newCloseDate || DateTime.local(),
            getTimeGateDateTime(program)
          );

    setFormState((prev) => ({
      ...prev,
      openDate: newOpenDate,
      closeDate: newCloseDate,
      openTime: newOpenTime,
      closeTime: newCloseTime,
      closeAfter,
      recurrence,
      quantityGeneration,
      startDate: startDate ? startDate : deadlineDate,
      endDate: endDate
        ? endDate
        : deadlineDate.startOf('day').plus({ days: 1 }),
      distributedResource: distributedResource,
    }));

    onNextClick();
  };

  const onServiceSubmit = (data: { [x: string]: any }) => {
    const {
      startDate: newStartDate,
      startTime: newServiceStartTime,
      endDate: newEndDate,
      endTime: newServiceEndTime,
      contractModel: newContractModel,
      feeder: newFeeder,
      primary: newPrimary,
      industryActor: newIA,
      serviceWindow: newServiceWindow,
      serviceDays: newServiceDays,
      serviceType: newServiceType,
      bidOffer: newBidOffer,
    } = data;

    const newStartTime = DateTime.fromJSDate(newServiceStartTime);
    const newServiceStartDate = DateTime.fromJSDate(newStartDate)
      .startOf('day')
      .set({
        hour: newStartTime.hour,
        minute: newStartTime.minute,
      });
    const newEndTime = DateTime.fromJSDate(newServiceEndTime);
    const newServiceEndDate = DateTime.fromJSDate(newEndDate)
      .startOf('day')
      .set({
        hour: newEndTime.hour,
        minute: newEndTime.minute,
      });

    setFormState((prev) => ({
      ...prev,
      feeder: newFeeder,
      primary: newPrimary,
      industryActor: newIA,
      serviceType: newServiceType,
      serviceWindow: newServiceWindow,
      serviceDays: newServiceDays,
      contractModel: newContractModel,
      bidOffer: newBidOffer,
      startDate: newServiceStartDate,
      startTime: newStartTime,
      endDate: newServiceEndDate,
      endTime: newEndTime,
    }));

    onNextClick();
  };

  const onQuantitySubmit = (
    data: { [x: string]: any },
    isCalculated: boolean
  ) => {
    const {
      availabilityPrice: newAvailabilityPrice,
      utilizationPrice: newUtilizationPrice,
      quantity: newQuantity,
      hours: newHours,
    } = data;
    const negQuantity =
      serviceType?.value === serviceTypeRaw.SustainExportManagement;

    setFormState((prev) => ({
      ...prev,
      availabilityPrice: newAvailabilityPrice || 0,
      utilizationPrice: newUtilizationPrice || 0,
      quantity: negQuantity ? newQuantity * -1 : newQuantity,
      hours: newHours,
      isCalculated,
    }));

    onNextClick();
  };

  const onFlexRequestSubmit = () => {
    const $kwhTo$Wh = (value: number | null): number =>
      value ? value / 1000 : 0;
    const kwToW = (value: number): number => value * 1000;

    const requestorId = getUserTenantId();
    const participantId =
      contractModel === ContractModelOptions.Direct
        ? industryActor?.value
        : null;
    const convertedQuantity = quantity ? kwToW(quantity) : 1;
    const convertedAvailabilityPriceCeiling = $kwhTo$Wh(availabilityPrice);
    const convertedUtilizationPriceCeiling = $kwhTo$Wh(utilizationPrice);

    let serviceWindows: { start_time: DateTime; end_time: DateTime }[] = [];
    if (serviceDays && startTime && startDate && endTime && endDate) {
      serviceWindows = generateServiceWindows(
        startDate,
        endDate,
        serviceDays,
        startTime,
        endTime
      );
    }

    const toastList = {
      default: {
        text: 'Request created successfully.',
        appearance: 'success',
      },
      systemCalculated: {
        text: 'Buy request created. Request quantity is generating. Please check back later.',
        appearance: 'warning',
      },
    };

    const currentToast =
      toastList[
        quantityGeneration === generationMethodRaw.forecastBased
          ? 'systemCalculated'
          : 'default'
      ];

    const bodyData = {
      flex_type: bidOffer,
      requestor_id: requestorId,
      participant_id: participantId,
      requestor_der_rdf_id: distributedResource?.value,
      open_time: openDate?.toJSDate().toISOString(),
      close_time: closeDate?.toJSDate().toISOString(),
      service_type: serviceType?.value,
      peak_type: serviceWindow,
      quantity: convertedQuantity,
      hours,
      availability_price_ceiling: convertedAvailabilityPriceCeiling,
      utilization_price_ceiling: convertedUtilizationPriceCeiling,
      generation_method: quantityGeneration || generationMethodRaw.manual,
      feeder_id: feeder?.value,
      primary_id: primary?.value,
      custom_peak_days: serviceDays,
      service_windows: serviceWindows,
      use_active_contracts: includeActive,
      use_pending_contracts: includePending,
      volume_share_coefficient: procurementVolume,
      ...(isUpdate && { post_date: defaultValues?.postDate }),
    };

    const requestOptions = {
      onSuccess: (data: any) => {
        setJobId(data.job_id);
        const { value, setter } = refreshTrigger;
        addToast(currentToast.text, {
          appearance: currentToast.appearance as AppearanceTypes,
        });
        setter(!value);
        onCloseClick();
      },
      onError: (error: any) => {
        addToast(
          error?.response?.data.message ||
            `Error ${
              isUpdate ? 'amending' : 'creating'
            } request. Please check that all fields are correct.`,
          { appearance: 'error', autoDismiss: true }
        );
      },
    };

    if (isUpdate) {
      return updateRequest({
        method: 'put',
        body: bodyData,
        ...requestOptions,
      });
    }

    submitRequest({
      method: 'post',
      body: bodyData,
      ...requestOptions,
    });
  };

  const submitForm = (): string => {
    if (activeStep === 0) return 'create-request-setup';
    if (activeStep === 1) return 'create-request-service';
    if (activeStep === 2) return 'create-request-quantity';
    if (activeStep === 3) return 'create-request-review';
    return 'invalid-form';
  };

  const serviceWindowData =
    startDate &&
    endDate &&
    serviceDays &&
    startTime &&
    endTime &&
    generateServiceWindows(startDate, endDate, serviceDays, startTime, endTime);

  const { data: responseAssets } = useRequestEffect({
    url: `/api/dsp/program/${programID}/available_der_by_windows`,
    method: 'post',
    refetchOnChange: [startTime, endTime, startDate, endDate, serviceDays],
    blockRequest: () => !serviceWindowData || !isMEC,
    body: { service_windows: serviceWindowData },
    dataTransform: (data: any) => data,
  });

  const { data: siaList } = useRequestEffect<siaListItem>({
    url: `/api/measurement/program/${programID}/sia_forecasts`,
    method: 'get',
    refetchOnChange: [programID],
    blockRequest: () => !programID,
    dataTransform: (data) => data || [],
  });

  const contentList = [
    {
      content: (
        <CreateRequestSetup
          isDso={isDso}
          program={program}
          onSubmit={onSetupSubmit}
          data={formState}
          onFieldChange={onFieldChangeHandler}
          siaList={siaList}
        />
      ),
    },
    {
      content: (
        <CreateRequestService
          responseAssets={responseAssetsFiltered(
            responseAssets,
            feeder,
            serviceType,
            isDso
          )}
          program={program}
          feederList={(feederList as feederOption[]) || []}
          availableIndustryActors={industryActors}
          onSubmit={onServiceSubmit}
          data={formState}
          onFieldChange={onFieldChangeHandler}
          siaList={siaList}
        />
      ),
    },
    {
      content: (
        <CreateRequestQuantity
          currencySymbol={currencySymbol}
          onSubmit={onQuantitySubmit}
          data={formState}
          onFieldChange={onFieldChangeHandler}
          feederID={formState.feeder?.value}
          programID={programID}
        />
      ),
    },
    {
      content: (
        <CreateRequestReview data={formState} currencySymbol={currencySymbol} />
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      progress={progress}
      onClose={onClose}
      classNames={{ contentClass: 'create-request' }}
    >
      <DialogHeader
        title={`${isUpdate ? 'Amend' : 'Create'} Request`}
        onClose={onClose}
      />
      <DialogBody padding="left-aligned">
        <div className="create-request__body">
          <Stepper
            id="create-flex-request-stepper"
            steps={steps}
            direction="horizontal"
            onStepClick={handleStepClick}
            classNames={{
              stepperClass: 'create-request__stepper',
            }}
          >
            {contentList[activeStep]?.content}
          </Stepper>
        </div>
      </DialogBody>
      <DialogFooter>
        {activeStep !== 0 && (
          <Button
            variant="outlined"
            onClick={onBackClick}
            customClasses={{
              customButtonClass:
                'create-request__action create-request__action-back',
            }}
          >
            Back
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={onCloseClick}
          customClasses={{
            customButtonClass: 'create-request__action',
          }}
        >
          Cancel
        </Button>
        <Button
          customClasses={{
            customButtonClass: 'create-request__action',
          }}
          form={activeStep === lastStep ? undefined : submitForm()}
          onClick={activeStep === lastStep ? onFlexRequestSubmit : undefined}
          htmlButtonType={activeStep === lastStep ? 'button' : 'submit'}
        >
          {activeStep === lastStep
            ? `${isUpdate ? 'Amend' : 'Create'} ${
                bidOffer === flexTypeRaw.BID ? 'buy' : 'sell'
              } request`
            : 'Next'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default CreateRequest;
