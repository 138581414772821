/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from 'react';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';

import { flexRequest, flexResponse } from 'types/contract-managment';
import { CurrentUser } from 'types/user';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'routes/LongTermContracts/components/Modal';
import ResponsesTable from 'routes/RequestsResponses/ResponsesTable';

import './AcceptRejectResponsesModal.scss';

/**
 * Handles generating the accept/reject responses modal.
 */
function AcceptRejectResponsesModal({
  programID,
  loggedInUser,
  request,
  requestID,
  responses,
  selectedResponses,
  acceptingResponses,
  rejectingResponses,
  setSelectedResponses,
  setAcceptingResponses,
  setRejectingResponses,
  setSubmittingResponses,
}: {
  programID: string;
  loggedInUser: CurrentUser;
  request: flexRequest;
  requestID: string;
  responses: flexResponse[];
  selectedResponses: string[];
  acceptingResponses: boolean;
  rejectingResponses: boolean;
  setSelectedResponses: (value: Array<string>) => void;
  setAcceptingResponses: (value: boolean) => void;
  setRejectingResponses: (value: boolean) => void;
  setSubmittingResponses: (value: boolean) => void;
}) {
  const { addToast } = useToasts();
  const [preselectedResponses, setPreselectedResponses] = useState<
    flexResponse[]
  >([]);

  /* ===== HELPER FUNCTIONS ===== */
  const selectedQuantityDelta = useMemo(() => {
    const requestedQuantity = Number(request?.quantity);
    let selectedQuantity = 0;
    selectedResponses.forEach((responseId) => {
      selectedQuantity += Number(
        responses?.find((response) => response.id === responseId)?.quantity
      );
    });
    return selectedQuantity - requestedQuantity;
  }, [request?.quantity, responses, selectedResponses]);

  useEffect(() => {
    if (!acceptingResponses && !rejectingResponses)
      setPreselectedResponses(
        responses.filter((response) => selectedResponses.includes(response.id))
      );
  }, [acceptingResponses, rejectingResponses, responses, selectedResponses]);

  /* ===== API CALLS ===== */

  const { makeRequest: submitSelectedResponses } = useRequest(
    `/api/dsp/program/${programID}/flex/accept`
  );

  const acceptSelectedResponses = async () =>
    submitSelectedResponses({
      method: 'post',
      body: {
        request_id: requestID,
        response_ids: selectedResponses.map((v, i) => {
          return v;
        }),
      },
      onSuccess: () => {
        setSelectedResponses([]);
        setSubmittingResponses(false);
      },
      onError: () => {
        setSubmittingResponses(false);
      },
      toast: {
        error: 'Error accepting responses.',
        success: 'Responses accepted.',
        settings: {
          autoDismiss: true,
        },
      },
    });

  const { makeRequest: submitRejectedResponses } = useRequest(
    `/api/dsp/program/${programID}/flex/reject`
  );

  const rejectSelectedResponses = async () =>
    submitRejectedResponses({
      method: 'post',
      body: {
        request_id: requestID,
        response_ids: selectedResponses.map((v, i) => {
          return v;
        }),
      },
      onSuccess: () => {
        setSelectedResponses([]);
        setSubmittingResponses(false);
      },
      onError: (error) => {
        setSubmittingResponses(false);
        addToast(
          error?.response?.data.message || 'Error rejecting responses.',
          { appearance: 'error' }
        );
      },
      toast: {
        success: 'Responses have been rejected.',
        settings: { autoDismiss: true },
      },
    });

  /* ===== RENDER FUNCTIONS ===== */

  return (
    <Modal
      active={acceptingResponses || rejectingResponses}
      reverseFooterButtons={true}
      confirmProps={{
        disabled: selectedResponses.length < 1,
        label: acceptingResponses ? 'Accept' : 'Reject',
        onClick: () => {
          setSubmittingResponses(true);
          acceptingResponses
            ? acceptSelectedResponses()
            : rejectSelectedResponses();
          setAcceptingResponses(false);
          setRejectingResponses(false);
        },
      }}
      cancelProps={{
        label: 'Cancel',
      }}
      onClose={() => {
        setAcceptingResponses(false);
        setRejectingResponses(false);
      }}
      title={acceptingResponses ? 'Accept responses' : 'Reject responses'}
    >
      <div className="accept-reject-responses-modal__content">
        <div className="accept-reject-responses-modal__content-left">
          <div className="accept-reject-responses-modal__content-left-paragraph">
            {request.startTime.toFormat('dd MMM yyyy')}
          </div>
          <div className="accept-reject-responses-modal__content-left-paragraph">
            {request.endTime.toFormat('dd MMM yyyy')}
          </div>
          <div className="accept-reject-responses-modal__content-left-paragraph">
            {request.peak}
          </div>
        </div>
        <div className="accept-reject-responses-modal__content-right">
          <ResponsesTable
            request={request}
            showColumns={[
              'checkboxes',
              'responder',
              'quantity',
              'availability',
              'utilization',
            ]}
            loggedInUser={loggedInUser}
            data={preselectedResponses}
            programId={programID}
            selectedResponses={selectedResponses}
            setSelectedResponses={setSelectedResponses}
            customClasses={{
              tableRowClass:
                'accept-reject-responses-modal__content-right-accept-reject-response-row',
            }}
          />
          <div className="accept-reject-responses-modal__content-bottom">
            {rejectingResponses &&
              'Note: You will not be able to change your action later.'}
            {acceptingResponses &&
              selectedQuantityDelta !== 0 &&
              `Note: The selected responses are ${Math.abs(
                Math.floor(selectedQuantityDelta / 1000)
              )}kW ${
                selectedQuantityDelta < 0 ? 'less' : 'more'
              } than required.`}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AcceptRejectResponsesModal;
