import React, { useMemo, useState, useCallback } from 'react';
import { Cell, IdType, Row, TableInstance } from 'react-table';
import classNames from 'classnames';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import {
  flexContract,
  flexItemStatus,
  serviceType,
  acceptReject,
} from 'types/contract-managment';

import { env } from 'helpers/env';
import copyToClipboard from 'helpers/copyToClipboard';

import Table from 'components/Table';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Menu from 'components/Menu';
import MenuItem from 'components/Menu/MenuItem';
import Dialog from 'components/Dialog';
import DialogHeader from 'components/Dialog/DialogHeader';
import DialogBody from 'components/Dialog/DialogBody';
import DialogFooter from 'components/Dialog/DialogFooter';

import ViewContract from 'routes/LongTermContracts/components/ViewContract';
import AcceptRejectContracts from 'routes/LongTermContracts/components/AcceptRejectContracts';
import CancelContract from 'routes/LongTermContracts/components/CancelContract';
import UpdateContract from 'routes/LongTermContracts/components/UpdateContract';
import ContractsTableFilter from 'routes/LongTermContracts/components/ContractsTableFilters';
import {
  renderBidOffer,
  renderServicePeriod,
  renderWindow,
  renderPerson,
  renderCost,
  renderQuantity,
  renderLight,
  renderUpdates,
  renderCSA,
  renderText,
  renderZone,
} from 'routes/LongTermContracts/helpers/tableFunctions';
import {
  ContractsTableParams,
  getDateSort,
  getModelFilter,
  getServices,
  getStatusList,
  isInTimeRange,
} from './ContractsTable.logic';

import './ContractsTable.scss';

/**
 * Used to generate a specialzed version of the table component: long term contracts table.
 * Handles the boilerplate code and allows features to easily be turned on or off.
 */
const ContractsTable = ({
  data,
  showColumns,
  programID,
  placeHolderRow,
  loggedInUser,
  hideFilters = false,
  showPSATime = false,
  refetchContracts,
  gateTime,
}: ContractsTableParams) => {
  const { selectedProgram: program, feeders } = useProgramsContext();
  const { userIsDso, getUserTenantId } = useUserContext();
  const { currencyFormatter, longCurrencyFormatter, currencySymbol } =
    useLocaleFormatter(program?.currency, program?.locale);

  const [actionsAnchorEl, setActionsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [showUpdateContract, toggleUpdateContract] = useState(false);
  const [acceptOrReject, setAcceptOrReject] = useState<acceptReject>(undefined);
  const [showViewContract, toggleViewContract] = useState(false);
  const [showCancelContract, toggleCancelContract] = useState(false);
  const [contract, setContract] = useState<flexContract | undefined>(undefined);
  const [constractUpdateDetails, setConstractUpdateDetails] =
    useState<null | JSX.Element>(null);
  const [
    constractUpdateDetailsForClipboard,
    setConstractUpdateDetailsForClipboard,
  ] = useState<null | string>(null);
  const [updatesDetailsDialogOpen, setUpdatesDetailsDialogOpen] =
    useState(false);

  const { addToast } = useToasts();
  // get a list of displayed services.
  const services = useMemo(() => getServices(data), [data]);

  // get a list of status types being displayed
  const statusList = useCallback(
    (data, servicesFilters) => getStatusList(data, servicesFilters),
    []
  );

  const isDSO = userIsDso();
  const userId = getUserTenantId();
  const requestorID = contract?.request.requestor?.id;
  const responderID = contract?.response?.responder?.id;
  const isMEC = contract?.request?.service === serviceType.MEC;
  const isMic = contract?.request?.service === serviceType.MIC;
  const canCancelAcceptedMEC = contract?.status === flexItemStatus.accepted;
  const isUserRelatedToContract = [requestorID, responderID].includes(userId);

  const canCancel: boolean = useMemo(() => {
    if (isMEC || isMic) {
      const inTimeRange = isInTimeRange(contract?.request?.startTime, gateTime);

      const canCancelPendingMEC =
        contract?.status === flexItemStatus.pending &&
        isUserRelatedToContract &&
        inTimeRange;

      return canCancelAcceptedMEC || canCancelPendingMEC;
    } else {
      // normal sustain contracts
      return canCancelAcceptedMEC && (isUserRelatedToContract || isDSO);
    }
  }, [
    isMEC,
    isMic,
    contract?.status,
    contract?.request?.startTime,
    isDSO,
    gateTime,
    canCancelAcceptedMEC,
    isUserRelatedToContract,
  ]);

  const service = contract?.request?.service;

  const isDsoServiceCanUpdate =
    (service === serviceType.SustainPeakManagement ||
      service === serviceType.SustainExportManagement) &&
    isDSO &&
    canCancel;

  const isNonDsoServiceCanUpdate =
    canCancelAcceptedMEC && service === serviceType.MEC && !isDSO;

  const canUpdate =
    isNonDsoServiceCanUpdate ||
    isDsoServiceCanUpdate ||
    isUserRelatedToContract;

  const canViewContract = isDSO;
  const canModifyPendingContract =
    contract?.status === flexItemStatus.pending && isDSO;

  const showDsoOption = env.isWSC && isDSO;

  const canShowUpdateContract =
    !isMEC && !isMic && (isDSO || isUserRelatedToContract);

  const openMenu = Boolean(actionsAnchorEl);

  const openActionMenu = (
    newContract: flexContract,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setContract(newContract);
    setActionsAnchorEl(event.currentTarget);
  };
  const closeActionMenu = () => {
    setActionsAnchorEl(null);
  };

  const openUpdateContract = () => {
    closeActionMenu();
    toggleUpdateContract(true);
  };
  const closeUpdateContract = () => toggleUpdateContract(false);

  const openCancelContract = () => {
    closeActionMenu();
    toggleCancelContract(true);
  };
  const closeCancelContract = () => toggleCancelContract(false);

  const openViewContract = () => {
    closeActionMenu();
    toggleViewContract(true);
  };
  const closeViewContract = () => toggleViewContract(false);

  const openAcceptContract = () => {
    closeActionMenu();
    setAcceptOrReject('Accept');
  };
  const openRejectContract = () => {
    closeActionMenu();
    setAcceptOrReject('Reject');
  };
  const closeARContract = () => {
    setAcceptOrReject(undefined);
  };
  const acceptRejectSuccess = () => {
    refetchContracts();
    closeARContract();
  };

  const servicePeriodMemoed = useMemo(
    () => (cellInfo: any) => renderServicePeriod(cellInfo, 'contracts-table'),
    []
  );

  const bidOfferMemoed = useMemo(
    () => (cellInfo: any) => renderBidOffer(cellInfo, 'contracts-table'),
    []
  );

  const windowMemoed = useMemo(
    () => (cellInfo: any) => renderWindow(cellInfo, 'contracts-table'),
    []
  );

  const quantityMemoed = useMemo(
    () => (cellInfo: any) => renderQuantity(cellInfo, 'contracts-table'),
    []
  );

  const updatesMemoed = useMemo(
    () => (cellInfo: any) =>
      renderUpdates(
        cellInfo,
        'contracts-table',
        setUpdatesDetailsDialogOpen,
        setConstractUpdateDetails,
        setConstractUpdateDetailsForClipboard
      ),
    []
  );

  const csaMemoed = useMemo(
    () => (cellInfo: any) => renderCSA(cellInfo, 'contracts-table'),
    []
  );

  const lightMemoed = useMemo(
    () => (cellInfo: any) => renderLight(cellInfo, 'contracts-table'),
    []
  );

  // Handles rendering any cells just displaying dollar values.
  const kWhDollarsMemoed = useMemo(
    () => (cellInfo: any) =>
      renderCost(
        cellInfo,
        'contracts-table',
        currencyFormatter,
        longCurrencyFormatter
      ),
    [currencyFormatter, longCurrencyFormatter]
  );

  const textMemoed = useMemo(
    () => (cellInfo: any) => renderText(cellInfo, 'contracts-table'),
    []
  );

  const personMemoed = useMemo(
    () => (cellInfo: any) => renderPerson(cellInfo, feeders, 'contracts-table'),
    [feeders]
  );

  const renderZoneMemoed = useMemo(
    () => (cellInfo: any) => renderZone(cellInfo, feeders, 'contracts-table'),
    [feeders]
  );

  const renderActions = useMemo(
    () => (cellInfo: any) => {
      const contract: flexContract = cellInfo.cell.row.original;

      return (
        <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
          <IconButton
            icon="MoreHorizontalIcon"
            onClick={(e) => openActionMenu(contract, e)}
          />
        </div>
      );
    },
    []
  );

  const dateSort = useMemo(
    () => (rowA: any, rowB: any) => getDateSort(rowA, rowB),
    []
  );

  const modelFilter = useMemo(
    () => (rows: Row<any>[], ids: IdType<any>[], query: string) =>
      getModelFilter(rows, ids, query),
    []
  );

  const { makeRequest: makeCancelRequest } = useRequest(
    `/api/dsp/program/${programID}/flex/contracts/cancel`
  );

  const cancelContract = async () => {
    await makeCancelRequest({
      method: 'post',
      body: {
        contract_ids: [contract?.id],
      },
      toast: {
        success: 'Successfully cancelled contract.',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        refetchContracts();
        closeCancelContract();
      },
      onError: (error) => {
        addToast(
          error?.response?.data?.message ||
            'Error cancelling contract. Please try again.',
          { appearance: 'error' }
        );
        closeCancelContract();
      },
    });
  };

  /**
   * First part of this function defines all the possible columns and their logic.
   * Second part handles figuring out what columns to render.
   */
  const columns = useMemo(() => {
    const availableColumns = {
      servicePeriod: {
        Header: 'Service Period',
        accessor: 'request.startTime',
        Cell: servicePeriodMemoed,
        sortType: dateSort,
      },
      serviceWindow: {
        Header: 'Window',
        accessor: 'request.peak',
        Cell: windowMemoed,
      },
      serviceType: {
        Header: 'Service Type',
        accessor: 'response.serviceType',
        Cell: textMemoed,
      },
      bidOrOffer: {
        Header: '',
        accessor: 'request.flex',
        Cell: bidOfferMemoed,
        // width:
      },
      quantity: {
        Header: (
          <div>
            <div>Quantity</div>
            <div>(kW)</div>
          </div>
        ),
        accessor: 'response.quantity',
        Cell: quantityMemoed,
      },
      availability: {
        Header: (
          <div>
            <div>Availability</div>
            <div>{`(${currencySymbol}/kW/h)`}</div>
          </div>
        ),
        accessor: 'response.availability',
        Cell: kWhDollarsMemoed,
      },
      utilization: {
        Header: (
          <div>
            <div>Utilization</div>
            <div>{`(${currencySymbol}/kWh)`}</div>
          </div>
        ),
        accessor: 'response.utilization',
        Cell: kWhDollarsMemoed,
      },
      zone: {
        Header: 'Zone',
        accessor: 'feederID',
        Cell: renderZoneMemoed,
      },
      requestor: {
        Header: 'Requestor',
        accessor: 'request.requestorName',
        Cell: personMemoed,
      },
      responder: {
        Header: 'Respondent',
        accessor: 'response.responderName',
        Cell: personMemoed,
      },
      csa: {
        Header: 'CSA',
        accessor: 'response.status',
        Cell: csaMemoed,
      },
      status: {
        Header: 'Status',
        accessor: 'status',
        Cell: lightMemoed,
      },
      updates: {
        Header: 'Updates',
        accessor: 'updatesCount',
        Cell: updatesMemoed,
      },
      actions: {
        Header: '',
        accessor: 'request.endTime',
        Cell: renderActions,
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [
    servicePeriodMemoed,
    dateSort,
    windowMemoed,
    textMemoed,
    bidOfferMemoed,
    quantityMemoed,
    currencySymbol,
    kWhDollarsMemoed,
    renderZoneMemoed,
    personMemoed,
    updatesMemoed,
    csaMemoed,
    lightMemoed,
    renderActions,
    showColumns,
  ]);

  function renderFilters(instance: TableInstance) {
    const { state } = instance;
    return (
      <ContractsTableFilter
        showPSATime={showPSATime}
        services={services}
        statusList={statusList(data, state.filters)}
        programID={programID}
        instance={instance}
      />
    );
  }

  /**
   * Custom function to handle cell rendering.
   * Handles the logic for coloring specific columns.
   * @param {Row} row - object containing information about the row being rendered.
   * @param index - cell index in the row
   * @param tableRowClass - custom general row class.
   * @param tableCellClass - custom general cell class.
   * @returns - row
   */
  function renderCells(
    cell: Cell<any>,
    index: number,
    tableCellClass: string | undefined
  ) {
    const col = cell.column.id;
    let style = {};
    if (col === 'status') {
      style = { width: '45px' };
    }
    return (
      <td
        {...cell.getCellProps()}
        key={index}
        className={classNames(tableCellClass)}
        style={style}
      >
        {cell.render('Cell')}
      </td>
    );
  }

  function renderUpdateDetailsDialog() {
    return (
      <Dialog
        open={updatesDetailsDialogOpen}
        type={'primary'}
        progress={100}
        onClose={() => {
          setUpdatesDetailsDialogOpen(false);
        }}
      >
        <DialogHeader title="Contract updates summary" />
        <DialogBody className="confirmation-dialog__body" padding="thick">
          {constractUpdateDetails}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="outlined"
            onClick={() => {
              setUpdatesDetailsDialogOpen(false);
            }}
            customClasses={{
              customButtonClass: 'confirmation-dialog__action',
            }}
          >
            Close
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              copyToClipboard(constractUpdateDetailsForClipboard);
            }}
            customClasses={{
              customButtonClass: 'confirmation-dialog__action',
            }}
          >
            Copy to clipboard
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }

  const TableStyles = {
    tableClass: 'contracts-table',
    tableHeaderClass: !hideFilters
      ? 'contracts-table__header'
      : 'contracts-table__hide',
    tableHeaderCellClass: 'contracts-table__header-cell',
    tableBodyRowClass: 'contracts-table__row',
    tableBodyCellClass: 'contracts-table__cell',
  };

  return (
    <>
      <Table
        disableSort
        tableType="HTML"
        data={data}
        columns={columns}
        customClasses={TableStyles}
        placeHolderRow={placeHolderRow}
        header={!hideFilters ? renderFilters : undefined}
        customCellRenderFunction={renderCells}
        customGlobalFilterFunction={modelFilter}
      />
      {contract && (
        <>
          <UpdateContract
            isDso={isDSO}
            open={showUpdateContract}
            onClose={closeUpdateContract}
            programID={programID}
            contract={contract}
            refreshTrigger={refetchContracts}
          />
          <CancelContract
            open={showCancelContract}
            onClose={closeCancelContract}
            onConfirm={cancelContract}
            serviceStart={contract.request.startTime}
            serviceEnd={contract.request.endTime}
          />
          <ViewContract
            contract={contract}
            onClose={closeViewContract}
            show={showViewContract}
          />
          <AcceptRejectContracts
            showModal={!!acceptOrReject}
            acceptOrReject={acceptOrReject}
            contract={contract}
            programID={programID}
            loggedInUser={loggedInUser}
            onCancel={closeARContract}
            onSuccess={acceptRejectSuccess}
          />
        </>
      )}
      {(canCancel || showDsoOption) && (
        <Menu
          id="contracts-actions-menu"
          anchorEl={actionsAnchorEl}
          open={openMenu}
          onClose={closeActionMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {showDsoOption && (
            <MenuItem
              disabled={!canModifyPendingContract}
              onClick={openAcceptContract}
            >
              Accept pending contract
            </MenuItem>
          )}
          {showDsoOption && (
            <MenuItem
              disabled={!canModifyPendingContract}
              onClick={openRejectContract}
            >
              Reject pending contract
            </MenuItem>
          )}
          {canCancel && (
            <MenuItem disabled={!canCancel} onClick={openCancelContract}>
              Cancel contract
            </MenuItem>
          )}
          {canShowUpdateContract && (
            <MenuItem disabled={!canUpdate} onClick={openUpdateContract}>
              Update contract
            </MenuItem>
          )}
          {showDsoOption && (
            <MenuItem disabled={!canViewContract} onClick={openViewContract}>
              View contract details
            </MenuItem>
          )}
        </Menu>
      )}
      {renderUpdateDetailsDialog()}
    </>
  );
};

export default ContractsTable;
