import { AxiosError } from 'axios';

import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import { DER } from '../useDERs';
import { primaryData } from 'types/contract-managment';

export interface Container {
  id: string;
  name: string;
  enrolled: boolean;
  type: 'feeder' | 'substation';
  children?: DER[];
  primaries?: primaryData[];
}

export interface Substation extends Container {
  type: 'substation';
}
export interface Feeder extends Container {
  type: 'feeder';
  substation: string | null;
}
interface ContainersResponse {
  feeders: Array<Feeder>;
  susbtations: Array<Substation>;
}
export type ContainerMap = {
  [id: string]: Container;
};

const containersToMap = (
  containers: Array<Feeder | Substation>,
  type: string
): ContainerMap =>
  containers.reduce(
    (containerMap: any, container: Container) => ({
      ...containerMap,
      [container.id]: {
        ...container,
        type,
      },
    }),
    {}
  );

export function useContainers(
  programID?: string | number | null,
  onSuccess?: (data: any, headers: { [key: string]: string }) => any,
  onError?: (error: AxiosError<any>) => void
) {
  const {
    loading: containersLoading,
    error: containersError,
    data: containers,
    refetch: refetchContainers,
  } = useRequestEffect<ContainerMap>({
    url: `/api/dsp/program/${programID}/feeder`,
    method: 'get',
    refetchOnChange: [programID],
    blockRequest: () => programID === undefined || programID === null,
    initialData: {},
    onSuccess,
    onError,
    toast: {
      error:
        'There was an unexpected error fetching your Feeders and Substations',
      settings: {
        autoDismiss: true,
      },
    },
    /**
     * Transform response from
     * {
     *    feeders: [...containers],
     *    substations: [...containers]
     * }
     * to
     * {
     *    [containerId]: {
     *      ...container,
     *      type
     *    }
     * }
     */
    dataTransform: (data: ContainersResponse) =>
      Object.entries(data).reduce(
        (containerMap: ContainerMap, [containerType, containers]) => ({
          ...containerMap,
          ...containersToMap(
            containers,
            containerType.substr(0, containerType.length - 1) // Strip the 's' off the end of type
          ),
        }),
        {}
      ),
  });

  return {
    containersLoading,
    containersError,
    containers,
    refetchContainers,
  };
}
