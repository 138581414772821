import React from 'react';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import IconButton from 'components/IconButton';
import SVG from 'components/SVG';
import Tooltip from 'components/Tooltip';

type MessageCellProps = {
  message: string;
  onClick: () => void;
};

const MessageCell = ({ message, onClick }: MessageCellProps) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <IconButton
          icon="Edit1Icon"
          customClasses={{
            customButtonClass: 'notification-table__message-cell',
          }}
          onClick={onClick}
          width={'16px'}
        />
      </Grid>
      {!message && (
        <Grid item>
          <Tooltip
            content={
              <div className="notification-table__alert-message">
                There is no message for this notification
              </div>
            }
          >
            <SVG
              fontSize="16px"
              icon="AlertCircleIcon"
              className="notification-table__alert"
            />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default MessageCell;
