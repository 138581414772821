import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ErrorPage from 'components/ErrorPage';

interface Props {
  children: React.ReactChild[] | React.ReactNode[] | React.ReactElement[];
  useRedirect404?: boolean;
}

function RouterSwitch({ children, useRedirect404 = false }: Props) {
  return (
    <Switch>
      {children}
      {useRedirect404 && <Redirect to="/404" />}
      {!useRedirect404 && (
        <Route>
          <ErrorPage type="404" />
        </Route>
      )}
    </Switch>
  );
}

export default RouterSwitch;
