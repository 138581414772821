import React from 'react';
import classes from 'classnames';

import SVG from 'components/SVG';
import Tag from 'components/Tag';
import Tooltip from 'components/Tooltip';

import { flexItemStatus } from 'types/contract-managment';

import './BidOfferTag.scss';

interface Props {
  type: string;
  status: flexItemStatus;
  classNames?: {
    tagClass?: string;
    iconClass?: string;
  };
}

const BidOfferTag: React.FC<Props> = ({ type, status, classNames = {} }) => {
  const { tagClass, iconClass } = classNames;
  const isCancelled =
    status === flexItemStatus.cancelled || status === flexItemStatus.expired;

  return (
    <Tooltip content={`${type} ${status}`} arrow={true} theme="light">
      <Tag
        content={type}
        leftElement={
          <SVG
            fontSize="15px"
            icon={isCancelled ? 'fiber_manual_record' : 'check_circle'}
            className={classes(
              {
                'bid-offer-tag--accepted': status === flexItemStatus.accepted,
                'bid-offer-tag--pending': status === flexItemStatus.pending,
                'bid-offer-tag--rejected': status === flexItemStatus.rejected,
                'bid-offer-tag--cancelled': isCancelled,
              },
              iconClass
            )}
          />
        }
        round
        customClasses={{
          tagClass: classes('bid-offer-tag', tagClass, {
            'bid-offer-tag__text--light': isCancelled,
          }),
        }}
        style={{ fontWeight: 'bold' }}
      />
    </Tooltip>
  );
};

export default BidOfferTag;
