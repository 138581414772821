import React from 'react';
import cn from 'classnames';
import {
  useRequestEffect,
  useAuthContext,
} from '@opusonesolutions/gridos-app-framework';
import { UserTenant } from 'types/tenant';

import Avatar from '../Avatar';
import Tooltip from 'components/Tooltip';

import './LoggedInUserInfo.scss';

interface LoggedInUserInfoProps {
  lightText?: boolean;
  classNames?: {
    loggedInUserInfoClass?: string;
    userNameClass?: string;
    userRoleClass?: string;
    avatarImageClass?: string;
    avatarClass?: string;
  };
  styles?: {
    loggedInUserInfoStyle?: React.CSSProperties;
  };
}

const LoggedInUserInfo = ({
  lightText = false,
  styles = {},
  classNames = {},
}: LoggedInUserInfoProps) => {
  const {
    loggedInUserInfoClass,
    userNameClass,
    userRoleClass,
    avatarImageClass,
    avatarClass,
  } = classNames;
  const { loggedInUserInfoStyle } = styles;
  const { user } = useAuthContext();

  const { data: userTenants } = useRequestEffect<UserTenant[]>({
    url: '/api/dsp/user_tenants',
    initialData: [],
    method: 'get',
    refetchOnChange: [],
    toast: {
      error: 'Failed to load participant list.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  // find the logged in user
  const userTenant = userTenants?.find(
    (userTenant) => userTenant.user.email === user.email
  );

  const content = userTenant?.user.email || user.email;

  return (
    <div
      className={cn('logged-in-user-info', loggedInUserInfoClass)}
      style={loggedInUserInfoStyle}
    >
      <div className="logged-in-user-info__user">
        <Avatar className={cn(avatarClass)} imageClass={cn(avatarImageClass)} />
        <div className="logged-in-user-info__user-info">
          <Tooltip content={content.length <= 20 ? '' : content}>
            <p
              className={cn(
                'logged-in-user-info__user-name',
                { 'logged-in-user-info__user-name--light': lightText },
                userNameClass
              )}
            >
              {content}
            </p>
          </Tooltip>
          <p
            className={cn(
              'logged-in-user-info__user-role',
              { 'logged-in-user-info__user-role--light': lightText },
              userRoleClass
            )}
          >
            {userTenant?.tenant.is_dso ? 'DSO' : 'IA'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoggedInUserInfo;
