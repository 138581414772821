import { MarketType } from 'types';

export enum PFAnalysisTypesRaw {
  UTILIZATION = 'UTILISATION',
  NEEDS = 'NEEDS',
  CONTRACT_SELECTION = 'CONTRACT_SELECTION',
}

export enum PFAnalysisTypes {
  UTILIZATION = 'Utilization',
  NEEDS = 'Needs',
  CONTRACT_SELECTION = 'Contract selection',
}

export enum PFObjective {
  OPTIMAL_POWERFLOW = 'OPTIMAL_POWERFLOW',
  NETWORK_LOSSES = 'NETWORK_LOSSES',
}

export type PFReportDetails = {
  analysis_name: PFAnalysisTypesRaw;
  analysis_time: string;
  analysis_type: null | string;
  end_time: string;
  feeder_id: string;
  feeder_name: string;
  id: string;
  market_type: MarketType;
  objective: PFObjective;
  program_id: string;
  report_location: string | null;
  simulation_success: boolean;
  start_time: string;
};

export type PFReportDownloadDetails = {
  report_location: string;
  feeder_name: string;
  market_type: string;
  analysis_name: string;
  analysis_time: string;
};
