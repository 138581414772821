import React, {
  createRef,
  FocusEvent,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react';
import classNames from 'classnames';
import check from 'check-types';

import './NumberInput.scss';

type ShowValidationMessage = 'always' | 'after-edit' | 'never';

type NumberInputProps = {
  id: string;
  className?: string;
  inputFieldClassName?: string | undefined;
  inputFieldUnitClassName?: string | undefined;
  label?: ReactNode;
  gt?: number;
  lt?: number;
  max?: number;
  min?: number;
  step?: string;
  onChange?: (n?: number) => void;
  value?: number;
  prefix?: ReactNode;
  unit?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  validationMessage?: Array<string> | string;
  showValidationMessage?: ShowValidationMessage;
  nonZero?: boolean;
  negNonZero?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  name?: string;
  htmlFor?: string;
  placeholder?: string;
  errorHasAbsolutePosition?: boolean;
};

const NumberInput: React.FC<NumberInputProps> = ({
  id,
  className,
  inputFieldClassName,
  inputFieldUnitClassName,
  htmlFor,
  gt,
  lt,
  max,
  min,
  step = 'any',
  prefix,
  unit,
  label,
  disabled = false,
  nonZero = false,
  negNonZero = false,
  onChange,
  value,
  required = false,
  invalid = false,
  validationMessage,
  showValidationMessage = 'after-edit',
  onBlur,
  onFocus,
  name,
  placeholder,
  errorHasAbsolutePosition = false,
}) => {
  const inputRef: any = createRef();
  const [edited, setEdited] = useState(false);
  const [previousStringValue, setPreviousStringValue] = useState('');

  const parseFloatOrUndefined = (
    value: number | string | undefined
  ): number | undefined => {
    if (check.number(value)) {
      return value;
    }
  };

  const isInRange = () => {
    if (showValidationMessage === 'never') {
      return true;
    }
    if (!edited && showValidationMessage === 'after-edit') {
      return true;
    }

    let valid = true;

    const numVal = parseFloatOrUndefined(value);
    if (required && (!check.number(numVal) || !numVal)) {
      valid = false;
    } else if (nonZero && numVal && numVal <= 0) {
      valid = false;
    } else if (negNonZero && numVal && numVal >= 0) {
      valid = false;
    } else if (check.number(max) && numVal && numVal > max) {
      valid = false;
    } else if (check.number(min) && numVal && numVal < min) {
      valid = false;
    } else if (check.number(gt) && numVal && numVal <= gt) {
      valid = false;
    } else if (check.number(lt) && numVal && numVal >= lt) {
      valid = false;
    } else if (invalid) {
      valid = !invalid;
    }

    return valid;
  };

  const getErrorMessage = (): string => {
    if (invalid) {
      if (check.array(validationMessage)) {
        return validationMessage.reduce((acc, cur) => `${acc} ${cur}`, '');
      }
      return validationMessage || '';
    }

    const numVal = parseFloatOrUndefined(value);
    if (required && (Number.isNaN(numVal) || numVal === undefined)) {
      return 'Field is required.';
    } else if (nonZero && numVal && numVal <= 0) {
      return 'Value must be greater than 0.';
    } else if (negNonZero && numVal && numVal >= 0) {
      return 'Value must be less than 0.';
    } else if (min !== null || max !== null) {
      const minMessage = min !== null ? `smaller than ${min}` : '';
      const maxMessage = max !== null ? `greater than ${max}` : '';
      return `Value must not be ${minMessage}${
        !!minMessage && !!maxMessage ? ' or ' : ''
      }${maxMessage}.`;
    }

    const minMessage = gt !== null ? `smaller than or equal to ${gt}` : '';
    const maxMessage = lt !== null ? `greater than or equal to ${lt}` : '';
    return `Value must not be ${minMessage}${
      !!minMessage && !!maxMessage ? ' or ' : ''
    }${maxMessage}.`;
  };

  const onWheel = () => {
    inputRef.current.blur();
  };

  let errorMessage;
  const valueInRange = isInRange();

  if (!valueInRange) {
    errorMessage = getErrorMessage();
  }

  return (
    <div
      className={classNames(className, {
        'number-input-group': true,
        'number-input-group--invalid': !valueInRange || (edited && invalid),
      })}
    >
      <div className="number-input">
        {label && (
          <label htmlFor={htmlFor || id} className="number-input__label">
            {label}
          </label>
        )}
        <div className="number-input-container">
          {prefix && <span className="number-input-prefix">{prefix}</span>}
          <input
            id={htmlFor || id}
            type="number"
            min={min}
            max={max}
            step={step}
            className={`number-input__input ${
              unit ? 'number-input-with-unit' : ''
            } ${inputFieldClassName ? inputFieldClassName : ''}`}
            value={value}
            disabled={disabled}
            onChange={(e: SyntheticEvent) => {
              if (onChange) {
                const { value } = e.target as HTMLInputElement;
                const parsed = value !== '' ? Number(value) : undefined;
                // if no . present - parse new value
                if (value.indexOf('.') < 0) {
                  onChange(parsed);
                } else {
                  // if . present and numeric value have changed - parse new value
                  if (Number(previousStringValue) !== parsed) {
                    onChange(parsed);
                  }
                }
                setEdited(true);
                setPreviousStringValue(value);
              }
            }}
            required={required}
            title={valueInRange ? undefined : errorMessage}
            onBlur={(valueInRange && onBlur) || undefined}
            onFocus={onFocus}
            name={name}
            placeholder={placeholder}
            ref={inputRef}
            onWheel={onWheel}
            data-testid="number-input"
          />
          {unit && (
            <span
              className={`number-input-unit ${
                inputFieldUnitClassName ? inputFieldUnitClassName : ''
              }`}
            >
              {unit}
            </span>
          )}
        </div>
      </div>
      {!valueInRange && (
        <div
          className={`input-error ${
            errorHasAbsolutePosition ? 'input-error__absolute' : ''
          }`}
        >
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default NumberInput;
