import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { flexType, serviceType } from 'types/contract-managment';
import { ResponseAsset } from 'types/der';
import { getRequestTotalDailyHours } from 'helpers/serviceWindows';
import {
  FlexResponseFormFields,
  FlexResponseFormProps,
} from './FlexResponseForm.types';
import { isUndefined } from 'lodash';

import { flexResponseFormSchema, selectStyles } from './FlexResponseForm.logic';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import NumberInput from 'routes/RequestsResponses/components/NumberInput';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import Select from 'components/Select';
import FormLabel from 'components/FormLabel';
import SVG from 'components/SVG';

import './FlexResponseForm.scss';

const FlexResponseForm: React.FC<FlexResponseFormProps> = ({
  request,
  currencySymbol,
  responseAssets,
  onSubmit,
  onCancel,
  selectedDer,
  zoneList,
}) => {
  const { availability, quantity, utilization, service } = request;
  const availabilityPrice = availability ? availability * 1000 : 0;
  const utilizationPrice = utilization ? utilization * 1000 : 0;
  const isMec = service === serviceType.MEC;
  const isMic = service === serviceType.MIC;
  const isDynamic = service === serviceType.DynamicPeakManagement;
  const negQuantity = service === serviceType.SustainExportManagement;

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, dirtyFields, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      flexResponseFormSchema(
        service,
        availabilityPrice,
        utilizationPrice,
        isDynamic
      )
    ),
  });

  const quantityWatcher = watch(FlexResponseFormFields.Quantity);
  const assetWatcher: ResponseAsset = watch(FlexResponseFormFields.Asset);
  const availabilityWatcher = watch(FlexResponseFormFields.AvailabilityPrice);
  const utilizationWatcher = watch(FlexResponseFormFields.UtilizationPrice);
  const zoneWatcher = watch(FlexResponseFormFields.Zone);

  useEffect(() => {
    if (request.serviceWindows) {
      setValue(
        FlexResponseFormFields.DailyDurationHours,
        getRequestTotalDailyHours(request.serviceWindows)
      );
    }
  }, [request, setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        quantity: quantityWatcher,
        asset: {},
        availabilityPrice:
          availabilityWatcher === undefined ? 0 : availabilityWatcher,
        utilizationPrice:
          utilizationWatcher === undefined ? 0 : utilizationWatcher,
      });
    }
  }, [
    availabilityWatcher,
    isSubmitSuccessful,
    quantityWatcher,
    reset,
    utilizationWatcher,
  ]);

  const testResponseAssetIsCurtailable = (
    asset: ResponseAsset | undefined
  ): boolean => !!(asset && asset.curtailable);

  const setDefaultMaxEnergyDaily = async (asset: ResponseAsset) => {
    const [maxEnergyDaily, quantity, dailyDurationHours] = getValues([
      FlexResponseFormFields.MaxEnergyDaily,
      FlexResponseFormFields.Quantity,
      FlexResponseFormFields.DailyDurationHours,
    ]);
    if (
      (dirtyFields[FlexResponseFormFields.MaxEnergyDaily] && maxEnergyDaily) ||
      !request.serviceWindows
    ) {
      return;
    }
    let maxEnergyDailyDefault = null;
    if (testResponseAssetIsCurtailable(asset) && quantity) {
      maxEnergyDailyDefault = quantity * dailyDurationHours;
    }
    setValue(FlexResponseFormFields.MaxEnergyDaily, maxEnergyDailyDefault, {
      shouldDirty: false,
    });
  };

  const defaultValue =
    isMec || isMic
      ? zoneList[0]
      : zoneList.find((i) => i.value === request.feederId);

  useEffect(() => {
    if (isMec && zoneWatcher) {
      setValue(FlexResponseFormFields.Asset, null);
    }
  }, [zoneWatcher, setValue, isMec]);

  const assets =
    isMec || isMic
      ? responseAssets?.filter((asset) => asset.feederId === zoneWatcher?.value)
      : responseAssets;

  const disableSubmit =
    isUndefined(assetWatcher) ||
    errors?.feeder?.message ||
    errors?.asset?.message ||
    errors?.maxEnergyDaily?.message ||
    errors?.quantity?.message ||
    errors?.availabilityPrice?.message ||
    errors?.utilizationPrice?.message;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-response-form__panel">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormLabel required={isMec || isMic} htmlFor="feeder">
              Zone
            </FormLabel>
            {defaultValue && (
              <Controller
                name={FlexResponseFormFields.Zone}
                control={control}
                defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      isDisabled={!isMec && !isMic}
                      value={value}
                      isClearable={false}
                      isMulti={false}
                      options={zoneList}
                      onChange={onChange}
                      error={!!errors?.feeder?.message}
                      styles={selectStyles}
                      placeholder="Select zone"
                    />
                  </>
                )}
              />
            )}
            <div className="create-request-service__error">
              {errors?.feeder?.message}
            </div>
          </Grid>
          <Grid item xs={4}>
            <FormLabel required htmlFor="distributedResource">
              Distributed energy resource
            </FormLabel>
            {responseAssets && (
              <Controller
                name={FlexResponseFormFields.Asset}
                control={control}
                defaultValue={selectedDer}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name={FlexResponseFormFields.Asset}
                    value={value}
                    isClearable={false}
                    isMulti={false}
                    options={assets}
                    onChange={(value) => {
                      setDefaultMaxEnergyDaily(value);
                      onChange(value);
                    }}
                    placeholder="Select..."
                    styles={selectStyles}
                    error={!!errors?.asset?.message}
                    helperText={errors?.asset?.message}
                  />
                )}
              />
            )}
            {testResponseAssetIsCurtailable(assetWatcher) && (
              <div className="flex-response-form__input-wrap">
                <Controller
                  name={FlexResponseFormFields.MaxEnergyDaily}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      id="maxEnergyDaily"
                      label="Max Energy Daily"
                      onChange={(value) =>
                        onChange(value ? Number(value) : null)
                      }
                      unit="KWh"
                      placeholder="Type"
                      value={value}
                      showValidationMessage="always"
                      validationMessage={errors?.maxEnergyDaily?.message}
                      invalid={!!errors?.maxEnergyDaily?.message}
                      errorHasAbsolutePosition
                    />
                  )}
                />
              </div>
            )}
            {!responseAssets?.length && (
              <div className="flex-response-form__input-row--no-assets">
                No assets eligible to submit a response to this request
              </div>
            )}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <FormLabel required htmlFor="distributedResource">
              Quantity
              <Tooltip
                arrow
                content="Request quantity must be equal to or less than the physical or commercial capacity."
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name={FlexResponseFormFields.Quantity}
              control={control}
              defaultValue={
                !quantity
                  ? 0
                  : quantity > 0
                  ? quantity / 1000
                  : (quantity / 1000) * -1
              }
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  id="quantity"
                  onChange={(value) => onChange(value ? Number(value) : null)}
                  onBlur={() => setDefaultMaxEnergyDaily(assetWatcher)}
                  prefix={negQuantity ? '-' : ''}
                  unit="kW"
                  value={value}
                  invalid={!!errors?.quantity?.message}
                />
              )}
            />
            {errors?.quantity && (
              <div className="flex-response-form__error">
                {errors?.quantity && errors?.quantity?.message}
              </div>
            )}
          </Grid>
          {!isDynamic && (
            <Grid item xs={4}>
              <FormLabel required htmlFor="availabilityPrice">
                Availability Price
                <Tooltip
                  arrow
                  content="The price for asset availability across the duration of the contract period"
                  theme="light"
                >
                  <SVG
                    icon="InfoIcon"
                    fontSize="12px"
                    className="create-request-service__label-icon"
                  />
                </Tooltip>
              </FormLabel>
              <Controller
                name={FlexResponseFormFields.AvailabilityPrice}
                control={control}
                defaultValue={availabilityPrice}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    id="availabilityPrice"
                    onChange={(value) => onChange(value ? Number(value) : null)}
                    unit="/kWh"
                    prefix={currencySymbol}
                    value={value}
                    className="has-tooltip"
                    placeholder="Type"
                    invalid={!!errors?.availabilityPrice?.message}
                  />
                )}
              />
              {errors?.availabilityPrice && (
                <div className="flex-response-form__error">
                  {errors?.availabilityPrice &&
                    `The availability price cannot exceed ${availabilityPrice}
                    /kW/h`}
                </div>
              )}
            </Grid>
          )}
          {!isMec && !isMic && (
            <Grid item xs={4}>
              <FormLabel required htmlFor="utilizationPrice">
                Utililzation Price
                <Tooltip
                  arrow
                  content="The price for asset utilization across the duration of the contract period"
                  theme="light"
                >
                  <SVG
                    icon="InfoIcon"
                    fontSize="12px"
                    className="create-request-service__label-icon"
                  />
                </Tooltip>
              </FormLabel>
              <Controller
                name={FlexResponseFormFields.UtilizationPrice}
                control={control}
                defaultValue={utilizationPrice}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    id="utilizationPrice"
                    onChange={(value) => onChange(value ? Number(value) : null)}
                    unit="/kWh"
                    prefix={currencySymbol}
                    value={value}
                    className="has-tooltip"
                    placeholder="Type"
                    invalid={!!errors?.utilizationPrice?.message}
                  />
                )}
              />
              {errors?.utilizationPrice && (
                <div className="flex-response-form__error">
                  {errors?.utilizationPrice &&
                    `The utilization price cannot exceed ${utilizationPrice}
                    /kW/h`}
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </div>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button htmlButtonType="submit" disabled={disableSubmit}>
            {request.flex === flexType.BID
              ? 'Submit sell response'
              : 'Submit buy response'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FlexResponseForm;
