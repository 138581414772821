import React from 'react';
import { Route, useParams } from 'react-router-dom';

import RouterSwitch from 'components/RouterSwitch';
import NavButtons from 'components/NavButtons';
import Switch from 'components/Switch';

import DayAheadPage from './DayAheadPage';
import IntentPage from './IntentPage';

import './MarketSchedules.scss';

const intentPath = '/program/:programID/schedules';
const dayAheadPath = '/program/:programID/schedules/day-ahead';

const marketScheduleRoutes = [
  {
    path: '',
    label: 'Intent to dispatch',
    useIsExact: true,
  },
  {
    path: 'day-ahead',
    label: 'Day ahead analysis',
    useIsExact: true,
  },
];

// header and routing
function MarketSchedules() {
  const { programID } = useParams<{
    programID: string;
  }>();

  function renderMarketSchedulesHeader() {
    return (
      <div className="market-schedules__header">
        <div className="market-schedules__header-top">
          <h1 className="market-schedules__header-title">Market schedules</h1>
          <div className="market-schedules__header-actions">
            <Switch
              id="market-schedules-show-graph-switch"
              checked={false}
              onClick={() => {}}
              label="Show graph"
              labelPosition="left"
              disabled={true}
            />
          </div>
        </div>
        <NavButtons
          baseRoute={`/program/${programID}/schedules`}
          routes={marketScheduleRoutes}
        />
      </div>
    );
  }

  return (
    <div className="market-schedules">
      {renderMarketSchedulesHeader()}
      <RouterSwitch useRedirect404>
        <Route exact path={intentPath}>
          <IntentPage />
        </Route>
        <Route exact path={dayAheadPath}>
          <DayAheadPage />
        </Route>
      </RouterSwitch>
    </div>
  );
}

export default MarketSchedules;
