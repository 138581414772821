import React from 'react';
import classes from 'classnames';

import './DialogBody.scss';

type DialogProps = {
  padding?: 'normal' | 'thick' | 'left-aligned';
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
};

function DialogBody({
  children,
  padding = 'normal',
  className,
  styles,
}: DialogProps) {
  return (
    <div
      className={classes(
        'dialog-body',
        {
          [`dialog-body--padding-${padding}`]: padding,
        },
        className
      )}
      style={styles}
    >
      {children}
    </div>
  );
}

export default DialogBody;
