import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Route, useParams, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';
import { FieldValues, UseFormReset } from 'react-hook-form';

import { useProgramsContext } from 'contexts/ProgramsContext';

import {
  SettlementSummaryRaw,
  SettlementSummary,
  SettlementStatus,
} from 'types/settlement';

import fileExportSave from 'helpers/downloadFile';

import IconButton from 'components/IconButton';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import RouterSwitch from 'components/RouterSwitch';

import { processRawSettlementSummary } from 'routes/Settlement/helpers/dataProcessors';
import { handleBaselineImport } from 'routes/Settlement/helpers/sharedAPICalls';
import SettlementSummaryTable from 'routes/Settlement/components/SettlementSummaryTable';
import { EventsTableColumns } from 'routes/Settlement/components/SettlementSummaryTable/SettlementSummaryTable';
import SettlementNav from 'routes/Settlement/components/SettlementNav';
import SettlementHeader from 'routes/Settlement/components/SettlementHeader';
import SettlementSummaryFilters from 'routes/Settlement/components/SettlementSummaryFilters';
import BaselineImportForm from 'routes/Settlement/SettlementSingle/components/BaselineImportForm';

import './SettlementSummaryPage.scss';

function SettlementSummaryPage() {
  const { programID } = useParams<{
    programID: string;
  }>();
  const { selectedProgram: program, selectedFeeder } = useProgramsContext();

  const [selectedRows, updateSelected] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.local());

  const [importModal, setImportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const location = useLocation();

  useEffect(() => {
    updateSelected([]);
  }, [location.pathname]);

  useEffect(() => {
    if (program) {
      const date = DateTime.local()
        .setZone(program.timezone)
        .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
      setSelectedDate((prev) => (prev !== date ? date : prev));
    }
  }, [program]);

  const allPath = '/program/:programID/settlement';
  const exportPath = '/program/:programID/settlement/export';
  const importPath = '/program/:programID/settlement/import';
  const publishPath = '/program/:programID/settlement/publish';
  const pendingPath = '/program/:programID/settlement/pending';
  const publishedPath = '/program/:programID/settlement/published';
  const exportActive = useRouteMatch(exportPath)?.isExact;
  const importActive = useRouteMatch(importPath)?.isExact;
  const publishActive = useRouteMatch(publishPath)?.isExact;

  const { makeRequest: exportSettlementAdmin } = useRequest(
    `/api/dsp/program/${programID}/feeder/${selectedFeeder?.id}/flex/settlement/admin/export`
  );

  const exportSettlementSummary = () => {
    exportSettlementAdmin({
      method: 'get',
      params: {
        industry_actor_ids: (selectedRows || []).join(','),
        start_time: selectedDate.toJSDate().toISOString(),
        end_time: selectedDate
          .plus({
            months: 1,
          })
          .toJSDate()
          .toISOString(),
      },
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(
          data,
          headers,
          `${selectedDate.toJSDate().toISOString()}-${selectedDate
            .plus({
              months: 1,
            })
            .toJSDate()
            .toISOString()}-settlement_report.csv`
        );
      },
      responseType: 'blob',
      toast: {
        error: 'Something went wrong during the export!',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { data: summaries, refetch: refetchSummaries } = useRequestEffect<
    SettlementSummary[]
  >({
    urlGen: () =>
      `/api/dsp/program/${programID}/feeder/${selectedFeeder?.id}/flex/settlement/admin`,
    blockRequest: () => !programID || !selectedFeeder || !selectedFeeder?.id,
    params: {
      start_time: selectedDate.toJSDate().toISOString(),
      end_time: selectedDate
        .plus({
          months: 1,
        })
        .toJSDate()
        .toISOString(),
    },
    initialData: [],
    method: 'get',
    refetchOnChange: [programID, selectedFeeder, selectedDate, importModal],
    toast: {
      error: 'Failed to load settlement summary data.',
      settings: {
        autoDismiss: true,
      },
    },
    dataTransform: (data) => {
      return data?.tenants?.map((summary: SettlementSummaryRaw) =>
        processRawSettlementSummary(summary)
      );
    },
  });

  async function importBaseline(data: any, reset: UseFormReset<FieldValues>) {
    handleBaselineImport(
      data,
      reset,
      programID,
      addToast,
      setImportModal,
      setLoading
    );
  }

  const exportData = summaries?.filter(
    (summary: SettlementSummary) =>
      summary.settlementStatus === SettlementStatus.ExportMeasurement
  );
  // Criteria for Export and import data is the same.
  const importData = exportData;
  const publishData = summaries?.filter(
    (summary: SettlementSummary) =>
      summary.settlementStatus === SettlementStatus.PublishSettlement
  );
  const pendingData = summaries?.filter(
    (summary: SettlementSummary) =>
      summary.settlementStatus === SettlementStatus.PendingMeasurementData
  );
  const publishedData = summaries?.filter(
    (summary: SettlementSummary) =>
      summary.settlementStatus === SettlementStatus.Published
  );

  const rowCounts = {
    allCount: summaries?.length || 0,
    exportCount: exportData?.length || 0,
    importCount: importData?.length || 0,
    publishCount: publishData?.length || 0,
    pendingCount: pendingData?.length || 0,
    publishedCount: publishedData?.length || 0,
  };

  const allColumns: EventsTableColumns[] = [
    'industryActor',
    'events',
    'hasMeasurements',
    'requested',
    'hasBaseline',
    'delivered',
    'cost',
    'penalty',
    'finalSettlement',
    'status',
    'clickIcon',
  ];

  const toggleImportModal = () => {
    if (summaries && summaries?.length > 0) {
      setImportModal(!importModal);
    } else {
      setImportModal(false);
      updateSelected([]);
    }
  };

  return (
    <div className="settlement-summary-page">
      <SettlementHeader
        selectedRowCount={selectedRows.length}
        selectedTenants={selectedRows}
        startDate={selectedDate}
        title="Settlement"
        showButtons={{
          showExportButton: exportActive,
          showImportButton: importActive,
          showPublishButton: publishActive,
        }}
        refetchSummaries={refetchSummaries}
        toggleImportModal={toggleImportModal}
      />
      <div className="settlement-summary-page__actions">
        <SettlementSummaryFilters
          startDate={selectedDate}
          setStartDate={setSelectedDate}
        />
        <IconButton
          icon="DownloadIcon"
          onClick={exportSettlementSummary}
          fontSize="12px"
          tooltip="Export Settlement Summary"
        />
      </div>
      <SettlementNav sectionRowCounts={rowCounts} />
      <Modal
        active={importModal}
        cancelProps={{ variant: 'outlined' }}
        hideFooter={true}
        title={`Import baseline data / ${selectedDate.toFormat('LLLL y')}`}
        onClose={toggleImportModal}
      >
        <div>
          {selectedRows.length > 0 && (
            <BaselineImportForm
              selectedTenants={selectedRows}
              startDate={selectedDate}
              loading={loading}
              onSubmit={importBaseline}
              onCancel={toggleImportModal}
            />
          )}
        </div>
      </Modal>
      <RouterSwitch>
        <Route exact path={allPath}>
          {summaries && program && (
            <SettlementSummaryTable
              hideCheckboxes
              selectedState={{ selectedRows, updateSelected }}
              startDate={selectedDate}
              showColumns={allColumns}
              data={summaries}
              program={program}
            />
          )}
        </Route>
        <Route exact path={exportPath}>
          {exportData && program && (
            <SettlementSummaryTable
              startDate={selectedDate}
              selectedState={{ selectedRows, updateSelected }}
              showColumns={allColumns}
              data={exportData}
              program={program}
            />
          )}
        </Route>
        <Route exact path={importPath}>
          {importData && program && (
            <SettlementSummaryTable
              startDate={selectedDate}
              selectedState={{ selectedRows, updateSelected }}
              showColumns={allColumns}
              data={importData}
              program={program}
            />
          )}
        </Route>
        <Route exact path={publishPath}>
          {publishData && program && (
            <SettlementSummaryTable
              startDate={selectedDate}
              selectedState={{ selectedRows, updateSelected }}
              showColumns={allColumns}
              data={publishData}
              program={program}
            />
          )}
        </Route>
        <Route exact path={pendingPath}>
          {pendingData && program && (
            <SettlementSummaryTable
              hideCheckboxes
              startDate={selectedDate}
              selectedState={{ selectedRows, updateSelected }}
              showColumns={allColumns}
              data={pendingData}
              program={program}
            />
          )}
        </Route>
        <Route exact path={publishedPath}>
          {publishedData && program && (
            <SettlementSummaryTable
              hideCheckboxes
              startDate={selectedDate}
              selectedState={{ selectedRows, updateSelected }}
              showColumns={allColumns}
              data={publishedData}
              program={program}
            />
          )}
        </Route>
      </RouterSwitch>
    </div>
  );
}

export default SettlementSummaryPage;
