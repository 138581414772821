import { FeederRaw, Feeder } from 'types/feeder';
import { TenantRaw, Tenant } from 'types/tenant';

export function processRawTenant(tenant: TenantRaw): Tenant {
  const {
    name,
    id,
    image,
    auto_confirm_enabled,
    billing_account_id,
    external_account_id,
    is_dso,
    phone_number,
    administrative_area,
    address_line_1,
    address_line_2,
    locality,
    postal_code,
    invoicee,
    invoicee_info,
    ders,
    users,
    country,
    enable_p2p,
    enable_p2n,
  } = tenant;
  return {
    id,
    auto_confirm_enabled,
    billing_account_id,
    external_account_id,
    is_dso,
    phone_number,
    administrative_area,
    country,
    address_line_1,
    address_line_2,
    locality,
    postal_code,
    invoicee,
    invoicee_info,
    name,
    image,
    ders,
    users,
    enable_p2p,
    enable_p2n,
  };
}

export function processRawFeeder(feeder: FeederRaw): Feeder {
  const { enrolled, id, name, substation, primaries } = feeder;
  return {
    enrolled,
    id,
    name,
    substation,
    primaries,
  };
}
