import { Actions, initialState } from '../Calendar.logic';

const reducer = (state: typeof initialState, action: any) => {
  switch (action.type) {
    case Actions.SET_INIT:
      return {
        ...state,
        disablePast: action.payload.disablePast,
        selectedDate: action.payload.selectedDate,
        selectedMonth: action.payload.selectedMonth,
        selectedYear: action.payload.selectedYear,
        maxDate: action.payload.maxDate,
        minDate: action.payload.minDate,
        disabledDays: action.payload.disabledDays,
      };
    case Actions.SET_MONTH:
      return {
        ...state,
        selectedMonth: action.payload,
        selectedYear: action.payload,
      };
    case Actions.SET_DATE:
      return {
        ...state,
        selectedDate: action.payload,
        selectedMonth: action.payload,
        selectedYear: action.payload,
      };
    default:
      throw new Error('Unexpected action');
  }
};

export default reducer;
