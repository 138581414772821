import React from 'react';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { flexContract } from 'types/contract-managment';

import SVG from 'components/SVG';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';

import ModalSection from 'routes/LongTermContracts/components/ModalSection';
import ViewFlexAuctionInfo from 'routes/LongTermContracts/components/ViewFlexAuctionInfo';

import './ViewContract.scss';

interface Props {
  contract: flexContract;
  show: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title?: string;
  hideFooter?: boolean;
}

const ViewContractModal: React.FC<Props> = ({
  contract,
  show,
  onClose,
  onConfirm,
  title = 'View Contract',
  hideFooter = true,
}) => {
  const { getProgram } = useProgramsContext();
  const program = getProgram(contract.programID);
  const { currencyFormatter } = useLocaleFormatter(
    program?.currency,
    program?.locale
  );
  const { request, response } = contract;

  return (
    <Modal
      title={title}
      onClose={onClose}
      active={show}
      hideFooter={hideFooter}
      cancelProps={{
        label: 'Close',
        variant: 'outlined',
      }}
      confirmProps={{
        label: 'Cancel Contract',
        onClick: onConfirm ? onConfirm : onClose,
      }}
      classes={{
        contentClass: 'view-contract__content',
        headerClass: 'view-contract__header',
      }}
      alignFooterButtons="center"
    >
      <div>
        <ModalSection
          title="Request"
          icon={<SVG icon="Edit3Icon" className="modal-content-icon" />}
        >
          <ViewFlexAuctionInfo
            type="request"
            includeUser
            data={request}
            availabilityFormatted={`${currencyFormatter.format(
              request.availability * 1000 || 0
            )}`}
            utilizationFormatted={`${currencyFormatter.format(
              request.utilization * 1000 || 0
            )}`}
          />
        </ModalSection>
        <ModalSection
          title="Response"
          icon={<SVG icon="Edit3Icon" className="modal-content-icon" />}
        >
          <ViewFlexAuctionInfo
            type={'response'}
            data={response}
            availabilityFormatted={`${currencyFormatter.format(
              response.availability * 1000 || 0
            )}`}
            utilizationFormatted={`${currencyFormatter.format(
              response.utilization * 1000 || 0
            )}`}
          />
        </ModalSection>
      </div>
    </Modal>
  );
};

export default ViewContractModal;
