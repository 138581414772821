import React from 'react';
import { capitalize } from 'lodash';

import Switch from 'components/Switch';
import Button from 'components/Button';
import SVG from 'components/SVG';
import { MarketType } from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';

import './NeedsAnalysisSummary.scss';

interface AnalysisSummaryProps {
  label: string;
  active: boolean;
  setActive: () => void;
  errors: string | undefined;
  displaySaveButton: boolean;
  saveData: (marketType: MarketType) => void;
  changesWereMade: boolean;
}

export const NeedsAnalysisSummary = ({
  label,
  active,
  setActive,
  errors,
  displaySaveButton,
  saveData,
  changesWereMade,
}: AnalysisSummaryProps) => {
  const thisMarketType =
    label === 'week' ? MarketType.weekAhead : MarketType.dayAhead;
  return (
    <div className="market-summary__market-type">
      <Switch
        id={`${label}ahead-switch`}
        label={`${capitalize(label)}-ahead needs analysis`}
        type="primary"
        checked={active}
        onClick={setActive}
        classNames={{
          labelClass: 'market-summary__market-type-label',
        }}
        disabled={Boolean(errors)}
      />
      <div className="market-summary__right">
        {errors && (
          <span className="market-summary__message">
            <SVG icon="XCircleIcon" className="market-summary__message-icon" />
            <span>{errors}</span>
          </span>
        )}
        {displaySaveButton && (
          <Button
            variant="contained"
            onClick={() => {
              saveData(thisMarketType);
            }}
            startIcon={<SVG icon="SaveIcon" fontSize="16px" />}
            customStyles={{
              customButtonStyles: { minWidth: '144px' },
            }}
            disabled={Boolean(errors) || !changesWereMade}
          >
            Save changes
          </Button>
        )}
      </div>
    </div>
  );
};
