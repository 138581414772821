import React, { useEffect, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { Program, SystemMarketInfo } from 'types/program';

import RouterSwitch from 'components/RouterSwitch';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';

import SettingsMenu from './components/SettingsMenu';
import Basic from './routes/Basic';
import FinancialModel from './routes/FinancialModel';
import Advanced from './routes/Advanced';
import ContractSelectionAnalysis from './routes/ContractSelectionAnalysis';
import EmailNotifications from './routes/EmailNotifications';
import NeedsAnalysis from './routes/NeedsAnalysis';
import NeedsAnalysisService from './routes/NeedsAnalysis/routes/NeedsAnalysisService';

import './Settings.scss';

export interface SelectInterface {
  label: string;
  value: string;
}

export interface ISaveMessages {
  [key: string]: string[];
}

type ProgramKey = keyof Program;
export type MarketKey = keyof SystemMarketInfo;

export type SettingsCommonProps = {
  program: Program;
  saving: boolean;
  updateProp: (key: ProgramKey, value: any) => void;
};

export enum Routes {
  basic = '/program/:programID/settings/basic',
  financialModel = '/program/:programID/settings/financial-model',
  advanced = '/program/:programID/settings/advanced',
  contractSelection = '/program/:programID/settings/contract-selection',
  emailNotifications = '/program/:programID/settings/email-notifications',
  needsAnalysis = '/program/:programID/settings/needs-analysis',
  serviceTypeRoute = '/program/:programID/settings/needs-analysis/:serviceType',
}

export enum marketObjective {
  'LOSS' = 'LOSS',
  'COST' = 'COST',
  'PV' = 'PV',
  'PV_BESS' = 'PV_BESS',
}

const Settings = () => {
  const { programID, updateProgram } = {
    ...useParams<{ programID: string }>(),
    ...useProgramsContext(),
  };
  const history = useHistory();
  const isContractSelectionActive = useRouteMatch(
    Routes.contractSelection
  )?.isExact;
  const isEmailNotificationActive = useRouteMatch(
    Routes.emailNotifications
  )?.isExact;
  const isNeedsAnalysisActive = useRouteMatch(Routes.needsAnalysis)?.isExact;
  const isServiceTypeActive = useRouteMatch(Routes.serviceTypeRoute)?.isExact;

  const [edited, setEdited] = useState(false);
  const [program, setProgram] = useState<Program | null>(null); // Keep track of program here so that we can mutate
  const [selectedMarketObjective, setMarketObjective] = useState<
    marketObjective | undefined
  >(undefined);
  const [saveMessages, setSaveMessages] = useState<ISaveMessages>({});
  const [needToShowModal, setNeedToShowModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { loading: programLoading } = useRequestEffect<Program>({
    url: `/api/dsp/program/${programID}`,
    method: 'get',
    dataTransform: (data: Program) => {
      // Add defaults since we didn't migrate data
      if (data.system_market_info === null) {
        data.system_market_info = {
          avoided_generation_capacity_cost: undefined,
          num_forecasted_peak_events: undefined,
          renewable_energy_credit: undefined,
          system_peak_load: undefined,
          system_peak_threshold: undefined,
          transmission_loss: undefined,
        };
      }
      return data;
    },
    onSuccess: (data) => {
      if (data) {
        setProgram(data);
      }
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 404) {
        history.push('/');
      }
    },
    toast: {
      error: 'Could not load program settings',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { data: ISOs, loading: loadingISOs } = useRequestEffect<
    SelectInterface[]
  >({
    url: '/api/dsp/program/isos',
    method: 'get',
    refetchOnChange: [],
    initialData: [],
    dataTransform: (data: Array<string>) =>
      data.map((label) => ({ label, value: label })),
    toast: {
      error: 'Could not load list of ISOs.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const updateProp = (key: ProgramKey, value: any) => {
    if (program === null || program[key] === value) {
      // No change happened
      return;
    }
    setEdited(true);
    setProgram({
      ...program,
      [key]: value,
    });
  };

  const updateMarketInfoProp = (key: MarketKey, value: any) => {
    if (program?.system_market_info[key] === value) {
      // No change happened
      return;
    }

    setEdited(true);
    // @ts-expect-error
    setProgram({
      ...program,
      system_market_info: {
        // @ts-expect-error
        ...program.system_market_info,
        [key]: value,
      },
    });
  };

  const { makeRequest: runSave, loading: saving } = useRequest(
    `/api/dsp/program/${programID}`
  );

  const saveProgram = async () => {
    // Cannot send the workspace_name & program_id fields otherwise a 400 occurs
    const toSave: any = {
      ...program,
    };
    delete toSave.workspace_name;
    delete toSave.program_id;
    delete toSave.iso_id;
    delete toSave.system_market_info.id;
    delete toSave.system_market_info.program_id;
    await runSave({
      method: 'patch',
      body: toSave,
      toast: {
        error: 'Could not update program settings.',
        success: 'Successfully saved program settings.',
        settings: {
          autoDismiss: true,
        },
      },
      blockRequest: undefined,
      dataTransform: undefined,
      onError: undefined,
      onSuccess: (data: any) => {
        setSaveMessages({});
        setProgram(data);
        setEdited(false);
        updateProgram(data.program_id, data);
      },
    });
  };

  const loading = programLoading || loadingISOs;
  const valid = !!program?.name;

  const updateMarketObjectiveSettings = (
    newMarketObjective?: marketObjective
  ) => {
    setMarketObjective(newMarketObjective);
    if (newMarketObjective === marketObjective.LOSS) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'ANY');
      updateProp('market_control_strategy', 'LOSS_OPTIMIZE_ENROLLED_ASSETS');
    } else if (newMarketObjective === marketObjective.COST) {
      updateProp('financial_model', 'DLMP');
      updateProp('battery_control', 'ANY');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    } else if (newMarketObjective === marketObjective.PV) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'PARTICIPANT');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    } else if (newMarketObjective === marketObjective.PV_BESS) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'GRIDOS');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    }
  };

  useEffect(() => {
    if (!program) {
      return;
    }

    // See if we match one of the market objectives & select if we do
    const financialModel = program.financial_model;
    const batteryControl = program.battery_control;
    const marketControlStrategy = program.market_control_strategy;
    let newMarketObjective = undefined;

    if (
      financialModel === 'LMPD' &&
      batteryControl === 'ANY' &&
      marketControlStrategy === 'LOSS_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.LOSS;
    } else if (
      financialModel === 'DLMP' &&
      batteryControl === 'ANY' &&
      marketControlStrategy === 'COST_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.COST;
    } else if (
      financialModel === 'LMPD' &&
      batteryControl === 'PARTICIPANT' &&
      marketControlStrategy === 'COST_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.PV;
    } else if (
      financialModel === 'LMPD' &&
      batteryControl === 'GRIDOS' &&
      marketControlStrategy === 'COST_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.PV_BESS;
    }

    if (selectedMarketObjective !== newMarketObjective) {
      setMarketObjective(newMarketObjective);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  if (program && program.financial_model !== 'LMPD') {
    updateProp('skip_nodal_losses_calculation', false);
  }

  if (loading || !program) {
    return <></>;
  }

  return (
    <div className="program-settings">
      {loading && <LoadingSpinner />}
      <SettingsMenu programID={programID} />
      <div className="program-settings-setup">
        <RouterSwitch>
          <Route exact path={Routes.basic}>
            {!loading && program && (
              <Basic
                program={program}
                saving={saving}
                updateProp={updateProp}
                saveMessages={saveMessages}
                ISOs={ISOs}
                setNeedToShowModal={setNeedToShowModal}
              />
            )}
          </Route>
          <Route exact path={Routes.financialModel}>
            {!loading && program && (
              <FinancialModel
                program={program}
                saving={saving}
                updateProp={updateProp}
                updateMarketObjectiveSettings={updateMarketObjectiveSettings}
                selectedMarketObjective={selectedMarketObjective}
                updateMarketInfoProp={updateMarketInfoProp}
              />
            )}
          </Route>
          <Route exact path={Routes.advanced}>
            {!loading && program && (
              <Advanced
                program={program}
                saving={saving}
                updateProp={updateProp}
              />
            )}
          </Route>
          <Route exact path={Routes.contractSelection}>
            {!loading && program && (
              <ContractSelectionAnalysis programId={programID} />
            )}
          </Route>
          <Route exact path={Routes.emailNotifications}>
            {!loading && program && <EmailNotifications />}
          </Route>
          <Route exact path={Routes.needsAnalysis}>
            {!loading && program && <NeedsAnalysis />}
          </Route>
          <Route exact path={Routes.serviceTypeRoute}>
            {!loading && program && <NeedsAnalysisService />}
          </Route>
        </RouterSwitch>
        {!loading &&
          program &&
          !isContractSelectionActive &&
          !isEmailNotificationActive &&
          !isNeedsAnalysisActive &&
          !isServiceTypeActive && (
            <Button
              disabled={!edited || saving || !valid}
              margin="0 0 0 auto"
              onClick={() => {
                if (needToShowModal) {
                  setShowModal(true);
                } else {
                  saveProgram();
                }
              }}
            >
              Save
            </Button>
          )}
      </div>
      <Modal
        active={showModal}
        hideClose
        onClose={() => setShowModal(false)}
        confirmProps={{ onClick: saveProgram }}
        title="Confirm Program ISO Change"
        width="300px"
      >
        <div className="confirm-message">
          <div>{`Are you sure you wish to change the ISO for Program: ${program?.name}? This action will clear the mapped ISO Zones for all enrolled feeders.`}</div>
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
