import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

import { env } from 'helpers/env';
import { isMidnight } from 'helpers/time';

import {
  feederOption,
  generationMethodRaw,
  IndustryActor,
  SelectDays,
  serviceTypeOption,
  serviceTypeRaw,
  siaListItem,
} from 'types/contract-managment';
import { DER, AssetControlStrategy } from 'types/der';
import { Tenant } from 'types/tenant';
import { CreateRequestFormData } from './CreateRequest.types';

export const responseAssetsFiltered = (
  responseAssets: DER[],
  feeder?: feederOption,
  serviceType?: serviceTypeOption,
  isDso?: boolean
) => {
  if (!isEmpty(responseAssets) && !!responseAssets?.length) {
    const defaultServiceType = serviceType
      ? serviceType?.value
      : isDso
      ? serviceTypeRaw.SustainPeakManagement
      : serviceTypeRaw.MEC;

    return responseAssets
      .filter((der: DER) => {
        return der?.info?.feeder?.id === feeder?.value;
      })
      .filter((der) => der.enabled_services.includes(defaultServiceType))
      .map((der: DER) => ({
        label: String(der.info.name),
        value: String(der.rdf_id),
        curtailable: der.control_strategy === AssetControlStrategy.Curtailable,
        permissions: der.permissions,
        feederId: der.rdf_id,
        info: der.info,
        enabledServices: der.enabled_services,
        connectionExportCapacity: der.connection_agreement_export_capacity,
        connectionImportCapacity: der.connection_agreement_import_capacity,
        tcvnExportCapacity: der.tcvn_export_capacity,
        tcvnImportCapacity: der.tcvn_import_capacity,
      }));
  }
  return [];
};

export const generateTimestamp = (
  date: DateTime,
  days: number,
  hour: number
): DateTime => {
  return date.set({ hour }).plus({ days }).toUTC();
};

export const setIndustryActors = (
  tenants: Tenant[],
  userId: string
): IndustryActor[] => {
  return tenants.reduce((industryActors: IndustryActor[], tenant) => {
    if (tenant.id !== userId) {
      industryActors.push({
        label: tenant.name,
        value: tenant.id,
        ders: tenant.ders.map((d) => ({
          label: d.info?.name || d.rdf_id,
          value: d.rdf_id,
        })),
      });
    }
    return industryActors;
  }, [] as IndustryActor[]);
};

export const generateServiceWindows = (
  startTime: DateTime,
  endTime: DateTime,
  days: SelectDays,
  from: DateTime,
  to: DateTime
): { start_time: DateTime; end_time: DateTime }[] => {
  const numberedDays = [
    days['monday'],
    days['tuesday'],
    days['wednesday'],
    days['thursday'],
    days['friday'],
    days['saturday'],
    days['sunday'],
  ];
  const totalDays = endTime
    .startOf('day')
    .diff(startTime.startOf('day'), ['days']).days;

  const timestamps: { start_time: DateTime; end_time: DateTime }[] = [];
  let currentDay = startTime.weekday;
  for (let i = 0; i < totalDays + 1; i++) {
    if (numberedDays[currentDay - 1]) {
      timestamps.push({
        start_time: generateTimestamp(startTime, i, from.hour),
        end_time: generateTimestamp(
          startTime.plus({
            // Shift to next day if end time selected is 00:00:00, indicating the end of the day
            days: isMidnight(to) ? 1 : 0,
          }),
          i,
          to.hour
        ),
      });
    }
    if (currentDay >= 7) {
      currentDay = 1;
    } else {
      currentDay++;
    }
  }

  return timestamps;
};

export const defaultFormState: CreateRequestFormData = {
  id: undefined,
  openDate: undefined,
  closeDate: undefined,
  openTime: undefined,
  closeTime: undefined,
  closeAfter: undefined,
  contractModel: undefined,
  feeder: undefined,
  primary: undefined,
  industryActor: undefined,
  startDate: undefined,
  startTime: undefined,
  endDate: undefined,
  endTime: undefined,
  serviceWindow: undefined,
  serviceDays: undefined,
  serviceType: undefined,
  bidOffer: undefined,
  distributedResource: undefined,
  availabilityPrice: 0,
  utilizationPrice: 0,
  quantityGeneration: undefined,
  quantity: 0,
  hours: 0,
  systemCalculate: false,
  recurrence: undefined,
  includeActive: false,
  includePending: false,
  isCalculated: false,
  procurementVolume: 100,
  requestQuantity: 0,
  postDate: undefined,
};

export const defaultSteps = [
  {
    label: 'Request setup',
    active: true,
    completed: false,
  },
  {
    label: 'Service details',
    active: false,
    completed: false,
  },
  {
    label: 'Price and quantity',
    active: false,
    completed: false,
  },
  {
    label: 'Review',
    active: false,
    completed: false,
  },
];

export const isWSC = env.isWSC;

export const getMaxSiaDate = (siaList: siaListItem): DateTime => {
  return DateTime.fromISO(siaList.max_forecast_time).setZone('UTC');
};

export const getIsManualInput = (
  quantityGeneration: generationMethodRaw | undefined,
  quantityGenerationWatcher: generationMethodRaw | undefined
) =>
  (quantityGeneration || quantityGenerationWatcher) ===
  generationMethodRaw.manual;

export const getIsForecastBased = (
  quantityGeneration: generationMethodRaw | undefined,
  quantityGenerationWatcher: generationMethodRaw | undefined
) =>
  (quantityGeneration || quantityGenerationWatcher) ===
  generationMethodRaw.forecastBased;

export const getFormattedTime = (date: Date): DateTime => {
  return DateTime.fromJSDate(date);
};

export const validateDERs = (
  endTimeWatcher: any,
  startTimeWatcher: any,
  serviceDaysWatcher: any,
  zoneWatcher: any,
  data: CreateRequestFormData
) => {
  const { startTime, endTime, serviceDays, feeder } = data;
  let disabledDERsMessage = undefined;
  if (!startTimeWatcher && !startTime) {
    disabledDERsMessage = 'Set the service start time';
  }
  if (!endTimeWatcher && !endTime) {
    disabledDERsMessage = 'Set the service end time';
  }
  if (!serviceDaysWatcher && !serviceDays) {
    disabledDERsMessage = 'Select the service days';
  }
  if (!zoneWatcher && !feeder) {
    disabledDERsMessage = 'Select a zone';
  }
  return disabledDERsMessage;
};
export const getDateTimeFromDateString = (date: string) =>
  DateTime.fromJSDate(new Date(date));
