import React, { Fragment } from 'react';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { Icon, Line, Node } from 'types/cim';
import {
  DlmpNodalPrice,
  LmpdNodalPrice,
  PayasbidNodalPrice,
  PayasclearNodalPrice,
  MapFinancialModel,
} from 'types/map';

import './Tooltip.scss';

const areDlmpPricingEvents = (
  data:
    | DlmpNodalPrice
    | LmpdNodalPrice
    | PayasbidNodalPrice
    | PayasclearNodalPrice,
  financialModel: MapFinancialModel
): data is DlmpNodalPrice => {
  return financialModel === 'DLMP';
};

const areLmpdPricingEvents = (
  data:
    | DlmpNodalPrice
    | LmpdNodalPrice
    | PayasbidNodalPrice
    | PayasclearNodalPrice,
  financialModel: MapFinancialModel
): data is LmpdNodalPrice => {
  return financialModel === 'LMPD';
};

const arePayasbidPricingEvents = (
  data:
    | DlmpNodalPrice
    | LmpdNodalPrice
    | PayasbidNodalPrice
    | PayasclearNodalPrice,
  financialModel: MapFinancialModel
): data is PayasbidNodalPrice => {
  return financialModel === 'PAY_AS_BID';
};

const arePayasclearPricingEvents = (
  data:
    | DlmpNodalPrice
    | LmpdNodalPrice
    | PayasbidNodalPrice
    | PayasclearNodalPrice,
  financialModel: MapFinancialModel
): data is PayasbidNodalPrice => {
  return financialModel === 'PAY_AS_CLEAR';
};

const round = (value: number, decimalPlaces = 2) =>
  (
    Math.round(value * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  ).toLocaleString();

const getDlmpDivs = (
  hoveredObject: Icon | Line | Node,
  prices: DlmpNodalPrice,
  currencyFormatter: Intl.NumberFormat,
  numberFormatter: Intl.NumberFormat
) => {
  return (
    <Fragment>
      <div>
        <b>{`${hoveredObject.properties.name}`}</b>
      </div>
      {!!prices.energy && (
        <div>
          <div>-----</div>
          <div>
            <b>Energy: &nbsp;</b>
            {`${currencyFormatter.format(prices.energy)}/MWh`}
          </div>
          <div>
            <b>Residual (Losses + Voltage) &nbsp;</b>
            {`${currencyFormatter.format(prices.loss)}/MWh`}
          </div>
          <div>
            <b>Congestion &nbsp;</b>
            {`${currencyFormatter.format(prices.congestion)}/MWh`}
          </div>
          <div>-----</div>
          <div>
            <b>DLMP &nbsp;</b>
            {`${currencyFormatter.format(prices.dlmp)}/MWh`}
          </div>
          <div>-----</div>
        </div>
      )}
      {!!prices.offer && (
        <div>
          <b>Offer: &nbsp;</b>
          {`${currencyFormatter.format(prices.offer)}/MWh`}
        </div>
      )}
      {!!prices.capacity && (
        <div>
          <div>
            <b>Capacity: &nbsp;</b>
            {`${numberFormatter.format(prices.capacity)} MW`}
          </div>
          <div>
            <b>Dispatch: &nbsp;</b>
            {`${numberFormatter.format(prices.dispatch)} MW`}
          </div>
        </div>
      )}
      {!!prices.generation && (
        <div>
          <div>-----</div>
          <div>
            <b>Generation: &nbsp;</b>
            {`${numberFormatter.format(prices.generation)} MW`}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const getLmpdDivs = (
  hoveredObject: Icon | Line | Node,
  prices: LmpdNodalPrice,
  formatter: Intl.NumberFormat
) => {
  return (
    <Fragment>
      <div>
        <b>{`${hoveredObject.properties.name}`}</b>
      </div>
      <div className="dotted-line" />
      <div>
        <b>Bulk: &nbsp;</b>
        {`${formatter.format(prices.lmp)}/MWh`}
      </div>
      <div>
        <b>Distribution: &nbsp;</b>
        {`${formatter.format(prices.d)}/MWh`}
      </div>
      <div className="dotted-line" />
      <div>
        <b>LMP+D: &nbsp;</b>
        {`${formatter.format(prices.lmpd)}/MWh`}
      </div>
    </Fragment>
  );
};

const getPayasbidDivs = (
  hoveredObject: Icon | Line | Node,
  prices: PayasbidNodalPrice,
  currencyFormatter: Intl.NumberFormat
) => {
  return (
    <Fragment>
      <div>
        <b>{`${hoveredObject.properties.name}`}</b>
      </div>
      <div className="dotted-line" />
      <div>
        <b>Market Clearing Price: &nbsp;</b>
        {`${currencyFormatter.format(prices.price)}/MWh`}
      </div>
    </Fragment>
  );
};

const getPayasclearDivs = (
  hoveredObject: Icon | Line | Node,
  prices: PayasclearNodalPrice,
  currencyFormatter: Intl.NumberFormat
) => {
  return (
    <Fragment>
      <div>
        <b>{`${hoveredObject.properties.name}`}</b>
      </div>
      <div className="dotted-line" />
      <div>
        <b>Market Clearing Price: &nbsp;</b>
        {`${currencyFormatter.format(prices.price)}/MWh`}
      </div>
    </Fragment>
  );
};

interface TooltipProps {
  currency?: string;
  locale?: string;
  hoveredObject: Line | Node | Icon;
  financialModel: MapFinancialModel;
  nodalPrices: {
    [key: string]:
      | DlmpNodalPrice
      | LmpdNodalPrice
      | PayasbidNodalPrice
      | PayasclearNodalPrice;
  };
  pointerX: number;
  pointerY: number;
}

const Tooltip = ({
  currency,
  locale,
  hoveredObject,
  financialModel,
  nodalPrices,
  pointerX,
  pointerY,
}: TooltipProps) => {
  const { currencyFormatter, numberFormatter } = useLocaleFormatter(
    currency,
    locale
  );

  if (
    !hoveredObject ||
    !hoveredObject.properties ||
    !hoveredObject.properties.id
  ) {
    return null;
  }

  const objectId = hoveredObject.properties.id;
  const objectPrices = nodalPrices[objectId];

  return (
    hoveredObject && (
      <div
        className="old-tooltip"
        style={{
          position: 'absolute',
          zIndex: 1,
          pointerEvents: 'none',
          left: pointerX,
          top: pointerY,
        }}
      >
        {financialModel === 'DLMP' &&
          areDlmpPricingEvents(objectPrices, financialModel) &&
          getDlmpDivs(
            hoveredObject,
            objectPrices,
            currencyFormatter,
            numberFormatter
          )}
        {financialModel === 'LMPD' &&
          areLmpdPricingEvents(objectPrices, financialModel) &&
          getLmpdDivs(hoveredObject, objectPrices, currencyFormatter)}
        {financialModel === 'PAY_AS_BID' &&
          arePayasbidPricingEvents(objectPrices, financialModel) &&
          getPayasbidDivs(hoveredObject, objectPrices, currencyFormatter)}
        {financialModel === 'PAY_AS_CLEAR' &&
          arePayasclearPricingEvents(objectPrices, financialModel) &&
          getPayasclearDivs(hoveredObject, objectPrices, currencyFormatter)}
        <div className="dotted-line" />
        <div>
          <b>Dispatch: &nbsp;</b>
          {`${round(objectPrices.power / 1000)} kW`}
        </div>
      </div>
    )
  );
};

export default Tooltip;
