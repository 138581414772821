import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useParams, useHistory } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { Program, useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import {
  flexItemStatus,
  flexRequest,
  flexRequestRaw,
  flexResponse,
} from 'types/contract-managment';
import { Tenant } from 'types/tenant';
import { CurrentUser } from 'types/user';

import fileExportSave from 'helpers/downloadFile';
import { getRemainingTime } from 'helpers/time';

import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import SVG from 'components/SVG';
import Button from 'components/Button';

import {
  processRawFlexRequest,
  processRawFlexResponse,
} from 'routes/LongTermContracts/helpers/dataProcessers';
import AcceptRejectResponsesModal from 'routes/RequestsResponses/routes/AcceptRejectResponsesModal';
import ResponsesTable from 'routes/RequestsResponses/ResponsesTable';
import RequestsTable from 'routes/RequestsResponses/RequestsTable';
import { columns } from 'routes/RequestsResponses/RequestsTable/RequestsTable';
import { flexResponseColumns } from 'routes/RequestsResponses/ResponsesTable/ResponsesTable';
import NewFlexResponse from 'routes/RequestsResponses/routes/ViewRequest/NewFlexResponse';
import CreateRequest from 'routes/RequestsResponses/CreateRequest';
import { convertDefaultValues } from './ViewRequest.logic';

import './ViewRequest.scss';

interface requestParams {
  tenants: Tenant[];
  loggedInUser: CurrentUser;
  program: Program | null;
}

const ViewRequests = ({ tenants, loggedInUser, program }: requestParams) => {
  const [isCreateNewFlexResponseModalActive, showCreateNewFlexResponseModal] =
    useState(false);
  const [deletedId, setDeletedId] = useState<string>('');
  const [submittingResponses, setSubmittingResponses] = useState(false);
  const [selectedResponses, setSelectedResponses] = useState<Array<string>>([]);
  const [acceptingResponses, setAcceptingResponses] = useState(false);
  const [rejectingResponses, setRejectingResponses] = useState(false);
  const [showCreateFlexRequest, setShowCreateFlexRequest] = useState(false);

  const { feeders } = useProgramsContext();
  const { userIsDso, getUserTenantId } = useUserContext();

  const { requestID } = useParams<{ requestID: string }>();
  const history = useHistory();

  const programID = program?.program_id || '';

  const userTenantId = getUserTenantId();
  const isDso = userIsDso();

  const {
    data,
    loading: loadingResponses,
    refetch,
  } = useRequestEffect<{
    request: flexRequest | undefined;
    responses: flexResponse[] | undefined;
    rawRequest: flexRequestRaw | undefined;
  }>({
    url: `/api/dsp/program/${programID}/flex/requests/${requestID}`,
    initialData: {
      request: undefined,
      responses: undefined,
      rawRequest: undefined,
    },
    method: 'get',
    refetchOnChange: [programID, requestID, deletedId, submittingResponses],
    blockRequest: () => !tenants || !programID,
    dataTransform: (data: flexRequestRaw) => {
      const request = processRawFlexRequest(data, tenants, program);
      const responses = data.responses.map((response) => {
        return processRawFlexResponse(response, tenants, request);
      });
      return {
        request,
        responses,
        rawRequest: data,
      };
    },
  });

  function goBack() {
    history.push(`/program/${programID}/requests-responses`);
  }

  const deletedCallback = (id: string) => setDeletedId(id);

  // view response permissions will be done on backend
  let responses = data?.responses;
  const request = useCallback(
    () => (data && data.request ? [data.request] : undefined),
    [data]
  )();

  let isRequestor = false;
  let isResponder = false;

  const participantId = request && request[0].participantId;
  if (request && request[0].requestor?.id === userTenantId) {
    isRequestor = true;
  } else if (loggedInUser) {
    isResponder = true;
  }

  // hide responses if user is not logged in with an account associated with a participant.
  if (!loggedInUser) {
    responses = [];
  }
  /*
    Only Non DSO can create Pool request's response and Direct request's response if participant
    https://opusonesolutions.atlassian.net/wiki/spaces/TT/pages/1742372925/Flex+Auction+User+Permissions
  */
  const canCreateResponse =
    !isDso &&
    (!participantId || participantId === userTenantId) &&
    request &&
    request[0]?.status === flexItemStatus.accepted;

  const requestColumns: columns[] = [
    'zone',
    'serviceDetails',
    'availableTime',
    'bidOrOffer',
    'quantity',
    'availability',
    'utilization',
    'requestor',
    'serviceType',
    'expiryDate',
  ];

  if (isRequestor) {
    requestColumns.push('actions');
  }

  let responsesColumns: flexResponseColumns[] = [
    'quantity',
    'availability',
    'utilization',
    'responder',
    'serviceType',
    'status',
    'actions',
  ];

  if (isRequestor) {
    responsesColumns = [
      'checkboxes',
      'quantity',
      'availability',
      'utilization',
      'responder',
      'asset',
      'serviceType',
      'status',
    ];
  }

  if (isDso) {
    responsesColumns = [
      'checkboxes',
      'quantity',
      'availability',
      'utilization',
      'responder',
      'serviceType',
      'status',
    ];
  }

  const expiry = data?.request?.expiryDate as DateTime;
  const timeRemained = expiry && getRemainingTime(expiry);
  const isExpired = timeRemained === 'Expired';

  const showTables =
    !loadingResponses && programID && data && data.responses && data.request;

  function placeHolderRow() {
    return (
      <tr className="responses-table__row">
        <td
          style={{ padding: '20px 0 0 35px' }}
          className="responses-table__cell responses-table__text responses-table__text--light"
          colSpan={responsesColumns.length}
        >
          {canCreateResponse && (
            <span>
              {isRequestor
                ? 'No responses have been received.'
                : 'You have not sent any responses.'}{' '}
              <span
                onClick={() =>
                  loggedInUser && showCreateNewFlexResponseModal(true)
                }
                className={classNames({
                  'view-request__link': loggedInUser && !isExpired,
                  'responses-table__text--light': !loggedInUser,
                })}
              >
                <Tooltip
                  arrow
                  enabled={isExpired}
                  content={'Auction for request has already closed.'}
                  theme="light"
                >
                  Send response now.
                </Tooltip>
              </span>
            </span>
          )}
          {!canCreateResponse && <span>No responses have been received.</span>}
        </td>
      </tr>
    );
  }

  const { makeRequest: runExport } = useRequest(
    `/api/dsp/program/${programID}/flex/requests/${requestID}/export`
  );

  const exportResponses = async () => {
    await runExport({
      method: 'get',
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(
          data,
          headers,
          `program-${programID}-flex-request-${requestID}-responses.csv`
        );
      },
      onError: undefined,
      toast: {
        error: 'Could not export responses.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const defaultValues = useMemo(() => {
    if (data?.request && data.rawRequest) {
      return convertDefaultValues(
        feeders,
        data?.rawRequest || [],
        tenants,
        loggedInUser,
        request?.[0]
      );
    }
    return undefined;
  }, [request, data, feeders, tenants, loggedInUser]);

  return (
    <div className="view-request">
      <div className="view-request__header">
        <div
          className="view-request__header-row"
          style={{ margin: '0 10px 0 5px' }}
        >
          <Button
            variant="text"
            onClick={goBack}
            startIcon={<SVG icon="ChevronLeftIcon" fontSize="14px" />}
          >
            Back to all Requests
          </Button>
          <div className="view-request__header-right">
            {canCreateResponse && (
              <Button
                disabled={!loggedInUser || isExpired}
                tooltip={
                  isExpired
                    ? 'Auction for request has already closed.'
                    : undefined
                }
                onClick={() => showCreateNewFlexResponseModal(true)}
              >
                Create Response
              </Button>
            )}
            {!isResponder && (
              <>
                <Button
                  variant="outlined"
                  disabled={
                    selectedResponses.length === 0 ||
                    submittingResponses ||
                    !loggedInUser
                  }
                  onClick={() => {
                    setRejectingResponses(true);
                    setAcceptingResponses(false);
                  }}
                >
                  Reject
                </Button>
                <Button
                  variant="outlined"
                  disabled={
                    selectedResponses.length === 0 ||
                    submittingResponses ||
                    !loggedInUser
                  }
                  onClick={() => {
                    setAcceptingResponses(true);
                    setRejectingResponses(false);
                  }}
                >
                  Accept
                </Button>
              </>
            )}
          </div>
        </div>
        <div
          className="view-request__header-row"
          style={{ margin: '26px 10px 0' }}
        >
          <h1 className="view-request__header-title">Request and Responses</h1>
          <div className="view-request__header-right">
            <div className="view-request__dot view-request__dot--decrease">
              Decrease MW
            </div>
            <div className="view-request__dot view-request__dot--increase">
              Increase MW
            </div>
            <IconButton
              icon="DownloadIcon"
              onClick={() => exportResponses()}
              iconClass="requests-table__actions-icon"
              tooltip="Export Flex Responses"
              disabled={!responses || responses.length === 0}
            />
          </div>
        </div>
      </div>
      <div className="view-request__wrapper">
        {showTables && request !== undefined && (
          <RequestsTable
            hideFilters
            data={request}
            showColumns={requestColumns}
            programId={programID}
            deletedCallback={deletedCallback}
            showCreateRequest={() => setShowCreateFlexRequest(true)}
          />
        )}
      </div>
      <div className="view-request__body">
        <div className="view-request__body-header" />
        <div className="view-request__body-left">
          <span className="view-request__text" style={{ marginRight: '10px' }}>
            {isResponder ? 'Your Response' : 'Responses Received'}
          </span>
          <SVG icon="UsersIcon" fontSize="20px" />
        </div>
        {showTables &&
          responses !== undefined &&
          data &&
          data.request !== undefined && (
            <div className="view-request__body-right">
              <ResponsesTable
                request={data.request}
                showColumns={responsesColumns}
                loggedInUser={loggedInUser}
                data={responses}
                programId={programID}
                selectedResponses={selectedResponses}
                setSelectedResponses={setSelectedResponses}
                placeHolderRow={placeHolderRow}
                deletedCallback={deletedCallback}
              />
              <AcceptRejectResponsesModal
                programID={programID}
                loggedInUser={loggedInUser}
                request={data.request}
                requestID={requestID}
                responses={responses}
                selectedResponses={selectedResponses}
                acceptingResponses={acceptingResponses}
                rejectingResponses={rejectingResponses}
                setSelectedResponses={setSelectedResponses}
                setAcceptingResponses={setAcceptingResponses}
                setRejectingResponses={setRejectingResponses}
                setSubmittingResponses={setSubmittingResponses}
              />
            </div>
          )}
      </div>
      {data && data.request && loggedInUser && !isExpired && (
        <NewFlexResponse
          show={isCreateNewFlexResponseModalActive}
          updateParent={(value: boolean) => {
            refetch();
            showCreateNewFlexResponseModal(value);
          }}
          programID={programID}
          request={data.request}
          derId={data.request.derId}
          setSubmittingResponses={setSubmittingResponses}
        />
      )}
      {showCreateFlexRequest && (
        <CreateRequest
          isUpdate
          open={showCreateFlexRequest}
          onClose={() => setShowCreateFlexRequest(false)}
          currentUser={loggedInUser}
          tenants={tenants}
          programID={programID}
          refreshTrigger={{
            value: true,
            setter: refetch,
          }}
          setJobId={() => false}
          defaultValues={defaultValues}
        />
      )}
    </div>
  );
};

export default ViewRequests;
