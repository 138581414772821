import { DateTime, Settings } from 'luxon';
import { sortWeekDays } from './date';

import { SelectDays, peakType } from 'types/contract-managment';
import { Program } from 'contexts/ProgramsContext';
import { isString } from 'lodash';

interface Options {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  expired: string;
}

export function getRemainingTime(
  expiry: DateTime,
  options = {
    expired: 'Expired',
    days: 'd',
    hours: 'h',
    minutes: 'm',
    seconds: 's',
  } as Options
): string {
  const remaining = expiry.diff(DateTime.local());
  const seconds = remaining.as('seconds');
  if (seconds <= 0) {
    return options.expired;
  }
  if (remaining.as('days') > 1) {
    return `${Math.floor(remaining.as('days'))}${options.days}`;
  } else if (remaining.as('hours') > 1) {
    return `${Math.floor(remaining.as('hours'))}${options.hours}`;
  } else if (remaining.as('minutes') > 1) {
    return `${Math.floor(remaining.as('minutes'))}${options.minutes}`;
  }
  return `${Math.floor(seconds)}${options.seconds}`;
}

export function convertNumberstoReadableTime(
  number: number,
  numberIn: 'seconds' | 'milliseconds'
): string | undefined {
  if (numberIn === 'seconds') {
    let newTime = number;
    if (newTime < 1) return undefined;
    if (newTime < 60) return `${newTime}s`;
    newTime /= 60;
    if (newTime < 60) return `${newTime}m`;
    newTime /= 60;
    if (newTime < 24) return `${newTime}h`;
    newTime /= 24;
    if (newTime < 7) return `${newTime}d`;
    newTime /= 7;
    return `${newTime}w`;
  }

  // milliseconds
  let newTime = number / 1000;
  if (newTime < 1) return undefined;
  if (newTime < 60) return `${newTime}s`;
  newTime /= 60;
  if (newTime < 60) return `${newTime}m`;
  newTime /= 60;
  if (newTime < 24) return `${newTime}h`;
  newTime /= 24;
  if (newTime < 7) return `${newTime}d`;
  newTime /= 7;
  return `${newTime}w`;
}

export function getServiceTimeRangeDetails(
  peak: peakType,
  customPeakDays: SelectDays | null,
  customPeakStartTime: DateTime | undefined,
  customPeakEndTime: DateTime | undefined
) {
  let days: string[] = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
  let startTime = '0:00';
  let endTime = '23:59';
  let timeDuration = '24';

  if (peak === 'Off Peak') {
    days = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri'];
    startTime = '7:00';
    endTime = '21:59';
    timeDuration = '15';
  }

  if (peak === 'Peak') {
    days = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri'];
    startTime = '22:00';
    endTime = '6:59';
    timeDuration = '9';
  }

  if (
    peak === 'Custom' &&
    customPeakDays &&
    customPeakStartTime &&
    customPeakEndTime
  ) {
    const selectedDays: string[] = [];
    Object.keys(customPeakDays).forEach((day) => {
      if (customPeakDays[day]) {
        selectedDays.push(day[0].toUpperCase() + day.substring(1, 3));
      }
    });
    days = sortWeekDays(selectedDays, true);

    const timeDurationObject = customPeakEndTime.diff(customPeakStartTime, [
      'hours',
      'minutes',
    ]);
    const timeDurationMinutes = Math.round(timeDurationObject.minutes);
    const timeDurationHours = Math.floor(
      timeDurationMinutes < 60
        ? timeDurationObject.hours
        : timeDurationObject.hours + 1
    );
    timeDuration =
      timeDurationHours > 0 ? timeDurationHours.toString().concat('h ') : '';
    timeDuration = timeDuration.concat(
      timeDurationMinutes > 0 && timeDurationMinutes < 60
        ? timeDurationMinutes.toString().concat('m')
        : ''
    );

    startTime = customPeakStartTime.toLocaleString({
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
    });
    endTime = customPeakEndTime.toLocaleString({
      hour: 'numeric',
      minute: '2-digit',
      hour12: false,
    });
  }
  return {
    days,
    startTime,
    endTime,
    timeDuration,
  };
}

export function minutesToMilliseconds(mins: number): number {
  return mins * 60 * 1000;
}

export function getSystemTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/** Sets luxon's timezone globally. Defaults to system time if undefined.
 * @param {string} timezone
 */
export function setGlobalTimezone(timezone: string | undefined) {
  let tz = 'local';
  if (timezone) {
    tz = timezone;
  }
  Settings.defaultZone = tz;
}

/** Returns the difference in milliseconds between the system timezone and the program timezone if set.
 * @returns {number}
 */
export function getSystemAndProgramOffset(): number {
  const systemTimezone = getSystemTimezone();
  const systemOffset = DateTime.local().setZone(systemTimezone).offset;
  const programOffset = DateTime.local().offset;
  const systemProgramDifference = minutesToMilliseconds(
    Math.abs(systemOffset - programOffset)
  );
  if (systemOffset > programOffset) {
    return systemProgramDifference * -1;
  }
  return systemProgramDifference;
}

/** Returns the system time with program time offset applied
 * @param {DateTime} datetime
 * @returns {number}
 */
export function getProgramOffsetTimeMilliseconds(datetime: DateTime): number {
  const systemProgramTimezoneOffset = getSystemAndProgramOffset();
  const timeInMilliseconds = datetime.valueOf();
  return timeInMilliseconds + systemProgramTimezoneOffset;
}

/** Removes the program offset time from system time. Returns time in ms
 * @param {Date} date
 * @returns {number}
 */
export function systemTimeToProgramTime(date: Date): number {
  const systemProgramTimezoneOffset = getSystemAndProgramOffset();
  const timeMilliseconds = date.getTime();
  return timeMilliseconds - systemProgramTimezoneOffset;
}

/** Removes the program offset time from system time. Returns time in luxon datetime.
 * @param {Date} date
 * @returns {DateTime}
 */
export function jsDateToDateTimeProgramTimezone(date: Date): DateTime {
  const time = systemTimeToProgramTime(date);
  return DateTime.fromMillis(time);
}

/** Get procurement gate time from program. Returns time in luxon datetime.
 * @param {Program} program
 * @returns {DateTime}
 */
export const getTimeGateDateTime = (program: Program | null): DateTime => {
  const parsedHours = program?.procurement_gate_time?.split(':');
  const gateWeekday = program?.procurement_gate_weekday || 4;
  return DateTime.local()
    .startOf('day')
    .set({
      hour: parseInt(parsedHours?.[0] || '18'),
      minute: parseInt(parsedHours?.[1] || '0'),
      weekday: !isString(gateWeekday) ? gateWeekday : 3 + 1,
    });
};

/** Fix the issue when an extra date is added if the end time is 0:00
 * @param {DateTime} endTime
 * @returns {boolean}
 */
export const isMidnight = (endTime: DateTime): boolean =>
  endTime.hour === 0 && endTime.minute === 0 && endTime.second === 0;
