import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import SVG from 'components/SVG';

import './ErrorPage.scss';

interface Props {
  type: '404' | '500' | 'ninja';
}

function ErrorPage({ type }: Props) {
  const history = useHistory();
  function goBack() {
    history.goBack();
  }

  function goHome() {
    history.push(`/`);
  }

  function contactUs() {
    window.open(
      'https://opusonesolutions.atlassian.net/servicedesk/customer/portal/8',
      '_blank'
    );
  }

  function renderButtons() {
    return (
      <div className="error-page__buttons">
        <Button
          variant="outlined"
          customClasses={{
            customButtonClass: 'error-page__button',
          }}
          onClick={contactUs}
        >
          Contact us
        </Button>
        <Button
          variant="outlined"
          customClasses={{
            customButtonClass: 'error-page__button',
          }}
          onClick={goHome}
        >
          Home
        </Button>
        <Button
          customClasses={{
            customButtonClass: 'error-page__button',
          }}
          onClick={goBack}
        >
          Go back
        </Button>
      </div>
    );
  }

  function generatePage() {
    switch (type) {
      case '500':
        return (
          <div className="error-page__500">
            <SVG icon="LightBulb" className="error-page__500-light" />
            <SVG
              icon="BrokenLightBulb"
              className="error-page__500-light-broken"
            />
            <h1 className="error-page__title" style={{ marginTop: '125px' }}>
              500
            </h1>
            <h2 className="error-page__header">
              Oops! Looks like there is an overload.
            </h2>
            <p className="error-page__content-500">
              Page is currently unavailable! But, we are working to resolve the
              internal errors. Please try again in sometime and feel free to
              contact us if the problem persists.
            </p>
            {renderButtons()}
          </div>
        );
        break;

      case '404':
        return (
          <div className="error-page__404">
            <SVG icon="Error404" styles={{ marginTop: '67px' }} />
            <h1 className="error-page__header" style={{ marginTop: '50px' }}>
              Oh no! We lost the connection.
            </h1>
            <p className="error-page__content-404">
              Page is currently unavailable! But, our team of experts is working
              to resolve this issue. Please try again in sometime and feel free
              to contact us if the problem persists.
            </p>
            {renderButtons()}
          </div>
        );
        break;

      case 'ninja':
      default:
        return (
          <div className="error-page__ninja">
            <SVG icon="Ninja" className="error-page__ninja-svg" />
            <div className="error-page__column">
              <h1
                className="error-page__header"
                style={{ marginBottom: '28px' }}
              >
                Oh no! The angry ninja drop kicked one of our servers.
              </h1>
              <p className="error-page__content-500">
                Page is currently unavailable! But, we are working to resolve
                the internal errors. Please try again in sometime and feel
                contact us if the problem persists.
              </p>
              {renderButtons()}
            </div>
          </div>
        );
        break;
    }
  }

  return <div className="error-page">{generatePage()}</div>;
}

export default ErrorPage;
