import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { closeAfterEnum } from 'routes/RequestsResponses/CreateRequest/CreateRequestSetup/CreateRequestSetup.types';

import {
  MarketType,
  NeedsAnalysisType,
  useNeedsAnalysis,
} from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';
import { serviceType, serviceTypeRaw } from 'types/contract-managment';
import {
  useFormControlsReturnTypeWeekAhead,
  useFormControlsReturnTypeDayAhead,
} from './NeedsAnalysisForm/NeedsAnalysisForm.logic';
import { DateTime } from 'luxon';
import { getDateTimeFromWeekDay } from 'helpers/date';
import { closeAfterOptions } from 'routes/RequestsResponses/CreateRequest/CreateRequestSetup/CreateRequestSetup.logic';

export const defaultMarketType: NeedsAnalysisType = {
  publish_request_after_hours: 0,
  window_start_time: '',
  market_type: '',
  feeder_enrollments: [],
  saturday: false,
  service_type: '',
  thursday: false,
  wednesday: false,
  close_request_after_hours: 24,
  is_active: false,
  sunday: false,
  analysis_day: '',
  window_end_time: '',
  include_pending_contracts: false,
  monday: false,
  analysis_time: '',
  program_id: '',
  friday: false,
  procurement_volume: 0,
  id: '',
  tuesday: false,
  include_accepted_contracts: false,
  publish_request_day: '',
  publish_request_time: '',
  close_request_date: undefined,
  close_after_type: closeAfterEnum.TWENTY_FOUR_HOURS,
};

export const useMarketType = (dataRefetchTrigger: boolean) => {
  const { loading, data } = useNeedsAnalysis(dataRefetchTrigger);
  const { programID, serviceType } =
    useParams<{ programID: string; serviceType: string }>();
  const [weekAheadData, setWeekAheadData] = useState<NeedsAnalysisType>();
  const [dayAheadData, setDayAheadData] = useState<NeedsAnalysisType>();

  const getDataByMarketType = (
    marketType: string,
    formControlsWeekAhead: useFormControlsReturnTypeWeekAhead,
    formControlsDayAhead: useFormControlsReturnTypeDayAhead
  ) => {
    const setValue =
      marketType === MarketType.weekAhead
        ? formControlsWeekAhead.setValueWeekAhead
        : formControlsDayAhead.setValueDayAhead;
    const dataMarket = data
      ?.filter(
        (result: NeedsAnalysisType) => result.service_type === serviceType
      )
      .find((result: NeedsAnalysisType) => result.market_type === marketType);

    const dataToReturn = dataMarket || {
      ...defaultMarketType,
      service_type: serviceType,
      market_type: marketType,
      program_id: programID,
    };

    const publishDateTime = deriveDateTimeHoursAfter(
      dataToReturn.analysis_day,
      dataToReturn.analysis_time,
      dataToReturn.publish_request_after_hours
    );

    const closeDateTime =
      publishDateTime.isValid && publishDateTime
        ? deriveDateTimeHoursAfter(
            publishDateTime.weekdayLong.toUpperCase(),
            publishDateTime.toFormat('HH:mm:ss'),
            dataToReturn.close_request_after_hours
          )
        : undefined;

    setValue('id', dataToReturn.id);
    setValue('market_type', marketType);
    setValue(
      'publish_request_after_hours',
      dataToReturn.publish_request_after_hours
    );
    setValue('feeder_enrollments', dataToReturn.feeder_enrollments);
    setValue('service_typ', dataToReturn.service_type);
    setValue(
      'close_request_after_hours',
      dataToReturn.close_request_after_hours
    );
    setValue('is_active', dataToReturn.is_active);
    setValue('analysis_day', dataToReturn.analysis_day);
    setValue('analysis_time', dataToReturn.analysis_time);
    setValue('window_start_time', dataToReturn.window_start_time);
    setValue('window_end_time', dataToReturn.window_end_time);
    setValue(
      'include_pending_contracts',
      dataToReturn.include_pending_contracts
    );
    setValue(
      'include_accepted_contracts',
      dataToReturn.include_accepted_contracts
    );
    setValue('program_id', dataToReturn.program_id);
    setValue('procurement_volume', dataToReturn.procurement_volume);
    setValue(
      'publish_request_day',
      publishDateTime.isValid
        ? publishDateTime.weekdayLong.toUpperCase()
        : undefined
    );
    setValue(
      'publish_request_time',
      publishDateTime.isValid
        ? publishDateTime.toFormat('HH:mm:ss')
        : defaultMarketType.publish_request_time
    );
    setValue(
      'close_request_day',
      closeDateTime && closeDateTime.isValid
        ? closeDateTime.weekdayLong.toUpperCase()
        : undefined
    );
    setValue(
      'close_request_time',
      closeDateTime && closeDateTime.isValid
        ? closeDateTime.toFormat('HH:mm:ss')
        : undefined
    );
    setValue(
      'close_request_date',
      closeDateTime && closeDateTime.isValid ? closeDateTime : undefined
    );
    setValue(
      'close_after_type',
      dataToReturn
        ? deriveCloseAfterTypeFromTime(
            dataToReturn.close_request_after_hours -
              dataToReturn.publish_request_after_hours
          )
        : defaultMarketType.close_after_type
    );
    setValue('monday', dataToReturn.monday);
    setValue('tuesday', dataToReturn.tuesday);
    setValue('wednesday', dataToReturn.wednesday);
    setValue('thursday', dataToReturn.thursday);
    setValue('friday', dataToReturn.friday);
    setValue('saturday', dataToReturn.saturday);
    setValue('sunday', dataToReturn.sunday);
    setValue('serviceDays', {
      monday: dataToReturn.monday,
      tuesday: dataToReturn.tuesday,
      wednesday: dataToReturn.wednesday,
      thursday: dataToReturn.thursday,
      friday: dataToReturn.friday,
      saturday: dataToReturn.saturday,
      sunday: dataToReturn.sunday,
    });

    return dataToReturn;
  };

  return {
    loading,
    weekAheadData,
    dayAheadData,
    setWeekAheadData,
    setDayAheadData,
    getDataByMarketType,
  };
};

const deriveDateTimeHoursAfter = (
  startDay: string,
  startTime: string,
  afterHours: string | number
) => {
  const hours = Number(afterHours);
  const time = DateTime.fromFormat(startTime, 'H:mm:ss');
  const startDate = getDateTimeFromWeekDay(startDay).set({
    hour: time.hour,
    minute: time.minute,
    second: time.second,
    millisecond: time.millisecond,
  });
  const targetDate = startDate.plus({ hours: hours });
  return targetDate;
};

const deriveCloseAfterTypeFromTime = (closeAfterHours: string | number) => {
  const hours = Number(closeAfterHours);

  const toReturn =
    closeAfterOptions.find((option) => option.hours === hours)?.label ||
    'Custom';

  return toReturn;
};

export const serviceTypes: Record<string, string> = {
  [serviceTypeRaw.SustainPeakManagement]: serviceType.SustainPeakManagement,
  [serviceTypeRaw.SecurePeakManagement]: serviceType.SecurePeakManagement,
  [serviceTypeRaw.SustainExportManagement]: serviceType.SustainExportManagement,
  [serviceTypeRaw.DynamicPeakManagement]: serviceType.DynamicPeakManagement,
};
