import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { AxiosError } from 'axios';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import { flexContract, acceptReject } from 'types/contract-managment';
import { CurrentUser } from 'types/user';

import Button from 'components/Button';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import ContractsTable from 'routes/LongTermContracts/ContractsTable';
import { columns } from 'routes/LongTermContracts/ContractsTable/ContractsTable.logic';

import './AcceptRejectContracts.scss';

interface Props {
  showModal: boolean;
  acceptOrReject: acceptReject;
  contract: flexContract;
  programID: string;
  loggedInUser: CurrentUser;
  onCancel: () => void;
  onSuccess: () => void;
}

const AcceptRejectContracts: React.FC<Props> = ({
  showModal,
  acceptOrReject,
  contract,
  programID,
  loggedInUser,
  onCancel,
  onSuccess,
}) => {
  const acceptRejectLowerCase = acceptOrReject || ''.toLowerCase();
  const urlAccept = `/api/dsp/program/${programID}/flex/contracts/accept`;
  const urlReject = `/api/dsp/program/${programID}/flex/contracts/reject`;
  const { makeRequest: acceptRequest } = useRequest(urlAccept);
  const { makeRequest: rejectRequest } = useRequest(urlReject);
  const { addToast } = useToasts();

  const showColumns: columns[] = [
    'servicePeriod',
    'serviceType',
    'requestor',
    'quantity',
    'utilization',
    'availability',
  ];

  const submit = () => {
    const contract_ids = [contract.id];
    const acceptMessage = 'Contract sucesfully accepted!';
    const rejectMessage = 'Contract sucesfully rejected!';
    const settings = {
      method: 'post' as any,
      body: { contract_ids },
      onSuccess: onSuccess,
      onError: (error: AxiosError) => {
        console.error(error.response?.data?.message);
        addToast(`Error ${acceptRejectLowerCase}ing responses.`, {
          appearance: 'error',
        });
        onCancel();
      },
      toast: {
        success: acceptOrReject === 'Accept' ? acceptMessage : rejectMessage,
        settings: { autoDismiss: true },
      },
    };
    if (acceptOrReject === 'Accept') {
      acceptRequest(settings);
    } else {
      rejectRequest(settings);
    }
  };

  return (
    <Modal
      active={showModal}
      title={`${acceptOrReject} pending contracts`}
      onClose={onCancel}
      classes={{ contentClass: 'pending-contracts__modal-bg' }}
      styles={{
        contentStyles: { minWidth: '750px' },
      }}
      customFooter={
        <div className="accept-reject-contracts__button-wrap">
          <Button onClick={submit}>{acceptOrReject || ''}</Button>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      }
    >
      <ContractsTable
        refetchContracts={() => undefined}
        data={[contract]}
        showColumns={showColumns}
        programID={programID}
        loggedInUser={loggedInUser}
        hideFilters={true}
      />
    </Modal>
  );
};

export default AcceptRejectContracts;
