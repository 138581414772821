import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const CheckIcon = ({
  title,
  titleId,
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.75 15.45c-.2 0-.3-.1-.4-.2l-4.2-4.2c-.2-.2-.2-.6 0-.9.2-.3.6-.2.9 0l3.7 3.7 8.7-8.7c.2-.2.6-.2.9 0 .2.2.2.6 0 .9l-9.2 9.2c-.1.1-.2.2-.4.2Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default CheckIcon;
