import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Cell } from 'react-table';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { DERIcons, DERType } from 'types/der';
import {
  flexRequest,
  flexResponse,
  flexItemStatus,
} from 'types/contract-managment';
import { CurrentUser } from 'types/user';

import SVG from 'components/SVG';
import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'routes/LongTermContracts/components/Modal';
import IconButton from 'components/IconButton';

import Checkbox from 'routes/RequestsResponses/components/Checkbox';
import {
  getStatus,
  renderPerson,
  renderCost,
  renderLight,
  renderQuantity,
} from 'routes/LongTermContracts/helpers/tableFunctions';

import './ResponsesTable.scss';

// List items should match the selector from column definitions.
export type flexResponseColumns =
  | 'checkboxes'
  | 'quantity'
  | 'availability'
  | 'utilization'
  | 'responder'
  | 'asset'
  | 'serviceType'
  | 'status'
  | 'actions';

interface ResponsesTableParams {
  showColumns: flexResponseColumns[];
  data: flexResponse[];
  request: flexRequest;
  loggedInUser: CurrentUser;
  programId: string;
  selectedResponses: string[];
  setSelectedResponses: (selectedResponses: Array<string>) => void;
  placeHolderRow?: () => React.ReactElement;
  deletedCallback?(id: string): void;
  customClasses?: {
    tableRowClass?: string;
  };
}

/**
 * Used to generate a specialzed version of the table component: long term contracts table.
 * Handles the boilerplate code and allows features to easily be turned on or off.
 */
const ResponsesTable = ({
  data,
  request,
  showColumns,
  programId,
  loggedInUser,
  selectedResponses,
  setSelectedResponses,
  placeHolderRow,
  deletedCallback,
  customClasses,
}: ResponsesTableParams) => {
  const { selectedProgram: program, feeders } = useProgramsContext();
  const { currencyFormatter, longCurrencyFormatter, currencySymbol } =
    useLocaleFormatter(program?.currency, program?.locale);

  const renderCheckboxes = useMemo(
    () => (cellInfo: any) => {
      const { original } = cellInfo.cell.row;
      const responseId = String(original.id);
      const selectedIndex = selectedResponses.indexOf(responseId);
      const status = getStatus(original, 'responses-table');

      const checked = selectedIndex > -1 ? true : false;
      if (status !== flexItemStatus.pending) return null;
      return (
        <Checkbox
          id={'test'}
          checked={checked}
          onClick={() => {
            if (checked)
              setSelectedResponses(
                selectedResponses
                  .slice(0, selectedIndex)
                  .concat(selectedResponses.slice(selectedIndex + 1))
              );
            else setSelectedResponses(selectedResponses.concat(responseId));
          }}
        />
      );
    },
    [selectedResponses, setSelectedResponses]
  );

  const quantityMemoed = useMemo(
    () => (cellInfo: any) => renderQuantity(cellInfo, 'responses-table'),
    []
  );

  const kWhDollarsMemoed = useMemo(
    () => (cellInfo: any, col: string) =>
      renderCost(
        cellInfo,
        'responses-table',
        currencyFormatter,
        longCurrencyFormatter,
        col
      ),
    [currencyFormatter, longCurrencyFormatter]
  );

  const personMemoed = useMemo(
    () => (cellInfo: any) => renderPerson(cellInfo, feeders, 'responses-table'),
    [feeders]
  );

  const renderAsset = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const icon = DERIcons[value as DERType];
      return (
        <div className="responses-table--centered">
          <SVG icon={icon} fontSize="22px" />
        </div>
      );
    },
    []
  );

  const renderService = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { original } = cellInfo.cell.row;
      const status = getStatus(original, 'responses-table');
      const cancelled =
        status === flexItemStatus.expired ||
        status === flexItemStatus.cancelled;

      let textClass = 'responses-table__text';
      if (cancelled) {
        textClass += ' responses-table__text--light';
      }
      return (
        <div>
          {value !== request.service && (
            <SVG
              icon="WarningRoundedIcon"
              fontSize="10px"
              className={classNames('responses_table__warning', {
                'responses-table__warning--cancelled': cancelled,
              })}
            />
          )}
          <span className={textClass}>{value}</span>
        </div>
      );
    },
    [request.service]
  );

  const lightMemoed = useMemo(
    () => (cellInfo: any) => renderLight(cellInfo, 'responses-table'),
    []
  );

  const [selectedId, selectId] = useState<string>('');
  const { makeRequest: cancelRequest, loading: deleting } = useRequest(
    `/api/dsp/program/${programId}/flex/response/${selectedId}/cancel`
  );

  const handleDeleteResponse = () => {
    cancelRequest({
      method: 'post',
      body: {},
      toast: {
        success: 'Response cancelled successfully.',
        error: 'Error cancelling response.',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        deletedCallback && deletedCallback(selectedId);
        selectId('');
      },
      onError: undefined,
    });
  };

  const renderActions = useMemo(
    () => (cellInfo: any) => {
      const response = cellInfo.cell.row.original;
      const cancelled = response?.status === flexItemStatus.cancelled;
      const userEmail = response?.responder?.users.find(
        (user: CurrentUser) => user.email === loggedInUser?.email
      );
      const isResponseOwner = userEmail.email === loggedInUser?.email;
      const responseEditable = !cancelled && isResponseOwner;

      let disabledMessage = '';
      if (!isResponseOwner)
        disabledMessage = 'Response can only be cancelled by its author.';
      if (cancelled)
        disabledMessage = 'The response has already been cancelled.';

      return (
        <div className="responses-table__actions">
          <Tooltip
            arrow
            enabled={!responseEditable}
            content={!responseEditable ? disabledMessage : 'Cancel'}
            theme="light"
          >
            <IconButton
              customClasses={{
                customButtonClass: 'responses-table__actions-button',
              }}
              icon="XIcon"
              disabled={!responseEditable}
              onClick={() => selectId(cellInfo.cell.value)}
              customStyles={{ customButtonStyles: { margin: '0 5px' } }}
              iconClass="responses-table__actions-icon"
            />
          </Tooltip>
        </div>
      );
    },
    [loggedInUser?.email]
  );

  /**
   * First part of this function defines all the possible columns and their logic.
   * Second part handles figuring out what columns to render.
   */
  const columns = useMemo(() => {
    const availableColumns = {
      checkboxes: {
        Header: '',
        accessor: 'derRDFID',
        Cell: renderCheckboxes,
      },
      quantity: {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: quantityMemoed,
      },
      availability: {
        Header: `Availability (${currencySymbol}/kW/h)`,
        accessor: 'availability',
        Cell: kWhDollarsMemoed,
      },
      utilization: {
        Header: `Utilization (${currencySymbol}/kWh)`,
        accessor: 'utilization',
        Cell: (cell: any) => kWhDollarsMemoed(cell, 'utilization'),
      },
      responder: {
        Header: 'Responder',
        accessor: 'responderName',
        Cell: personMemoed,
      },
      asset: {
        Header: 'Asset',
        accessor: 'derType',
        Cell: renderAsset,
      },
      serviceType: {
        Header: 'Service Type',
        accessor: 'serviceType',
        Cell: renderService,
      },
      status: {
        Header: 'Accept/Reject',
        accessor: 'status',
        Cell: lightMemoed,
      },
      actions: {
        Header: '',
        accessor: 'id',
        Cell: renderActions,
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [
    renderCheckboxes,
    quantityMemoed,
    kWhDollarsMemoed,
    personMemoed,
    renderAsset,
    renderService,
    renderActions,
    lightMemoed,
    showColumns,
    currencySymbol,
  ]);

  function customHeaderCells(
    column: any,
    index: number,
    tableHeadCellClass: string | undefined,
    renderSortedArrows: (
      sorted: boolean,
      sortedDesc: boolean | undefined
    ) => React.ReactElement | undefined
  ) {
    const centerHeaders = ['derType', 'evaluation', 'status'];
    let style = {};
    if (column.id === 'quantity' && !showColumns.includes('checkboxes')) {
      style = { paddingLeft: '35px' };
    }

    if (centerHeaders.includes(column.id)) {
      return (
        <th
          {...column.getHeaderProps(column.getSortByToggleProps())}
          key={index}
          className={tableHeadCellClass}
          style={style}
        >
          <div className="responses-table--centered">
            {column.render('Header')}
            {renderSortedArrows(column.isSorted, column.isSortedDesc)}
          </div>
        </th>
      );
    }

    return (
      <th
        {...column.getHeaderProps(column.getSortByToggleProps())}
        key={index}
        className={tableHeadCellClass}
        style={style}
      >
        {column.render('Header')}
        {renderSortedArrows(column.isSorted, column.isSortedDesc)}
      </th>
    );
  }

  function customBodyCells(
    cell: Cell,
    index: number,
    tableCellClass: string | undefined
  ) {
    let style = {};
    if (cell.column.id === 'quantity' && !showColumns.includes('checkboxes')) {
      style = { paddingLeft: '35px' };
    }
    return (
      <td
        {...cell.getCellProps()}
        key={index}
        className={tableCellClass}
        style={style}
      >
        {cell.render('Cell')}
      </td>
    );
  }

  const TableStyles = {
    tableClass: 'responses-table',
    tableHeaderClass: 'responses-table__header',
    tableHeaderCellClass: 'responses-table__header-cell',
    tableBodyClass: 'responses-table__body',
    tableBodyRowClass: classNames(
      'responses-table__row',
      customClasses?.tableRowClass
    ),
    tableBodyCellClass: 'responses-table__cell',
  };

  return (
    <>
      <Table
        data={data}
        columns={columns}
        tableType="HTML"
        placeHolderRow={placeHolderRow}
        customClasses={TableStyles}
        customHeaderCellRenderFunction={customHeaderCells}
        customCellRenderFunction={customBodyCells}
      />
      <Modal
        active={selectedId !== ''}
        reverseFooterButtons={true}
        hideClose
        cancelProps={{ disabled: deleting, label: 'No' }}
        confirmProps={{
          disabled: deleting,
          label: 'Yes',
          onClick: handleDeleteResponse,
        }}
        onClose={() => selectId('')}
        title="Are you sure you want to cancel this response?"
      />
    </>
  );
};

export default ResponsesTable;
