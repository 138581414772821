import React, { useState } from 'react';

import Button from 'components/Button';
import Menu from 'components/Menu';
import MenuItem from 'components/Menu/MenuItem';
import SVG from 'components/SVG';
import Switch from 'components/Switch';

import { feederOption } from 'types/contract-managment';
import { NeedsAnalysisType } from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';

export const GenerateMenu = (
  feederList: feederOption[],
  onFieldChangeHandler: (name: keyof NeedsAnalysisType, value: any) => void,
  feeder_enrollments: string[]
) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const open = Boolean(actionsAnchorEl);

  const openActionMenu = (event: any) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const closeActionMenu = () => {
    setActionsAnchorEl(null);
  };

  const selectAllCheckboxes = () => {
    onFieldChangeHandler(
      'feeder_enrollments',
      feederList.map((value) => {
        return value.value;
      })
    );
  };

  const deselectAllCheckboxes = () => {
    onFieldChangeHandler('feeder_enrollments', []);
  };

  const allSelected = feeder_enrollments?.length === feederList?.length;

  return (
    <>
      <Button
        onClick={openActionMenu}
        customClasses={{
          customButtonClass:
            'needs-analysis-form__input needs-analysis-form__input-zones',
        }}
        variant="outlined"
        endIcon={<SVG icon="ChevronDownIcon" />}
        disabledAnimation={true}
      >
        {feeder_enrollments?.length}/{feederList?.length} selected
      </Button>
      <Menu
        id="test-menu"
        anchorEl={actionsAnchorEl}
        open={open}
        onClose={closeActionMenu}
      >
        <MenuItem
          type="checkbox"
          selected={allSelected}
          onChange={() => {
            if (!allSelected) {
              selectAllCheckboxes();
            } else {
              deselectAllCheckboxes();
            }
          }}
        >
          {!allSelected ? 'Select' : 'Clear'} all
        </MenuItem>
        {feederList.map((value, index) => {
          return (
            <MenuItem
              key={`checkbox_for_${value.value}`}
              type="checkbox"
              selected={feeder_enrollments?.includes(value.value)}
              onChange={() => {
                if (!feeder_enrollments.includes(value.value)) {
                  onFieldChangeHandler('feeder_enrollments', [
                    ...feeder_enrollments,
                    value.value,
                  ]);
                } else {
                  onFieldChangeHandler(
                    'feeder_enrollments',
                    feeder_enrollments.filter((v: any) => v !== value.value)
                  );
                }
              }}
            >
              {value.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export const GenerateContractSwitches = (
  id: keyof NeedsAnalysisType,
  label: string,
  switchState: boolean,
  onFieldChangeHandler: (name: keyof NeedsAnalysisType, value: any) => void
) => {
  return (
    <Switch
      id={id.toString()}
      checked={switchState}
      onClick={() => {
        onFieldChangeHandler(id, !switchState);
      }}
      label={label}
      type="primary"
      classNames={{
        switchClass: 'needs-analysis-form__input-switch',
      }}
    />
  );
};
