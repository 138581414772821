export enum TableColumnsList {
  recipient_type = 'recipient_type',
  service_type = 'service_type',
  recurrence = 'recurrence',
  message = 'message',
  active = 'active',
}

export type ModalProps = {
  isShow: boolean;
  content?: Record<string, any>;
  type?: 'message' | 'preview';
};

export interface NotificationItemProp {
  delay: number | null;
  body: string;
  service_type: string;
  active: boolean;
  program_id: string;
  recipient_type: string;
  triggering_event_type: string;
  subject: string;
  header: string;
  id?: string;
  newId?: string;
}

export interface NotificationRulesProp {
  is_reminder: false;
  recipient: Array<string>;
  service_type: Array<string | null>;
}

export type NotificationListItemProp = {
  value: string;
  title: string;
  notifications: NotificationItemProp[];
  settings: NotificationRulesProp;
};

export interface NotificationsStateProp {
  title: string;
  items: NotificationListItemProp[];
}
