import { DateTime } from 'luxon';

import { DERType } from './der';
import { Tenant } from './tenant';

export enum ContractModelOptions {
  Pool = 'POOL',
  Direct = 'DIRECT',
}

export enum peakType {
  Peak = 'Peak',
  OffPeak = 'Off Peak',
  twentyFourSeven = '24/7',
  Custom = 'Custom',
}

export enum serviceHelperText {
  Peak = 'Mon – Fri (non holiday), 07:00 – 21:59',
  OffPeak = 'Mon – Fri  22:00 - 06:59, Sat, Sun, Holidays',
  twentyFourSeven = 'All day, every day',
  Custom = 'Custom',
}

export enum peakTypeRaw {
  Peak = 'PEAK',
  OffPeak = 'OFF_PEAK',
  twentyFourSeven = 'TWENTYFOUR_SEVEN',
  Custom = 'CUSTOM',
}

export enum flexType {
  BID = 'Bid',
  OFFER = 'Offer',
}

export enum flexTypeRaw {
  BID = 'BID',
  OFFER = 'OFFER',
}

export enum serviceType {
  MEC = 'MEC',
  MIC = 'MIC',
  SustainBilateral = 'Sustain - Bilateral',
  SustainPeakManagement = 'Sustain - Peak Management',
  SustainExportManagement = 'Sustain - Export Peak Management',
  DynamicPeakManagement = 'Dynamic - Constraint Management',
  SecurePeakManagement = 'Secure - Constraint Management',
}

export enum serviceTypeRaw {
  MEC = 'MEC',
  MIC = 'MIC',
  SustainBilateral = 'SUSTAIN_BILATERAL',
  SustainPeakManagement = 'SUSTAIN_PEAK_MANAGEMENT',
  SustainExportManagement = 'SUSTAIN_EXPORT_MANAGEMENT',
  DynamicPeakManagement = 'DYNAMIC_PEAK_MANAGEMENT',
  SecurePeakManagement = 'SECURE_PEAK_MANAGEMENT',
}

export enum enrollmentType {
  P2P = 'P2P',
  P2N = 'P2N',
  Both = 'Both',
  None = 'None',
}

export enum flexItemStatus {
  processing = 'processing',
  pending = 'pending',
  rejected = 'rejected',
  cancelled = 'cancelled',
  accepted = 'accepted',
  expired = 'expired',
}

export enum flexItemStatusRaw {
  processing = 'PROCESSING',
  pending = 'PENDING',
  rejected = 'REJECTED',
  cancelled = 'CANCELLED',
  accepted = 'ACCEPTED',
  expired = 'EXPIRED',
}

export enum generationMethod {
  manual = 'manual',
  systemGenerated = 'system generated',
  forecastBased = 'forecast based',
}

export enum generationMethodRaw {
  manual = 'MANUAL',
  systemGenerated = 'SYSTEM_GENERATED',
  forecastBased = 'FORECAST_BASED',
}
export interface DEROption {
  value: string;
  label: string;
}

export interface IndustryActor {
  label: string;
  value: string;
  ders: DEROption[];
}

export interface SelectDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  [key: string]: boolean;
}

export interface DateRange {
  from: DateTime | null;
  to: DateTime | null;
}

export type acceptReject = 'Accept' | 'Reject' | undefined;

export type primaryData = {
  id: string;
  name: string;
};

export type selectOption = {
  label: string;
  value: string;
};

export type feederOption = {
  label: string;
  value: string;
  primaries?: primaryData[];
};

export type serviceTypeOption = {
  label: string;
  value: serviceTypeRaw;
};

export type flexRequest = {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  peak: peakType;
  duration: string;
  flex: flexType;
  quantity: number;
  availability: number;
  utilization: number;
  service: serviceType;
  serviceEnrollment: string;
  requestorName: string;
  requestorImage: string;
  requestor?: Tenant;
  status: flexItemStatus;
  expiryDate: DateTime;
  participantId: string;
  feederId: string;
  primary: (primaryData & selectOption) | undefined;
  customPeakDays: SelectDays | null;
  serviceWindows: ServiceWindow[];
  hasActiveContract: boolean | undefined;
  lastUpdated: DateTime;
  generationMethod: generationMethod;
  hours?: number;
  openTime?: DateTime;
  derId?: string | null;
  derRDFID?: string;
  derType?: DERType;
  derName?: string;
};

export type ServiceWindow = {
  startTime: DateTime;
  endTime: DateTime;
};

export type ServiceWindowRaw = {
  start_time: string;
  end_time: string;
};

export type flexRequestRaw = {
  id: string;
  program_id: string;
  feeder_id: string;
  primary: (primaryData & selectOption) | undefined;
  requestor_der_rdf_id: string | null;
  target_def_rdf_id: string | null;
  flex_type: flexTypeRaw;
  participant_id: string;
  requestor_id: string;
  close_time: string;
  service_type: serviceTypeRaw;
  serviceEnrollment: string;
  peak_type: peakTypeRaw;
  service_windows: ServiceWindowRaw[];
  start_time: string;
  end_time: string;
  quantity: number;
  availability_price_ceiling: number;
  utilization_price_ceiling: number;
  status: flexItemStatusRaw;
  custom_peak_days: SelectDays | null;
  responses: flexResponseRaw[];
  contracts: flexContractRaw[];
  updated_at: string;
  generation_method: generationMethodRaw;
  hours?: number;
  open_time?: string;
  post_date?: string;
};

export type flexResponseRaw = {
  id: string;
  program_id: string;
  feeder_id: string;
  der_rdf_id: string;
  flex_type: flexTypeRaw;
  flex_request_id: string;
  quantity: number;
  availability_price: number;
  utilization_price: number;
  status: flexItemStatusRaw;
  responder_id: number;
};

export type flexResponse = {
  id: string;
  quantity: number;
  availability: number;
  utilization: number;
  acceptance: 'Full' | '-';
  derRDFID: string;
  derType: DERType;
  derName: string;
  responderName: string;
  responderImage: string;
  responder?: Tenant;
  status: flexItemStatus;
  serviceType: serviceType;
  request?: flexRequest;
};

export type flexContractRaw = {
  id: string;
  program_id: string;
  feeder_id: string;
  primary: (primaryData & selectOption) | undefined;
  flex_request_id: string;
  flex_response_id: string;
  status: flexItemStatusRaw;
  request: flexRequestRaw;
  response: flexResponseRaw;
  updates: any[];
  updated_at: string;
};

export type flexContract = {
  feederID: string;
  id: string;
  programID: string;
  primary: (primaryData & selectOption) | undefined;
  request: flexRequest;
  response: flexResponse;
  status: flexItemStatus;
  updates: any[];
  updatesCount: number;
  updatedAt: DateTime;
};

export type siaListItem = {
  max_forecast_time: string;
  zones: {
    id: string;
    iso_id: string;
    name: string;
  }[];
};
