import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import classes from 'classnames';
import InputMask from 'react-input-mask';
import { DateTime } from 'luxon';

import IconButton from 'components/IconButton';
import { KeyboardDateInputProps } from '../DatePicker.types';

import { defaultInputState } from '../DatePicker';
import '../DatePicker.scss';

const KeyboardDateInput = React.forwardRef(
  (
    {
      inputState,
      setInputState,
      value,
      onClick,
      onChange,
      onClear,
      error,
      placeholder,
      disabled,
      increments,
      showArrows,
      dateFormat,
      disableTyping,
      isClearable,
      helperText,
      id,
      label,
      styles,
      classNames,
      isOpen,
    }: KeyboardDateInputProps,
    ref: any
  ) => {
    const onInputChange = React.useCallback(
      (evt) => {
        const val = evt.target.value;

        if (disableTyping || !val) return;

        const isFullDateEntered = val.indexOf('-') === -1;
        if (isFullDateEntered) {
          onChange(DateTime.fromFormat(val, dateFormat));
        }
        setInputState(val);
      },
      [onChange, setInputState, dateFormat, disableTyping]
    );

    const changeDateHandler = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      direction: 'left' | 'right'
    ) => {
      e.stopPropagation();
      if (value) {
        onChange(
          value.plus({ days: increments * (direction === 'left' ? -1 : 1) })
        );
      }
    };

    const onClearHandler = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      onClear && onClear();
    };

    const showClear =
      inputState !== defaultInputState && !disabled && isClearable;

    return (
      <FormControl fullWidth className="date-picker-new">
        <InputMask
          mask="99/99/9999"
          maskChar="-"
          value={inputState}
          onChange={onInputChange}
        >
          {({ ...props }: any) => (
            <TextField
              {...props}
              id={id}
              ref={ref}
              label={label}
              helperText={helperText}
              autoComplete="false"
              placeholder={placeholder}
              className={classes(classNames, {
                'date-picker-new__input--disabled': disabled,
                'date-picker-new__input--error': error,
                'date-picker-new__input--focus': isOpen,
              })}
              classes={{ root: 'date-picker-new__input' }}
              style={styles}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <>
                    {showArrows && (
                      <div className="date-picker-new__actions">
                        {showClear && (
                          <IconButton
                            icon="XIcon"
                            variant="nestedIconButton"
                            onClick={onClearHandler}
                          />
                        )}
                        <IconButton
                          icon="ChevronLeftIcon"
                          variant="nestedIconButton"
                          onClick={(e) => changeDateHandler(e, 'left')}
                        />
                        <IconButton
                          icon="ChevronRightIcon"
                          variant="nestedIconButton"
                          onClick={(e) => changeDateHandler(e, 'right')}
                        />
                      </div>
                    )}
                    <IconButton
                      icon="CalendarIcon"
                      variant="nestedIconButton"
                      onClick={onClick}
                    />
                  </>
                ),
              }}
            />
          )}
        </InputMask>
      </FormControl>
    );
  }
);

export default KeyboardDateInput;
