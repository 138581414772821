import React, { useState, Fragment } from 'react';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import NumberInput from 'components/NumberInput';
import TextInput from 'components/TextInput';
import RadioButtonGroup from 'components/RadioButtonGroup';

import { useAssetEnrollmentInfo } from 'routes/Program/routes/Enrollment/api';

import './AssetConfig.scss';

type AssetLMPSettingsProps = {
  assetID?: string;
  assetType: string;
  programID?: string;
};

const AssetConfig = ({
  assetID: derRdfId,
  assetType,
  programID,
}: AssetLMPSettingsProps) => {
  const [edited, setEdited] = useState<any>({});

  const {
    enrollment: der_enrollment,
    enrollmentLoading: loading,
    refetch,
  } = useAssetEnrollmentInfo(programID, derRdfId);

  const { makeRequest: saveMarketInfoReq, loading: saving } = useRequest(
    `/api/dsp/program/${programID}/der/${derRdfId}`
  );

  const updateField = (key: string, value: any) => {
    setEdited({
      ...edited,
      [key]: value,
    });
  };

  const saveConfigSettings = async () =>
    saveMarketInfoReq({
      method: 'patch',
      body: edited,
      toast: {
        success: 'Successfully updated asset configuration.',
        error:
          'There was an unexpected error updating your asset configuration',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        setEdited({});
        refetch();
      },
    });

  const isBattery = assetType === 'BESS';
  const hasRampRate = isBattery || assetType === 'GenSet';
  const isPhotoVoltaic = assetType === 'PhotoVoltaic';

  return (
    <div className="asset-config-settings">
      <div className="asset-config-settings-header">
        <Button
          disabled={!Object.entries(edited).length || saving}
          onClick={saveConfigSettings}
        >
          Save
        </Button>
      </div>
      <div className="section">
        {loading && <LoadingSpinner />}
        {!loading && der_enrollment && (
          <Fragment>
            <div className="row">
              <Checkbox
                checked={
                  Object.prototype.hasOwnProperty.call(edited, 'is_aggregated')
                    ? edited.is_aggregated
                    : der_enrollment.is_aggregated
                }
                disabled={saving}
                label="Is Aggregated?"
                onChange={(e) => updateField('is_aggregated', e.target.checked)}
              />
            </div>

            <div className="row">
              <div className="row">
                <Checkbox
                  checked={
                    Object.prototype.hasOwnProperty.call(edited, 'activated')
                      ? edited.activated
                      : der_enrollment.activated
                  }
                  disabled={
                    saving ||
                    !Object.prototype.hasOwnProperty.call(
                      der_enrollment,
                      'activated'
                    )
                  }
                  label=" Activated"
                  onChange={(e) => updateField('activated', e.target.checked)}
                />
              </div>
              {isPhotoVoltaic && (
                <div className="row">
                  <Checkbox
                    checked={
                      Object.prototype.hasOwnProperty.call(
                        edited,
                        'automatic_pv_forecast_enabled'
                      )
                        ? edited.automatic_pv_forecast_enabled
                        : der_enrollment.automatic_pv_forecast_enabled
                    }
                    disabled={
                      saving ||
                      !Object.prototype.hasOwnProperty.call(
                        der_enrollment,
                        'automatic_pv_forecast_enabled'
                      )
                    }
                    onChange={(e) =>
                      updateField(
                        'automatic_pv_forecast_enabled',
                        e.target.checked
                      )
                    }
                    label="Generate PV Forecast"
                  />
                </div>
              )}
              <div className="row email-row">
                <span
                  className={
                    !Object.prototype.hasOwnProperty.call(
                      der_enrollment,
                      'email'
                    )
                      ? 'not-enrolled'
                      : ''
                  }
                >
                  DER should be mapped to customer to update DER settings
                </span>
                <Checkbox
                  checked={
                    Object.prototype.hasOwnProperty.call(edited, 'enabled')
                      ? edited.enabled
                      : der_enrollment.email?.enabled
                  }
                  disabled={
                    saving ||
                    !Object.prototype.hasOwnProperty.call(
                      der_enrollment,
                      'email'
                    )
                  }
                  onChange={(e) => updateField('enabled', e.target.checked)}
                  customClasses="email-checkbox"
                />
                <TextInput
                  id="email"
                  label="Email"
                  value={
                    Object.prototype.hasOwnProperty.call(edited, 'email')
                      ? edited.email
                      : der_enrollment.email?.email
                  }
                  disabled={
                    saving ||
                    !Object.prototype.hasOwnProperty.call(
                      der_enrollment,
                      'email'
                    )
                  }
                  onChange={(value) => {
                    if (value === undefined) {
                      updateField('email', null);
                    } else {
                      updateField('email', value);
                    }
                  }}
                />
              </div>
            </div>

            {(assetType === 'PhotoVoltaic' ||
              assetType === 'Hydro' ||
              assetType === 'Wind') && (
              <div className="row">
                <Checkbox
                  checked={
                    Object.prototype.hasOwnProperty.call(
                      edited,
                      'control_strategy'
                    )
                      ? edited.control_strategy === 'CURTAILABLE'
                      : der_enrollment.control_strategy === 'CURTAILABLE'
                  }
                  disabled={saving}
                  onChange={(e) =>
                    updateField(
                      'control_strategy',
                      e.target.checked ? 'CURTAILABLE' : 'SCHEDULED'
                    )
                  }
                  label="Is Curtailable?"
                />
              </div>
            )}
            {isBattery && (
              <div className="row">
                <RadioButtonGroup
                  id="operating_mode"
                  label="Operating Mode"
                  listType="column"
                  onChange={(value) => updateField('operating_mode', value)}
                  options={[
                    {
                      id: 'BID_OFFER',
                      label: 'Bid/Offer',
                      disabled:
                        saving ||
                        !Object.prototype.hasOwnProperty.call(
                          der_enrollment,
                          'operating_mode'
                        ),
                    },
                    {
                      id: 'UNCONSTRAINED',
                      label: 'Unconstrained',
                      disabled:
                        saving ||
                        !Object.prototype.hasOwnProperty.call(
                          der_enrollment,
                          'operating_mode'
                        ),
                    },
                  ]}
                  value={
                    Object.prototype.hasOwnProperty.call(
                      edited,
                      'operating_mode'
                    )
                      ? edited.operating_mode
                      : der_enrollment.operating_mode
                  }
                />
              </div>
            )}
            {hasRampRate && (
              <div className="row">
                <NumberInput
                  id="ramp_rate"
                  label="Ramp Rate"
                  unit="W/s"
                  onChange={(value) => {
                    if (value === undefined) {
                      updateField('ramp_rate', null);
                    } else {
                      updateField('ramp_rate', value);
                    }
                  }}
                  value={
                    Object.prototype.hasOwnProperty.call(edited, 'ramp_rate')
                      ? edited.ramp_rate
                      : der_enrollment.ramp_rate
                  }
                  disabled={saving}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AssetConfig;
