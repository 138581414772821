import React from 'react';
// eslint-disable-next-line custom-rules/deprecated-component
import OldCheckbox from 'components/OldCheckbox';

import './LeftRail.scss';

type Feeder = {
  id: string;
  name: string;
};

type Substation = {
  id: string;
  name: string;
  feeders: Feeder[];
};

type LeftRailProps = {
  loading: boolean;
  setSelectedContainer: (id: string, selected: boolean) => void;
  substations: Substation[];
  selected: string[]; // Selected IDs
  setShowAllIcons: (show: boolean) => void;
  showAllIcons: boolean;
};

const LeftRail = ({
  loading,
  setSelectedContainer,
  substations,
  selected,
  showAllIcons,
  setShowAllIcons,
}: LeftRailProps) => {
  return (
    <div className="left-rail">
      <div className="left-rail-header">
        <h3>Network</h3>
        <div className="der-select-container">
          <OldCheckbox
            checked={!showAllIcons}
            onClick={(e: any) => setShowAllIcons(!e.target.checked)}
          />
          <span className="label">Show Only DERs</span>
        </div>
      </div>
      {substations.map((substation) => {
        return (
          <div key={substation.id} className="substation">
            <div className="label-container">
              <OldCheckbox
                checked={selected.includes(substation.id)}
                disabled={loading}
                onClick={(e: any) =>
                  setSelectedContainer(substation.id, e.target.checked)
                }
              />
              <span className="label">{substation.name}</span>
            </div>
            {substation.feeders.map((feeder) => {
              return (
                <div className="feeder" key={feeder.id}>
                  <div className="label-container">
                    <OldCheckbox
                      checked={selected.includes(feeder.id)}
                      disabled={loading}
                      onClick={(e: any) =>
                        setSelectedContainer(feeder.id, e.target.checked)
                      }
                    />
                    <span className="label">{feeder.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default LeftRail;
