import React, { useState } from 'react';
import {
  apm,
  useRequestEffect,
  Request,
} from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';

import { useUserContext } from 'contexts/UserContext';

import { SelectOption } from 'types';
import { Tenant } from 'types/tenant';
import { User } from 'types/user';

import Button from 'components/Button';
import Select from 'components/OldSelect';

import { RenderUser } from './RenderUser';

import './ParticipantUsers.scss';

export type ParticipantUsersProps = {
  tenant: Tenant;
  setTenant: (updatedTenant: Tenant) => void;
  canEdit: boolean;
};

export const ParticipantUsers = ({
  tenant,
  setTenant,
  canEdit,
}: ParticipantUsersProps) => {
  const { addToast } = useToasts();
  const { userIsDso } = useUserContext();
  const isDso = userIsDso();

  const [newUserID, setNewUserID] = useState<SelectOption | null>(null);
  const [updatingUsers, setUpdatingUsers] = useState(false);
  const [unmappedUserOptions, setUnmappedUserOptions] = useState<
    Array<SelectOption>
  >([]);

  function displayUserName(name: string | undefined, email: string) {
    const displayName = name ? `${name} ` : '';
    return `${displayName}(${email})`;
  }

  const { loading: usersLoading } = useRequestEffect({
    url: '/api/dsp/users/tenant_unmapped',
    initialData: [],
    method: 'get',
    refetchOnChange: [],
    toast: {
      error: 'Failed to load DERs.',
      settings: {
        autoDismiss: true,
      },
    },
    onSuccess: (
      data: Array<{ name: string; id: string; email: string }> | undefined
    ) => {
      if (data) {
        setUnmappedUserOptions(
          data.map(({ name, id, email }) => ({
            label: displayUserName(name, email),
            value: id,
          }))
        );
      }
    },
  });

  const addUserToTenant = async () => {
    const request = new Request(`/api/dsp/user_tenants`);
    setUpdatingUsers(true);

    try {
      const { data: userTenant } = await request.post({
        tenant_id: tenant?.id,
        user_id: newUserID?.value,
      });

      setTenant({
        ...tenant,
        users: [...(tenant?.users || []), userTenant.user],
      });
      setUnmappedUserOptions(
        unmappedUserOptions.filter(
          (option: SelectOption) => option.value !== newUserID?.value
        )
      );
      addToast(`Added user ${userTenant.user?.name} to ${tenant?.name}`, {
        appearance: 'success',
      });
      setNewUserID(null);
    } catch (error: any) {
      apm.captureError(error);
      addToast(error, { appearance: 'error' });
    }
    setUpdatingUsers(false);
  };

  const removeUserFromTenant = async (userID: string) => {
    const request = new Request(
      `/api/dsp/user_tenants/${userID}/${tenant?.id}`
    );
    setUpdatingUsers(true);

    const userGetReq = new Request(`/api/dsp/users/${userID}`);
    try {
      await request.delete();
      let message = `Removed user from ${tenant?.name}`;
      setTenant({
        ...tenant,
        users: (tenant?.users || []).filter((u: User) => {
          if (u.id === userID) {
            message = `Removed user ${u?.name} from ${tenant?.name}`;
          }
          return u.id !== userID;
        }),
      });

      const { data: user } = await userGetReq.get();
      setUnmappedUserOptions([
        ...unmappedUserOptions,
        { label: displayUserName(user.name, user.email), value: user.id },
      ]);
      addToast(message, {
        appearance: 'success',
      });
    } catch (error: any) {
      apm.captureError(error);
      addToast(error, { appearance: 'error' });
    }

    setUpdatingUsers(false);
  };

  return (
    <div className="participant-users">
      <div className="participant-users__add">
        <div className="participant-users__select">
          <label className="participant-users__label" htmlFor="user-select">
            Add user
          </label>
          <Select
            placeholder="Select a user or search by name"
            isDisabled={!canEdit || usersLoading || updatingUsers || !isDso}
            isClearable
            isMulti={false}
            options={unmappedUserOptions}
            onChange={(option) => setNewUserID(option)}
            value={
              unmappedUserOptions.find(
                (opt: SelectOption) => opt.value === newUserID?.value
              ) || null
            }
          />
        </div>
        <Button
          variant="outlined"
          customClasses={{
            customButtonClass: 'participant-users__button',
          }}
          disabled={
            !canEdit || usersLoading || updatingUsers || newUserID === null
          }
          onClick={addUserToTenant}
        >
          Add user
        </Button>
      </div>
      <div className="participant-users__table-header">
        <div className="participant-users__table-header-cell" />
        <div className="participant-users__table-header-cell">Name</div>
        <div className="participant-users__table-header-cell">Email</div>
        <div className="participant-users__table-header-cell" />
        <div className="participant-users__table-header-cell" />
      </div>
      <div className="participant-users__table">
        {tenant?.users?.map((user: User, index: number) => (
          <RenderUser
            key={index}
            user={user}
            index={index + 1}
            numUsers={tenant?.users.length}
            removeUser={removeUserFromTenant}
          />
        ))}
      </div>
    </div>
  );
};
