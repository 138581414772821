import React from 'react';
import { useParams } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import {
  enrollmentType,
  flexRequest,
  flexRequestRaw,
} from 'types/contract-managment';
import { Program } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { Tenant } from 'types/tenant';

import { processRawFlexRequest } from 'routes/LongTermContracts/helpers/dataProcessers';

import LoadingSpinner from 'components/LoadingSpinner';
import RequestsTable from 'routes/RequestsResponses/RequestsTable';
import { columns } from 'routes/RequestsResponses/RequestsTable/RequestsTable';

import './Requests.scss';

interface requestParams {
  tenants: Tenant[] | undefined;
  program: Program | null;
  refetchTrigger: boolean;
}

/**
 * Handles fetching the data, logic special to this page
 * and enabling or disabling columns and features for the table.
 * @returns Long term contracts table
 */
const Requests = ({ tenants, program, refetchTrigger }: requestParams) => {
  const { programID } = useParams<{ programID: string }>();
  const { getUserEnrollmentType } = useUserContext();
  const userEnrollment = getUserEnrollmentType();

  const { data: flexRequests, loading: isLoadingData } = useRequestEffect<
    flexRequest[]
  >({
    url: `/api/dsp/program/${programID}/flex/requests`,
    initialData: [],
    method: 'get',
    refetchOnChange: [programID, tenants, refetchTrigger],
    blockRequest: () => !tenants,
    dataTransform: (data) => {
      return data
        .map((row: flexRequestRaw) => {
          return processRawFlexRequest(row, tenants, program);
        })
        .filter((row: flexRequestRaw) => {
          return userEnrollment === enrollmentType.Both
            ? row
            : row.serviceEnrollment === userEnrollment;
        })
        .sort((a: flexRequestRaw, b: flexRequestRaw) => {
          if (a.status > b.status) {
            return -1;
          }
          if (a.status < b.status) {
            return 1;
          }
          return 0;
        });
    },
  });

  const columns: columns[] = [
    'contractModel',
    'zone',
    'serviceDetails',
    'availableTime',
    'serviceType',
    'bidOrOffer',
    'quantity',
    'availability',
    'utilization',
    'requestor',
    'evaluation',
    'expiryDate',
  ];

  return (
    <div className="requests">
      {isLoadingData && <LoadingSpinner />}
      {!isLoadingData && !flexRequests && (
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          No Data Found
        </div>
      )}
      {!isLoadingData && flexRequests && (
        <RequestsTable
          data={flexRequests}
          showColumns={columns}
          programId={programID}
          isClickable
        />
      )}
    </div>
  );
};
export default Requests;
