import { DateTime } from 'luxon';
import { DERType } from 'types/der';
import { serviceTypeRaw, serviceType } from './contract-managment';

export type SettlementSummaryRaw = {
  participant: string;
  participant_id: string;
  events: number;
  measurement: SettlementTrafficLightRaw;
  requested: number;
  baseline: SettlementTrafficLightRaw;
  delivered: number;
  cost: number;
  penalty: number;
  final_settlement: number;
  published: boolean;
};

export type SettlementSummary = {
  tenantName: string;
  tenantID: string;
  tenantImage: string;
  events: number;
  measurement: SettlementTrafficLight;
  requested: number;
  baseline: SettlementTrafficLight;
  delivered: number;
  cost: number;
  penalty: number;
  finalSettlement: number;
  published: boolean;
  settlementStatus: SettlementStatus;
};

export enum SettlementStatus {
  ExportMeasurement = 'Export Measurement',
  ImportBaseline = 'Import Baseline',
  PublishSettlement = 'Publish Settlement',
  PendingMeasurementData = 'Pending Measurement Data',
  Published = 'Published',
}

export enum SettlementTrafficLight {
  'GREEN' = 'success',
  'ORANGE' = 'warning',
  'RED' = 'error',
  'CLEAR' = 'off',
}

export enum SettlementTrafficLightRaw {
  'GREEN' = 'GREEN',
  'ORANGE' = 'ORANGE',
  'RED' = 'RED',
  'CLEAR' = 'CLEAR',
}

export enum UploadedDataCoverage {
  Full = 'Full',
  Partial = 'Partial',
  None = 'None',
}

export type SettlementSingleResponseRawByContract = {
  der: {
    id: string;
    info: {
      name: string;
      der_type: DERType;
    };
  };
  service_windows: {
    start_time: string;
    end_time: string;
    has_measurement: boolean;
  }[];
  contract: serviceTypeRaw;
  power_requested?: number;
  power_delivered?: number;
  penalty?: number;
  cost?: number;
  final_settlement?: number;
};

export type SettlementSingleResponseRawDaily = {
  [contractID: string]: SettlementSingleResponseRawByContract;
};

export type SettlementSingleResponseRaw = {
  [dateStr: string]: SettlementSingleResponseRawDaily;
};

export type SettlementSingleResponse = {
  date: DateTime;
  measurementStatus: TrafficLightStatus;
  requested: number;
  delivered: number;
  cost: number;
  penalty?: number;
  finalSettlement: number;
  subRows: SettlementSingleResponseSubRow[];
};

export type SettlementSingleResponseSubRow = {
  time: {
    start: DateTime;
    end: DateTime;
  }[];
  der: DERInfo;
  contractType: serviceType;
  cost?: number;
  requested?: number;
  delivered?: number;
  penalty?: number;
  finalSettlement?: number;
};

export type DERInfo = {
  name: string;
  type: DERType;
  id: string;
};

export enum TrafficLightStatus {
  Green = 'GREEN',
  Orange = 'ORANGE',
  Blank = 'BLANK',
}
