import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import {
  useMeasurementsContext,
  TimeseriesTag,
} from 'contexts/MeasurementContext';

import check from 'check-types';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import HeaderLayout from 'components/HeaderLayout';
import NumberInput from 'components/NumberInput';
import Select from 'components/OldSelect';
import TextInput from 'components/TextInput';

import './SingleTag.scss';

type TagKey =
  | 'measurement_type'
  | 'name'
  | 'phases'
  | 'unit_multiplier'
  | 'unit_symbol';

const MEASUREMENT_TYPES = [
  { label: 'Apparent Power', value: 'APPARENT_POWER' },
  { label: 'Real Power', value: 'REAL_POWER' },
  { label: 'Reactive Power', value: 'REACTIVE_POWER' },
  { label: 'Current', value: 'CURRENT' },
  { label: 'Voltage', value: 'VOLTAGE' },
  { label: 'Energy', value: 'ENERGY' },
  { label: 'Status', value: 'STATUS' },
  { label: 'State of Charge', value: 'SOC_LEVEL' },
];
const PHASES = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'AB', value: 'AB' },
  { label: 'AC', value: 'AC' },
  { label: 'BC', value: 'BC' },
  { label: 'ABC', value: 'ABC' },
];

const SingleTimeseries = () => {
  const { timeseriesID, tagID } = useParams<{
    timeseriesID: string;
    tagID: string;
  }>();
  const { getTimeseriesById } = useMeasurementsContext();
  const timeseries = timeseriesID ? getTimeseriesById(timeseriesID) : undefined;

  const [edited, setEdited] = useState(false);
  const [saveMessages, setSaveMessages] = useState<{
    [key: string]: Array<string>;
  }>({});
  const [tag, setTag] = useState<TimeseriesTag | null>(null);

  useRequestEffect<TimeseriesTag>({
    url: `/api/dsp/measurements/timeseries/${timeseriesID}/tag/${tagID}`,
    method: 'get',
    onSuccess: (data) => {
      if (data) {
        setTag(data);
      }
    },
    toast: {
      error: 'Failed to load timeseries tag',
    },
  });

  const { loading: saving, makeRequest: runUpdate } = useRequest(
    `/api/dsp/measurements/timeseries/${timeseriesID}/tag/${tagID}`
  );

  const saveTag = async () => {
    if (timeseriesID && tagID) {
      await runUpdate({
        method: 'patch',
        body: {
          ...tag,
          timeseries_id: timeseriesID,
        },
        onError: (error) => {
          if (error.response && error.response.status === 400) {
            setSaveMessages(error.response.data.message);
          }
        },
        onSuccess: () => {
          setEdited(false);
          setSaveMessages({});
        },
        toast: {
          error: 'Failed to update tag.',
          success: 'Successfully updated tag.',
        },
      });
    }
  };

  const updateProp = (key: TagKey, value: any) => {
    setEdited(true);

    if (tag) {
      setTag({
        ...tag,
        [key]: value,
      });
    }
  };

  let valid = true;

  if (tag) {
    valid =
      !!tag.name &&
      !!tag.unit_symbol &&
      check.number(tag.unit_multiplier) &&
      check.greaterOrEqual(tag.unit_multiplier, 1);
  }

  return (
    <HeaderLayout
      className="single-tag"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
            {
              to: '/measurements/timeseries',
              label: <h2 className="title">Timeseries</h2>,
            },
            {
              to: `/measurements/timeseries/${timeseriesID}`,
              label: <h2 className="title">{timeseries?.name || ''}</h2>,
            },
          ]}
          separator="/"
          currentHeader={`${tag?.name || ''} Metadata`}
        />
      }
      titleRightContent={
        <Button
          disabled={!edited || saving || !valid}
          onClick={() => saveTag()}
        >
          Save
        </Button>
      }
    >
      {tag && (
        <Fragment>
          <TextInput
            disabled={saving}
            id="name"
            invalid={!!saveMessages.name}
            required
            label="Tag Name"
            onChange={(value) => updateProp('name', value)}
            placeholder="Name"
            validationMessage={
              saveMessages.name ? saveMessages.name[0] : undefined
            }
            value={tag.name}
          />
          <Select
            isClearable={false}
            isDisabled={saving}
            isMulti={false}
            isSearchable
            label="Measurement Type"
            onChange={(opt) => updateProp('measurement_type', opt.value)}
            options={MEASUREMENT_TYPES}
            value={MEASUREMENT_TYPES.find(
              (v) => v.value === tag.measurement_type
            )}
          />
          <Select
            isClearable
            isDisabled={saving}
            isMulti={false}
            isSearchable
            label="Phases"
            onChange={(opt) => {
              if (opt) {
                updateProp('phases', opt.value);
              } else {
                // Unselect case. Represented as a blank string in our data model
                updateProp('phases', '');
              }
            }}
            options={PHASES}
            value={
              tag.phase ? PHASES.find((v) => v.value === tag.phase) : undefined
            }
          />
          <TextInput
            disabled={saving}
            id="unit_symbol"
            invalid={!!saveMessages.unit_symbol}
            required
            label="Unit Symbol"
            onChange={(value) => updateProp('unit_symbol', value)}
            validationMessage={
              saveMessages.unit_symbol ? saveMessages.unit_symbol[0] : undefined
            }
            value={tag.unit_symbol || ''}
          />
          <NumberInput
            disabled={saving}
            id="multiplier"
            invalid={!!saveMessages.unit_multiplier}
            label="Unit Multiplier"
            onChange={(value) => updateProp('unit_multiplier', value)}
            min={1}
            required
            validationMessage={
              saveMessages.unit_multiplier
                ? saveMessages.unit_multiplier[0]
                : undefined
            }
            value={tag.unit_multiplier}
          />
        </Fragment>
      )}
    </HeaderLayout>
  );
};

export default SingleTimeseries;
