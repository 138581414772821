const copyToClipboard = (value: any) => {
  if (navigator.clipboard) {
    if (navigator.clipboard.writeText) {
      navigator.clipboard.writeText(value);
      return;
    }
    // Typescript can't handle this fallback so ignore for now
    //@ts-ignore
    else if (navigator.clipboard.write) {
      //@ts-ignore
      navigator.clipboard.write(value);
      return;
    }
  }
};

export default copyToClipboard;
