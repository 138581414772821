import React from 'react';
import classes from 'classnames';

import './Dialog.scss';

type DialogProps = {
  open: boolean;
  onClose?: () => void;
  type?: 'primary' | 'error';
  progress?: number;
  disableBackdropClick?: boolean;
  disableProgress?: boolean;
  children?: React.ReactNode;
  classNames?: {
    dialogClass?: string;
    overlayClass?: string;
    wrapperClass?: string;
    contentClass?: string;
    progressClass?: string;
  };
  styles?: {
    dialogStyles?: React.CSSProperties;
    overlayStyles?: React.CSSProperties;
    wrapperStyles?: React.CSSProperties;
    contentStyles?: React.CSSProperties;
    progressStyles?: React.CSSProperties;
  };
};

function Dialog({
  open,
  children,
  progress = 100,
  onClose = () => {},
  disableBackdropClick = false,
  disableProgress = false,
  type = 'primary',
  classNames = {},
  styles = {},
}: DialogProps) {
  const {
    dialogClass,
    overlayClass,
    wrapperClass,
    contentClass,
    progressClass,
  } = classNames;
  const {
    dialogStyles,
    overlayStyles,
    wrapperStyles,
    contentStyles,
    progressStyles,
  } = styles;

  let updatedProgress = 0;
  if (progress) {
    updatedProgress = progress;
    if (progress && progress > 100) {
      updatedProgress = 100;
    }
    if (progress && progress < 0) {
      progress = 0;
    }
  }

  return (
    <div className={classes('dialog', dialogClass)} style={dialogStyles}>
      <div
        className={classes(
          'dialog__overlay',
          {
            'dialog__overlay--active': open,
          },
          overlayClass
        )}
        style={overlayStyles}
        onClick={disableBackdropClick ? () => {} : onClose}
      />
      <div
        className={classes('dialog__wrapper', wrapperClass)}
        style={wrapperStyles}
      >
        <div
          className={classes(
            'dialog__content',
            {
              'dialog__content--active': open,
            },
            contentClass
          )}
          style={contentStyles}
        >
          {!disableProgress && (
            <div
              className={classes(
                `dialog__progress dialog__progress-${type}`,
                progressClass
              )}
              style={{ width: `${updatedProgress}%`, ...progressStyles }}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export default Dialog;
