import React, { useEffect, useRef, useState, useMemo, Fragment } from 'react';
import { AxiosError } from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import {
  apm,
  Request,
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { Timeseries, TimeseriesTag } from 'contexts/MeasurementContext';

import fileExportSave from 'helpers/downloadFile';

import Button from 'components/Button';
import FileForm from 'components/FileForm';
import IconButton from 'components/IconButton';
import Breadcrumbs from 'components/Breadcrumbs';
import HeaderLayout from 'components/HeaderLayout';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import Select from 'components/OldSelect';

import {
  ClassInstanceMap,
  MeasurementMapping,
  MappableObject,
  SelectOption,
  TimeseriesTagMap,
} from './MappingTypes';
import AddMappingModal from './AddMappingModal';
import MappingCard from './MappingCard';

import './Mapping.scss';
import 'components/Button/Button.scss';

const sorter = Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const Mapping = () => {
  const { programs } = useProgramsContext();
  const programOptions = useMemo(
    () =>
      programs.map(({ program_id, name }) => ({
        label: name,
        value: program_id,
      })),
    [programs]
  );

  // Load the initial program, selectedClass, and selectedInstanceID from query string
  const history = useHistory();
  const { pathname, search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamProgramID = params.get('program');

  const [deleting, setDeleting] = useState<boolean>(false);
  const [fileData, setFileData] = useState(new FormData());
  const [filename, setFilename] = useState('');
  const [hasData, setHasData] = useState(false);
  const [inUploadMode, setInUploadMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [program, setProgram] = useState<string | null>(
    queryParamProgramID || null
  );
  const [selectedInstanceID, setSelectedInstanceID] = useState<string | null>(
    params.get('instance')
  );
  const [selectedClass, setSelectedClass] = useState<string>(
    params.get('class') || 'Feeder'
  ); // Show feeders by default
  const [tags, setTags] = useState<TimeseriesTagMap>({});
  const [tagsLoading, setTagsLoading] = useState<boolean>(false);

  const uploadFormRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    const progID = params.get('program');
    const numericalProgramID = progID !== null ? parseInt(progID) : null;

    if (
      program !== numericalProgramID ||
      selectedClass !== params.get('class') ||
      selectedInstanceID !== params.get('instance')
    ) {
      // Update query string because our state chaned. Use `replace` to avoid giving the user an infinite history
      const programParam = program !== null ? `program=${program}` : '';
      const instanceParam =
        selectedInstanceID !== null ? `&instance=${selectedInstanceID}` : '';
      history.replace(
        `${pathname}?${programParam}&class=${selectedClass}${instanceParam}`
      );
    }
  }, [program, selectedClass, selectedInstanceID, history, params, pathname]);

  useEffect(() => {
    if (programOptions.length > 0 && program === null) {
      setProgram(programOptions[0].value);
    }
  }, [programOptions, program]);

  const { data: timeseriesOptions, loading: timeseriesLoading } =
    useRequestEffect<SelectOption[]>({
      url: '/api/dsp/measurements/timeseries',
      initialData: [],
      method: 'get',
      refetchOnChange: [],
      toast: {
        error: 'Failed to load data feeds.',
        settings: {
          autoDismiss: true,
        },
      },
      dataTransform: (data: Array<Timeseries>) => {
        return data.map((ts) => ({ label: ts.name, value: ts.id }));
      },
    });

  useEffect(() => {
    const didCancel = false;

    async function fetchTags() {
      if (!timeseriesOptions) {
        return;
      }

      const timeseriesIDs = timeseriesOptions.map((opt) => opt.value);
      const requests = timeseriesIDs.map(
        (timeseriesID) =>
          new Request(`/api/dsp/measurements/timeseries/${timeseriesID}/tags`)
      );

      try {
        setTagsLoading(true);
        const responses = await Promise.all(
          requests.map((r: Request) => r.get())
        );

        if (didCancel) {
          // Cancelled before the request finished so do nothing
          return;
        }

        const tagData: TimeseriesTagMap = {};

        responses.forEach(
          ({ data }: { data: Array<TimeseriesTag> }, index: number) => {
            const timeseriesID = timeseriesIDs[index];
            const dataMap: { [key: string]: TimeseriesTag } = {};
            data.forEach((tag) => {
              dataMap[tag.id] = tag;
            });
            tagData[timeseriesID] = dataMap;
          }
        );

        setTags(tagData);
      } catch (error: any) {
        apm.captureError(error);
      }

      setTagsLoading(false);
    }

    fetchTags();
  }, [timeseriesOptions]);

  const {
    data: mappableObjects,
    loading: mapObjectsLoading,
    refetch: refetchMappableObjects,
  } = useRequestEffect<ClassInstanceMap>({
    url: `/api/dsp/program/${program}/measurements/mappable-objects`,
    initialData: {},
    method: 'get',
    refetchOnChange: [program],
    blockRequest: () => program === null,
    /**
     * Transform response from
     * [
     *    { class , id, ... }
     * ]
     * to
     * {
     *    [class]: {
     *      [id]: {
     *        ...
     *      }
     *    }
     * }
     */
    dataTransform: (data: Array<MappableObject> | Record<string, unknown>) => {
      const dataMap: ClassInstanceMap = {};

      // initialData is sent when request is blocked
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          if (!dataMap[obj.class]) {
            dataMap[obj.class] = {};
          }

          dataMap[obj.class][obj.id] = obj;
        });
      }

      return dataMap;
    },
  });

  const {
    data: mappings,
    loading: mappingsLoading,
    refetch: refetchMappings,
  } = useRequestEffect<Array<MeasurementMapping>>({
    url: `/api/dsp/program/${program}/measurements/mappings/id/${selectedInstanceID}`,
    initialData: undefined,
    method: 'get',
    refetchOnChange: [program, selectedInstanceID],
    blockRequest: () => program === null || selectedInstanceID === null,
  });

  const { makeRequest: updateMapping } = useRequest(
    `/api/dsp/program/${program}/measurements/mappings/id/${selectedInstanceID}`
  );

  const submitMappingUpdate = async (body: any) => {
    await updateMapping({
      method: 'put',
      body,
      onSuccess: () => {
        refetchMappings();
      },
      toast: {
        success: 'Successfully saved measurement mapping',
        error: 'Failed to create measurement mapping.',
      },
    });
  };

  const submitMappingDelete = async (id: string) => {
    if (!program) {
      return;
    }

    const request = new Request(
      `/api/dsp/program/${program}/measurements/mappings/${id}`
    );

    try {
      setDeleting(true);
      await request.delete();
    } catch (error: any) {
      apm.captureError(error);
    }
    setDeleting(false);
    refetchMappableObjects();
    refetchMappings();
  };

  const loading =
    mapObjectsLoading ||
    timeseriesLoading ||
    tagsLoading ||
    mappingsLoading ||
    deleting;

  const classOptions = Object.keys(mappableObjects || {}).map((cls) => ({
    label: cls,
    value: cls,
  }));
  const valueOptions = Object.values(
    mappableObjects && mappableObjects[selectedClass]
      ? mappableObjects[selectedClass]
      : {}
  ).map((o: MappableObject) => ({ label: o.name, value: o.id }));

  classOptions.sort((a, b) => sorter.compare(a.label, b.label));
  valueOptions.sort((a, b) => sorter.compare(a.label, b.label));

  const cancelUpload = () => {
    setInUploadMode(false);
    setFileData(new FormData());
    setFilename('');
    setHasData(false);

    if (uploadFormRef.current) {
      uploadFormRef.current.reset();
    }
  };

  const { makeRequest: runUpload, loading: runningUpload } = useRequest(
    `/api/dsp/measurements/mappings/upload`
  );

  const uploadData = async () => {
    await runUpload({
      method: 'post',
      body: fileData,
      dataTransform: undefined,
      blockRequest: undefined,
      onSuccess: () => {
        cancelUpload(); // exits upload mode
      },
      onError: undefined,
      toast: {
        error: (error: AxiosError) => {
          if (error?.response?.data.message) {
            return `${error?.response?.data.message}`;
          }
          return 'Error uploading measurement mappings.';
        },
        success: 'Successfully uploaded measurement mappings.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { makeRequest: runExport, loading: downloadingMappings } =
    useRequest<Blob>(
      `/api/dsp/program/${program}/measurements/mappings/export`
    );

  const downloadMappings = async () => {
    await runExport({
      method: 'get',
      onSuccess: (data, headers) => {
        if (data) {
          fileExportSave(data, headers);
        }
      },
      toast: {
        error: 'Could not export program measurement mappings',
        settings: {
          autoDismiss: true,
        },
      },

      // Axios options
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  return (
    <HeaderLayout
      className="measurement-mapping"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
          ]}
          separator="/"
          currentHeader="Measurement Mapping"
        />
      }
      titleRightContent={
        <Fragment>
          <Button
            customClasses={{
              customButtonClass: 'header-button',
            }}
            disabled={modalOpen || !selectedInstanceID}
            onClick={() => setModalOpen(true)}
          >
            Add New Mapping
          </Button>
          <Button
            customClasses={{
              customButtonClass: 'header-button',
            }}
            disabled={program === null || downloadingMappings}
            onClick={() => downloadMappings()}
          >
            Export Mappings
          </Button>
          <IconButton
            icon={inUploadMode ? 'close' : 'create'}
            onClick={() => {
              setInUploadMode(!inUploadMode);
              if (inUploadMode) {
                // We are exiting upload mode
                cancelUpload();
              }
            }}
            tooltip={inUploadMode ? 'Close' : 'Bulk Upload'}
          />
        </Fragment>
      }
    >
      <div className="input-row">
        <div className="input-wrapper">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={loading}
            label="Program"
            onChange={(opt) => setProgram(opt.value)}
            options={programOptions}
            row
            value={programOptions.find(
              (opt: SelectOption) => opt.value === program
            )}
          />
        </div>
        <div className="input-wrapper">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={loading}
            label="Class"
            onChange={(opt) => {
              setSelectedClass(opt.value);
              setSelectedInstanceID(null);
            }}
            options={classOptions}
            row
            value={
              selectedClass === null
                ? null
                : classOptions.find(
                    (opt: SelectOption) => opt.value === selectedClass
                  )
            }
          />
        </div>
        <div className="input-wrapper">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={loading}
            label="Instance"
            onChange={(opt) => setSelectedInstanceID(opt.value)}
            options={valueOptions}
            row
            value={
              selectedInstanceID === null
                ? null
                : valueOptions.find(
                    (opt: SelectOption) => opt.value === selectedInstanceID
                  )
            }
          />
        </div>
      </div>
      {!loading &&
        selectedInstanceID &&
        mappings?.map((mapping) => (
          <MappingCard
            key={`${selectedInstanceID}-${mapping.id}`}
            onDelete={submitMappingDelete}
            mapping={mapping}
            tag={tags[mapping.timeseries_id][mapping.tag_id]}
            timeseriesOptions={timeseriesOptions || []}
          />
        ))}
      <AddMappingModal
        onCancel={() => setModalOpen(false)}
        onConfirm={async (mapping) => {
          await submitMappingUpdate([mapping]);
          setModalOpen(false);
        }}
        modalOpen={modalOpen}
        tagMap={tags}
        timeseriesOptions={timeseriesOptions || []}
      />
      <Modal
        active={inUploadMode}
        hideClose
        cancelProps={{ disabled: runningUpload }}
        confirmProps={{
          disabled: !hasData || runningUpload,
          onClick: uploadData,
          label: runningUpload ? (
            <i className="material-icons rotating-icon">refresh</i>
          ) : (
            'Save'
          ),
        }}
        height="250px"
        classes={{ contentClass: 'mapping__modal-content' }}
        onClose={cancelUpload}
        title="Upload Measurement Mappings"
      >
        <div className="upload-form">
          <FileForm
            accept="*.csv"
            createRef={(form) => (uploadFormRef.current = form)}
            id="upload"
            onChange={(e) => {
              //@ts-ignore
              const { files } = e.target;

              const file = files[0];
              const key = 'mappings';
              fileData.set(key, file);
              setFilename(file.name);
              setHasData(true);
            }}
          >
            <Button
              customClasses={{
                customButtonClass: 'button--non-interactive',
              }}
            >
              Select file
            </Button>
          </FileForm>
          <div className="filename">{filename}</div>
        </div>
      </Modal>
    </HeaderLayout>
  );
};
export default Mapping;
