import React from 'react';
import { DateTime } from 'luxon';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import DialogHeader from 'components/Dialog/DialogHeader';
import DialogFooter from 'components/Dialog/DialogFooter';
import DialogBody from 'components/Dialog/DialogBody';

import './CancelContract.scss';

type CancelContractProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  serviceStart: DateTime;
  serviceEnd: DateTime;
};

function CancelContract({
  open,
  onClose,
  onConfirm,
  serviceStart,
  serviceEnd,
}: CancelContractProps) {
  return (
    <Dialog
      open={open}
      type="error"
      progress={100}
      onClose={onClose}
      classNames={{ contentClass: 'cancel-contract' }}
    >
      <DialogHeader title="Cancel Contract?" onClose={onClose} />
      <DialogBody className="cancel-contract__body" padding="left-aligned">
        {`Are you sure you want to cancel the contract for the period of ${serviceStart.toLocaleString(
          {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          }
        )} to ${serviceEnd.toLocaleString({
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        })}? This action cannot be undone.`}
      </DialogBody>
      <DialogFooter>
        <Button
          variant="outlined"
          onClick={onClose}
          customClasses={{
            customButtonClass: 'cancel-contract__action',
          }}
        >
          Dismiss
        </Button>
        <Button
          onClick={onConfirm}
          customClasses={{
            customButtonClass: 'cancel-contract__action',
          }}
        >
          Yes, cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default CancelContract;
