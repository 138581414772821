import React, { Fragment } from 'react';

import './GridosVersion.scss';

type Props = {
  tag_ref: string;
  sha?: string;
};

const GridosVersion = (props: Props) => {
  function renderSha() {
    if (props.sha) {
      return <Fragment>{props.sha} /</Fragment>;
    }
  }

  return (
    <div className="gridos-version">
      <h3 className="navigation__section-title">Version</h3>
      <p className="gridos-version__item" title={props.tag_ref}>
        {renderSha()} {props.tag_ref}
      </p>
    </div>
  );
};

export default GridosVersion;
