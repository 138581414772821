import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import { DER } from 'types/der';

import './DERs.scss';

const DERs = () => {
  const { data: DERs, loading } = useRequestEffect<DER[]>({
    url: '/api/dsp/der',
    method: 'get',
    toast: {
      error: 'Failed to load list of DERs',
    },
  });

  return (
    <HeaderLayout className="ders" title="Market DERs">
      <Fragment>
        {loading && <LoadingSpinner />}
        {!loading && DERs && (
          <div className="list-container">
            <ul className="der-list">
              {DERs.map((der) => (
                <li className="der-item" key={der.id}>
                  <Link to={`/ders/${der.rdf_id}`}>{der.info.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Fragment>
    </HeaderLayout>
  );
};

export default DERs;
