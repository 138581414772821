import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';

import {
  useForm,
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form';
import { defaultMarketType } from '../NeedsAnalysisService.logic';
import { NeedsAnalysisType } from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';
import { AnyObject } from 'yup/lib/types';

export const daysOfTheWeekOptions = [
  { label: 'Every Monday', value: 'MONDAY' },
  { label: 'Every Tuesday', value: 'TUESDAY' },
  { label: 'Every Wednesday', value: 'WEDNESDAY' },
  { label: 'Every Thursday', value: 'THURSDAY' },
  { label: 'Every Friday', value: 'FRIDAY' },
  { label: 'Every Saturday', value: 'SATURDAY' },
  { label: 'Every Sunday', value: 'SUNDAY' },
];

const schema = () =>
  yup.object().shape({
    feeder_enrollments: yup
      .array()
      .test(
        'feeder-enrollments-test',
        'Select at least 1 zone',
        (arr) => !(arr ? arr.length < 1 : true)
      ),
    procurement_volume: yup
      .number()
      .required('Enter procurement volume. ')
      .typeError('Procurement volume must be a number. ')
      .positive('Procurement volume must be greater than 0. '),
    serviceDays: yup
      .object()
      .test('service-days-test', 'Select at least one service day. ', (arr) => {
        if (!arr) return false;
        const arrAsObject = arr as AnyObject;
        const daysToCheck = Object.keys(arrAsObject);
        return !daysToCheck.every((day) => !arrAsObject[day]);
      }),
    window_start_time: yup
      .string()
      .required('Service start and end times are required. ')
      .test(
        'window-start-time-test',
        'Start time should occurs at least one hour before the end time. ',
        (rawStartTime, ctx): boolean => {
          const rawEndTime = ctx.parent.window_end_time;
          if (!rawEndTime || !rawStartTime) {
            return false;
          }
          const endDate =
            rawEndTime instanceof DateTime
              ? rawEndTime
              : DateTime.fromFormat(rawEndTime, 'H:mm:ss');
          const startDate = DateTime.fromISO(rawStartTime)
            ? DateTime.fromISO(rawStartTime)
            : DateTime.fromFormat(rawStartTime, 'H:mm:ss');
          return endDate.hour - startDate.hour >= 0;
        }
      ),
    window_end_time: yup
      .string()
      .required('Service start and end times are required. ')
      .test(
        'window_end_time',
        'Start time should occurs at least one hour before the end time. ',
        (rawEndTime, ctx): boolean => {
          const rawStartTime = ctx.parent.window_start_time;
          if (!rawEndTime || !rawStartTime) {
            return false;
          }
          const startDate =
            rawStartTime instanceof DateTime
              ? rawStartTime
              : DateTime.fromFormat(rawStartTime, 'H:mm:ss');
          const endDate = DateTime.fromISO(rawEndTime)
            ? DateTime.fromISO(rawEndTime)
            : DateTime.fromFormat(rawEndTime, 'H:mm:ss');
          return endDate.hour - startDate.hour >= 0;
        }
      ),
    analysis_day: yup.string().required('Select request creation day. '),
    analysis_time: yup.string().required('Select request creation time. '),
    publish_request_day: yup.string().required('Select request publish day. '),
    publish_request_time: yup
      .string()
      .required('Select request publish time. '),
    publish_request_after_hours: yup
      .number()
      .moreThan(-1, 'Request should be created before being published. '),
    close_request_after_hours: yup
      .string()
      .test(
        'close_request_after_hours',
        'Request should be closed at least 1 hour after being published. ',
        (val) => {
          const closeAfterHours = Number(val);
          if (isNaN(closeAfterHours)) {
            return false;
          }
          return closeAfterHours > 0;
        }
      ),
  });

export interface useFormControlsReturnTypeWeekAhead {
  formStateWeekAhead: NeedsAnalysisType;
  setFormStateWeekAhead: React.Dispatch<
    React.SetStateAction<NeedsAnalysisType>
  >;
  controlWeekAhead: Control<FieldValues, any>;
  errorsWeekAhead: {
    [x: string]: any;
  };
  getValuesWeekAhead: UseFormGetValues<FieldValues>;
  setValueWeekAhead: UseFormSetValue<FieldValues>;
  handleSubmitWeekAhead: UseFormHandleSubmit<FieldValues>;
  registerWeekAhead: UseFormRegister<FieldValues>;
  triggerWeekAheadValidation: UseFormTrigger<FieldValues>;
}

export interface useFormControlsReturnTypeDayAhead {
  formStateDayAhead: NeedsAnalysisType;
  setFormStateDayAhead: React.Dispatch<React.SetStateAction<NeedsAnalysisType>>;
  controlDayAhead: Control<FieldValues, any>;
  errorsDayAhead: {
    [x: string]: any;
  };
  getValuesDayAhead: UseFormGetValues<FieldValues>;
  setValueDayAhead: UseFormSetValue<FieldValues>;
  handleSubmitDayAhead: UseFormHandleSubmit<FieldValues>;
  registerDayAhead: UseFormRegister<FieldValues>;
  triggerDayAheadValidation: UseFormTrigger<FieldValues>;
}

export const useWeekAheadFormControls = () => {
  const defaultFormData: NeedsAnalysisType = defaultMarketType;

  const [formStateWeekAhead, setFormStateWeekAhead] =
    useState<NeedsAnalysisType>(defaultFormData);

  const {
    control: controlWeekAhead,
    register: registerWeekAhead,
    getValues: getValuesWeekAhead,
    setValue: setValueWeekAhead,
    handleSubmit: handleSubmitWeekAhead,
    formState: { errors: errorsWeekAhead },
    trigger: triggerWeekAheadValidation,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema()),
  });
  return {
    formStateWeekAhead,
    setFormStateWeekAhead,
    controlWeekAhead,
    errorsWeekAhead,
    getValuesWeekAhead,
    setValueWeekAhead,
    handleSubmitWeekAhead,
    registerWeekAhead,
    triggerWeekAheadValidation,
  };
};

export const useDayAheadFormControls = () => {
  const defaultFormData: NeedsAnalysisType = defaultMarketType;

  const [formStateDayAhead, setFormStateDayAhead] =
    useState<NeedsAnalysisType>(defaultFormData);

  const {
    control: controlDayAhead,
    register: registerDayAhead,
    getValues: getValuesDayAhead,
    setValue: setValueDayAhead,
    handleSubmit: handleSubmitDayAhead,
    formState: { errors: errorsDayAhead },
    trigger: triggerDayAheadValidation,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema()),
  });
  return {
    formStateDayAhead,
    setFormStateDayAhead,
    controlDayAhead,
    errorsDayAhead,
    getValuesDayAhead,
    setValueDayAhead,
    handleSubmitDayAhead,
    registerDayAhead,
    triggerDayAheadValidation,
  };
};
