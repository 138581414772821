import { DateTime } from 'luxon';

export const createTimeIntervals = (
  increments: number,
  inputState: string,
  maxTime?: DateTime,
  minTime?: DateTime,
  data?: DateTime
) => {
  const dataValue = data || DateTime.local();

  const { rawHour, newHour, newMinutes, isFullTimeEntered } =
    getConvertedTime(inputState);

  const isFirstHourNumberExist = rawHour[0] !== '-';
  const isSecondHourNumberExist = rawHour[1] !== '-';

  const arrayLength = 1440 / increments;

  const res = Array.from({ length: arrayLength }, (_, i) => {
    const newDate = dataValue.startOf('day').plus({ minutes: increments * i });
    const isActive = dataValue.diff(newDate).milliseconds === 0;
    const isDisabled =
      (maxTime && newDate > maxTime) || (minTime && newDate < minTime);

    const { newHour: iHour, newMinutes: iMin } = getConvertedTime(
      newDate.toFormat('HH:mm')
    );

    const defaultHourCalculation = newHour === iHour;
    const zeroHourCalculation = iHour < 10;
    const normalHourCalculation =
      newHour * 10 <= iHour && iHour < newHour * 10 * 2;

    const isHours = () => {
      if (
        isSecondHourNumberExist ||
        (isFirstHourNumberExist && !isSecondHourNumberExist && newHour > 2)
      ) {
        return defaultHourCalculation;
      } else if (isFirstHourNumberExist && newHour === 0) {
        return zeroHourCalculation;
      } else if (isFirstHourNumberExist && newHour < 3) {
        return normalHourCalculation;
      }
      return true;
    };

    const isMinutes =
      newMinutes === 0 || iMin.toString().includes(newMinutes.toString());

    return {
      id: newDate.toISO(),
      value: newDate,
      isDisabled,
      isActive,
      order: isHours() && isMinutes ? 1 : 0,
    };
  });

  return isFullTimeEntered ? res : res.filter((item) => item.order !== 0);
};

const getClosestBetween = (
  target: number,
  firstVal: number,
  secondVal: number
) => {
  if (secondVal > 59) return firstVal;
  return Math.abs(target - firstVal) < Math.abs(target - secondVal)
    ? firstVal
    : secondVal;
};

export const getClosestInterval = (interval: number, val: number) => {
  let res = null;
  if (val !== 0 && val < interval) {
    res = getClosestBetween(val, 0, interval);
  }
  if (val !== interval && val > interval) {
    const valCount = Math.floor(val / interval);
    res = getClosestBetween(
      val,
      valCount * interval,
      valCount * interval + interval
    );
  }
  return res !== null ? res : val;
};

export const getConvertedTime = (value: string) => {
  const [hour, minutes] = value.split(':');
  const newHour = parseInt(hour) > 24 ? 24 : parseInt(hour);
  const newMinutes = parseInt(minutes) > 59 ? 59 : parseInt(minutes);
  const isFullTimeEntered = !hour?.includes('-') && !minutes?.includes('-');
  return {
    newHour: newHour || 0,
    newMinutes: newMinutes || 0,
    isFullTimeEntered,
    rawHour: hour,
    rawMinutes: minutes,
  };
};
