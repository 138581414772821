import React from 'react';

import quantityConverter from 'helpers/quantityConverter';

import SVG from 'components/SVG';
import Tag from 'components/Tag';
import { TagParams } from 'components/Tag/Tag';

import './QuantityTag.scss';

type Props = {
  quantity: number;
  convertFromWatts?: boolean;
  border?: boolean;
  tagProps?: { customClasses?: TagParams['customClasses']; round?: boolean };
};

/**
 * Tag for showing power quantity with bolt symbol
 */
const QuantityTag: React.FC<Props> = ({
  quantity,
  convertFromWatts = true,
  border = false,
  tagProps = {},
}) => {
  const positive = quantity >= 0;
  const isPos = positive ? 'pos' : 'neg';
  const sign = positive ? '+' : '';
  const value = convertFromWatts
    ? quantityConverter(quantity) || 0
    : quantity.toFixed(2);
  return (
    <Tag
      content={`${sign}${value} kWh`}
      style={{ border: border ? '1px solid #FC0000' : 0, fontSize: '12px' }}
      customClasses={{
        tagClass: 'quantity-tag',
      }}
      {...tagProps}
      leftElement={
        <SVG
          fontSize="14px"
          styles={{ height: '10px', width: '6px' }}
          icon="bolt"
          className={`quantity-tag--${isPos}`}
        />
      }
      round
    />
  );
};

export default QuantityTag;
