import React, {
  forwardRef,
  memo,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import Popper from '@mui/material/Popper';

import IconButton from 'components/IconButton';
import {
  CalendarProps,
  PickDateItemProps,
  PickDateProps,
  PickType,
} from '../Calendar.types';

import './PickDate.scss';

const monthList = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

const setNewDate = (date: DateTime, val: number, type: 'year' | 'month') =>
  date.startOf('day').plus({ [type]: val });

const getYearsList = (
  current: DateTime,
  yearsCount: number,
  minDate?: CalendarProps['minDate'],
  maxDate?: CalendarProps['maxDate']
) => {
  const yearList = Array(yearsCount).fill(0);
  const res = yearList.map((_, idx) => {
    const newDate = setNewDate(current, idx - 10, 'year');
    const isMin =
      (minDate &&
        minDate > newDate &&
        minDate.toFormat('yyyy') !== newDate.toFormat('yyyy')) ||
      false;

    const isMaxDateEqualToday =
      maxDate && maxDate.get('year') === newDate.get('year');
    const isMax =
      (!isMaxDateEqualToday && maxDate && maxDate < newDate) || false;

    return {
      value: newDate.get('year'),
      label: newDate.toFormat('yyyy'),
      disabled: isMax || isMin,
    };
  });
  return res;
};

const ContentItem = forwardRef(
  (
    { item, type, onSubmitHandler, selectableContent }: PickDateItemProps,
    ref
  ) => (
    <div
      ref={ref as any}
      key={item.label}
      onClick={() => onSubmitHandler(type, item.value)}
      className={classNames('pick-popover__item', {
        'pick-popover__item--active':
          selectableContent[type].numberToCompare === item.value,
        'pick-popover__item--disabled': item.disabled,
      })}
    >
      {item.label}
    </div>
  )
);

const PickDate = ({
  onSubmit,
  type,
  currentDate,
  minDate,
  maxDate,
  className,
}: PickDateProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [yearsCount, setYearsCount] = useState(50);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const yearsList = useMemo(
    () => getYearsList(currentDate, yearsCount, minDate, maxDate),
    [currentDate, minDate, maxDate, yearsCount]
  );

  const yearsListRefs = yearsList.reduce((acc, value) => {
    acc[value.label] = React.createRef<HTMLDivElement>();
    return acc;
  }, {} as any);

  useEffect(() => {
    setTimeout(() => {
      setYearsCount((prev) => prev + 10);
    }, 10000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (open && type === 'year') {
        const currentYear = parseInt(currentDate.toFormat('yyyy')) - 1;
        yearsListRefs[currentYear].current?.scrollIntoView({
          block: 'start',
        });
      }
    }, 0);
  }, [open, type, yearsListRefs, currentDate]);

  const selectableContent = {
    month: {
      numberToCompare: currentDate.get('month'),
      label: currentDate.toFormat('MMMM'),
      disabled: false,
    },
    year: {
      numberToCompare: currentDate.get('year'),
      label: currentDate.toFormat('yyyy'),
      disabled: yearsList.filter((i) => i.disabled).length === 7,
    },
  };

  const onOpenHandler = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onSubmitHandler = (type: PickType, value: number) => {
    onSubmit(type, value);
    setAnchorEl(null);
  };

  const contentList = {
    month: monthList.map((item) => (
      <ContentItem
        ref={null}
        key={item.label}
        item={item}
        onSubmitHandler={onSubmitHandler}
        selectableContent={selectableContent}
        type={type}
      />
    )),
    year: yearsList.map((item) => (
      <ContentItem
        key={item.label}
        item={item}
        ref={yearsListRefs[item.label]}
        onSubmitHandler={onSubmitHandler}
        selectableContent={selectableContent}
        type={type}
      />
    )),
  };

  return (
    <>
      <span
        aria-describedby={id}
        className={classNames('pick-date', className)}
        onClick={(e) => !selectableContent[type].disabled && onOpenHandler(e)}
        style={{ minWidth: type === 'month' ? 82 : 'auto' }}
      >
        {selectableContent[type].label}
        {!selectableContent[type].disabled && (
          <IconButton
            icon="ChevronDownIcon"
            variant="nestedIconButton"
            customClasses={{
              customButtonClass: classNames(
                'calendar-header__icon',
                'pick-date__action',
                {
                  'pick-date__action--open': open,
                }
              ),
            }}
          />
        )}
      </span>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className="pick-popover"
        style={{
          width: anchorEl?.offsetWidth || 'auto',
          maxHeight: type === 'year' ? '180px' : 'auto',
        }}
      >
        {contentList[type]}
      </Popper>
    </>
  );
};

export default memo(PickDate);
