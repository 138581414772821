import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { useUserContext } from 'contexts/UserContext';

import { env } from 'helpers/env';

import {
  NavMenuSectionData,
  NavMenuCategoriesData,
  NavMenuSubcategoriesData,
} from './Navigation.types';

import SVG from 'components/SVG';

import './Navigation.scss';
import 'styles/effects.scss';

export function renderSection(section: NavMenuSectionData) {
  const { title } = section;
  return (
    <div className="navigation__section">
      {title && <h3 className="navigation__section-title">{title}</h3>}
    </div>
  );
}

export function renderCategory(
  category: NavMenuCategoriesData,
  programMatch: any,
  feeder: any,
  isAuthEnabled: any,
  hasAllPermissions: any,
  isFlexAuction: any,
  history: any
) {
  const {
    icon,
    label,
    link,
    requiresProgram,
    requiresFeeder,
    permissions,
    subcategories,
  } = category;

  function renderCategoryItem() {
    if (link || link === '') {
      return RenderSubcategory(
        {
          icon,
          label,
          link,
          requiresProgram,
          requiresFeeder,
          permissions,
        },
        category,
        programMatch,
        feeder,
        isAuthEnabled,
        hasAllPermissions,
        isFlexAuction,
        history
      );
    } else {
      let disabled = false;
      if (requiresProgram) disabled = programMatch === null;
      if (requiresFeeder && !feeder) disabled = programMatch === null;
      return (
        <div className="navigation__category">
          <SVG
            icon={icon}
            className={classNames('navigation__icon', {
              'navigation__icon--disabled': disabled,
            })}
          />
          <p
            className={classNames('navigation__label', {
              'navigation__label--disabled': disabled,
            })}
          >
            {label}
          </p>
        </div>
      );
    }
  }

  return (
    <>
      {renderCategoryItem()}
      {subcategories &&
        subcategories?.map((subcategory) =>
          RenderSubcategory(
            subcategory,
            category,
            programMatch,
            feeder,
            isAuthEnabled,
            hasAllPermissions,
            isFlexAuction,
            history
          )
        )}
    </>
  );
}

function RenderSubcategory(
  subcategory: NavMenuSubcategoriesData,
  category: NavMenuCategoriesData,
  programMatch: any,
  feeder: any,
  isAuthEnabled: any,
  hasAllPermissions: any,
  isFlexAuction: any,
  history: any
) {
  const { userIsDso, userHasTenant } = useUserContext();
  const {
    icon,
    label,
    link,
    requiresProgram,
    requiresFeeder,
    permissions: subPermissions = [],
  } = subcategory;
  const {
    requiresProgram: catRequiresProgram,
    requiresFeeder: catRequiresFeeder,
    permissions: catPermissions = [],
  } = category;

  const needsProgramSelected = catRequiresProgram || requiresProgram;
  const needsFeederSelected = catRequiresFeeder || requiresFeeder;
  const permissions = subPermissions.concat(catPermissions);
  let disabled = false;
  let to = link;

  if (to.charAt(0) !== '/') {
    to = `/${to}`;
  }

  if (needsProgramSelected) {
    // If these links require the program, we need to substitute the programID
    disabled = programMatch === null;
    to =
      programMatch === null
        ? '/'
        : `/program/${programMatch.params.programID}${to}`;
  }

  if (needsFeederSelected && feeder) {
    to = `${to}/feeder/${feeder.id}`;
  }

  if (permissions && isAuthEnabled && !hasAllPermissions(permissions)) {
    // This link does not have permission to appear.
    return null;
  }

  if (permissions) {
    if (permissions.indexOf('view_flex_request') > -1 && !isFlexAuction) {
      // This will hide Long Term Contract button
      return null;
    }
  }

  if (link === '/isos') {
    if (env.isNMF) return null;
  }

  if (link === '/participants-and-ders/participants') {
    if (env.isWSC) return null;
  }

  if (link === 'settings/basic') {
    if (!userIsDso()) return null;
  }

  if (link === 'enrollment') {
    if (!userHasTenant()) return null;
  }

  const isActive =
    link === '/' || link === ''
      ? // The trailing slash is optional so need to match both
        history.location.pathname === to ||
        `${history.location.pathname}/` === to
      : history.location.pathname.startsWith(to);

  return (
    <NavLink
      key={link}
      to={to}
      isActive={(_, { pathname }) =>
        link === '/' || link === ''
          ? // The trailing slash is optional so need to match both
            pathname === to || `${pathname}/` === to
          : pathname.startsWith(to)
      }
      activeClassName="navigation__link--selected"
      className="navigation__link"
    >
      <div
        className={classNames('navigation__link-content', {
          'navigation__link-content--disabled': disabled,
        })}
      >
        {icon && (
          <SVG
            icon={icon}
            className={classNames('navigation__icon', {
              'navigation__icon--active': isActive,
              'navigation__icon--disabled': disabled,
            })}
          />
        )}
        {!icon && (
          <div
            className={classNames('navigation__dot', {
              'navigation__dot--active': isActive,
              'navigation__dot--disabled': disabled,
            })}
          />
        )}
        <p
          className={classNames('navigation__label', {
            'navigation__label--active': isActive,
            'navigation__label--disabled': disabled,
          })}
        >
          {label}
        </p>
      </div>
    </NavLink>
  );
}
