import React, { useEffect, useState } from 'react';
import { TableInstance } from 'react-table';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import fileExportSave from 'helpers/downloadFile';

import { SelectOption } from 'types';
// import SVG from 'components/SVG';
import Select from 'routes/LongTermContracts/components/Select';
// import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Menu from 'components/Menu';
import MenuItem from 'components/Menu/MenuItem';

import './ContractsTableFilters.scss';

type ContractsTableFiltersProps = {
  instance: TableInstance; // react table 7 instance details in react-table docs.
  programID: string;
  services: {
    label: string;
    value: string;
  }[];
  statusList: {
    label: string;
    value: string;
  }[];
  showPSATime?: boolean;
};

function ContractsTableFilters({
  instance,
  services,
  statusList,
  programID,
  showPSATime = false,
}: ContractsTableFiltersProps) {
  const [contractFilterState, setFilterState] = useState<SelectOption>(
    statusList[0]
  );
  const { state, setFilter } = instance;

  useEffect(() => {
    setFilterState(
      (prev) =>
        statusList.find(
          (i) => i.value === state.filters.find((j) => j.id === 'status')?.value
        ) || statusList[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters]);

  const [actionsAnchorEl, setActionsAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const { makeRequest: runExport } = useRequest(
    `/api/dsp/program/${programID}/flex/contracts/export`
  );

  const { makeRequest: runCSAExport } = useRequest(
    `/api/dsp/program/${programID}/recurring_analysis/reports`
  );

  const open = Boolean(actionsAnchorEl);

  const openActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const closeActionMenu = () => {
    setActionsAnchorEl(null);
  };

  const exportContracts = async () => {
    await runExport({
      method: 'get',
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(
          data,
          headers,
          `program-${programID}-flex-contracts.csv`
        );
      },
      onError: undefined,
      toast: {
        error: 'Could not export contracts.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const exportCSAData = async () => {
    await runCSAExport({
      method: 'get',
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(data, headers); // `program-${programID}-CSA-data.csv`
      },
      onError: undefined,
      toast: {
        error: 'Could not export CSA data.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const onFilterChange = (e: SelectOption) => {
    if (e && e.value === 'all') {
      setFilter('status', '');
    } else {
      setFilter('status', e ? e.value : '');
    }
    setFilterState(e);
  };

  return (
    <div className="contracts-table-filters">
      <div
        className="contracts-table-filters__filter new-look"
        style={{ marginLeft: '0' }}
      >
        <Select
          label={
            <span className="contracts-table-filters__label-text">
              Contract Status
            </span>
          }
          placeholder="All"
          defaultValue={contractFilterState}
          onChange={onFilterChange}
          isMulti={false}
          isClearable={false}
          options={statusList}
          value={contractFilterState}
        />
      </div>
      <div className="contracts-table-filters__filter new-look">
        <div
          className="contracts-table-filters__filter new-look"
          style={{ marginLeft: '0' }}
        >
          <Select
            label={
              <span className="contracts-table-filters__label-text">
                Service Type
              </span>
            }
            placeholder="All Service Types"
            defaultValue={services[0]}
            onChange={(e) => {
              if (e && e.value === 'all') {
                setFilter('response.serviceType', '');
              } else {
                setFilter('response.serviceType', e ? e.label : '');
              }
            }}
            isMulti={false}
            isClearable={false}
            options={services}
          />
        </div>
      </div>
      <div className="contracts-table-filters__actions">
        <IconButton
          icon="DownloadIcon"
          onClick={openActionMenu}
          customClasses={{
            customButtonClass: 'contracts-table-filters__actions-button',
          }}
        />
      </div>
      <Menu
        id="contracts-actions-menu"
        anchorEl={actionsAnchorEl}
        open={open}
        onClose={closeActionMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={exportContracts}>Download contracts</MenuItem>
        <MenuItem onClick={exportCSAData}>
          Download most recent CSA data
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ContractsTableFilters;
