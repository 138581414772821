import React, { useMemo } from 'react';
import { Cell, Row } from 'react-table';
import { DateTime } from 'luxon';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { useProgramsContext } from 'contexts/ProgramsContext';

import {
  SettlementSingleResponse,
  SettlementSingleResponseSubRow,
} from 'types/settlement';

import Table from 'components/Table';
import SVG from 'components/SVG';

import {
  renderAssetTypeCell,
  renderCurrencyCell,
  renderDeliveredCell,
  renderLightVariantCell,
  renderPowerQuantityCell,
} from 'routes/Settlement/components/TableColumns';

import './SettlementSingleTable.scss';

type TableRow = {
  row: Row<
    SettlementSingleResponse &
      SettlementSingleResponseSubRow & { canExpand: boolean }
  >;
};

type Props = {
  data: SettlementSingleResponse[];
};

function renderDateTimeCell({ row }: TableRow) {
  if (!row.values.date && row.original.time) {
    const times = [];
    for (const { start, end } of row.original.time) {
      const startString = start.toFormat('HH:mm');
      const endString =
        end.hour === 0 && end.day !== start.day
          ? '24:00'
          : end.toFormat('HH:mm');
      times.push(`${startString} - ${endString}`);
    }
    return (
      <span className="settlement-single-table__window-time">
        {times.join('&nbsp;')}
      </span>
    );
  }

  const date: DateTime = row.values.date;
  return date.toLocaleString({
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

function renderMeasurementCell({ row }: TableRow) {
  return row.values.measurementStatus ? (
    <span className="settlement-single-table__light-wrap">
      {renderLightVariantCell(row.values.measurementStatus)}
    </span>
  ) : (
    ''
  );
}

function renderDerCell({ row }: TableRow) {
  if (!row.values['der.name'] || !row.original.der) {
    return null;
  }
  return (
    <span className="settlement-single-table__subrow-item">
      {row.values['der.name']
        ? renderAssetTypeCell(row.original.der.type, row.original.der.name)
        : null}
    </span>
  );
}

function renderContractTypeCell({ row }: TableRow) {
  return (
    <span className="settlement-single-table__subrow-item">
      {row.values.contractType || null}
    </span>
  );
}

function renderExpanderCell({ row }: TableRow) {
  if (!row.canExpand) {
    return null;
  }
  return (
    <span
      className="settlement-single-table__expand"
      {...row.getToggleRowExpandedProps()}
    >
      {row.isExpanded ? (
        <SVG icon="MinusIcon" className="settlement-single-table__svg--minus" />
      ) : (
        <SVG icon="PlusIcon" className="settlement-single-table__svg--plus" />
      )}
    </span>
  );
}

export const SettlementSingleTable: React.FC<Props> = ({ data }) => {
  const { selectedProgram } = useProgramsContext();
  const { currencyFormatter } = useLocaleFormatter(
    selectedProgram?.currency,
    selectedProgram?.locale
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Date | Time',
        accessor: 'date',
        width: 150,
        Cell: renderDateTimeCell,
      },
      {
        Header: 'Measurement',
        accessor: 'measurementStatus',
        width: 150,
        Cell: renderMeasurementCell,
      },
      {
        Header: 'DER',
        accessor: 'der.name',
        width: 150,
        Cell: renderDerCell,
      },
      {
        Header: 'Contract',
        accessor: 'contractType',
        width: 170,
        Cell: renderContractTypeCell,
      },
      {
        Header: 'Requested',
        width: 200,
        accessor: (data: SettlementSingleResponse) =>
          renderPowerQuantityCell(data.requested, !!data.subRows),
      },
      {
        Header: 'Delivered',
        width: 300,
        accessor: (data: SettlementSingleResponse) =>
          renderDeliveredCell(data.delivered, data.requested, !!data.subRows),
      },
      {
        Header: 'Cost',
        width: 120,
        accessor: (data: SettlementSingleResponse) =>
          renderCurrencyCell(data.cost, currencyFormatter, !data.subRows),
      },
      {
        Header: 'Penalty',
        width: 120,
        accessor: (data: SettlementSingleResponse) =>
          renderCurrencyCell(data.penalty, currencyFormatter, !data.subRows),
      },
      {
        Header: 'Final Settlement',
        width: 200,
        accessor: (data: SettlementSingleResponse) =>
          renderCurrencyCell(
            data.finalSettlement,
            currencyFormatter,
            !data.subRows
          ),
      },
      {
        Header: () => null,
        id: 'expander',
        width: 40,
        Cell: renderExpanderCell,
      },
    ],
    [currencyFormatter]
  );

  const styles = {
    tableClass: 'settlement-single-table',
    tableHeaderCellClass: 'settlement-single-table__header-cell',
    tableBodyClass: 'settlement-single-table__body',
    tableBodyRowClass: 'settlement-single-table__row',
    tableBodyRowExpandedClass: 'settlement-single-table__row--expanded',
    tableBodyCellClass: 'settlement-single-table__cell',
  };

  const customRowRenderFunction = useMemo(
    () =>
      (
        row: Row,
        index: number,
        tableRowClass: string | undefined,
        tableCellClass: string | undefined
      ) =>
        (
          <React.Fragment key={`${index}--parent`}>
            <tr
              className={`settlement-single-table__${
                !row.canExpand ? 'top-border' : 'separator'
              }`}
            >
              {row.canExpand &&
                row.cells.map((_: Cell, index: number) => (
                  <td key={`${index}--child1`}></td>
                ))}
            </tr>
            <tr
              className={tableRowClass}
              {...row.getRowProps()}
              key={`${index}--child2`}
            >
              {row.cells.map((cell: Cell, index: number) => (
                <td
                  {...cell.getCellProps({ width: cell.column.width } as any)}
                  key={index}
                  className={tableCellClass}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          </React.Fragment>
        ),
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      tableType="HTML"
      disableSort={true}
      customClasses={styles}
      customRowRenderFunction={customRowRenderFunction}
    />
  );
};
