import React from 'react';
import { Route } from 'react-router-dom';

import RouterSwitch from 'components/RouterSwitch';
import Weather from './Weather';
import WeatherStation from './routes/WeatherStation';

const WeatherContainer = () => {
  return (
    <RouterSwitch>
      <Route exact path="/measurements/weather">
        <Weather />
      </Route>
      <Route exact path="/measurements/weather/station/:stationName">
        <WeatherStation />
      </Route>
    </RouterSwitch>
  );
};

export default WeatherContainer;
