/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint jsx-a11y/no-static-element-interactions: off */
import React, { ReactChild, ReactElement } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import IconButton from 'components/IconButton';

import './Modal.scss';

type customClasses = {
  modalClass?: undefined | string;
  overlayClass?: undefined | string;
  wrapperClass?: undefined | string;
  contentClass?: undefined | string;
  headerClass?: undefined | string;
  bodyClass?: undefined | string;
  footerClass?: undefined | string;
  closeClass?: undefined | string;
  titleClass?: undefined | string;
};

type customStyles = {
  modalStyles?: undefined | React.CSSProperties;
  overlayStyles?: undefined | React.CSSProperties;
  wrapperStyles?: undefined | React.CSSProperties;
  contentStyles?: undefined | React.CSSProperties;
  headerStyles?: undefined | React.CSSProperties;
  bodyStyles?: undefined | React.CSSProperties;
  footerStyles?: undefined | React.CSSProperties;
  titleStyles?: undefined | React.CSSProperties;
};

type actionButton = {
  hidden?: boolean;
  label?: string | ReactChild;
  disabled?: boolean;
  onClick?: undefined | (() => void);
  key?: string | undefined;
  customClass?: string | undefined;
  styles?: undefined | React.CSSProperties;
  type?: 'primary';
  variant?: 'contained' | 'text' | 'outlined';
  shape?: 'rounded' | 'circular';
};

interface ModalProps {
  title?: string | React.ReactNode;
  children?: ReactChild;
  onClose: () => void;
  cancelProps?: actionButton;
  confirmProps?: actionButton;
  active?: boolean;
  hideClose?: boolean;
  disableBackdropClick?: boolean;
  reverseFooterButtons?: boolean;
  alignFooterButtons?: 'start' | 'end' | 'center';
  hideHeader?: boolean;
  hideFooter?: boolean;
  customHeader?: ReactElement;
  customFooter?: ReactElement;
  height?: string;
  maxHeight?: string;
  width?: string;
  maxWidth?: string;
  classes?: customClasses;
  styles?: customStyles;
}

const defaultClasses = {
  modalClass: undefined,
  overlayClass: undefined,
  wrapperClass: undefined,
  contentClass: undefined,
  headerClass: undefined,
  bodyClass: undefined,
  footerClass: undefined,
  closeClass: undefined,
  titleClass: undefined,
};

const defaultStyles = {
  modalStyles: undefined,
  overlayStyles: undefined,
  wrapperStyles: undefined,
  contentStyles: undefined,
  headerStyles: undefined,
  bodyStyles: undefined,
  footerStyles: undefined,
  titleStyles: undefined,
};

const defaultBtnProps: actionButton = {
  hidden: false,
  disabled: false,
  onClick: undefined,
  customClass: undefined,
  styles: undefined,
  variant: 'outlined',
  type: 'primary',
};

const defaultConfirmProps = {
  label: 'Confirm',
  ...defaultBtnProps,
  variant: undefined,
};

const defaultCancelProps = {
  label: 'Cancel',
  ...defaultBtnProps,
};

/**
 * Stateless component that create a modal overlay and a modal body.
 * Status is controlled by container component.
 * Requires 2 callback function:
 *   - onConfirm - For clicks on the confirm button
 *   - onCancel - For clicks on the cancel button or overlay
 * Header and footer can be removed using the props showHeader and showFooter
 * Accepts any valid JSX as children in the modal body
 */
function Modal({
  title = '',
  children,
  onClose,
  cancelProps = defaultCancelProps,
  confirmProps = defaultConfirmProps,
  active = false,
  hideClose = false,
  disableBackdropClick = false,
  reverseFooterButtons = false,
  alignFooterButtons,
  hideHeader = false,
  hideFooter = false,
  customHeader,
  customFooter,
  height = 'auto',
  maxHeight,
  width = 'auto',
  maxWidth,
  classes = defaultClasses,
  styles = defaultStyles,
}: ModalProps) {
  const {
    modalClass,
    titleClass,
    overlayClass,
    bodyClass,
    footerClass,
    headerClass,
    wrapperClass,
    contentClass,
    closeClass,
  } = classes;
  const {
    modalStyles,
    titleStyles,
    overlayStyles,
    bodyStyles,
    footerStyles,
    headerStyles,
    wrapperStyles,
    contentStyles,
  } = styles;

  function renderActionButton(props: actionButton) {
    const {
      key,
      styles,
      customClass,
      hidden,
      label,
      onClick,
      disabled,
      variant,
    } = props;
    if (hidden) return;
    return (
      <Button
        customClasses={{
          customButtonClass: classNames('modal__footer-btn', customClass),
        }}
        onClick={onClick ? onClick : onClose}
        disabled={disabled}
        key={key}
        customStyles={{
          customButtonStyles: styles,
        }}
        variant={variant}
      >
        {label}
      </Button>
    );
  }

  function renderFooter() {
    let footerStyle = undefined;
    if (alignFooterButtons) {
      footerStyle = { justifyContent: alignFooterButtons };
    }
    if (customFooter) {
      return customFooter;
    }
    return (
      <div
        className={classNames('modal__footer', footerClass)}
        style={{ ...footerStyle, ...footerStyles }}
      >
        {!reverseFooterButtons
          ? renderActionButton({ ...defaultConfirmProps, ...confirmProps })
          : renderActionButton({ ...defaultCancelProps, ...cancelProps })}
        {!reverseFooterButtons
          ? renderActionButton({ ...defaultCancelProps, ...cancelProps })
          : renderActionButton({ ...defaultConfirmProps, ...confirmProps })}
      </div>
    );
  }

  function renderHeader() {
    if (customHeader) {
      return customHeader;
    }
    return (
      <div
        className={classNames('modal__header', headerClass)}
        style={headerStyles}
      >
        <p
          className={classNames('modal__title', titleClass)}
          style={titleStyles}
        >
          {title}
        </p>
        {!hideClose && (
          <IconButton
            icon="XIcon"
            onClick={onClose}
            customClasses={{
              customButtonClass: classNames(closeClass),
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classNames('modal', modalClass)} style={modalStyles}>
      <div
        className={classNames('modal__overlay', overlayClass, {
          'modal__overlay--active': active,
        })}
        style={overlayStyles}
        onClick={disableBackdropClick ? () => {} : onClose}
      />
      <div
        className={classNames('modal__wrapper', wrapperClass)}
        style={wrapperStyles}
      >
        <div
          className={classNames('modal__content', contentClass, {
            'modal__content--active': active,
          })}
          style={{
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            width: width,
            height: height,
            ...contentStyles,
          }}
        >
          {!hideHeader && renderHeader()}
          <div
            className={classNames('modal__body', bodyClass)}
            style={bodyStyles}
          >
            {children}
          </div>
          {!hideFooter && renderFooter()}
        </div>
      </div>
    </div>
  );
}

export default Modal;
