import React, { useState } from 'react';

import Popover from 'components/Popover';
import Button from 'components/Button';
import DeletingModal from '../../DeletingModal';

interface ActionCellProps {
  isDisabledPreview: boolean;
  isDisabledRemove: boolean;
  onPreview: () => void;
  onRemove: () => void;
}

const ActionCell = ({
  isDisabledRemove,
  isDisabledPreview,
  onPreview,
  onRemove,
}: ActionCellProps) => {
  const [cancelPopover, setCancelPopover] = useState(false);

  const cancelPopoverHandler = () => {
    setCancelPopover((prev) => !prev);
  };

  const onRemoveHandler = () => {
    onRemove();
    cancelPopoverHandler();
  };

  const removeBtn = (
    <Button variant="text" disabled={isDisabledRemove}>
      Remove
    </Button>
  );

  return (
    <div className="notification-table__action-cell">
      <Button variant="text" disabled={!isDisabledPreview} onClick={onPreview}>
        Preview
      </Button>
      {isDisabledRemove ? (
        removeBtn
      ) : (
        <Popover
          cancelTrigger={cancelPopover}
          title={removeBtn}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          content={
            <DeletingModal
              onClose={cancelPopoverHandler}
              onConfirm={onRemoveHandler}
            />
          }
        />
      )}
    </div>
  );
};

export default ActionCell;
