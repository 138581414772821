import React, { Component } from 'react';
import classnames from 'classnames';
import copyToClipboard from 'helpers/copyToClipboard';

import './TextInput.scss';

type TextInputProps = {
  className?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  htmlFor?: string;
  id: string;
  invalid?: boolean;
  label?: string;
  name?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  resetEditState?: boolean;
  setFocus?: boolean;
  validationMessage?: string;
  value: string | null;
};

type TextInputState = {
  edited: boolean;
};

// Creates a styled text input with a label.
class TextInput extends Component<TextInputProps, TextInputState> {
  static defaultProps = {
    htmlFor: null,
    placeholder: null,
    disabled: false,
    label: null,
    className: '',
    required: false,
    invalid: false,
    validationMessage: null,
    setFocus: false,
    hideLabel: false,
    onChange: () => null,
    onKeyPress: () => null,
    onBlur: undefined,
    onFocus: undefined,
    name: undefined,
    resetEditState: false,
  };

  state = {
    edited: false,
  };

  textInput: HTMLInputElement | null = null;

  componentDidMount() {
    // autofocus the input on mount
    this.focusTextInput();
  }

  componentDidUpdate(prevProps: TextInputProps) {
    if (this.props.resetEditState && !prevProps.resetEditState) {
      this.setState({ edited: false });
    }
  }

  copyValueToClipboard = () => {
    const { textInput } = this;

    if (textInput === null) {
      return;
    }

    copyToClipboard(textInput.value);

    if (document.queryCommandSupported('copy')) {
      textInput.disabled = false;
      textInput.focus();
      textInput.select();
      document.execCommand('copy');
      textInput.disabled = true;
    }
  };

  setTextInputRef = (element: HTMLInputElement | null) => {
    this.textInput = element;
  };

  focusTextInput = () => {
    // Focus the text input using the raw DOM API
    if (this.props.setFocus && this.textInput) this.textInput.focus();
  };

  onChange = (value: string) => {
    if (!this.state.edited) {
      this.setState({ edited: true });
    }
    this.props.onChange(value);
  };

  validate = () => {
    const { required, value, invalid } = this.props;

    if (invalid) {
      return false;
    }

    if (!this.state.edited) {
      return true;
    }

    let valid = true;
    if (required) {
      valid = !!value && value.length > 0;
    }

    return valid;
  };

  render() {
    const valid = this.validate();
    const {
      className,
      disabled,
      hideLabel,
      htmlFor,
      id,
      label,
      name,
      onBlur,
      onFocus,
      onKeyPress,
      placeholder,
      required,
      validationMessage,
      value,
    } = this.props;

    return (
      <div
        className={classnames({
          'text-input-group': true,
          ...(className ? { [className]: true } : {}),
        })}
      >
        <div className="text-input">
          {label && !hideLabel && (
            <label htmlFor={htmlFor || id} className="text-input__label">
              {label}
              {required ? ' *' : ''}
            </label>
          )}
          <div className="text-input-container">
            <input
              id={htmlFor || id}
              type="text"
              className={classnames({
                'text-input__input': true,
                'text-invalid': !this.validate(),
                'text-input__edited': this.state.edited && valid,
              })}
              onBlur={() => {
                if (this.validate() && onBlur) {
                  onBlur();
                }
              }}
              onFocus={onFocus}
              name={name}
              value={value || ''}
              title={value || ''}
              onChange={(e) => this.onChange(e.target.value)}
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              onKeyPress={onKeyPress}
              ref={this.setTextInputRef}
            />
          </div>
        </div>
        {!valid && (
          <div className="input-error">
            <p>
              {validationMessage
                ? validationMessage
                : `Please enter a valid ${label ? label : id}`}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default TextInput;
