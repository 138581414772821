import React from 'react';
import CheckboxMUI from '@mui/material/Checkbox';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import classNames from 'classnames';

import Tooltip from 'components/Tooltip';
import CheckIcon from 'SVG/Icons/CheckIcon';
import MinusIcon from 'SVG/Icons/MinusIcon';

import './Checkbox.scss';

interface CheckboxProps {
  checked: boolean;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  disabled?: boolean;
  label?: FormControlLabelProps['label'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  indeterminate?: boolean;
  color?: 'primary';
  size?: 'medium' | 'small';
  tooltip?: string;
  disableRipple?: boolean;
  customClasses?: string;
  customStyles?: React.CSSProperties;
}

const Checkbox = ({
  checked,
  onChange,
  disabled = false,
  label = '',
  labelPlacement = 'end',
  indeterminate = false,
  color = 'primary',
  size = 'medium',
  tooltip,
  disableRipple = false,
  customClasses,
  customStyles,
}: CheckboxProps) => {
  let propClasses = '';
  if (color) propClasses += `checkbox--${color} `;
  if (size) propClasses += `checkbox--${size} `;

  const checkboxItem = (
    <CheckboxMUI
      icon={<></>}
      checkedIcon={<CheckIcon className="checkbox__icon" />}
      indeterminateIcon={<MinusIcon className="checkbox__icon" />}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      indeterminate={indeterminate}
      disableRipple={disableRipple}
      classes={{
        root: `checkbox`,
        checked: `checkbox--${color}--checked`,
        disabled: `checkbox--${color}--disabled`,
      }}
      style={customStyles}
      className={classNames(propClasses, customClasses)}
    />
  );

  return (
    <Tooltip content={tooltip || ''} arrow={true} theme="light">
      {label ? (
        <FormControlLabel
          disabled={disabled}
          label={label}
          control={checkboxItem}
          labelPlacement={labelPlacement}
          classes={{
            root: 'checkbox__label-root',
            label: 'checkbox__label',
          }}
        />
      ) : (
        checkboxItem
      )}
    </Tooltip>
  );
};

export default Checkbox;
