import React, { useState } from 'react';
import {
  apm,
  useRequestEffect,
  Request,
} from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';

import { SelectOption } from 'types';
import { DER } from 'types/der';
import { Tenant } from 'types/tenant';

import { renderDERRow } from './ParticipantDers.logic';

import Button from 'components/Button';
import Select from 'components/OldSelect';

import './ParticipantDers.scss';

export type ParticipantDersProps = {
  tenant: Tenant | null;
  canAddDER: boolean;
  setTenant: (updatedTenant: Tenant) => void;
  refetchTenants: () => void;
};

export const ParticipantDers = ({
  tenant,
  canAddDER,
  setTenant,
  refetchTenants,
}: ParticipantDersProps) => {
  const { addToast } = useToasts();

  const [newDER, setNewDER] = useState<SelectOption | null>();
  const [updatingAssets, setUpdatingAssets] = useState(false);
  const [unmappedDEROptions, setUnmappedDEROptions] = useState<
    Array<SelectOption>
  >([]);
  const [expandedDerID, setExpandedDerId] = useState<string | undefined>();

  // Get all unassigned DERs
  const { loading: dersLoading } = useRequestEffect({
    url: '/api/dsp/der/tenant_unmapped',
    initialData: [],
    method: 'get',
    refetchOnChange: [],
    blockRequest: () => !canAddDER,
    toast: {
      error: 'Unable to load unlisted DER.',
      settings: {
        autoDismiss: true,
      },
    },
    onSuccess: (data: Array<{ name: string; rdf_id: string }> | undefined) => {
      if (data) {
        setUnmappedDEROptions(
          data.map(({ name, rdf_id }) => ({ label: name, value: rdf_id }))
        );
      }
    },
  });

  const addDERToTenant = async () => {
    const request = new Request(`/api/dsp/tenant_ders`);
    setUpdatingAssets(true);

    try {
      const { data: newTenantDER } = await request.post({
        tenant_id: tenant?.id,
        der_rdf_id: newDER?.value,
      });

      //@ts-ignore
      setTenant({
        ...tenant,
        ders: [...(tenant?.ders || []), newTenantDER.der],
      });
      const index = unmappedDEROptions.findIndex(
        (der: SelectOption) => der.value === newDER?.value
      );

      if (index !== -1) {
        const newDERMapOptions = [...unmappedDEROptions];
        newDERMapOptions.splice(index, 1);
        setUnmappedDEROptions(newDERMapOptions);
      }
      addToast(`Added DER ${newDER?.label} to ${tenant?.name}`, {
        appearance: 'success',
      });
      setNewDER(null);
    } catch (error: any) {
      apm.captureError(error);
      addToast(error, { appearance: 'error' });
    }

    setUpdatingAssets(false);
  };

  const removeDERFromTenant = async (derID: string) => {
    const request = new Request(`/api/dsp/tenant_ders/${tenant?.id}/${derID}`);

    try {
      await request.delete();

      const index = tenant?.ders.findIndex((der) => der.rdf_id === derID);

      if (typeof index === 'undefined') {
        return;
      }
      const newDERs = [...(tenant?.ders || [])];
      const removedDER = newDERs.splice(index, 1)[0];

      //@ts-ignore
      setTenant({
        ...tenant,
        ders: newDERs,
      });

      const newSelectOption = {
        isDisabled: false,
        value: removedDER.rdf_id,
        label: removedDER.info.name,
      };
      setUnmappedDEROptions([...unmappedDEROptions, newSelectOption]);
      setExpandedDerId(undefined);
      addToast(`Removed DER ${removedDER.info.name} from ${tenant?.name}`, {
        appearance: 'success',
      });
    } catch (error: any) {
      apm.captureError(error);
    }
  };

  return (
    <div className="participant-ders">
      {canAddDER && (
        <div className="participant-ders__add">
          <div className="participant-ders__select">
            <label className="participant-ders__label" htmlFor="der-select">
              Add DER
            </label>
            <Select
              placeholder="Select DER or search by name"
              isDisabled={!canAddDER || dersLoading || updatingAssets}
              isClearable
              isMulti={false}
              options={unmappedDEROptions}
              onChange={(option) => setNewDER(option)}
              value={
                unmappedDEROptions.find(
                  (opt: SelectOption) => opt.value === newDER?.value
                ) || null
              }
            />
          </div>
          <Button
            variant="outlined"
            customClasses={{
              customButtonClass: 'participant-ders__button',
            }}
            disabled={!canAddDER || dersLoading || updatingAssets || !newDER}
            onClick={() => addDERToTenant()}
          >
            Add DER
          </Button>
        </div>
      )}
      <div className="participant-ders__table-header">
        <div className="participant-ders__table-header-cell">
          Distributed energy resource
        </div>
        <div className="participant-ders__table-header-cell">ID</div>
        <div className="participant-ders__table-header-cell" />
      </div>
      <div className="participant-ders__table">
        {tenant?.ders?.map((der: DER) =>
          renderDERRow(
            der,
            tenant,
            removeDERFromTenant,
            expandedDerID,
            setExpandedDerId,
            refetchTenants
          )
        )}
      </div>
    </div>
  );
};
