import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import { isUndefined } from 'lodash';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { Program, useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { DERIcons } from 'types/der';

import { getCurrencySymbol } from 'helpers/locale';
import { isMidnight } from 'helpers/time';

import Button from 'components/Button';
import SVG from 'components/SVG';
import Tooltip from 'components/Tooltip';
import NumberInput from 'components/NumberInput';
import IconButton from 'components/IconButton';
import { DatePicker } from 'components/DatePicker';
import Dialog from 'components/Dialog';
import DialogHeader from 'components/Dialog/DialogHeader';
import DialogFooter from 'components/Dialog/DialogFooter';
import DialogBody from 'components/Dialog/DialogBody';
import DialogSection from 'components/Dialog/DialogSection';

import {
  getWeekDays,
  schema,
  UpdateContractProps,
} from './UpdateContract.logic';

import './UpdateContract.scss';

function UpdateContract({
  open,
  onClose,
  programID,
  refreshTrigger,
  contract,
  isDso,
}: UpdateContractProps) {
  const { user: currentUser } = useUserContext();
  const { selectedProgram, getFeeder } = useProgramsContext();
  const program = selectedProgram as Program;
  const { currencyFormatter } = useLocaleFormatter(
    program?.currency,
    program?.locale
  );
  const currencySymbol = getCurrencySymbol(currencyFormatter);

  const { feederID, id, request, response } = contract;
  const {
    derName,
    derType,
    responderName,
    quantity,
    availability,
    utilization,
  } = response;

  const { customPeakDays, service, peak, startTime, endTime, primary } =
    request;

  const feeder = getFeeder(feederID);

  const serviceStart = startTime.setZone(program.timezone);
  const serviceEnd = endTime.setZone(program.timezone).minus({
    days: isMidnight(endTime) ? 1 : 0,
  });

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema(startTime, endTime, quantity, isDso)),
  });

  useEffect(() => {
    setValue('startDate', startTime.startOf('day'));
    setValue(
      'endDate',
      endTime.startOf('day').minus({
        days: isMidnight(endTime) ? 1 : 0,
      })
    );
  }, [setValue, startTime, endTime]);

  const newStart = getValues('startDate');
  const newEnd = getValues('endDate');

  function onCloseClick() {
    reset();
    onClose();
  }

  const { makeRequest: submitUpdate } = useRequest(
    `/api/dsp/program/${programID}/feeder/${feederID}/create_contract_update`
  );

  function onSubmit(data: { [x: string]: any }) {
    const { startDate, endDate, quantity } = data;

    submitUpdate({
      method: 'post',
      body: {
        flex_contract_id: id,
        start_time: startDate.toISOString(),
        // add a day to properly capture the service windows within the end day.
        end_time: DateTime.fromJSDate(endDate)
          .plus({ days: 1 })
          .toJSDate()
          .toISOString(),
        reason: `Manually updated on ${DateTime.now().toFormat(
          'DD HH:mm'
        )} by ${currentUser?.name} (${currentUser?.email}).`,
        quantity: quantity * 1000,
      },
      toast: {
        success: 'Update for Flex Contract created successfully.',
        error:
          'Error creating update for Flex Contract. Please check that all fields are correct.',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        refreshTrigger();
        onCloseClick();
      },
      onError: (errorData) => {
        /* TODO: Add error messages received from API to input relevant elements */
      },
    });
  }

  const startDateWatcher = watch('startDate');
  const endDateWatcher = watch('endDate');
  const quantityWatcher = watch('quantity');

  const disableUpdate =
    isUndefined(startDateWatcher) ||
    isUndefined(endDateWatcher) ||
    isUndefined(quantityWatcher) ||
    errors?.startDate?.message ||
    errors?.endDate?.message ||
    errors?.quantity?.message;

  return (
    <Dialog
      open={open}
      progress={100}
      onClose={onCloseClick}
      classNames={{ contentClass: 'update-contract' }}
    >
      <DialogHeader title="Update contract" onClose={onCloseClick} />
      <DialogBody padding="left-aligned">
        <form id="update-contract-dialog" onSubmit={handleSubmit(onSubmit)}>
          <DialogSection
            classNames={{ headingClass: 'update-contract__section-title' }}
            title="Contract details"
            icon="ListIcon"
          >
            <div className="update-contract__section">
              <div className="update-contract__item">
                <div className="update-contract__label">Zone</div>
                <div className="update-contract__value">{feeder?.name}</div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">Primary</div>
                <div className="update-contract__value">{primary?.name}</div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">Industry Actor</div>
                <div className="update-contract__value">{responderName}</div>
              </div>
            </div>
            <div className="update-contract__section update-contract__section-border">
              <div className="update-contract__item">
                <div className="update-contract__label">DER</div>
                <div className="update-contract__value update-contract__value-der">
                  <SVG
                    className="update-contract__der"
                    icon={DERIcons[derType]}
                  />
                  <span className="update-contract__der-name">{derName}</span>
                </div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">Service type</div>
                <div className="update-contract__value">{service}</div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">Contract ID</div>
                <div className="update-contract__value">
                  <span className="update-contract__value-id">{id}</span>
                  <IconButton
                    icon="CopyIcon"
                    fontSize="12px"
                    customClasses={{
                      customButtonClass: 'update-contract__copy',
                    }}
                    onClick={() => navigator.clipboard.writeText(id)}
                  />
                </div>
              </div>
            </div>
            <div className="update-contract__section update-contract__section--last">
              <div className="update-contract__item">
                <div className="update-contract__label">Service period</div>
                <div className="update-contract__value">
                  {`${serviceStart?.toLocaleString({
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  })} - ${serviceEnd?.toLocaleString({
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  })}`}
                </div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">Service window</div>
                <div className="update-contract__value">{peak}</div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">Service days</div>
                <div className="update-contract__value">
                  {customPeakDays &&
                    getWeekDays(customPeakDays, serviceStart, serviceEnd)}
                </div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">Quantity</div>
                <div className="update-contract__value">{`${
                  quantity / 1000
                } kW`}</div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">
                  Availability price cap
                </div>
                <div className="update-contract__value">{`${currencySymbol}${
                  availability * 1000
                }/kW/h`}</div>
              </div>
              <div className="update-contract__item">
                <div className="update-contract__label">
                  Utilization price cap
                </div>
                <div className="update-contract__value">{`${currencySymbol}${
                  utilization * 1000
                }/kWh`}</div>
              </div>
            </div>
          </DialogSection>
          <DialogSection
            isLast
            classNames={{
              headingClass: 'update-contract__section-title',
            }}
            title="Contract updates"
            icon="BookOpenIcon"
          >
            <div className="update-contract__section update-contract__section--no-spacing">
              <div className="update-contract__item">
                <div
                  className="update-contract__label"
                  style={{ marginBottom: 0 }}
                >
                  <span className="update-contract__info">Start*</span>
                  <Tooltip
                    arrow
                    content="Updates to this contract starts on 00:00."
                    theme="light"
                  >
                    <SVG icon="InfoIcon" fontSize="12px" />
                  </Tooltip>
                </div>
                <div className="update-contract__value">
                  <Controller
                    name="startDate"
                    defaultValue={startTime.startOf('day')}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disabledMonthPicker
                        value={value}
                        onChange={(value) => onChange(value)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="update-contract__item">
                <div
                  className="update-contract__label"
                  style={{ marginBottom: 0 }}
                >
                  <span className="update-contract__info">End*</span>
                  <Tooltip
                    arrow
                    content="Update to this contract end on 23:59."
                    theme="light"
                  >
                    <SVG icon="InfoIcon" fontSize="12px" />
                  </Tooltip>
                </div>
                <div className="update-contract__value">
                  <Controller
                    name="endDate"
                    defaultValue={endTime.startOf('day')}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disabledMonthPicker
                        value={value}
                        onChange={(value) => onChange(value)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="update-contract__item update-contract__update">
                {newEnd &&
                  newStart &&
                  `Updating contract for ${
                    Math.floor(newEnd.diff(newStart, ['days']).days) + 1
                  } of ${
                    Math.floor(endTime.diff(startTime, ['days']).days) + 1
                  } days.`}
              </div>
            </div>
            <div
              className="update-contract__section"
              style={{ paddingBottom: 0, marginTop: '4px' }}
            >
              <div className="update-contract__error">
                {errors?.startDate?.message}
              </div>
              <div className="update-contract__error">
                {errors?.endDate?.message}
              </div>
            </div>
            <div className="update-contract__section update-contract__section--no-spacing">
              <div className="update-contract__item">
                <div
                  className="update-contract__label"
                  style={{ marginBottom: 0 }}
                >
                  <span className="update-contract__info">
                    Updated quantity*
                  </span>
                  <Tooltip
                    arrow
                    content={`Updated quantity must be between 0 kW and ${
                      quantity / 1000
                    } kW`}
                    theme="light"
                  >
                    <SVG icon="InfoIcon" fontSize="12px" />
                  </Tooltip>
                </div>
                <div className="update-contract__value">
                  {open && (
                    <Controller
                      name="quantity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NumberInput
                          id="quantity"
                          onChange={(value) => onChange(Number(value))}
                          unit="kW"
                          value={value}
                          placeholder="Quantity"
                          invalid={!!errors?.quantity?.message}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="update-contract__section update-contract__section--last"
              style={{ paddingBottom: 0, marginTop: '4px' }}
            >
              <div className="update-contract__error">
                {errors?.quantity?.message}
              </div>
            </div>
          </DialogSection>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="outlined"
          onClick={onCloseClick}
          customClasses={{
            customButtonClass: 'update-contract__action',
          }}
        >
          Cancel
        </Button>
        <Button
          customClasses={{
            customButtonClass: 'update-contract__action',
          }}
          form="update-contract-dialog"
          htmlButtonType="submit"
          disabled={disableUpdate}
        >
          Update
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default UpdateContract;
