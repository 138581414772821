import React from 'react';
import classes from 'classnames';

import Tooltip from 'components/Tooltip';

import './Switch.scss';

export type SwitchParams = {
  id: string;
  checked: boolean;
  onClick: () => void;
  label?: string;
  secondaryLabel?: string;
  labelPosition?: 'left' | 'right' | 'top' | 'bottom';
  containLabel?: boolean;
  helperText?: string;
  disabled?: boolean;
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
  size?: 'small' | 'normal' | 'large';
  toolTip?: string;
  disableAnimations?: boolean;
  name?: string;
  styles?: {
    switchStyles?: React.CSSProperties;
    switchContainerStyles?: React.CSSProperties;
    labelStyles?: React.CSSProperties;
    labelLTStyles?: React.CSSProperties;
    labelRBStyles?: React.CSSProperties;
    toggleStyles?: React.CSSProperties;
    knobStyles?: React.CSSProperties;
    internalLabelStyles?: React.CSSProperties;
    textStyles?: React.CSSProperties;
  };
  classNames?: {
    switchClass?: string;
    switchContainerClass?: string;
    labelClass?: string;
    labelLTClass?: string;
    labelRBClass?: string;
    toggleClass?: string;
    knobClass?: string;
    internalLabelClass?: string;
    textClass?: string;
    tooltipClass?: string;
  };
};

function Switch({
  id,
  checked,
  onClick,
  label,
  secondaryLabel,
  labelPosition = 'right',
  containLabel = false,
  helperText,
  disabled = false,
  type,
  size = 'normal',
  toolTip,
  disableAnimations = false,
  name,
  styles = {},
  classNames = {},
}: SwitchParams) {
  const {
    switchClass,
    switchContainerClass,
    labelClass,
    labelLTClass,
    labelRBClass,
    toggleClass,
    knobClass,
    internalLabelClass,
    textClass,
  } = classNames;
  const {
    switchStyles,
    switchContainerStyles,
    labelStyles,
    labelLTStyles,
    labelRBStyles,
    toggleStyles,
    knobStyles,
    internalLabelStyles,
    textStyles,
  } = styles;

  const isPrimaryLabelFirst =
    labelPosition === 'left' || labelPosition === 'top';
  const isVerticle = labelPosition === 'bottom' || labelPosition === 'top';

  let innerLabel = checked ? label : secondaryLabel;
  if (!isPrimaryLabelFirst) {
    innerLabel = checked ? secondaryLabel : label;
  }

  return (
    <div className={classes('switch', switchClass)} style={switchStyles}>
      <Tooltip content={toolTip || ''}>
        <div
          className={classes(
            'switch__container',
            {
              'switch__container--vertical': isVerticle,
            },
            switchContainerClass
          )}
          style={switchContainerStyles}
        >
          {((!containLabel && isPrimaryLabelFirst && label) ||
            secondaryLabel) && (
            <label
              className={classes(
                'switch__label',
                `switch__label--${size}`,
                {
                  'switch__label--left': !isVerticle,
                  'switch__label--top': isVerticle,
                  'switch__label--disabled': disabled,
                },
                labelClass,
                labelLTClass
              )}
              style={{ ...labelStyles, ...labelLTStyles }}
              htmlFor={id}
            >
              {isPrimaryLabelFirst ? label : secondaryLabel}
            </label>
          )}
          <label
            className={classes(
              'switch__toggle',
              `switch__toggle--${type}`,
              `switch__toggle--${size}`,
              {
                'switch__toggle--disabled': disabled,
              },
              toggleClass
            )}
            style={toggleStyles}
            htmlFor={id}
          >
            <input
              type="checkbox"
              className="switch__checkbox"
              name={name}
              id={id}
              checked={checked}
              onChange={onClick}
              disabled={disabled}
            />
            {containLabel && (
              <span
                className={classes(
                  'switch__inner-label',
                  `switch__inner-label--${size}`,
                  {
                    [`switch__inner-label--first`]: checked,
                    [`switch__text--${type}`]: checked,
                    [`switch__inner-label--second`]: !checked,
                  },
                  internalLabelClass
                )}
                style={internalLabelStyles}
              >
                {innerLabel}
              </span>
            )}
            <div
              className={classes(
                'switch__knob',
                {
                  [`switch__knob-${size}--checked`]: checked,
                  [`switch__knob--${type}`]: checked,
                  'switch__knob--disabled': disabled,
                },
                `switch__knob-${size}`,
                knobClass
              )}
              style={knobStyles}
            />
          </label>
          {((!containLabel && !isPrimaryLabelFirst && label) ||
            secondaryLabel) && (
            <label
              className={classes(
                'switch__label',
                `switch__label--${size}`,
                {
                  'switch__label--right': !isVerticle,
                  'switch__label--bottom': isVerticle,
                  'switch__label--disabled': disabled,
                },
                labelClass,
                labelRBClass
              )}
              style={{ ...labelStyles, ...labelRBStyles }}
              htmlFor={id}
            >
              {!isPrimaryLabelFirst ? label : secondaryLabel}
            </label>
          )}
        </div>
      </Tooltip>
      <span
        className={classes(
          'switch__text-helper',
          `switch__text--${size}`,
          `switch__text--${type}`,
          {
            'switch__text--default':
              !type || type === 'primary' || type === 'secondary',
            'switch__text--disabled': disabled,
          },
          textClass
        )}
        style={textStyles}
      >
        {helperText}
      </span>
    </div>
  );
}

export default Switch;
