import React from 'react';
import { useParams } from 'react-router-dom';

import NavButtons from 'components/NavButtons';

type SettlementNavProps = {
  sectionRowCounts: {
    allCount: number;
    exportCount: number;
    importCount: number;
    publishCount: number;
    pendingCount: number;
    publishedCount: number;
  };
};

function SettlementNav({ sectionRowCounts }: SettlementNavProps) {
  const {
    allCount,
    exportCount,
    importCount,
    publishCount,
    pendingCount,
    publishedCount,
  } = sectionRowCounts;

  const { programID } = useParams<{
    programID: string;
  }>();

  const settlementRoutes = [
    {
      path: '',
      label: `All Participants (${allCount})`,
      useIsExact: true,
    },
    {
      path: 'export',
      label: `Export Measurement (${exportCount})`,
      useIsExact: true,
    },
    {
      path: 'import',
      label: `Import Baseline (${importCount})`,
      useIsExact: true,
    },
    {
      path: 'publish',
      label: `Publish Settlement (${publishCount})`,
      useIsExact: true,
    },
    {
      path: 'pending',
      label: `Pending Measurement Data (${pendingCount})`,
      useIsExact: true,
    },
    {
      path: 'published',
      label: `Published (${publishedCount})`,
      useIsExact: true,
    },
  ];

  return (
    <NavButtons
      baseRoute={`/program/${programID}/settlement`}
      routes={settlementRoutes}
    />
  );
}

export default SettlementNav;
