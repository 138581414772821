import React from 'react';
import { DateTime } from 'luxon';
import MonthPicker from 'components/MonthPicker';

import './SettlementSummaryFilters.scss';

export type SettlementSummaryFiltersProps = {
  startDate: DateTime;
  setStartDate: (newStartDate: DateTime) => void;
};

const SettlementSummaryFilters = ({
  startDate,
  setStartDate,
}: SettlementSummaryFiltersProps) => {
  return (
    <div className="settlement-summary-filters">
      <div className="settlement-summary-filters__filter">
        <label className="settlement-summary-filters__label">
          Settlement Period
        </label>
        <MonthPicker
          date={startDate}
          // Settlement screen filter selects whole months making day irrelevant
          onClose={(date: DateTime) => {
            setStartDate(
              date.set({
                day: 1,
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default SettlementSummaryFilters;
