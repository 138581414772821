import {
  ContractModelOptions,
  flexRequest,
  flexRequestRaw,
} from 'types/contract-managment';
import { DER } from 'types/der';
import { Feeder } from 'types/feeder';
import { Tenant } from 'types/tenant';
import { CurrentUser } from 'types/user';

import { setIndustryActors } from 'routes/RequestsResponses/CreateRequest/CreateRequest.logic';
import { closeAfterOptions } from 'routes/RequestsResponses/CreateRequest/CreateRequestSetup/CreateRequestSetup.logic';

const getDerItem = (tenants: Tenant[], derId: string | null | undefined) => {
  if (!derId) return undefined;

  let derItem: DER | undefined;
  tenants.forEach(({ ders }) => {
    const item = ders.find((i) => i.rdf_id === derId);
    if (item) {
      return (derItem = item);
    }
  });

  return {
    value: derItem?.rdf_id || '',
    label: derItem?.info.name || '',
    info: derItem?.info,
  };
};

export const convertDefaultValues = (
  feeders: Feeder[],
  rawRequest: flexRequestRaw,
  tenants: Tenant[],
  currentUser: CurrentUser,
  request?: flexRequest
) => {
  if (!request) return undefined;

  const {
    id,
    startTime,
    endTime,
    participantId,
    feederId,
    primary,
    service,
    quantity,
    hours,
    availability,
    utilization,
    derId,
    openTime,
    expiryDate,
    customPeakDays,
  } = request;

  const { generation_method, service_type, flex_type, peak_type, post_date } =
    rawRequest;

  const contractModel = participantId
    ? ContractModelOptions.Direct
    : ContractModelOptions.Pool;
  const feeder = feeders.find((feeder) => feeder.id === feederId);
  const quantityGeneration = generation_method;

  const serviceType = {
    value: service_type,
    label: service,
  };
  const serviceDays = customPeakDays
    ? {
        sunday: customPeakDays.sunday,
        monday: customPeakDays.monday,
        tuesday: customPeakDays.tuesday,
        wednesday: customPeakDays.wednesday,
        thursday: customPeakDays.thursday,
        friday: customPeakDays.friday,
        saturday: customPeakDays.saturday,
      }
    : undefined;
  const bidOffer = flex_type;
  const serviceWindow = peak_type;
  const derItem = getDerItem(tenants, derId);
  const industryActor = setIndustryActors(tenants, currentUser.id).find(
    (i) => i.value === participantId
  );

  const closeAfter = closeAfterOptions.find(
    (i) =>
      parseInt(i.label) ===
      Math.abs(openTime?.diff(expiryDate, ['hours']).hours || 0)
  );

  return {
    id,
    openDate: openTime,
    closeDate: expiryDate,
    openTime: openTime,
    closeTime: expiryDate,
    startDate: startTime,
    startTime: startTime,
    endDate: endTime,
    endTime: endTime,
    closeAfter: closeAfter?.id,
    contractModel,
    feeder: {
      label: feeder?.name || '',
      value: feeder?.id || '',
    },
    primary,
    industryActor,
    serviceWindow,
    serviceDays,
    serviceType,
    bidOffer,
    distributedResource: derItem,
    availabilityPrice: availability * 1000,
    utilizationPrice: utilization * 1000,
    quantityGeneration,
    quantity: quantity / 1000,
    hours: hours || 0,
    systemCalculate: false,
    recurrence: undefined,
    includeActive: false,
    includePending: false,
    isCalculated: true,
    procurementVolume: 100,
    requestQuantity: 0,
    postDate: post_date,
  };
};
