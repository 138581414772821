import React from 'react';
import { Route } from 'react-router-dom';

import RouterSwitch from 'components/RouterSwitch';
import ISOList from './ISOList';
import AddISO from './routes/AddISO';
import ISO from './routes/ISO';

const DataSourcesContainer = () => {
  return (
    <RouterSwitch>
      <Route exact path="/isos">
        <ISOList />
      </Route>
      <Route exact path="/isos/add">
        <AddISO />
      </Route>
      <Route exact path="/isos/:isoID">
        <ISO />
      </Route>
    </RouterSwitch>
  );
};

export default DataSourcesContainer;
