import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Container } from 'hooks/useContainers';

import Dialog from 'components/Dialog';
import DialogHeader from 'components/Dialog/DialogHeader';
import DialogBody from 'components/Dialog/DialogBody/DialogBody';
import DialogFooter from 'components/Dialog/DialogFooter';
import FileForm from 'components/FileForm';
import Button from 'components/Button';
import Select from 'components/Select';

import {
  UploadType,
  UploadTypeEnum,
  useDataUpload,
} from 'routes/Program/routes/Enrollment/api';

type UploadTypes = {
  containers: Container[];
  showUploadScalarModal: boolean;
  setShowUploadScalarModal: Dispatch<SetStateAction<boolean>>;
  showUploadPrimariesModal: boolean;
  setShowUploadPrimariesModal: Dispatch<SetStateAction<boolean>>;
  uploadType: UploadType | undefined;
};

export const UploadModal = ({
  containers,
  showUploadScalarModal,
  setShowUploadScalarModal,
  showUploadPrimariesModal,
  setShowUploadPrimariesModal,
  uploadType,
}: UploadTypes) => {
  const uploadFormRef = useRef<HTMLFormElement | null>(null);
  const [hasData, setHasData] = useState<boolean>(false);
  const [canUpload, setCanUpload] = useState<boolean>(false);
  const [filename, setFilename] = useState('');

  const {
    handleUpload,
    uploading,
    uploadingPrimaries,
    setSelectedFeederOpts,
    selectedFeederOpts,
    fileData,
    cancelUpload,
  } = useDataUpload(
    uploadType,
    setShowUploadScalarModal,
    setShowUploadPrimariesModal,
    setFilename,
    setHasData,
    uploadFormRef
  );

  const feederOptions = containers.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  useEffect(() => {
    setCanUpload(
      hasData &&
        ((selectedFeederOpts && uploadType === UploadTypeEnum.ScalarData) ||
          uploadType === UploadTypeEnum.Primaries)
    );
  }, [hasData, selectedFeederOpts, setCanUpload, uploadType]);

  return (
    <Dialog
      open={showUploadScalarModal || showUploadPrimariesModal}
      onClose={cancelUpload}
    >
      <DialogHeader
        title={`Upload ${
          showUploadPrimariesModal ? 'primaries model' : 'scalar dataset'
        }`}
        onClose={cancelUpload}
      />
      <DialogBody>
        <div className="upload-form">
          {!showUploadPrimariesModal && (
            <Select
              isClearable={true}
              isMulti={false}
              label="Zone"
              onChange={(opt) => setSelectedFeederOpts(opt)}
              options={feederOptions || []}
              value={feederOptions.find(
                (f) => f.value === selectedFeederOpts?.value
              )}
            />
          )}
          <FileForm
            accept=".csv"
            createRef={(form) => (uploadFormRef.current = form)}
            id="upload"
            onChange={(e) => {
              //@ts-ignore
              const { files } = e.target;

              // If there not exactly one files attached, do nothing
              if (files.length !== 1) return;

              // Only 1 file should exist / only the first file matters
              const file = files[0];

              fileData.set('dataset', file);
              setFilename(file.name);
              setHasData(true);
            }}
          >
            <Button
              customClasses={{
                customButtonClass: 'button--non-interactive',
              }}
              customStyles={{ customButtonStyles: { marginTop: '8px' } }}
            >
              {!showUploadPrimariesModal
                ? 'Select Scalar Dataset CSV File'
                : 'Select Primaries Model CSV File'}
            </Button>
          </FileForm>
          <div className="filename">{filename}</div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={handleUpload}
          disabled={uploading || uploadingPrimaries || !canUpload}
          loading={uploading || uploadingPrimaries}
        >
          Upload
        </Button>
        <Button
          variant="outlined"
          onClick={cancelUpload}
          disabled={uploading || uploadingPrimaries}
        >
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
