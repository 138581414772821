import React from 'react';
import { default as MUIAccordion } from '@mui/material/Accordion';

import { AccordionListProps } from './interface';

const Accordion = ({
  id,
  expanded,
  elevation,
  children,
  disableGutters,
  sx,
  className,
  onChange,
}: AccordionListProps) => {
  const [open, setOpen] = React.useState<string | number | false>(
    expanded ? id : false
  );

  const handleChange =
    (panel: string | number) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setOpen(isExpanded ? panel : false);
    };

  if (!onChange) {
    return (
      <MUIAccordion
        expanded={open === id}
        onChange={handleChange(id)}
        elevation={elevation}
        disableGutters={disableGutters}
        sx={sx}
        className={className}
      >
        {children}
      </MUIAccordion>
    );
  } else {
    return (
      <MUIAccordion
        expanded={expanded}
        onChange={onChange}
        elevation={elevation}
        disableGutters={disableGutters}
        sx={sx}
        className={className}
      >
        {children}
      </MUIAccordion>
    );
  }
};

export default Accordion;
