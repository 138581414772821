import React, { useState } from 'react';

import { serviceType as serviceTypeEnum } from 'types/contract-managment';
import { useUserContext } from 'contexts/UserContext';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/Accordion';
import PageTitle from 'components/PageTitle';
import SVG from 'components/SVG';

import MECSelection from './MECSelection';

import './ContractSelectionAnalysis.scss';

export const ContractSelectionAnalysis = ({
  programId,
}: {
  programId: string;
}) => {
  const [{ message, configuring, updating }, setInfo] = useState<
    Record<string, string | boolean | undefined>
  >({
    message: '',
    configuring: false,
    updating: false,
  });
  const { userIsDso } = useUserContext();

  return (
    <div className="contract-selection">
      <PageTitle
        title="Contract selection analysis"
        classes={{ titleClass: 'contract-selection__title' }}
      >
        <div className="contract-selection__message">
          {(configuring || updating) && !message && (
            <>
              <span>Configuring settings...</span>
              <SVG
                icon="RefreshAltIcon"
                className="contract-selection__message-icon"
              />
            </>
          )}
          {!configuring && !updating && message && (
            <>
              <span>{message}</span>
              <SVG
                icon="CheckIcon"
                className="contract-selection__message-icon"
              />
            </>
          )}
        </div>
      </PageTitle>
      <Accordion
        id={serviceTypeEnum.MEC}
        elevation={0}
        disableGutters
        sx={{
          position: 'inherit',
        }}
      >
        <AccordionSummary
          expandIcon={
            <SVG
              round
              icon="ChevronDownIcon"
              fontSize={'1.1rem'}
              color={'#54595e'}
            />
          }
          sx={{
            borderBottom: '2px solid #f9fbfd',
          }}
        >
          <div className="contract-selection__service">Peer to peer</div>
        </AccordionSummary>
        <AccordionDetails>
          <MECSelection
            isDso={userIsDso()}
            programId={programId}
            serviceType={serviceTypeEnum.MEC}
            setInfo={setInfo}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
