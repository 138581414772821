import React, { useState } from 'react';
import { DateTime } from 'luxon';

import PageTitle from 'components/PageTitle';

import { CardProps } from 'routes/Program/routes/Reports/Reports';
import { PeriodsTable } from 'routes/Program/routes/Reports/components/TimePeriodsTable/TimePeriodsTable';
import TimePeriodsTable from 'routes/Program/routes/Reports/components/TimePeriodsTable';

import './AuditLogs.scss';

export const AuditLogs = ({ downloading, downloadReport }: CardProps) => {
  const [filteredResults, setFilteredResults] = useState<PeriodsTable[]>();

  const downloadAuditLogReport = async (
    analysisStartDate: DateTime,
    analysisEndDate: DateTime
  ) => {
    const url = `/api/dsp/audit/read/csv`;
    const params = {
      start: analysisStartDate.toJSDate().toISOString(),
      end: analysisEndDate.toJSDate().toISOString(),
    };
    downloadReport(url, params);
  };

  const downloadDaylyReport = async (date: DateTime) => {
    const url = `/api/dsp/audit/read/csv`;
    const params = {
      start: date.startOf('day').toJSDate().toISOString(),
      end: date.endOf('day').toJSDate().toISOString(),
    };
    downloadReport(url, params);
  };

  return (
    <div className="audit-logs">
      <PageTitle
        title="Audit log"
        classes={{ titleClass: 'audit-logs__title' }}
      />
      <TimePeriodsTable
        customColumnHeader="Audit log"
        data={filteredResults || []}
        setFilteredResults={setFilteredResults}
        downloadDaylyReport={downloadDaylyReport}
        downloadLogs={downloadAuditLogReport}
        downloading={downloading}
      />
    </div>
  );
};
