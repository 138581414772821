import React from 'react';

// eslint-disable-next-line custom-rules/deprecated-component
import OldCheckbox from 'components/OldCheckbox';
import RadioButtonGroup from 'components/RadioButtonGroup';

import { SettingsCommonProps } from 'routes/Program/routes/Settings/Settings';

const forecastTypeOpts = [
  { label: 'System', id: 'SYSTEM_GENERATED_FORECAST' },
  { label: 'User', id: 'USER_UPLOADED_FORECAST' },
  { label: 'Nodal', id: 'NODAL_FORECAST' },
  { label: 'Scalar allocation', id: 'SCALAR_ALLOCATION' },
];

export const Advanced = ({
  program,
  saving,
  updateProp,
}: SettingsCommonProps) => {
  const { REACT_APP_IS_WSC } = process.env;

  return (
    <div className="program-settings_column">
      <div className="program-settings_column-row">
        <RadioButtonGroup
          id="event-confirmation"
          label="Event Confirmation"
          listType="column"
          onChange={(value) =>
            updateProp('auto_confirm_enabled', value === 'enabled')
          }
          options={[
            {
              id: 'disabled',
              label: 'I will confirm events myself',
            },
            { id: 'enabled', label: 'Auto-confirm all events' },
          ]}
          value={program.auto_confirm_enabled ? 'enabled' : 'disabled'}
        />
      </div>
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving}
          checked={program.allow_reverse_active_powerflow}
          onClick={() =>
            updateProp(
              'allow_reverse_active_powerflow',
              !program.allow_reverse_active_powerflow
            )
          }
        />
        Allow Reverse Active Powerflow
      </div>
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving}
          checked={program.allow_reverse_reactive_powerflow}
          onClick={() =>
            updateProp(
              'allow_reverse_reactive_powerflow',
              !program.allow_reverse_reactive_powerflow
            )
          }
        />
        Allow Reverse Reactive Powerflow
      </div>
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving}
          checked={program.generate_nodal_load_forecasts}
          onClick={() =>
            updateProp(
              'generate_nodal_load_forecasts',
              !program.generate_nodal_load_forecasts
            )
          }
        />
        Generate Nodal Load Forecasts
      </div>
      <div className="program-settings_column-row">
        <RadioButtonGroup
          id="forecast-source"
          label="Forecast Source"
          listType="column"
          onChange={(value) => updateProp('forecast_type', value)}
          options={forecastTypeOpts}
          value={program.forecast_type}
        />
      </div>
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving || !(program.financial_model === 'LMPD')}
          checked={program.skip_nodal_losses_calculation}
          onClick={() =>
            updateProp(
              'skip_nodal_losses_calculation',
              !program.skip_nodal_losses_calculation
            )
          }
        />
        Skip Nodal Losses Calculation
      </div>
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving}
          checked={program.constraint_energy_management}
          onClick={() =>
            updateProp(
              'constraint_energy_management',
              !program.constraint_energy_management
            )
          }
        />
        Constraint Energy Management
      </div>
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving}
          checked={program.is_simulation}
          onClick={() => updateProp('is_simulation', !program.is_simulation)}
        />
        Simulation
      </div>
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving}
          checked={program.flex_auction_enabled}
          onClick={() =>
            updateProp('flex_auction_enabled', !program.flex_auction_enabled)
          }
        />
        Flex auction enabled
      </div>
      {REACT_APP_IS_WSC && (
        <div className="program-settings_column-row">
          <RadioButtonGroup
            id="same_day_market_enabled"
            label="Market and Settlement selection"
            listType="column"
            onChange={(value) =>
              updateProp('same_day_market_enabled', value === 'enabled')
            }
            options={[
              {
                id: 'enabled',
                label: 'Day-ahead and Same-day',
              },
              { id: 'disabled', label: 'Day-ahead only' },
            ]}
            value={program.same_day_market_enabled ? 'enabled' : 'disabled'}
          />
        </div>
      )}
    </div>
  );
};
