import React from 'react';
import SVG from 'components/SVG';
import './ErrorMessage.scss';

interface Props {
  showIcon?: boolean;
}

const ErrorMessage: React.FC<Props> = ({ showIcon = false, children }) => (
  <div className="error-message">
    {showIcon && (
      <SVG
        icon="warning_outline"
        fontSize="14px"
        className="error-message__icon"
      />
    )}
    <span>{children}</span>
  </div>
);

export default ErrorMessage;
