import React, { FunctionComponent, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import { Program, useProgramsContext } from 'contexts/ProgramsContext';

import { MarketType } from 'types';

import Button from 'components/Button';
import Card from 'components/Card';
// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'components/OldDatePicker';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import Select from 'components/OldSelect';
import Tooltip from 'components/Tooltip';

const MARKET_TYPES = [
  { label: 'Same Day', value: MarketType.SAMEDAY },
  { label: 'Day Ahead', value: MarketType.DAYAHEAD },
];

const ClearMarketDataCard: FunctionComponent = () => {
  const { programs, getProgram } = useProgramsContext();

  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState(DateTime.local().startOf('hour'));
  const [endTime, setEndTime] = useState(
    DateTime.local().startOf('hour').plus({ hours: 1 })
  );
  const [marketType, setMarketType] = useState(MarketType.SAMEDAY);
  const [program, setProgram] = useState<Program | null>();

  const programOptions = useMemo(() => {
    return programs.map(({ program_id, name }) => ({
      label: name,
      value: program_id,
    }));
  }, [programs]);

  const handleClearDataMarketChange = (market: MarketType) => {
    if (program) {
      setMarketType(market);
      if (market === MarketType.SAMEDAY) {
        setStartTime(
          DateTime.local()
            .setZone(program.timezone)
            .plus({ hours: 1 })
            .startOf('hour')
        );
        setEndTime(
          DateTime.local()
            .setZone(program.timezone)
            .plus({ hours: 2 })
            .startOf('hour')
        );
      } else {
        setStartTime(
          startTime.setZone(program.timezone).plus({ days: 1 }).startOf('day')
        );
        setEndTime(
          startTime.setZone(program.timezone).plus({ days: 2 }).startOf('day')
        );
      }
    }
  };

  const { loading: clearingMarketData, makeRequest: runClearMarketData } =
    useRequest(
      `/api/dsp/admin/program/${program?.program_id}/clear_market_data`
    );

  const clearMarketData = async () => {
    await runClearMarketData({
      params: {
        start: startTime,
        end: endTime,
        market_type: marketType,
      },
      method: 'post',
      onSuccess: () => {
        setShowModal(false);
      },
      toast: {
        success: `Successfully cleared market data for ${marketType} market of program '${
          program?.name
        }' from ${startTime.toFormat('ff')} to ${endTime.toFormat('ff')}.`,
        error: 'Could not clear market data.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const invalidClearDataOptions = endTime <= startTime;

  return (
    <>
      <Card className="event-card" title="Clear Market Data">
        <Select
          label="Program"
          onChange={(opt) => {
            setProgram(getProgram(opt.value));
          }}
          isMulti={false}
          isClearable={false}
          options={programOptions}
          row
          value={programOptions.find((m) => m.value === program?.program_id)}
        />
        <Select
          label="Market"
          onChange={(opt) => handleClearDataMarketChange(opt.value)}
          isMulti={false}
          isClearable={false}
          options={MARKET_TYPES}
          row
          value={MARKET_TYPES.find((m) => m.value === marketType)}
        />

        <span className="date-label">Start Time</span>
        <OldDatePicker
          date={startTime}
          onClose={(start) => {
            setStartTime(start);
          }}
          options={{
            enableSeconds: false,
            enableTime: true,
            formatDate: (date) => {
              const dt = DateTime.fromJSDate(date).setZone(
                program ? program.timezone : 'UTC'
              );
              return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
            },
            minuteIncrement:
              marketType === 'SAMEDAY' && program
                ? program.sameday_event_duration / 60
                : 60,
          }}
          useUTC={false}
        />

        <span className="date-label">End Time</span>
        <OldDatePicker
          date={endTime}
          onClose={(end) => {
            setEndTime(end);
          }}
          options={{
            enableSeconds: false,
            enableTime: true,
            formatDate: (date) => {
              const dt = DateTime.fromJSDate(date).setZone(
                program ? program.timezone : 'UTC'
              );
              return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
            },
            minuteIncrement:
              marketType === 'SAMEDAY' && program
                ? program.sameday_event_duration / 60
                : 60,
          }}
          useUTC={false}
        />
        <Tooltip
          content={
            invalidClearDataOptions
              ? 'Start time must be before the end time.'
              : ''
          }
        >
          <Button
            disabled={clearingMarketData || invalidClearDataOptions}
            onClick={() => setShowModal(true)}
          >
            Clear Market Data
          </Button>
        </Tooltip>
      </Card>
      <Modal
        active={showModal}
        onClose={() => setShowModal(false)}
        cancelProps={{ disabled: clearingMarketData }}
        confirmProps={{
          disabled: clearingMarketData,
          onClick: clearMarketData,
        }}
        hideClose
        title="Confirm Market Data Deletion"
        width="300px"
      >
        <div className="confirm-message">
          <div>{`Are you sure you wish to delete market data from program: ${program?.name}? This action is permanent.`}</div>
        </div>
      </Modal>
    </>
  );
};

export default ClearMarketDataCard;
