import React from 'react';
import Select from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SelectOption } from '@mui/base';
import classnames from 'classnames';

import ChevronDownIcon from 'SVG/Icons/ChevronDownIcon';
import './Select.scss';

interface WrappedSelectProps {
  row?: boolean;
  placeholder?: string;
  label?: React.ReactNode;
  onChange: (key: any) => void;
  options?: SelectOption<any>[];
  value?: SelectOption<any> | null;
  defaultValue?: SelectOption<any>;
  name?: string;
  error?: boolean;
  helperText?: string | undefined;
  isClearable: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  classes?: {
    wrapperClass?: string;
    labelClass?: string;
    helperTextClass?: string;
  };
  styles?: any;
  variant?: 'outlined' | 'default';
  disablePortal?: boolean;
}

const WrappedSelect = ({
  row,
  label,
  error,
  helperText,
  classes,
  placeholder,
  options,
  value,
  defaultValue,
  onChange,
  name,
  isMulti = false,
  isDisabled = false,
  isClearable = true,
  variant = 'default',
  disablePortal = true,
}: WrappedSelectProps) => (
  <div
    className={classnames('select', classes?.wrapperClass, {
      select__row: !!row,
    })}
  >
    {label && (
      <label className={classnames('select__label', classes?.labelClass)}>
        {label}
      </label>
    )}

    <Select
      disablePortal={disablePortal}
      limitTags={2}
      disableClearable={!isClearable}
      multiple={isMulti}
      onChange={(data, value) => onChange(value)}
      disabled={isDisabled}
      popupIcon={<ChevronDownIcon width={17} />}
      value={value}
      defaultValue={defaultValue}
      classes={{
        popper: 'select__popper',
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            name={name}
            placeholder={placeholder}
            helperText={helperText}
            classes={{
              root: 'select__selectRoot',
            }}
            className={classnames({
              'select__selectRoot--outlined': variant === 'outlined',
            })}
          />
        );
      }}
      renderOption={(props, option, state) => {
        props['aria-selected'] = option.label === state.inputValue;
        return <li {...props}>{option.label}</li>;
      }}
      options={options || []}
      getOptionLabel={(option) => option.label as string}
      getOptionDisabled={(option) => option.disabled || false}
    />
  </div>
);

export default WrappedSelect;
