import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import SVG from 'components/SVG';
import ModalSection from 'routes/LongTermContracts/components/ModalSection';
import { BaselineTenants } from './components/BaselineTenants';

import {
  BaselineImportFormProps,
  FilesField,
  MeasurementDataExport,
  ModalData,
} from './BaselineImportForm.types';
import { Fields, schema } from './BaselineImportForm.logic';

import './BaselineImportForm.scss';

const BaselineImportForm: React.FC<BaselineImportFormProps> = ({
  selectedTenants,
  startDate,
  loading,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const removeFilesFromDer = (derId: string) => {
    setValue(`${Fields.Files}[${derId}]`, null, { shouldValidate: true });
    trigger(Fields.Files);
  };

  const submit = (data: { [x: string]: any }) => {
    const filtered: FilesField = {};
    for (const key in data.files) {
      if (data.files[key] && data.files[key].length > 0) {
        filtered[key] = data.files[key];
      }
    }
    onSubmit(filtered, reset);
  };

  const { data: baselineTenants } = useRequestEffect<ModalData[]>({
    url: `/api/dsp/tenant_ders/measurement_data_export_status`,
    method: 'get',
    refetchOnChange: [selectedTenants, startDate],
    blockRequest: () => !selectedTenants || !startDate,
    params: {
      tenant_ids: selectedTenants.join(','),
      start_date: startDate.toJSDate().toISOString(),
      end_date: startDate
        .plus({
          months: 1,
        })
        .toJSDate()
        .toISOString(),
    },
    toast: {
      error: 'Failed to load import baseline data.',
      settings: {
        autoDismiss: true,
      },
    },
    dataTransform: (data) =>
      data.map((tenant: MeasurementDataExport) => ({
        id: tenant.tenant_id,
        name: tenant.tenant_name,
        ders: tenant.owned_ders.map((der) => ({
          id: der.der_id,
          name: der.der_label,
          status: der.export_status,
        })),
      })),
  });

  return (
    <form onSubmit={handleSubmit(submit)} className="baseline-import-form">
      <ModalSection
        title="Baseline Data"
        icon={
          <SVG icon="Edit3Icon" className="baseline-import-form__panel-icon" />
        }
      >
        {baselineTenants?.map((tenant) => (
          <BaselineTenants
            key={tenant.id}
            tenant={tenant}
            removeFilesFromDer={removeFilesFromDer}
            register={register}
            control={control}
          />
        ))}
        {errors && errors.files && (
          <ErrorMessage>{errors.files.message}</ErrorMessage>
        )}
      </ModalSection>
      <div className="baseline-import-form__buttons">
        <Button
          variant="outlined"
          title="Cancel"
          htmlButtonType="button"
          onClick={onCancel}
          loading={loading}
        >
          Cancel
        </Button>
        <Button title="Submit" htmlButtonType="submit" loading={loading}>
          Upload baseline data
        </Button>
      </div>
    </form>
  );
};

export default BaselineImportForm;
