import React, { useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { Route, useParams } from 'react-router-dom';
import {
  apm,
  Request,
  useAuthContext,
} from '@opusonesolutions/gridos-app-framework';

import fileExportSave from 'helpers/downloadFile';

import RouterSwitch from 'components/RouterSwitch';
import ReportsMenu from './components/ReportsMenu';
import Settlements from './routes/Settlements';
import MarketTransactions from './routes/MarketTransactions';
import PowerFlowAnalysis from './routes/PowerFlowAnalysis';
import { AuditLogs } from './routes/AuditLog/AuditLogs';

import './Reports.scss';

export type CardProps = {
  downloading: boolean;
  downloadReport: (
    url: string,
    params?: {
      start_date?: string;
      end_date?: string;
      start_time?: string;
      end_time?: string;
      start?: string;
      end?: string;
    },
    fallback?: string
  ) => void;
  programId?: string;
};

export enum Routes {
  auditLogs = '/program/:programID/reports/audit-logs',
  settlements = '/program/:programID/reports/settlements',
  marketTransactions = '/program/:programID/reports/market-transactions',
  powerFlowAnalysis = '/program/:programID/reports/power-flow-analysis',
}

const Reports = () => {
  const { hasAllPermissions } = useAuthContext();
  const { programID: programId } = useParams<{ programID: string }>();
  const [downloading, setDownloading] = useState(false);

  const downloadReport = async (
    url: string,
    params: Record<string, unknown> = {},
    fallback: string | undefined
  ) => {
    setDownloading(true);

    const request = new Request(url);
    const options: AxiosRequestConfig = {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
      params,
    };

    try {
      const { data, headers, status } = await request.get(options);

      if (status !== 204) {
        fileExportSave(data, headers, fallback);
      }
    } catch (error: any) {
      apm.captureError(error);
    }

    setDownloading(false);
  };

  return (
    <div className="program-reports">
      <ReportsMenu programID={programId} />
      <RouterSwitch>
        <Route exact path={Routes.settlements}>
          <Settlements
            downloading={downloading}
            downloadReport={downloadReport}
            programId={programId || ''}
          />
        </Route>
        <Route exact path={Routes.marketTransactions}>
          <MarketTransactions
            downloading={downloading}
            downloadReport={downloadReport}
            programId={programId || ''}
          />
        </Route>
        <Route exact path={Routes.powerFlowAnalysis}>
          <PowerFlowAnalysis
            downloading={downloading}
            downloadReport={downloadReport}
            programId={programId || ''}
          />
        </Route>
        {hasAllPermissions(['download_audit_logs']) && (
          <Route exact path={Routes.auditLogs}>
            <AuditLogs
              downloading={downloading}
              downloadReport={downloadReport}
            />
          </Route>
        )}
      </RouterSwitch>
    </div>
  );
};

export default Reports;
