import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Controller } from 'react-hook-form';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { SelectOption } from 'types';
import { Feeder } from 'types/feeder';
import { feederOption } from 'types/contract-managment';

import { getDateTimeFromWeekDay } from 'helpers/date';

// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import FormLabel from 'components/FormLabel';
import NumberInput from 'components/NumberInput';
import PageTitle from 'components/PageTitle';
import Select from 'components/Select';
import SVG from 'components/SVG';
import TimePicker from 'components/TimePicker';
// eslint-disable-next-line custom-rules/deprecated-component
import Tooltip from 'components/OldTooltip';

import { closeAfterEnum } from 'routes/RequestsResponses/CreateRequest/CreateRequestSetup/CreateRequestSetup.types';
import { MarketType } from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';
import { NeedsAnalysisType } from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';
import { closeAfterOptions } from 'routes/RequestsResponses/CreateRequest/CreateRequestSetup/CreateRequestSetup.logic';
import { selectStyles } from 'routes/RequestsResponses/CreateRequest/CreateRequestService/CreateRequestService.logic';
import {
  daysOfTheWeekOptions,
  useFormControlsReturnTypeWeekAhead,
  useFormControlsReturnTypeDayAhead,
} from './NeedsAnalysisForm.logic';
import RadioButtonGroup from 'routes/RequestsResponses/components/RadioButtonGroup';
import CustomPeakSelector from 'routes/LongTermContracts/components/CustomPeakSelector';
import {
  GenerateMenu,
  GenerateContractSwitches,
} from './NeedsAnalysisForm.render';

import './NeedsAnalysisForm.scss';

interface NeedsAnalysisFormProps {
  marketType: MarketType;
  setChangesWereMade: React.Dispatch<React.SetStateAction<boolean>>;
  formControls:
    | useFormControlsReturnTypeWeekAhead
    | useFormControlsReturnTypeDayAhead;
}

export const NeedsAnalysisForm = ({
  marketType,
  setChangesWereMade,
  formControls,
}: NeedsAnalysisFormProps) => {
  const { feeders } = useProgramsContext();
  const [disableClose, setDisableClose] = useState(true);

  const feederList: feederOption[] = feeders.reduce(
    (feederList: feederOption[], feeder: Feeder) => {
      if (feeder.enrolled) {
        feederList.push({
          label: feeder.name,
          value: feeder.id,
        });
      }
      return feederList;
    },
    [] as feederOption[]
  );

  const isWeekAhead = marketType === MarketType.weekAhead;
  const control = isWeekAhead
    ? (formControls as useFormControlsReturnTypeWeekAhead).controlWeekAhead
    : (formControls as useFormControlsReturnTypeDayAhead).controlDayAhead;
  const errors = isWeekAhead
    ? (formControls as useFormControlsReturnTypeWeekAhead).errorsWeekAhead
    : (formControls as useFormControlsReturnTypeDayAhead).errorsDayAhead;
  const getValues = isWeekAhead
    ? (formControls as useFormControlsReturnTypeWeekAhead).getValuesWeekAhead
    : (formControls as useFormControlsReturnTypeDayAhead).getValuesDayAhead;
  const setValue = isWeekAhead
    ? (formControls as useFormControlsReturnTypeWeekAhead).setValueWeekAhead
    : (formControls as useFormControlsReturnTypeDayAhead).setValueDayAhead;
  const setFormState = isWeekAhead
    ? (formControls as useFormControlsReturnTypeWeekAhead).setFormStateWeekAhead
    : (formControls as useFormControlsReturnTypeDayAhead).setFormStateDayAhead;
  const formState = isWeekAhead
    ? (formControls as useFormControlsReturnTypeWeekAhead).formStateWeekAhead
    : (formControls as useFormControlsReturnTypeDayAhead).formStateDayAhead;
  const triggerFormValidation = isWeekAhead
    ? (formControls as useFormControlsReturnTypeWeekAhead)
        .triggerWeekAheadValidation
    : (formControls as useFormControlsReturnTypeDayAhead)
        .triggerDayAheadValidation;

  const onFieldChangeHandler = (name: keyof NeedsAnalysisType, value: any) => {
    setValue(name, value);
    setFormState((prev) => ({ ...prev, name: value }));
    const millisecondsInDay = 24 * 60 * 60 * 1000;

    const valuesNecessaryForPublishAfterCalculations = [
      'publish_request_time',
      'analysis_time',
      'publish_request_day',
      'analysis_day',
    ];

    if (
      valuesNecessaryForPublishAfterCalculations.includes(name) &&
      !valuesNecessaryForPublishAfterCalculations
        .map((valueName: string) => {
          return !!getValues(valueName);
        })
        .includes(false)
    ) {
      const publishAfterHours =
        DateTime.fromFormat(getValues('publish_request_time'), 'H:mm:ss').hour -
        DateTime.fromFormat(getValues('analysis_time'), 'H:mm:ss').hour;

      const publishAfterDays = Math.floor(
        getDateTimeFromWeekDay(getValues('publish_request_day')).diff(
          getDateTimeFromWeekDay(getValues('analysis_day'))
        ).milliseconds / millisecondsInDay
      );

      onFieldChangeHandler(
        'publish_request_after_hours',
        publishAfterDays * 24 + publishAfterHours
      );
    }

    const valuesNecessaryForCloseAfterCalculations = [
      'publish_request_time',
      'publish_request_day',
      'close_request_date',
    ];

    if (
      valuesNecessaryForCloseAfterCalculations.includes(name) &&
      !valuesNecessaryForCloseAfterCalculations
        .map((valueName: string) => {
          return !!getValues(valueName);
        })
        .includes(false) &&
      ![24, 48, 72].includes(getValues('close_request_after_hours'))
    ) {
      const closeAfterHours =
        (getValues('close_request_date') as DateTime).hour -
        DateTime.fromFormat(getValues('publish_request_time'), 'H:mm:ss').hour;

      const closeAfterDays = Math.floor(
        getValues('close_request_date').diff(
          getDateTimeFromWeekDay(getValues('publish_request_day'))
        ).milliseconds / millisecondsInDay
      );
      onFieldChangeHandler(
        'close_request_after_hours',
        closeAfterDays * 24 + closeAfterHours
      );
    }

    setChangesWereMade(true);
    triggerFormValidation();
    triggerFormValidation();
  };

  return (
    <form id={`${marketType}`}>
      <div className="needs-analysis-form">
        <div className="needs-analysis-form__section">
          <PageTitle
            title="Analysis setup"
            classes={{
              titleClass:
                'needs-analysis-form__title needs-analysis-form__title-top',
            }}
          ></PageTitle>

          <div className="needs-analysis-form__input">
            <FormLabel htmlFor="feeder_enrollments">Zone</FormLabel>
            <Controller
              name="feeder_enrollments"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {GenerateMenu(
                    feederList,
                    onFieldChangeHandler,
                    getValues('feeder_enrollments')
                  )}
                </>
              )}
            />
            <div className="needs-analysis-form__input-error needs-analysis-form__input-error-mt-10">
              {errors?.feeder_enrollments?.message}
            </div>
          </div>

          <div className="needs-analysis-form__input needs-analysis-form__input-padded">
            <FormLabel htmlFor="procurement_volume">
              Procurement volume %
              <Tooltip
                arrow={true}
                content="Procurement volume"
                theme="light"
                distance={14}
                className="needs-analysis-form__section-label-icon"
              >
                <SVG icon="InfoIcon" fontSize="12px" />
              </Tooltip>
            </FormLabel>
            <Controller
              name="procurement_volume"
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  id="procurement_volume"
                  onChange={(value) => {
                    onChange(Number(value));
                    onFieldChangeHandler('procurement_volume', Number(value));
                  }}
                  unit="%"
                  value={getValues('procurement_volume')}
                  placeholder="Type"
                  inputFieldUnitClassName="needs-analysis-form__input-unit"
                  invalid={!!errors?.procurement_volume?.message}
                />
              )}
            />
            <div className="needs-analysis-form__input-error">
              {errors?.procurement_volume?.message}
            </div>
          </div>

          <div className="needs-analysis-form__input">
            <FormLabel htmlFor="include_accepted_contracts">
              Contract status
            </FormLabel>
            <Controller
              name="include_accepted_contracts"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {GenerateContractSwitches(
                    'include_accepted_contracts',
                    'Include accepted contracts',
                    getValues('include_accepted_contracts'),
                    onFieldChangeHandler
                  )}
                </>
              )}
            />
            <Controller
              name="include_pending_contracts"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {GenerateContractSwitches(
                    'include_pending_contracts',
                    'Include pending contracts',
                    getValues('include_pending_contracts'),
                    onFieldChangeHandler
                  )}
                </>
              )}
            />
          </div>
        </div>

        <div className="needs-analysis-form__section">
          <PageTitle
            title="Service setup"
            classes={{ titleClass: 'needs-analysis-form__title' }}
          ></PageTitle>
          <Grid container spacing={2}>
            <Grid item>
              <FormLabel htmlFor="serviceDays" styles={{ maxWidth: '130px' }}>
                Service days
                <Tooltip
                  arrow={true}
                  content="The request will service the selected days and times"
                  theme="light"
                  distance={14}
                  className="needs-analysis-form__section-label-icon"
                >
                  <SVG icon="InfoIcon" fontSize="12px" />
                </Tooltip>
              </FormLabel>
              <Controller
                name="serviceDays"
                control={control}
                defaultValue={{
                  monday: formState.monday,
                  tuesday: formState.tuesday,
                  wednesday: formState.wednesday,
                  thursday: formState.thursday,
                  friday: formState.friday,
                  saturday: formState.saturday,
                  sunday: formState.sunday,
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomPeakSelector
                    startDate={undefined}
                    endDate={undefined}
                    selectedDays={{
                      monday: value.monday,
                      tuesday: value.tuesday,
                      wednesday: value.wednesday,
                      thursday: value.thursday,
                      friday: value.friday,
                      saturday: value.saturday,
                      sunday: value.sunday,
                    }}
                    onChange={(value) => {
                      onChange(value);
                      onFieldChangeHandler('monday', value.monday);
                      onFieldChangeHandler('tuesday', value.tuesday);
                      onFieldChangeHandler('wednesday', value.wednesday);
                      onFieldChangeHandler('thursday', value.thursday);
                      onFieldChangeHandler('friday', value.friday);
                      onFieldChangeHandler('saturday', value.saturday);
                      onFieldChangeHandler('sunday', value.sunday);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              item
              xs={3}
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <FormLabel htmlFor="window_start_time">
                  From
                  <Tooltip
                    arrow={true}
                    content="The request will service the selected days and times"
                    theme="light"
                    distance={14}
                    className="needs-analysis-form__section-label-icon"
                  >
                    <SVG icon="InfoIcon" fontSize="12px" />
                  </Tooltip>
                </FormLabel>
                <Controller
                  name="window_start_time"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      value={
                        getValues('window_start_time')
                          ? DateTime.fromFormat(
                              getValues('window_start_time'),
                              'H:mm:ss'
                            )
                          : undefined
                      }
                      increments={60}
                      onChange={(value: DateTime | undefined) => {
                        onChange(value);
                        onFieldChangeHandler(
                          'window_start_time',
                          value?.toFormat('HH:mm:ss')
                        );
                      }}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <FormLabel htmlFor="window_end_time">
                  To
                  <Tooltip
                    arrow={true}
                    content="The request will service the selected days and times"
                    theme="light"
                    distance={14}
                    className="needs-analysis-form__section-label-icon"
                  >
                    <SVG icon="InfoIcon" fontSize="12px" />
                  </Tooltip>
                </FormLabel>
                <Controller
                  name="window_end_time"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      value={
                        getValues('window_end_time')
                          ? DateTime.fromFormat(
                              getValues('window_end_time'),
                              'H:mm:ss'
                            )
                          : undefined
                      }
                      increments={60}
                      onChange={(value: DateTime | undefined) => {
                        onChange(value);
                        onFieldChangeHandler(
                          'window_end_time',
                          value?.toFormat('HH:mm:ss')
                        );
                      }}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="needs-analysis-form__input-error needs-analysis-form__input-error-mt-10">
          {errors?.serviceDays?.message || ''}
          {errors?.window_start_time?.message ||
            errors?.window_end_time?.message ||
            ''}
        </div>

        <div className="needs-analysis-form__section">
          <PageTitle
            title="Repeat settings"
            classes={{ titleClass: 'needs-analysis-form__title' }}
          ></PageTitle>

          <Grid container spacing={2}>
            <Grid item>
              <div className="needs-analysis-form__input">
                <FormLabel htmlFor="analysis_day">
                  Trigger request creation
                </FormLabel>
                <Controller
                  name="analysis_day"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={daysOfTheWeekOptions.find(
                        (opt) => opt.value === getValues('analysis_day')
                      )}
                      isClearable={true}
                      onChange={(option: SelectOption) => {
                        onChange(option);
                        onFieldChangeHandler('analysis_day', option?.value);
                      }}
                      options={daysOfTheWeekOptions}
                      error={!!errors?.analysis_day?.message}
                      styles={selectStyles}
                      placeholder="Select"
                    />
                  )}
                />
                <div className="needs-analysis-form__input-error needs-analysis-form__input-error-mt-10">
                  {errors?.analysis_day?.message}
                </div>
              </div>
            </Grid>
            <Grid item>
              <FormLabel htmlFor="analysis_time">
                <span className="needs-analysis-form__input-padded">At</span>
                <Tooltip
                  arrow={true}
                  content="Request will be created on the selected day and time"
                  theme="light"
                  distance={14}
                  className="needs-analysis-form__section-label-icon needs-analysis-form__section-label-icon--padded"
                >
                  <SVG icon="InfoIcon" fontSize="12px" />
                </Tooltip>
              </FormLabel>
              <Controller
                name="analysis_time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TimePicker
                    value={
                      getValues('analysis_time')
                        ? DateTime.fromFormat(
                            getValues('analysis_time'),
                            'H:mm:ss'
                          )
                        : undefined
                    }
                    increments={60}
                    onChange={(date: DateTime | undefined) => {
                      onChange(date);
                      onFieldChangeHandler(
                        'analysis_time',
                        date?.toFormat('HH:mm:ss')
                      );
                    }}
                    disabled={false}
                  />
                )}
              />
              <div className="needs-analysis-form__input-error needs-analysis-form__input-error-mt-10">
                {errors?.analysis_time?.message}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              <div className="needs-analysis-form__input needs-analysis-form__input">
                <FormLabel htmlFor="publish_request_day">
                  Publish request in NMF
                </FormLabel>
                <Controller
                  name="publish_request_day"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={
                        getValues('publish_request_day')
                          ? daysOfTheWeekOptions.find(
                              (opt) =>
                                opt.value === getValues('publish_request_day')
                            )
                          : undefined
                      }
                      isClearable={true}
                      onChange={(value: SelectOption) => {
                        onChange(value);
                        onFieldChangeHandler(
                          'publish_request_day',
                          value?.value
                        );
                      }}
                      options={daysOfTheWeekOptions}
                      error={!!errors?.publish_request_day?.message}
                      styles={selectStyles}
                      placeholder="Select"
                    />
                  )}
                />
                <div className="needs-analysis-form__input-error needs-analysis-form__input-error-mt-10">
                  {errors?.publish_request_after_hours?.message ||
                    errors?.publish_request_day?.message}
                </div>
              </div>
            </Grid>
            <Grid item>
              <FormLabel htmlFor="publish_request_time">
                <span className="needs-analysis-form__input-padded">At</span>
                <Tooltip
                  arrow={true}
                  content="The request will service the selected days and times"
                  theme="light"
                  distance={14}
                  className="needs-analysis-form__section-label-icon needs-analysis-form__section-label-icon--padded"
                >
                  <SVG icon="InfoIcon" fontSize="12px" />
                </Tooltip>
              </FormLabel>
              <Controller
                name="publish_request_time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TimePicker
                    value={
                      getValues('publish_request_time')
                        ? DateTime.fromFormat(
                            getValues('publish_request_time'),
                            'H:mm:ss'
                          )
                        : undefined
                    }
                    increments={60}
                    onChange={(date: DateTime | undefined) => {
                      onChange(date);
                      onFieldChangeHandler(
                        'publish_request_time',
                        date?.toFormat('HH:mm:ss')
                      );
                    }}
                    disabled={false}
                  />
                )}
              />
              <div className="needs-analysis-form__input-error needs-analysis-form__input-error-mt-10">
                {errors?.publish_request_time?.message}
              </div>
            </Grid>
          </Grid>

          <FormLabel
            styles={{ marginBottom: '8px' }}
            htmlFor="close_request_after_hours"
          >
            <span className="needs-analysis-form__input-padded">
              Close request after
            </span>
          </FormLabel>
          <Controller
            name="close_request_after_hours"
            control={control}
            defaultValue={
              closeAfterOptions.find(
                (val) => val.hours === getValues('close_request_after_hours')
              )?.id || getValues('close_after_type')
                ? getValues('close_after_type')
                : closeAfterEnum.TWENTY_FOUR_HOURS
            }
            render={({ field: { onChange, value } }) => (
              <RadioButtonGroup
                id="closeAfterDateTime"
                listType="row"
                label=""
                onChange={(value) => {
                  if (value === closeAfterEnum.CUSTOM) {
                    onChange(value);
                  }
                  onFieldChangeHandler(
                    'close_after_type',
                    marketType !== MarketType.dayAhead
                      ? value
                      : closeAfterEnum.TWENTY_FOUR_HOURS
                  );
                  const closeAfterOpt = closeAfterOptions.find(
                    ({ id }) => id === value
                  );
                  const publishAfter = getValues('publish_request_after_hours');
                  if (publishAfter) {
                    if (closeAfterOpt?.hours) {
                      onFieldChangeHandler(
                        'close_request_after_hours',
                        closeAfterOpt.hours + publishAfter
                      );
                    } else {
                      onFieldChangeHandler(
                        'close_request_date',
                        getValues('close_request_date')
                      );
                    }
                  }
                  setDisableClose(value !== closeAfterEnum.CUSTOM);
                }}
                options={
                  marketType === MarketType.dayAhead
                    ? closeAfterOptions.map((opt) => {
                        return {
                          ...opt,
                          disabled:
                            opt.label === closeAfterEnum.TWENTY_FOUR_HOURS
                              ? false
                              : true,
                        };
                      })
                    : closeAfterOptions
                }
                value={
                  marketType !== MarketType.dayAhead
                    ? getValues('close_after_type')
                    : closeAfterEnum.TWENTY_FOUR_HOURS
                }
              />
            )}
          />
        </div>

        {getValues('close_after_type') === closeAfterEnum.CUSTOM && (
          <Controller
            name="close_request_date"
            control={control}
            defaultValue={getValues('close_request_date') || undefined}
            render={({ field: { onChange, value } }) => (
              <Grid
                container
                spacing={2}
                className="needs-analysis-form__input-custom-close-time"
              >
                <Grid
                  item
                  className="needs-analysis-form__input-custom-close-time-grid-item"
                >
                  <div className="needs-analysis-form__input needs-analysis-form__input">
                    <FormLabel htmlFor="close_request_date">
                      Close request on
                      <Tooltip
                        arrow
                        content="Request will close on this day."
                        theme="light"
                      >
                        <SVG
                          icon="InfoIcon"
                          fontSize="12px"
                          className="needs-analysis-form__section-label-icon"
                        />
                      </Tooltip>
                    </FormLabel>

                    <Select
                      value={
                        value
                          ? daysOfTheWeekOptions.find(
                              (opt) =>
                                opt.value === value.weekdayLong.toUpperCase()
                            )
                          : undefined
                      }
                      isClearable={true}
                      onChange={(value: SelectOption) => {
                        onChange(value);
                        const date = value
                          ? getDateTimeFromWeekDay(value.value)
                          : undefined;
                        if (date) {
                          onFieldChangeHandler('close_request_date', date);
                        }
                      }}
                      options={daysOfTheWeekOptions}
                      error={!!errors?.publish_request_day?.message}
                      styles={selectStyles}
                      placeholder="Select"
                      isDisabled={
                        disableClose &&
                        getValues('close_after_type') !== closeAfterEnum.CUSTOM
                      }
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  className="needs-analysis-form__input-custom-close-time-grid-item"
                >
                  <FormLabel htmlFor="close_request_date">
                    <span className="needs-analysis-form__input-padded">
                      At
                    </span>
                    <Tooltip
                      arrow={true}
                      content="Request will be closed on the selected day and time"
                      theme="light"
                      distance={14}
                      className="needs-analysis-form__section-label-icon needs-analysis-form__section-label-icon--padded"
                    >
                      <SVG icon="InfoIcon" fontSize="12px" />
                    </Tooltip>
                  </FormLabel>
                  <TimePicker
                    value={value}
                    increments={60}
                    onChange={(date: DateTime | undefined) => {
                      const newDate = (
                        (getValues('close_request_date') as DateTime) ||
                        DateTime.local()
                      ).set({
                        hour: date?.hour,
                        minute: date?.minute,
                        second: 0,
                        millisecond: 0,
                      });
                      onChange(newDate);
                      onFieldChangeHandler('close_request_date', newDate);
                    }}
                    disabled={false}
                  />
                </Grid>
              </Grid>
            )}
          />
        )}
        <div className="needs-analysis-form__input-error needs-analysis-form__input-error-mt-10">
          {errors?.close_request_after_hours?.message}
        </div>
      </div>
    </form>
  );
};
