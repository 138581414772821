import React from 'react';
import Switch from 'components/Switch';

type SwitchCellProps = {
  id: string;
  enabled: boolean;
  onClick: () => void;
};

const SwitchCell = ({ id, enabled, onClick }: SwitchCellProps) => {
  return (
    <Switch
      id={id}
      checked={enabled}
      onClick={onClick}
      type="primary"
      classNames={{
        switchClass: 'notification-table__switch',
      }}
    />
  );
};

export default SwitchCell;
