import React from 'react';

import SVG from 'components/SVG';
import { Button, ButtonProps } from '../Button/Button';
import cn from 'classnames';

import './IconButton.scss';

export interface IconButtonProps extends Omit<ButtonProps, 'variant'> {
  height?: string;
  width?: string;
  fontSize?: string;
  selected?: boolean;
  icon: string;
  iconColor?: string | undefined;
  iconClass?: string;
  roundIcon?: boolean;
  variant?: 'iconButton' | 'nestedIconButton';
}

export function IconButton({
  icon,
  iconColor,
  iconClass,
  roundIcon = false,
  fontSize,
  height,
  width,
  color = 'primary',
  selected = false,
  disabled = false,
  loading = false,
  variant = 'iconButton',
  customClasses = {},
  ...props
}: IconButtonProps) {
  const { customButtonClass, customLoaderClass, customLoaderSpokeClass } =
    customClasses;

  let iconSize = '16px';
  if (fontSize) {
    iconSize = fontSize;
  }

  return (
    <Button
      {...props}
      color={color}
      variant="text"
      size="medium"
      loading={variant === 'nestedIconButton' ? false : loading}
      disabled={disabled}
      customClasses={{
        customButtonClass: cn(
          {
            'icon-button': variant === 'iconButton',
            'icon-button__nested': variant === 'nestedIconButton',
            [`icon-button__${color}`]: variant === 'iconButton',
            [`icon-button__nested-${color}`]: variant === 'nestedIconButton',
            [`icon-button__${color}--selected`]:
              variant === 'iconButton' && (selected || loading),
          },
          customButtonClass
        ),
        customLoaderSpokeClass: cn(
          `icon-button__${color}-loader`,
          customLoaderSpokeClass
        ),
        customLoaderClass: customLoaderClass,
      }}
    >
      <SVG
        icon={icon}
        className={cn(iconClass)}
        color={iconColor}
        fontSize={iconSize}
        round={roundIcon}
        styles={{ height: height, width: width }}
      />
    </Button>
  );
}
