import React, { useCallback, useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import { DateTime } from 'luxon';

import { Calendar } from 'components/Calendar';
import { DatePickerPopoverProps } from '../DatePicker.types';

export const DatePickerPopover = ({
  anchor,
  value,
  onClose,
  onChange,
  maxDate,
  minDate,
  disabledDays,
  selectedMonth,
  disabledMonthPicker,
}: DatePickerPopoverProps) => {
  const initValue = useRef<boolean>(false);
  const skipDate = useRef<boolean>(false);
  const [currentValue, setValue] = useState<DateTime | null>(DateTime.local());

  useEffect(() => {
    if (value && value.isValid) {
      setValue(value);
    }
  }, [value]);

  const safeOnChange = useCallback(
    (date: DateTime | null) => {
      if (skipDate.current) {
        skipDate.current = false;
        setValue(date);
        return;
      }
      if (date) {
        onChange(date);
      }
    },
    [skipDate, setValue, onChange]
  );

  const onCloseHandler = () => {
    onClose(currentValue);
  };

  useEffect(() => {
    if (!initValue.current && !!anchor) {
      initValue.current = true;
    }
  }, [anchor, initValue, setValue, value]);

  useEffect(() => {
    if (!anchor) {
      initValue.current = false;
    }
  }, [anchor]);

  return (
    <Popover
      disableAutoFocus
      disableEnforceFocus
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onCloseHandler}
    >
      <Calendar
        value={currentValue}
        onChange={safeOnChange}
        maxDate={maxDate}
        minDate={minDate}
        disabledDays={disabledDays}
        selectedMonth={selectedMonth}
        disabledMonthPicker={disabledMonthPicker}
      />
    </Popover>
  );
};
