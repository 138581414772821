import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { Tenant } from 'types/tenant';
import {
  VintagedBidOfferRaw,
  VintagedBidOffer,
} from 'types/vintaged-bid-offers';

import { ProcessRawVBOs } from '../helpers/dataProcessers';
import EventsTable from '../EventsTable';
import { EventsTableColumns } from '../EventsTable/EventsTable';

import './DayAheadPage.scss';

function DayAheadPage() {
  const { programID } = useParams<{
    programID: string;
  }>();
  const { selectedProgram: program, getFeeder } = useProgramsContext();

  const [filterDate, setFilterDate] = useState<DateTime>(
    program
      ? DateTime.local().setZone(program.timezone).plus({
          days: 1,
        })
      : DateTime.local().plus({
          days: 1,
        })
  );

  useEffect(() => {
    setFilterDate(
      program
        ? DateTime.local().setZone(program.timezone).plus({
            days: 1,
          })
        : DateTime.local().plus({
            days: 1,
          })
    );
  }, [program]);

  const { data: tenants, loading: loadingTenants } = useRequestEffect<Tenant[]>(
    {
      url: '/api/dsp/tenants',
      method: 'get',
      toast: {
        error: 'Failed to load tenant list.',
        settings: {
          autoDismiss: true,
        },
      },
    }
  );

  const { data: offers } = useRequestEffect<VintagedBidOffer[]>({
    urlGen: () =>
      `/api/dsp/program/${programID}/vintaged_bid_offer/market/DAYAHEAD`,
    params: {
      start_time: filterDate.startOf('day').toJSDate().toISOString(),
      end_time: filterDate
        .startOf('day')
        .plus({ days: 1 })
        .toJSDate()
        .toISOString(),
    },
    initialData: [],
    method: 'get',
    refetchOnChange: [programID, tenants, loadingTenants, filterDate],
    toast: {
      error: 'Failed to load bids & offers',
      settings: {
        autoDismiss: true,
      },
    },
    blockRequest: () => !program || loadingTenants || !tenants,
    dataTransform: (data) => {
      return data.map((vbo: VintagedBidOfferRaw) =>
        ProcessRawVBOs(vbo, tenants, program || null, getFeeder)
      );
    },
  });

  const DAColumns: EventsTableColumns[] = [
    'time',
    'bidOffer',
    'feeder',
    'quantity',
    'quantityKVAR',
    'availability',
    'utilization',
    'responder',
    'arrow',
    'requestor',
    'serviceType',
    'psaEligible',
    'status',
  ];

  return (
    <div className="day-ahead-page">
      {program && (
        <EventsTable
          type="DA Analysis"
          showColumns={DAColumns}
          data={offers || []}
          program={program}
          dateFilterState={{ filterDate, setFilterDate }}
        />
      )}
    </div>
  );
}

export default DayAheadPage;
