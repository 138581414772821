import React from 'react';
import classNames from 'classnames';

import SVG from '../SVG';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';

import './Tag.scss';

const defaultClasses = {
  tagClass: '',
  contentClass: '',
  infoIconClass: '',
};

export interface TagParams {
  content: string | React.ReactChild;
  customClasses?: {
    tagClass?: string;
    contentClass?: string;
    infoIconClass?: string;
  };
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
  round?: boolean;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  info?: string;
  onRemove?: () => void;
  style?: React.CSSProperties;
}

/**
 * Generates Tag component. Also known as a pill or chip.
 * @returns tag component
 */
function Tag({
  customClasses = defaultClasses,
  content,
  type,
  round = false,
  onRemove,
  info,
  rightElement,
  leftElement,
  style,
}: TagParams): React.ReactElement {
  const { tagClass, infoIconClass, contentClass } = customClasses;

  return (
    <span
      className={classNames(tagClass, 'tag', `tag-${type}`, {
        'tag--round': round,
      })}
      style={style}
    >
      {leftElement}
      <span className={`${contentClass || ''} tag__content`}>{content}</span>
      {rightElement}
      {info && (
        <Tooltip content={info}>
          <SVG
            icon="info_outlined"
            className={`${infoIconClass || ''} tag__info`}
            round
          />
        </Tooltip>
      )}
      {onRemove && (
        <IconButton
          roundIcon
          variant="nestedIconButton"
          icon="XIcon"
          onClick={onRemove}
        />
      )}
    </span>
  );
}

export default Tag;
