import React, { useMemo, useState } from 'react';

// eslint-disable-next-line custom-rules/deprecated-component
import OldCheckbox from 'components/OldCheckbox';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import Select from 'components/OldSelect';
import { SelectOption } from 'types';

import { TimeseriesTagMap } from './MappingTypes';

import './AddMappingModal.scss';

type AddMappingModalProps = {
  onCancel: () => void;
  onConfirm: (mapping: {
    tag_id: string;
    in_load_sign_convention: boolean;
  }) => void;
  modalOpen: boolean;
  tagMap: TimeseriesTagMap;
  timeseriesOptions: SelectOption[];
};

const AddMappingModal = ({
  onCancel,
  onConfirm,
  modalOpen,
  tagMap,
  timeseriesOptions,
}: AddMappingModalProps) => {
  const [loadPositive, setLoadPositive] = useState(true);
  const [timeseriesID, setTimeseriesID] = useState<string | null>(null);
  const [tagID, setTagID] = useState<string | null>(null);

  const tagOptions = useMemo(() => {
    if (!timeseriesID) {
      return [];
    }

    return Object.values(tagMap[timeseriesID]).map((tag) => ({
      label: tag.name,
      value: tag.id,
    }));
  }, [tagMap, timeseriesID]);

  return (
    <Modal
      title="Enter New Measurement Mapping"
      width="450px"
      active={modalOpen}
      height="400px"
      hideClose
      confirmProps={{
        disabled: !timeseriesID || !tagID,
        label: 'Save',
        onClick: async () => {
          if (tagID) {
            await onConfirm({
              tag_id: tagID,
              in_load_sign_convention: loadPositive,
            });
            // Clear old data after save
            setTimeseriesID(null);
            setTagID(null);
          }
        },
      }}
      onClose={() => onCancel()}
    >
      <div className="add-mapping-modal">
        <Select
          label="Timeseries"
          isClearable={false}
          isMulti={false}
          onChange={(opt) => {
            setTimeseriesID(opt.value);
            setTagID(null);
          }}
          options={timeseriesOptions}
          value={
            timeseriesID
              ? timeseriesOptions.find((opt) => opt.value === timeseriesID)
              : null
          }
        />
        <Select
          label="Tag"
          isClearable={false}
          isMulti={false}
          onChange={(opt) => setTagID(opt.value)}
          options={tagOptions}
          value={tagID ? tagOptions.find((opt) => opt.value === tagID) : null}
        />
        <div>Load Positive?</div>
        <OldCheckbox
          checked={loadPositive}
          onClick={() => setLoadPositive(!loadPositive)}
        />
      </div>
    </Modal>
  );
};

export default AddMappingModal;
