import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Cell } from 'react-table';
import { DateTime } from 'luxon';

import Table from 'components/Table';
import IconButton from 'components/IconButton';

import ReportsTableFilter from '../ReportsTableFilter';

import './TimePeriodsTable.scss';

export type PeriodsTable = {
  timePeriod: string;
  date: DateTime;
};

export interface PeriodsTableParams {
  customColumnHeader: string;
  data: PeriodsTable[];
  downloadDaylyReport: (value: DateTime) => void;
  downloadLogs: (start: DateTime, end: DateTime) => void;
  setFilteredResults: Dispatch<SetStateAction<PeriodsTable[] | undefined>>;
  downloading: boolean;
}

type columns = 'timePeriod' | 'date';

const timePeriodsColumns: columns[] = ['timePeriod', 'date'];

export const TimePeriodsTable = ({
  customColumnHeader,
  data,
  downloadDaylyReport,
  downloadLogs,
  setFilteredResults,
  downloading,
}: PeriodsTableParams) => {
  const renderPeriods = (cellInfo: any) => {
    return <div className="periods-table__text">{cellInfo.cell.value}</div>;
  };

  const renderAction = useMemo(
    () => (cellInfo: any) => {
      return (
        <div className="periods-table__text">
          <IconButton
            icon="DownloadIcon"
            onClick={() => downloadDaylyReport(cellInfo.cell.value)}
            iconClass="periods-table__action-icon"
          />
        </div>
      );
    },
    [downloadDaylyReport]
  );

  const columns = useMemo(() => {
    const availableColumns = {
      timePeriod: {
        Header: `${customColumnHeader} period`,
        accessor: 'timePeriod',
        Cell: renderPeriods,
      },
      date: {
        Header: '',
        accessor: 'date',
        Cell: renderAction,
      },
    };

    const generateColumns: any[] = [];
    timePeriodsColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [renderAction, customColumnHeader]);

  const renderFilter = () => (
    <ReportsTableFilter
      downloadLogs={downloadLogs}
      setFilteredResults={setFilteredResults}
      downloading={downloading}
    />
  );

  /**
   * Custom function to handle cell rendering.
   * Handles the logic for coloring specific columns.
   * @param {Row} row - object containing information about the row being rendered.
   * @param index - cell index in the row
   * @param tableRowClass - custom general row class.
   * @param tableCellClass - custom general cell class.
   * @returns - row
   */
  function renderCells(
    row: any,
    index: number,
    tableRowClass: string | undefined,
    tableCellClass: string | undefined
  ) {
    return (
      <div className={tableRowClass} {...row.getRowProps()} key={index}>
        {row.cells.map((cell: Cell, index: number) => (
          <div {...cell.getCellProps()} key={index} className={tableCellClass}>
            {cell.render('Cell')}
          </div>
        ))}
      </div>
    );
  }

  const TableStyles = {
    tableClass: 'periods-table',
    tableHeaderRowClass: 'periods-table__header-row',
    tableHeaderCellClass: 'periods-table__header-cell',
    tableBodyRowClass: 'periods-table__body-row',
    tableBodyCellClass: 'periods-table__body-cell',
  };

  return (
    <Table
      disableSort={false}
      data={data}
      columns={columns}
      customClasses={TableStyles}
      customRowRenderFunction={renderCells}
      header={renderFilter}
    />
  );
};
