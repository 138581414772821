import { useMemo } from 'react';

import { getCurrencySymbol } from 'helpers/locale';

export default function useLocaleFormatter(currency = 'USD', locale = 'en-US') {
  const currencyFormatter = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  }, [currency, locale]);
  const longCurrencyFormatter = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 3,
      maximumFractionDigits: 17, // maximum number of decimals for 64-bit Floating Point
    });
  }, [currency, locale]);
  const numberFormatter = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [locale]);
  const currencySymbol = useMemo(() => {
    return getCurrencySymbol(currencyFormatter);
  }, [currencyFormatter]);

  return {
    currencyFormatter,
    longCurrencyFormatter,
    currencySymbol,
    numberFormatter,
  };
}
