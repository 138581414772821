import classNames from 'classnames';
import React from 'react';

/**
 * Creates a loading spinner. There are two variations available:
 *   - circles : spinner made up of circle that change opacity
 *   - bars : 3 vertical bars that change size
 */
type LoadingSpinnerProps = {
  type: 'bars' | 'circles';
  customStyles?: {
    root?: string;
    spinner?: string;
  };
};

const LoadingSpinner = ({ type, customStyles }: LoadingSpinnerProps) => (
  <div className={classNames('loading-container', customStyles?.root)}>
    <div
      className={classNames(`loading-spinner--${type}`, customStyles?.spinner)}
    >
      Loading...
    </div>
  </div>
);

LoadingSpinner.defaultProps = {
  type: 'circles',
};

export default LoadingSpinner;
