import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  Request,
  useRequest,
  useRequestEffect,
  useAuthContext,
} from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';
import { FieldValues, UseFormReset } from 'react-hook-form';

import { getDateTimeFromParam } from 'hooks/useQueryState';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { Tenant } from 'types/tenant';
import {
  SettlementSingleResponseRaw,
  SettlementSingleResponse,
} from 'types/settlement';

import { env } from 'helpers/env';
import fileExportSave from 'helpers/downloadFile';

import IconButton from 'components/IconButton';
import Button from 'components/Button';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import SVG from 'components/SVG';
import { SettlementSingleTable } from './components/SettlementSingleTable';
import Dialog from 'components/Dialog';
import DialogHeader from 'components/Dialog/DialogHeader';
import DialogBody from 'components/Dialog/DialogBody';

import BaselineImportForm from 'routes/Settlement/SettlementSingle/components/BaselineImportForm';
import MeasurementUploadForm from './components/MeasurementUploadForm';

import { processRawSettlementSinge } from 'routes/Settlement/helpers/dataProcessors';
import { handleBaselineImport } from 'routes/Settlement/helpers/sharedAPICalls';
import SettlementSummaryFilters from 'routes/Settlement/components/SettlementSummaryFilters';
import SettlementHeader from 'routes/Settlement/components/SettlementHeader';

import './SettlementSingle.scss';

const SettlementSingle: React.FC = () => {
  const DATE_PARAM_NAME = 'start_date';
  const { programID, tenantID } = useParams<{
    programID: string;
    tenantID: string;
  }>();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { selectedProgram: program, selectedFeeder } = useProgramsContext();
  const { hasAllPermissions } = useAuthContext();
  const { userIsDso } = useUserContext();
  const { addToast } = useToasts();

  const [showModal, setShowModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contractIds, setContractIds] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<DateTime>(
    getDateTimeFromParam(
      params,
      DATE_PARAM_NAME,
      program
        ? DateTime.local()
            .setZone(program.timezone)
            .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
        : DateTime.local().set({
            day: 1,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
    )
  );

  const toggleModal = () => setShowModal(!showModal);
  const toggleImportModal = () => setImportModal(!importModal);

  async function importBaseline(data: any, reset: UseFormReset<FieldValues>) {
    handleBaselineImport(
      data,
      reset,
      programID,
      addToast,
      setImportModal,
      setLoading
    );
  }
  const { makeRequest: exportSettlementTenant } = useRequest(
    `/api/dsp/program/${programID}/feeder/${selectedFeeder?.id}/flex/settlement/${tenantID}/export`
  );

  const exportSettlementSummary = () => {
    const start = startDate.toJSDate().toISOString();
    const end = startDate
      .plus({
        months: 1,
      })
      .toJSDate()
      .toISOString();
    exportSettlementTenant({
      method: 'get',
      params: {
        start_time: start,
        end_time: end,
      },
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(data, headers, `settlement-summary-${start}-${end}.csv`);
      },
      responseType: 'blob',
      toast: {
        error: 'Something went wrong during the export!',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { data: settlements, refetch: refetchSettlements } = useRequestEffect<
    SettlementSingleResponse[]
  >({
    urlGen: () =>
      `/api/dsp/program/${programID}/feeder/${selectedFeeder?.id}/flex/settlement/${tenantID}`,
    params: {
      start_time: startDate.toJSDate().toISOString(),
      end_time: startDate
        .plus({
          months: 1,
        })
        .set({
          day: 1,
        })
        .toJSDate()
        .toISOString(),
    },
    initialData: [],
    method: 'get',
    refetchOnChange: [programID, selectedFeeder, startDate, tenantID],
    toast: {
      error: 'Failed to load settlement data.',
      settings: {
        autoDismiss: true,
      },
    },
    blockRequest: () => !programID || !selectedFeeder?.id || !tenantID,
    dataTransform: (data: SettlementSingleResponseRaw) => {
      const settlements: SettlementSingleResponse[] = [];
      const contractIdSet = new Set<string>();
      for (const dateStr in data) {
        if (Object.keys(data[dateStr]).length > 0) {
          Object.keys(data[dateStr]).forEach(contractIdSet.add, contractIdSet);
          settlements.push(processRawSettlementSinge(data[dateStr], dateStr));
        }
      }
      setContractIds(Array.from(contractIdSet));
      return settlements;
    },
  });

  const { data: tenant } = useRequestEffect<Tenant>({
    url: `/api/dsp/tenants/${tenantID}`,
    method: 'get',
    refetchOnChange: [],
    toast: {
      error: 'Failed to load tenant.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const handleMeasurementUpload = async (
    data: { [key: string]: FileList },
    reset: UseFormReset<FieldValues>
  ) => {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key) => {
      [...data[key]].forEach((file) => {
        formData.append(`${key}`, file);
      });
    });
    const url = `/api/dsp/program/${programID}/flex/measurements/upload/${keys.join(
      ','
    )}`;
    const request = new Request(url);
    setLoading(true);
    try {
      await request.post(formData);
      refetchSettlements();
      addToast('Upload successful!', { appearance: 'success' });
      setShowModal(false);
      reset();
    } catch (err: any) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
      }
      let message =
        'The server encountered an internal error and was unable to complete your request. Either the server is overloaded or there is an error in the application';
      if (err?.response?.status === 400) {
        message =
          'The uploaded csv does not match the measurements template or has empty rows.';
      }
      addToast(message, {
        appearance: 'error',
      });
    }
    setLoading(false);
  };

  const { makeRequest: triggerSettlement } = useRequest(
    `/api/dsp/flex/settlement/trigger`
  );

  const triggerManualSettlement = async () => {
    const contractDate = startDate.set({ day: 15 });
    const body = {
      start_time: contractDate.toJSDate().toISOString(),
      contract_ids: contractIds,
    };
    triggerSettlement({
      method: 'post',
      body,
      toast: {
        success: `Settlement triggered for ${contractDate.monthLong}`,
        error: `Something went wrong when triggering ${contractDate.monthLong} settlements`,
      },
    });
  };

  const isDSO = userIsDso();
  const canUploadMeasurementData =
    env.isNMF && !isDSO && settlements && settlements.length > 0;
  const canRunSettlement = isDSO && contractIds.length > 0;

  return (
    <div className="settlement-single">
      <SettlementHeader
        selectedRowCount={+isDSO}
        selectedTenants={[tenantID]}
        startDate={startDate}
        refetchSummaries={refetchSettlements}
        toggleImportModal={toggleImportModal}
        backButtonTitle={
          isDSO && hasAllPermissions(['view_settlements_summary'])
            ? 'Back to all participants'
            : undefined
        }
        summaryPage={false}
      >
        {canUploadMeasurementData && (
          <Button onClick={toggleModal}>Upload Measurement Data</Button>
        )}
      </SettlementHeader>
      <div className="settlement-single__title">{tenant?.name}</div>
      <div className="settlement-single__subnav">
        <div>
          {canRunSettlement && (
            <Button onClick={triggerManualSettlement} variant="text">
              <SVG
                icon="RefreshAltIcon"
                className="settlement-single__subnav-icon"
              />
              Run manual settlement
            </Button>
          )}
        </div>
      </div>
      <div className="settlement-single__actions">
        <SettlementSummaryFilters
          startDate={startDate}
          setStartDate={setStartDate}
        />
        <IconButton
          icon="DownloadIcon"
          onClick={exportSettlementSummary}
          fontSize="12px"
          tooltip="Export Settlement Summary"
        />
      </div>
      <SettlementSingleTable data={settlements || []} />

      <Dialog
        open={showModal}
        type={'primary'}
        progress={100}
        onClose={toggleModal}
      >
        <DialogHeader title="Upload Measurement Data" onClose={toggleModal} />
        <DialogBody>
          {tenant && (
            <MeasurementUploadForm
              ders={tenant.ders}
              loading={loading}
              onSubmit={handleMeasurementUpload}
              onCancel={toggleModal}
            />
          )}
        </DialogBody>
      </Dialog>

      {hasAllPermissions(['view_settlements_summary']) && (
        <Modal
          active={importModal}
          cancelProps={{ variant: 'outlined' }}
          hideFooter={true}
          title={`Import baseline data / ${startDate.toFormat('LLLL y')}`}
          onClose={toggleImportModal}
        >
          <BaselineImportForm
            selectedTenants={[tenantID]}
            startDate={startDate}
            loading={loading}
            onSubmit={importBaseline}
            onCancel={toggleImportModal}
          />
        </Modal>
      )}
    </div>
  );
};

export default SettlementSingle;
