import React, { useEffect, useState, useMemo } from 'react';
import useLocaleFormatter from 'hooks/useLocaleFormatter';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { SelectOption } from 'types';
import { flexType, flexTypeRaw, serviceType } from 'types/contract-managment';
import { DER, AssetControlStrategy } from 'types/der';

import { getCurrencySymbol } from 'helpers/locale';
import { getRemainingTime } from 'helpers/time';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
// eslint-disable-next-line custom-rules/deprecated-component
import Divider from 'components/Divider';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import SVG from 'components/SVG';

import ModalSection from 'routes/LongTermContracts/components/ModalSection';
import FlexResponseForm from './FlexResponseForm';
import {
  Props,
  renderServicePeriod,
  renderServiceType,
  renderTimeRange,
  renderZone,
} from './NewFlexResponse.logic';

import './NewFlexResponse.scss';

function NewFlexResponse({
  show = false,
  updateParent = (value: boolean) => {},
  programID,
  request,
  setSubmittingResponses,
}: Props) {
  const {
    id,
    derId,
    availability,
    expiryDate,
    flex,
    feederId,
    primary,
    service,
    startTime,
    endTime,
    peak,
    customPeakDays,
    serviceWindows,
    utilization,
    quantity,
  } = request;
  const [zoneList, setZoneList] = useState<SelectOption[]>([]);

  const { selectedProgram: program, feeders } = useProgramsContext();
  const { getUserDerFeederId, getUserTenantId } = useUserContext();

  const { data } = useRequestEffect<DER>({
    url: `/api/dsp/der/rdf/${derId}`,
    method: 'get',
  });
  const isMec = service === serviceType.MEC;
  const isMic = service === serviceType.MIC;
  const negQuantity = service === serviceType.SustainExportManagement;

  const [isModalActive, toggleModal] = useState(false);

  const { makeRequest: submitRequestResponse } = useRequest(
    `/api/dsp/program/${programID}/flex/request/${id}/response`
  );

  const { currencyFormatter } = useLocaleFormatter(
    program?.currency,
    program?.locale
  );

  const currencySymbol = getCurrencySymbol(currencyFormatter);

  const timeDiff = getRemainingTime(expiryDate, {
    days: ' days',
    hours: ' hours',
    minutes: ' minutes',
    seconds: ' seconds',
    expired: '',
  });

  const formattedServiceWindows = useMemo(
    () =>
      serviceWindows.map((item) => ({
        start_time: item.startTime,
        end_time: item.endTime,
      })),
    [serviceWindows]
  );

  const { data: responseAssets } = useRequestEffect({
    url: `/api/dsp/program/${programID}/available_der/${id}`,
    method: 'get',
    toast: {
      error: 'Failed to load available ders.',
      settings: {
        autoDismiss: true,
      },
    },
    body: {
      service_windows: formattedServiceWindows,
    },
    dataTransform: (data: any) => {
      const zones = new Set();
      const res = data
        .filter(
          (der: DER) =>
            (isMec || isMic || der.info.feeder.id === request.feederId) &&
            request.derRDFID !== der.rdf_id
        )
        .map((der: DER) => {
          //create unique Set of available zones
          zones.add(
            JSON.stringify({
              value: der.info.feeder.id,
              label: der.info.feeder.name,
            })
          );
          return {
            label: String(der.info.name),
            value: String(der.rdf_id),
            feederId: der.info.feeder.id,
            curtailable:
              der.control_strategy === AssetControlStrategy.Curtailable,
            connectionImportCapacity: der.connection_agreement_import_capacity,
            connectionExportCapacity: der.connection_agreement_export_capacity,
            tcvnExportCapacity: der.tcvn_export_capacity,
            tcvnImportCapacity: der.tcvn_import_capacity,
            remainingCapacity: der.remaining_capacity,
          };
        });
      // Convert Set to array and parse string to object
      setZoneList(Array.from(zones).map((i) => JSON.parse(i as string)));

      return res;
    },
  });

  const handleFlexResponseSubmit = async (data: { [x: string]: any }) => {
    const responderId = getUserTenantId();
    const feederID =
      isMec || isMic ? getUserDerFeederId(data.asset.value) : feederId;
    const derRdfId = data.asset.value;
    const quantity = negQuantity ? data.quantity * -1000 : data.quantity * 1000; // kW to MW
    const availabilityPrice = data.availabilityPrice
      ? data.availabilityPrice / 1000 // $/kW/h to $/W/h
      : 0;
    const utilizationPrice = data.utilizationPrice
      ? data.utilizationPrice / 1000 // $/kW/h to $/W/h
      : 0;
    const resFlexType =
      flex === flexType.BID ? flexTypeRaw.OFFER : flexTypeRaw.BID;
    const maxEnergyDaily =
      data?.asset?.curtailable && data.maxEnergyDaily
        ? data.maxEnergyDaily * 1000
        : null;
    return submitRequestResponse({
      method: 'post',
      body: {
        der_rdf_id: derRdfId,
        responder_id: responderId,
        flex_type: resFlexType,
        feeder_id: feederID,
        quantity: quantity,
        availability_price: availabilityPrice,
        utilization_price: utilizationPrice,
        max_energy_daily: maxEnergyDaily,
      },
      toast: {
        success: 'Response created successfully.',
        error: (e) => {
          const responseErrorMessage = e.response?.data.message;
          return `Error creating response. ${responseErrorMessage}`;
        },
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        setSubmittingResponses(true);
        updateParent(false);
      },
    });
  };

  useEffect(() => {
    toggleModal(show);
  }, [show]);

  const selectedDer = responseAssets?.find((i: any) => i.value === derId);

  return (
    <Modal
      title={
        <>
          Create response
          <span className="new-flex-response__time">
            {timeDiff
              ? `Request closing in ${timeDiff}`
              : 'Request has expired'}
          </span>
        </>
      }
      active={isModalActive}
      cancelProps={{ variant: 'outlined' }}
      classes={{
        headerClass: 'new-flex-response__modal-header',
        contentClass: 'new-flex-response__modal-content',
        bodyClass: 'new-flex-response__modal-body',
        footerClass: 'new-flex-response__modal-footer',
      }}
      hideFooter={true}
      onClose={() => updateParent(false)}
    >
      <div className="new-flex-response">
        <ModalSection
          title="Request details"
          icon={
            <SVG icon="ToolIcon" className="new-flex-response__panel-icon" />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Zone</div>
              <div className="new-flex-response__value">
                {renderZone(feederId, feeders)}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Primary</div>
              <div className="new-flex-response__value">{primary?.name}</div>
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Industry actor</div>
              <div className="new-flex-response__value">
                {request.requestorName}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Service type</div>
              <div className="new-flex-response__value">
                {renderServiceType(service)}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Request type</div>
              <div className="new-flex-response__value">{flex}</div>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Service period</div>
              <div className="new-flex-response__value">
                {renderServicePeriod(startTime, endTime)}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Service window</div>
              <div className="new-flex-response__value">{peak}</div>
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Service days</div>
              <div className="new-flex-response__value">
                {renderTimeRange(peak, customPeakDays, serviceWindows)}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">
                Request quantity (kW)
              </div>
              <div className="new-flex-response__value">{quantity / 1000}</div>
            </Grid>
            <Grid item xs={4}>
              <div className="new-flex-response__label">Availability price</div>
              <div className="new-flex-response__value">
                {currencyFormatter.format(availability * 1000 || 0)}/kW/h
              </div>
            </Grid>
            {isMec || isMic ? (
              <Grid item xs={4}>
                <div className="new-flex-response__label">
                  Distributed energy resource
                </div>
                {data && data.info && (
                  <div className="new-flex-response__value">
                    {data.info.name}
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={4}>
                <div className="new-flex-response__label">
                  Utilization price ceiling
                </div>

                <div className="new-flex-response__value">
                  {currencyFormatter.format(utilization * 1000 || 0)}/kWh
                </div>
              </Grid>
            )}
          </Grid>
        </ModalSection>
        <ModalSection
          title="Your response"
          icon={
            <SVG icon="Edit3Icon" className="new-flex-response__panel-icon" />
          }
          panelClass="new-look"
        >
          <FlexResponseForm
            zoneList={zoneList}
            request={request}
            currencySymbol={currencySymbol}
            responseAssets={responseAssets}
            onSubmit={handleFlexResponseSubmit}
            onCancel={() => {
              updateParent(false);
              toggleModal(false);
            }}
            selectedDer={selectedDer}
          />
        </ModalSection>
      </div>
    </Modal>
  );
}

export default NewFlexResponse;
