import { FieldValues, UseFormReset } from 'react-hook-form';
import { AddToast } from 'react-toast-notifications';
import { Request } from '@opusonesolutions/gridos-app-framework';

export const handleBaselineImport = async (
  data: { [key: string]: FileList },
  reset: UseFormReset<FieldValues>,
  programID: string,
  addToast: AddToast,
  setImportModal: (value: boolean) => void,
  setLoading: (value: boolean) => void
) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  keys.forEach((key) => {
    [...data[key]].forEach((file) => {
      formData.append(`${key}`, file);
    });
  });

  const url = `/api/dsp/program/${programID}/flex/baselines/upload/${keys.join(
    ','
  )}`;
  const request = new Request(url);
  setLoading(true);
  try {
    await request.post(formData);
    addToast('Upload successful!', { appearance: 'success' });
    setImportModal(false);
    reset();
  } catch (err: any) {
    let message = 'Something went wrong during the upload!';
    if (err?.response?.data?.message) {
      message = err.response.data.message;
    }
    if (err?.response?.status === 400) {
      message =
        'The uploaded csv does not match the baseline template or has empty rows.';
    }
    addToast(message, {
      appearance: 'error',
    });
  }
  setLoading(false);
};
