import React from 'react';
import {
  default as MUIAccordionDetails,
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';

export const AccordionDetails = ({
  children,
  className,
}: AccordionDetailsProps) => {
  return (
    <MUIAccordionDetails className={className}>{children}</MUIAccordionDetails>
  );
};
