import React from 'react';
import classes from 'classnames';

import IconButton from 'components/IconButton';

import './DialogHeader.scss';

type DialogProps = {
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  classNames?: {
    headerClass?: string;
    titleClass?: string;
    closeClass?: string;
    iconClass?: string;
  };
  styles?: {
    headerStyles?: React.CSSProperties;
    titleStyles?: React.CSSProperties;
  };
};

function DialogHeader({
  title,
  onClose,
  children,
  classNames = {},
  styles = {},
}: DialogProps) {
  const { headerClass, titleClass, closeClass, iconClass } = classNames;
  const { headerStyles, titleStyles } = styles;

  return (
    <div className={classes('dialog-header', headerClass)} style={headerStyles}>
      {title && (
        <h1
          className={classes('dialog-header__title', titleClass)}
          style={titleStyles}
        >
          {title}
        </h1>
      )}
      {children}
      {onClose && (
        <IconButton
          icon="XIcon"
          onClick={onClose}
          customClasses={{
            customButtonClass: classes('dialog-header__close', closeClass),
          }}
          iconClass={classes(iconClass)}
        />
      )}
    </div>
  );
}

export default DialogHeader;
