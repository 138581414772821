import React from 'react';
import { default as MUIMenuList } from '@mui/material/MenuList';

import { MenuListProps } from './MenuList.types';

const MenuList = ({
  children,
  autoFocus,
  autoFocusItem,
  disabledItemsFocusable,
  disableListWrap,
}: MenuListProps) => {
  return (
    <MUIMenuList
      autoFocus={autoFocus}
      autoFocusItem={autoFocusItem}
      disabledItemsFocusable={disabledItemsFocusable}
      disableListWrap={disableListWrap}
    >
      {children}
    </MUIMenuList>
  );
};

export default MenuList;
