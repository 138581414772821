import React from 'react';

import PageTitle from 'components/PageTitle';

import './ParticipantsDersHeader.scss';

export const ParticipantsDersHeader: React.FC = ({ children }) => {
  return (
    <div className="participants-ders-header__header">
      <PageTitle title="Participants and DERs">{children}</PageTitle>
    </div>
  );
};
