import React, { Dispatch, SetStateAction, MouseEvent, useState } from 'react';

import { useUserContext } from 'contexts/UserContext';

import IconButton from 'components/IconButton';
import Menu from 'components/Menu';
import MenuItem from 'components/Menu/MenuItem';

import {
  useDataDownload,
  UploadTypeEnum,
  UploadType,
} from 'routes/Program/routes/Enrollment/api';

type UploadDownloadType = {
  setShowUploadPrimariesModal: Dispatch<SetStateAction<boolean>>;
  setShowUploadModal: Dispatch<SetStateAction<boolean>>;
  setUploadType: Dispatch<SetStateAction<UploadType | undefined>>;
};

export const UploadDownloadButtons = ({
  setShowUploadPrimariesModal,
  setShowUploadModal,
  setUploadType,
}: UploadDownloadType) => {
  const { userIsDso } = useUserContext();
  const isDso = userIsDso();

  const { handlePrimariesDownload } = useDataDownload();

  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const openActionMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const closeActionMenu = () => {
    setActionsAnchorEl(null);
  };

  const onMenuClick = (type: UploadTypeEnum) => {
    closeActionMenu();
    if (type === UploadTypeEnum.ScalarData) {
      setUploadType(UploadTypeEnum.ScalarData);
      return setShowUploadModal(true);
    } else if (type === UploadTypeEnum.Primaries) {
      setUploadType(UploadTypeEnum.Primaries);
      return setShowUploadPrimariesModal(true);
    }
  };

  return (
    <>
      <IconButton icon="UploadIcon" onClick={(e) => openActionMenu(e)} />
      <IconButton
        icon="DownloadIcon"
        disabled={!isDso}
        tooltip="Download primaries model"
        tooltipTheme="dark"
        tooltipPlacement="bottom"
        onClick={handlePrimariesDownload}
      />
      <Menu
        id="contracts-actions-menu"
        anchorEl={actionsAnchorEl}
        open={!!actionsAnchorEl}
        onClose={closeActionMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => onMenuClick(UploadTypeEnum.ScalarData)}
          disabled={false}
        >
          Upload scalar data set
        </MenuItem>
        <MenuItem
          onClick={() => onMenuClick(UploadTypeEnum.Primaries)}
          disabled={false}
        >
          Upload primaries model
        </MenuItem>
      </Menu>
    </>
  );
};
