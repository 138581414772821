import React from 'react';

import './FadeLoader.scss';

export type FadeLoaderProps = {
  color?: string;
  customClasses?: {
    loaderClass?: string;
    spokeClass?: string;
  };
  customStyles?: {
    loaderStyles?: React.CSSProperties;
    spokeStyles?: React.CSSProperties;
  };
  id?: string;
  // placeholder for future extendability
  size?: 'medium';
};

export const FadeLoader: React.FC<FadeLoaderProps> = ({
  color,
  customStyles = {},
  customClasses = {},
  id,
}) => {
  const { loaderClass, spokeClass } = customClasses;
  const { loaderStyles, spokeStyles } = customStyles;
  /**
   * Possible future extensions
   *  1. size presets (small and large)
   *  2. allow devs to set number of spokes -> will need to calculate spoke position and animation delay and trasnsform origin
   *  3. shortcut for animation properties? -> delay
   *  4. custom classes for individual spokes
   */
  return (
    <span id={id} className={`fade-loader ${loaderClass}`} style={loaderStyles}>
      <span
        className={`fade-loader__spoke fade-loader__1 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
      <span
        className={`fade-loader__spoke fade-loader__2 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
      <span
        className={`fade-loader__spoke fade-loader__3 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
      <span
        className={`fade-loader__spoke fade-loader__4 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
      <span
        className={`fade-loader__spoke fade-loader__5 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
      <span
        className={`fade-loader__spoke fade-loader__6 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
      <span
        className={`fade-loader__spoke fade-loader__7 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
      <span
        className={`fade-loader__spoke fade-loader__8 ${spokeClass}`}
        style={{ ...spokeStyles, color: `${color}` }}
      />
    </span>
  );
};
