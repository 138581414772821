import React, { useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';

import { useProgramsContext } from 'contexts/ProgramsContext';

import Menu from 'components/Menu/Menu';
import MenuItem from 'components/Menu/MenuItem';
import Button from 'components/Button';
import SVG from 'components/SVG';

import { PeriodsTableParams } from '../TimePeriodsTable/TimePeriodsTable';

import './ReportsTableFilter.scss';

const generateTimePeriods = (timezone: string | undefined) => {
  return [
    {
      label: 'Today',
      value: {
        startDate: DateTime.now().setZone(timezone).startOf('day'),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
    {
      label: 'Yesterday',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ days: 1 }),
        endDate: DateTime.now()
          .setZone(timezone)
          .endOf('day')
          .minus({ days: 1 }),
      },
    },
    {
      label: 'Last 7 days',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ days: 7 }),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
    {
      label: 'Last 30 days',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ days: 30 }),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
    {
      label: 'Last 6 months',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ months: 6 }),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
  ];
};

export const ReportsTableFilter = ({
  downloadLogs,
  setFilteredResults,
  downloading,
}: Pick<
  PeriodsTableParams,
  'downloadLogs' | 'setFilteredResults' | 'downloading'
>) => {
  const { selectedProgram } = useProgramsContext();
  const timezone = selectedProgram?.timezone;
  const [dateBack, setDateBack] = useState(31);
  const [selected, setSelected] = useState(3);
  const [analysisStartDate, setAnalysisStartDate] = useState(
    DateTime.now().setZone(timezone).startOf('day').minus({ days: 30 })
  );
  const [analysisEndDate, setAnalysisEndDate] = useState(
    DateTime.local().setZone(timezone).endOf('day')
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const timePeriods = useMemo(() => {
    return generateTimePeriods(timezone);
  }, [timezone]);

  const setTimePeriod = (
    value: { startDate: DateTime; endDate: DateTime },
    index: number
  ) => {
    const { startDate, endDate } = value;
    const diff = endDate.diff(startDate, ['days']);
    const start = Math.ceil(diff.get('days'));
    index === 1 ? setDateBack(2) : setDateBack(start);
    setSelected(index);
    setAnalysisStartDate(startDate);
    setAnalysisEndDate(endDate);
    handleClose();
  };

  useEffect(() => {
    const date = DateTime.local();
    const filteredResult = Array(dateBack)
      .fill(dateBack)
      .map((_, i) => {
        return {
          timePeriod: date.plus({ days: -i }).toFormat('LLLL d yyyy'),
          date: date.plus({ days: -i }),
        };
      });
    if (dateBack === 2) filteredResult.shift();
    setFilteredResults(filteredResult);
  }, [dateBack, setFilteredResults]);

  return (
    <div className="filter">
      <div className="filter__header">
        <Button
          variant="text"
          onClick={handleClick}
          customClasses={{
            customButtonClass: 'filter__action filter__action-hovered',
          }}
          endIcon={<SVG icon="ChevronDownIcon" fontSize="16" />}
        >
          <div className="filter__action-text filter__action-text-menu">
            {timePeriods[selected].label}
          </div>
        </Button>
        <Button
          variant="text"
          onClick={() => downloadLogs(analysisStartDate, analysisEndDate)}
          disabled={downloading || analysisStartDate > analysisEndDate}
          customClasses={{
            customButtonClass: 'filter__action',
          }}
        >
          <div className="filter__action-text filter__action-text-button">
            Download reports
          </div>
        </Button>
      </div>
      <Menu
        id="audit-logs-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        {timePeriods.map(({ value, label }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setTimePeriod(value, index);
            }}
            classes={{
              rootClass: cn('filter__menu-text', {
                'filter__menu-text-active': index === selected,
              }),
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
