import React, { useEffect, useState } from 'react';
import Dialog from 'components/Dialog';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import FormLabel from 'components/FormLabel';
import TextField from 'components/TextField';
import Button from 'components/Button';

import { NotificationItemProp } from '../../EmailNotifications.types';
import './MessageForm.scss';

interface MessageFormProps {
  active: boolean;
  onClose: () => void;
  onSave: (data: NotificationItemProp, subject: string, body: string) => void;
  content?: Record<string, any>;
}

const MessageForm = ({
  content,
  active,
  onClose,
  onSave,
}: MessageFormProps) => {
  const [formState, setFormState] = useState({
    subject: '',
    body: '',
  });

  useEffect(() => {
    setFormState(() => ({
      subject: content?.subject || '',
      body: content?.body || '',
    }));
  }, [content]);

  const fieldChangeHandler = (type: 'subject' | 'body', e: string) => {
    setFormState((prev) => ({
      ...prev,
      [type]: e,
    }));
  };

  return (
    <Dialog
      disableProgress
      open={active}
      onClose={onClose}
      classNames={{
        contentClass: 'message-form',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="message-form__title">Write message</div>
        </Grid>
        <Grid item xs={12}>
          <FormLabel htmlFor="subject">Subject</FormLabel>
          <TextField
            id="subject"
            name="subject"
            value={formState.subject}
            placeholder="Add a subject line"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => fieldChangeHandler('subject', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel htmlFor="message">Message</FormLabel>
          <TextField
            multiline
            id="message"
            name="message"
            placeholder="Add a message"
            value={formState.body}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => fieldChangeHandler('body', e.target.value)}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-end"
          spacing={2}
          className="message-form__buttons"
        >
          <Grid item>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                onSave(
                  content as NotificationItemProp,
                  formState.subject,
                  formState.body
                );
                onClose();
              }}
            >
              Save changes
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default MessageForm;
