import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
  useState,
  ChangeEvent,
} from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { Container, useContainers } from 'hooks/useContainers';
import { DER, useDERs } from 'hooks/useDERs';

import { useProgramsContext, Program } from 'contexts/ProgramsContext';

import { FeederEnrollment } from 'types/feeder';

import fileExportSave from 'helpers/downloadFile';

export enum UploadTypeEnum {
  Primaries = 'PRIMARIES',
  ScalarData = 'SCALAR_DATA',
}
export type UploadType = UploadTypeEnum.Primaries | UploadTypeEnum.ScalarData;

export type DERType =
  | 'BESS'
  | 'GenSet'
  | 'Hydro'
  | 'Load'
  | 'PhotoVoltaic'
  | 'Wind';

export type AssetEnrollment = {
  asset_id: string;
  control_strategy: 'SCHEDULED' | 'CURTAILABLE';
  feeder_id: string;
  info: {
    apparent_power: number;
    class: string;
    der_phases: string;
    der_rdf_id: string;
    der_type: DERType;
    feeder: {
      id: string;
      name: string;
    };
    gen_capacity: number;
    location: [string, string];
    name: string;
    phases: number;
    power_factor: number;
    rated_voltage: number;
    reactive_power: number;
    real_power: number;
    usage_point_type: string | null;
  };
  is_aggregated: boolean;
  market_der_id: number;
  program_id: number;
  ramp_rate: number | null;
  max_p_curtailed: number | null;
  percent_curtailed: number | null;
  max_energy_curtailment_per_day: number | null;
  workspace_name: string;
  activated?: boolean;
  automatic_pv_forecast_enabled?: boolean;
  email?: {
    email: string;
    enabled: boolean;
  };
  operating_mode?: 'UNCONSTRAINED' | 'BID_OFFER';
};

function useContainerEnrollment(program: Program | null): {
  containers: { [id: string]: Container };
  loading: boolean;
  toggleContainerEnrollment: (
    event: ChangeEvent<HTMLInputElement>,
    containerId: string
  ) => void;
} {
  const { setRefetchFeeders } = useProgramsContext();
  const { makeRequest: addFeeder, loading: addFeederLoading } = useRequest(
    `/api/dsp/program/${program?.program_id}/enroll_feeder`
  );
  const { makeRequest: removeFeeder, loading: removeFeederLoading } =
    useRequest(`/api/dsp/program/${program?.program_id}/remove_feeder`);

  const { containers, containersLoading, refetchContainers } = useContainers(
    program?.program_id
  );

  const addFeederToProgram = ({ id, name }: { id: string; name: string }) =>
    addFeeder({
      method: 'post',
      body: { feeder_id: id },
      onSuccess: refetchContainers,
      toast: {
        success: `${name} was successfully enrolled in ${program?.name}`,
        error: (error: AxiosError) => {
          if (error.message.includes('enrolled in one program')) {
            return `${name} is already enrolled in a program.`;
          }
          return `There was an unexpected error trying to enroll ${name}.`;
        },
      },
    });

  const removeFeederFromProgram = ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }) =>
    removeFeeder({
      method: 'post',
      body: { feeder_id: id },
      onSuccess: refetchContainers,
      toast: {
        success: `${name} was successfully unenrolled from ${program?.name}`,
        error: (error: AxiosError<Error>) => {
          const messages: { [key in string | number]: string } = {
            404: `Program ${program?.name} does not exist.`,
            default: `There was an unexpected error trying to unenroll ${name}.`,
          };
          return messages[error.response?.status || 'default'];
        },
      },
    });

  const toggleContainerEnrollment = (
    event: ChangeEvent<HTMLInputElement>,
    containerId: string
  ) => {
    if (containers) {
      if (!event.currentTarget.checked) {
        removeFeederFromProgram(containers[containerId]);
        setRefetchFeeders(true);
      } else {
        addFeederToProgram(containers[containerId]);
        setRefetchFeeders(true);
      }
    }
  };

  return {
    containers: containers || {},
    loading: containersLoading || addFeederLoading || removeFeederLoading,
    toggleContainerEnrollment,
  };
}

export function useDEREnrolment(programID?: string): {
  DERs: { [id: string]: DER };
  DERsLoading: boolean;
  toggleDEREnrollment: (
    event: ChangeEvent<HTMLInputElement>,
    containerId: string,
    derID: string
  ) => void;
  updating: boolean;
  refetchDERs: () => void;
} {
  const { DERs, DERsLoading, updateDEREnrollmentState, refetchDERs } =
    useDERs(programID);

  const { makeRequest: addDer, loading: addDERLoading } = useRequest(
    `/api/dsp/program/${programID}/enroll_asset`
  );
  const { makeRequest: removeDer, loading: removeDERLoading } = useRequest(
    `/api/dsp/program/${programID}/remove_asset`
  );

  const addDERToProgram = async (feederID: string, derID: string) => {
    return addDer({
      method: 'post',
      body: {
        feeder_id: feederID,
        asset_id: derID,
      },
      onSuccess: () => updateDEREnrollmentState(derID, true),
      toast: {
        success: 'Successfully added DER to program.',
        error: (error: AxiosError<Error>) => {
          const messages: { [key in string | number]: string } = {
            404: 'Program does not exist.',
            500: 'The asset may be enrolled',
            default: 'Could not add DER to program.',
          };
          return messages[error.response?.status || 'default'];
        },
      },
    });
  };

  const removeDERFromProgram = async (derID: string) => {
    return removeDer({
      method: 'post',
      body: {
        asset_id: derID,
      },
      onSuccess: () => updateDEREnrollmentState(derID, false),
      toast: {
        success: `Successfully removed DER from the program.`,
        error: 'Could not remove DER from the program.',
      },
    });
  };

  const toggleDEREnrollment = (
    event: ChangeEvent<HTMLInputElement>,
    containerId: string,
    derID: string
  ) => {
    !event.currentTarget.checked
      ? removeDERFromProgram(derID)
      : addDERToProgram(containerId, derID);
  };

  return {
    DERs: DERs || {},
    DERsLoading,
    toggleDEREnrollment,
    updating: addDERLoading || removeDERLoading,
    refetchDERs,
  };
}

export function useEnrollmentAPI() {
  const { programID } = useParams<{ programID: string }>();

  const { selectedProgram: program } = useProgramsContext();

  const {
    containers = {},
    loading: containersLoading,
    toggleContainerEnrollment,
  } = useContainerEnrollment(program || null);

  const {
    toggleDEREnrollment,
    updating,
    DERsLoading,
    DERs = {},
    refetchDERs,
  } = useDEREnrolment(programID);

  const containerTree: {
    [id: string]: Container;
  } = useMemo(() => {
    const sorter = Intl.Collator(undefined, {
      numeric: true, // make sure 10-foo comes after 2-bar
      sensitivity: 'base', // ignore case
    });

    // Remove substations from the UI
    const sortedContainers: Container[] = Object.values(containers)
      .sort((a, b) => sorter.compare(a.name, b.name))
      .filter((c) => c.type !== 'substation');
    const sortedDERs: DER[] = Object.values(DERs).sort((a: DER, b: DER) =>
      sorter.compare(a.name, b.name)
    );

    return sortedContainers.reduce((tree, container) => {
      // Gather associated DERs
      const children = sortedDERs.filter(
        (DER: DER) => DER.feeder?.id === container.id
      );
      if (children.length > 0) {
        return {
          ...tree,
          [container.id]: {
            ...container,
            children,
          },
        };
      }

      return tree;
    }, {});
  }, [containers, DERs]);

  const exportedContainers = useMemo(
    () => Object.values(containerTree),
    [containerTree]
  );

  return {
    programID,
    programName: program?.name,
    containers: exportedContainers,
    loading: containersLoading || DERsLoading,
    updating,
    toggleContainerEnrollment,
    toggleDEREnrollment,
    refetchDERs,
  };
}

export function useAssetEnrollmentInfo(
  programID?: string,
  der_rdf_id?: string
) {
  const {
    data: enrollment,
    loading: enrollmentLoading,
    error,
    refetch,
  } = useRequestEffect<AssetEnrollment>({
    url: `/api/dsp/program/${programID}/der/${der_rdf_id}`,
    method: 'get',
    refetchOnChange: [programID, der_rdf_id],
    blockRequest: () => programID === undefined || der_rdf_id === undefined,
    toast: {
      error: 'There was an unexpected error loading your Asset.',
    },
  });

  return {
    enrollment,
    enrollmentLoading,
    error,
    refetch,
  };
}

export function useFeederEnrollmentInfo(programID?: string, feederID?: string) {
  const {
    data: enrollment,
    loading: enrollmentLoading,
    error,
    refetch,
  } = useRequestEffect<FeederEnrollment>({
    url: `/api/dsp/program/${programID}/feeder_enrollment/${feederID}`,
    method: 'get',
    refetchOnChange: [programID, feederID],
    blockRequest: () => programID === undefined || feederID === undefined,
    toast: {
      error: 'There was an unexpected error loading your Feeder.',
    },
  });

  return {
    enrollment,
    enrollmentLoading,
    error,
    refetch,
  };
}

export const useDataDownload = () => {
  const { selectedProgram: program } = useProgramsContext();
  const programName = program?.name;

  const { makeRequest: runPrimariesDownload } = useRequest(
    '/api/dsp/primary/export'
  );

  const handlePrimariesDownload = async () => {
    await runPrimariesDownload({
      method: 'get',
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(data, headers, `${programName}_primaries.csv`);
      },
      onError: undefined,
      toast: {
        error: 'Could not download primaries model.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000,
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  return { handlePrimariesDownload };
};

export const useDataUpload = (
  uploadType: string | undefined,
  setShowUploadScalarModal: Dispatch<SetStateAction<boolean>>,
  setShowUploadPrimariesModal: Dispatch<SetStateAction<boolean>>,
  setFilename: Dispatch<SetStateAction<string>>,
  setHasData: Dispatch<SetStateAction<boolean>>,
  uploadFormRef: MutableRefObject<HTMLFormElement | null>
) => {
  const { programID } = useParams<{ programID: string }>();
  const { refetchDERs } = useDERs(programID);
  const { refetchContainers } = useContainers(programID);

  const [selectedFeederOpts, setSelectedFeederOpts] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [fileData, setFileData] = useState(new FormData());

  const { makeRequest: runScalarDataUpload, loading: uploading } = useRequest(
    `/api/dsp/sia/scalar_data_upload/program/${programID}/feeder/${selectedFeederOpts?.value}`
  );

  const { makeRequest: runPrimariesUpload, loading: uploadingPrimaries } =
    useRequest('/api/dsp/primary/ingest');

  const handleUpload = async () => {
    const isScalarDataUpload = uploadType === UploadTypeEnum.ScalarData;
    const runUpload = isScalarDataUpload
      ? runScalarDataUpload
      : runPrimariesUpload;
    const successMessage = `${
      isScalarDataUpload ? 'Scalar data set' : 'Primaries model'
    } uploaded successfully.`;
    const errorMessage = `Could not upload ${
      isScalarDataUpload ? 'scalar data set' : 'primaries model'
    }.`;

    await runUpload({
      method: 'post',
      body: fileData,
      dataTransform: undefined,
      blockRequest: undefined,
      onSuccess: () => {
        cancelUpload(); // exits upload mode
        refetchContainers();
        refetchDERs();
      },
      onError: undefined,
      toast: {
        error: (error) => {
          if (!isScalarDataUpload) {
            return `${errorMessage} \n ${
              error?.response?.data?.message || error?.message || ''
            }`;
          }
          return `${errorMessage} ${error?.message || ''}`;
        },
        success: successMessage,
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const cancelUpload = () => {
    setSelectedFeederOpts(null);
    setShowUploadScalarModal(false);
    setShowUploadPrimariesModal(false);
    setFileData(new FormData());
    setFilename('');
    setHasData(false);

    if (uploadFormRef.current) {
      uploadFormRef.current.reset();
    }
  };

  return {
    handleUpload,
    uploading,
    uploadingPrimaries,
    cancelUpload,
    setSelectedFeederOpts,
    selectedFeederOpts,
    fileData,
  };
};
