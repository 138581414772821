import React from 'react';

import { DER, DERIcons } from 'types/der';
import { Tenant } from 'types/tenant';

import Tooltip from 'components/Tooltip';
import SVG from 'components/SVG';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/Accordion';
import IconButton from 'components/IconButton';

import ParticipantAssetConfiguration from './ParticipantAssetConfiguration';

export function renderDERRow(
  der: DER,
  tenant: Tenant,
  removeDERFromTenant: (derID: string) => Promise<void>,
  expandedDerID: string | undefined,
  setExpandedDerId: React.Dispatch<React.SetStateAction<string | undefined>>,
  refetchTenants: () => void
) {
  const {
    info: { der_type, name, der_rdf_id: id },
  } = der;

  return (
    <Accordion
      key={`participantDer${id}`}
      id={`participantDer${id}`}
      elevation={0}
      disableGutters
      className={'participant-ders__table-row'}
      expanded={id === expandedDerID}
      onChange={() =>
        expandedDerID === id
          ? setExpandedDerId(undefined)
          : setExpandedDerId(id)
      }
    >
      <AccordionSummary
        expandIcon={<SVG round icon="ChevronDownIcon" fontSize={'1.1rem'} />}
        AccordionSummaryProps={{
          className: 'participant-ders__table-row-accordion',
        }}
      >
        <div className="participant-ders__table-row-details">
          <div key={`${id}-name`} className="participant-ders__table-cell">
            <div className="participant-ders__table-content">
              <SVG
                className="participant-ders__der"
                icon={DERIcons[der_type]}
                fontSize="24px"
              />
              <Tooltip
                arrow
                theme="light"
                enabled={name?.length > 20}
                content={name}
              >
                <p className="participant-ders__table-content-text">{name}</p>
              </Tooltip>
              <IconButton
                icon="CopyIcon"
                fontSize="12px"
                customClasses={{
                  customButtonClass: 'participant-ders__table-content-copy',
                }}
                onClick={() => navigator.clipboard.writeText(name)}
              />
            </div>
          </div>
          <div key={`${id}-id`} className="participant-ders__table-cell">
            <div className="participant-ders__table-content participant-ders__table-content-text">
              <Tooltip theme="light" arrow content={id}>
                <div className="participant-ders__table-content-text">{id}</div>
              </Tooltip>
              <IconButton
                icon="CopyIcon"
                fontSize="12px"
                customClasses={{
                  customButtonClass: 'participant-ders__table-content-copy',
                }}
                onClick={() => navigator.clipboard.writeText(id)}
              />
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="participant-ders__table-row-config">
        <ParticipantAssetConfiguration
          der={der}
          tenant={tenant}
          removeDERFromTenant={removeDERFromTenant}
          refetchTenants={refetchTenants}
        />
      </AccordionDetails>
    </Accordion>
  );
}
