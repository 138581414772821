import React, { useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import classes from 'classnames';
import InputMask from 'react-input-mask';
import IconButton from 'components/IconButton';

import { TimePickerInputProps } from '../TimePicker.types';
import { getClosestInterval, getConvertedTime } from '../TimePicker.logic';
import { defaultInputState } from '../TimePicker';

import '../TimePicker.scss';

const TimePickerInput = React.forwardRef(
  ({
    inputState,
    setInputState,
    value,
    onChange,
    onClear,
    onClick,
    disabled,
    helperText,
    error,
    label,
    disableMenu,
    disableTyping,
    increments,
    isClearable,
    styles,
    classNames,
  }: TimePickerInputProps) => {
    const onInputChangeHandler = useCallback(
      (evt) => {
        evt.stopPropagation();
        const { newHour, newMinutes, isFullTimeEntered } = getConvertedTime(
          evt.target.value
        );
        if (isFullTimeEntered) {
          const closestMinutesInterval = getClosestInterval(
            increments,
            newMinutes
          );
          if (value) {
            onChange(
              value.set({
                hour: newHour,
                minute: closestMinutesInterval,
              })
            );
          }
        } else {
          setInputState(evt.target.value);
        }
      },
      [value, onChange, increments, setInputState]
    );

    const onClearHandler = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      onClear && onClear();
    };

    return (
      <FormControl fullWidth onClick={(e) => !disableMenu && onClick(e as any)}>
        <InputMask
          mask="99:99"
          maskChar="-"
          value={inputState}
          onChange={(e) => !disableTyping && onInputChangeHandler(e)}
          disabled={disabled}
        >
          {({ ...props }: any) => (
            <TextField
              {...props}
              label={label}
              variant="standard"
              helperText={helperText}
              className={classes(classNames, {
                'time-picker__input--disabled': disabled,
                'time-picker__input--error': error,
              })}
              classes={{ root: 'time-picker__input' }}
              style={styles}
              InputProps={{
                endAdornment: (
                  <>
                    {!disabled &&
                      isClearable &&
                      inputState !== defaultInputState && (
                        <IconButton
                          icon="XIcon"
                          variant="nestedIconButton"
                          onClick={onClearHandler}
                        />
                      )}
                    <IconButton
                      icon="ClockIcon"
                      disabled={disabled}
                      variant="nestedIconButton"
                    />
                  </>
                ),
              }}
            />
          )}
        </InputMask>
      </FormControl>
    );
  }
);

export default TimePickerInput;
