import React, { useState } from 'react';
import { apm, useRequest } from '@opusonesolutions/gridos-app-framework';
import { useAssetEnrollmentInfo } from 'routes/Program/routes/Enrollment/api';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import cn from 'classnames';

import { useUserContext } from 'contexts/UserContext';

import { serviceType, serviceTypeRaw } from 'types/contract-managment';
import { DER } from 'types/der';
import { Tenant } from 'types/tenant';

import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Checkbox from 'components/Checkbox/Checkbox';
import NumberInput from 'components/NumberInput';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { getServiceTypeRaw } from 'routes/LongTermContracts/helpers/dataProcessers';

import './ParticipantAssetConfiguration.scss';

export type ParticipantDersProps = {
  der: DER;
  tenant: Tenant;
  removeDERFromTenant: (derID: string) => Promise<void>;
  refetchTenants: () => void;
};

const KW_FACTOR = 1000;
const safeDivide = (n: number | null) => (n ? n / KW_FACTOR : n);
const safeMultiply = (n: number | null) => (n ? n * KW_FACTOR : n);

export const ParticipantAssetConfiguration = ({
  der,
  tenant,
  removeDERFromTenant,
  refetchTenants,
}: ParticipantDersProps) => {
  const [showConfirmation, setConfirmation] = useState<boolean>(false);
  const { userIsDso } = useUserContext();
  const isDso = userIsDso();

  const p2nServices = [
    serviceType.SustainPeakManagement,
    serviceType.SustainExportManagement,
    serviceType.SustainBilateral,
    serviceType.DynamicPeakManagement,
    serviceType.SecurePeakManagement,
  ];
  const p2pServices = [serviceType.MEC, serviceType.MIC];

  const { enable_p2p: isEnableP2P, enable_p2n: isEnableP2N } = tenant;

  const [saving, setSaving] = useState(false);

  const { enrollmentLoading: loading } = useAssetEnrollmentInfo(
    undefined,
    der.rdf_id
  );

  const schema = () =>
    yup.object().shape({
      connectionAgreementImportCapacity: yup.number().moreThan(-1).nullable(),
      connectionAgreementExportCapacity: yup.number().moreThan(-1).nullable(),
      tcvnImportCapacity: yup.number().moreThan(-1).nullable(),
      tcvnExportCapacity: yup.number().moreThan(-1).nullable(),
      enabledServices: yup.array(),
    });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const [enabledServices, setEnabledServices] = useState<serviceTypeRaw[]>(
    der?.enabled_services || []
  );

  const { makeRequest: makeUpdateDerRequest } = useRequest(
    `/api/dsp/der/${der.rdf_id}`
  );

  const [
    connectionAgreementImportCapacity,
    setConnectionAgreementImportCapacity,
  ] = useState<number | null>(
    safeDivide(der.connection_agreement_import_capacity)
  );

  const [
    connectionAgreementExportCapacity,
    setConnectionAgreementExportCapacity,
  ] = useState<number | null>(
    safeDivide(der.connection_agreement_export_capacity)
  );

  const [tcvnImportCapacity, setTcvnImportCapacity] = useState<number | null>(
    safeDivide(der.tcvn_import_capacity)
  );

  const [tcvnExportCapacity, setTcvnExportCapacity] = useState<number | null>(
    safeDivide(der.tcvn_export_capacity)
  );

  const updateDer = async () => {
    setSaving(true);
    await makeUpdateDerRequest({
      method: 'patch',
      body: {
        ...der,
        connection_agreement_import_capacity: safeMultiply(
          connectionAgreementImportCapacity
        ),
        connection_agreement_export_capacity: safeMultiply(
          connectionAgreementExportCapacity
        ),
        tcvn_import_capacity: safeMultiply(tcvnImportCapacity),
        tcvn_export_capacity: safeMultiply(tcvnExportCapacity),
        enabled_services: enabledServices,
      },
      toast: {
        success: `${der.info.name} updated`,
        // To Do:
        // When backend endpoint works, update this with message that includes impacted services.
        // E.g. 'PV1 was successfuly enrolled/unenrolled into/from ..., ..., ...'
        error: `Error updating ${der.info.name}`,
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        refetchTenants();
        setSaving(false);
      },
      onError: (error: AxiosError) => {
        apm.captureError(error);
        setSaving(false);
      },
    });

    setSaving(false);
  };

  const isServiceChecked = (service: serviceType) =>
    enabledServices.includes(getServiceTypeRaw(service));

  const mergeEnabledServices = (service: serviceType) => {
    const checkedService = getServiceTypeRaw(service);

    if (enabledServices.includes(checkedService)) {
      setEnabledServices((prev) =>
        prev.filter((value) => value !== checkedService)
      );
    } else {
      setEnabledServices((prev) => [...prev, checkedService]);
    }
  };

  const renderActions = () => {
    return (
      <div className="participant-asset-configuration__header-actions">
        <Button variant="outlined" disabled={saving}>
          Cancel
        </Button>
        <Button onClick={updateDer} disabled={saving || loading}>
          Save
        </Button>
        <IconButton
          icon="Trash2Icon"
          disabled={saving}
          onClick={() => setConfirmation(true)}
        />
      </div>
    );
  };

  const renderServiceTypes = (
    serviceTypes: serviceType[],
    isEnable: boolean | undefined
  ) => {
    return (
      <>
        {serviceTypes.map((service: serviceType) => {
          return (
            <div
              key={`participantAssetConfigurationContent${service}`}
              className="participant-asset-configuration__content-services-type"
            >
              <label
                className={cn('participant-asset-configuration__label', {
                  'participant-asset-configuration__label-disabled': !isEnable,
                })}
              >
                <Checkbox
                  checked={isServiceChecked(service)}
                  disabled={!isEnable || !isDso}
                  onChange={() => {
                    mergeEnabledServices(service);
                    isServiceChecked(service);
                  }}
                  label={service}
                />
              </label>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <ConfirmationDialog
        open={showConfirmation}
        title="Delete DER?"
        body={`Are you sure you want to delete DER ${
          der ? der.info.name : ''
        }? This action is permenant and cannot be undone. To add the DER back, you must add a new DER.`}
        onClose={() => setConfirmation(false)}
        onConfirm={() => removeDERFromTenant(der.rdf_id)}
        cancelText="Dismiss"
        confirmText="Yes, delete DER"
      />
      <form
        id="configure-asset"
        onSubmit={handleSubmit(updateDer)}
        className="participant-asset-configuration"
      >
        <div className="participant-asset-configuration__header">
          <div className="participant-asset-configuration__header-title">
            Asset configuration
          </div>
          {isDso && renderActions()}
        </div>
        <div className="participant-asset-configuration__content">
          <div className="participant-asset-configuration__content-services">
            <div className="participant-asset-configuration__content-services-title">
              Peer to network services
            </div>
            <div>
              <Tooltip
                content={
                  !isEnableP2N
                    ? `Market participant is not enrolled into P2N services`
                    : ''
                }
                arrow
                theme="light"
              >
                {renderServiceTypes(p2nServices, isEnableP2N)}
              </Tooltip>
            </div>
          </div>
          <div className="participant-asset-configuration__content-services">
            <div className="participant-asset-configuration__content-services-title">
              Peer to peer services
            </div>
            <div>
              <Tooltip
                content={
                  !isEnableP2P
                    ? `Market participant is not enrolled into P2P services`
                    : ''
                }
                arrow
                theme="light"
              >
                {renderServiceTypes(p2pServices, isEnableP2P)}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="participant-asset-configuration__content-capacities">
          <div className="participant-asset-configuration__label">
            Connection agreement import capacity
          </div>
          <Controller
            name="connectionAgreementImportCapacity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                id={'connectionAgreementImportCapacity'}
                unit="kW"
                invalid={!!errors?.connectionAgreementImportCapacity?.message}
                inputFieldClassName="participant-asset-configuration__input-field"
                value={Number(connectionAgreementImportCapacity)}
                onChange={(value) => {
                  setConnectionAgreementImportCapacity(Number(value));
                }}
                disabled={!isDso}
              />
            )}
          />
          <div className="participant-asset-configuration__label">
            Connection agreement export capacity
          </div>
          <Controller
            name="connectionAgreementExportCapacity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                id={'connectionAgreementExportCapacity'}
                unit="kW"
                invalid={!!errors?.connectionAgreementExportCapacity?.message}
                inputFieldClassName="participant-asset-configuration__input-field"
                value={Number(connectionAgreementExportCapacity)}
                onChange={(value) => {
                  setConnectionAgreementExportCapacity(Number(value));
                }}
                disabled={!isDso}
              />
            )}
          />
          <div className="participant-asset-configuration__label">
            TCVN additional import capacity
          </div>
          <Controller
            name="tcvnImportCapacity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                id={'tcvnImportCapacity'}
                unit="kW"
                invalid={!!errors?.tcvnImportCapacity?.message}
                inputFieldClassName="participant-asset-configuration__input-field"
                value={Number(tcvnImportCapacity)}
                onChange={(value) => {
                  setTcvnImportCapacity(Number(value));
                }}
                disabled={!isDso}
              />
            )}
          />
          <div className="participant-asset-configuration__label">
            TCVN additional export capacity
          </div>
          <Controller
            name="tcvnExportCapacity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                id={'tcvnExportCapacity'}
                unit="kW"
                invalid={!!errors?.tcvnExportCapacity?.message}
                inputFieldClassName="participant-asset-configuration__input-field"
                value={Number(tcvnExportCapacity)}
                onChange={(value) => {
                  setTcvnExportCapacity(Number(value));
                }}
                disabled={!isDso}
              />
            )}
          />
        </div>
      </form>
    </>
  );
};
