import React from 'react';

import Button from 'components/Button';
import SVG from 'components/SVG';

import './DeletingModal.scss';

interface DeletingModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

const DeletingModal = ({ onClose, onConfirm }: DeletingModalProps) => (
  <div className="deleting-modal">
    <div className="deleting-modal__message">
      <SVG
        fontSize="16px"
        icon="AlertTriangleIcon"
        className="deleting-modal__icon"
      />
      <p>Are you sure you want to remove this notification?</p>
    </div>
    <div className="deleting-modal__buttons">
      <Button variant="outlined" onClick={onClose}>
        No, keep it
      </Button>
      <Button onClick={onConfirm}>Yes, delete</Button>
    </div>
  </div>
);
export default DeletingModal;
