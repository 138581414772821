import React from 'react';

import './ModalSection.scss';

interface Props {
  title: string;
  icon: React.ReactNode;
  panelClass?: string;
}

const ModalSection: React.FC<Props> = ({
  title,
  icon,
  panelClass = 'modal-section__panel',
  children,
}) => (
  <div className="modal-section">
    <div className="modal-section__heading-panel">
      <h2 className="modal-section__title">{title}</h2>
      <span className="modal-section__icon">{icon}</span>
    </div>
    <div className={panelClass}>{children}</div>
  </div>
);

export default ModalSection;
