import { DateTime } from 'luxon';
import {
  serviceTypeRaw,
  selectOption,
  flexTypeRaw,
} from 'types/contract-managment';
import * as yup from 'yup';
import { CreateRequestFormData } from '../CreateRequest.types';

export const schema = (
  isCalculating: boolean,
  distributedResource: selectOption | undefined,
  serviceType: selectOption | undefined,
  bidOffer: flexTypeRaw | undefined
) =>
  yup.object().shape({
    availabilityPrice: yup
      .number()
      .nullable()
      .test(
        'price-error-for-p2n',
        'Availability price must be greater than -1',
        (value) => {
          if (value && value < 0) {
            return serviceType?.value === serviceTypeRaw.MEC ||
              serviceType?.value === serviceTypeRaw.MEC
              ? true
              : false;
          }
          return true;
        }
      ),
    utilizationPrice: yup.number().nullable(),
    quantity: yup
      .number()
      .typeError('Quantity must be a number.')
      .test(
        'quantityError',
        'Enter the number of kilowatts required.',
        (value) => {
          if ((value && value > 0) || isCalculating) {
            return true;
          }
          return false;
        }
      )
      .test(
        'mec_request_quantity_test',
        'Quantity exceeds maximum allowed for this resorce.',
        (value) => {
          // Bypass validation if any of required values are missing.
          if (!distributedResource) return true;
          if (value && distributedResource) {
            const {
              connectionExportCapacity: CAEC,
              connectionImportCapacity: CAIC,
              tcvnExportCapacity: TCVNExportCapacity,
              tcvnImportCapacity: TCVNImportCapacity,
            } = distributedResource as Record<string, any>;
            if (serviceType?.value === serviceTypeRaw.MEC) {
              if (
                bidOffer === flexTypeRaw.OFFER &&
                CAEC &&
                value * 1000 > CAEC
              ) {
                return false;
              }
              if (
                bidOffer === flexTypeRaw.BID &&
                TCVNExportCapacity &&
                value * 1000 > TCVNExportCapacity
              ) {
                return false;
              }
            }
            if (serviceType?.value === serviceTypeRaw.MIC) {
              if (
                bidOffer === flexTypeRaw.OFFER &&
                CAIC &&
                value * 1000 > CAIC
              ) {
                return false;
              }
              if (
                bidOffer === flexTypeRaw.BID &&
                TCVNImportCapacity &&
                value * 1000 > TCVNImportCapacity
              ) {
                return false;
              }
            }
          }
          // const res = distributedResource as Record<string, any>;
          // const s = res?.info?.apparent_power;
          // const powerFactor = res?.info?.power_factor;
          // const lowerPowerLimit = res?.info?.lower_power_limit;
          // const maxMECRequestQuantity = Math.abs(
          //   Math.abs(s * powerFactor) - Math.abs(lowerPowerLimit)
          // );
          // if (!s || !powerFactor || !lowerPowerLimit || !value) return true;
          // return value <= maxMECRequestQuantity;
          return true;
        }
      ),
    hours: yup
      .number()
      .typeError('Hours must be a number.')
      .test('hoursError', 'Duration required.', (value, context) => {
        const isValueBiggerThanZero = value && value > 0;
        if (!!isValueBiggerThanZero || isCalculating) {
          return true;
        }
        return false;
      }),
    systemCalculate: yup
      .string()
      .test('typeError', 'Please click calculate button', (value, context) => {
        if (!isCalculating) {
          const { hours, quantity } = context.parent;
          return hours > 0 && quantity > 0;
        }
        return true;
      }),
  });

export const handleNumberChange = (value: number | undefined): number | null =>
  value ? Number(value) : null;

export const onSystemCalculate = (
  setIsCalculating: (data: boolean) => void,
  setRanCalculation: (data: boolean) => void,
  submitSystemCalculate: (data: any) => void,
  startDate: DateTime | undefined,
  endDate: DateTime | undefined,
  includeActive: boolean,
  includePending: boolean,
  setDisableCalculateButton: (data: boolean) => void,
  onFieldChange: (name: keyof CreateRequestFormData, value: any) => void,
  setValue: (name: string, data: any) => void,
  procurementVolume: number,
  serviceType?: string
) => {
  setIsCalculating(true);
  setRanCalculation(true);
  submitSystemCalculate({
    method: 'get',
    params: {
      start_date: startDate?.toJSDate().toISOString(),
      end_date: endDate?.toJSDate().toISOString(),
      use_active_contracts: includeActive,
      use_pending_contracts: includePending,
      service_type: serviceType,
    },
    toast: {
      error:
        'Error generating system calculated request. ' +
        'Ensure forecasts have been uploaded for the service dates.',
      settings: {
        autoDismiss: true,
      },
    },
    onSuccess: (data: any) => {
      const { quantity, hours } = data;
      const formattedQuantity = quantity / 1000;
      const includeRequestQuantity =
        formattedQuantity * (procurementVolume / 100);

      setIsCalculating(false);
      setDisableCalculateButton(true);
      onFieldChange('systemCalculate', true);
      setValue('quantity', formattedQuantity);
      setValue('hours', hours);
      onFieldChange('requestQuantity', includeRequestQuantity);
      setValue('requestQuantity', includeRequestQuantity);
    },
    onError: () => {
      setIsCalculating(false);
      onFieldChange('systemCalculate', false);
      setValue('quantity', undefined);
      setValue('hours', undefined);
    },
  });
};

export const getEndDate = (start: DateTime, end: DateTime) => {
  const diff = end.diff(start, 'days').get('days');
  // if user select the same day we still need at least one day range to get forecast data
  if (diff < 1) {
    return end.plus({ day: 1 });
  }
  return end;
};
