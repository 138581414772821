import React, { useState } from 'react';

import SVG from 'components/SVG';
import { BranchItemProp } from './BranchItem.types';
import Menu from '../Menu';
import './BranchItem.scss';

const BranchItem = ({ items, children }: BranchItemProp) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const open = Boolean(actionsAnchorEl);

  const openActionMenu = (event: any) => {
    setActionsAnchorEl(event.currentTarget.parentElement);
  };
  const closeActionMenu = () => {
    setActionsAnchorEl(null);
  };
  return (
    <>
      <div onClick={openActionMenu} className="branch-item">
        {children}
        <SVG icon="ChevronRightIcon" className="branch-item__icon" />
      </div>
      <Menu
        open={open}
        autoFocus={false}
        anchorEl={actionsAnchorEl}
        onClose={closeActionMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items}
      </Menu>
    </>
  );
};

export default BranchItem;
