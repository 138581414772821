import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { getSystemTimezone } from 'helpers/time';
import { env } from 'helpers/env';

import './Clock.scss';

type TimeDisplayProps = {
  title: string;
  datetime: DateTime;
};

const TimeDisplay: React.FC<TimeDisplayProps> = ({ title, datetime }) => {
  let color = '';
  if (env.isNMF) {
    color = 'white';
  }
  return (
    <div className="clock__wrap">
      <p style={{ color }} className="clock__title">
        {title}
      </p>
      <p style={{ color }} className="clock__date">
        {datetime.toFormat('MMM d yyyy')}
      </p>
      <p style={{ color }} className="clock__time">
        {datetime.toFormat('HH:mm')}
      </p>{' '}
      <p style={{ color }} className="clock__timezone">
        ({datetime.zoneName})
      </p>
    </div>
  );
};

type Props = {
  datetime: DateTime;
};

const Clock: React.FC<Props> = ({ datetime }) => {
  const { selectedProgram } = useProgramsContext();
  const [date, setDate] = useState(datetime);

  useEffect(() => {
    const interval = setTimeout(() => {
      setDate(DateTime.local());
    }, 5000);
    return () => clearTimeout(interval);
  });

  useEffect(() => {
    setDate(datetime);
  }, [datetime]);

  return (
    <div className="clock">
      {selectedProgram && (
        <>
          <TimeDisplay title="Program time" datetime={date} />
          <span className="clock__separator" />
        </>
      )}
      <TimeDisplay
        title="Local Time"
        datetime={date.setZone(getSystemTimezone())}
      />
    </div>
  );
};

export default Clock;
