import React from 'react';

import { DERIcons, DERType } from 'types/der';
import { TrafficLightStatus } from 'types/settlement';

import QuantityTag from 'components/QuantityTag';
import SVG from 'components/SVG';
import Light from 'components/Light';

import './TableColumns.scss';

function getTagClass(mainRow: boolean) {
  return mainRow
    ? 'table-columns__power-tag'
    : 'table-columns__power-tag--subrow';
}

export function renderLightVariantCell(status: TrafficLightStatus) {
  switch (status) {
    case TrafficLightStatus.Green:
      return <Light variant="success" size="xsmall" />;
    case TrafficLightStatus.Orange:
      return <Light variant="warning" size="xsmall" />;
    default:
      return <Light variant="off" size="xsmall" />;
  }
}

export function renderPowerQuantityCell(
  quantity: number | undefined,
  mainRow: boolean
) {
  if (!quantity) {
    return '-';
  }
  return (
    <QuantityTag
      quantity={quantity || 0}
      convertFromWatts={true}
      tagProps={{
        round: true,
        customClasses: {
          tagClass: getTagClass(mainRow),
        },
      }}
    />
  );
}

export function renderDeliveredCell(
  delivered: number | undefined,
  requested: number | undefined,
  mainRow: boolean
) {
  if (!delivered && delivered !== 0) {
    return '-';
  }
  if (!requested) {
    return '-';
  }

  const underdelivered = delivered < requested;
  return (
    <span className="table-columns__delivered">
      <QuantityTag
        quantity={delivered || 0}
        convertFromWatts={true}
        border={underdelivered && mainRow}
        tagProps={{
          round: true,
          customClasses: {
            tagClass: getTagClass(mainRow),
          },
        }}
      />
      {requested && mainRow && (
        <span className="table-columns__delivered--percentage">
          {Math.round((delivered / requested) * 100)}%
        </span>
      )}
      {requested && !mainRow && requested > delivered && (
        <SVG icon="ArrowDownIcon" fontSize="12px" />
      )}
    </span>
  );
}

export function renderCurrencyCell(
  value: number | undefined,
  currencyFormatter: Intl.NumberFormat,
  subcell = false
) {
  if (!value && value !== 0) {
    return '-';
  }
  return (
    <span className={`table-columns__currency${subcell ? '--subcell' : ''}`}>
      {currencyFormatter.format(value)}
    </span>
  );
}

export function renderAssetTypeCell(asset: DERType, name?: string) {
  return (
    <span className="view-flex-auction-info__row">
      <SVG icon={`${DERIcons[asset]}`} fontSize="18px" />
      <span
        style={{ margin: '3px 0 0 6px' }}
        className="view-flex-auction-info__text"
      >
        {name ? name : asset}
      </span>
    </span>
  );
}
