/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { DateTime } from 'luxon';

import TimePicker from 'components/TimePicker';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import SVG from 'components/SVG';
import Switch from 'components/Switch';
import Stepper, { StepperStep } from 'components/Stepper';
import Checkbox from 'components/Checkbox';
import Menu from 'components/Menu';
import MenuItem from 'components/Menu/MenuItem';
import Tooltip from 'components/Tooltip';
import RadioButtons from 'components/RadioButtons';
import { DatePicker } from 'components/DatePicker';
import SunIcon from 'SVG/Icons/SunIcon';

import './Icons.scss';

const iconNames = [
  'ActivityIcon',
  'AggregatorIcon',
  'AirplayIcon',
  'AlertCircleIcon',
  'AlertOctagonIcon',
  'AlertTriangleIcon',
  'AlignCenterIcon',
  'AlignJustifyIcon',
  'AlignLeftIcon',
  'AlignRightIcon',
  'AnchorIcon',
  'ApertureIcon',
  'ArchiveIcon',
  'ArrowDownCircleIcon',
  'ArrowDownIcon',
  'ArrowDownLeftIcon',
  'ArrowDownRightIcon',
  'ArrowRightIcon',
  'ArrowUpCircleIcon',
  'ArrowUpIcon',
  'ArrowUpLeftIcon',
  'ArrowUpRightIcon',
  'AtSignIcon',
  'AwardIcon',
  'BarChart1Icon',
  'BarChart2Icon',
  'BatteryChargingIcon',
  'BatteryIcon',
  'BellIcon',
  'BellOffIcon',
  'BluetoothIcon',
  'BoldIcon',
  'BookIcon',
  'BookmarkIcon',
  'BookOpenIcon',
  'BoxIcon',
  'BriefcaseIcon',
  'CalendarIcon',
  'CameraIcon',
  'CameraOffIcon',
  'CastIcon',
  'CheckCircleIcon',
  'CheckIcon',
  'CheckSquareIcon',
  'ChevronDownIcon',
  'ChevronLeftIcon',
  'ChevronRightIcon',
  'ChevronsDownIcon',
  'ChevronsLeftIcon',
  'ChevronsRightIcon',
  'ChevronsUpIcon',
  'ChevronUpIcon',
  'ChromeIcon',
  'CircleIcon',
  'ClipboardIcon',
  'ClockIcon',
  'CloudDrizzleIcon',
  'CloudIcon',
  'CloudLightningIcon',
  'CloudOffIcon',
  'CloudRainIcon',
  'CloudSnowIcon',
  'CodeIcon',
  'CodepenIcon',
  'CodesAndBoxIcon',
  'CoffeeIcon',
  'ColumnsIcon',
  'CommandIcon',
  'CompassIcon',
  'CongestionPointIcon',
  'ConnectionsIcon',
  'CopyIcon',
  'CornerDownLeftIcon',
  'CornerDownRightIcon',
  'CornerLeftDownIcon',
  'CornerLeftUpIcon',
  'CornerRightDownIcon',
  'CornerUpLeftIcon',
  'CornerUpRigh1Icon',
  'CornerUpRightIcon',
  'CPUIcon',
  'CreditCardIcon',
  'CropIcon',
  'CrosshairIcon',
  'DatabaseIcon',
  'DeleteIcon',
  'DiscIcon',
  'DivideCircleIcon',
  'DivideIcon',
  'DivideSquareIcon',
  'DollarSignIcon',
  'DownloadCloudIcon',
  'DownloadIcon',
  'DribbleIcon',
  'DropletIcon',
  'Edit1Icon',
  'Edit2Icon',
  'Edit3Icon',
  'ExternalLinkIcon',
  'EyeIcon',
  'EyeOffIcon',
  'FacebookIcon',
  'FastForwardIcon',
  'FeatherIcon',
  'FigmaIcon',
  'FileIcon',
  'FileMinusIcon',
  'FilePlusIcon',
  'FileTextIcon',
  'FilmIcon',
  'FilterIcon',
  'FlagIcon',
  'FolderIcon',
  'FolderMinusIcon',
  'FolderPlusIcon',
  'FramerIcon',
  'FrownIcon',
  'GiftIcon',
  'GitBranchIcon',
  'GitCommitIcon',
  'GithubIcon',
  'GitlabIcon',
  'GitMergeIcon',
  'GitPullRequestIcon',
  'GlobeIcon',
  'GridIcon',
  'HardDriveIcon',
  'HashIcon',
  'HeadphonesIcon',
  'HeartIcon',
  'HelpCircleIcon',
  'HexagonIcon',
  'HomeIcon',
  'ImageIcon',
  'InboxIcon',
  'InfoIcon',
  'InstagramIcon',
  'ItalicIcon',
  'KeyIcon',
  'LayersIcon',
  'LayoutIcon',
  'LifeBuoyIcon',
  'Link1Icon',
  'Link2Icon',
  'LinkedInIcon',
  'ListIcon',
  'LoaderIcon',
  'LockIcon',
  'LogInIcon',
  'LogOutIcon',
  'MailIcon',
  'MapIcon',
  'MapPinIcon',
  'Maximize1Icon',
  'Maximize2Icon',
  'MehIcon',
  'MenuIcon',
  'MessageCircleIcon',
  'MessageSquareIcon',
  'MicrophoneIcon',
  'MicrophoneOffIcon',
  'Minimize1Icon',
  'Minimize2Icon',
  'MinusCircleIcon',
  'MinusIcon',
  'MinusSquareIcon',
  'MonitorIcon',
  'MoonIcon',
  'MoreHorizontalIcon',
  'MoreVerticalIcon',
  'MousePointerIcon',
  'MoveIcon',
  'MusicIcon',
  'Navigation1Icon',
  'Navigation2Icon',
  'OctagonIcon',
  'PackageIcon',
  'PaperclipIcon',
  'PauseCircleIcon',
  'PauseIcon',
  'PenToolIcon',
  'PercentIcon',
  'PhoneCallIcon',
  'PhoneForwardedIcon',
  'PhoneIcon',
  'PhoneIncomingIcon',
  'PhoneMissedIcon',
  'PhoneOffIcon',
  'PhoneOutgoingIcon',
  'PieChartIcon',
  'PlayCircleIcon',
  'PlayIcon',
  'PlusCircleIcon',
  'PlusIcon',
  'PlusSquareIcon',
  'PocketIcon',
  'PowerIcon',
  'PrinterIcon',
  'RadioIcon',
  'RefreshCCWIcon',
  'RefreshCWIcon',
  'RepeatIcon',
  'RewindIcon',
  'RotateCCWIcon',
  'RotateCWIcon',
  'RSSIcon',
  'SaveIcon',
  'ScissorsIcon',
  'SearchIcon',
  'SendIcon',
  'ServerIcon',
  'SettingsIcon',
  'Share1Icon',
  'Share2Icon',
  'ShieldIcon',
  'ShieldOffIcon',
  'ShoppingBagIcon',
  'ShoppingCartIcon',
  'ShuffleIcon',
  'SidebarIcon',
  'SkipBackIcon',
  'SkipForwardIcon',
  'SlackIcon',
  'SlashIcon',
  'SlidersIcon',
  'SmartphoneIcon',
  'SmileIcon',
  'SortHighLowIcon',
  'SortLowHighIcon',
  'SpeakerIcon',
  'SquareIcon',
  'StarIcon',
  'StopCircleIcon',
  'SunIcon',
  'SunriseIcon',
  'SunsetIcon',
  'TabletIcon',
  'TagIcon',
  'TargetIcon',
  'TerminalIcon',
  'ThermometerIcon',
  'ThumbsDownIcon',
  'ThumbsUpIcon',
  'ToggleLeftIcon',
  'ToggleRightIcon',
  'ToolIcon',
  'Trash1Icon',
  'Trash2Icon',
  'TrelloIcon',
  'TrendingDownIcon',
  'TrendingUpIcon',
  'TriangleIcon',
  'TruckIcon',
  'TVIcon',
  'TwitchIcon',
  'TwitterIcon',
  'TypeIcon',
  'UmbrellaIcon',
  'UnderlineIcon',
  'UnlockIcon',
  'UploadCloudIcon',
  'UploadIcon',
  'UserCheckIcon',
  'UserIcon',
  'UserMinusIcon',
  'UserPlusIcon',
  'UsersIcon',
  'UserXIcon',
  'VideoIcon',
  'VideoOffIcon',
  'VoicemailIcon',
  'Volume1Icon',
  'Volume2Icon',
  'VolumeIcon',
  'VolumeXIcon',
  'WatchIcon',
  'WifiIcon',
  'WifiOffIcon',
  'WindIcon',
  'XCircleIcon',
  'XIcon',
  'XOctagonIcon',
  'XSquareIcon',
  'YoutubeIcon',
  'ZapIcon',
  'ZapOffIcon',
  'ZoomInIcon',
  'ZoomOutIcon',
  'DERHydroIcon',
  'DERWindIcon',
  'DERSolarIcon',
  'DERBESSIcon',
  'DERSynchIcon',
  'DERShiftingIcon',
  'DERCurtailingIcon',
];

type CheckboxStateProps = {
  [key: string]: boolean;
};

const Icons = () => {
  function GenerateIcons() {
    return (
      <div className="icons">
        {iconNames.map((name) => {
          return (
            <div key={name} className="icons__item">
              <div className="icons__item--code">
                <pre>{name}</pre>
              </div>
              <div className="icons__item--icon">
                <SVG icon={name} fontSize="24" />
              </div>
            </div>
          );
        })}
        <div className="icons__item">
          <div className="icons__item--code">
            <pre>Demo of custom colors</pre>
          </div>
          <div className="icons__item--icon">
            <SVG
              icon="DERHydroIcon"
              className="icons__item--colors"
              color="red"
              fontSize="48"
            />
          </div>
        </div>
        <div className="icons__item">
          <div className="icons__item--code">
            <pre>Demo of material icon</pre>
          </div>
          <div className="icons__item--icon">
            <SVG
              icon="settings"
              className="icons__item--colors"
              fontSize="48"
            />
          </div>
        </div>
      </div>
    );
  }

  function GenerateSwitches() {
    const [sw1, setsw1] = useState(false);
    const [sw2, setsw2] = useState(false);
    const [sw3, setsw3] = useState(false);
    const [sw4, setsw4] = useState(false);
    const [sw5, setsw5] = useState(false);
    const [sw6, setsw6] = useState(false);
    const [sw7, setsw7] = useState(false);
    const [sw8, setsw8] = useState(false);
    const [sw9, setsw9] = useState(false);
    const [sw10, setsw10] = useState(false);
    const [sw11, setsw11] = useState(false);
    const [sw12, setsw12] = useState(false);

    function changeSW1() {
      setsw1(!sw1);
    }

    return (
      <>
        <div className="icons__container">
          <span className="icons__title--main">
            Toggle Types and sizes with helper text
          </span>
          <div className="icons">
            <div className="icons__wrapper-icons">
              <span className="icons__title">Primary</span>
              <Switch
                id="sw1"
                type="primary"
                checked={sw1}
                onClick={changeSW1}
                label="on"
                secondaryLabel="off"
                helperText="Helper Text"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Secondary</span>
              <Switch
                id="sw2"
                type="secondary"
                checked={sw2}
                onClick={() => setsw2(!sw2)}
                label="on"
                secondaryLabel="off"
                helperText="Helper Text"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Success</span>
              <Switch
                id="sw3"
                type="success"
                checked={sw3}
                onClick={() => setsw3(!sw3)}
                label="on"
                secondaryLabel="off"
                helperText="Helper Text"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Warning</span>
              <Switch
                id="sw4"
                type="warning"
                checked={sw4}
                onClick={() => setsw4(!sw4)}
                label="on"
                secondaryLabel="off"
                helperText="Helper Text"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Error</span>
              <Switch
                id="sw5"
                type="error"
                checked={sw5}
                onClick={() => setsw5(!sw5)}
                label="on"
                secondaryLabel="off"
                helperText="Helper Text"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Disabled</span>
              <Switch
                id="sw12"
                type="error"
                checked={sw12}
                onClick={() => setsw12(!sw12)}
                label="on"
                secondaryLabel="off"
                helperText="Helper Text"
                disabled
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Small</span>
              <Switch
                id="sw6"
                type="primary"
                checked={sw6}
                onClick={() => setsw6(!sw6)}
                label="on"
                secondaryLabel="off"
                size="small"
                helperText="Helper Text"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Normal</span>
              <Switch
                id="sw7"
                type="primary"
                checked={sw7}
                onClick={() => setsw7(!sw7)}
                label="on"
                secondaryLabel="off"
                helperText="Helper Text"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Large</span>
              <Switch
                id="sw8"
                type="primary"
                checked={sw8}
                onClick={() => setsw8(!sw8)}
                label="on"
                secondaryLabel="off"
                size="large"
                helperText="Helper Text"
              />
            </div>
          </div>
        </div>
        <div className="icons__container">
          <span className="icons__title--main">
            Toggles with different Label Positions
          </span>
          <div className="icons">
            <div className="icons__wrapper-icons">
              <Switch
                id="sw9"
                type="primary"
                checked={sw9}
                onClick={() => setsw9(!sw9)}
                label="top"
                labelPosition="top"
              />
            </div>
            <div className="icons__wrapper-icons">
              <Switch
                id="sw9"
                type="primary"
                checked={sw9}
                onClick={() => setsw9(!sw9)}
                label="right"
                labelPosition="right"
              />
            </div>
            <div className="icons__wrapper-icons">
              <Switch
                id="sw9"
                type="primary"
                checked={sw9}
                onClick={() => setsw9(!sw9)}
                label="bottom"
                labelPosition="bottom"
              />
            </div>
            <div className="icons__wrapper-icons">
              <Switch
                id="sw9"
                type="primary"
                checked={sw9}
                onClick={() => setsw9(!sw9)}
                label="left"
                labelPosition="left"
              />
            </div>
          </div>
        </div>
        <div className="icons__container">
          <span className="icons__title--main">
            Different sized toggles with internal labels
          </span>
          <div className="icons">
            <div className="icons__wrapper-icons">
              <span className="icons__title">Small</span>
              <Switch
                id="sw10"
                type="primary"
                checked={sw10}
                onClick={() => setsw10(!sw10)}
                label="on"
                secondaryLabel="off"
                containLabel
                size="small"
                helperText="no internal labels"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Normal</span>
              <Switch
                id="sw10"
                type="primary"
                checked={sw10}
                onClick={() => setsw10(!sw10)}
                labelPosition="left"
                label="on"
                secondaryLabel="off"
                containLabel
                size="normal"
              />
            </div>
            <div className="icons__wrapper-icons">
              <span className="icons__title">Large</span>
              <Switch
                id="sw10"
                type="primary"
                checked={sw10}
                onClick={() => setsw10(!sw10)}
                labelPosition="left"
                label="on"
                secondaryLabel="off"
                containLabel
                size="large"
              />
            </div>
          </div>
        </div>
        <div className="icons__container">
          <span className="icons__title--main">
            Custom Toggle with tooltip:
          </span>
          <div className="icons">
            <div className="icons__wrapper-icons">
              <Switch
                id="sw11"
                type="error"
                toolTip="This is a toggle with custom classes passed to it!"
                checked={sw11}
                onClick={() => setsw11(!sw11)}
                label="on"
                secondaryLabel="off"
                size="large"
                helperText="I'm purple!"
                classNames={{
                  toggleClass: 'icons__custom-switch-toggle',
                  knobClass: sw11
                    ? 'icons__custom-switch-knob--active'
                    : 'icons__custom-switch-knob',
                  textClass: 'icons__custom-switch-text',
                  labelRBClass: 'icons__custom-switch-rb',
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  const CheckboxesContent = () => {
    const [checkboxState, setCheckboxState] = useState<CheckboxStateProps>({});

    const onCheckboxHandler = (id: string) => {
      setCheckboxState((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    return (
      <>
        <Checkbox
          checked={!!checkboxState?.id_1}
          label="Can click"
          onChange={() => onCheckboxHandler('id_1')}
        />
        <Checkbox checked={false} label="Unselected" onChange={() => false} />
        <Checkbox checked onChange={() => false} label="Active" />
        <Checkbox checked indeterminate onChange={() => false} label="Multi" />
        <Checkbox
          disabled
          checked={false}
          onChange={() => false}
          label="Disabled (unselected)"
        />
        <Checkbox
          disabled
          checked
          onChange={() => false}
          label="Disabled (active)"
        />

        <Checkbox
          checked
          onChange={() => false}
          label="With tooltip"
          tooltip="With tooltip"
        />

        <Checkbox
          checked={!!checkboxState?.id_2}
          disableRipple
          onChange={() => onCheckboxHandler('id_2')}
          label="With out animations"
        />
      </>
    );
  };

  const DemoStepper = () => {
    const isLinear = true;
    /* contains examples of different kinds of steppers, pick and choose as needed */
    // index to easily pull active step information
    const [activeStep, setActiveStep] = useState(0);
    // list of all the steps and their states.
    const [steps, updateSteps] = useState<StepperStep[]>([
      {
        label: 'Request setup',
        active: true,
        completed: false,
      },
      {
        label: 'Service details',
        active: false,
        completed: false,
      },
      {
        label: 'Quantity & price',
        active: false,
        completed: false,
      },
      {
        label: 'Review',
        active: false,
        completed: false,
      },
    ]);

    const totalSteps = steps.length;

    function updateStep(
      updatedSteps: StepperStep[],
      step: number,
      active: boolean | undefined = undefined,
      completed: boolean | undefined = undefined
    ) {
      updatedSteps[step] = {
        ...updatedSteps[step],
        active: active !== undefined ? active : updatedSteps[step].active,
        completed:
          completed !== undefined ? completed : updatedSteps[step].completed,
      };
    }

    const handleNext = () => {
      if (isLinear) {
        // make a copy
        const updatedSteps = [...steps];
        // update next step if any otherwise this function should not have been triggered.
        if (totalSteps !== activeStep + 1) {
          // update current step
          updateStep(updatedSteps, activeStep, false, true);
          updateStep(updatedSteps, activeStep + 1, true);
          // update step state
          setActiveStep(activeStep + 1);
          updateSteps(updatedSteps);
        } else {
          // complete the active step
          updateStep(updatedSteps, activeStep, true, true);
          updateSteps(updatedSteps);
        }
      } else if (!isLinear) {
        // make a copy
        const updatedSteps = [...steps];
        // update current step
        updateStep(updatedSteps, activeStep, false);
        // update next step if any otherwise go to first step.
        if (totalSteps !== activeStep + 1) {
          updateStep(updatedSteps, activeStep + 1, true);
          setActiveStep(activeStep + 1);
        } else {
          updateStep(updatedSteps, 0, true);
          setActiveStep(0);
        }
        // update step state
        updateSteps(updatedSteps);
      }
    };

    const handleBack = () => {
      // if linear go back and umcomplete step
      if (isLinear) {
        // make a copy
        const updatedSteps = [...steps];
        // update previous step if any
        if (activeStep !== 0) {
          if (activeStep === totalSteps - 1 && steps[activeStep].completed) {
            // just undo the current step without going back
            updateStep(updatedSteps, activeStep, true, false);
            updateSteps(updatedSteps);
          } else {
            // update current step
            updateStep(updatedSteps, activeStep, false, false);
            // activate previous step
            updateStep(updatedSteps, activeStep - 1, true, false);
            // update step state
            setActiveStep(activeStep - 1);
            updateSteps(updatedSteps);
          }
        }
      } else if (!isLinear) {
        // make a copy
        const updatedSteps = [...steps];
        // update current step
        updateStep(updatedSteps, activeStep, false);
        // update previous step if any otherwise go to last step.
        if (activeStep - 1 >= 0) {
          updateStep(updatedSteps, activeStep - 1, true);
          setActiveStep(activeStep - 1);
        } else {
          updateStep(updatedSteps, totalSteps - 1, true);
          setActiveStep(totalSteps - 1);
        }
        // update step state
        updateSteps(updatedSteps);
      }
    };

    const handleReset = () => {
      // first item active rest inactive and everything incomplete
      setActiveStep(0);
      updateSteps(
        steps.map((step, index) => {
          let active = false;
          if (index === 0) {
            active = true;
          }
          return {
            ...step,
            active,
            completed: false,
          };
        })
      );
    };

    function handleStepClick(step: number) {
      if (!isLinear) {
        // make a copy
        const updatedSteps = [...steps];
        // update current step
        updateStep(updatedSteps, activeStep, false);
        // update previous step if any otherwise go to last step.
        updateStep(updatedSteps, step, true);
        updateSteps(updatedSteps);
        setActiveStep(step);
      } else if (isLinear && step <= activeStep) {
        // make a copy
        const updatedSteps = [...steps];
        // update steps
        updatedSteps.forEach((stepToUpdate, index) => {
          if (index > step) updateStep(updatedSteps, index, false, false);
          if (index === step) updateStep(updatedSteps, index, true, false);
        });
        updateSteps(updatedSteps);
        setActiveStep(step);
      }
    }

    function handleComplete() {
      // only relevant for non linear steppers
      // make a copy
      const updatedSteps = [...steps];
      // complete active step
      updateStep(updatedSteps, activeStep, undefined, true);
      // update state
      updateSteps(updatedSteps);
    }

    return (
      <div className="icons__stepper-wrapper">
        <Stepper
          id={'test-stepper'}
          steps={steps}
          direction={'horizontal'}
          onStepClick={handleStepClick}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '2rem 0',
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            customClasses={{
              customButtonClass: 'back-button',
            }}
            onClick={handleBack}
            // disabled={activeStep === 1}
          >
            Back
          </Button>
          {steps.every((step) => step.completed) && (
            <div>All steps completed - you&lsquo;re finished</div>
          )}
          <div style={{ display: 'flex' }}>
            <Button
              color="primary"
              variant="outlined"
              customClasses={{
                customButtonClass: 'cancel-button',
              }}
              onClick={handleReset}
              customStyles={{
                customButtonStyles: { marginRight: '20px' },
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              customClasses={{
                customButtonClass: 'next-button',
              }}
              onClick={handleNext}
            >
              Next
            </Button>
            {!isLinear && (
              <Button
                color="primary"
                customClasses={{
                  customButtonClass: 'next-button',
                }}
                onClick={handleComplete}
                customStyles={{
                  customButtonStyles: { marginLeft: '20px' },
                }}
              >
                Complete Step
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  function GenerateButtons() {
    const [showSaveButton, setShowSaveButton] = useState(false);
    function setSaveButton() {
      setShowSaveButton(!showSaveButton);
    }
    return (
      <div className="icons__buttons">
        <Button
          variant="contained"
          loading={showSaveButton}
          onClick={setSaveButton}
        >
          Button
        </Button>
        <Button
          variant="outlined"
          loading={showSaveButton}
          onClick={setSaveButton}
        >
          Button
        </Button>
        <Button variant="text" loading={showSaveButton} onClick={setSaveButton}>
          Button
        </Button>
        <Button variant="contained" disabled>
          Button
        </Button>
        <Button variant="outlined" disabled>
          Button
        </Button>
        <Button variant="text" disabled>
          Button
        </Button>
        <Button
          variant="contained"
          loading={showSaveButton}
          onClick={setSaveButton}
          startIcon={<SVG icon="PlusIcon" />}
          endIcon={<SVG icon="PlusCircleIcon" />}
        >
          Button
        </Button>
        <Button
          variant="outlined"
          loading={showSaveButton}
          onClick={setSaveButton}
          endIcon={<SVG icon="PlusIcon" />}
          startIcon={<SVG icon="PlusCircleIcon" />}
        >
          Button
        </Button>
        <Button
          variant="text"
          loading={showSaveButton}
          onClick={setSaveButton}
          startIcon={<SVG icon="PlusCircleIcon" />}
          endIcon={<SVG icon="CodesAndBoxIcon" />}
        >
          Button
        </Button>
        <Button
          variant="text"
          loading={showSaveButton}
          onClick={setSaveButton}
          startIcon={<SVG icon="PlusCircleIcon" fontSize="16px" />}
          endIcon={<SVG icon="CodesAndBoxIcon" fontSize="16px" />}
        >
          Button
        </Button>
        <Button
          variant="contained"
          loading={showSaveButton}
          onClick={() => console.log('clicked!')}
          startIcon={<SVG icon="PlusIcon" />}
          endIcon={<SVG icon="PlusCircleIcon" />}
          disabled
        >
          Button
        </Button>
        <Button
          variant="outlined"
          loading={showSaveButton}
          onClick={setSaveButton}
          endIcon={<SVG icon="PlusIcon" />}
          startIcon={<SVG icon="PlusCircleIcon" />}
          disabled
        >
          Button
        </Button>
        <Button
          variant="text"
          loading={showSaveButton}
          onClick={setSaveButton}
          startIcon={<SVG icon="ActivityIcon" />}
          endIcon={<SVG icon="CodesAndBoxIcon" />}
          disabled
        >
          Button
        </Button>
        <Button
          variant="text"
          loading={showSaveButton}
          onClick={setSaveButton}
          tooltip="This is a button with a tooltip!"
        >
          Button Tooltip
        </Button>
        <Button
          variant="outlined"
          loading={showSaveButton}
          onClick={setSaveButton}
          startIcon={<SVG icon="ActivityIcon" />}
          endIcon={<SVG icon="CodesAndBoxIcon" />}
          disabledInteractions
        >
          Button not interactable
        </Button>
      </div>
    );
  }

  function GenerateIconButtons() {
    const [showSaveButton, setShowSaveButton] = useState(false);
    function setSaveButton() {
      setShowSaveButton(!showSaveButton);
    }
    return (
      <div className="icons__buttons">
        <IconButton
          icon="ApertureIcon"
          onClick={setSaveButton}
          loading={showSaveButton}
        />
        <IconButton
          icon="ApertureIcon"
          onClick={setSaveButton}
          loading={showSaveButton}
          selected
        />
        <IconButton
          icon="ApertureIcon"
          onClick={setSaveButton}
          loading={showSaveButton}
          disabled
        />
        <IconButton icon="ApertureIcon" variant="nestedIconButton" />
        <IconButton
          icon="ApertureIcon"
          variant="nestedIconButton"
          onClick={setSaveButton}
          loading={showSaveButton}
          disabled
        />
      </div>
    );
  }

  function GenerateTimePicker() {
    const [state, setState] = useState<DateTime | undefined>(undefined);

    const setStateHandler = (value: DateTime | undefined) => {
      setState(value);
    };

    return (
      <div className="icons">
        <div className="icons__item">
          <TimePicker
            isClearable
            label="Clearable"
            value={state}
            onChange={setStateHandler}
          />
        </div>
        <div className="icons__item">
          <TimePicker
            isClearable
            disabled
            label="Disabled"
            value={state}
            onChange={setStateHandler}
          />
        </div>
        <div className="icons__item">
          <TimePicker
            label="Default"
            value={state}
            onChange={setStateHandler}
          />
        </div>
      </div>
    );
  }

  const GenerateMenu = () => {
    const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
      null
    );

    const [checkboxState, setCheckboxState] = useState([false, true]);

    const open = Boolean(actionsAnchorEl);

    const openActionMenu = (event: any) => {
      setActionsAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
      setActionsAnchorEl(null);
    };

    const selectAllCheckboxes = () => {
      setCheckboxState((prev) => prev.map((i) => true));
    };

    const changeCheckboxState = (id: number) => {
      setCheckboxState((prev) =>
        prev.map((el, idx) => {
          if (idx === id) {
            return !prev[idx];
          }
          return el;
        })
      );
    };

    const isAllCheckboxesActive = !checkboxState.includes(false);

    return (
      <>
        <Button onClick={openActionMenu}>Open menu</Button>
        <Menu
          id="test-menu"
          anchorEl={actionsAnchorEl}
          open={open}
          onClose={closeActionMenu}
        >
          <MenuItem tooltip="Tooltip example">Item with tooltip</MenuItem>
          <MenuItem>Item unselected</MenuItem>
          <MenuItem selected>Item selected</MenuItem>
          <MenuItem disabled divider>
            Item disabled
          </MenuItem>
          <MenuItem
            type="checkbox"
            divider
            selected={isAllCheckboxesActive}
            onChange={selectAllCheckboxes}
          >
            Select all
          </MenuItem>
          <MenuItem
            type="checkbox"
            selected={checkboxState[0]}
            onChange={() => changeCheckboxState(0)}
          >
            Checkbox unselected
          </MenuItem>
          <MenuItem
            selected={checkboxState[1]}
            type="checkbox"
            onChange={() => changeCheckboxState(1)}
          >
            Checkbox selected
          </MenuItem>
          <MenuItem disabled divider type="checkbox">
            Checkbox disabled
          </MenuItem>
          <MenuItem
            type="branch"
            items={
              <>
                <MenuItem>Item 1</MenuItem>
                <MenuItem>Item 2</MenuItem>
                <MenuItem>Item 3</MenuItem>
              </>
            }
          >
            Item 5
          </MenuItem>
          <MenuItem
            type="branch"
            items={
              <>
                <MenuItem>Item 4</MenuItem>
                <MenuItem>Item 5</MenuItem>
                <MenuItem>Item 6</MenuItem>
              </>
            }
          >
            Item 6
          </MenuItem>
          <MenuItem icon={<SunIcon />}>With icon</MenuItem>
          <MenuItem endAdornment="kWt">With Adornment</MenuItem>
        </Menu>
      </>
    );
  };

  const GenerateTooltips = () => {
    return (
      <div className="icons__buttons">
        <Tooltip content="tooltip content">
          <SVG icon="InfoIcon" fontSize="15px" />
        </Tooltip>
        <Tooltip arrow content="Multiple line content very long very long text">
          <Button>Long content with arrow</Button>
        </Tooltip>
        <Tooltip
          arrow
          content="Multiple line content very long very long text"
          theme="light"
        >
          <Button variant="outlined">Light theme with arrow</Button>
        </Tooltip>
        <div style={{ width: '100%' }}></div>

        <Tooltip
          arrow
          followCursor
          content="Multiple line content very long very long text"
          theme="light"
        >
          <Button variant="contained">Light theme with follow cursor</Button>
        </Tooltip>
      </div>
    );
  };

  const GenerateRadioButtons = () => {
    const options = [
      {
        value: 'Default',
        label: 'Default',
        disabled: false,
      },
      {
        value: 'Selected',
        label: 'Selected',
        disabled: false,
        tooltip: 'Some tooltip message',
      },
    ];
    const options2 = [
      {
        value: '3',
        label: 'Disabled',
        disabled: true,
      },
      {
        value: '4',
        label: 'Disabled selected',
        disabled: true,
        tooltip: 'Some tooltip message',
      },
    ];

    return (
      <>
        <div className="icons__buttons">
          <RadioButtons
            options={options}
            id="example-group"
            onChange={(value: any) =>
              console.log('Selected radio value is', value)
            }
            defaultSelected={options[1]}
            label="Options example"
            helperText="Some helper text"
          />
        </div>
        <div className="icons__buttons">
          <RadioButtons
            options={options2}
            id="example-group"
            onChange={(value: any) =>
              console.log('Selected radio value is', value)
            }
            defaultSelected={options2[1]}
            label="Options example 2"
          />
        </div>
      </>
    );
  };

  const GenerateDatePicker = () => {
    const [state, setState] = useState<DateTime | undefined>(DateTime.local());

    return (
      <>
        <div className="icons">
          <div className="icons__item">
            <DatePicker
              isClearable
              label="Typing enabled"
              value={state || null}
              onChange={setState}
            />
          </div>
          <div className="icons__item">
            <DatePicker
              isClearable
              disableTyping
              label="Typing disabled"
              value={state || null}
              onChange={setState}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="wrapper" style={{ overflow: 'auto', padding: 40 }}>
      {GenerateMenu()}
      {GenerateButtons()}
      {GenerateIconButtons()}
      {GenerateIcons()}
      {GenerateSwitches()}
      <DemoStepper />
      {GenerateTimePicker()}
      <CheckboxesContent />
      {GenerateMenu()}
      {GenerateTooltips()}
      {GenerateRadioButtons()}
      {GenerateDatePicker()}
    </div>
  );
};

export default Icons;
