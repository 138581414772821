import { AxiosError } from 'axios';
import React, { useState, Fragment } from 'react';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/Button';
// eslint-disable-next-line custom-rules/deprecated-component
import OldCheckbox from 'components/OldCheckbox';

import './AssetLMPDSettings.scss';

type AssetLMPSettingsProps = {
  assetID?: string;
  programID?: string;
};

const AssetLMPDSettings = ({ assetID, programID }: AssetLMPSettingsProps) => {
  const [edited, setEdited] = useState<any>({});

  const { data: marketInfo = {}, loading } = useRequestEffect<any>({
    url: `/api/dsp/program/${programID}/asset_market_info/${assetID}`,
    method: 'get',
    toast: {
      error:
        'There was an unexpected error loading the Market Info for this Asset.',
    },
  });

  const { makeRequest: saveMarketInfoReq, loading: saving } = useRequest(
    `/api/dsp/program/${programID}/asset_market_info/${assetID}`
  );

  const updateField = (key: string, value: any) => {
    setEdited({
      ...edited,
      [key]: value,
    });
  };

  const saveMarketInfo = async () =>
    saveMarketInfoReq({
      method: 'patch',
      body: edited,
      toast: {
        success: 'Successfully updated Market Info',
        error: ({ code }: AxiosError) =>
          code === '500' &&
          'There was an unexpected error updating your Market Info',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => setEdited({}),
    });

  return (
    <div className="asset-lmpd-settings">
      <div className="asset-lmpd-settings-header">
        <Button
          disabled={!Object.entries(edited).length || saving}
          onClick={saveMarketInfo}
        >
          Save
        </Button>
      </div>
      <div className="section">
        {loading && <LoadingSpinner />}
        {!loading && (
          <Fragment>
            <div className="row">
              <OldCheckbox
                checked={
                  Object.prototype.hasOwnProperty.call(edited, 'is_renewable')
                    ? edited.is_renewable
                    : marketInfo.is_renewable
                }
                disabled={saving}
                onClick={(e) =>
                  updateField('is_renewable', e.currentTarget.checked)
                }
              />
              Is Renewable?
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AssetLMPDSettings;
