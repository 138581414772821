import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Route } from 'react-router-dom';
import {
  useRequestEffect,
  useAuthContext,
  useRequest,
} from '@opusonesolutions/gridos-app-framework';

import { useUserContext } from 'contexts/UserContext';

import { Tenant } from 'types/tenant';

import LoadingSpinner from 'components/LoadingSpinner';
import RouterSwitch from 'components/RouterSwitch';
import NavButtons from 'components/NavButtons';
import Button from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';
import SVG from 'components/SVG';

import {
  participantsAndDerRoutes,
  placeHolderRow,
  tenantRoutes,
  sortTenants,
} from './Participants.logic';
import { ParticipantsDersHeader } from 'routes/ParticipantsAndDers/Components/ParticipantsDersHeader';
import { ParticipantsTable } from 'routes/ParticipantsAndDers/Components/ParticipantsTable.tsx';
import ParticipantCreate from './Routes/ParticipantCreate';
import ParticipantOverview from './Routes/ParticipantOverview';
import ParticipantDers from './Routes/ParticipantDers';
import ParticipantUsers from './Routes/ParticipantUsers';

import './Participants.scss';

export type columns = 'marketParticipant' | 'accountType' | 'serviceType';

export enum Routes {
  overview = '/participants-and-ders/participants/:tenantId/overview',
  edit = '/participants-and-ders/participants/:tenantId/overview/edit',
  users = '/participants-and-ders/participants/:tenantId/users',
  ders = '/participants-and-ders/participants/:tenantId/ders',
  create = '/participants-and-ders/participants/create',
}

const showColumns: columns[] = [
  'marketParticipant',
  'accountType',
  'serviceType',
];

export const Participants = () => {
  const history = useHistory();
  const { hasAllPermissions } = useAuthContext();
  const { user: loggedInUser, userIsDso } = useUserContext();
  const isDso = userIsDso();
  const { tenantId } = useParams<{ tenantId: string }>();

  const [tenant, setTenant] = useState<Tenant | undefined>();
  const [tenants, setTenants] = useState<Tenant[] | undefined>();
  const [showConfirmation, setConfirmation] = useState<boolean>(false);
  const [isCreatingNewTenant, setIsCreatingNewTenant] =
    useState<boolean>(false);

  const closeConfirmation = () => setConfirmation(false);
  const openConfirmation = () => setConfirmation(true);

  const canAddDER = hasAllPermissions(['update_market_der']);

  const { loading, refetch: refetchTenants } = useRequestEffect<Tenant[]>({
    url: '/api/dsp/tenants',
    initialData: [],
    method: 'get',
    refetchOnChange: [],
    toast: {
      error: 'Failed to load participant list.',
      settings: {
        autoDismiss: true,
      },
    },
    onSuccess: (data) => {
      setTenants(data);
    },
  });

  const { makeRequest: makeCancelRequest } = useRequest(
    `/api/dsp/tenants/${tenant?.id}`
  );

  const deleteTenant = async () => {
    await makeCancelRequest({
      method: 'delete',
      toast: {
        success: `${
          tenant?.name || 'Participant'
        } has been deleted from the Neutral Market Facilitator.`,
        error: `Error deleting ${tenant?.name || 'participant'}.`,
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        refetchTenants();
        closeConfirmation();
        if (tenants) {
          const index = tenants.findIndex((t) => t.id === tenant?.id);
          const nextIndex =
            index === 0 ? index + 1 : index && index > 0 ? index - 1 : 0;
          setTenant(tenants[nextIndex]);
          history.push(
            `/participants-and-ders/participants/${tenants[nextIndex].id}/overview`
          );
        }
      },
      onError: () => {
        closeConfirmation();
      },
    });
  };

  const canEditTenant =
    (isDso || loggedInUser?.tenants[0]?.id === tenant?.id) &&
    hasAllPermissions(['update_tenant']);

  const canDeleteTenant = isDso && loggedInUser?.tenants[0]?.id !== tenant?.id;

  function updateTenant(updatedTenant: Tenant) {
    setTenant(updatedTenant);
    if (tenants) {
      const index = tenants?.findIndex((t) => t.id === updatedTenant.id);
      const newTenants = tenants;
      newTenants[index] = updatedTenant;
      setTenants([...newTenants]);
    }
  }

  function showCreateNewParticipant() {
    setIsCreatingNewTenant(true);
    history.push(Routes.create);
  }

  useEffect(() => {
    if (isCreatingNewTenant) {
      setTenant(undefined);
    }
  }, [isCreatingNewTenant]);

  useEffect(() => {
    if (tenant) {
      setIsCreatingNewTenant(false);
    }
  }, [tenant]);

  useEffect(() => {
    if (tenantId && !tenant) {
      const selectedTenant = tenants?.find((tenant) => tenantId === tenant.id);
      setTenant(selectedTenant);
    }
  }, [tenantId, tenant, tenants]);

  useEffect(() => {
    if (!isDso && loggedInUser) {
      if (loggedInUser.tenants[0]?.id !== tenantId || !tenantId) {
        history.push(
          `/participants-and-ders/participants/${loggedInUser.tenants[0]?.id}/overview`
        );
      }
    }
  }, [loggedInUser, isDso, tenantId, history]);

  sortTenants(tenants);

  function renderSubPageRouting() {
    if (tenant) {
      return (
        <RouterSwitch>
          <Route path={Routes.overview}>
            <ParticipantOverview
              tenant={tenant}
              setTenant={updateTenant}
              canEdit={canEditTenant}
            />
          </Route>
          <Route exact path={Routes.users}>
            <ParticipantUsers
              tenant={tenant}
              setTenant={updateTenant}
              canEdit={canEditTenant}
            />
          </Route>
          <Route exact path={Routes.ders}>
            <ParticipantDers
              canAddDER={canEditTenant && canAddDER}
              tenant={tenant}
              setTenant={updateTenant}
              refetchTenants={refetchTenants}
            />
          </Route>
          <Route path={Routes.create}>
            <ParticipantCreate
              setIsCreatingNewTenant={setIsCreatingNewTenant}
              setCurrentTenant={setTenant}
              refetchTenants={refetchTenants}
            />
          </Route>
        </RouterSwitch>
      );
    } else {
      return (
        <Route path={Routes.create}>
          <ParticipantCreate
            setIsCreatingNewTenant={setIsCreatingNewTenant}
            setCurrentTenant={setTenant}
            refetchTenants={refetchTenants}
          />
        </Route>
      );
    }
  }

  function renderIATenantView() {
    if (tenant) {
      return (
        <>
          <div className="participants__right participants__right-ia">
            <NavButtons
              baseRoute={`/participants-and-ders/participants/${tenant.id}`}
              routes={tenantRoutes}
              classNames={{
                navClass: 'participants__ia-nav',
              }}
            />
            <div className="participants__right-details">
              {renderSubPageRouting()}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="participants__right participants__right-ia">
            <div className="participants__right-details">
              <span className="participants__right-placeholder-text">
                You do not belong to any tenant.
              </span>
            </div>
          </div>
        </>
      );
    }
  }

  function renderDSOTenantsView() {
    return (
      <>
        <div className="participants__left">
          <NavButtons
            baseRoute={`/participants-and-ders`}
            routes={participantsAndDerRoutes}
          />
          {loading && <LoadingSpinner />}
          {!loading && tenants && (
            <>
              <Button
                onClick={() => showCreateNewParticipant()}
                variant="text"
                disabled={isCreatingNewTenant}
                startIcon={<SVG icon="PlusCircleIcon" fontSize="16px" />}
                margin="12px 0 0 0"
              >
                Add new participant
              </Button>
              <ParticipantsTable
                data={tenants || []}
                placeHolderRow={() =>
                  placeHolderRow(showColumns?.length, loading)
                }
                showColumns={showColumns}
                setParticipant={setTenant}
              />
            </>
          )}
        </div>
        <div className="participants__right">
          <div className="participants__right-actions">
            {tenant && canEditTenant && (
              <Button
                variant="text"
                onClick={openConfirmation}
                customClasses={{
                  customButtonClass: 'participants__action',
                }}
                disabled={!canDeleteTenant}
                startIcon={<SVG icon="Trash1Icon" fontSize="16px" />}
              >
                <span className="participants__action-text">
                  Delete participant
                </span>
              </Button>
            )}
            {tenant && (
              <Button
                variant="text"
                customClasses={{
                  customButtonClass: 'participants__action',
                }}
                startIcon={<SVG icon="PinIcon" fontSize="16px" />}
                disabled
              >
                <span className="participants__action-text">
                  Pin participant
                </span>
              </Button>
            )}
          </div>
          <div className="participants__right-details">
            {tenant ? (
              <>
                <NavButtons
                  baseRoute={`/participants-and-ders/participants/${tenant.id}`}
                  routes={tenantRoutes}
                />
                {renderSubPageRouting()}
              </>
            ) : !isCreatingNewTenant ? (
              <span className="participants__right-placeholder-text">
                Select a participant for participant and DER details
              </span>
            ) : (
              <>{renderSubPageRouting()}</>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="participants">
      <ParticipantsDersHeader />
      <div className="participants__content">
        {loggedInUser?.tenants && isDso
          ? renderDSOTenantsView()
          : renderIATenantView()}
      </div>
      <ConfirmationDialog
        open={showConfirmation}
        title="Delete Participant?"
        body={`Are you sure you want to delete participant ${
          tenant?.name || ''
        }? This action is permenant and cannot be undone. To add the participant back, you must add a new participant.`}
        onClose={closeConfirmation}
        onConfirm={deleteTenant}
        cancelText="Dismiss"
        confirmText="Yes, delete participant"
      />
    </div>
  );
};
