import React from 'react';
import { Route } from 'react-router-dom';

import { env } from 'helpers/env';

import RouterSwitch from 'components/RouterSwitch';
import FeederSettings from './routes/FeederSettings';
import AssetSettings from './routes/AssetSettings';
import Enrollment from './components/Enrollment';

const { isWSC } = env;

const EnrollmentRoutes = () => (
  <RouterSwitch>
    <Route exact path="/program/:programID/enrollment">
      <Enrollment />
    </Route>
    <Route exact path="/program/:programID/enrollment/feeder/:feederID">
      <FeederSettings />
    </Route>
    {!isWSC && (
      <Route
        exact
        path="/program/:programID/enrollment/asset/:feederID/:assetID"
      >
        <AssetSettings />
      </Route>
    )}
  </RouterSwitch>
);

export default EnrollmentRoutes;
