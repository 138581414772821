import { DateTime } from 'luxon';
import React, { useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import ChartWrapper from 'components/ChartWrapper';
import DateRangePicker from 'components/DateRangePicker';
import FileForm from 'components/FileForm';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import Select from 'components/OldSelect';

import { ChartData } from 'chart.js';

import fileExportSave from 'helpers/downloadFile';
import useQueryState, {
  getDateFromParam,
  serializeDate,
} from 'hooks/useQueryState';

import { WeatherStation, WeatherData } from 'types/weather';

import './WeatherStation.scss';
import 'components/Button/Button.scss';

const DATA_TYPES = [
  { label: 'Forecast', value: 'forecast' },
  { label: 'Observed', value: 'observation' },
];
const FIELD_VALUES = [
  { label: 'Temperature', value: 'temperature' },
  { label: 'Dew Point', value: 'dew_point' },
  { label: 'Feels Like', value: 'feels_like' },
  { label: 'Wind Chill', value: 'wind_chill' },
  { label: 'Humidity', value: 'humidity' },
  { label: 'Wet Bulb Temperature', value: 'wet_bulb_temperature' },
  { label: 'Wind Speed', value: 'wind_speed' },
  { label: 'Gusting Wind Speed', value: 'wind_speed_gust' },
  { label: 'Wind Direction', value: 'wind_direction' },
  { label: 'Ceiling', value: 'ceiling' },
  { label: 'Visibility', value: 'visibility' },
  { label: 'Cloud Coverage', value: 'cloud_coverage' },
  { label: 'Pressure', value: 'pressure' },
  { label: 'Beam Horizontal Irradiance', value: 'irradiance_beam_horizontal' },
  {
    label: 'Diffuse Horizontal Irradiance',
    value: 'irradiance_diffuse_horizontal',
  },
  { label: 'Direct Normal Irradiance', value: 'irradiance_direct_normal' },
  {
    label: 'Global Horizontal Irradiance',
    value: 'irradiance_global_horizontal',
  },
];

const FIELD_DATA: {
  [key: string]: { label: string; unit: string; suggestedMin?: number };
} = {
  ceiling: {
    label: 'Ceiling',
    unit: 'm',
  },
  cloud_coverage: {
    label: 'Cloud Coverage',
    unit: '%',
  },
  dew_point: {
    label: 'Dew Point Temperature',
    unit: '°C',
  },
  feels_like: {
    label: 'Feels Like Temperature',
    unit: '°C',
  },
  humidity: {
    label: 'Humidity',
    unit: '%',
  },
  irradiance_beam_horizontal: {
    label: 'Beam Horizontal Irradiance',
    unit: 'W / m²',
  },
  irradiance_diffuse_horizontal: {
    label: 'Diffuse Horizontal Irradiance',
    unit: 'W / m²',
  },
  irradiance_direct_normal: {
    label: 'Direct Normal Irradiance',
    unit: 'W / m²',
  },
  irradiance_global_horizontal: {
    label: 'Global Horizontal Irradiance',
    unit: 'W / m²',
  },
  pressure: {
    label: 'Pressure',
    // Lowest every recorded barometric pressure
    suggestedMin: 86.9,
    unit: 'kPa',
  },
  temperature: {
    label: 'Temperature',
    unit: '°C',
  },
  visibility: {
    label: 'Visibility',
    unit: 'm',
  },
  wet_bulb_temperature: {
    label: 'Wet Bulb Temperature',
    unit: '°C',
  },
  wind_chill: {
    label: 'Wind Chill Temperature',
    unit: '°C',
  },
  wind_direction: {
    label: 'Wind Direction',
    unit: 'degrees',
  },
  wind_speed: {
    label: 'Wind Speed',
    unit: 'm/s',
  },
  wind_speed_gust: {
    label: 'Gusting Wind Speed',
    unit: 'm/s',
  },
};

const WeatherStationComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [startDate, setStartDate] = useQueryState(
    getDateFromParam(params, 'startDate', DateTime.local().startOf('day')),
    'startDate',
    serializeDate
  );
  const [endDate, setEndDate] = useQueryState(
    getDateFromParam(params, 'endDate', DateTime.local().endOf('day')),
    'endDate',
    serializeDate
  );
  const [dataType, setDataType] = useQueryState(
    params.get('dataType') || 'forecast',
    'dataType'
  );
  const [field, setField] = useQueryState(
    params.get('field') || 'temperature',
    'field'
  );

  // This state is used for the upload modal
  const [uploadDataType, setUploadDataType] = useState('forecast');
  const [fileData, setFileData] = useState(new FormData());
  const [filename, setFilename] = useState('');
  const [hasUploadData, setHasUploadData] = useState(false);
  const [inUploadMode, setInUploadMode] = useState(false);
  const uploadFormRef = useRef<HTMLFormElement | null>(null);

  const { stationName } = useParams<{ stationName: string }>();
  const { data: station, loading: loadingStation } =
    useRequestEffect<WeatherStation>({
      url: `/api/dsp/weather/station/name/${stationName}`,
      refetchOnChange: [stationName],
      method: 'get',
      toast: {
        error: `Could not load station with name '${stationName}'.`,
      },
    });

  const {
    data: weatherData,
    loading: loadingData,
    refetch: refetchWeatherData,
  } = useRequestEffect<WeatherData[]>({
    url: `/api/dsp/weather/station/${station?.id}/${dataType}`,
    blockRequest: () => !station,
    refetchOnChange: [endDate, station, startDate, dataType],
    method: 'get',
    params: {
      start_time: startDate.toISO(),
      end_time: endDate.endOf('day').toISO(),
    },
    toast: {
      error: `Could not load data for '${startDate.toISO()}' to '${endDate.toISO()}'.`,
    },
  });

  const { loading: downloading, makeRequest: runDownload } = useRequest(
    `/api/dsp/weather/station/${station?.id}/${dataType}/export`
  );
  const exportData = async () => {
    await runDownload({
      method: 'get',
      onSuccess: (data, headers) => {
        fileExportSave(data, headers);
      },
      // Axios params
      params: {
        start_time: startDate.toISO(),
        end_time: endDate.endOf('day').toISO(),
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const chartData = useMemo(() => {
    const data: ChartData = {
      datasets: [],
      labels: [],
    };

    if (weatherData) {
      data.datasets.push({
        data: weatherData.map((d) => ({
          x: DateTime.fromISO(d.time).valueOf(),
          // @ts-ignore
          y: d[field],
        })),
        label: FIELD_DATA[field].label,
        stepped: 'before',
        fill: false,
        backgroundColor: '#00467F',
        borderColor: '#00467F',
      });
    }

    return data;
  }, [weatherData, field]);

  const fieldData = FIELD_DATA[field];

  const cancelUpload = () => {
    setInUploadMode(false);
    setFileData(new FormData());
    setFilename('');
    setHasUploadData(false);

    if (uploadFormRef.current) {
      uploadFormRef.current.reset();
    }
  };

  const { makeRequest: runUpload, loading: uploading } = useRequest(
    `/api/dsp/weather/station/${station?.id}/${uploadDataType}`
  );

  const uploadWeatherData = async () => {
    await runUpload({
      method: 'post',
      body: fileData,
      onSuccess: () => {
        refetchWeatherData();
        cancelUpload(); // exits upload mode
      },
      toast: {
        error: (error) => {
          let message = 'Could not upload weather data.';
          const response = error.response;

          if (response && response.status === 400) {
            // Bad data provided by the user. Let's try and show the user a good message
            if (response.data && response.data.message) {
              // Error could be on any row. Pick the first we find
              const rowKeys = [...Object.keys(response.data.message)];

              if (rowKeys.length > 0) {
                const row = rowKeys[0];
                const rowData = response.data.message[row];
                const fieldKeys = [...Object.keys(rowData)];

                if (fieldKeys.length > 0) {
                  // Pick the first message for the first field
                  const field = fieldKeys[0];
                  message = `CSV validation failed at row ${row}. Error for field '${field}': '${rowData[field][0]}'`;
                }
              }
            }
          }

          return message;
        },
        success: `Successfully uploaded weather ${uploadDataType} data.`,
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  return (
    <HeaderLayout
      className="weather-station"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
            {
              to: '/measurements/weather',
              label: <h2 className="title">Weather Stations</h2>,
            },
          ]}
          separator="/"
          currentHeader={station ? station.name : ''}
        />
      }
      titleRightContent={
        <Button disabled={inUploadMode} onClick={() => setInUploadMode(true)}>
          Upload Weather Data
        </Button>
      }
    >
      <div className="control-container">
        <DateRangePicker
          endDate={endDate}
          onClose={(startDate, endDate) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          startDate={startDate}
        />
        <div className="select-container">
          <Select
            isClearable={false}
            isMulti={false}
            label="Data Type"
            onChange={(opt) => setDataType(opt.value)}
            options={DATA_TYPES}
            row
            value={DATA_TYPES.find((opt) => opt.value === dataType)}
          />
        </div>
        <div className="select-container">
          <Select
            isClearable={false}
            isMulti={false}
            label="Measurement"
            onChange={(opt) => setField(opt.value)}
            options={FIELD_VALUES}
            row
            value={FIELD_VALUES.find((opt) => opt.value === field)}
          />
        </div>
        <IconButton
          disabled={downloading}
          icon="get_app"
          onClick={() => exportData()}
          tooltip="Download Weather Data"
        />
      </div>
      {!loadingStation && !loadingData && (
        <ChartWrapper
          config={{
            type: 'line',
            data: chartData,
            options: {
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  intersect: false,
                  mode: 'nearest',
                  axis: 'x',
                  callbacks: {
                    title: (tooltipItems) => {
                      let title = '';
                      if (tooltipItems.length > 0) {
                        const item = tooltipItems[0];
                        const dateTime = DateTime.fromMillis(
                          item.parsed.x
                        ).setZone(startDate.zone.name);
                        title = dateTime.toFormat('DDD hh:mm:ss a');
                      }

                      return title;
                    },
                    label: (tooltipItem) => {
                      const { parsed, dataset } = tooltipItem;
                      let value = parsed.y;
                      value = Math.round(value * 100) / 100;
                      return `${dataset.label}: ${value.toLocaleString()} ${
                        fieldData.unit
                      }`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  adapters: {
                    date: {
                      zone: startDate.zone.name,
                    },
                  },
                  min: startDate.startOf('day').valueOf(),
                  max: endDate.startOf('day').plus({ days: 1 }).valueOf(),
                  type: 'time',
                  offset: true,
                  title: {
                    display: true,
                    text: `Time (${startDate.zone.name})`,
                  },
                  ticks: {
                    major: {
                      enabled: true,
                    },
                    source: 'auto',
                    autoSkip: true,
                    autoSkipPadding: 75,
                    maxRotation: 0,
                    sampleSize: 100,
                  },
                },
                y: {
                  suggestedMin: fieldData.suggestedMin || 0,
                  suggestedMax: 1,
                  grid: {
                    drawBorder: false,
                  },
                  title: {
                    display: true,
                    text: `${fieldData.label} ${fieldData.unit}`,
                  },
                },
              },
            },
          }}
        />
      )}
      <Modal
        active={inUploadMode}
        hideClose
        cancelProps={{ disabled: uploading }}
        confirmProps={{
          disabled: !hasUploadData || uploading,
          onClick: uploadWeatherData,
          label: uploading ? (
            <i className="material-icons rotating-icon">refresh</i>
          ) : (
            'Save'
          ),
        }}
        height="250px"
        onClose={cancelUpload}
        title="Upload Forecast Data"
        width="300px"
      >
        <div className="upload-form">
          <Select
            isClearable={false}
            isMulti={false}
            label="Data Type"
            onChange={(opt) => setUploadDataType(opt.value)}
            options={DATA_TYPES}
            value={
              uploadDataType === 'forecast' ? DATA_TYPES[0] : DATA_TYPES[1]
            }
          />
          <FileForm
            accept="*.csv"
            id="upload"
            onChange={(e) => {
              //@ts-ignore
              const { files } = e.target;

              // If there not exactly one files attached, do nothing
              if (files.length !== 1) return;

              // Only 1 file should exist / only the first file matters
              const file = files[0];

              // Assume .csv file is valid, allow API to reject
              const csvKey = 'weather.csv';
              fileData.set(csvKey, file);
              setFilename(file.name);
              setHasUploadData(true);
            }}
            createRef={(form) => (uploadFormRef.current = form)}
          >
            <Button
              customClasses={{
                customButtonClass: 'button--non-interactive',
              }}
            >
              Select file
            </Button>
          </FileForm>
          <div className="filename">{filename}</div>
        </div>
      </Modal>
    </HeaderLayout>
  );
};

export default WeatherStationComponent;
