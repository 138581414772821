import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';
import { DateTime } from 'luxon';
import { ChartData } from 'chart.js';

import useQueryState, {
  getDateFromParam,
  serializeDate,
} from 'hooks/useQueryState';

import {
  useMeasurementsContext,
  TimeseriesTag,
  TagValue,
} from 'contexts/MeasurementContext';

import Breadcrumbs from 'components/Breadcrumbs';
import ChartWrapper from 'components/ChartWrapper';
import DateRangePicker from 'components/DateRangePicker';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
import Select from 'components/OldSelect';

import fileExportSave from 'helpers/downloadFile';

import './Feeds.scss';

interface SelectOption {
  label: string;
  value: any;
}

const Feeds = () => {
  // Load the timeseries, tag, start, and end dates from query params
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [timeseriesID, setTimeseriesID] = useQueryState<string | null>(
    params.get('timeseries'),
    'timeseries'
  );
  const [tagID, setTagID] = useQueryState<string | null>(
    params.get('tag'),
    'tag'
  );
  const [timeseriesName, setTimeseriesName] = useState<string | null>(null);
  const [tagOptions, setTagOptions] = useState<Array<SelectOption>>([]); // Options passed to point select

  const [endDate, setEndDate] = useQueryState(
    getDateFromParam(params, 'endDate', DateTime.local().endOf('day')).endOf(
      'day'
    ),
    'endDate',
    serializeDate
  );
  const [startDate, setStartDate] = useQueryState(
    getDateFromParam(params, 'startDate', DateTime.local().startOf('day')),
    'startDate',
    serializeDate
  );

  const { getTimeseries } = useMeasurementsContext();
  const timeseriesOptions = getTimeseries().map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const { data: tags, loading: tagsLoading } = useRequestEffect<
    TimeseriesTag[]
  >({
    url: `/api/dsp/measurements/timeseries/${timeseriesID}/tags`,
    initialData: [],
    method: 'get',
    refetchOnChange: [timeseriesID],
    toast: {
      error: `Failed to load tags for ${timeseriesName} feed.`,
      settings: {
        autoDismiss: true,
      },
    },
    blockRequest: () => timeseriesID === null,
    dataTransform: (data: Array<TimeseriesTag>) => {
      const sorter = Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      data.sort((a: TimeseriesTag, b: TimeseriesTag) =>
        sorter.compare(a.name, b.name)
      );
      return data;
    },
    onSuccess: (data) => {
      if (data) {
        setTagOptions(data.map((tag) => ({ label: tag.name, value: tag.id })));

        if (tagID === null) {
          setTagID(data.length ? data[0].id : null);
        }
      }
    },
  });

  const tag = tags?.find((tag) => tag.id === tagID);

  const { data: chartData, loading: tagValuesLoading } = useRequestEffect<
    ChartData<'bar'>
  >({
    url: `/api/dsp/measurements/timeseries/${timeseriesID}/tag/${tagID}/by_time`,
    initialData: { datasets: [], labels: [] },
    method: 'get',
    refetchOnChange: [timeseriesID, tagID, startDate, endDate],
    params: {
      start_time: startDate.startOf('day').toISO(),
      end_time: endDate.endOf('day').toISO(),
    },
    blockRequest: () => timeseriesID === null || tagID === null,
    dataTransform: (data) => {
      return {
        datasets: [
          {
            data: data.map((val: TagValue) => {
              const x = DateTime.fromISO(val.time_sampled).valueOf();
              const y = val.value;

              return { x, y };
            }),
            label: tag?.name || 'Value',
            backgroundColor: '#00467F',
            borderColor: '#00467F',
          },
        ],
        labels: [],
      };
    },
  });

  const { loading: downloading, makeRequest: runExport } = useRequest(
    `/api/dsp/measurements/timeseries/${timeseriesID}/tag/${tagID}/by_time/export`
  );
  const exportData = async () => {
    await runExport({
      method: 'get',
      onSuccess: (data, headers) => {
        fileExportSave(data, headers);
      },

      // Axios params
      params: {
        start_time: startDate.startOf('day').toISO(),
        end_time: endDate.endOf('day').toISO(),
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  return (
    <HeaderLayout
      className="measurement-feeds"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
          ]}
          separator="/"
          currentHeader="Data Feeds"
        />
      }
    >
      <div className="input-container">
        <div className="input-row">
          <div className="input-wrapper">
            <Select
              isMulti={false}
              isDisabled={tagsLoading || tagValuesLoading}
              isClearable
              label="Timeseries"
              options={timeseriesOptions}
              onChange={(opt) => {
                setTimeseriesID(opt !== null ? opt.value : null);
                setTimeseriesName(opt !== null ? opt.label : null);
                if (!opt) {
                  setTagID(null);
                }
              }}
              row
              value={
                timeseriesOptions
                  ? timeseriesOptions.find(
                      (opt: SelectOption) => opt.value === timeseriesID
                    )
                  : null
              }
            />
          </div>
          <div className="input-wrapper">
            <Select
              isMulti={false}
              isDisabled={tagsLoading || tagValuesLoading}
              isClearable
              label="Tag"
              onChange={(opt) => {
                if (opt === null) {
                  setTagID(null);
                } else {
                  setTagID(opt.value);
                }
              }}
              options={tagOptions}
              row
              value={
                tagID !== null
                  ? tagOptions.find(({ value }) => value === tagID)
                  : null
              }
            />
          </div>
          <div className="date-wrapper">
            <label>Date</label>
            <DateRangePicker
              endDate={endDate}
              startDate={startDate}
              onClose={(start, end) => {
                setStartDate(start);
                setEndDate(end);
              }}
            />
          </div>
          <IconButton
            disabled={downloading}
            icon="get_app"
            onClick={() => exportData()}
            tooltip="Download data"
          />
        </div>
      </div>
      <div className="chart-container">
        {tag && chartData && (
          <ChartWrapper
            config={{
              type: 'bar',
              data: chartData,
              options: {
                maintainAspectRatio: false,
                scales: {
                  x: {
                    min: startDate.startOf('day').valueOf(),
                    max: endDate.startOf('day').plus({ days: 1 }).valueOf(),
                    type: 'time',
                    offset: true,
                    ticks: {
                      major: {
                        enabled: true,
                      },
                      source: 'auto',
                      autoSkip: true,
                      autoSkipPadding: 75,
                      maxRotation: 0,
                      sampleSize: 100,
                    },
                  },
                  y: {
                    suggestedMin: 0,
                    grid: {
                      drawBorder: false,
                    },
                    title: {
                      display: true,
                      text: `${tag.measurement_type} ${tag.unit_symbol}`,
                    },
                    ticks: {
                      // @ts-expect-error
                      callback: (v: number) => {
                        let power = Math.floor(Math.log10(v));
                        power = power - (power % 3);
                        let scale;
                        let multiplier = 1;
                        switch (power) {
                          case 3:
                            scale = 'k';
                            multiplier = 1e3;
                            break;
                          case 6:
                            scale = 'M';
                            multiplier = 1e6;
                            break;
                          case 9:
                            scale = 'G';
                            multiplier = 1e9;
                            break;
                          case 12:
                            scale = 'T';
                            multiplier = 1e12;
                            break;
                          default:
                            scale = '';
                            multiplier = 1;
                        }
                        return `${(v / multiplier).toFixed(2)} ${scale}${
                          tag.unit_symbol
                        }`;
                      },
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
    </HeaderLayout>
  );
};

export default Feeds;
