import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useMeasurementsContext } from 'contexts/MeasurementContext';

import Breadcrumbs from 'components/Breadcrumbs';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';

import './Timeseries.scss';

const Timeseries = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');

  const { addTimeseries, deleteTimeseries, getTimeseries } =
    useMeasurementsContext();
  const timeseries = getTimeseries();

  return (
    <HeaderLayout
      className="timeseries"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
          ]}
          separator="/"
          currentHeader="Timeseries"
        />
      }
      titleRightContent={
        <Button disabled={modalOpen} onClick={() => setModalOpen(true)}>
          Add New Timeseries
        </Button>
      }
    >
      <div className="timeseries-list">
        {timeseries.map((ts) => (
          <div className="timeseries-list-item" key={ts.id}>
            <span className="name">
              <Link to={`/measurements/timeseries/${ts.id}`}>{ts.name}</Link>
            </span>
            <IconButton
              icon="delete"
              onClick={() => deleteTimeseries(ts.id)}
              tooltip="Delete timeseries"
            />
          </div>
        ))}
      </div>
      <Modal
        active={modalOpen}
        hideClose
        confirmProps={{
          disabled: !name,
          label: 'Save',
          onClick: async () => {
            await addTimeseries(name);
            setModalOpen(false);
            setName('');
          },
        }}
        onClose={() => setModalOpen(false)}
        title="Enter New Timeseries Name"
        width="300px"
      >
        <TextInput
          id="name"
          onChange={(value) => setName(value)}
          value={name}
        />
      </Modal>
    </HeaderLayout>
  );
};

export default Timeseries;
