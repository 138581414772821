import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useUserContext } from 'contexts/UserContext';

import { flexContract } from 'types/contract-managment';
import { CurrentUser } from 'types/user';

import LoadingSpinner from 'components/LoadingSpinner';
import contractFilters from 'routes/LongTermContracts/helpers/contractFilters';
import ContractsTable from 'routes/LongTermContracts/ContractsTable';
import ContractsTableHeader from 'routes/LongTermContracts/components/ContractsTableHeader';
import { columns } from 'routes/LongTermContracts/ContractsTable/ContractsTable.logic';

import './P2PContracts.scss';

interface p2pContractsParams {
  contracts: flexContract[] | undefined;
  isLoading: boolean;
  loggedInUser: CurrentUser;
  refetchContracts: () => void;
  gateTime?: DateTime;
}

const P2PContracts = ({
  contracts,
  isLoading,
  loggedInUser,
  refetchContracts,
  gateTime,
}: p2pContractsParams) => {
  const { programID } = useParams<{ programID: string }>();
  const { userIsDso } = useUserContext();
  const isDso = userIsDso();
  const tenant = loggedInUser.tenants[0];

  const slicedContracts = useMemo(
    () => contractFilters('p2p', tenant.id, isLoading, isDso, contracts),
    [contracts, isDso, isLoading, tenant.id]
  );

  const contractTableColumns: columns[] = [
    'bidOrOffer',
    'servicePeriod',
    'serviceWindow',
    'serviceType',
    'quantity',
    'availability',
    'requestor',
    'responder',
    'updates',
    'csa',
    'status',
    'actions',
  ];

  function placeHolderRow() {
    return (
      <tr className="contracts-table__row">
        <td
          className="contracts-table__cell active-contracts__placeholder-text"
          colSpan={contractTableColumns.length}
        >
          <div style={{ width: 'fit-content', margin: 'auto' }}>
            {!isLoading && 'There is no data to display.'}
            {isLoading && <LoadingSpinner />}
          </div>
        </td>
      </tr>
    );
  }

  return (
    <div className="active-contracts">
      <ContractsTableHeader />
      <ContractsTable
        showPSATime
        data={slicedContracts}
        showColumns={contractTableColumns}
        programID={programID}
        loggedInUser={loggedInUser}
        placeHolderRow={placeHolderRow}
        refetchContracts={refetchContracts}
        gateTime={gateTime}
      />
    </div>
  );
};

export default P2PContracts;
