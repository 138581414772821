import React from 'react';
import Select from 'components/Select';
import { SelectOption } from 'types';
import classNames from 'classnames';

type SelectCellProps = {
  options: SelectOption[];
  placeholder: string;
  defaultValue?: SelectOption;
  onChange: (value: string) => void;
  isError: boolean;
  value?: SelectOption | null;
};

const SelectCell = ({
  value,
  onChange,
  options,
  defaultValue,
  placeholder,
  isError,
}: SelectCellProps) => (
  <Select
    disablePortal={false}
    value={value}
    onChange={(e) => onChange(e.value)}
    options={options}
    defaultValue={defaultValue}
    isClearable={false}
    variant="outlined"
    placeholder={placeholder}
    classes={{
      wrapperClass: classNames('notification-table__select-cell', {
        'notification-table__select-cell--error': isError,
      }),
    }}
  />
);

export default SelectCell;
