import React, {
  useMemo,
  useState,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';
import { Cell, TableInstance } from 'react-table';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { Program } from 'contexts/ProgramsContext';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { flexItemStatus, serviceType } from 'types/contract-managment';
import { FlexPricingEvent } from 'types/pricing-events';
import { VintagedBidOffer } from 'types/vintaged-bid-offers';

import { convertNumberstoReadableTime } from 'helpers/time';
import { env } from 'helpers/env';
import quantityConverter from 'helpers/quantityConverter';

import Table from 'components/Table';
import EventsTableHeader from './EventsTableFilters';
import Tag from 'components/Tag';
import SVG from 'components/SVG';
import Tooltip from 'components/Tooltip';
import Light from 'components/Light';
import BidOfferTag from 'components/BidOfferTag';
import UserProfile from 'components/UserProfile';
import IconButton from 'components/IconButton';
import Switch from 'components/Switch';

import './EventsTable.scss';

export type EventsTableColumns =
  | 'time'
  | 'feeder'
  | 'bidOffer'
  | 'quantity'
  | 'quantityKVAR'
  | 'availability'
  | 'utilization'
  | 'responder'
  | 'arrow'
  | 'requestor'
  | 'status'
  | 'serviceType'
  | 'actions'
  | 'psaEligible'
  | 'clickIcon';

interface EventsTableProps {
  showColumns: EventsTableColumns[];
  data: FlexPricingEvent[] | VintagedBidOffer[];
  program: Program;
  dateFilterState: {
    filterDate: DateTime;
    setFilterDate: Dispatch<SetStateAction<DateTime>>;
  };
  clickableRows?: boolean;
  hideFilters?: boolean;
  tableHeader?: (instance: TableInstance) => JSX.Element;
  updateEvent?: (
    pricingEventID: string,
    state_type: string,
    reason: string
  ) => void;
  type?:
    | 'Intra Day Analysis'
    | 'DA Analysis'
    | 'WA Analysis'
    | 'Intent to Dispatch';
  downloadTable?:
    | ((
        startTime?: DateTime | undefined,
        endTime?: DateTime | undefined
      ) => void)
    | undefined;
}

//header and routing
function EventsTable({
  showColumns,
  data,
  program,
  clickableRows = false,
  dateFilterState,
  hideFilters,
  type = 'Intent to Dispatch',
  updateEvent,
  downloadTable,
}: EventsTableProps) {
  const { isWSC } = env;
  const [filterStartTime, setfilterStartTime] = useState<DateTime>();
  const [filterEndTime, setfilterEndTime] = useState<DateTime>();

  const { filterDate, setFilterDate } = dateFilterState;

  const { currencyFormatter, longCurrencyFormatter, currencySymbol } =
    useLocaleFormatter(program?.currency, program?.locale);

  const [timeline, setTimeline] = useState<string>('timeline');

  const timelineFormat = [
    { label: 'Timeline Format', value: 'timeline' },
    { label: 'Grouped Format', value: 'grouped' },
  ];
  const marketClosed = (status: string) =>
    ['Expired', 'Rejected', 'Cancelled', 'Completed'].includes(status);

  const dateFilter = useMemo(
    () =>
      (
        rows: any,
        columnIds: string[],
        filter: { value: DateTime; frame: any }
      ) => {
        const { value, frame } = filter;

        if (!filter) {
          return rows;
        }

        if (frame === 'day') {
          setFilterDate(value ? value.setZone(program.timezone) : value);
        } else if (frame === 'startTime') {
          setfilterStartTime(value ? value.setZone(program.timezone) : value);
        } else if (frame === 'endTime') {
          setfilterEndTime(value ? value.setZone(program.timezone) : value);
        }

        return rows.filter((row: any) => {
          const start = row.original.startTime;
          const end = row.original.endTime;
          let onDate = true;
          let validStart = true;
          let validEnd = true;

          if (
            filterDate &&
            (start.year !== filterDate.year ||
              start.month !== filterDate.month ||
              start.day !== filterDate.day)
          ) {
            onDate = false;
          }

          if (filterStartTime) {
            if (
              !filterEndTime &&
              (start.hour !== filterStartTime.hour ||
                start.minute !== filterStartTime.minute)
            ) {
              validStart = false;
            } else if (
              start.hour < filterStartTime.hour ||
              (start.hasSame(filterStartTime, 'hour') &&
                start.minute < filterStartTime.minute)
            ) {
              validStart = false;
            }
          }

          if (filterEndTime) {
            if (
              !filterStartTime &&
              (end.hour !== filterEndTime.hour ||
                end.minute !== filterEndTime.minute)
            ) {
              validEnd = false;
            } else if (
              end.hour > filterEndTime.hour ||
              end.hour === 0 ||
              (end.hasSame(filterEndTime, 'hour') &&
                end.minute > filterEndTime.minute)
            ) {
              validEnd = false;
            }
          }

          return onDate && validStart && validEnd;
        });
      },
    [
      filterDate,
      filterEndTime,
      filterStartTime,
      program.timezone,
      setFilterDate,
    ]
  );

  /* RENDER FUNCTIONS */
  const renderTimeWindow = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { duration, endTime, status } = cellInfo.row.original;
      const rowID = parseInt(cellInfo.row.id, 10);
      const previousRow = cellInfo.rowsById[`${rowID - 1}`];

      if (timeline === 'grouped') {
        if (rowID !== 0 && previousRow) {
          const { endTime: previousEndTime, startTime: previousStartTime } =
            previousRow.original;

          if (
            previousStartTime.equals(value) &&
            previousEndTime.equals(endTime)
          ) {
            return '';
          }
        }

        return (
          <div className="events-table__time">
            <Tag
              content={
                <>
                  <span>{value.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>
                  <span style={{ marginLeft: '10px' }}>
                    {endTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                  </span>
                </>
              }
              round
              customClasses={{
                tagClass: 'events-table__tag',
                contentClass: classNames({
                  'events-table__text--light': marketClosed(status),
                }),
              }}
            />
            <span className="events-table__text--light events-table__time-duration">
              {convertNumberstoReadableTime(duration, 'seconds')}
            </span>
          </div>
        );
      }

      // timeline format
      if (rowID !== 0) {
        const previousStartTime = previousRow?.original.startTime;

        if (previousStartTime && previousStartTime.equals(value)) {
          return '';
        }
      }

      return (
        <Tag
          content={
            <>
              <span>{value.toFormat('HH:mm')}</span>
            </>
          }
          round
          customClasses={{
            tagClass: classNames(
              'events-table__tag',
              'events-table__text--dark',
              'events-table__tag--dotted'
            ),
            contentClass: classNames({
              'events-table__text--light': marketClosed(status),
            }),
          }}
        />
      );
    },
    [timeline]
  );

  const renderBidOffer = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const text = `${value.slice(0, 1)}${value
        .slice(1, value.length)
        .toLowerCase()}`;

      return <BidOfferTag type={text} status={flexItemStatus.accepted} />;
    },
    []
  );

  const renderQuantity = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { status } = cellInfo.row.original;
      const sign = value >= 0 ? '+' : '';
      return (
        <Tag
          content={`${sign}${quantityConverter(value)}`}
          leftElement={
            <SVG className="events-table__bolt" fontSize="1rem" icon="bolt" />
          }
          round
          customClasses={{
            tagClass: classNames('events-table__tag', {
              'events-table__text--light': marketClosed(status),
              'events-table__tag--darkened': !marketClosed(status),
            }),
          }}
        />
      );
    },
    []
  );

  const renderValue = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { status } = cellInfo.row.original;
      return (
        <Tooltip
          arrow
          content={`${longCurrencyFormatter.format(
            cellInfo.cell.value * 1000
          )}`}
          theme="light"
        >
          <Tag
            content={`${currencyFormatter.format(value * 1000)}`}
            round
            customClasses={{
              tagClass: classNames('events-table__tag', {
                'events-table__text--light': marketClosed(status),
                'events-table__tag--darkened': !marketClosed(status),
              }),
            }}
          />
        </Tooltip>
      );
    },
    [currencyFormatter, longCurrencyFormatter]
  );

  const renderProfile = useCallback(
    (img: string, name: string, status: string, subText?: string) => {
      return (
        <UserProfile
          src={img}
          name={name}
          subText={subText}
          size="small"
          classNames={{
            nameClass: classNames({
              'events-table__text--light': marketClosed(status),
            }),
            avatarImageClass: classNames({
              'events-table__avatar-light': marketClosed(status),
            }),
          }}
        />
      );
    },
    []
  );

  const renderResponder = useMemo(
    () => (cellInfo: any) => {
      if (!cellInfo.value) return renderProfile('', '', '');
      const { name, image, der } = cellInfo.cell.value;
      const { status } = cellInfo.row.original;
      return renderProfile(image, name, status, der.name);
    },
    [renderProfile]
  );

  const renderRequestor = useMemo(
    () => (cellInfo: any) => {
      if (!cellInfo.value) return renderProfile('', '', '');
      const { name, image } = cellInfo.cell.value;
      const { status } = cellInfo.row.original;
      return renderProfile(image, name, status);
    },
    [renderProfile]
  );

  const renderText = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { status } = cellInfo.row.original;
      return (
        <span
          className={classNames('events-table__text', {
            'events-table__text--light': marketClosed(status),
          })}
        >
          {value}
        </span>
      );
    },
    []
  );

  const renderPSA = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      return (
        <Switch
          id={value}
          checked={false}
          onClick={() => console.log('clicked')}
          type="primary"
          label={'On'} // TODO if checked
          disabled={false}
          styles={{ labelStyles: { fontSize: '12px' } }}
        />
      );
    },
    []
  );

  const renderStatus = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { opfFailure } = cellInfo.row.original;
      const isFailure = opfFailure === false && isWSC;
      if (type === 'Intent to Dispatch') {
        const message = isFailure
          ? 'PSA failure'
          : value === 'Cancelled'
          ? 'Intent to dispatch cancelled'
          : value;
        return (
          <Tooltip content={message}>
            <Light
              size="xsmall"
              variant={
                isFailure
                  ? 'error'
                  : ['Accepted', 'Confirmed', 'Completed'].includes(value)
                  ? 'success'
                  : value === 'Pending'
                  ? 'warning'
                  : ['Rejected', 'Cancelled'].includes(value)
                  ? 'cancelled'
                  : 'off'
              }
            />
          </Tooltip>
        );
      }

      const content = (
        <Tooltip content="Pending">
          <Light size="xsmall" variant="off" />
        </Tooltip>
      );

      return content;
    },
    [isWSC, type]
  );

  const renderArrow = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      if (value === serviceType.MEC) {
        return (
          <div className="events-table__arrow-container">
            <span className="events-table__arrow">&#8594;</span>
          </div>
        );
      }
      return <span />;
    },
    []
  );

  const renderClickIcon = useMemo(
    () => () => {
      return (
        <div className="events-table__center">
          <SVG
            fontSize="1rem"
            icon="arrow_right"
            styles={{ marginLeft: '20px', color: 'black' }}
          />
        </div>
      );
    },
    []
  );

  const renderActions = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { status } = cellInfo.cell.row.original;
      const enabled = !['Cancelled', 'Expired', 'Completed', 'Active'].includes(
        status
      );
      return (
        <div className="events-table__center">
          <IconButton
            onClick={() => {
              if (updateEvent) {
                updateEvent(
                  value,
                  'Cancelled',
                  'Market Operator manually cancelled event'
                );
              }
            }}
            icon="XIcon"
            customClasses={{
              customButtonClass: 'events-table__actions-button',
            }}
            disabled={!enabled}
          />
        </div>
      );
    },
    [updateEvent]
  );

  /**
   * First part of this function defines all the possible columns and their logic.
   * Second part handles figuring out what columns to render.
   */
  const columns = useMemo(() => {
    const availableColumns = {
      time: {
        Header: <Tooltip content="Time">{`Time (1h)`}</Tooltip>,
        accessor: 'startTime',
        Cell: renderTimeWindow,
        filter: dateFilter,
      },
      feeder: {
        Header: 'Zone',
        accessor: 'feederName',
        Cell: renderText,
      },
      bidOffer: {
        Header: <Tooltip content="Bid/Offer from request.">{` `}</Tooltip>,
        accessor: 'type',
        Cell: renderBidOffer,
      },
      quantity: {
        Header: (
          <Tooltip content="The kW amount from the contract response available for utilization.">
            <span className="events-table__header-content">
              {`Quantity (kW)`}
              <SVG
                icon="info_outlined"
                className={`events-table__info`}
                fontSize="14px"
                round
              />
            </span>
          </Tooltip>
        ),
        accessor: 'powerRequired',
        Cell: renderQuantity,
      },
      quantityKVAR: {
        Header: 'Quantity (kVAR)',
        accessor: 'reactivePowerRequired',
        Cell: renderQuantity,
      },
      availability: {
        Header: (
          <Tooltip content="The price for the availability of the asset over the contract period.">
            {`Availability (${currencySymbol}/kW/h)`}
          </Tooltip>
        ),
        accessor: 'availabilityPrice',
        Cell: renderValue,
      },
      utilization: {
        Header: (
          <Tooltip content="The price per service call of the asset per market interval.">
            {`Utilization (${currencySymbol}/kWh)`}
          </Tooltip>
        ),
        accessor: 'utilizationPrice',
        Cell: renderValue,
      },
      responder: {
        Header: 'Responder',
        accessor: 'responder',
        Cell: renderResponder,
      },
      arrow: {
        Header: '',
        accessor: 'serviceType',
        id: 'arrow',
        Cell: renderArrow,
      },
      requestor: {
        Header: 'Requestor',
        accessor: 'requestor',
        Cell: renderRequestor,
      },
      status: {
        Header: (
          <Tooltip
            content={type === 'Intent to Dispatch' ? '' : 'Has the market run.'}
          >
            <span className="events-table__header-content">
              {`Status`}
              <SVG
                icon="info_outlined"
                className={`events-table__info`}
                fontSize="14px"
                round
              />
            </span>
          </Tooltip>
        ),
        accessor: 'status',
        Cell: renderStatus,
      },
      serviceType: {
        Header: 'Service Type',
        accessor: 'serviceType',
        Cell: renderText,
      },
      actions: {
        Header: 'Fiat request',
        accessor: 'id',
        id: 'actions',
        Cell: renderActions,
      },
      psaEligible: {
        Header: (
          <Tooltip content="This contract will be submitted/ removed from the PSA schedule.">
            <span className="events-table__header-content">
              {`PSA Eligible`}
              <SVG
                icon="info_outlined"
                className={`events-table__info`}
                fontSize="14px"
                round
              />
            </span>
          </Tooltip>
        ),
        accessor: 'id',
        id: 'psa',
        Cell: renderPSA,
      },
      clickIcon: {
        Header: '',
        accessor: 'clickIcon',
        Cell: renderClickIcon,
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [
    currencySymbol,
    dateFilter,
    renderActions,
    renderArrow,
    renderBidOffer,
    renderClickIcon,
    renderPSA,
    renderQuantity,
    renderRequestor,
    renderResponder,
    renderStatus,
    renderText,
    renderTimeWindow,
    renderValue,
    showColumns,
    type,
  ]);

  function placeHolder() {
    return (
      <tr className="events-table__row">
        <td className="events-table__cell" colSpan={columns.length}>
          <div style={{ width: 'fit-content', margin: 'auto' }}>
            There is no data to display.
          </div>
        </td>
      </tr>
    );
  }

  /**
   * Custom function to handle cell rendering.
   * Handles the logic for coloring specific columns.
   * @param {Row} row - object containing information about the row being rendered.
   * @param index - cell index in the row
   * @param tableRowClass - custom general row class.
   * @param tableCellClass - custom general cell class.
   * @returns - row
   */
  function renderCells(
    row: any,
    index: number,
    tableCellClass: string | undefined
  ) {
    const whiteCells = [
      'quantity',
      'feederName',
      'powerRequired',
      'reactivePowerRequired',
      'availabilityPrice',
      'utilizationPrice',
      'responder',
      'arrow',
      'requestor',
      'status',
      'serviceType',
      'actions',
      'psa',
      'clickIcon',
    ];

    const shouldGreyOutRow = ['Cancelled', 'Completed'].includes(
      row.values.status
    );
    return (
      <tr
        className={`${shouldGreyOutRow && 'events-table--light-gray'}`}
        {...row.getRowProps()}
        key={index}
      >
        {row.cells.map((cell: Cell, index: number) => {
          const col = cell.column.id;
          return (
            <td
              {...cell.getCellProps()}
              key={index}
              className={classNames(tableCellClass, {
                'events-table--white':
                  whiteCells.includes(col) && !shouldGreyOutRow,
              })}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  }

  const TableStyles = {
    tableClass: 'events-table',
    tableHeaderClass: 'events-table__header',
    tableBodyClass: !hideFilters ? 'events-table__body' : '',
    tableHeaderCellClass: 'events-table__header-cell',
    tableBodyRowClass: 'events-table__row',
    tableBodyCellClass: 'events-table__cell',
  };

  const initialFilterState = useMemo(
    () => [{ id: 'startTime', value: DateTime.local().plus({ days: 1 }) }],
    []
  );

  // routes from here go like: prog->id->schedules->feeder->id->page->date?
  return (
    <Table
      disableSort
      tableType="HTML"
      data={data}
      columns={columns}
      customClasses={TableStyles}
      placeHolderRow={placeHolder}
      customRowRenderFunction={renderCells}
      initialFilterState={initialFilterState}
      header={EventsTableHeader({
        filterDate,
        type: 'test',
        timelineFormat,
        setTimeline,
        filterEndTime,
        filterStartTime,
        downloadTable,
      })}
    />
  );
}

export default EventsTable;
