import { DateTime } from 'luxon';

import {
  PFAnalysisTypesRaw,
  PFAnalysisTypes,
} from '../../routes/PowerFlowAnalysis/PowerFlowAnalysis.types';
import { Feeder } from 'types/feeder';
import { SelectOption, MarketType } from 'types';

export const analysesList: SelectOption[] = [
  { value: 'ALL', label: 'All analyses' },
  { value: PFAnalysisTypesRaw.UTILIZATION, label: PFAnalysisTypes.UTILIZATION },
  {
    value: PFAnalysisTypesRaw.CONTRACT_SELECTION,
    label: PFAnalysisTypes.CONTRACT_SELECTION,
  },
  { value: PFAnalysisTypesRaw.NEEDS, label: PFAnalysisTypes.NEEDS },
];

export const marketsList: SelectOption[] = [
  { value: 'ALL', label: 'All markets' },
  { value: MarketType.SAMEDAY, label: 'Sameday' },
  { value: MarketType.DAYAHEAD, label: 'Day ahead' },
  { value: MarketType.WEEK_AHEAD, label: 'Week ahead' },
];

export function generateFeederList(feeders: Feeder[]): SelectOption[] {
  const feedersList: SelectOption[] = [{ label: 'All zones', value: 'ALL' }];
  feeders.forEach((feeder) => {
    feedersList.push({
      label: feeder.name,
      value: feeder.id,
    });
  });
  return feedersList;
}

export function generateTimePeriods(
  timezone: string | undefined
): SelectOption[] {
  return [
    {
      label: 'Today',
      value: {
        startDate: DateTime.now().setZone(timezone).startOf('day'),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
    {
      label: 'Yesterday',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ days: 1 }),
        endDate: DateTime.now()
          .setZone(timezone)
          .endOf('day')
          .minus({ days: 1 }),
      },
    },
    {
      label: 'Last 7 days',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ days: 7 }),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
    {
      label: 'Last 30 days',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ days: 30 }),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
    {
      label: 'Last 6 months',
      value: {
        startDate: DateTime.now()
          .setZone(timezone)
          .startOf('day')
          .minus({ months: 6 }),
        endDate: DateTime.now().setZone(timezone).endOf('day'),
      },
    },
  ];
}
