import { isNull, isUndefined } from 'lodash';
import {
  NotificationItemProp,
  NotificationRulesProp,
  NotificationsStateProp,
} from './EmailNotifications.types';

export const recipientList = [
  {
    label: 'All Industry Actors',
    value: 'ALL_IA',
  },
  {
    label: 'Requestor',
    value: 'REQUESTOR',
  },
  {
    label: 'Responder',
    value: 'RESPONDER',
  },
  {
    label: 'Distribution System Operator',
    value: 'DSO',
  },
  {
    label: 'Contract Side',
    value: 'CONTRACT_SIDE',
  },
];

export const serviceList = [
  {
    label: 'All services',
    value: null,
  },
  {
    label: 'All Peer to Network services',
    value: 'P2N',
  },
  {
    label: 'All Peer to Peer services',
    value: 'P2P',
  },
];

export const recurrenceList = [
  {
    label: '15 minutes before event',
    value: 900,
  },
  {
    label: '30 minutes before event',
    value: 1800,
  },
  {
    label: '1 hour before event',
    value: 3600,
  },
  {
    label: '12 hours before event',
    value: 43200,
  },
  {
    label: 'real time',
    value: 0,
  },
];

export const contentListTemplate = [
  {
    title: 'General',
    items: [
      {
        value: 'TENANT_CREATED',
        title: 'New market participant registered',
        settings: {},
        notifications: [],
      },
      {
        value: 'MARKET_GATE',
        title: 'Market gate closure reminder',
        settings: {},
        notifications: [],
      },
    ],
  },
  {
    title: 'Requests',
    items: [
      {
        value: 'FLEX_REQUEST_SUBMITTED',
        title: 'Request submitted',
        settings: {},
        notifications: [],
      },
      {
        value: 'FLEX_REQUEST_CANCELLED',
        title: 'Request canceled',
        settings: {},
        notifications: [],
      },
    ],
  },

  {
    title: 'Responces',
    items: [
      {
        value: 'FLEX_RESPONSE_SUBMITTED',
        title: 'Response submitted',
        settings: {},
        notifications: [],
      },
      {
        value: 'FLEX_RESPONSE_ACCEPTED',
        title: 'Response accepted',
        settings: {},
        notifications: [],
      },
      {
        value: 'FLEX_RESPONSE_REJECTED',
        title: 'Response rejected',
        settings: {},
        notifications: [],
      },
      {
        value: 'FLEX_RESPONSE_CANCELLED',
        title: 'Response canceled',
        settings: {},
        notifications: [],
      },
    ],
  },
  {
    title: 'Contracts',
    items: [
      {
        value: 'FLEX_CONTRACT_ACCEPTED',
        title: 'Contract accepted',
        settings: {},
        notifications: [],
      },
      {
        value: 'FLEX_CONTRACT_REJECTED',
        title: 'Contract rejected',
        settings: {},
        notifications: [],
      },
      {
        value: 'FLEX_CONTRACT_UPDATED',
        title: 'Contract updated',
        settings: {},
        notifications: [],
      },
      {
        value: 'UNAVAILABILITY_NOTICE',
        title: 'Unavailability notice',
        settings: {},
        notifications: [],
      },
    ],
  },
  {
    title: 'Intent to dispatch',
    items: [
      {
        value: 'INTENT_TO_DISPATCH_PUBLISHED',
        title: 'Intent to dispatch submitted',
        settings: {},
        notifications: [],
      },
      {
        value: 'INTENT_TO_DISPATCH_CANCELLED',
        title: 'Intent to dispatch cancelled',
        settings: {},
        notifications: [],
      },
      {
        value: 'INTENT_TO_DISPATCH_REMINDER_TO_START',
        title: 'Intent to dispatch start time reminder',
        settings: {},
        notifications: [],
      },
      {
        value: 'INTENT_TO_DISPATCH_REMINDER_TO_STOP',
        title: 'Intent to dispatch end time reminder',
        settings: {},
        notifications: [],
      },
    ],
  },
  {
    title: 'Settlement',
    items: [
      {
        value: 'MEASUREMENT_DATA_UPLOAD_GATE',
        title: 'Reminder to upload measurement data',
        settings: {},
        notifications: [],
      },
      {
        value: 'FLEX_SETTLEMENT_PUBLISHED',
        title: 'Settlement report published',
        settings: {},
        notifications: [],
      },
    ],
  },
];

export const defaultModalState = {
  isShow: false,
  content: {},
  type: undefined,
};

export const emptyRow = {
  delay: null,
  body: '',
  service_type: '',
  active: false,
  program_id: '',
  recipient_type: '',
  triggering_event_type: '',
  subject: '',
  header: '',
};

export const modifyItemFromListById = (
  list: NotificationsStateProp[],
  id: string,
  target: NotificationItemProp,
  type: 'add' | 'update'
) => {
  return list.map((listItem) => {
    const res = listItem.items.find((item) => item.value === id);
    if (!res) {
      return listItem;
    }
    if (type === 'add') {
      type === 'add' && res.notifications.push(target);
    }
    if (type === 'update') {
      const index = res.notifications.findIndex(
        (i) => (i.newId || i.id) === (target.newId || target.id)
      );
      res.notifications[index] = target;
    }
    return listItem;
  });
};

export const notificationApiUrl = (programId: string) =>
  `/api/dsp/program/${programId}/notification_rule`;

export const notificationUpdateApiUrl = (programId: string, id: string) =>
  `/api/dsp/program/${programId}/notification_rule/${id}`;

export const notificationPreviewApiUrl = '/api/dsp/notification_rule/preview';

export const notificationRulesApiUrl = '/api/dsp/notification_rule/allowed';

export const getNotificationRules = () => {
  const notificationRules = localStorage.getItem('notificationRules');
  if (isUndefined(notificationRules) || isNull(notificationRules)) return false;
  return JSON.parse(notificationRules);
};

export const setNotificationRulesData = (
  data: Record<string, NotificationRulesProp>
) => {
  localStorage.setItem('notificationRules', JSON.stringify(data));
};

export const constructState = (
  notificationRules: Record<string, NotificationRulesProp>,
  notificationList: NotificationItemProp[],
  contentListTemplate: NotificationsStateProp[]
): NotificationsStateProp[] =>
  contentListTemplate.map((list) => {
    const items =
      list &&
      list.items.map((item) => {
        const notificationItems = notificationList.filter(
          (i) => i.triggering_event_type === item?.value
        );
        const settings = notificationRules[item.value];
        return {
          ...item,
          settings,
          notifications: [...item.notifications, ...notificationItems],
        };
      });
    return {
      ...list,
      items,
    };
  });

export const getChangedItems = (data: Record<string, any>, target: string) => {
  return data?.[target] || false;
};
