import React from 'react';
import { Route } from 'react-router-dom';

import RouterSwitch from 'components/RouterSwitch';
import ProgramAdmin from './ProgramAdmin';
import ProgramAdminSettings from './routes/ProgramAdminSettings';

const ProgramAdminSettingsContainer = () => {
  return (
    <RouterSwitch>
      <Route exact path="/admin/programs">
        <ProgramAdmin />
      </Route>
      <Route exact path="/admin/programs/:programID">
        <ProgramAdminSettings />
      </Route>
    </RouterSwitch>
  );
};

export default ProgramAdminSettingsContainer;
