import React from 'react';
import { DateTime } from 'luxon';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import { getServiceTimeRangeDetails } from 'helpers/time';
import { peakType, SelectDays } from 'types/contract-managment';

type TimeRangeProps = {
  peak: peakType;
  customDays: SelectDays | null;
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;
};

export const TimeRange = ({
  peak,
  customDays,
  startDate,
  endDate,
}: TimeRangeProps) => {
  const { days, startTime, endTime } = getServiceTimeRangeDetails(
    peak,
    customDays,
    startDate,
    endDate
  );

  return (
    <Grid container spacing={1}>
      <Grid item>{days.join(' | ')}</Grid>
      <Grid item>
        (
        {`${startTime === '24:00' ? '00:00' : startTime} - ${
          endTime === '00:00' ? '24:00' : endTime
        }`}
        )
      </Grid>
    </Grid>
  );
};
