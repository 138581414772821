import React, { Component } from 'react';
import classnames from 'classnames';
import Select, {
  ActionMeta,
  Props,
  OnChangeValue,
  MultiValue,
} from 'react-select';

import './Select.scss';

type Nullable<X> = X | null;

interface WrappedSelectProps<OptionType> extends Props<OptionType> {
  row?: boolean;
  placeholder?: string;
  label?: React.ReactNode;
  onChange(
    opt: OnChangeValue<OptionType, boolean>,
    action: ActionMeta<OptionType>
  ): void;
  error?: boolean;
  helperText?: string | undefined;
  classes?: {
    wrapperClass?: string;
    labelClass?: string;
    helperTextClass?: string;
  };
  customStyles?: {
    wrapperStyle?: undefined | React.CSSProperties;
  };
}

const defaultCustomStyles = {
  wrapperStyle: undefined,
};

interface MultiSelectProps<OptionType> extends WrappedSelectProps<OptionType> {
  isClearable: false;
  isMulti: true;
  onChange: (
    opt: ReadonlyArray<OptionType>,
    action: ActionMeta<OptionType>
  ) => void;
}

interface NullableMultiSelectProps<OptionType>
  extends WrappedSelectProps<OptionType> {
  isClearable: true;
  isMulti: true;
  onChange: (
    opt: Nullable<ReadonlyArray<OptionType>>,
    action: ActionMeta<OptionType>
  ) => void;
}

interface SingleSelectProps<OptionType> extends WrappedSelectProps<OptionType> {
  isClearable: false;
  isMulti: false;
  onChange: (opt: OptionType, action: ActionMeta<OptionType>) => void;
}

interface NullableSingleSelectProps<OptionType>
  extends WrappedSelectProps<OptionType> {
  isClearable: true;
  isMulti?: false;
  onChange: (opt: Nullable<OptionType>, action: ActionMeta<OptionType>) => void;
}

function isSingleNullableValue<T>(
  opt: OnChangeValue<T, false>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is Nullable<T> {
  return !isMulti && !!isClearable;
}

function isMultiNullableValue<T>(
  opt: OnChangeValue<T, true>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is MultiValue<T> {
  return !!isMulti && !!isClearable;
}

function isSingleValue<T>(
  opt: OnChangeValue<T, false>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is T {
  return !isMulti && !isClearable;
}

function isMultiValue<T>(
  opt: OnChangeValue<T, true>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is ReadonlyArray<T> {
  return !!isMulti && !isClearable;
}

type SelectProps<T> =
  | SingleSelectProps<T>
  | MultiSelectProps<T>
  | NullableSingleSelectProps<T>
  | NullableMultiSelectProps<T>;

class WrappedSelect<T> extends Component<SelectProps<T>> {
  static defaultProps = {
    isClearable: false,
    isMulti: false,
    customStyles: defaultCustomStyles,
    styles: {
      clearIndicator: (provided: Record<string, unknown>) => ({
        ...provided,
        padding: '5px 8px',
      }),
      control: (provided: Record<string, unknown>) => ({
        ...provided,
        borderColor: '#D4D4D4',
        height: '30px',
        minHeight: 'unset',
      }),
      dropdownIndicator: (provided: Record<string, unknown>) => ({
        ...provided,
        padding: '5px 8px',
      }),
      indicatorSeparator: (provided: Record<string, unknown>) => ({
        ...provided,
        marginBottom: '5px',
        marginTop: '3px',
      }),
      menu: (provided: Record<string, unknown>) => ({
        ...provided,
        zIndex: 10,
      }),
      singleValue: (provided: Record<string, unknown>) => ({
        ...provided,
      }),
      valueContainer: (provided: Record<string, unknown>) => ({
        ...provided,
        height: '30px',
        padding: '0px 8px',
      }),
    },
  };

  render() {
    const { props } = this;
    const { error, helperText, classes, placeholder } = props;
    const wrapperStyle =
      props.customStyles && props.customStyles.wrapperStyle
        ? props.customStyles.wrapperStyle
        : defaultCustomStyles.wrapperStyle;
    return (
      <div
        className={classnames('select', classes?.wrapperClass, {
          select__row: !!props.row,
        })}
        style={wrapperStyle}
      >
        {props.label && (
          <label
            className={classnames(
              'select_label',
              'select__text',
              classes?.labelClass
            )}
          >
            {props.label}
          </label>
        )}
        <Select
          className="select__base"
          {...props}
          placeholder={placeholder}
          isOptionDisabled={(option: any) => option.isDisabled}
          onChange={(opt: any, action) => {
            if (typeof opt === 'undefined') {
              opt = null;
            }

            if (props.onChange) {
              if (
                isSingleNullableValue(opt, props.isMulti, props.isClearable)
              ) {
                // Call Nullable<T> override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }

              if (isMultiNullableValue(opt, props.isMulti, props.isClearable)) {
                // Call Nullable<T[]> override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }

              if (isSingleValue(opt, props.isMulti, props.isClearable)) {
                // Call T override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }

              if (isMultiValue(opt, props.isMulti, props.isClearable)) {
                // Call T[] override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }
            }
          }}
        />
        {helperText && (
          <p
            className={classnames(
              'select__helper',
              'select__text',
              classes?.helperTextClass,
              {
                'select__text--error': error,
              }
            )}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
}
export default WrappedSelect;
