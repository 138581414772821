import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MinusIcon = ({
  title,
  titleId,
  color = 'currentColor',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.2 10.2H4.6c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h11.7c.3 0 .6.3.6.6s-.3.6-.7.6Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default MinusIcon;
