import React, { useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  apm,
  Request,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { SelectOption } from 'types';

import { env } from 'helpers/env';

import Button from 'components/Button';
import HeaderLayout from 'components/HeaderLayout';
import Select from 'components/OldSelect';
import TextInput from 'components/TextInput';
import LoadingSpinner from 'components/LoadingSpinner';

import './AddISO.scss';

const CUSTOM_ISO_VALUE = 'CUSTOM_ISO_VALUE';
const INVALID_ISO_REGEX = /[^\w-_]/g;

type LegacyISO = {
  currency: string;
  dayahead_generation_time: string;
  locale: string;
  name: string;
  sameday_data_delay: number;
  sameday_generation_lead_time: number;
  timezone: string;
  zones: [];
};

const AddISO = () => {
  const [isoOptions, setISOOptions] = useState<SelectOption[]>([]);
  const [name, setName] = useState<string | null>(null);
  const [redirect, setRedirect] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedISO, setSelectedISO] = useState(null);

  const { loading, data: legacyISOs } = useRequestEffect<LegacyISO[]>({
    url: '/api/dsp/legacy_iso',
    method: 'get',
    refetchOnChange: [],
    onSuccess: (data) => {
      if (data) {
        const newISOOptions = data
          .map(({ name }) => ({ label: name.toUpperCase(), value: name }))
          .concat([{ label: 'Create Custom ISO', value: CUSTOM_ISO_VALUE }]);
        setISOOptions(newISOOptions);
      }
    },
    toast: {
      error: 'Could not load list of built-in ISOs',
    },
  });

  const readyToSave =
    selectedISO &&
    (selectedISO !== CUSTOM_ISO_VALUE ||
      (selectedISO === CUSTOM_ISO_VALUE &&
        name &&
        !INVALID_ISO_REGEX.test(name)));

  const saveISO = async () => {
    setSaving(true);

    try {
      const addRequest = new Request('/api/dsp/iso');

      let currency, locale;
      let dayaheadGenerationTime, samedayGenerationLeadTime;
      let isoName, isoZones, sameDayDataDelay, timezone;

      if (selectedISO === CUSTOM_ISO_VALUE) {
        currency = 'USD';
        dayaheadGenerationTime = '12:00:00';
        isoName = name;
        locale = 'en-US';
        sameDayDataDelay = 0;
        samedayGenerationLeadTime = 90 * 60; //  Default to 90 minutes ahead of time
        timezone = 'UTC'; // Default to UTC for custom ISOs
      } else {
        const legacyISO = legacyISOs?.find(({ name }) => name === selectedISO);

        if (legacyISO) {
          currency = legacyISO.currency;
          dayaheadGenerationTime = legacyISO.dayahead_generation_time;
          isoName = legacyISO.name;
          isoZones = legacyISO.zones;
          locale = legacyISO.locale;
          sameDayDataDelay = legacyISO.sameday_data_delay;
          samedayGenerationLeadTime = legacyISO.sameday_generation_lead_time;
          timezone = legacyISO.timezone;
        }
      }

      await addRequest.post({
        currency,
        dayahead_generation_time: dayaheadGenerationTime,
        locale,
        name: isoName,
        sameday_data_delay: sameDayDataDelay,
        sameday_generation_lead_time: samedayGenerationLeadTime,
        timezone: timezone,
        zones: isoZones,
      });
      setRedirect(true);
    } catch (error: any) {
      apm.captureError(error);
    }

    setSaving(false);
  };

  return (
    <HeaderLayout
      className="add-iso"
      title={env.isWSC ? 'Create New Settings' : 'Create New ISO'}
      titleRightContent={
        <Fragment>
          <Link to="/isos" className="cancel-button">
            <Button variant="outlined">Cancel</Button>
          </Link>
          <Button
            disabled={loading || !readyToSave || saving}
            onClick={() => saveISO()}
          >
            Add
          </Button>
        </Fragment>
      }
    >
      <Fragment>
        {redirect && <Redirect to="/isos" />}
        {loading && <LoadingSpinner />}
        {!loading && (
          <div className="iso-content">
            <div className="input-container">
              <label>{env.isWSC ? 'Setting' : 'ISO'}</label>
              <Select
                isClearable={false}
                isMulti={false}
                onChange={({ value }) => setSelectedISO(value)}
                options={isoOptions}
                value={isoOptions.find(({ value }) => value === selectedISO)}
              />
            </div>
            {selectedISO === CUSTOM_ISO_VALUE && (
              <div className="input-container">
                <TextInput
                  id="name"
                  label={env.isWSC ? 'Custom Setting Name ' : 'Custom ISO Name'}
                  onChange={(v) => setName(v)}
                  value={name}
                />
              </div>
            )}
          </div>
        )}
      </Fragment>
    </HeaderLayout>
  );
};

export default AddISO;
