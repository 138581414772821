import React, { Fragment } from 'react';

import { useProgramsContext } from 'contexts/ProgramsContext';

import Breadcrumbs from 'components/Breadcrumbs';
import HeaderLayout from 'components/HeaderLayout';

import ProgramCard from './ProgramCard';

import './ProgramAdmin.scss';

const ProgramAdmin = () => {
  const { programs } = useProgramsContext();

  return (
    <HeaderLayout
      className="program-admin"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/admin',
              label: <h2 className="title">Admin Panel</h2>,
            },
          ]}
          separator="/"
          currentHeader="Programs"
        />
      }
    >
      <Fragment>
        {programs.map((program) => (
          <ProgramCard
            key={program.program_id}
            programID={program.program_id}
            programName={program.name}
          />
        ))}
      </Fragment>
    </HeaderLayout>
  );
};

export default ProgramAdmin;
