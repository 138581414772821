import React, { memo, useMemo } from 'react';

import { getDaysOfWeek } from '../Calendar.logic';

import './WeekDays.scss';

const WeekDays = () => {
  const days = useMemo(() => getDaysOfWeek(), []);
  return (
    <div className="week-days">
      {days.map((day, idx) => (
        <div key={`${day}-${idx}`} className="week-days__item">
          {day}
        </div>
      ))}
    </div>
  );
};

export default memo(WeekDays);
