import React from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { DERIcons, DERType, getDERTypeFromString } from 'types/der';
import { flexItemStatus, serviceType } from 'types/contract-managment';
import { Feeder } from 'types/feeder';

import { getRemainingTime, isMidnight } from 'helpers/time';
import { sortWeekDays } from 'helpers/date';
import quantityConverter from 'helpers/quantityConverter';

import Tooltip from 'components/Tooltip';
import Light from 'components/Light';
import Tag from 'components/Tag';
import SVG from 'components/SVG';
import Button from 'components/Button';

type currencyFormatterType = {
  format: (value: number) => void;
};

type classPrefixType = 'requests-table' | 'responses-table' | 'contracts-table';

/**
 * Figures out the actual status of a flex item.
 * @param {Object} data - origional flex item data.
 * @param {string} classPrefix - table prefix string
 * @returns {flexItemStatus}
 */
export function getStatus(
  data: any,
  classPrefix: classPrefixType
): flexItemStatus {
  const { status } = data;
  if (status === flexItemStatus.rejected) return flexItemStatus.rejected;
  if (status === flexItemStatus.cancelled) return flexItemStatus.cancelled;
  if (status === flexItemStatus.expired) return flexItemStatus.expired;

  const endDate =
    classPrefix === 'requests-table' ? data.endTime : data.request.endTime;
  const expiryDate =
    classPrefix === 'requests-table'
      ? data.expiryDate
      : data.request.expiryDate;
  const hasActiveContract =
    classPrefix === 'requests-table'
      ? data.hasActiveContract
      : data.request.hasActiveContract;

  const responseExpired =
    (getRemainingTime(expiryDate) === 'Expired' && !hasActiveContract) ||
    getRemainingTime(endDate) === 'Expired';

  if (responseExpired) return flexItemStatus.expired;

  if (status === flexItemStatus.accepted) return flexItemStatus.accepted;
  return flexItemStatus.pending;
}

const isCancelled = (original: any, classPrefix: classPrefixType) => {
  const status = getStatus(original, classPrefix);
  return (
    status === flexItemStatus.expired ||
    status === flexItemStatus.cancelled ||
    status === flexItemStatus.rejected
  );
};

export const renderContractModel = (
  cellInfo: any,
  classPrefix: classPrefixType
) => {
  const { request } = cellInfo.row.original;
  const cellValue = cellInfo.value || request?.participantId ? 'D' : 'P';
  const classStyle =
    cellInfo.value || request?.participantId ? 'direct' : 'pool';
  const tooltip = cellInfo.value || request?.participantId ? 'Direct' : 'Pool';

  return (
    <Tooltip content={`${tooltip} participation`} arrow={true} theme="light">
      <div
        className={`${classPrefix}__contract-model ${classPrefix}__contract-model-${classStyle}`}
      >
        {cellValue}
      </div>
    </Tooltip>
  );
};

export const renderZone = (
  cellInfo: any,
  feeders: Feeder[],
  classPrefix: classPrefixType
) => {
  const feeder = feeders.find((feeder) => feeder.id === cellInfo.value);
  const feederName = feeder?.name || '-';
  const displayFeeder =
    feederName.length > 20 ? `${feederName.slice(0, 20)}...` : feederName;

  const { original } = cellInfo.cell.row;
  const cancelled = isCancelled(original, classPrefix);

  return (
    <Tooltip content={feederName} arrow={true} theme="light">
      <div className={classNames(`${classPrefix}__feeder`)}>
        <span
          className={classNames(`${classPrefix}__text`, {
            [`${classPrefix}__text--light`]: cancelled,
          })}
        >
          {displayFeeder}
        </span>
        <span
          className={classNames(`${classPrefix}__text`, {
            [`${classPrefix}__text--light`]: cancelled,
          })}
        >
          {original.primary?.name}
        </span>
      </div>
    </Tooltip>
  );
};

export function renderQuantity(cellInfo: any, classPrefix: classPrefixType) {
  const { value } = cellInfo.cell;
  const { original } = cellInfo.cell.row;
  const isPos = value >= 0 ? 'pos' : 'neg';
  const sign = isPos ? '' : '-';
  const cancelled = isCancelled(original, classPrefix);
  const isProcessing = original.status === flexItemStatus.processing;

  return (
    <Tag
      content={isProcessing ? '-' : `${sign}${quantityConverter(value)}`}
      leftElement={
        <SVG
          fontSize="1rem"
          icon="bolt"
          className={classNames({ [`${classPrefix}__text--light`]: cancelled })}
        />
      }
      customClasses={{
        tagClass: classNames(
          `${classPrefix}__tag`,
          `${classPrefix}__tag--darkened`,
          { [`${classPrefix}__text--light`]: cancelled }
        ),
      }}
    />
  );
}

export function renderCost(
  cellInfo: any,
  classPrefix: classPrefixType,
  currencyFormatter: currencyFormatterType,
  longCurrencyFormatter: currencyFormatterType,
  col = ''
) {
  const { original } = cellInfo.cell.row;
  const cancelled = isCancelled(original, classPrefix);
  const isMicMacUtilization =
    (original.service === serviceType.MEC ||
      original.service === serviceType.MIC) &&
    col === 'utilization';

  return (
    <div>
      <Tooltip
        arrow
        content={
          isMicMacUtilization
            ? 'N/A'
            : `${longCurrencyFormatter.format(cellInfo.cell.value * 1000)}`
        }
        theme="light"
      >
        <Tag
          content={
            isMicMacUtilization
              ? 'N/A'
              : `${currencyFormatter.format(cellInfo.cell.value * 1000)}`
          }
          customClasses={{
            tagClass: classNames(
              `${classPrefix}__tag`,
              `${classPrefix}__tag--darkened`,
              {
                [`${classPrefix}__text--light`]: cancelled,
              }
            ),
          }}
        />
      </Tooltip>
    </div>
  );
}

export function renderWindow(cellInfo: any, classPrefix: classPrefixType) {
  const { value } = cellInfo.cell;
  const { original } = cellInfo.cell.row;
  const cancelled = isCancelled(original, classPrefix);

  const transforTime = (obj: any) =>
    obj.toLocaleString(DateTime.TIME_24_SIMPLE);

  const startTime = original?.startTime
    ? transforTime(original.startTime)
    : transforTime(original.request.startTime);

  const endTime = original?.endTime
    ? transforTime(original.endTime)
    : transforTime(original.request?.endTime);

  const days = original?.customPeakDays
    ? original.customPeakDays
    : original.request?.customPeakDays;

  const selectedDays = Object.keys(days)
    .filter((day) => days[day] !== false)
    .map((day) => day[0].toUpperCase() + day.slice(1, 3));
  const sortedDays = sortWeekDays(selectedDays, true).join(', ');

  const ServiceInterval = () => (
    <>
      {startTime === '24:00' ? '00:00' : startTime} -{' '}
      {endTime === '00:00' ? '24:00' : endTime} <br /> {sortedDays}
    </>
  );

  return (
    <div
      className={classNames(`${classPrefix}__text`, {
        [`${classPrefix}__text--light`]: cancelled,
      })}
    >
      <span style={{ marginRight: '4px' }}>{value}</span>
      <Tooltip content={<ServiceInterval />} arrow={true} theme="light">
        <SVG icon="InfoIcon" fontSize="12px" />
      </Tooltip>
    </div>
  );
}

export function renderServicePeriod(
  cellInfo: any,
  classPrefix: classPrefixType
) {
  const start = cellInfo.cell.value;
  const end =
    cellInfo.cell.row.original.endTime ||
    cellInfo.cell.row.original.request.endTime;
  const { original } = cellInfo.cell.row;
  const endDisplay = end.minus({
    days: isMidnight(end) ? 1 : 0,
  });
  const cancelled = isCancelled(original, classPrefix);
  const duration = original?.duration
    ? original.duration
    : original.request.duration;

  return (
    <Tooltip content={`${duration} service`} arrow={true} theme="light">
      <span
        className={classNames(`${classPrefix}__text`, {
          [`${classPrefix}__text--light`]: cancelled,
        })}
      >
        <span style={{ marginRight: '6px' }}>
          {start.toLocaleString(DateTime.DATE_MED)}
        </span>{' '}
        -
        <span style={{ marginLeft: '6px' }}>
          {endDisplay.toLocaleString(DateTime.DATE_MED)}
        </span>
      </span>
    </Tooltip>
  );
}

export function renderBidOffer(cellInfo: any, classPrefix: classPrefixType) {
  const { value } = cellInfo.cell;
  const { original } = cellInfo.cell.row;
  const greyOut = isCancelled(original, classPrefix);

  if (classPrefix === 'contracts-table') {
    return (
      <Tooltip content={value} arrow={true} theme="light">
        <div
          className={classNames(`${classPrefix}__contract-model`, {
            [`${classPrefix}__text--light`]: greyOut,
          })}
        >
          {value[0]}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tag
      content={value}
      customClasses={{
        tagClass: classNames(
          `${classPrefix}__tag`,
          `${classPrefix}__tag--darkened`,
          { [`${classPrefix}__text--light`]: greyOut }
        ),
      }}
    />
  );
}

export function renderPerson(
  cellInfo: any,
  feeders: Feeder[],
  classPrefix: classPrefixType
) {
  const tenantName = cellInfo.cell.value;
  const { original } = cellInfo.cell.row;
  const feeder = feeders.find((feeder) => feeder.id === original.feederID);
  const feederName = feeder?.name || '';
  const displayFeeder =
    feederName.length > 20 ? `${feederName.slice(0, 20)}...` : feederName;

  const cancelled = isCancelled(original, classPrefix);

  let details = {
    derName: '',
    derType: '',
  };
  if (original && original.response) {
    if (cellInfo.cell.column.id === 'response.responderName') {
      details = original.response;
    } else {
      details = original.request;
    }
  }
  const { derType, derName } = details;
  const displayDERName =
    derName && derName.length > 20 ? `${derName.slice(0, 20)}...` : derName;
  const actualDERType = getDERTypeFromString(derType);
  const showDER =
    classPrefix === 'contracts-table' || actualDERType !== DERType.undefined;

  return (
    <Tooltip content={derName} arrow={true} theme="light">
      <div className={classNames(`${classPrefix}__der`)}>
        {actualDERType && showDER && (
          <div className={classNames(`${classPrefix}__der-image`)}>
            <SVG
              icon={DERIcons[actualDERType]}
              className={classNames(`${classPrefix}__der-image-icon`, {
                [`${classPrefix}__der-image-icon--disabled`]: cancelled,
              })}
            />
          </div>
        )}
        <div className={classNames(`${classPrefix}__der-details`)}>
          <span
            className={classNames(`${classPrefix}__text`, {
              [`${classPrefix}__text--light`]: cancelled,
            })}
          >
            {tenantName}
          </span>
          {showDER && (
            <span className={classNames(`${classPrefix}__text--light`)}>
              {displayDERName}
            </span>
          )}
          <span className={classNames(`${classPrefix}__text--light`)}>
            {displayFeeder}
          </span>
        </div>
      </div>
    </Tooltip>
  );
}

export function renderText(cellInfo: any, classPrefix: classPrefixType) {
  const { original } = cellInfo.cell.row;
  const cancelled = isCancelled(original, classPrefix);
  const service = cellInfo.cell.value.replace(/management/gi, 'Mgmt');
  return (
    <span
      className={classNames(`${classPrefix}__text`, {
        [`${classPrefix}__text--light`]: cancelled,
      })}
    >
      {service}
    </span>
  );
}

export function renderUpdates(
  cellInfo: any,
  classPrefix: classPrefixType,
  setUpdatesDetailsDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setUpdateDetails?: React.Dispatch<React.SetStateAction<JSX.Element | null>>,
  setUpdateDetailsForClipboard?: React.Dispatch<
    React.SetStateAction<string | null>
  >
) {
  const { original } = cellInfo.cell.row;
  const cancelled = isCancelled(original, classPrefix);
  const value = cellInfo.cell.value;
  const updatesRaw = original.updates as Array<any>;
  const updates = updatesRaw.map((update) => {
    return {
      startTime: DateTime.fromISO(update.start_time),
      endTime: DateTime.fromISO(update.end_time),
      quantity: update.quantity as number,
      reason: update.reason as string,
      flex_contract_id: update.reason as string,
    };
  });
  const lastUpdatedAt = original.updatedAt as DateTime;

  if (value === 0) {
    return (
      <span
        className={classNames(`${classPrefix}__text`, {
          [`${classPrefix}__text--light`]: cancelled,
        })}
      >
        -
      </span>
    );
  }

  return (
    <Button
      tooltip={`Updated on ${lastUpdatedAt.toFormat(
        'DD'
      )} at ${lastUpdatedAt.toFormat('HH:mm')}`}
      variant="text"
      onClick={() => {
        if (setUpdatesDetailsDialogOpen !== undefined) {
          if (setUpdateDetails !== undefined) {
            setUpdateDetails(
              <ul style={{ paddingLeft: '12px' }}>
                {updates.map(
                  (
                    { startTime, endTime, quantity, reason, flex_contract_id },
                    index
                  ) => {
                    return (
                      <li
                        key={`${flex_contract_id}-update-${index}`}
                        style={{ paddingTop: '0.5rem' }}
                      >
                        Quantity updated to {quantity / 1000}kW starting from{' '}
                        {startTime.toFormat('DD HH:mm')} untill{' '}
                        {endTime.toFormat('DD HH:mm')}.
                        <br />
                        <i style={{ paddingTop: '0.25rem' }}>
                          {reason.length > 0 && <span>{reason}</span>}
                        </i>
                      </li>
                    );
                  }
                )}
              </ul>
            );
          }
          if (setUpdateDetailsForClipboard !== undefined) {
            setUpdateDetailsForClipboard(
              'quantity\t' +
                'from\t' +
                'to\t' +
                'reason\n' +
                updates
                  .map(({ startTime, endTime, quantity, reason }) => {
                    return `${quantity}\t${startTime}\t${endTime}\t${reason}\n`;
                  })
                  .toString()
                  .replaceAll(',', '')
            );
          }
          setUpdatesDetailsDialogOpen(true);
        }
      }}
    >
      <span
        className={classNames(`${classPrefix}__text`, {
          [`${classPrefix}__text--light`]: cancelled,
        })}
      >
        <SVG
          icon="BookOpenIcon"
          fontSize="12px"
          className={classNames({
            [`${classPrefix}__text--disabled`]: cancelled,
            [`${classPrefix}__text--light`]: !cancelled,
          })}
        />
        <span style={{ marginLeft: '8px' }}>{value}</span>
      </span>
    </Button>
  );
}

export function renderLight(cellInfo: any, classPrefix: classPrefixType) {
  const { value } = cellInfo.cell;
  let content = (
    <Tooltip content="Pending" arrow={true} theme="light">
      <Light size="xsmall" variant="warning" />
    </Tooltip>
  );

  if (value === flexItemStatus.processing) {
    content = (
      <Tooltip content="Processing" arrow={true} theme="light">
        <Light size="xsmall" variant="warning" />
      </Tooltip>
    );
  }

  if (value === flexItemStatus.accepted) {
    content = (
      <Tooltip content="Accepted" arrow={true} theme="light">
        <Light size="xsmall" variant="success" />
      </Tooltip>
    );
  }

  if (value === flexItemStatus.rejected) {
    content = (
      <Tooltip content="Rejected" arrow={true} theme="light">
        <Light size="xsmall" variant="error" />
      </Tooltip>
    );
  }

  if (value === flexItemStatus.expired) {
    content = (
      <Tooltip content="Expired" arrow={true} theme="light">
        <Light size="xsmall" variant="off" />
      </Tooltip>
    );
  }

  if (value === flexItemStatus.cancelled) {
    if (classPrefix === 'responses-table') {
      return (
        <div className="responses-table--centered responses-table__text responses-table__text--light">
          Cancelled
        </div>
      );
    }
    if (classPrefix === 'requests-table') {
      return <div className={`${classPrefix}--centered`} />;
    }
    if (classPrefix === 'contracts-table') {
      content = (
        <Tooltip content="Cancelled" arrow={true} theme="light">
          <Light size="xsmall" variant="off" />
        </Tooltip>
      );
    }
  }

  return <div className={`${classPrefix}--centered`}>{content}</div>;
}

export function renderViewExpired(cellInfo: any, classPrefix: classPrefixType) {
  const { original } = cellInfo.cell.row;
  const status = getStatus(original, classPrefix);
  const text =
    status === flexItemStatus.expired || status === flexItemStatus.cancelled
      ? 'Expired'
      : '';

  return (
    <span className={`${classPrefix}__text ${classPrefix}__text--light`}>
      {text}
    </span>
  );
}

export function renderCSA(cellInfo: any, classPrefix: classPrefixType) {
  const { original } = cellInfo.cell.row;
  const cancelled = isCancelled(original, classPrefix);
  return (
    <Tag
      content={'N/A'}
      customClasses={{
        tagClass: classNames(
          `${classPrefix}__tag`,
          `${classPrefix}__tag--darkened`,
          { [`${classPrefix}__text--light`]: cancelled }
        ),
      }}
    />
  );
}

/**
 * Custom date filter function to work with luxon.
 */
export function getFilterDate(
  rows: any,
  columnIds: string[],
  filter: DateTime,
  setFilterDate: React.Dispatch<React.SetStateAction<DateTime | undefined>>
) {
  if (filter === undefined || filter === null || !filter.isValid) {
    setFilterDate(undefined);
    return rows;
  }
  setFilterDate(filter);
  return rows.filter((row: any) => {
    const original = row.original.request || row.original;
    const start =
      original.startTime.toMillis() -
      (original.startTime.hour * 3600 -
        original.startTime.minute * 60 -
        original.startTime.second) *
        1000; // trim startTime to beginning of selected day
    const end = original.endTime.toMillis();
    const selected = filter.toMillis();
    if (selected >= start && selected <= end) {
      return true;
    }
    return false;
  });
}
