import { DateTime } from 'luxon';

export function sortWeekDays(
  weekDays: string[],
  shortDays = false,
  isSundayFirst = false
) {
  if (shortDays) {
    const sorter: any = {
      mon: 1,
      tue: 2,
      wed: 3,
      thu: 4,
      fri: 5,
      sat: 6,
      sun: isSundayFirst ? 0 : 7,
    };

    return weekDays.sort(function sortByDay(a, b) {
      const day1 = a.toLowerCase();
      const day2 = b.toLowerCase();
      return sorter[day1] - sorter[day2];
    });
  }

  const sorter: any = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: isSundayFirst ? 0 : 7,
  };

  return weekDays.sort(function sortByDay(a, b) {
    const day1 = a.toLowerCase();
    const day2 = b.toLowerCase();
    return sorter[day1] - sorter[day2];
  });
}

export function getDateTimeFromWeekDay(weekday: string) {
  const parsingTokens = {
    digit: 'E', // day represented by integer
    short: 'EEE', // ...by first three letters
    default: 'EEEE', // ...by full word
  };

  const dateTimeFromWeekday = DateTime.fromFormat(
    weekday,
    weekday.length === 1
      ? parsingTokens.digit
      : weekday.length === 3
      ? parsingTokens.short
      : parsingTokens.default
  );

  const currentWeekNumber = DateTime.now().weekNumber;
  const weeksToShift = currentWeekNumber - dateTimeFromWeekday.weekNumber;

  return dateTimeFromWeekday.plus({ weeks: weeksToShift });
}
