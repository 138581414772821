import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { DayCellsProps } from '../Calendar.types';
import { isDayDisabled } from '../Calendar.logic';

import './DayCell.scss';

const DayCells = ({ day, state, onSelect }: DayCellsProps) => {
  const isActive = day?.isActive;
  const isAutOfMonth = day.isAutOfMonth;

  const disabled = useMemo(() => isDayDisabled(day, state), [day, state]);

  const _onSelect = useCallback(() => {
    if (!disabled) {
      onSelect && onSelect(day?.date);
    }
  }, [day?.date, onSelect, disabled]);

  return (
    <div
      className={classNames('day-cell', {
        'day-cell--selected': isActive,
        'day-cell--disabled': disabled,
      })}
      onClick={_onSelect}
    >
      <div
        className={classNames('day-cell__item', {
          'day-cell__item--active': isActive,
          'day-cell__item--disabled': disabled,
          'day-cell__item--semiDisabled': isAutOfMonth,
        })}
      >
        {day?.day}
      </div>
    </div>
  );
};

export default memo(DayCells);
