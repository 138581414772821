import React, { isValidElement } from 'react';
import Tippy from '@tippy.js/react';
import { Options } from 'tippy.js';

import './Tooltip.scss';

const DEFAULT_TOOLTIP_EXPIRY = 1500; // http://redmine.vpn.opusonesolutions.com/issues/6891

// Borrow these type definitions from @tippy.js/react but use our own
// definition of `children` since we wrap everything in a span
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface TooltipProps extends Omit<Options, 'content'> {
  className?: string;
  children: React.ReactChild;
  content?: React.ReactChild | React.ReactChild[];
  enabled?: boolean;
  expires?: boolean;
  persist?: boolean;
}

const getClassName = (className: string, isElem: boolean) =>
  `${className} ${isElem ? 'custom-tip' : ''}`;

// @ts-ignore
const Tooltip: React.FunctionComponent<TooltipProps> = ({
  content,
  className = '',
  children,
  arrow = false,
  placement = 'top',
  enabled = true,
  persist = false,
  expires = false,
  ...props
}) => {
  let expiryTimer: NodeJS.Timeout | null;
  return content ? (
    <Tippy
      {...props}
      content={content}
      allowHTML
      arrow={arrow}
      enabled={enabled}
      className={getClassName(className, isValidElement(content))}
      placement={placement}
      showOnInit={persist}
      interactive
      flip
      {...(persist ? { isVisible: true, hideOnClick: false } : {})}
      onShown={({ hide }) => {
        if (expires && !persist) {
          expiryTimer = setTimeout(() => {
            hide();
          }, DEFAULT_TOOLTIP_EXPIRY);
        }
      }}
      onHide={() => {
        if (expiryTimer) {
          clearTimeout(expiryTimer);
        }
      }}
    >
      <span className={`${className} tip-span`}>{children}</span>
    </Tippy>
  ) : (
    children
  );
};

export default Tooltip;
