import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from 'components/Tooltip';
import { RadioButtonProps } from './RadioButton.types';
import './RadioButton.scss';

const RadioButton = ({
  value,
  label,
  id,
  labelPosition,
  disabled,
  type,
  size,
  tooltip,
  disableAnimation,
  classNames,
  styles,
}: RadioButtonProps) => (
  <Tooltip content={tooltip}>
    <FormControlLabel
      id={id}
      disabled={disabled}
      value={value}
      labelPlacement={labelPosition}
      label={label}
      classes={{
        root: 'radio-button',
        label: `radio-button__label--${size} radio-button__label--${type}`,
      }}
      control={
        <Radio
          size="small"
          classes={{
            root: 'radio-button__radio',
            disabled: 'radio-button__radio--disabled',
            checked: 'radio-button__radio--checked',
          }}
          disableRipple={disableAnimation}
          style={styles}
          className={`radio-button--${type} radio-button--${size} ${classNames}`}
        />
      }
    />
  </Tooltip>
);

export default RadioButton;
