import React, { useState, useEffect } from 'react';
import { Request, apm } from '@opusonesolutions/gridos-app-framework';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUserContext } from 'contexts/UserContext';

import { Tenant } from 'types/tenant';

import { Routes } from '../../Participants';

import RouterSwitch from 'components/RouterSwitch';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import IconButton from 'components/IconButton';
import TextInput from 'components/TextInput';
import Switch from 'components/Switch';

import './ParticipantOverview.scss';

export type ParticipantOverviewProps = {
  tenant: Tenant;
  setTenant: (updatedTenant: Tenant) => void;
  canEdit: boolean;
};

export const ParticipantOverview = ({
  tenant,
  setTenant,
  canEdit,
}: ParticipantOverviewProps) => {
  const history = useHistory();
  const { userIsDso } = useUserContext();
  const [editedTenant, setEditTenant] = useState<Tenant>(tenant);
  const [saving, setSaving] = useState(false);
  const [edited, setEdited] = useState(false);
  const [saveMessages, setSaveMessages] = useState<{
    [key: string]: Array<string>;
  }>({});

  const {
    name,
    id,
    phone_number,
    address_line_1,
    country,
    locality,
    administrative_area,
    postal_code,
    billing_account_id,
    enable_p2n,
    enable_p2p,
  } = tenant;
  const [enableP2N, setEnableP2N] = useState(enable_p2n);
  const [enableP2P, setEnableP2P] = useState(enable_p2p);

  const schema = () =>
    yup.object().shape({
      name: yup.string().required(),
      phoneNumber: yup.number().required(),
      streetAddress: yup.string().required(),
      locality: yup.string().required(),
      adminArea: yup.string().required(),
      postalCode: yup.string().required(),
      country: yup.string().required(),
    });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema()),
  });

  useEffect(() => {
    if (tenant.id !== editedTenant.id) {
      setEditTenant(tenant);
      reset();
    }
  }, [editedTenant.id, setEditTenant, tenant, reset]);

  const enableEdit = () => {
    history.push(
      `/participants-and-ders/participants/${tenant.id}/overview/edit`
    );
  };
  const disableEdit = () => {
    reset();
    history.push(`/participants-and-ders/participants/${tenant.id}/overview`);
  };

  const updateKey = (key: keyof Tenant, value: any) => {
    if (editedTenant) {
      setEdited(true);
      setEditTenant({
        ...editedTenant,
        [key]: value,
      });
    }
  };

  const saveTenant = async () => {
    const request = new Request(`/api/dsp/tenants/${id}`);

    setSaving(true);

    try {
      const { data: updatedtenant } = await request.patch(editedTenant);
      setSaveMessages({});
      setTenant({ ...tenant, ...updatedtenant });
      setEdited(false);
    } catch (error: any) {
      const { response } = error;

      if (response.status === 400) {
        setSaveMessages(response.data.message);
      } else if (response.status === 409) {
        // Happens when the billing account ID already exists
        setSaveMessages({
          billing_account_id: [response.data.message],
        });
      }
      apm.captureError(error);
    }
    setSaving(false);
    disableEdit();
  };

  useEffect(() => {
    if (edited) {
      saveTenant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableP2N, enableP2P]);

  function renderEditMode() {
    return (
      <form
        id="update-tenant-information"
        onSubmit={handleSubmit(saveTenant)}
        className="participant-overview__bottom-edit"
      >
        <Controller
          name="name"
          control={control}
          defaultValue={name}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="name"
              label="Name"
              value={value}
              placeholder="Tenant Name"
              onChange={(value) => {
                updateKey('name', value);
                onChange(value);
              }}
              className="participant-overview__input"
              invalid={!!saveMessages.name || errors?.name?.message}
              validationMessage={
                saveMessages.name ? saveMessages.name[0] : errors?.name?.message
              }
              resetEditState={saving}
            />
          )}
        />
        <TextInput
          disabled
          id="billing_account_id"
          label="Account ID"
          value={billing_account_id || ''}
          placeholder="Account ID"
          className="participant-overview__input"
        />
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue={phone_number}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="phone_number"
              label="Phone number"
              value={value}
              placeholder="Phone number"
              onChange={(value) => {
                updateKey('phone_number', value);
                onChange(value);
              }}
              className="participant-overview__input"
              resetEditState={saving}
              invalid={
                !!saveMessages.phoneNumber || errors?.phoneNumber?.message
              }
              validationMessage={
                saveMessages.name
                  ? saveMessages.phoneNumber[0]
                  : errors?.phoneNumber?.message
              }
            />
          )}
        />
        <Controller
          name="streetAddress"
          control={control}
          defaultValue={address_line_1}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="address_line_1"
              label="Street address"
              value={value}
              placeholder="Street address"
              onChange={(value) => {
                updateKey('address_line_1', value);
                onChange(value);
              }}
              className="participant-overview__input"
              resetEditState={saving}
              invalid={errors?.streetAddress?.message}
              validationMessage={errors?.streetAddress?.message}
            />
          )}
        />
        <div className="participant-overview__row">
          <Controller
            name="locality"
            control={control}
            defaultValue={locality}
            render={({ field: { onChange, value } }) => (
              <TextInput
                disabled={saving}
                id="locality"
                label="City"
                value={value}
                placeholder="City"
                onChange={(value) => {
                  updateKey('locality', value);
                  onChange(value);
                }}
                className="participant-overview__input"
                resetEditState={saving}
                invalid={errors?.locality?.message}
                validationMessage={errors?.locality?.message}
              />
            )}
          />
          <Controller
            name="adminArea"
            control={control}
            defaultValue={administrative_area}
            render={({ field: { onChange, value } }) => (
              <TextInput
                disabled={saving}
                id="administrative_area"
                label="State / Province"
                value={value}
                placeholder="State / Province"
                onChange={(value) => {
                  updateKey('administrative_area', value);
                  onChange(value);
                }}
                className="participant-overview__input participant-overview__input--horizontal"
                resetEditState={saving}
                invalid={errors?.adminArea?.message}
                validationMessage={errors?.adminArea?.message}
              />
            )}
          />
          <Controller
            name="postalCode"
            control={control}
            defaultValue={postal_code}
            render={({ field: { onChange, value } }) => (
              <TextInput
                disabled={saving}
                id="postal_code"
                label="Zip / Postal Code"
                value={value}
                placeholder="Zip / Postal Code"
                onChange={(value) => {
                  updateKey('postal_code', value);
                  onChange(value);
                }}
                className="participant-overview__input participant-overview__input--horizontal"
                resetEditState={saving}
                invalid={errors?.postalCode?.message}
                validationMessage={errors?.postalCode?.message}
              />
            )}
          />
        </div>
        <Controller
          name="country"
          control={control}
          defaultValue={country}
          render={({ field: { onChange, value } }) => (
            <TextInput
              disabled={saving}
              id="country"
              label="Country"
              value={value}
              placeholder="Country"
              onChange={(value) => {
                updateKey('country', value);
                onChange(value);
              }}
              className="participant-overview__input"
              resetEditState={saving}
              invalid={errors?.country?.message}
              validationMessage={errors?.country?.message}
            />
          )}
        />
        <div className="participant-overview__actions">
          <Button onClick={saveTenant} disabled={!edited}>
            Save Changes
          </Button>
          <Button onClick={disableEdit} variant="outlined">
            Cancel
          </Button>
        </div>
      </form>
    );
  }

  function renderViewMode() {
    return (
      <div className="participant-overview__bottom">
        <div className="participant-overview__table">
          <div className="participant-overview__label">Name</div>
          <div className="participant-overview__text">{name}</div>
          <div className="participant-overview__label">Account ID</div>
          <div className="participant-overview__text participant-overview__text-id">
            <Tooltip content={id} theme="light" arrow>
              {id}
            </Tooltip>
            <IconButton
              icon="CopyIcon"
              fontSize="12px"
              customClasses={{
                customButtonClass: 'participant-overview__copy',
              }}
              onClick={() => navigator.clipboard.writeText(id)}
            />
          </div>
          <div className="participant-overview__label">Phone Number</div>
          <div className="participant-overview__text">{phone_number}</div>
          <div className="participant-overview__label">Street address</div>
          <div className="participant-overview__text">{address_line_1}</div>
          <div className="participant-overview__label">Country</div>
          <div className="participant-overview__text">{country}</div>
          <div className="participant-overview__label">City</div>
          <div className="participant-overview__text">{locality}</div>
          <div className="participant-overview__label">State / Province</div>
          <div className="participant-overview__text">
            {administrative_area}
          </div>
          <div className="participant-overview__label">ZIP / Postal Code</div>
          <div className="participant-overview__text">{postal_code}</div>
        </div>
        <div>
          {canEdit && (
            <Button variant="text" onClick={enableEdit}>
              Edit
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="participant-overview">
      <div className="participant-overview__top ">
        <div className="participant-overview__label">Service type</div>
        <div className="participant-overview__text">
          <Switch
            label="Peer to network"
            type="primary"
            id="p2n-switch"
            checked={enable_p2n}
            onClick={() => {
              setEnableP2N(!enableP2N);
              updateKey('enable_p2n', !enableP2N);
            }}
            styles={{
              switchStyles: { marginBottom: '8px' },
            }}
            disabled={!userIsDso()}
          />
          <Switch
            label="Peer to peer"
            type="primary"
            id="p2p-switch"
            checked={enable_p2p}
            onClick={() => {
              setEnableP2P(!enableP2P);
              updateKey('enable_p2p', !enableP2P);
            }}
            disabled={!userIsDso()}
          />
        </div>
      </div>
      <RouterSwitch>
        <Route exact path={Routes.edit}>
          {renderEditMode()}
        </Route>
        <Route exact path={Routes.overview}>
          {renderViewMode()}
        </Route>
      </RouterSwitch>
    </div>
  );
};
