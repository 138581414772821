import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import {
  generationMethodRaw,
  serviceType as serviceTypeList,
  serviceTypeRaw,
} from 'types/contract-managment';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import SVG from 'components/SVG';
import Tooltip from 'components/Tooltip';
import Switch from 'components/Switch';
import Button from 'components/Button';

import NumberInput from 'components/NumberInput';
import FormLabel from 'components/FormLabel';
import DialogSection from 'components/Dialog/DialogSection';

import { CreateRequestQuantityProps } from './CreateRequestQuantity.types';
import {
  handleNumberChange,
  onSystemCalculate,
  schema,
} from './CreateRequestQuantity.logic';

import './CreateRequestQuantity.scss';

function CreateRequestQuantity({
  currencySymbol,
  onSubmit,
  data,
  feederID,
  programID,
  onFieldChange,
}: CreateRequestQuantityProps) {
  const [isCalculating, setIsCalculating] = useState(false);
  const [ranCalculation, setRanCalculation] = useState(false);
  const [disableCalculateButton, setDisableCalculateButton] = useState(false);

  const {
    startDate,
    endDate,
    availabilityPrice,
    utilizationPrice,
    quantityGeneration,
    quantity,
    hours,
    systemCalculate,
    serviceType,
    isCalculated,
    includeActive,
    includePending,
    distributedResource,
    procurementVolume,
    requestQuantity,
    bidOffer,
  } = data;

  const isManual = quantityGeneration === generationMethodRaw.manual;
  const isSystemCalculated =
    quantityGeneration === generationMethodRaw.systemGenerated;
  const isForecastBased =
    quantityGeneration === generationMethodRaw.forecastBased;

  const isDynamicManagement =
    serviceType?.value === serviceTypeRaw.DynamicPeakManagement;

  const isSustainExport =
    serviceType?.value === serviceTypeRaw.SustainExportManagement;

  const isP2N =
    serviceType?.value !== serviceTypeRaw.MEC &&
    serviceType?.value !== serviceTypeRaw.MIC;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      schema(
        isCalculated || ranCalculation || isForecastBased,
        distributedResource,
        serviceType,
        bidOffer
      )
    ),
  });

  const { makeRequest: submitSystemCalculate } = useRequest(
    `/api/dsp/program/${programID}/feeder/${feederID}/flex/system_calculated`
  );

  function renderPricing() {
    return (
      <>
        <Grid item>
          <FormLabel htmlFor="availabilityPrice">
            Availability price ceiling
            <Tooltip
              arrow
              content="Price capacity for the distributed energy resource selected."
              theme="light"
            >
              <SVG
                icon="InfoIcon"
                fontSize="12px"
                className="create-request-quantity__label-icon"
              />
            </Tooltip>
          </FormLabel>
          <Controller
            name="availabilityPrice"
            control={control}
            defaultValue={availabilityPrice}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                id="availabilityPrice"
                onChange={(value) => {
                  onChange(handleNumberChange(value));
                  onFieldChange('availabilityPrice', value);
                }}
                unit="/kW/h"
                prefix={currencySymbol}
                value={value}
                placeholder="Price"
                invalid={!!errors?.availabilityPrice?.message}
                disabled={isDynamicManagement}
              />
            )}
          />
          <div className="create-request-setup__error">
            {errors?.availabilityPrice?.message}
          </div>
        </Grid>
        {serviceType?.value !== serviceTypeList.MEC &&
          serviceType?.value !== serviceTypeList.MIC && (
            <Grid item>
              <FormLabel htmlFor="utilizationPrice">
                Utilization Price Ceiling
                <Tooltip
                  arrow={true}
                  content="The price for asset utilization across the duration of the contract period."
                  theme="light"
                >
                  <SVG
                    icon="InfoIcon"
                    fontSize="12px"
                    className="create-request-quantity__label-icon"
                  />
                </Tooltip>
              </FormLabel>
              <Controller
                name="utilizationPrice"
                control={control}
                defaultValue={utilizationPrice}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    id="utilizationPrice"
                    onChange={(value) => {
                      onChange(handleNumberChange(value));
                      onFieldChange('utilizationPrice', value);
                    }}
                    unit="/kWh"
                    prefix={currencySymbol}
                    value={value}
                    placeholder="Price"
                    invalid={!!errors?.utilizationPrice?.message}
                  />
                )}
              />
              <div className="create-request-setup__error">
                {errors?.utilizationPrice?.message}
              </div>
            </Grid>
          )}
      </>
    );
  }

  function renderCalculateSection() {
    return (
      <>
        <Grid item xs={12} className="create-request-quantity__toggles">
          <div className="create-request-quantity__label">
            Procurement volume %
            <Tooltip
              arrow={true}
              content="Include existing contracts (sustain peak management)."
              theme="light"
            >
              <SVG
                icon="InfoIcon"
                fontSize="12px"
                className="create-request-quantity__label-icon"
              />
            </Tooltip>
          </div>
          <Controller
            name="procurementVolume"
            control={control}
            defaultValue={procurementVolume}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                id="procurementVolume"
                onChange={(value) => {
                  onChange(Number(value));
                  onFieldChange('procurementVolume', Number(value));
                }}
                unit="%"
                min={0}
                max={100}
                step="1"
                value={value}
                invalid={!!errors?.procurementVolume?.message}
              />
            )}
          />
          <div
            style={{ marginBottom: '8px' }}
            className="create-request-quantity__label"
          >
            Contract status
            <Tooltip
              arrow={true}
              content="Include existing contracts (sustain peak management)."
              theme="light"
            >
              <SVG
                icon="InfoIcon"
                fontSize="12px"
                className="create-request-quantity__label-icon"
              />
            </Tooltip>
          </div>
          <Switch
            id="system-calculated-include-active"
            checked={includeActive}
            onClick={() => {
              setDisableCalculateButton(false);
              onFieldChange('includeActive', !includeActive);
            }}
            label="Include active contracts"
            type="primary"
            classNames={{
              switchClass: 'create-request-quantity__toggles-toggle',
            }}
          />
          <Switch
            id="system-calculated-include-pending"
            checked={includePending}
            onClick={() => {
              setDisableCalculateButton(false);
              onFieldChange('includePending', !includePending);
            }}
            label="Include pending contracts"
            type="primary"
            classNames={{
              switchClass: 'create-request-quantity__toggles-toggle',
            }}
          />
          {isSystemCalculated && (
            <>
              <Button
                customClasses={{
                  customButtonClass: 'create-request-quantity__toggles-action',
                }}
                onClick={() =>
                  onSystemCalculate(
                    setIsCalculating,
                    setRanCalculation,
                    submitSystemCalculate,
                    startDate,
                    endDate,
                    includePending,
                    includeActive,
                    setDisableCalculateButton,
                    onFieldChange,
                    setValue,
                    procurementVolume,
                    serviceType?.value
                  )
                }
                loading={isCalculating}
                disabled={disableCalculateButton}
              >
                Generate quantity
              </Button>
              <div className="create-request-setup__error create-request-setup__error-mt-10">
                {!systemCalculate && errors?.systemCalculate?.message}
              </div>
            </>
          )}
        </Grid>
      </>
    );
  }

  return (
    <form
      id="create-request-quantity"
      onSubmit={handleSubmit((data) => onSubmit(data, ranCalculation))}
    >
      <DialogSection
        classNames={{ headingClass: 'create-request-service__section-title' }}
        title={'Service price'}
        icon="ClipboardIcon"
      >
        <Grid container spacing={2}>
          {renderPricing()}
        </Grid>
      </DialogSection>
      {(isSystemCalculated || isForecastBased) && (
        <DialogSection
          classNames={{ headingClass: 'create-request-service__section-title' }}
          title="Scheduled events quantity"
          icon="SlidersIcon"
        >
          <Grid container spacing={2}>
            {renderCalculateSection()}
          </Grid>
        </DialogSection>
      )}
      {isSystemCalculated &&
        (ranCalculation || (hours !== 0 && quantity !== 0)) && (
          <DialogSection
            classNames={{
              headingClass: 'create-request-service__section-title',
            }}
            title={'System calculated quantity'}
            icon="SlidersIcon"
          >
            <div className="create-request-quantity__inputs">
              <div>
                <label
                  htmlFor="quantity"
                  className="create-request-quantity__label"
                >
                  Maximum Quantity
                </label>
                <Controller
                  name="quantity"
                  control={control}
                  defaultValue={quantity}
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      id="quantity"
                      unit="kW"
                      prefix={isSustainExport ? '-' : ''}
                      value={value}
                      placeholder="Quantity"
                      invalid={!!errors?.quantity?.message}
                      disabled
                    />
                  )}
                />
              </div>
              <div>
                <label
                  htmlFor="requestQuantity"
                  className="create-request-quantity__label"
                >
                  Request Quantity
                </label>
                <Controller
                  name="requestQuantity"
                  control={control}
                  defaultValue={requestQuantity}
                  render={({ field: { value } }) => (
                    <NumberInput
                      id="requestQuantity"
                      unit="kW"
                      prefix={isSustainExport ? '-' : ''}
                      value={value}
                      placeholder="Quantity"
                      invalid={!!errors?.requestQuantity?.message}
                      disabled
                    />
                  )}
                />
              </div>
              <div>
                <label
                  htmlFor="hours"
                  className="create-request-quantity__label"
                >
                  Hours
                </label>
                <Controller
                  name="hours"
                  control={control}
                  defaultValue={hours}
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      id="hours"
                      onChange={(value) => {
                        onChange(Number(value));
                        onFieldChange('hours', Number(value));
                      }}
                      value={value}
                      invalid={!!errors?.hours?.message}
                      disabled={!isSustainExport}
                    />
                  )}
                />
                <div className="create-request-setup__error create-request-setup__error-mt-10">
                  {errors?.hours?.message}
                </div>
              </div>
            </div>
          </DialogSection>
        )}
      {isManual && (
        <DialogSection
          classNames={{ headingClass: 'create-request-service__section-title' }}
          title="Manual input quantity"
          icon="SlidersIcon"
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormLabel
                required
                htmlFor="quantity"
                styles={{ maxWidth: '130px' }}
              >
                Request quantity
                <Tooltip
                  arrow={true}
                  content="Request quantity must be equal to or less than the physical or commercial capacity."
                  theme="light"
                >
                  <SVG
                    icon="InfoIcon"
                    fontSize="12px"
                    className="create-request-quantity__label-icon"
                  />
                </Tooltip>
              </FormLabel>
              <Controller
                name="quantity"
                control={control}
                defaultValue={
                  quantity && quantity < 0 ? quantity * -1 : quantity
                }
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    id="quantity"
                    onChange={(value) => {
                      onChange(Number(value));
                      onFieldChange(
                        'quantity',
                        isSustainExport ? Number(value) * -1 : Number(value)
                      );
                    }}
                    unit="kW"
                    prefix={isSustainExport ? '-' : ''}
                    value={value}
                    placeholder="Quantity"
                    invalid={!!errors?.quantity?.message}
                  />
                )}
              />
              <div className="create-request-setup__error">
                {errors?.quantity?.message}
              </div>
            </Grid>
            <Grid item>
              <label htmlFor="hours" className="create-request-quantity__label">
                Duration
              </label>
              <Controller
                name="hours"
                control={control}
                defaultValue={hours}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    id="hours"
                    onChange={(value) => {
                      onChange(Number(value) || 0);
                      onFieldChange('hours', Number(value) || 0);
                    }}
                    value={value}
                    invalid={!!errors?.hours?.message}
                  />
                )}
              />
              <div className="create-request-setup__error">
                {errors?.hours?.message}
              </div>
            </Grid>
            {isP2N && (
              <Grid item marginLeft={2}>
                <label
                  htmlFor="utilizationTime"
                  className="create-request-quantity__label"
                >
                  Max Utilization Time
                </label>
                <Controller
                  name="utilizationTime"
                  control={control}
                  defaultValue={2}
                  render={({ field: { onChange, value } }) => (
                    <NumberInput
                      disabled
                      id="utilizationTime"
                      unit="hours"
                      onChange={(value) => {
                        onChange(Number(value) || 0);
                        onFieldChange('utilizationTime', Number(value) || 0);
                      }}
                      value={value}
                      invalid={!!errors?.hours?.message}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogSection>
      )}
    </form>
  );
}

export default CreateRequestQuantity;
