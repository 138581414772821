import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import PageTitle from 'components/PageTitle';
import MenuItem from 'components/Menu/MenuItem/MenuItem';
import MenuList from 'components/Menu/MenuList';

import { Routes } from 'routes/Program/routes/Settings/Settings';

import './SettingsMenu.scss';

export const SettingsMenu = ({ programID }: { programID: string }) => {
  const history = useHistory();

  const IsActiveRoute = (route: string) => useRouteMatch(route)?.isExact;

  return (
    <div className="settings-menu">
      <PageTitle
        title="Settings"
        classes={{ titleClass: 'settings-menu__title' }}
      />
      <MenuList>
        <MenuItem
          onClick={() => history.push(`/program/${programID}/settings/basic`)}
          classes={{
            rootClass: classNames('settings-menu__text', {
              'settings-menu__active': IsActiveRoute(Routes.basic),
            }),
          }}
        >
          Basic
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(`/program/${programID}/settings/advanced`)
          }
          classes={{
            rootClass: classNames('settings-menu__text', {
              'settings-menu__active': IsActiveRoute(Routes.advanced),
            }),
          }}
        >
          Advanced
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(`/program/${programID}/settings/financial-model`)
          }
          classes={{
            rootClass: classNames('settings-menu__text', {
              'settings-menu__active': IsActiveRoute(Routes.financialModel),
            }),
          }}
        >
          Financial model
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(`/program/${programID}/settings/contract-selection`)
          }
          classes={{
            rootClass: classNames('settings-menu__text', {
              'settings-menu__active': IsActiveRoute(Routes.contractSelection),
            }),
          }}
        >
          Contract selection analysis
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(`/program/${programID}/settings/email-notifications`)
          }
          classes={{
            rootClass: classNames('settings-menu__text', {
              'settings-menu__active': IsActiveRoute(Routes.emailNotifications),
            }),
          }}
        >
          Email notifications
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(`/program/${programID}/settings/needs-analysis`)
          }
          classes={{
            rootClass: classNames('settings-menu__text', {
              'settings-menu__active': IsActiveRoute(Routes.needsAnalysis),
            }),
          }}
        >
          Needs analysis
        </MenuItem>
      </MenuList>
    </div>
  );
};
