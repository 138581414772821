import React from 'react';
import * as yup from 'yup';
import { DateTime } from 'luxon';

import SVG from 'components/SVG';
import Tooltip from 'components/Tooltip';
import { generationMethodRaw, siaListItem } from 'types/contract-managment';
import { closeAfterEnum, recurrenceEnum } from './CreateRequestSetup.types';
import { getIsForecastBased, getMaxSiaDate } from '../CreateRequest.logic';

export const quantityGenerationTypesAccess = (isWSC: boolean) => ({
  dso: [true, true, isWSC],
  default: [true, false, false],
});

export const quantityGenerationTypes = [
  {
    id: generationMethodRaw.manual,
    label: (
      <div className="create-request-quantity__radio-text">
        Manual input
        <Tooltip arrow content="Manually input required quantity" theme="light">
          <SVG
            icon="InfoIcon"
            fontSize="12px"
            className="create-request-quantity__label-icon create-request-quantity__radio-icon"
          />
        </Tooltip>
      </div>
    ),
  },
  {
    id: generationMethodRaw.systemGenerated,
    label: (
      <div className="create-request-quantity__radio-text">
        Scheduled events
        <Tooltip
          arrow
          content="Required quantity calculated based on a schedule"
          theme="light"
        >
          <SVG
            icon="InfoIcon"
            fontSize="12px"
            className="create-request-quantity__label-icon create-request-quantity__radio-icon"
          />
        </Tooltip>
      </div>
    ),
  },
  {
    id: generationMethodRaw.forecastBased,
    label: (
      <div className="create-request-quantity__radio-text">
        Forecast based
        <Tooltip
          arrow
          content="Required quantity calculated based on the power flow analysis"
          theme="light"
        >
          <SVG
            icon="InfoIcon"
            fontSize="12px"
            className="create-request-quantity__label-icon create-request-quantity__radio-icon"
          />
        </Tooltip>
      </div>
    ),
  },
];

export const closeAfterOptions = [
  { label: '24 hours', id: closeAfterEnum.TWENTY_FOUR_HOURS, hours: 24 },
  { label: '48 hours', id: closeAfterEnum.FOURTY_EIGHT_HOURS, hours: 48 },
  { label: '72 hours', id: closeAfterEnum.SEVENTY_TWO_HOURS, hours: 72 },
  { label: 'Custom', id: closeAfterEnum.CUSTOM, hours: undefined },
];

export const recurrenceOptions = [
  { label: 'One time', id: recurrenceEnum.ONE_TIME },
  { label: 'Weekly', id: recurrenceEnum.WEEKLY, disabled: true },
  {
    label: 'Bi-weekly',
    id: recurrenceEnum.BI_WEEKLY,
    disabled: true,
  },
  { label: 'Monthly', id: recurrenceEnum.MONTHLY, disabled: true },
];

export const setCloseHandler = (
  id: string,
  getValues: (key: any[]) => DateTime[],
  setValue: (name: string, value: any) => void
) => {
  // set the close date and time based on close after option
  const [openDate, openTime]: DateTime[] = getValues(['openDate', 'openTime']);
  if (id === undefined) return;
  if (id === closeAfterEnum.TWENTY_FOUR_HOURS)
    setValue('closeDate', openDate.plus({ days: 1 }));
  if (id === closeAfterEnum.FOURTY_EIGHT_HOURS)
    setValue('closeDate', openDate.plus({ days: 2 }));
  if (id === closeAfterEnum.SEVENTY_TWO_HOURS)
    setValue('closeDate', openDate.plus({ days: 3 }));
  if (id === closeAfterEnum.CUSTOM) {
    setValue('closeTime', openTime);
  }
};

const compareDays = (start: DateTime, end: DateTime) => {
  const startVal = start.toLocal().startOf('day').valueOf();
  const endVal = end.toLocal().startOf('day').valueOf();
  return startVal === endVal;
};

const validateMaxDate = (
  context: any,
  value: Date | undefined,
  siaList: siaListItem | undefined
): boolean => {
  const isForecastBased = getIsForecastBased(
    context.parent.quantityGeneration,
    undefined
  );
  const maxSiaDate = siaList && getMaxSiaDate(siaList);
  if (value && isForecastBased && maxSiaDate) {
    return (
      DateTime.fromJSDate(value).startOf('day') < maxSiaDate.startOf('day')
    );
  }
  return true;
};

export const schema = (today: DateTime, siaList?: siaListItem) =>
  yup.object().shape({
    openDate: yup
      .date()
      .required('Open date is required.')
      .test(
        'in-future',
        'Select today or a date in the future.',
        (value): boolean => {
          if (!value) {
            return false;
          }
          const isSameDay = compareDays(DateTime.fromJSDate(value), today);
          const isInFuture =
            DateTime.fromJSDate(value).startOf('day') > today.startOf('day');
          return isSameDay || isInFuture;
        }
      )
      .test(
        'is-max',
        'Post request on is out of a sia range date.',
        (value, context): boolean => validateMaxDate(context, value, siaList)
      ),
    openTime: yup.date().required('Open time is required.'),
    closeDate: yup
      .date()
      .required('Close date is required.')
      .test(
        'in-future',
        'Select a close date that does not occur before the start date.',
        (closeDate, ctx): boolean => {
          const openDate = ctx.parent.openDate;
          if (!openDate || !closeDate) {
            return false;
          }
          // ignore the times
          const diff = DateTime.fromJSDate(closeDate)
            .startOf('day')
            .diff(DateTime.fromJSDate(openDate).startOf('day'), 'days');
          return diff.get('days') >= 0;
        }
      )
      .test(
        'is-max',
        'Close request on is out of a sia range date.',
        (value, context): boolean => validateMaxDate(context, value, siaList)
      ),

    closeTime: yup
      .date()
      .required('Close time is required.')
      .test(
        'in-future',
        'Close date and time must be at least one hour after the request has been created.',
        (closeTime, ctx): boolean => {
          let openDate = ctx.parent.openDate;
          let openTime = ctx.parent.openTime;
          let closeDate = ctx.parent.closeDate;
          if (!openDate || !closeDate || !openTime || !closeTime) {
            return false;
          }
          const actualCloseTime = DateTime.fromJSDate(closeTime);
          openTime = DateTime.fromJSDate(openTime);
          openDate = DateTime.fromJSDate(openDate).startOf('day').set({
            hour: openTime.hour,
            minute: openTime.minute,
          });
          closeDate = DateTime.fromJSDate(closeDate).startOf('day').set({
            hour: actualCloseTime.hour,
            minute: actualCloseTime.minute,
          });
          return closeDate.diff(openDate, 'hours').get('hours') >= 1;
        }
      ),
  });
