import { DateTime } from 'luxon';
import { IdType, Row } from 'react-table';

import { SelectOption } from 'types';
import { flexContract } from 'types/contract-managment';
import { CurrentUser } from 'types/user';

// List items should match the selector from column definitions.
export type columns =
  | 'servicePeriod'
  | 'serviceWindow'
  | 'serviceType'
  | 'bidOrOffer'
  | 'quantity'
  | 'availability'
  | 'utilization'
  | 'zone'
  | 'responder'
  | 'requestor'
  | 'csa'
  | 'status'
  | 'updates'
  | 'actions';

export interface ContractsTableParams {
  showColumns: columns[];
  data: flexContract[];
  programID: string;
  loggedInUser: CurrentUser;
  refetchContracts: () => void;
  placeHolderRow?: () => React.ReactElement;
  hideFilters?: boolean;
  showPSATime?: boolean;
  gateTime?: DateTime;
}

export const isInTimeRange = (startTime: DateTime, gateTime?: DateTime) => {
  const currentTime = DateTime.local();
  return (gateTime && gateTime > currentTime) || startTime > currentTime;
};

export const getServices = (data: flexContract[]) => {
  const relevantData = data
    .map((row) => row.response.serviceType)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  const x: SelectOption[] = [];
  if (relevantData.length > 1) {
    x.unshift({ label: 'All Service Types', value: 'all' });
  }
  return x.concat(
    relevantData.map((value) => ({
      label: value,
      value,
    }))
  );
};

export const getStatusList = (
  data: flexContract[],
  servicesFilters: Record<string, any>
) => {
  const serviceFilterItem = servicesFilters.find(
    (i: Record<string, string>) => i.id === 'response.serviceType'
  )?.value;

  const relevantData = data.reduce((acc, contract) => {
    const status: string = contract.status;
    if (serviceFilterItem && serviceFilterItem !== contract.request.service) {
      return { ...acc, [status]: acc[status] || 0 };
    }
    return {
      ...acc,
      [status]: acc[status] ? ++acc[status] : 1,
    };
  }, {} as Record<string, any>);

  const x: SelectOption[] = [];
  const keys: string[] = Object.keys(relevantData);

  let allCount = 0;
  const y = x.concat(
    keys.map((value) => {
      allCount += relevantData[value];
      return {
        label: `${value[0].toUpperCase()}${value.slice(1, value.length)} (${
          relevantData[value]
        })`,
        value,
      };
    })
  );

  if (keys.length > 1) {
    y.unshift({ label: `All (${allCount})`, value: 'all' });
  }

  return y;
};

/**
 * Custom date sort function to work with luxon.
 */
export const getDateSort = (
  rowA: any,
  rowB: any,
  col?: string,
  desc?: boolean
) => {
  const date1 = rowA.original.request.startTime.toMillis();
  const date2 = rowB.original.request.startTime.toMillis();
  if (date1 >= date2) {
    return 1;
  }
  return -1;
};

export const getModelFilter = (
  rows: Row<any>[],
  ids: IdType<any>[],
  query: string
) => {
  if (query === 'Both') {
    return rows;
  }

  const x = rows.filter((row: any) => {
    if (query === 'P2P') {
      if (
        !row.original.request.requestor.is_dso &&
        !row.original.response.responder.is_dso
      ) {
        return true;
      }
      return false;
    }

    if (query === 'P2N') {
      if (
        row.original.request.requestor.is_dso ||
        row.original.response.responder.is_dso
      ) {
        return true;
      }
      return false;
    }
    // no valid value selected return everything
    return true;
  });
  return x;
};
