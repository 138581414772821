import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';

import './RadioButtonGroup.scss';

type RadioButtonListType = 'row' | 'column';

export interface RadioButtonGroupOptions {
  id: string;
  label: string | ReactNode | null;
  body?: string | ReactNode | null;
  disabled?: boolean;
}

interface RadioButtonProps {
  id: string;
  label: string | ReactNode;
  listType: RadioButtonListType;
  onChange: (value: any) => void;
  options: RadioButtonGroupOptions[];
  value: any;
  classes?: {
    btnClass?: string;
    groupClass?: string;
  };
  styles?: {
    groupStyle?: React.CSSProperties | undefined;
  };
}

const defaultStyles = {
  groupStyle: undefined,
};

class RadioButtonGroup extends PureComponent<RadioButtonProps> {
  static defaultProps = {
    listType: 'row',
    label: '',
  };

  render() {
    const {
      id,
      label,
      listType,
      onChange,
      options,
      value,
      classes = {},
      styles = defaultStyles,
    } = this.props;
    const { btnClass, groupClass } = classes;
    const { groupStyle } = styles;

    return (
      <div
        className={classNames(
          `radio-btn-group radio-btn-group--${listType}`,
          groupClass
        )}
        style={groupStyle}
      >
        <label className="label">{label}</label>
        {options.map((option) => (
          <div className="option-group" key={option.id}>
            <input
              className="radio-btn-input"
              type="radio"
              value={option.id}
              id={`${id}-${option.id}`}
              name={id}
              onChange={(e) => onChange(e.target.value)}
              disabled={option.disabled}
              checked={value === option.id}
            />
            <div
              role="button"
              tabIndex={0}
              onClick={() => !option.disabled && onChange(option.id)}
              onKeyPress={({ key }) =>
                key === 'Enter' && !option.disabled && onChange(option.id)
              }
              className="option-label-container"
            >
              <label htmlFor={`${id}-${option.id}`}>
                <div className="option-icon-group">
                  <div
                    className={classNames(
                      'radio-btn',
                      {
                        'radio-btn--selected': value === option.id,
                      },
                      btnClass
                    )}
                  >
                    <div className="radio-btn-fill" />
                  </div>
                  <span>{option.label}</span>
                </div>
              </label>
              {option.body && (
                <div className="option-label-body">{option.body}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RadioButtonGroup;
