import React from 'react';
import { default as MUIMenu } from '@mui/material/Menu';

import { MenuProps } from './Menu.types';

const Menu = ({
  children,
  open,
  anchorEl,
  id,
  onClose,
  PaperProps,
  MenuListProps,
  transformOrigin,
  anchorOrigin,
  autoFocus = true,
}: MenuProps) => {
  const transformOriginProp = transformOrigin || {
    vertical: 'top',
    horizontal: 'left',
  };

  const anchorOriginProp = anchorOrigin || {
    vertical: 'bottom',
    horizontal: 'left',
  };
  return (
    <MUIMenu
      MenuListProps={MenuListProps}
      onClose={onClose}
      id={id}
      open={open}
      anchorEl={anchorEl}
      PaperProps={PaperProps}
      anchorOrigin={anchorOriginProp}
      transformOrigin={transformOriginProp}
      autoFocus={autoFocus}
    >
      {children}
    </MUIMenu>
  );
};

export default Menu;
