import React, { FunctionComponent, useRef, useState, useMemo } from 'react';

import { ChartDataset } from 'chart.js';
import { DateTime } from 'luxon';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import ChartWrapper from 'components/ChartWrapper';
// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'components/OldDatePicker';
import FileForm from 'components/FileForm';
import IconButton from 'components/IconButton';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import Select from 'components/OldSelect';
import Button from 'components/Button';

import fileExportSave from 'helpers/downloadFile';

import './AssetForecastPanel.scss';
import 'components/Button/Button.scss';

type DERType =
  | 'BESS'
  | 'GenSet'
  | 'Hydro'
  | 'Load'
  | 'PhotoVoltaic'
  | 'Wind'
  | undefined;

type ForecastData = {
  generation: {
    [key: string]: {
      pA: number;
      pB: number;
      pC: number;
      pABC: number;
      qA: number;
      qB: number;
      qC: number;
      qABC: number;
    };
  };
  timestamp: string;
};

type AssetForecastPanelProps = {
  derName: string;
  derType: DERType;
  hasGeneratedForecast: boolean;
  program_id: string;
  rdf_id: string;
  timezone: string;
};

const MARKET_TYPES = [
  { label: 'Day Ahead', value: 'dayahead' },
  { label: 'Same Day', value: 'sameday' },
];

const AssetForecastPanel: FunctionComponent<AssetForecastPanelProps> = ({
  derName,
  derType,
  hasGeneratedForecast,
  program_id,
  rdf_id,
  timezone,
}) => {
  const [date, setDate] = useState(DateTime.local().startOf('day'));
  const [fileData, setFileData] = useState(new FormData());
  const [filename, setFilename] = useState('');
  const [hasData, setHasData] = useState(false);
  const [inUploadMode, setInUploadMode] = useState(false);
  const [uploadMarketType, setUploadMarketType] = useState('dayahead');
  const uploadFormRef = useRef<HTMLFormElement | null>(null);
  const loadSign = derType && derType.includes('Load') ? 1 : -1;

  // Request for GridOS generation forecasts
  const {
    data: gridOsChartDataSameDay,
    loading: loadingGridOsDataSameDay,
    refetch: refetchGridOsDataSameDay,
  } = useRequestEffect<ChartDataset<'line'>>({
    url: `/api/measurement/program/${program_id}/forecasts-generation/SYSTEM_GENERATED_FORECAST`,
    method: 'get',
    refetchOnChange: [program_id, date],
    params: {
      start_time: date.toISO(),
      end_time: date.plus({ days: 1 }).startOf('day').toISO(),
      rdf_id,
      market_type: 'sameday',
    },
    dataTransform: (data: Array<ForecastData>) => {
      return {
        label: 'GridOS: Same Day',
        backgroundColor: 'hsl(270, 100%, 25%)',
        borderColor: 'hsl(270, 100%, 25%)',
        fill: false,
        stepped: 'before',
        pointRadius: 0,
        data: data.map((forecastData) => {
          const value = forecastData['generation'][rdf_id].pABC;
          return {
            x: DateTime.fromISO(forecastData.timestamp).valueOf(),
            y: loadSign * value,
          };
        }),
      };
    },
    toast: {
      error: 'Could not load GridOS sameday forecast data',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const {
    data: gridOsChartDataDayAhead,
    loading: loadingGridOsDataDayAhead,
    refetch: refetchGridOsDataDayAhead,
  } = useRequestEffect<ChartDataset<'line'>>({
    url: `/api/measurement/program/${program_id}/forecasts-generation/SYSTEM_GENERATED_FORECAST`,
    method: 'get',
    refetchOnChange: [program_id, date],
    params: {
      start_time: date.toISO(),
      end_time: date.plus({ days: 1 }).startOf('day').toISO(),
      rdf_id,
      market_type: 'dayahead',
    },
    dataTransform: (data: Array<ForecastData>) => {
      return {
        label: 'GridOS: Day Ahead',
        backgroundColor: 'hsl(0, 100%, 25%)',
        borderColor: 'hsl(0, 100%, 25%)',
        fill: false,
        stepped: 'before',
        pointRadius: 0,
        data: data.map((forecastData) => {
          const value = forecastData['generation'][rdf_id].pABC;
          return {
            x: DateTime.fromISO(forecastData.timestamp).valueOf(),
            y: loadSign * value,
          };
        }),
      };
    },
    toast: {
      error: 'Could not load GridOS dayahead forecast data',
      settings: {
        autoDismiss: true,
      },
    },
  });

  // Request for user-supplied generation forecasts
  const {
    data: userChartDataSameDay,
    loading: loadingUserDataSameDay,
    refetch: refetchUserDataSameDay,
  } = useRequestEffect<ChartDataset<'line'>>({
    url: `/api/measurement/program/${program_id}/forecasts-generation/USER_UPLOADED_FORECAST`,
    method: 'get',
    refetchOnChange: [program_id, date],
    params: {
      start_time: date.toISO(),
      end_time: date.plus({ days: 1 }).startOf('day').toISO(),
      rdf_id,
      market_type: 'sameday',
    },
    dataTransform: (data: Array<ForecastData>) => {
      return {
        label: 'User: Same Day',
        backgroundColor: 'hsl(90, 100%, 25%)',
        borderColor: 'hsl(90, 100%, 25%)',
        fill: false,
        stepped: 'before',
        pointRadius: 0,
        data: data.map((forecastData) => {
          const value = forecastData['generation'][rdf_id].pABC;
          return {
            x: DateTime.fromISO(forecastData.timestamp).valueOf(),
            y: loadSign * value,
          };
        }),
      };
    },
    toast: {
      error: 'Could not load user-supplied sameday forecast data',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const {
    data: userChartDataDayAhead,
    loading: loadingUserDataDayAhead,
    refetch: refetchUserDataDayAhead,
  } = useRequestEffect<ChartDataset<'line'>>({
    url: `/api/measurement/program/${program_id}/forecasts-generation/USER_UPLOADED_FORECAST`,
    method: 'get',
    refetchOnChange: [program_id, date],
    params: {
      start_time: date.toISO(),
      end_time: date.plus({ days: 1 }).startOf('day').toISO(),
      rdf_id,
      market_type: 'dayahead',
    },
    dataTransform: (data: Array<ForecastData>) => {
      return {
        label: 'User: Day Ahead',
        backgroundColor: 'hsl(180, 100%, 25%)',
        borderColor: 'hsl(180, 100%, 25%)',
        fill: false,
        stepped: 'before',
        pointRadius: 0,
        data: data.map((forecastData) => {
          const value = forecastData['generation'][rdf_id].pABC;
          return {
            x: DateTime.fromISO(forecastData.timestamp).valueOf(),
            y: loadSign * value,
          };
        }),
      };
    },
    toast: {
      error: 'Could not load user-supplied dayahead forecast data',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const generatedDAForecastLoading =
    hasGeneratedForecast &&
    loadingGridOsDataDayAhead &&
    !gridOsChartDataDayAhead;
  const generatedSDForecastLoading =
    hasGeneratedForecast && loadingGridOsDataSameDay && !gridOsChartDataSameDay;
  const exportUrl = useMemo(() => {
    if (
      !loadingUserDataSameDay &&
      userChartDataSameDay &&
      !generatedSDForecastLoading
    ) {
      const forecastId = userChartDataSameDay.data?.length
        ? 'USER_UPLOADED_FORECAST'
        : 'SYSTEM_GENERATED_FORECAST';
      return `/api/measurement/program/${program_id}/forecasts-generation/${forecastId}/export`;
    }
    return '';
  }, [
    loadingUserDataSameDay,
    userChartDataSameDay,
    program_id,
    generatedSDForecastLoading,
  ]);

  const { makeRequest: runExport, loading: downloading } =
    useRequest(exportUrl);

  const downloadReport = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
    const forecastName = userChartDataSameDay?.data?.length
      ? 'user_forecast'
      : 'gridos_forecast';

    await runExport({
      method: 'get',
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(
          data,
          headers,
          `${derName}-${forecastName}-${date.toISODate()}-sameday-export.csv`
        );
      },
      onError: undefined,
      toast: {
        error: 'Could not export forecast.',
        settings: {
          autoDismiss: true,
        },
      },

      // Request options
      // @ts-ignore
      params: {
        start_date: date.toISO(),
        end_date: date.plus({ days: 1 }).startOf('day').toISO(),
        market_type: 'sameday',
        rdf_id,
        timezone,
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    await runExport({
      method: 'get',
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(
          data,
          headers,
          `${derName}-${forecastName}-${date.toISODate()}-dayahead-export.csv`
        );
      },
      onError: undefined,
      toast: {
        error: 'Could not export forecast.',
        settings: {
          autoDismiss: true,
        },
      },

      // Request options
      // @ts-ignore
      params: {
        start_date: date.toISO(),
        end_date: date.plus({ days: 1 }).startOf('day').toISO(),
        market_type: 'dayahead',
        rdf_id,
        timezone,
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const { makeRequest: runUpload, loading: saving } = useRequest(
    `/api/measurement/program/${program_id}/asset/${rdf_id}/generation_forecast/USER_UPLOADED_FORECAST/market/${uploadMarketType}`
  );

  const uploadForecastData = async () => {
    await runUpload({
      method: 'post',
      body: fileData,
      dataTransform: undefined,
      blockRequest: undefined,
      onSuccess: () => {
        refetchUserDataDayAhead();
        refetchUserDataSameDay();
        refetchGridOsDataDayAhead();
        refetchGridOsDataSameDay();
        cancelUpload(); // exits upload mode
      },
      onError: undefined,
      toast: {
        error: 'Could not upload forecast data.',
        success: 'Successfully uploaded forecast data.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const cancelUpload = () => {
    setInUploadMode(false);
    setFileData(new FormData());
    setFilename('');
    setHasData(false);

    if (uploadFormRef.current) {
      uploadFormRef.current.reset();
    }
  };

  const datasets: ChartDataset<'line'>[] = [];

  if (
    hasGeneratedForecast &&
    gridOsChartDataDayAhead &&
    gridOsChartDataSameDay
  ) {
    datasets.push(gridOsChartDataDayAhead);
    datasets.push(gridOsChartDataSameDay);
  }

  if (userChartDataDayAhead && userChartDataSameDay) {
    datasets.push(userChartDataDayAhead, userChartDataSameDay);
  }

  const yAxisLabel =
    derType && derType.includes('Load')
      ? 'Load Forecast (MW)'
      : 'Generation Forecast (MW)';

  return (
    <div className="generation-forecast-panel">
      <div className="generation-forecast-panel-header">
        <OldDatePicker
          date={date}
          onClose={(d: DateTime) => setDate(d)}
          useUTC={false}
        />
        <div className="right">
          <IconButton
            icon={inUploadMode ? 'close' : 'create'}
            onClick={() => {
              setInUploadMode(!inUploadMode);
              if (inUploadMode) {
                // We are exiting upload mode
                cancelUpload();
              }
            }}
            tooltip={inUploadMode ? 'Close' : 'Upload Forecast Data'}
          />
          <IconButton
            disabled={downloading}
            icon="get_app"
            onClick={() => downloadReport()}
            tooltip="Download Forecast"
          />
        </div>
      </div>
      {!loadingUserDataSameDay &&
        !loadingUserDataDayAhead &&
        userChartDataSameDay &&
        userChartDataDayAhead &&
        !generatedDAForecastLoading &&
        !generatedSDForecastLoading && (
          <div className="charts">
            <ChartWrapper
              config={{
                data: {
                  datasets,
                  labels: [],
                },
                options: {
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      intersect: false,
                      mode: 'nearest',
                      axis: 'x',
                      callbacks: {
                        title: (tooltipItems) => {
                          let title = '';
                          if (tooltipItems.length > 0) {
                            const item = tooltipItems[0];
                            const dateTime = DateTime.fromMillis(
                              item.parsed.x
                            ).setZone(timezone);
                            title = dateTime.toFormat('DDD hh:mm:ss a ZZ');
                          }

                          return title;
                        },
                        label: (tooltipItem) => {
                          const { parsed, dataset } = tooltipItem;
                          let value = parsed.y;
                          value = Math.round(value * 100) / 100 / 1e6;
                          return `${
                            dataset.label
                          }: ${value.toLocaleString()} MW`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      adapters: {
                        date: {
                          zone: timezone,
                        },
                      },
                      type: 'time',
                      min: date.startOf('day').setZone(timezone).valueOf(),
                      max: date
                        .startOf('day')
                        .setZone(timezone)
                        .plus({ days: 1 })
                        .valueOf(),
                      offset: true,
                      title: {
                        display: true,
                        text: `Time (${timezone})`,
                      },
                      ticks: {
                        major: {
                          enabled: true,
                        },
                        source: 'auto',
                        autoSkip: true,
                        autoSkipPadding: 75,
                        maxRotation: 0,
                        sampleSize: 100,
                      },
                    },
                    y: {
                      suggestedMin: 0,
                      suggestedMax: 1e6,
                      grid: {
                        drawBorder: false,
                      },
                      title: {
                        display: true,
                        text: yAxisLabel,
                      },
                      ticks: {
                        // @ts-expect-error
                        callback: (value) => `${(value / 1e6).toFixed(2)}`,
                      },
                    },
                  },
                },
                type: 'line',
              }}
            />
          </div>
        )}
      <Modal
        active={inUploadMode}
        onClose={cancelUpload}
        cancelProps={{ disabled: saving }}
        confirmProps={{
          onClick: uploadForecastData,
          label: saving ? (
            <i className="material-icons rotating-icon">refresh</i>
          ) : (
            'Save'
          ),
          disabled: !hasData || saving,
        }}
        hideClose
        height="250px"
        title="Upload Forecast Data"
      >
        <div className="upload-form">
          <Select
            isClearable={false}
            isMulti={false}
            label="Market Type"
            onChange={(opt) => setUploadMarketType(opt.value)}
            options={MARKET_TYPES}
            value={
              uploadMarketType === 'dayahead'
                ? MARKET_TYPES[0]
                : MARKET_TYPES[1]
            }
          />
          <FileForm
            accept="*.csv"
            createRef={(form) => (uploadFormRef.current = form)}
            id="upload"
            onChange={(e) => {
              //@ts-ignore
              const { files } = e.target;

              // If there not exactly one files attached, do nothing
              if (files.length !== 1) return;

              // Only 1 file should exist / only the first file matters
              const file = files[0];

              // Assume .csv file is valid, allow API to reject
              const csvKey = 'asset_forecast_csv';
              fileData.set(csvKey, file);
              setFilename(file.name);
              setHasData(true);
            }}
          >
            <Button
              customClasses={{
                customButtonClass: 'button--non-interactive',
              }}
              customStyles={{ customButtonStyles: { marginTop: '8px' } }}
            >
              Select file
            </Button>
          </FileForm>
          <div className="filename">{filename}</div>
        </div>
      </Modal>
    </div>
  );
};

export default AssetForecastPanel;
