import React, { Dispatch, useEffect } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import LoadingSpinner from 'components/LoadingSpinner';
import SVG from 'components/SVG';
import FormLabel from 'components/FormLabel';
import Tooltip from 'components/Tooltip';
import Select from 'components/OldSelect';
// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'components/OldDatePicker';

import { daysOptions, selectStyles, useServiceTypeSelection } from '../logic';

import './MECSelection.scss';

type MECSelectionProps = {
  serviceType: string | undefined;
  programId: string;
  setInfo: Dispatch<
    React.SetStateAction<Record<string, string | boolean | undefined>>
  >;
  isDso: boolean;
};

export const MECSelection = ({
  programId,
  serviceType,
  setInfo,
  isDso,
}: MECSelectionProps) => {
  const {
    dataAnalysis,
    analysisDay,
    analysisTime,
    message,
    setAnalysisDay,
    setAnalysisTime,
    register,
    errors,
    configuring,
    loading,
    updating,
    setAnalizeData,
  } = useServiceTypeSelection(programId, serviceType);

  useEffect(() => {
    const day = daysOptions.find((d) => d.value === dataAnalysis?.analysis_day);
    setAnalysisDay(day?.value);
    setAnalysisTime(
      DateTime.fromFormat(dataAnalysis?.analysis_time || '00:00:00', 'hh:mm:ss')
    );
  }, [dataAnalysis, setAnalysisDay, setAnalysisTime]);

  useEffect(() => {
    setInfo({ message, configuring, updating });
  }, [message, configuring, updating, setInfo]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="mec-selection__dates">
          <div>
            <FormLabel htmlFor="selectDay">
              Analyze weekly on
              <Tooltip
                arrow
                content="Analysis period will occur Sunday through Saturday"
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="mec-selection__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Select
              name="analysisDay"
              isMulti={false}
              isDisabled={!isDso}
              isClearable={false}
              options={daysOptions}
              onChange={(opt) => {
                setAnalysisDay(opt.value);
                setAnalizeData({
                  analysis_day: opt.value,
                });
              }}
              value={
                analysisDay !== undefined
                  ? daysOptions.find((opt) => opt.value === analysisDay)
                  : null
              }
              row
              placeholder="Select day"
              styles={selectStyles}
              classes={{ wrapperClass: 'mec-selection__day' }}
            />
            {errors?.selectDay?.message && (
              <div
                className="mec-selection__error"
                style={{ marginLeft: '12px' }}
              >
                {errors?.selectDay?.message ? errors?.selectDay?.message : ''}
              </div>
            )}
          </div>
          <div>
            <FormLabel htmlFor="selectTime">Analyze at</FormLabel>
            <OldDatePicker
              {...register('analysisTime')}
              disabled={!isDso}
              date={analysisTime ?? null}
              showArrows={false}
              useUTC={false}
              defaultDate={analysisTime ?? null}
              placeholder="- - : - -"
              onClose={(value) => {
                setAnalysisTime(value);
                setAnalizeData({
                  analysis_time: value.toUTC().toISOTime(),
                });
              }}
              options={{
                noCalendar: true,
                enableTime: true,
                dateFormat: 'H:i',
                time_24hr: true,
              }}
              customClasses={{
                inputClass: classNames('new-clock mec-selection__time', {
                  'mec-selection__error-input': errors?.analysisTime?.message,
                }),
              }}
            />
            {errors?.analysisTime?.message && (
              <div className="mec-selection__error">
                {errors?.analysisTime?.message
                  ? errors?.analysisTime?.message
                  : null}
              </div>
            )}
          </div>
        </div>
      )}
      {message && (
        <div className="mec-selection__info">
          Analysis period will occur Sunday through Saturday.
        </div>
      )}
    </>
  );
};
