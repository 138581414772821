import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import SVG from 'components/SVG';

import './Avatar.scss';

type AvatarParams = {
  src?: string;
  alt?: string;
  shape?: 'circle' | 'square' | 'rounded';
  size?: 'small' | 'large' | undefined;
  children?: string;
  style?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  className?: string;
  imageClass?: string;
};

type loadedType = false | 'loaded' | 'error';

/**
 * Generates the Avatar element.
 * Determines what to load by trying src => children => alt => default icon
 */
function Avatar({
  src,
  alt,
  size,
  shape = 'circle',
  children,
  style,
  imageStyle,
  className,
  imageClass,
}: AvatarParams): React.ReactElement {
  /**
   * Figures out if any passed in image paths work or error out.
   * @returns {string} - did the given image path work?
   */
  function useLoaded(): string | boolean {
    const [loaded, setLoaded] = useState<loadedType>(false);

    useEffect(() => {
      if (src === undefined) {
        return undefined;
      }
      setLoaded(false);

      let active = true;
      const image = new Image();
      image.src = src;
      image.onload = (): void => {
        if (!active) {
          return;
        }
        setLoaded('loaded');
      };
      image.onerror = (): void => {
        if (!active) {
          return;
        }
        setLoaded('error');
      };

      return (): void => {
        active = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);

    return loaded;
  }

  /**
   * Handles figuring out what to show as the avatar.
   * @returns the actual avatar
   */
  function RenderAvatar(): React.ReactElement {
    const loaded = useLoaded();

    if (loaded === 'loaded') {
      return (
        <img
          className={imageClass || ''}
          style={imageStyle}
          src={src}
          alt={alt}
        />
      );
    }
    if (children) {
      return (
        <span className={imageClass || ''} style={imageStyle}>
          {children}
        </span>
      );
    }
    if (alt) {
      return (
        <span className={imageClass || ''} style={imageStyle}>
          {alt[0].toLocaleUpperCase()}
        </span>
      );
    }
    let customSize = undefined;
    if (size) {
      customSize = size === 'large' ? '2.1rem' : '1.1rem';
    }
    return (
      <SVG
        round
        icon="person_outline"
        fontSize={customSize}
        className={classNames('avatar__image', imageClass)}
        styles={imageStyle}
      />
    );
  }

  return (
    <div
      className={classNames(
        'avatar',
        { 'avatar--circle': shape === 'circle' },
        { 'avatar--rounded': shape === 'rounded' },
        { 'avatar--small': size === 'small' },
        { 'avatar--large': size === 'large' },
        className
      )}
      style={style}
    >
      {RenderAvatar()}
    </div>
  );
}

export default Avatar;
