import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useUserContext } from 'contexts/UserContext';

import { CurrentUser } from 'types/user';
import { flexContract } from 'types/contract-managment';

import contractFilters from 'routes/LongTermContracts/helpers/contractFilters';
import ContractsTable from 'routes/LongTermContracts/ContractsTable';
import ContractsTableHeader from 'routes/LongTermContracts/components/ContractsTableHeader';
import { columns } from 'routes/LongTermContracts/ContractsTable/ContractsTable.logic';

import './P2NContracts.scss';

function getColumns(isDso: boolean): columns[] {
  if (isDso) {
    return [
      'bidOrOffer',
      'servicePeriod',
      'serviceWindow',
      'serviceType',
      'quantity',
      'availability',
      'utilization',
      'zone',
      'responder',
      'updates',
      'csa',
      'status',
      'actions',
    ];
  }
  return [
    'bidOrOffer',
    'servicePeriod',
    'serviceWindow',
    'serviceType',
    'quantity',
    'availability',
    'utilization',
    'zone',
    'responder',
    'updates',
    'csa',
    'status',
    'actions',
  ];
}

interface Props {
  contracts: flexContract[] | undefined;
  isLoading: boolean;
  loggedInUser: CurrentUser;
  refetchContracts: () => void;
}

const P2NContracts: React.FC<Props> = ({
  contracts,
  isLoading,
  loggedInUser,
  refetchContracts,
}) => {
  const { programID } = useParams<{ programID: string }>();
  const { userIsDso } = useUserContext();

  const isDso = userIsDso();
  const tenant = loggedInUser.tenants[0];

  const slicedContracts = useMemo(
    () => contractFilters('p2n', tenant.id, isLoading, isDso, contracts),
    [contracts, isDso, isLoading, tenant.id]
  );

  const pendingContractColumns = getColumns(isDso);

  function placeHolderRow() {
    return (
      <tr className="contracts-table__row">
        <td
          className="contracts-table__cell active-contracts__placeholder-text"
          colSpan={pendingContractColumns.length}
        >
          <div style={{ width: 'fit-content', margin: 'auto' }}>
            {!isLoading && 'There is no data to display.'}
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      <ContractsTableHeader />
      <ContractsTable
        data={slicedContracts}
        showColumns={pendingContractColumns}
        programID={programID}
        loggedInUser={loggedInUser}
        placeHolderRow={placeHolderRow}
        refetchContracts={refetchContracts}
      />
    </>
  );
};

export default P2NContracts;
