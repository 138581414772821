import React, {
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { DateTime } from 'luxon';
import { TableInstance } from 'react-table';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { SelectOption } from 'types';

import Button from 'components/Button';
import Select from 'components/Select';

import {
  PFReportDetails,
  PFReportDownloadDetails,
} from 'routes/Program/routes/Reports/routes/PowerFlowAnalysis/PowerFlowAnalysis.types';
import {
  analysesList,
  marketsList,
  generateFeederList,
  generateTimePeriods,
} from './ReportFilters.logic';

import './ReportFilters.scss';

export type ReportFilterProps = {
  instance: TableInstance;
  downloadPFAReports: (reportsToDownload: PFReportDownloadDetails[]) => void;
  setAnalysisDateRange: Dispatch<
    SetStateAction<{ startDate: DateTime; endDate: DateTime }>
  >;
  data: PFReportDetails[];
};

export function ReportFilters({
  downloadPFAReports,
  instance,
  data,
  setAnalysisDateRange,
}: ReportFilterProps) {
  const { setFilter, filteredRows } = instance;
  const { feeders, selectedProgram } = useProgramsContext();

  const [currentFilters, setCurrentFilters] = useState<
    Array<{ id: string; value: any }>
  >([]);

  const feedersList: SelectOption[] = useMemo(() => {
    return generateFeederList(feeders);
  }, [feeders]);

  const timezone = selectedProgram?.timezone;

  const timePeriods: SelectOption[] = useMemo(() => {
    return generateTimePeriods(timezone);
  }, [timezone]);

  useEffect(() => {
    if (data) {
      currentFilters.forEach(({ id, value }) => {
        setFilter(id, value);
      });
    }
  }, [currentFilters, data, setFilter]);

  function downloadAllReports() {
    if (downloadPFAReports) {
      const reports: PFReportDownloadDetails[] = filteredRows.map(
        (row: any) => {
          const {
            report_location,
            feeder_name,
            market_type,
            analysis_name,
            analysis_time,
          } = row.original;
          return {
            report_location,
            feeder_name,
            market_type,
            analysis_name,
            analysis_time,
          };
        }
      );
      downloadPFAReports(reports);
    }
  }

  function setTableFilter(filterId: string, filterValue: any) {
    setCurrentFilters((prev) => [
      ...prev.filter(({ id }) => filterId !== id),
      { id: filterId, value: filterValue },
    ]);
  }

  return (
    <div className="report-filters">
      <Select
        isClearable={false}
        defaultValue={timePeriods[0]}
        options={timePeriods}
        variant="outlined"
        classes={{ wrapperClass: 'report-filters__filter' }}
        onChange={(e) => {
          setAnalysisDateRange(e.value);
        }}
      />
      <Select
        isClearable={false}
        defaultValue={feedersList[0]}
        options={feedersList}
        variant="outlined"
        classes={{ wrapperClass: 'report-filters__filter' }}
        onChange={(e) => {
          e && e.value === 'ALL'
            ? setTableFilter('feeder_id', '')
            : setTableFilter('feeder_id', e ? e.value : '');
        }}
      />
      <Select
        isClearable={false}
        defaultValue={marketsList[0]}
        options={marketsList}
        variant="outlined"
        classes={{ wrapperClass: 'report-filters__filter' }}
        onChange={(e) => {
          e && e.value === 'ALL'
            ? setTableFilter('market_type', '')
            : setTableFilter('market_type', e ? e.value : '');
        }}
      />
      <Select
        isClearable={false}
        defaultValue={analysesList[0]}
        options={analysesList}
        variant="outlined"
        classes={{ wrapperClass: 'report-filters__filter' }}
        onChange={(e) => {
          e && e.value === 'ALL'
            ? setTableFilter('analysis_name', '')
            : setTableFilter('analysis_name', e ? e.value : '');
        }}
      />
      <Button variant="text" margin="auto 0" onClick={downloadAllReports}>
        Download reports
      </Button>
    </div>
  );
}
