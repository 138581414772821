import { SelectOption } from 'types';
import { flexRequest } from 'types/contract-managment';
import { ResponseAsset } from 'types/der';

export enum FlexResponseFormFields {
  Quantity = 'quantity',
  AvailabilityPrice = 'availabilityPrice',
  UtilizationPrice = 'utilizationPrice',
  Asset = 'asset',
  MaxEnergyDaily = 'maxEnergyDaily',
  DailyDurationHours = 'dailyDurationHours',
  Zone = 'feeder',
}

export type FlexResponseFormProps = {
  request: flexRequest;
  currencySymbol: string;
  responseAssets: ResponseAsset[];
  onSubmit: (data: { [x: string]: any }) => void;
  onCancel: () => void;
  selectedDer: SelectOption;
  zoneList: SelectOption[];
};
