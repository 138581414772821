import React, { useMemo, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Cell, Row, TableInstance } from 'react-table';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { flexItemStatus, flexRequest } from 'types/contract-managment';

import {
  getRemainingTime,
  jsDateToDateTimeProgramTimezone,
} from 'helpers/time';
import { env } from 'helpers/env';
import fileExportSave from 'helpers/downloadFile';

import SVG from 'components/SVG';
import Table from 'components/Table';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'routes/LongTermContracts/components/Modal';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import Menu from 'components/Menu';
import MenuItem from 'components/Menu/MenuItem';

import Select from 'routes/LongTermContracts/components/Select';
// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'routes/LongTermContracts/components/DatePicker';
import {
  renderServicePeriod,
  renderWindow,
  renderBidOffer,
  renderPerson,
  renderCost,
  renderLight,
  renderQuantity,
  renderText,
  getFilterDate,
  renderZone,
  renderContractModel,
} from 'routes/LongTermContracts/helpers/tableFunctions';

import './RequestsTable.scss';

// List items should match the selector from column definitions.
export type columns =
  | 'contractModel'
  | 'zone'
  | 'serviceDetails'
  | 'availableTime'
  | 'serviceType'
  | 'bidOrOffer'
  | 'quantity'
  | 'availability'
  | 'utilization'
  | 'requestor'
  | 'evaluation'
  | 'expiryDate'
  | 'actions';

interface RequestsTableParams {
  showColumns: columns[];
  data: flexRequest[];
  programId: string;
  hideFilters?: boolean;
  isClickable?: boolean;
  showVisibleRowCount?: boolean;
  deletedCallback?(id: string): void;
  showCreateRequest?: () => void;
}

function selectFilter<T extends object>(
  rows: Row<T>[],
  columnId: keyof T,
  filterValue: string
) {
  if (!filterValue) return rows;
  return rows.filter((row) => {
    return filterValue === String(row.original[columnId]);
  });
}

/**
 * Used to generate a specialized version of the table component: long term contracts table.
 * Handles the boilerplate code and allows features to easily be turned on or off.
 */
const RequestsTable = ({
  data,
  showColumns,
  programId,
  hideFilters = false,
  isClickable = false,
  showVisibleRowCount = false,
  showCreateRequest,
  deletedCallback,
}: RequestsTableParams) => {
  const { selectedProgram: program, feeders } = useProgramsContext();
  const { userIsDso } = useUserContext();
  const { currencyFormatter, longCurrencyFormatter, currencySymbol } =
    useLocaleFormatter(program?.currency, program?.locale);

  const history = useHistory();
  const location = useLocation();
  const isDso = userIsDso();

  const [filterDate, setFilterDate] = useState<DateTime>();
  const [actionsAnchorEl, setActionsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [request, setRequest] = useState<flexRequest>();

  const openActionMenu = (
    request: flexRequest,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setRequest(request);
    setActionsAnchorEl(event.currentTarget);
  };

  const closeActionMenu = () => {
    setActionsAnchorEl(null);
  };

  const onMenuClick = (type: 'create' | 'delete') => {
    closeActionMenu();
    if (type === 'create' && showCreateRequest) {
      return showCreateRequest();
    }
    return request && selectId(request.id);
  };

  const { isNMF, isWSC } = env;

  const getActionItems = useCallback(
    (request: flexRequest | undefined) => {
      if (request) {
        const { hasActiveContract, status, expiryDate, openTime } = request;

        const statusIsCancelled = status === flexItemStatus.cancelled;
        const statusIsAccepted = status === flexItemStatus.accepted;
        const inAmendRange =
          openTime && openTime.diff(DateTime.local()).milliseconds > 0;
        const isExpired = getRemainingTime(expiryDate) === 'Expired';

        const isCanAmend =
          isWSC && isDso && statusIsAccepted && !isExpired && inAmendRange;

        const tooltip =
          hasActiveContract && !statusIsCancelled
            ? 'This request cannot be cancelled because it is tied to a contract'
            : undefined;
        return {
          hasActiveContract,
          tooltip,
          statusIsCancelled,
          isCanAmend,
        };
      }
      return {
        hasActiveContract: false,
        tooltip: '',
        statusIsCancelled: false,
        isCanAmend: false,
      };
    },
    [isDso, isWSC]
  );

  const { hasActiveContract, tooltip, statusIsCancelled, isCanAmend } =
    useMemo(() => {
      return getActionItems(request);
    }, [request, getActionItems]);

  // get a list of displayed services.
  const services = useMemo(() => {
    const relevantData = data
      .map((row) => row.service)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    return relevantData.map((value) => ({
      label: value,
      value,
    }));
  }, [data]);

  // get a list of requestor names to display.
  const requestors = useMemo(() => {
    const releventData = data
      .map((row) => row.requestorName)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    return releventData.map((value) => ({
      label: value,
      value,
    }));
  }, [data]);

  const renderContractModelMemoed = useMemo(
    () => (cellInfo: any) => renderContractModel(cellInfo, 'requests-table'),
    []
  );

  const renderZoneMemoed = useMemo(
    () => (cellInfo: any) => renderZone(cellInfo, feeders, 'requests-table'),
    [feeders]
  );

  const availableDateMemoed = useMemo(
    () => (cellInfo: any) => renderServicePeriod(cellInfo, 'requests-table'),
    []
  );

  const availableTimeMemoed = useMemo(
    () => (cellInfo: any) => renderWindow(cellInfo, 'requests-table'),
    []
  );

  const bidOfferMemoed = useMemo(
    () => (cellInfo: any) => renderBidOffer(cellInfo, 'requests-table'),
    []
  );

  const quantityMemoed = useMemo(
    () => (cellInfo: any) => renderQuantity(cellInfo, 'requests-table'),
    []
  );

  const kWhDollarsMemoed = useMemo(
    () => (cellInfo: any, col: string) =>
      renderCost(
        cellInfo,
        'requests-table',
        currencyFormatter,
        longCurrencyFormatter,
        col
      ),
    [currencyFormatter, longCurrencyFormatter]
  );

  const serviceMemoed = useMemo(
    () => (cellInfo: any) => renderText(cellInfo, 'requests-table'),
    []
  );

  // Handles rendering the requestor cell with an anvatar.
  const personMemoed = useMemo(
    () => (cellInfo: any) => renderPerson(cellInfo, feeders, 'requests-table'),
    [feeders]
  );

  // Handles rendering light cells & logic to color the light.
  const lightMemoed = useMemo(
    () => (cellInfo: any) => renderLight(cellInfo, 'requests-table'),
    []
  );

  /**
   * Handles rendering the time remainig cells.
   */
  const renderTimeRemaining = useMemo(
    () => (cellInfo: any) => {
      const expiry = cellInfo.cell.value;
      const status = cellInfo.cell.row.original.status;

      let toPrint: string;
      if (status === 'cancelled') {
        toPrint = 'Cancelled';
      } else {
        toPrint = getRemainingTime(expiry);
      }
      const { original } = cellInfo.cell.row;
      const expiryDate = original.expiryDate.toLocaleString(DateTime.DATE_MED);

      return (
        <Tooltip
          content={`Request closes on ${expiryDate}`}
          arrow={true}
          theme="light"
        >
          <div className="requests-table__text requests-table__text--light requests-table--flex-centered">
            {toPrint}
            {isClickable && (
              <SVG
                fontSize="1rem"
                icon="arrow_right"
                styles={{ marginLeft: '20px', color: 'black' }}
              />
            )}
          </div>
        </Tooltip>
      );
    },
    [isClickable]
  );

  const [selectedId, selectId] = useState<string>('');
  const { makeRequest: cancelRequest, loading: deleting } = useRequest(
    `/api/dsp/program/${programId}/flex/request/${selectedId}/cancel`
  );

  const handleDeleteRequest = () => {
    cancelRequest({
      method: 'post',
      body: {},
      toast: {
        success: 'Request cancelled successfully.',
        error: 'Error cancelling request.',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        deletedCallback && deletedCallback(selectedId);
        selectId('');
      },
      onError: undefined,
    });
  };

  const renderActions = useMemo(
    () => (cellInfo: any) => {
      return (
        <div className="requests-table__actions-row">
          <IconButton
            icon="MoreHorizontalIcon"
            onClick={(e) => openActionMenu(cellInfo.cell.row.original, e)}
          />
        </div>
      );
    },
    []
  );

  const dateFilter = useMemo(
    () => (rows: any, columnIds: string[], filter: DateTime) =>
      getFilterDate(rows, columnIds, filter, setFilterDate),
    []
  );

  /**
   * Custom date sort function to work with luxon.
   */
  const dateSort = useMemo(
    () => (rowA: any, rowB: any, col: string, desc: boolean) => {
      const date1 = rowA.original.startTime.toMillis();
      const date2 = rowB.original.startTime.toMillis();
      if (date1 >= date2) {
        return 1;
      }
      return -1;
    },
    []
  );

  const { makeRequest: runExport } = useRequest(
    `/api/dsp/program/${programId}/flex/request/export_summary`
  );

  const exportRequests = async () => {
    await runExport({
      method: 'get',
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(data, headers, `program-${programId}-flex-requests.csv`);
      },
      onError: undefined,
      toast: {
        error: 'Could not export requests.',
        settings: {
          autoDismiss: true,
        },
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  /**
   * Handles rendering the filters that appear above the table.
   * @param {TableInstance} - react table 7 instance details in react-table docs.
   * @returns - filter header
   */
  function tableFilters({ setFilter }: TableInstance) {
    return (
      <div className="requests-table__filters">
        <div
          className="requests-table__filters-filter new-look"
          style={{ marginLeft: '0' }}
        >
          <Select
            label="Service Type"
            onChange={(e) => {
              setFilter('service', e ? e.label : '');
            }}
            isMulti={false}
            isClearable={true}
            options={services}
            classes={{
              labelClass: 'requests-table__text--light',
            }}
          />
        </div>
        <div className="requests-table__filters-filter new-look">
          <Select
            label="Requests From"
            onChange={(e) => {
              setFilter('requestorName', e ? e.label : '');
            }}
            isMulti={false}
            isClearable={true}
            options={requestors}
            classes={{
              labelClass: 'requests-table__text--light',
            }}
          />
        </div>
        <div className="requests-table__filters-filter new-look">
          <label className="requests-table__text requests-table__text--light">
            Available Date
          </label>
          <OldDatePicker
            date={filterDate}
            clearable={true}
            onClose={(d: DateTime) => setFilter('startTime', d)}
            showArrows={false}
            placeholder="Select"
            options={{
              enableSeconds: false,
              enableTime: false,
              formatDate: (date) => {
                const dt = jsDateToDateTimeProgramTimezone(date);
                return dt.toFormat('LLL d, yyyy');
              },
              minuteIncrement: 60,
              defaultDate: undefined,
            }}
            isControlled={false}
            customClasses={{
              inputClass: 'requests-table__text',
              datePickerClass: 'requests-table__date-picker',
            }}
            onClear={() => setFilter('startTime', undefined)}
            useUTC={false}
          />
        </div>
        <div className="requests-table__actions">
          <div className="requests-table__actions-top">
            {showVisibleRowCount && (
              <div
                style={{ margin: 'auto 0' }}
                className="requests-table__text requests-table--cancelled"
              >
                (Viewing {data.length}/{data.length} requests)
              </div>
            )}
            <div className="requests-table__dot requests-table__dot-decrease">
              Decrease MW
            </div>
            <div className="requests-table__dot requests-table__dot-increase">
              Increase MW
            </div>
          </div>
          <div className="requests-table__actions-bottom">
            <IconButton
              icon="DownloadIcon"
              onClick={() => exportRequests()}
              disabled={!data || data.length === 0}
              iconClass="requests-table__actions-icon"
              tooltip="Export Flex Requests"
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * First part of this function defines all the possible columns and their logic.
   * Second part handles figuring out what columns to render.
   */
  const columns = useMemo(() => {
    const availableColumns = {
      contractModel: {
        Header: '',
        accessor: 'participantId',
        Cell: renderContractModelMemoed,
      },
      zone: {
        Header: 'Zone',
        accessor: 'feederId',
        Cell: renderZoneMemoed,
      },
      serviceDetails: {
        Header: 'Service Details',
        accessor: 'startTime',
        Cell: availableDateMemoed,
        sortType: dateSort,
        filter: dateFilter,
      },
      availableTime: {
        Header: 'Service Window',
        accessor: 'peak',
        Cell: availableTimeMemoed,
      },
      serviceType: {
        Header: 'Service Type',
        accessor: 'service',
        Cell: serviceMemoed,
        filter: selectFilter,
      },
      bidOrOffer: {
        Header: 'Bids & Offers',
        accessor: 'flex',
        Cell: bidOfferMemoed,
      },
      quantity: {
        Header: 'Quantity (kW)',
        accessor: 'quantity',
        Cell: quantityMemoed,
      },
      availability: {
        Header: `Availability (${currencySymbol}/kW/h)`,
        accessor: 'availability',
        Cell: kWhDollarsMemoed,
      },
      utilization: {
        Header: `Utilization (${currencySymbol}/kWh)`,
        accessor: 'utilization',
        Cell: (cell: any) => kWhDollarsMemoed(cell, 'utilization'),
      },
      requestor: {
        Header: 'Requestor',
        accessor: 'requestorName',
        Cell: personMemoed,
        filter: selectFilter,
      },
      evaluation: {
        Header: 'Evaluation',
        accessor: 'status',
        Cell: lightMemoed,
      },
      expiryDate: {
        Header: 'Time Remaining',
        accessor: 'expiryDate',
        Cell: renderTimeRemaining,
      },
      actions: {
        Header: '',
        accessor: 'id',
        Cell: renderActions,
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [
    renderContractModelMemoed,
    renderZoneMemoed,
    dateSort,
    dateFilter,
    serviceMemoed,
    showColumns,
    availableDateMemoed,
    availableTimeMemoed,
    quantityMemoed,
    bidOfferMemoed,
    kWhDollarsMemoed,
    personMemoed,
    lightMemoed,
    renderTimeRemaining,
    renderActions,
    currencySymbol,
  ]);

  function onRowClick(requestId: string) {
    const currentPath = location.pathname; // to get current route
    history.push(`${currentPath}/request/${requestId}`);
  }

  /**
   * Custom function to handle cell rendering.
   * Handles the logic for coloring specific columns.
   * @param {Row} row - object containing information about the row being rendered.
   * @param index - cell index in the row
   * @param tableRowClass - custom general row class.
   * @param tableCellClass - custom general cell class.
   * @returns - row
   */
  function renderCells(
    row: any,
    index: number,
    tableRowClass: string | undefined,
    tableCellClass: string | undefined
  ) {
    let hideProcessing = false;
    row.cells.forEach((cell: any) => {
      const { column, value } = cell;
      if (
        column.id === 'status' &&
        value === flexItemStatus.processing &&
        isNMF
      ) {
        hideProcessing = true;
      }
    });

    return (
      <tr
        onClick={isClickable ? () => onRowClick(row.original.id) : null}
        className={classNames(
          `
          ${tableRowClass} 
          ${isClickable && 'requests-table--clickable'}
        `,
          { 'requests-table__row--hidden': hideProcessing }
        )}
        {...row.getRowProps()}
        key={index}
      >
        {row.cells.map((cell: Cell, index: number) => {
          const col = cell.column.id;
          return (
            <td
              {...cell.getCellProps()}
              key={index}
              className={classNames(
                tableCellClass,
                {
                  'requests-table__quantity--pos':
                    col === 'quantity' && cell.value >= 0,
                },
                {
                  'requests-table__quantity--neg':
                    col === 'quantity' && cell.value < 0,
                }
              )}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  }

  const TableStyles = {
    tableClass: 'requests-table',
    tableHeaderClass: !hideFilters ? 'requests-table__header' : '',
    tableHeaderCellClass: 'requests-table__header-cell',
    tableBodyClass: !hideFilters ? 'requests-table__body' : '',
    tableBodyRowClass: 'requests-table__row',
    tableBodyCellClass: 'requests-table__cell',
  };

  return (
    <>
      <Table
        data={data}
        tableType="HTML"
        columns={columns}
        customClasses={TableStyles}
        header={!hideFilters ? tableFilters : undefined}
        customRowRenderFunction={renderCells}
      />
      <Modal
        active={selectedId !== ''}
        reverseFooterButtons={true}
        hideClose
        cancelProps={{ disabled: deleting, label: 'No' }}
        confirmProps={{
          disabled: deleting,
          label: 'Yes',
          onClick: handleDeleteRequest,
        }}
        onClose={() => selectId('')}
        title="Are you sure you want to cancel this request?"
      />
      <Menu
        id="contracts-actions-menu"
        anchorEl={actionsAnchorEl}
        open={!!actionsAnchorEl}
        onClose={closeActionMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => onMenuClick('create')}
          disabled={hasActiveContract || !isCanAmend}
        >
          Amend
        </MenuItem>
        <MenuItem
          onClick={() => onMenuClick('delete')}
          disabled={hasActiveContract || statusIsCancelled}
        >
          <Tooltip content={<>{tooltip}</>}>Close</Tooltip>
        </MenuItem>
      </Menu>
    </>
  );
};

export default RequestsTable;
