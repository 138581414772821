import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';
import {
  PFReportDetails,
  PFReportDownloadDetails,
} from './PowerFlowAnalysis.types';

import { PFATableColumns } from './components/PFATable/PFATable';
import { CardProps } from 'routes/Program/routes/Reports/Reports';

import PFATable from './components/PFATable';

import './PowerFlowAnalysis.scss';

export const PowerFlowAnalysis = ({ downloadReport, programId }: CardProps) => {
  const { addToast } = useToasts();

  const [availableReports, setAvailableReports] = useState<PFReportDetails[]>(
    []
  );
  const [analysisDateRange, setAnalysisDateRange] = useState({
    startDate: DateTime.local().startOf('hour'),
    endDate: DateTime.local().startOf('hour').plus({ hours: 1 }),
  });

  const { loading: loadingAvailableReports } = useRequestEffect<any>({
    url: `/api/dsp/analytics/program/${programId}/analysis_runs`,
    method: 'get',
    params: {
      start_time: analysisDateRange.startDate
        .toUTC()
        .toFormat('yyyy-LL-dd HH:mm'),
      end_time: analysisDateRange.endDate.toUTC().toFormat('yyyy-LL-dd HH:mm'),
    },
    initialData: {},
    refetchOnChange: [programId, analysisDateRange],
    onSuccess: (data: PFReportDetails[]) => {
      setAvailableReports(data);
      if (data.length === 0) {
        addToast(
          `There are no reports available for this program for the selected date range.`,
          {
            appearance: 'warning',
          }
        );
      }
    },
    toast: {
      error: 'Failed to load list of available reports.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const downloadAnalysisReport = (
    reportsToDownload: PFReportDownloadDetails[]
  ) => {
    if (reportsToDownload.length === 0) {
      addToast(`No reports are available for the selected criteria.`, {
        appearance: 'error',
      });
    }

    reportsToDownload.forEach((report) => {
      const {
        report_location: location,
        feeder_name: feeder,
        market_type: market,
        analysis_name: analysis,
        analysis_time: time,
      } = report;
      const url = `/api/files/reports/${location}`;
      if (location !== null) {
        downloadReport(url, {}, `${feeder}-${analysis}-${market}-${time}.xlsx`);
      } else {
        addToast(
          `Failed to download report: ${feeder}-${analysis}-${market}-${time}.xlsx as the file path was invalid.`,
          {
            appearance: 'error',
          }
        );
      }
    });
  };

  const columns: PFATableColumns[] = [
    'zone',
    'marketType',
    'analysisPeriod',
    'analysisType',
    'actions',
  ];

  return (
    <div className="power-flow-analysis">
      <h1 className="power-flow-analysis__title">Power flow analysis</h1>
      <PFATable
        data={availableReports}
        showColumns={columns}
        downloadReport={downloadAnalysisReport}
        isLoading={loadingAvailableReports}
        setAnalysisDateRange={setAnalysisDateRange}
      />
    </div>
  );
};
