import { peakType, peakTypeRaw } from 'types/contract-managment';

export const processPeakType = (
  serviceWindow: peakTypeRaw | undefined
): peakType => {
  let peak: peakType = peakType.OffPeak;
  if (serviceWindow === peakTypeRaw.twentyFourSeven) {
    peak = peakType.twentyFourSeven;
  }
  if (serviceWindow === peakTypeRaw.Peak) peak = peakType.Peak;
  if (serviceWindow === peakTypeRaw.Custom) peak = peakType.Custom;
  return peak;
};
