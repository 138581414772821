import React, { useRef } from 'react';
import { DateTime } from 'luxon';

import { CalendarProps } from './Calendar.types';
import { initialState } from './Calendar.logic';
import reducer from './reducer';
import { setDate, setInit } from './reducer/actions';
import { CalendarHeader } from './CalendarHeader';
import { Days } from './Days';
import { WeekDays } from './WeekDays';

import './Calendar.scss';

const Calendar = ({
  value,
  onChange,
  disablePast,
  maxDate,
  minDate,
  disabledDays,
  selectedMonth,
  disabledMonthPicker = false,
}: CalendarProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const componentRef = useRef(false);

  React.useEffect(() => {
    if (!componentRef.current) {
      componentRef.current = true;
      dispatch(
        setInit(
          value,
          disablePast,
          maxDate,
          minDate,
          disabledDays,
          selectedMonth
        )
      );
    }
  }, [value, selectedMonth, disablePast, maxDate, minDate, disabledDays]);

  React.useEffect(() => {
    if (componentRef.current && value) {
      dispatch(setDate(value as DateTime));
    }
  }, [value]);

  const onSelect = (date: DateTime) => {
    onChange(date);
    dispatch(setDate(date));
  };

  return (
    <div className="calendar">
      <CalendarHeader
        state={state}
        dispatch={dispatch}
        disabledMonthPicker={disabledMonthPicker}
        minDate={minDate}
        maxDate={maxDate}
      />
      <WeekDays />
      <Days
        state={state}
        selectedDate={value || DateTime.local()}
        onSelect={onSelect}
      />
    </div>
  );
};

export default Calendar;
