import React from 'react';
import { Route } from 'react-router-dom';

import RouterSwitch from 'components/RouterSwitch';
import SingleTag from './routes/SingleTag';
import SingleTimeseries from './routes/SingleTimeseries';
import Timeseries from './Timeseries';

const TimeseriesContainer = () => {
  return (
    <RouterSwitch>
      <Route exact path="/measurements/timeseries">
        <Timeseries />
      </Route>
      <Route exact path="/measurements/timeseries/:timeseriesID">
        <SingleTimeseries />
      </Route>
      <Route exact path="/measurements/timeseries/:timeseriesID/tag/:tagID">
        <SingleTag />
      </Route>
    </RouterSwitch>
  );
};

export default TimeseriesContainer;
