import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Cell } from 'react-table';

import Table from 'components/Table';

import { Tenant } from 'types/tenant';

import { Routes } from 'routes/ParticipantsAndDers/ParticipantsAndDers';
import { columns } from 'routes/ParticipantsAndDers/Routes/Participants/Participants';

import './ParticipantsTable.scss';

interface ParticipantsTableParams {
  data: Tenant[];
  placeHolderRow?: () => React.ReactElement;
  showColumns: columns[];
  setParticipant: Dispatch<SetStateAction<Tenant | undefined>>;
}

export const ParticipantsTable = ({
  data,
  placeHolderRow,
  showColumns,
  setParticipant,
}: ParticipantsTableParams) => {
  const history = useHistory();
  const { tenantId } = useParams<{ tenantId: string }>();

  const renderMarketParticipant = (cellInfo: any) => {
    return (
      <div className="participants-table__text">{cellInfo.cell.value}</div>
    );
  };

  const renderAccountType = (cellInfo: any) => {
    const accountType = cellInfo.cell.value ? 'DSO' : 'Market Participant';
    return <div className="participants-table__text">{accountType}</div>;
  };

  const renderServiceType = (cellInfo: any) => {
    const { enable_p2p, enable_p2n } = cellInfo.row.original;
    const serviceType =
      enable_p2p && enable_p2n
        ? 'Both'
        : enable_p2p
        ? 'P2P'
        : enable_p2n
        ? 'P2N'
        : 'None';
    return <div className="participants-table__text">{serviceType}</div>;
  };

  const columns = useMemo(() => {
    const availableColumns = {
      marketParticipant: {
        Header: 'Market participant',
        accessor: 'name',
        Cell: renderMarketParticipant,
      },
      accountType: {
        Header: 'Account Type',
        accessor: 'is_dso',
        Cell: renderAccountType,
      },
      serviceType: {
        Header: 'Service type',
        accessor: '',
        Cell: renderServiceType,
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [showColumns]);

  const toggleTenant = (row: any) => {
    if (row.id === tenantId) {
      setParticipant(undefined);
      history.push(Routes.participants);
    } else {
      history.push(`${Routes.participants}/${row.id}/overview`);
      setParticipant(row);
    }
  };

  /**
   * Custom function to handle cell rendering.
   * Handles the logic for coloring specific columns.
   * @param {Row} row - object containing information about the row being rendered.
   * @param index - cell index in the row
   * @param tableRowClass - custom general row class.
   * @param tableCellClass - custom general cell class.
   * @returns - row
   */
  function renderCells(
    row: any,
    index: number,
    tableRowClass: string | undefined,
    tableCellClass: string | undefined
  ) {
    return (
      <div
        onClick={() => toggleTenant(row.original)}
        className={`
        ${tableRowClass} 
        participants-table--clickable
      `}
        {...row.getRowProps()}
        key={index}
      >
        {row.cells.map((cell: Cell, index: number) => {
          let isActive = false;
          if (row.original.id === tenantId) {
            isActive = true;
          }
          return (
            <div
              {...cell.getCellProps()}
              key={index}
              className={classNames(tableCellClass, {
                'participants-table__body-cell--active': isActive,
              })}
            >
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    );
  }

  const TableStyles = {
    tableClass: 'participants-table',
    tableHeaderRowClass: 'participants-table__header-row',
    tableHeaderCellClass: 'participants-table__header-cell',
    tableBodyRowClass: 'participants-table__body-row',
    tableBodyCellClass: 'participants-table__body-cell',
  };

  return (
    <Table
      disableSort
      data={data}
      columns={columns}
      customClasses={TableStyles}
      placeHolderRow={placeHolderRow}
      customRowRenderFunction={renderCells}
    />
  );
};
