import React, { Dispatch, SetStateAction } from 'react';

// eslint-disable-next-line custom-rules/deprecated-component
import OldCheckbox from 'components/OldCheckbox';
import NumberInput from 'components/NumberInput';
import Select from 'components/OldSelect';
import TextInput from 'components/TextInput';

import useLocaleData from 'hooks/useLocaleData';
import TimeZones from 'helpers/timezones';

import {
  ISaveMessages,
  SelectInterface,
  SettingsCommonProps,
} from 'routes/Program/routes/Settings/Settings';

type BasicProps = {
  saveMessages: ISaveMessages;
  ISOs: SelectInterface[] | undefined;
  setNeedToShowModal: Dispatch<SetStateAction<boolean>>;
} & SettingsCommonProps;

const eventDurationOpts = [
  { label: '1 Hour', value: 3600 },
  { label: '30 Minute', value: 1800 },
  { label: '15 Minute', value: 900 },
  { label: '5 Minute', value: 300 },
];

const eventMethodOpts = [
  { label: 'Batch', value: 'BATCH' },
  { label: 'Sequential', value: 'SEQUENTIAL' },
];

export const Basic = ({
  program,
  saving,
  updateProp,
  saveMessages,
  ISOs,
  setNeedToShowModal,
}: BasicProps) => {
  const { currencies, locales } = useLocaleData();

  const timezoneOpts = TimeZones.map((name) => ({ label: name, value: name }));

  return (
    <div className="program-settings_column">
      <div className="program-settings_column-row">
        <TextInput
          disabled={saving}
          id="name"
          invalid={!!saveMessages.name}
          required
          label="Program Name"
          onChange={(value) => updateProp('name', value)}
          placeholder="Name"
          validationMessage={
            saveMessages.name ? saveMessages.name[0] : undefined
          }
          value={program.name}
        />
      </div>
      <div className="program-settings_column-row">
        <TextInput
          disabled
          id="workspace"
          label="Workspace"
          placeholder="Workspace Name"
          value={program.workspace_name}
        />
      </div>
      <div className="program-settings_column-row">
        <Select
          isClearable={false}
          isMulti={false}
          isDisabled={saving}
          isSearchable
          label="Currency"
          options={currencies}
          onChange={(opt) => updateProp('currency', opt.value)}
          value={currencies.find(({ value }) => value === program.currency)}
        />
      </div>
      <div className="program-settings_column-row">
        <TextInput
          disabled={saving}
          id="external_account_id"
          invalid={!!saveMessages.external_account_id}
          label="External Account ID"
          onChange={(value) => updateProp('external_account_id', value)}
          placeholder="External Account ID"
          value={program.external_account_id?.toString() || ''}
          validationMessage={
            saveMessages.external_account_id
              ? saveMessages.external_account_id[0]
              : undefined
          }
          resetEditState={saving}
        />
      </div>
      <div className="program-settings_column-row">
        <TextInput
          disabled={saving}
          id="external_account_id"
          invalid={!!saveMessages.external_account_id}
          label="External Account ID"
          onChange={(value) => updateProp('external_account_id', value)}
          placeholder="External Account ID"
          value={program.external_account_id?.toString() || ''}
          validationMessage={
            saveMessages.external_account_id
              ? saveMessages.external_account_id[0]
              : undefined
          }
          resetEditState={saving}
        />
      </div>
      <div className="program-settings_column-row">
        <Select
          isClearable={false}
          isMulti={false}
          isDisabled={saving}
          isSearchable
          label="Locale"
          options={locales}
          onChange={(opt) => updateProp('locale', opt.value)}
          value={locales.find(({ value }) => value === program.locale)}
        />
      </div>
      <div className="program-settings_column-row">
        <Select
          isClearable={false}
          isMulti={false}
          isDisabled={saving}
          isSearchable
          label="Timezone"
          options={timezoneOpts}
          onChange={(opt) => updateProp('timezone', opt.value)}
          value={timezoneOpts.find(({ value }) => value === program.timezone)}
        />
      </div>
      <div className="program-settings_column-row">
        <Select
          isClearable={false}
          isMulti={false}
          isDisabled={saving}
          label="Same Day Event Duration"
          options={eventDurationOpts}
          onChange={(opt) => updateProp('sameday_event_duration', opt.value)}
          value={eventDurationOpts.find(
            ({ value }) => value === program.sameday_event_duration
          )}
        />
      </div>
      <div className="program-settings_column-row">
        <Select
          isClearable={false}
          isMulti={false}
          isDisabled={saving}
          label="Same Day Event Method"
          options={eventMethodOpts}
          onChange={(opt) => updateProp('sameday_event_method', opt.value)}
          value={eventMethodOpts.find(
            ({ value }) => value === program.sameday_event_method
          )}
        />
      </div>
      <div className="program-settings_column-row">
        <NumberInput
          id="dispatch_grace_period"
          label="Dispatch Grace Period"
          onChange={(value) => updateProp('dispatch_grace_period', value)}
          required
          unit="s"
          min={0}
          value={program.dispatch_grace_period}
        />
      </div>
      {!program.constraint_energy_management && (
        <div className="program-settings_column-row">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={saving}
            label="ISO"
            options={ISOs}
            onChange={(opt) => {
              updateProp('iso', opt.value);
              setNeedToShowModal(true);
            }}
            value={ISOs?.find(
              ({ value }: { value: any }) => value === program.iso
            )}
          />
        </div>
      )}
      <div className="program-settings_column-row">
        <OldCheckbox
          disabled={saving}
          checked={program.is_activated}
          onClick={() => updateProp('is_activated', !program.is_activated)}
        />
        Enable Market
      </div>
    </div>
  );
};
