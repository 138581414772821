import { DateTime } from 'luxon';
import {
  SettlementSummaryRaw,
  SettlementSummary,
  SettlementStatus,
  SettlementTrafficLight,
  SettlementTrafficLightRaw,
  SettlementSingleResponseRawDaily,
  SettlementSingleResponseRawByContract,
  SettlementSingleResponse,
  TrafficLightStatus,
  SettlementSingleResponseSubRow,
} from 'types/settlement';
import { getServiceType } from 'routes/LongTermContracts/helpers/dataProcessers';

export function processRawSettlementSummary(
  rawSummary: SettlementSummaryRaw
): SettlementSummary {
  const {
    participant,
    participant_id,
    events,
    measurement,
    requested,
    baseline,
    delivered,
    cost,
    penalty,
    published,
    final_settlement,
  } = rawSummary;
  const newBaseline = processSettlementLight(baseline);
  const newMeasurement = processSettlementLight(measurement);

  function determineSettlementStatus(): SettlementStatus {
    if (
      newBaseline !== SettlementTrafficLight.GREEN &&
      newMeasurement === SettlementTrafficLight.GREEN
    ) {
      return SettlementStatus.ExportMeasurement;
    }
    if (newMeasurement !== SettlementTrafficLight.GREEN) {
      return SettlementStatus.PendingMeasurementData;
    }
    if (published === false) {
      return SettlementStatus.PublishSettlement;
    }
    return SettlementStatus.Published;
  }

  return {
    tenantName: participant,
    tenantID: participant_id,
    tenantImage: '',
    events,
    measurement: newMeasurement,
    requested,
    baseline: newBaseline,
    delivered,
    cost,
    penalty,
    published,
    finalSettlement: final_settlement,
    settlementStatus: determineSettlementStatus(),
  };
}

function processSettlementLight(value: string): SettlementTrafficLight {
  if (value === SettlementTrafficLightRaw.GREEN) {
    return SettlementTrafficLight.GREEN;
  }
  if (value === SettlementTrafficLightRaw.ORANGE) {
    return SettlementTrafficLight.ORANGE;
  }
  if (value === SettlementTrafficLightRaw.RED) {
    return SettlementTrafficLight.RED;
  }
  return SettlementTrafficLight.CLEAR;
}

export function processRawSettlementSinge(
  rawSettlementSingle: SettlementSingleResponseRawDaily,
  dateStr: string
): SettlementSingleResponse {
  const response: SettlementSingleResponse = {
    date: DateTime.fromISO(dateStr),
    requested: 0,
    delivered: 0,
    cost: 0,
    penalty: 0,
    finalSettlement: 0,
    subRows: [],
    measurementStatus: TrafficLightStatus.Blank,
  };

  let allHasMeasurement = true;
  let anyHasMeasurement = false;

  for (const contractID in rawSettlementSingle) {
    const settlementByContract: SettlementSingleResponseRawByContract =
      rawSettlementSingle[contractID];
    const {
      power_requested: requested = 0,
      power_delivered: delivered = 0,
      cost = 0,
      final_settlement: finalSettlement = 0,
      penalty = 0,
      contract: contractType,
      der: {
        id: derID,
        info: { name: derName, der_type: derType },
      },
      service_windows: serviceWindows = [],
    } = settlementByContract;

    response.requested += requested;
    response.delivered += delivered;
    response.finalSettlement += finalSettlement;
    response.cost += cost;
    response.penalty = (response.penalty || 0) + penalty;

    const processedContractType = getServiceType(contractType);

    const subRow: SettlementSingleResponseSubRow = {
      requested,
      delivered,
      cost,
      penalty,
      finalSettlement,
      contractType: processedContractType,
      der: {
        id: derID,
        name: derName,
        type: derType,
      },
      time: [],
    };
    for (const window of serviceWindows) {
      subRow.time.push({
        start: DateTime.fromISO(window.start_time),
        end: DateTime.fromISO(window.end_time),
      });
      allHasMeasurement = allHasMeasurement && window.has_measurement;
      anyHasMeasurement = anyHasMeasurement || window.has_measurement;
    }

    response.subRows.push(subRow);
  }

  if (allHasMeasurement) {
    response.measurementStatus = TrafficLightStatus.Green;
  } else if (anyHasMeasurement) {
    response.measurementStatus = TrafficLightStatus.Orange;
  }

  return response;
}
