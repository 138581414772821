import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  useAuthContext,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { useContainers } from 'hooks/useContainers';

import { useUserContext } from 'contexts/UserContext';

import { env } from 'helpers/env';

import Button from 'components/Button';
import IconButton from 'components/IconButton';
import GridosVersion from 'components/GridosVersion';

import { GridOSData, NavMenuData } from './Navigation.types';
import { renderSection, renderCategory } from './Navigation.logic';

import './Navigation.scss';
import 'styles/effects.scss';

const Navigation = () => {
  const { isAuthEnabled, hasAllPermissions, logout } = useAuthContext();
  const { toggleNavMenu, displayNavigation } = useUserContext();
  const history = useHistory();

  const onLogoutHandler = () => {
    localStorage.removeItem('notificationRules');
    logout();
  };

  const isFlexAuction =
    process.env.REACT_APP_IS_NMF || process.env.REACT_APP_IS_WSC;

  const userManual = env.isNMF
    ? '/GridOS NMF User Guide LEO V2.0.pdf'
    : '/GridOS WSC User Guide V1.8.pdf';

  // HTTP Requests to fetch navigation data...
  const programMatch = useRouteMatch<{ programID: string }>(
    '/program/:programID'
  );

  const { data: navData, loading } = useRequestEffect<NavMenuData>({
    method: 'get',
    url: '/nav_menu.json',
  });

  const { data: gridosData } = useRequestEffect<GridOSData>({
    method: 'get',
    url: '/api/dsp/admin/gridos-info',
  });

  const { containers = {} } = useContainers(programMatch?.params.programID);
  const feeder = containers[Object.keys(containers)[0]];
  const tag_ref = gridosData?.version_info.tag;
  const sha = gridosData?.version_info.commit_sha;

  return (
    <div
      onClick={toggleNavMenu}
      className={classNames({
        navigation: true,
        'navigation--expanded': true,
        'navigation--hidden': displayNavigation,
      })}
    >
      <div
        onClick={toggleNavMenu}
        className={classNames({
          navigation__backdrop: true,
          'navigation__backdrop--hidden': displayNavigation,
          'effects__transition-fade': true,
        })}
      />
      <div
        className={classNames({
          'navigation__scroll-container': true,
          'navigation__scroll-container--hidden': displayNavigation,
          'effects__transition-slide': true,
        })}
      >
        <IconButton
          icon="XIcon"
          fontSize="18px"
          iconClass="navigation__close-button-icon"
          onClick={toggleNavMenu}
          customClasses={{
            customButtonClass: 'navigation__close-button',
          }}
        />

        {!loading &&
          navData &&
          navData.sections.map((section, index) => {
            const sectionRender = renderSection(section);
            const renderCats = section.categories.map((category) =>
              renderCategory(
                category,
                programMatch,
                feeder,
                isAuthEnabled,
                hasAllPermissions,
                isFlexAuction,
                history
              )
            );
            return (
              <React.Fragment key={`navigation-${index}-${section.title}`}>
                {sectionRender}
                {renderCats}
              </React.Fragment>
            );
          })}

        <div className="navigation__link--simple">Account settings</div>
        <a
          className="navigation__link--simple"
          target="_blank"
          rel="noopener noreferrer"
          href={userManual}
        >
          <span>User manual</span>
        </a>
        <a
          className="navigation__link--simple"
          target="_blank"
          rel="noopener noreferrer"
          href="/terms_and_conditions.pdf"
        >
          <span>Terms &amp; Conditions</span>
        </a>
        <a
          className="navigation__link--simple"
          target="_blank"
          rel="noopener noreferrer"
          href="https://opusonesolutions.atlassian.net/servicedesk/customer/portal/8"
        >
          <span>Report an issue</span>
        </a>

        <div className="navigation__scroll-container-separator" />

        {tag_ref && (
          <>
            <GridosVersion tag_ref={tag_ref} sha={sha} />
            <div className="navigation__scroll-container-separator" />
          </>
        )}

        <Button
          variant="outlined"
          customClasses={{
            customButtonClass: classNames({
              'navigation__logout-button': true,
              'effects__transition-all': true,
            }),
          }}
          onClick={onLogoutHandler}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Navigation;
