import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { serviceTypes, useMarketType } from './NeedsAnalysisService.logic';
import {
  useNeedsAnalysis,
  useSubmitNeedsAnalysis,
} from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';
import {
  useWeekAheadFormControls,
  useDayAheadFormControls,
} from './NeedsAnalysisForm/NeedsAnalysisForm.logic';

import {
  MarketType,
  NeedsAnalysisType,
} from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysis.logic';

import NeedsAnalysisForm from 'routes/Program/routes/Settings/routes/NeedsAnalysis/routes/NeedsAnalysisService/NeedsAnalysisForm';
import NeedsAnalysisSummary from './NeedsAnalysisSummary';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/Accordion';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import SVG from 'components/SVG';

import './NeedsAnalysisService.scss';

export const NeedsAnalysisService = () => {
  const location = useLocation();
  const history = useHistory();
  const { serviceType } = useParams<{ serviceType: string }>();
  const [dataRefetchTrigger, setDataRefetchTrigger] = useState(false);
  const { data } = useNeedsAnalysis(dataRefetchTrigger);

  const disableDayAhead = true;

  const wasWeekAheadActive =
    data?.find(
      (analysis) =>
        analysis.market_type === MarketType.weekAhead &&
        analysis.service_type === serviceType
    )?.is_active || false;

  const wasDayAheadActive =
    data?.find(
      (analysis) =>
        analysis.market_type === MarketType.dayAhead &&
        analysis.service_type === serviceType
    )?.is_active || false;

  const [activeWeekAhead, setActiveWeekAhead] = useState(wasWeekAheadActive);
  const [activeDayAhead, setActiveDayAhead] = useState(wasDayAheadActive);

  useEffect(() => {
    setActiveWeekAhead(wasWeekAheadActive);
    setActiveDayAhead(wasDayAheadActive);
  }, [wasWeekAheadActive, wasDayAheadActive]);

  const [changesWereMadeWeekAhead, setChangesWereMadeWeekAhead] =
    useState(false);
  const [changesWereMadeDayAhead, setChangesWereMadeDayAhead] = useState(false);
  const [saving, setSaving] = useState(false);
  const refetchData = () => {
    setDataRefetchTrigger(!dataRefetchTrigger);
  };

  const formControlsWeekAhead = useWeekAheadFormControls();
  const formControlsDayAhead = useDayAheadFormControls();

  const {
    loading,
    weekAheadData,
    dayAheadData,
    setWeekAheadData,
    setDayAheadData,
    getDataByMarketType,
  } = useMarketType(dataRefetchTrigger);

  const [weekAheadExpanded, setWeekAheadExpanded] = useState<
    boolean | undefined
  >(undefined);
  const [dayAheadExpanded, setDayAheadExpanded] = useState<boolean | undefined>(
    undefined
  );

  const weekAheadErrors =
    changesWereMadeWeekAhead && !weekAheadExpanded
      ? 'Unsaved changes'
      : Object.keys(formControlsWeekAhead.errorsWeekAhead).length > 0 &&
        weekAheadExpanded
      ? Object.keys(formControlsWeekAhead.errorsWeekAhead).length.toString() +
        ' errors'
      : undefined;
  const dayAheadErrors =
    changesWereMadeDayAhead && !dayAheadExpanded
      ? 'Unsaved changes'
      : Object.keys(formControlsDayAhead.errorsDayAhead).length > 0 &&
        dayAheadExpanded
      ? Object.keys(formControlsDayAhead.errorsDayAhead).length.toString() +
        ' errors'
      : undefined;

  const buttonRoute = () =>
    history.push(location.pathname.replace(`/${serviceType}`, ''));

  const [updateAnalysisId, setUpdateAnalysisId] = useState<
    string | undefined
  >();
  const { onNeedsAnalysisSubmit } = useSubmitNeedsAnalysis(updateAnalysisId);

  const reloadData = () => {
    if (!changesWereMadeWeekAhead) {
      const dataForWeekAheadMarketType = getDataByMarketType(
        MarketType.weekAhead,
        formControlsWeekAhead,
        formControlsDayAhead
      );
      formControlsWeekAhead.setFormStateWeekAhead((prev) => ({
        ...prev,
        ...dataForWeekAheadMarketType,
      }));
      setWeekAheadData(dataForWeekAheadMarketType);
      setActiveWeekAhead(formControlsWeekAhead.getValuesWeekAhead('is_active'));
    }
    if (!changesWereMadeDayAhead) {
      const dataForDayAheadMarketType = getDataByMarketType(
        MarketType.dayAhead,
        formControlsWeekAhead,
        formControlsDayAhead
      );
      formControlsDayAhead.setFormStateDayAhead((prev) => ({
        ...prev,
        ...dataForDayAheadMarketType,
      }));
      setDayAheadData(dataForDayAheadMarketType);
      setActiveDayAhead(formControlsDayAhead.getValuesDayAhead('is_active'));
    }
  };

  const handleChange = (panel: MarketType, expanded: boolean) => {
    if (
      !saving &&
      expanded &&
      !changesWereMadeWeekAhead &&
      !changesWereMadeDayAhead
    ) {
      refetchData();
      reloadData();
    }
    if (panel === MarketType.weekAhead) {
      setWeekAheadExpanded(expanded);
      setUpdateAnalysisId(formControlsWeekAhead.getValuesWeekAhead('id'));
      formControlsWeekAhead.triggerWeekAheadValidation();
    }
    if (panel === MarketType.dayAhead) {
      setUpdateAnalysisId(formControlsDayAhead.getValuesDayAhead('id'));
      setDayAheadExpanded(expanded);
      formControlsDayAhead.triggerDayAheadValidation();
    }
  };

  const activateWeekAhead = () => {
    const isActive = !activeWeekAhead;
    setActiveWeekAhead(isActive);
    setChangesWereMadeWeekAhead(true);
    saveData(MarketType.weekAhead, isActive);
  };

  const activateDayAhead = () => {
    const isActive = !activeDayAhead;
    setActiveDayAhead(isActive);
    setChangesWereMadeDayAhead(true);
    saveData(MarketType.dayAhead, isActive);
  };

  const saveData = (marketType: MarketType, activeStatus?: boolean) => {
    setSaving(true);
    const isWeekAhead = marketType === MarketType.weekAhead;
    const activeStatusFromData = isWeekAhead ? activeWeekAhead : activeDayAhead;
    onNeedsAnalysisSubmit(
      isWeekAhead
        ? (formControlsWeekAhead.getValuesWeekAhead() as NeedsAnalysisType)
        : (formControlsDayAhead.getValuesDayAhead() as NeedsAnalysisType),
      serviceType,
      setSaving,
      refetchData,
      activeStatus !== undefined ? activeStatus : activeStatusFromData,
      reloadData,
      isWeekAhead ? setChangesWereMadeWeekAhead : setChangesWereMadeDayAhead
    );
  };

  return (
    <>
      {loading || saving ? (
        <LoadingSpinner />
      ) : (
        <div className="market-selection">
          <Button
            variant="text"
            onClick={buttonRoute}
            startIcon={<SVG icon="ChevronLeftIcon" fontSize="16px" />}
          >
            {'Back to needs analysis'}
          </Button>
          <PageTitle
            title=""
            classes={{
              titleClass: 'market-selection__title',
              actionsClass: 'market-selection__actions',
            }}
          >
            <Button
              variant="text"
              disabled={true}
              customStyles={{
                customButtonStyles: {
                  color: '#363B3F',
                  backgroundColor: '#ECF2F8',
                  cursor: 'pointer',
                },
              }}
            >
              {'Peer to Network'}
            </Button>
            <span>{serviceTypes[serviceType]}</span>
          </PageTitle>
          <Accordion
            id={`${weekAheadData?.service_type}-${weekAheadData?.market_type}`}
            expanded={weekAheadExpanded || false}
            onChange={(event, expanded) => {
              handleChange(MarketType.weekAhead, expanded);
            }}
            elevation={0}
            disableGutters
            sx={{
              position: 'inherit',
            }}
            className="market-selection__actions-accordion"
          >
            <AccordionSummary
              expandIcon={
                <SVG
                  round
                  icon="ChevronDownIcon"
                  fontSize={'1.1rem'}
                  color={'#54595e'}
                />
              }
              sx={{
                borderBottom: '2px solid #f9fbfd',
              }}
            >
              <NeedsAnalysisSummary
                label="week"
                active={activeWeekAhead}
                setActive={activateWeekAhead}
                errors={weekAheadErrors}
                displaySaveButton={weekAheadExpanded || false}
                saveData={saveData}
                changesWereMade={changesWereMadeWeekAhead}
              />
            </AccordionSummary>
            <AccordionDetails>
              <NeedsAnalysisForm
                marketType={MarketType.weekAhead}
                setChangesWereMade={setChangesWereMadeWeekAhead}
                formControls={formControlsWeekAhead}
              />
            </AccordionDetails>
          </Accordion>
          {!disableDayAhead && (
            <Accordion
              id={`${dayAheadData?.service_type}-${dayAheadData?.market_type}`}
              expanded={dayAheadExpanded || false}
              onChange={(event, expanded) => {
                handleChange(MarketType.weekAhead, expanded);
              }}
              elevation={0}
              disableGutters
              sx={{
                position: 'inherit',
              }}
              className="market-selection__actions-accordion"
            >
              <AccordionSummary
                expandIcon={
                  <SVG
                    round
                    icon="ChevronDownIcon"
                    fontSize={'1.1rem'}
                    color={'#54595e'}
                  />
                }
                sx={{
                  borderBottom: '2px solid #f9fbfd',
                }}
              >
                <NeedsAnalysisSummary
                  label="day"
                  active={activeDayAhead}
                  setActive={activateDayAhead}
                  errors={dayAheadErrors}
                  displaySaveButton={dayAheadExpanded || false}
                  saveData={saveData}
                  changesWereMade={changesWereMadeDayAhead}
                />
              </AccordionSummary>
              <AccordionDetails>
                <NeedsAnalysisForm
                  marketType={MarketType.dayAhead}
                  setChangesWereMade={setChangesWereMadeDayAhead}
                  formControls={formControlsDayAhead}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      )}
    </>
  );
};
