import React, { useEffect, useMemo } from 'react';
import classes from 'classnames';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Instance as FlatPickrInstance } from 'flatpickr/dist/types/instance';
import { DateTime } from 'luxon';

import { useUserContext } from 'contexts/UserContext';

import { getTimeGateDateTime } from 'helpers/time';

import { SelectOption } from 'types';
import {
  ContractModelOptions,
  flexType,
  flexTypeRaw,
  peakTypeRaw,
  serviceType as serviceTypeEnum,
  serviceTypeRaw,
} from 'types/contract-managment';
import { CreateRequestServiceProps } from './CreateRequestService.types';

import Select from 'components/Select';
import FormLabel from 'components/FormLabel';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import Tooltip from 'components/Tooltip';
import SVG from 'components/SVG';
import { DatePicker } from 'components/DatePicker';
import DialogSection from 'components/Dialog/DialogSection';

import CustomPeakSelector from 'routes/LongTermContracts/components/CustomPeakSelector';
// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'routes/LongTermContracts/components/DatePicker';
import RadioButtonGroup from 'routes/RequestsResponses/components/RadioButtonGroup';
import {
  getIsForecastBased,
  getIsManualInput,
  getMaxSiaDate,
  validateDERs,
} from 'routes/RequestsResponses/CreateRequest/CreateRequest.logic';
import {
  schema,
  contractModelOptions,
  selectStyles,
  serviceWindowTypes,
  updateSelectedDays,
  minDate,
} from './CreateRequestService.logic';

import './CreateRequestService.scss';

function CreateRequestService({
  responseAssets,
  feederList,
  availableIndustryActors,
  onSubmit,
  data,
  siaList,
  onFieldChange,
  program,
}: CreateRequestServiceProps) {
  const {
    contractModel,
    feeder,
    primary,
    industryActor,
    startDate,
    endDate,
    serviceWindow,
    serviceDays,
    serviceType,
    startTime,
    endTime,
    bidOffer,
    closeDate,
    distributedResource,
    quantityGeneration,
  } = data;

  const { userIsDso, isNotEnrolledP2P, isNotEnrolledP2N } = useUserContext();
  const isDso = userIsDso();
  const newCloseDate = closeDate || DateTime.local();
  const isMEC =
    !isDso && (!serviceType || serviceType?.value === serviceTypeRaw.MEC);
  const isMIC = !isDso && serviceType?.value === serviceTypeRaw.MIC;
  const deadlineDate = useMemo(
    () =>
      (!serviceType && isDso) || isMEC || isMIC
        ? newCloseDate
        : minDate(newCloseDate, getTimeGateDateTime(program)),
    [newCloseDate, isMEC, isMIC, isDso, serviceType, program]
  );

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema(newCloseDate, deadlineDate, isMEC, isMIC)),
  });

  const contractModelWatcher = useWatch({
    control,
    name: 'contractModel',
  });

  const zoneWatcher = useWatch({
    control,
    name: 'feeder',
  });

  const serviceDaysWatcher = useWatch({
    control,
    name: 'serviceDays',
  });

  const startTimeWatcher = useWatch({
    control,
    name: 'startTime',
  });

  const endTimeWatcher = useWatch({
    control,
    name: 'endTime',
  });

  const startDateWatcher = useWatch({
    control,
    name: 'startDate',
  });

  const endDateWatcher = useWatch({
    control,
    name: 'endDate',
  });

  const serviceTypeWatcher = useWatch({
    control,
    name: 'serviceType',
  });

  const disabledDERsMessage = validateDERs(
    endTimeWatcher,
    startTimeWatcher,
    serviceDaysWatcher,
    zoneWatcher,
    data
  );

  const isDirectRequest = contractModelWatcher === ContractModelOptions.Direct;

  const requestTypes = [
    {
      label: flexType.BID,
      id: flexTypeRaw.BID,
      disabled: false,
    },
    {
      label: flexType.OFFER,
      id: flexTypeRaw.OFFER,
      disabled: isDso,
    },
  ];

  const serviceTypesIA = [
    {
      label: serviceTypeEnum.MEC,
      value: serviceTypeRaw.MEC,
      disabled: isNotEnrolledP2P,
    },
    {
      label: serviceTypeEnum.MIC,
      value: serviceTypeEnum.MIC,
      disabled: isNotEnrolledP2P,
    },
  ];

  const serviceTypesDSO = [
    {
      label: serviceTypeEnum.SustainPeakManagement,
      value: serviceTypeRaw.SustainPeakManagement,
      disabled: isNotEnrolledP2N,
    },
    {
      label: serviceTypeEnum.SustainExportManagement,
      value: serviceTypeRaw.SustainExportManagement,
      disabled: isNotEnrolledP2N,
    },
    {
      label: serviceTypeEnum.SustainBilateral,
      value: serviceTypeRaw.SustainBilateral,
      disabled: !isDirectRequest || isNotEnrolledP2N,
    },
    {
      label: serviceTypeEnum.DynamicPeakManagement,
      value: serviceTypeRaw.DynamicPeakManagement,
      disabled: isNotEnrolledP2N,
    },
    {
      label: serviceTypeEnum.SecurePeakManagement,
      value: serviceTypeRaw.SecurePeakManagement,
      disabled: isNotEnrolledP2N,
    },
  ];

  const isManualInput = getIsManualInput(undefined, quantityGeneration);
  const isForecastBased = getIsForecastBased(undefined, quantityGeneration);
  const maxDate = isForecastBased && siaList && getMaxSiaDate(siaList);

  useEffect(() => {
    if (serviceTypeWatcher || zoneWatcher) {
      setValue('distributedResource', { label: '', value: null });
    }
  }, [serviceTypeWatcher, zoneWatcher, setValue]);

  const primaries =
    isDso && isManualInput
      ? zoneWatcher?.primaries?.map((primary: any) => ({
          label: primary.name,
          value: primary.id,
        }))
      : null;

  return (
    <form id="create-request-service" onSubmit={handleSubmit(onSubmit)}>
      <DialogSection
        classNames={{ headingClass: 'create-request-service__section-title' }}
        title={'Topology & participants'}
        icon="MapPinIcon"
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormLabel
              required
              styles={{ marginBottom: '8px', maxWidth: '150px' }}
              htmlFor="ltcontractmodel"
            >
              Participant selection
              <Tooltip
                arrow
                content="A pool request sends all enrolled participants a request for flexibility. A direct request is for a specific participant."
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="contractModel"
              control={control}
              defaultValue={contractModel || contractModelOptions[0].id}
              render={({ field: { onChange, value } }) => (
                <RadioButtonGroup
                  id="ltcontractmodel"
                  listType="row"
                  label=""
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('contractModel', value);
                  }}
                  options={contractModelOptions}
                  value={value}
                  classes={{
                    btnClass: 'create-request-service__service-button',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            {(isDirectRequest ||
              (contractModelWatcher === undefined &&
                contractModel === ContractModelOptions.Direct)) && (
              <>
                <FormLabel required htmlFor="industryActor">
                  Industry Actor
                </FormLabel>

                <Controller
                  name="industryActor"
                  control={control}
                  defaultValue={industryActor}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      isClearable={false}
                      isMulti={false}
                      options={availableIndustryActors as SelectOption[]}
                      onChange={(value) => {
                        onChange(value);
                        onFieldChange('industryActor', value);
                      }}
                      error={!!errors?.industryActor?.message}
                      styles={selectStyles}
                    />
                  )}
                />
              </>
            )}
            <div className="create-request-service__error">
              {(isDirectRequest ||
                (contractModelWatcher === undefined &&
                  contractModel === ContractModelOptions.Direct)) &&
                errors?.industryActor?.message}
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <FormLabel required htmlFor="feeder">
              Zone
              <Tooltip arrow content="Zone the request occurs in" theme="light">
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="feeder"
              control={control}
              defaultValue={feeder}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isClearable={false}
                  isMulti={false}
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('feeder', value);
                  }}
                  options={feederList}
                  error={!!errors?.feeder?.message}
                  styles={selectStyles}
                  placeholder="Select zone"
                />
              )}
            />
            <div className="create-request-service__error">
              {errors?.feeder?.message}
            </div>
          </Grid>
          <Grid item xs={4}>
            <FormLabel htmlFor="primary">
              Primary
              <Tooltip
                arrow
                content="Primaries associated with the zone selected"
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="primary"
              control={control}
              defaultValue={isManualInput ? primary : null}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isClearable={false}
                  isMulti={false}
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('primary', value);
                  }}
                  options={primaries}
                  error={!!errors?.primary?.message}
                  styles={selectStyles}
                  placeholder="Select primary"
                  isDisabled={!isDso || !isManualInput}
                />
              )}
            />
            <div className="create-request-service__error">
              {errors?.primary?.message}
            </div>
          </Grid>
        </Grid>
      </DialogSection>
      <DialogSection
        isLast
        classNames={{ headingClass: 'create-request-service__section-title' }}
        title="Service details"
        icon="ToolIcon"
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormLabel required htmlFor="startDate">
              Service start date
              <Tooltip
                arrow
                content="Service occurs anytime between Sunday 00:00 to Saturday 23:59"
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="startDate"
              control={control}
              defaultValue={startDate?.startOf('day') || deadlineDate}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  minDate={deadlineDate}
                  maxDate={maxDate || null}
                  onChange={(val: any) => {
                    onChange(val);
                    onFieldChange('startDate', val);
                    setValue(
                      'serviceDays',
                      updateSelectedDays(
                        val,
                        getValues('endDate'),
                        getValues('serviceDays')
                      )
                    );
                  }}
                  value={value}
                />
              )}
            />
            <div className="create-request-service__error">
              {errors?.startDate?.message}
            </div>
          </Grid>
          <Grid item xs={4}>
            <FormLabel required htmlFor="endDate">
              Service end date
              <Tooltip
                arrow
                content="Service occurs anytime between Sunday 00:00 to Saturday 23:59"
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="endDate"
              control={control}
              defaultValue={
                endDate?.startOf('day') ||
                deadlineDate.startOf('day').plus({ days: 1 })
              }
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  minDate={deadlineDate}
                  maxDate={maxDate || null}
                  onChange={(val: any) => {
                    onChange(val);
                    onFieldChange('endDate', val);
                    setValue(
                      'serviceDays',
                      updateSelectedDays(
                        getValues('startDate'),
                        val,
                        getValues('serviceDays')
                      )
                    );
                  }}
                  value={value}
                />
              )}
            />
            <div className="create-request-service__error">
              {errors?.endDate?.message}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormLabel
              required
              htmlFor="serviceWindow"
              styles={{ maxWidth: '130px' }}
            >
              Service Window
              <Tooltip
                arrow
                content="Select the service window for this request."
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="serviceWindow"
              control={control}
              defaultValue={serviceWindow || peakTypeRaw.Custom}
              render={({ field: { onChange, value } }) => (
                <RadioButtonGroup
                  id="serviceWindow"
                  listType="row"
                  label=""
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('serviceWindow', value);
                  }}
                  options={serviceWindowTypes}
                  value={value}
                  classes={{
                    groupClass: 'create-request-service__service-radio-group',
                    btnClass: 'create-request-service__service-button',
                  }}
                />
              )}
            />
            <div className="create-request-service__error">
              {errors?.serviceWindow?.message}
            </div>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <FormLabel
                required
                htmlFor="serviceDays"
                styles={{ maxWidth: '130px' }}
              >
                Service days
                <Tooltip
                  arrow
                  content="The request will service the selected days and times"
                  theme="light"
                >
                  <SVG
                    icon="InfoIcon"
                    fontSize="12px"
                    className="create-request-service__label-icon"
                  />
                </Tooltip>
              </FormLabel>
              <Controller
                name="serviceDays"
                control={control}
                defaultValue={serviceDays}
                render={({ field: { onChange, value } }) => (
                  <CustomPeakSelector
                    startDate={
                      startDate ? startDate : startDateWatcher || deadlineDate
                    }
                    endDate={
                      endDate
                        ? endDate
                        : endDateWatcher ||
                          deadlineDate.startOf('day').plus({ days: 1 })
                    }
                    selectedDays={value}
                    onChange={(value) => {
                      onChange(value);
                      onFieldChange('serviceDays', value);
                    }}
                  />
                )}
              />
              <div style={{ marginTop: '4px' }}>
                <div className="create-request-service__error">
                  {errors?.serviceDays?.message}
                </div>
              </div>
            </Grid>
            <Grid
              container
              item
              xs={3}
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <FormLabel required htmlFor="startTime">
                  Start
                </FormLabel>
                <Controller
                  name="startTime"
                  control={control}
                  defaultValue={startTime}
                  render={({ field: { onChange, value } }) => (
                    <OldDatePicker
                      date={value}
                      showArrows={false}
                      useUTC={false}
                      defaultDate={startTime ? undefined : null}
                      onClose={(value) => {
                        onChange(value);
                        onFieldChange('startTime', value);
                      }}
                      options={{
                        noCalendar: true,
                        enableTime: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                        onReady: (
                          date: Date[],
                          time: string,
                          instance: FlatPickrInstance
                        ) => {
                          instance.calendarContainer.classList.add(
                            'create-request-service__flatpickr'
                          );
                        },
                      }}
                      customClasses={{
                        inputClass: classes(
                          'create-request-service__date small-input-clock',
                          {
                            'create-request-service__error-input':
                              errors?.startTime?.message,
                          }
                        ),
                      }}
                    />
                  )}
                />
                <div className="create-request-service__error">
                  {errors?.startTime?.message}
                </div>
              </Grid>
              <Grid item>
                <FormLabel required htmlFor="endTime">
                  End
                </FormLabel>
                <Controller
                  name="endTime"
                  defaultValue={endTime}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <OldDatePicker
                      date={value}
                      showArrows={false}
                      useUTC={false}
                      defaultDate={endTime ? undefined : null}
                      onClose={(value) => {
                        onChange(value);
                        onFieldChange('endTime', value);
                      }}
                      options={{
                        noCalendar: true,
                        enableTime: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                        onValueUpdate: (
                          date: Date[],
                          time: string,
                          instance: FlatPickrInstance
                        ) => {
                          if (time === '00:00') {
                            if (instance.hourElement) {
                              instance.hourElement.value = '24';
                            }
                            instance.input.value = '24:00';
                          }
                        },
                        onReady: (
                          date: Date[],
                          time: string,
                          instance: FlatPickrInstance
                        ) => {
                          instance.calendarContainer.classList.add(
                            'create-request-service__flatpickr'
                          );
                          if (time === '00:00') {
                            if (instance.hourElement) {
                              instance.hourElement.value = '24';
                            }
                            instance.input.value = '24:00';
                          }
                        },
                      }}
                      customClasses={{
                        inputClass: classes(
                          'create-request-service__date small-input-clock',
                          {
                            'create-request-service__error-input':
                              errors?.endTime?.message,
                          }
                        ),
                      }}
                    />
                  )}
                />
                <div className="create-request-service__error">
                  {errors?.endTime?.message}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <FormLabel required htmlFor="serviceType">
              Service Type
              <Tooltip arrow content="Flexibility service type." theme="light">
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="serviceType"
              control={control}
              defaultValue={
                serviceType || (isDso ? serviceTypesDSO[0] : serviceTypesIA[0])
              }
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isClearable={false}
                  options={isDso ? serviceTypesDSO : serviceTypesIA}
                  isDisabled={isNotEnrolledP2N && isNotEnrolledP2P}
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('serviceType', value);
                  }}
                  error={!!errors?.contractType?.message}
                  helperText={errors?.contractType?.message}
                  styles={selectStyles}
                  classes={{ wrapperClass: 'create-request-service__select' }}
                />
              )}
            />
            <div className="create-request-service__error">
              {isNotEnrolledP2N && isNotEnrolledP2P
                ? 'Service types are not enrolled on P2P or P2N services'
                : errors?.serviceType?.message}
            </div>
          </Grid>
          {(isMEC || isMIC) && (
            <Grid item xs={4}>
              <FormLabel
                required={bidOffer === flexTypeRaw.BID}
                htmlFor="distributedResource"
              >
                Distributed energy resource
                <Tooltip
                  arrow
                  content="The zone, service dates, times, days, window and type must be set."
                  theme="light"
                >
                  <SVG
                    icon="InfoIcon"
                    fontSize="12px"
                    className="create-request-service__label-icon"
                  />
                </Tooltip>
              </FormLabel>
              <Controller
                name="distributedResource"
                defaultValue={distributedResource || undefined}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="distributedResource"
                    value={value}
                    isClearable={false}
                    isMulti={false}
                    options={responseAssets}
                    isDisabled={!!disabledDERsMessage}
                    onChange={(value) => {
                      onChange(value);
                      onFieldChange('distributedResource', value);
                    }}
                    styles={selectStyles}
                    classes={{ wrapperClass: 'create-request-service__select' }}
                  />
                )}
              />
              <div
                className={classes('create-request-service__message', {
                  'create-request-service__error':
                    errors?.distributedResource?.message,
                })}
              >
                {disabledDERsMessage
                  ? disabledDERsMessage
                  : errors?.distributedResource?.message}
              </div>
            </Grid>
          )}
          <Grid item xs={4}>
            {/* <FormLabel
              required
              htmlFor="maximumDailyEnergy"
              styles={{ maxWidth: '150px' }}
            >
              Maximum daily energy
            </FormLabel>
            <Controller
              name="maximumDailyEnergy"
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  id="maximumDailyEnergy"
                  unit="/kWh"
                  onChange={(value) => onChange(Number(value))}
                  value={value}
                  invalid={!!errors?.maximumDailyEnergy?.message}
                />
              )}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <FormLabel
              required
              htmlFor="bidOffer"
              styles={{ maxWidth: '115px' }}
            >
              Request Type
              <Tooltip arrow content="Flexibility service type" theme="light">
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="bidOffer"
              control={control}
              defaultValue={
                bidOffer || (isDso ? requestTypes[0].id : requestTypes[1].id)
              }
              render={({ field: { value, onChange } }) => (
                <RadioButtonGroup
                  id="bidOffer"
                  listType="row"
                  label=""
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('bidOffer', value);
                  }}
                  options={requestTypes}
                  value={bidOffer || value}
                  classes={{
                    groupClass: 'create-request-service__bid-offer',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogSection>
    </form>
  );
}

export default CreateRequestService;
