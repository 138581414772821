import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import PageTitle from 'components/PageTitle';
import MenuItem from 'components/Menu/MenuItem/MenuItem';
import MenuList from 'components/Menu/MenuList';

import { Routes } from 'routes/Program/routes/Reports/Reports';

import './ReportsMenu.scss';

export const ReportsMenu = ({ programID }: { programID: string }) => {
  const history = useHistory();
  const { hasAllPermissions } = useAuthContext();

  const isAuditLogs = useRouteMatch(Routes.auditLogs)?.isExact;
  const isSettlements = useRouteMatch(Routes.settlements)?.isExact;
  const isMarketTransactions = useRouteMatch(
    Routes.marketTransactions
  )?.isExact;
  const isPowerFlowAnalysis = useRouteMatch(Routes.powerFlowAnalysis)?.isExact;
  const basicRoute = `/program/${programID}/reports`;

  return (
    <div className="reports-menu">
      <PageTitle
        title="Program reports"
        classes={{ titleClass: 'reports-menu__title' }}
      />
      <MenuList>
        <MenuItem
          onClick={() => history.push(`${basicRoute}/settlements`)}
          classes={{
            rootClass: classNames('reports-menu__text', {
              'reports-menu__active': isSettlements,
            }),
          }}
        >
          Settlements
        </MenuItem>
        <MenuItem
          onClick={() => history.push(`${basicRoute}/market-transactions`)}
          classes={{
            rootClass: classNames('reports-menu__text', {
              'reports-menu__active': isMarketTransactions,
            }),
          }}
        >
          Market transactions
        </MenuItem>
        <MenuItem
          onClick={() => history.push(`${basicRoute}/power-flow-analysis`)}
          classes={{
            rootClass: classNames('reports-menu__text', {
              'reports-menu__active': isPowerFlowAnalysis,
            }),
          }}
        >
          Power flow analysis
        </MenuItem>
        {hasAllPermissions(['download_audit_logs']) && (
          <MenuItem
            onClick={() => history.push(`${basicRoute}/audit-logs`)}
            classes={{
              rootClass: classNames('reports-menu__text', {
                'reports-menu__active': isAuditLogs,
              }),
            }}
          >
            Audit log
          </MenuItem>
        )}
      </MenuList>
    </div>
  );
};
