import React, { Component } from 'react';
import flatpickr from 'flatpickr';
import classNames from 'classnames';
import { Instance as FlatPickrInstance } from 'flatpickr/dist/types/instance';
import { Options as FlatPickrOptions } from 'flatpickr/dist/types/options';
import isEqual from 'lodash.isequal';
import { DateTime } from 'luxon';

import {
  getProgramOffsetTimeMilliseconds,
  systemTimeToProgramTime,
} from 'helpers/time';

import '../OldDatePicker/DatePickerCalendarInstance.scss';
import './DateRangePicker.scss';

type DatePickerProps = {
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;
  dateFormat?: string;
  disabled?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  onClose: (startDate: DateTime, endDate: DateTime) => void;
  options: FlatPickrOptions;
  error?: boolean;
  helperText?: string;
  controlled?: boolean;
  customClasses?: {
    inputClass?: string;
    datePickerClass?: string;
  };
};

type DatePickerState = {
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;
};

/*
 * Creates a date picker instance that has forward and back buttons for the dates
 * and an input that triggers the calendar to open. The default is to allow selection
 * of both date and time
 */
class DatePicker extends Component<DatePickerProps, DatePickerState> {
  public static defaultProps = {
    date: null,
    disabled: false,
    options: {},
    dateFormat: 'M j, Y',
    controlled: true,
  };

  calendar: FlatPickrInstance | null;
  dateInput: HTMLInputElement | null;

  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      endDate: this.props.endDate || DateTime.local(),
      startDate: this.props.startDate || DateTime.local(),
    };

    this.calendar = null;
    this.dateInput = null;
  }

  componentDidMount() {
    this.createDateField(this.state.startDate, this.state.endDate);
  }

  componentDidUpdate(prevProps: DatePickerProps) {
    if (!this.props.controlled) {
      return;
    }
    // If calendar is controlled, update the internal date state
    const { calendar, props } = this;
    const { startDate, endDate, options } = props;

    if (
      !!endDate &&
      !!startDate &&
      !!prevProps.endDate &&
      !!prevProps.startDate
    ) {
      if (
        calendar &&
        (endDate.valueOf() !== prevProps.endDate.valueOf() ||
          startDate.valueOf() !== prevProps.startDate.valueOf())
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          endDate,
          startDate,
        });
        // calendar.setDate(date.toLocaleString());
        calendar.setDate([startDate.valueOf(), endDate.valueOf()]);
        this.createDateField(startDate, endDate);
      }
    }

    // this.createDateField may have recreated the calendar
    // Need to use this.calendar after this point
    if (!isEqual(prevProps.options, options) && this.calendar) {
      Object.keys(options).forEach((key) => {
        // @ts-ignore
        this.calendar.set(key, options[key]);
      });
      this.calendar.redraw();
    }

    // Allow for undefined value to clear the calendar
    if (
      endDate === undefined &&
      startDate === undefined &&
      prevProps.endDate !== endDate &&
      prevProps.startDate !== startDate
    ) {
      calendar?.clear();
      this.setState({
        endDate,
        startDate,
      });
    }
  }

  componentWillUnmount() {
    if (this.calendar && this.calendar.destroy) {
      this.calendar.destroy();
    }
  }

  getDefaultDate(date: DateTime | undefined): Date | undefined {
    if (date) {
      const dateMilliseconds = getProgramOffsetTimeMilliseconds(date);
      return new Date(dateMilliseconds);
    }
  }

  mapDateOffsetOnClose(date: Date): DateTime {
    const dateMilliseconds = systemTimeToProgramTime(date);
    return DateTime.fromMillis(dateMilliseconds);
  }

  createDateField = (
    startDate: DateTime | undefined,
    endDate: DateTime | undefined
  ) => {
    const { calendar, dateInput, props } = this;
    let options = { ...props.options };
    const { maxDate, minDate } = props;
    const { dateFormat } = props;

    if (minDate && maxDate) {
      options = {
        ...options,
        minDate: minDate.valueOf(),
        maxDate: maxDate.valueOf(),
      };
    }

    const defaultDate = [
      this.getDefaultDate(startDate),
      this.getDefaultDate(endDate),
    ];

    // @ts-ignore
    this.calendar = flatpickr(dateInput, {
      time_24hr: true,
      minuteIncrement: 1,
      dateFormat,
      mode: 'range',
      onClose: (dates: Date[]) => {
        const mappedDates = dates.map(this.mapDateOffsetOnClose);
        this.handleDateChange(mappedDates);
      },
      defaultDate,
      ...options,
    });
    if (calendar && calendar.calendarContainer) {
      calendar.calendarContainer.classList.add('date-picker-calendar-instance');
    }
  };

  handleDateChange = (dates: DateTime[]) => {
    if (dates.length === 1) {
      // This occurs during the intermediate range selection
      // so ignore for now
      return;
    }
    // User selected a range
    const startDate = dates[0];
    const endDate = dates[1];
    this.setState({
      startDate,
      endDate,
    });
    this.props.onClose(startDate, endDate);
  };

  render() {
    const { disabled, error, helperText, customClasses } = this.props;
    return (
      <div
        className={classNames(
          'date-range-picker',
          `${customClasses?.datePickerClass || ''}`
        )}
      >
        <input
          className={classNames(
            'date-range-picker__input',
            `${customClasses?.inputClass || ''}`
          )}
          name="startDate"
          disabled={disabled}
          placeholder="From - To"
          ref={(div) => {
            this.dateInput = div;
          }}
        />
        {helperText && (
          <p
            className={classNames(
              'date-range-picker__text',
              'date-range-picker__helper',
              { 'date-range-picker__text--error': error }
            )}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
}

export default DatePicker;
