import React, { useState } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import Popover from 'components/Popover';
import TextField from 'components/TextField';
import IconButton from 'components/IconButton';
import { MONTHS } from './months';
import './MonthPicker.scss';

interface MonthPickerProps {
  date: DateTime;
  onChange?: (date: DateTime) => void;
  onClose?: (date: DateTime) => void;
  disabled?: boolean;
}

const MonthPicker = ({
  date,
  onChange,
  onClose,
  disabled = false,
}: MonthPickerProps) => {
  const [cancel, setCancel] = useState<boolean>(false);
  const [state, setState] = useState<DateTime>(date);

  const getFormattedTitle = (date: DateTime) => date.toFormat('MMMM yyyy');

  const monthList = MONTHS.map((item) => ({
    name: item.name,
    value: state.set({ year: state.get('year'), month: item.value }),
    isActive: state.get('month') === item.value,
  }));

  const onYearChangeHandler = (direction: 'left' | 'right') => {
    const yearNumber = direction === 'left' ? -1 : 1;
    onChange && onChange(date.plus({ year: yearNumber }));
    setState((prev) => prev?.plus({ year: yearNumber }));
  };

  const onMonthClickHandler = (val: DateTime) => {
    setState(val);
    onChange && onChange(val);
    onClose && onClose(val);
    setCancel((prev) => !prev);
  };

  return (
    <Popover
      cancelTrigger={onClose && cancel}
      title={<TextField value={getFormattedTitle(date)} />}
      content={
        <div className="month-picker">
          <div className="month-picker__year">
            <IconButton
              variant="nestedIconButton"
              onClick={() => onYearChangeHandler('left')}
              icon="ChevronLeftIcon"
              disabled={disabled}
            />
            <span>{getFormattedTitle(state)}</span>
            <IconButton
              variant="nestedIconButton"
              onClick={() => onYearChangeHandler('right')}
              icon="ChevronRightIcon"
              disabled={disabled}
            />
          </div>
          <div className="month-picker__month">
            {monthList.map((item) => (
              <span
                className={classNames('month-picker__month-item', {
                  'month-picker__month-item--selected': item.isActive,
                })}
                key={item.name}
                onClick={() => onMonthClickHandler(item.value)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default MonthPicker;
