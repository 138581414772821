import React, { useMemo } from 'react';
import cn from 'classnames';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  TableInstance,
  IdType,
  Cell,
  Row,
  Column,
  useExpanded,
  useFlexLayout,
  PluginHook,
} from 'react-table';

import SVG from 'components/SVG';

import './Table.scss';

type customBodyCellRenderFunctionType = (
  cell: Cell,
  index: number,
  cellClass: string | undefined
) => React.ReactElement;

type customHeaderCellRenderFunctionType = (
  column: Column,
  index: number,
  tableHeadCellClass: string | undefined,
  renderSortedArrows: (
    sorted: boolean,
    sortedDesc: boolean | undefined
  ) => React.ReactElement | undefined
) => React.ReactElement;

interface TableParams {
  columns: any;
  data: any[];
  disableSort?: boolean;
  tableType?: 'HTML' | 'Flex' | 'Grid';
  initialFilterState?: { id: string; value: any }[];
  header?: (instance: TableInstance) => React.ReactElement;
  customRowRenderFunction?: (
    row: Row,
    index: number,
    tableRowClass?: string | undefined,
    tableCellClass?: string | undefined,
    customCellRenderFunction?: customBodyCellRenderFunctionType | undefined
  ) => React.ReactElement;
  customCellRenderFunction?: customBodyCellRenderFunctionType;
  customHeaderCellRenderFunction?: customHeaderCellRenderFunctionType;
  customGlobalFilterFunction?: (
    rows: Row<any>[],
    ids: IdType<any>[],
    query: string
  ) => Row[];
  onRowClick?: (
    e: React.MouseEvent<HTMLTableRowElement | HTMLDivElement, MouseEvent>,
    rowData: any
  ) => void;
  placeHolderRow?: () => React.ReactElement;
  customClasses?: {
    tableClass?: string;
    tableHeaderClass?: string;
    tableHeaderRowClass?: string;
    tableHeaderCellClass?: string;
    tableBodyClass?: string;
    tableBodyRowClass?: string;
    tableBodyRowExpandedClass?: string;
    tableBodyCellClass?: string;
  };
}

/**
 * General component that handles the basic table logic.
 * Use a wrapper style the table and define table specific logic.
 * NOTE: unless specified all passed params need to be memoized.
 */
const Table = ({
  columns,
  data,
  header,
  placeHolderRow,
  initialFilterState,
  onRowClick,
  customCellRenderFunction,
  customRowRenderFunction,
  customHeaderCellRenderFunction,
  customGlobalFilterFunction,
  customClasses = {},
  disableSort = false,
  tableType = 'Grid',
}: TableParams) => {
  const {
    tableClass,
    tableHeaderClass,
    tableHeaderRowClass,
    tableHeaderCellClass,
    tableBodyClass,
    tableBodyRowClass,
    tableBodyRowExpandedClass,
    tableBodyCellClass,
  } = customClasses;

  const options: any = {};
  options['globalFilter'] = customGlobalFilterFunction;
  options['useSortBy'] = !disableSort;

  const plugins: PluginHook<object>[] = [];
  if (tableType === 'Flex') plugins.push(useFlexLayout);

  const instance = useTable(
    {
      initialState: { filters: initialFilterState || [] },
      columns,
      data,
      ...options,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    ...plugins
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  const renderSortedArrows = useMemo(
    () => (sorted: boolean, sortedDesc: boolean | undefined) => {
      if (sorted) {
        if (sortedDesc) {
          return (
            <span className="table__sort">
              <SVG icon="TriangleIcon" fontSize="8px" />
            </span>
          );
        }
        return (
          <span className="table__sort">
            <SVG
              icon="TriangleIcon"
              fontSize="8px"
              styles={{ transform: 'rotate(90deg)' }}
            />
          </span>
        );
      }
      return (
        <span className="table__sort">
          <i style={{ visibility: 'hidden' }}>x</i>
        </span>
      );
    },
    []
  );

  if (tableType !== 'HTML') {
    return (
      <>
        {header && header(instance)}
        <div className={cn('table', tableClass)} {...getTableProps()}>
          <div className={cn('table__header', tableHeaderClass)}>
            {headerGroups.map((headerGroup, index) => (
              <div
                className={cn('table__header-row', tableHeaderRowClass)}
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => {
                  if (customHeaderCellRenderFunction) {
                    return customHeaderCellRenderFunction(
                      column,
                      index,
                      tableHeaderCellClass,
                      renderSortedArrows
                    );
                  } else {
                    return (
                      <div
                        {...column.getHeaderProps(
                          !disableSort
                            ? column.getSortByToggleProps()
                            : undefined
                        )}
                        key={index}
                        className={cn(
                          'table__header-cell',
                          tableHeaderCellClass
                        )}
                      >
                        {column.render('Header')}
                        {!disableSort &&
                          renderSortedArrows(
                            column.isSorted,
                            column.isSortedDesc
                          )}
                      </div>
                    );
                  }
                })}
              </div>
            ))}
          </div>
          <div
            className={cn('table__body', tableBodyClass)}
            {...getTableBodyProps()}
          >
            {(data.length === 0 || rows.length === 0) &&
              placeHolderRow &&
              placeHolderRow()}
            {rows.map((row, index) => {
              prepareRow(row);
              if (customRowRenderFunction) {
                return customRowRenderFunction(
                  row,
                  index,
                  tableBodyRowClass,
                  tableBodyCellClass,
                  customCellRenderFunction
                );
              }

              const rowClass =
                row.depth > 0 ? tableBodyRowExpandedClass : tableBodyRowClass;

              return (
                <div
                  className={cn('table__body-row', rowClass)}
                  onClick={
                    onRowClick ? (e) => onRowClick(e, row.original) : undefined
                  }
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    if (customCellRenderFunction) {
                      return customCellRenderFunction(
                        cell,
                        index,
                        tableBodyCellClass
                      );
                    } else {
                      return (
                        <div
                          {...cell.getCellProps()}
                          key={index}
                          className={cn('table__body-cell', tableBodyCellClass)}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {header && header(instance)}
      <table
        className={tableClass}
        {...getTableProps()}
        cellSpacing="0"
        cellPadding="0"
      >
        <thead className={tableHeaderClass}>
          {headerGroups.map((headerGroup, index) => (
            <tr
              className={tableHeaderRowClass}
              {...headerGroup.getHeaderGroupProps()}
              key={index}
            >
              {headerGroup.headers.map((column, index) => {
                if (customHeaderCellRenderFunction) {
                  return customHeaderCellRenderFunction(
                    column,
                    index,
                    tableHeaderCellClass,
                    renderSortedArrows
                  );
                } else {
                  return (
                    <th
                      {...column.getHeaderProps(
                        !disableSort ? column.getSortByToggleProps() : undefined
                      )}
                      key={index}
                      className={tableHeaderCellClass}
                    >
                      {column.render('Header')}
                      {!disableSort &&
                        renderSortedArrows(
                          column.isSorted,
                          column.isSortedDesc
                        )}
                    </th>
                  );
                }
              })}
            </tr>
          ))}
        </thead>
        <tbody className={tableBodyClass} {...getTableBodyProps()}>
          {(data.length === 0 || rows.length === 0) &&
            placeHolderRow &&
            placeHolderRow()}
          {rows.map((row, index) => {
            prepareRow(row);
            if (customRowRenderFunction) {
              return customRowRenderFunction(
                row,
                index,
                tableBodyRowClass,
                tableBodyCellClass,
                customCellRenderFunction
              );
            }

            const rowClass =
              row.depth > 0 ? tableBodyRowExpandedClass : tableBodyRowClass;

            return (
              <tr
                className={rowClass}
                onClick={
                  onRowClick ? (e) => onRowClick(e, row.original) : undefined
                }
                {...row.getRowProps()}
                key={index}
              >
                {row.cells.map((cell, index) => {
                  if (customCellRenderFunction) {
                    return customCellRenderFunction(
                      cell,
                      index,
                      tableBodyCellClass
                    );
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className={tableBodyCellClass}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Table;
