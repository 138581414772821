import * as Yup from 'yup';
import { FilesField } from './BaselineImportForm.types';

export enum Fields {
  Files = 'files',
}

export const schema = Yup.object().shape({
  [Fields.Files]: Yup.object()
    .test(
      'has-file',
      'You must upload at least one file.',
      (value: FilesField, data: any): boolean => {
        return Object.values(value || {}).some((val) => val && val.length > 0);
      }
    )
    .nullable(),
});
