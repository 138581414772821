import React, { useEffect, useRef, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { Tenant } from 'types/tenant';
import { CurrentUser } from 'types/user';

import RouterSwitch from 'components/RouterSwitch';
import Button from 'components/Button';

import Requests from './routes/Requests';
import ViewRequest from './routes/ViewRequest';
import CreateRequest from './CreateRequest';

import RequestsResponsesHeader from './components/RequestsResponsesHeader';

import './RequestsResponses.scss';

enum Routes {
  MainPath = '/program/:programID/requests-responses',
  RequestPath = '/program/:programID/requests-responses/request/:requestID',
}

const RequestsResponses: React.FunctionComponent = () => {
  const { addToast } = useToasts();

  const [showCreateFlexRequest, setShowCreateFlexRequest] = useState(false);
  const [refetchRequests, toggleRefetchRequests] = useState(false);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const jobCompleted = useRef(false);

  const { programID } = useParams<{ programID: string }>();
  const { user: currentUser, userIsDso, isNotEnrolledP2P } = useUserContext();
  const { selectedProgram } = useProgramsContext();
  const program = selectedProgram || null;

  function toggleCreateFlexRequest() {
    setShowCreateFlexRequest(!showCreateFlexRequest);
  }

  const { data: tenants } = useRequestEffect<Tenant[]>({
    url: '/api/dsp/tenants',
    method: 'get',
    toast: {
      error: 'Failed to load tenant list.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { makeRequest: getJobData } = useRequest(`/api/dsp/job/${jobId}`);

  useEffect(() => {
    if (jobId) {
      const interval = setInterval(
        () =>
          !jobCompleted.current &&
          getJobData({
            method: 'get',
            onSuccess: (data) => {
              if (data.status === 'completed') {
                toggleRefetchRequests((prev) => !prev);
                addToast(
                  `Successfully completed utilization analysis for request creation.`,
                  {
                    appearance: 'success',
                  }
                );
                return (jobCompleted.current = true);
              } else if (data.status === 'failed') {
                addToast(`Utilization analysis failed for request creation.`, {
                  appearance: 'error',
                });
                return (jobCompleted.current = true);
              }
            },
          }),
        3000
      );
      if (jobCompleted.current) {
        clearInterval(interval);
      }
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, jobCompleted.current]);

  if (!currentUser || !tenants) {
    return <></>;
  }

  return (
    <div className="requests-responses">
      <RouterSwitch useRedirect404>
        <Route exact path={Routes.MainPath}>
          <RequestsResponsesHeader title="All Requests">
            <Button
              onClick={() => {
                setShowCreateFlexRequest(true);
              }}
              disabled={!userIsDso() && isNotEnrolledP2P}
            >
              Create Request
            </Button>
          </RequestsResponsesHeader>
          <Requests
            tenants={tenants}
            program={program}
            refetchTrigger={refetchRequests}
          />
          <CreateRequest
            open={showCreateFlexRequest}
            onClose={toggleCreateFlexRequest}
            currentUser={currentUser as CurrentUser}
            tenants={tenants}
            programID={programID}
            refreshTrigger={{
              value: refetchRequests,
              setter: toggleRefetchRequests,
            }}
            setJobId={(id: string) => {
              if (id) {
                jobCompleted.current = false;
                setJobId(id);
              }
            }}
          />
        </Route>
        <Route path={Routes.RequestPath}>
          <ViewRequest
            tenants={tenants}
            loggedInUser={currentUser as CurrentUser}
            program={program}
          />
        </Route>
      </RouterSwitch>
    </div>
  );
};

export default RequestsResponses;
