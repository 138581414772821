import React, { useState } from 'react';
import { DateTime } from 'luxon';

import PageTitle from 'components/PageTitle';

import { CardProps } from 'routes/Program/routes/Reports/Reports';
import { PeriodsTable } from 'routes/Program/routes/Reports/components/TimePeriodsTable/TimePeriodsTable';
import TimePeriodsTable from 'routes/Program/routes/Reports/components/TimePeriodsTable';

import './MarketTransactions.scss';

export const MarketTransactions = ({
  downloading,
  downloadReport,
  programId,
}: CardProps) => {
  const [filteredResults, setFilteredResults] = useState<PeriodsTable[]>();

  const downloadMarketTransactionReport = (
    transactionStartDate: DateTime,
    transactionEndDate: DateTime
  ) => {
    const url = `/api/dsp/program/${programId}/market_transactions/report`;
    const params = {
      start_date: transactionStartDate.toFormat('yyyy-LL-dd'),
      end_date: transactionEndDate.toFormat('yyyy-LL-dd'),
    };
    downloadReport(url, params);
  };

  const downloadDaylyReport = async (date: DateTime) => {
    const url = `/api/dsp/program/${programId}/market_transactions/report`;
    const params = {
      start_date: date.toFormat('yyyy-LL-dd'),
      end_date: date.toFormat('yyyy-LL-dd'),
    };
    downloadReport(url, params);
  };

  return (
    <div className="market-transactions">
      <PageTitle
        title="Market transactions"
        classes={{ titleClass: 'market-transactions__title' }}
      />
      <TimePeriodsTable
        customColumnHeader="Transaction"
        data={filteredResults || []}
        setFilteredResults={setFilteredResults}
        downloadDaylyReport={downloadDaylyReport}
        downloadLogs={downloadMarketTransactionReport}
        downloading={downloading}
      />
    </div>
  );
};
