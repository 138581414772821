import React, { useState } from 'react';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { WeatherStation } from 'types/weather';
import { FeederEnrollment } from 'types/feeder';

import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import Select from 'components/OldSelect';

import { useFeederEnrollmentInfo } from '../../api';

import './FeederConfig.scss';

type FeederConfigProps = {
  feederID?: string;
  programID?: string;
};
interface SelectInterface {
  label: string;
  value: string;
}

type FeederKey = keyof FeederEnrollment;
type FeederEditState = { [key in FeederKey]?: any };

const FeederConfig = ({ feederID, programID }: FeederConfigProps) => {
  const [edited, setEdited] = useState<FeederEditState>({});

  const {
    enrollment: feeder,
    enrollmentLoading: loading,
    refetch,
  } = useFeederEnrollmentInfo(programID, feederID);

  const { selectedProgram: program } = useProgramsContext();
  const { data: zones } = useRequestEffect<SelectInterface[]>({
    url: `/api/dsp/program/isos/${program?.iso}/zones`,
    method: 'get',
    initialData: [],
    blockRequest: () => !program || !program.iso,
    refetchOnChange: [program?.iso],
    dataTransform: (data: Array<{ name: string }>) =>
      data.map(({ name }) => ({ label: name, value: name })),
    toast: {
      error: 'Could not load list of ISO zones.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { data: weatherStations } = useRequestEffect({
    url: '/api/dsp/weather/stations',
    method: 'get',
    initialData: [],
    dataTransform: (data: WeatherStation[]) =>
      data.map(({ id, name }) => ({ label: name, value: id })),
    toast: {
      error: 'Could not load list of weather stations',
    },
  });

  const { makeRequest: saveSettings, loading: saving } = useRequest(
    `/api/dsp/program/${programID}/feeder_enrollment/${feederID}`
  );

  const updateField = (key: FeederKey, value: any) => {
    setEdited({
      ...edited,
      [key]: value,
    });
  };

  const saveConfigSettings = async () =>
    saveSettings({
      method: 'patch',
      body: edited,
      toast: {
        success: 'Successfully updated feeder configuration.',
        error:
          'There was an unexpected error updating your feeder configuration',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        setEdited({});
        refetch();
      },
    });

  const getEditedProp = (key: FeederKey) =>
    Object.prototype.hasOwnProperty.call(edited, key)
      ? edited[key]
      : feeder
      ? feeder[key]
      : null;
  const voltage_lower_limit = getEditedProp('voltage_lower_limit');
  const voltage_upper_limit = getEditedProp('voltage_upper_limit');
  const weatherStationID = getEditedProp('weather_station');
  const zone = getEditedProp('zone');

  return (
    <div className="feeder-config-settings">
      <div className="feeder-config-settings-header">
        <h3 className="column-header">Feeder Configuration</h3>
        <Button
          disabled={!Object.entries(edited).length || saving}
          onClick={saveConfigSettings}
        >
          Save
        </Button>
      </div>
      <div className="section">
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            <Select
              isClearable={true}
              isMulti={false}
              isDisabled={saving}
              label="Weather Station"
              options={weatherStations}
              onChange={(opt) =>
                updateField('weather_station', opt ? opt.value : null)
              }
              value={weatherStations?.find(
                ({ value }) => value === weatherStationID
              )}
            />
            {!program?.constraint_energy_management && (
              <Select
                isClearable={false}
                isMulti={false}
                isDisabled={saving}
                label="Zone"
                options={zones}
                onChange={(opt) => updateField('zone', opt.value)}
                value={zones?.find(({ value }) => value === zone)}
              />
            )}
            <NumberInput
              id="voltage_upper_limit"
              label="OPF Voltage Upper Limit"
              onChange={(value) => updateField('voltage_upper_limit', value)}
              required
              unit="p.u."
              max={1.5}
              min={voltage_lower_limit}
              value={voltage_upper_limit}
            />
            <NumberInput
              id="voltage_lower_limit"
              label="OPF Voltage Lower Limit"
              onChange={(value) => updateField('voltage_lower_limit', value)}
              required
              unit="p.u."
              max={voltage_upper_limit}
              min={0.6}
              value={voltage_lower_limit}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FeederConfig;
