import Stepper from './Stepper';
import useStepper from './useStepper';

export type StepperStep = {
  label: string;
  active?: boolean;
  completed?: boolean;
  onStepClickOverride?: (step: number) => void;
  stylesOverrides?: {
    stepOverrideStyle?: React.CSSProperties;
    wrapperOverrideStyle?: React.CSSProperties;
    iconOverrideStyle?: React.CSSProperties;
    circleOverrideStyle?: React.CSSProperties;
    numberOverrideStyle?: React.CSSProperties;
    labelOverrideStyle?: React.CSSProperties;
    seperatorOverrideStyle?: React.CSSProperties;
  };
  classNameOverrides?: {
    stepOverrideClass?: string;
    wrapperOverrideClass?: string;
    iconOverrideClass?: string;
    circleOverrideClass?: string;
    numberOverrideClass?: string;
    labelOverrideClass?: string;
    seperatorOverrideClass?: string;
  };
};

export default Stepper;
export { useStepper };
