import { ServiceWindow } from 'types/contract-managment';

export function getRequestTotalDailyHours(
  serviceWindows: ServiceWindow[]
): number {
  if (!serviceWindows || serviceWindows.length < 1) {
    return 0;
  }
  const { startTime, endTime } = serviceWindows[0];
  return endTime.diff(startTime, ['hours']).hours;
}
