import React from 'react';
import classes from 'classnames';

import Button from 'components/Button';

import './DialogFooter.scss';

type DialogProps = {
  confirmAction?: {
    confirmLabel?: string;
    confirmClass?: string;
    confirmOnClick?: () => void;
  };
  closeAction?: {
    closeLabel?: string;
    closeClass?: string;
    closeOnClick?: () => void;
  };
  className?: string;
  styles?: React.CSSProperties;
  children?: React.ReactNode;
};

function DialogFooter({
  confirmAction = {},
  closeAction = {},
  className,
  styles,
  children,
}: DialogProps) {
  const { confirmLabel, confirmOnClick, confirmClass } = confirmAction;
  const { closeLabel, closeOnClick, closeClass } = closeAction;

  return (
    <div className={classes('dialog-footer', className)} style={styles}>
      {children}
      {closeOnClick && (
        <Button
          variant="outlined"
          onClick={closeOnClick}
          customClasses={{
            customButtonClass: classes(closeClass),
          }}
        >
          {closeLabel || 'Cancel'}
        </Button>
      )}
      {confirmOnClick && (
        <Button
          onClick={confirmOnClick}
          customClasses={{
            customButtonClass: classes(confirmClass),
          }}
        >
          {confirmLabel || 'Confirm'}
        </Button>
      )}
    </div>
  );
}

export default DialogFooter;
