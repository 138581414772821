import React from 'react';

import SVG from 'components/SVG';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';

interface NewNotificationButtonProps {
  showSave: boolean;
  onSave: () => void;
  onNewCreate: () => void;
  loading: boolean;
}

const NotificationButtons = ({
  showSave,
  onSave,
  onNewCreate,
  loading,
}: NewNotificationButtonProps) => {
  const onClickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'create' | 'save'
  ) => {
    e.stopPropagation();
    if (type === 'create') {
      onNewCreate();
    }
    if (type === 'save') {
      onSave();
    }
  };

  if (loading) {
    return (
      <LoadingSpinner
        customStyles={{
          root: 'email-notifications__loading',
          spinner: 'email-notifications__loading-spinner',
        }}
      />
    );
  }

  return (
    <>
      {showSave && (
        <Button
          variant="contained"
          onClick={(e) => onClickHandler(e, 'save')}
          margin="0 16px 0 auto"
          startIcon={<SVG fontSize="16px" icon="SaveIcon" />}
        >
          Save changes
        </Button>
      )}
      <Button
        variant="text"
        onClick={(e) => onClickHandler(e, 'create')}
        margin="0 16px 0 0"
        startIcon={<SVG fontSize="16px" icon="PlusCircleIcon" />}
      >
        Create new notification
      </Button>
    </>
  );
};

export default NotificationButtons;
