import { generationMethodRaw, siaListItem } from 'types/contract-managment';
import { Program } from 'contexts/ProgramsContext';
import { CreateRequestFormData } from '../CreateRequest.types';

export enum closeAfterEnum {
  TWENTY_FOUR_HOURS = '24 hours',
  FOURTY_EIGHT_HOURS = '48 hours',
  SEVENTY_TWO_HOURS = '72 hours',
  CUSTOM = 'Custom',
}

export enum recurrenceEnum {
  ONE_TIME = 'One time',
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi-weekly',
  MONTHLY = 'Monthly',
}

export type CreateRequestSetupSchema = {
  openDate: Date;
  openTime: Date;
  closeDate: Date;
  closeTime: Date;
  closeAfter: closeAfterEnum;
  recurrence: recurrenceEnum;
  quantityGeneration: generationMethodRaw;
};

export type CreateRequestSetupProps = {
  data: CreateRequestFormData;
  program: Program;
  onSubmit: (data: { [x: string]: any }) => void;
  isDso: boolean;
  onFieldChange: (name: keyof CreateRequestFormData, value: any) => void;
  siaList?: siaListItem;
};
