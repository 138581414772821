import React from 'react';
import MUITooltip from '@mui/material/Tooltip';
import classNames from 'classnames';

import { TooltipProps } from './Tooltip.types';
import './Tooltip.scss';

const Tooltip = ({
  children,
  content,
  placement = 'top',
  theme = 'dark',
  arrow = false,
  enabled = true,
  followCursor = false,
  customClasses,
  customStyles,
}: TooltipProps) => {
  const hasTooltip = !content || !enabled;

  if (hasTooltip) return <>{children}</>;

  return (
    <MUITooltip
      title={content}
      placement={placement}
      arrow={arrow}
      followCursor={followCursor}
      classes={{
        popper: classNames('tooltip', customClasses?.root),
        tooltip: classNames(
          'tooltip__content',
          `tooltip__content--${theme}`,
          customClasses?.tooltip
        ),
        arrow: classNames(`tooltip__arrow--${theme}`),
      }}
      style={customStyles}
    >
      <span>{children}</span>
    </MUITooltip>
  );
};

export default Tooltip;
