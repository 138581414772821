import React from 'react';
import { DateTime } from 'luxon';
import classes from 'classnames';

import { SelectDays } from 'types/contract-managment';

// eslint-disable-next-line custom-rules/deprecated-component
import OldCheckbox from 'components/OldCheckbox';

import './CustomPeakSelector.scss';

const dayMapper = {
  sunday: 'Sun',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
} as { [day: string]: string };

const defaultDays: SelectDays = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
};

interface Props {
  startDate?: DateTime;
  endDate?: DateTime;
  selectedDays?: SelectDays;
  onChange: (days: SelectDays) => void;
}

const CustomPeakSelector: React.FC<Props> = ({
  startDate,
  endDate,
  selectedDays,
  onChange,
}) => {
  const days = selectedDays || defaultDays;
  const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const id = e.currentTarget.id;
    onChange({ ...days, [id]: !days[id] });
  };

  function disableDay(dayIndex: number) {
    if (!startDate || !endDate) {
      return false;
    }
    if (
      endDate.startOf('day').diff(startDate.startOf('day'), 'days').days >= 7
    ) {
      // all days valid
      return false;
    }
    const startDay = startDate.weekdayLong.toLowerCase();
    const endDay = endDate.weekdayLong.toLowerCase();
    const dayKeys = Object.keys(days);
    const startIndex = dayKeys.indexOf(startDay);
    const endIndex = dayKeys.indexOf(endDay);
    /**
     * If the week days in the start and end date are sequential in the array:
     * Items selected and not between them (uninclusive) are invalid.
     */
    if (startIndex < endIndex) {
      if (dayIndex < startIndex || dayIndex > endIndex) {
        return true;
      }
      return false;
    }
    /**
     * If the week days of the start and end date are not sequential in the array:
     * Items between them (uninclusive) are invalid.
     */
    if (dayIndex < startIndex && dayIndex > endIndex) {
      return true;
    }
    if (startIndex === endIndex && dayIndex !== startIndex) {
      return true;
    }
    return false;
  }

  return (
    <div className="custom-peak-selector">
      {Object.keys(days).map((day, index) => {
        const disabled = disableDay(index);
        return (
          <div key={day}>
            <label
              className={classes('custom-peak-selector__label', {
                'custom-peak-selector__label--disabled': disabled,
              })}
            >
              <OldCheckbox
                id={day}
                checked={days[day]}
                onClick={handleClick}
                className={classes('custom-peak-selector__checkbox', {
                  'custom-peak-selector__checkbox--disabled': disabled,
                })}
                disabled={disabled}
              />
              {dayMapper[day]}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default CustomPeakSelector;
