import React from 'react';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';

import './Login.scss';

const Login = () => {
  const { isAuthenticated, isLoading, login } = useAuthContext();

  return (
    <div className="login-container">
      {!isLoading && !isAuthenticated && (
        <Card className="center-modal" hideTitle>
          <div className="logo-container">
            <img
              src="/gridos_logo_light.png"
              alt="gridos-logo"
              className="gridos-logo"
            />
          </div>
          <div className="contents-container">
            <Button
              customClasses={{
                customButtonClass: 'login-button',
              }}
              onClick={() => login()}
            >
              Login
            </Button>
          </div>
        </Card>
      )}
      {(isLoading || isAuthenticated) && (
        <div className="contents-container">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
export default Login;
