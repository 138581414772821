import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Feeder, FeederRaw } from 'types/feeder';

import {
  apm,
  Request,
  useAuthContext,
} from '@opusonesolutions/gridos-app-framework';
import { processRawFeeder } from 'helpers/dataProcessors';
import { setGlobalTimezone } from 'helpers/time';

export interface Program {
  constraint_energy_management: boolean;
  currency: string;
  financial_model: 'LMPD' | 'DLMP' | 'PAY_AS_BID' | 'PAY_AS_CLEAR';
  iso: string;
  iso_id: string;
  locale: string;
  name: string;
  program_id: string;
  sameday_event_duration: number;
  timezone: string;
  workspace_name: string;
  flex_auction_enabled: boolean;
  procurement_gate_time?: string;
  procurement_gate_weekday?: number;
}

interface ProgramsCtx {
  addProgram(program: Program): void;
  deleteProgram(id: string): void;
  getProgram(id: string | null | undefined): Program | null | undefined;
  getProgramName(id: string): string | null;
  updateProgram(id: string, program: Program): void;
  setCurrentProgram(id: string | null): void;
  setCurrentFeeder(id: string | null): void;
  getFeeder(id: string | null | undefined): Feeder | null | undefined;
  programs: Program[];
  selectedProgram: Program | null | undefined;
  feeders: Feeder[];
  selectedFeeder: Feeder | null | undefined;
  setRefetchFeeders(value: boolean): void;
}

interface ProgramsContextProviderProps {
  children: ReactNode;
}

interface feederAPI {
  feeders: FeederRaw[];
  substations: any[];
}

export const ProgramsContext = createContext<ProgramsCtx>({
  addProgram() {},
  deleteProgram() {},
  getProgram() {
    return null;
  },
  getProgramName() {
    return null;
  },
  updateProgram() {},
  setCurrentProgram() {},
  setCurrentFeeder() {},
  getFeeder() {
    return null;
  },
  programs: [],
  selectedProgram: null,
  feeders: [],
  selectedFeeder: null,
  setRefetchFeeders: () => false,
});

export const useProgramsContext = () => {
  const context = useContext(ProgramsContext);
  if (!context) {
    throw new Error(
      `useProgramsContext must be used within a ProgramsContextProvider`
    );
  }
  return context;
};

const programSorter = Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const processPrograms = (programs: Program[]): Program[] => {
  return programs.sort((a, b) => programSorter.compare(a.name, b.name));
};

const processFeeders = (feeders: feederAPI): Feeder[] => {
  const processedFeeders = feeders.feeders.map((feeder: Feeder) =>
    processRawFeeder(feeder)
  );
  return processedFeeders
    .filter((feeder) => feeder.enrolled)
    .sort((a, b) => programSorter.compare(a.name, b.name));
};

/**
 * Program context definition.
 * @param param0
 * @returns
 */
const ProgramsContextProvider = ({
  children,
}: ProgramsContextProviderProps) => {
  const { isAuthenticated } = useAuthContext();

  const [programs, setPrograms] = useState<Program[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<
    Program | null | undefined
  >(null);

  const [feeders, setFeeders] = useState<Feeder[]>([]);
  const [selectedFeeder, setSelectedFeeder] = useState<
    Feeder | null | undefined
  >(null);
  const [refetchFeeders, setRefetchFeeders] = useState(false);

  const onLogin = useRouteMatch('/login');

  useEffect(() => {
    if (onLogin !== null || !isAuthenticated) {
      return;
    }

    let didCancel = false;

    (async () => {
      const request = new Request(`/api/dsp/program`);
      try {
        const { data } = await request.get();

        if (didCancel) {
          return;
        }

        const programs = processPrograms(data);
        setPrograms(programs);
      } catch (error: any) {
        apm.captureError(error);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [onLogin, isAuthenticated]);

  // Fetches feeders when new program is selected
  useEffect(() => {
    if (!selectedProgram) {
      return;
    }

    let didCancel = false;

    (async () => {
      const request = new Request(
        `/api/dsp/program/${selectedProgram?.program_id}/feeder`
      );
      try {
        const { data } = await request.get();

        if (didCancel) {
          return;
        }

        const feeders = processFeeders(data);
        setFeeders(feeders);
        if (feeders.length > 0) {
          setSelectedFeeder(feeders[0]);
        }
        setRefetchFeeders(false);
      } catch (error: any) {
        apm.captureError(error);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [selectedProgram, refetchFeeders]);

  const addProgram = (program: Program) => {
    const newPrograms = [...programs, program];
    newPrograms.sort((a, b) => programSorter.compare(a.name, b.name));
    setPrograms(newPrograms);
  };

  const deleteProgram = (id: string) => {
    const index = programs.findIndex((p) => p.program_id === id);
    if (index !== -1) {
      const newPrograms = [...programs];
      newPrograms.splice(index, 1);
      setPrograms(newPrograms);
    }
  };

  const getProgram = (id: string | null | undefined) => {
    if (!id) {
      return null;
    }
    return programs.find((p) => p.program_id === id);
  };

  const getProgramName = (id: string) => {
    const program = programs.find((p) => p.program_id === id);
    if (!program) {
      return null;
    }
    return program.name;
  };

  const updateProgram = (id: string, program: Program) => {
    const index = programs.findIndex((p) => p.program_id === id);
    if (index !== -1) {
      const newPrograms = [...programs];
      newPrograms[index] = {
        ...newPrograms[index],
        ...program,
      };
      setPrograms(newPrograms);
    }
  };

  const setCurrentProgram = (id: string | null) => {
    const program = getProgram(id);
    setGlobalTimezone(program?.timezone);
    if (id !== selectedProgram?.program_id) {
      setSelectedProgram(program);
    }
  };

  const setCurrentFeeder = (id: string | null) => {
    setSelectedFeeder(getFeeder(id));
  };

  const getFeeder = (id: string | null | undefined) => {
    if (!id) {
      return null;
    }
    return feeders.find((feeder) => feeder.id === id);
  };

  return (
    <ProgramsContext.Provider
      value={{
        addProgram,
        deleteProgram,
        getProgram,
        getProgramName,
        updateProgram,
        setCurrentProgram,
        setRefetchFeeders,
        programs,
        selectedProgram,
        feeders,
        getFeeder,
        selectedFeeder,
        setCurrentFeeder,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
};

export const ProgramsProvider = ProgramsContextProvider;
