import React from 'react';

import PageTitle from 'components/PageTitle';

import './RequestsResponsesHeader.scss';

interface Props {
  title: string;
}

const RequestsResponsesHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="requests-responses-header__header">
      <PageTitle title={title}>{children}</PageTitle>
    </div>
  );
};

export default RequestsResponsesHeader;
