import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  DeepMap,
  FieldError,
  FieldValues,
  useForm,
  UseFormTrigger,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

type RequiredDataAnalysis = {
  service_type: string | undefined;
  analysis_day: number | undefined;
  analysis_time: string | undefined;
  include_pending_contracts: boolean;
  include_accepted_contracts: boolean;
};

// Thursday is the earliest day when a user would be able to set this weekly
// analysis time. Anytime from Thursday-Saturday can be selected
const weekDays = ['Thursday', 'Friday', 'Saturday'];

/*
class WeekDay(enum.Enum):
    MONDAY = 0
    TUESDAY = 1
    WEDNESDAY = 2
    THURSDAY = 3
    FRIDAY = 4
    SATURDAY = 5
    SUNDAY = 6
*/

// start 0: MONDAY
export const daysOptions = weekDays.map((d, i) => ({
  label: d,
  value: i + 3,
}));

export const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderColor: '#f9fbfb',
    height: '40px',
    paddingLeft: '4px',
    minHeight: 'unset',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '5px 8px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    marginBottom: '19px',
    marginTop: '19px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#54595E',
    fontSize: 12,
  }),
  option: (provided: any) => ({
    ...provided,
    color: '#54595E',
    fontSize: 12,
    display: 'flex',
  }),
};

const schema = yup.object().shape({
  selectDay: yup
    .number()
    .typeError('Day must be a number.')
    .required('Day is required'),
  analysisTime: yup.date().required('Time is required'),
});

const validateBody = (
  body: RequiredDataAnalysis,
  errors: DeepMap<FieldValues, FieldError>,
  trigger: UseFormTrigger<FieldValues>,
  id: string | undefined = undefined
) => {
  if (!body.analysis_day) {
    trigger('selectDay');
    return;
  }
  delete errors.selectDay;
  if (!id && body?.analysis_time?.substring(0, 8) === '00:00:00') {
    trigger('analysisTime');
    return;
  }
  if (!body.analysis_time) {
    trigger('analysisTime');
    return;
  }
  delete errors.analysisTime;
  return true;
};

export const useServiceTypeSelection = (
  programId: string,
  serviceType: string | undefined
) => {
  const [dataAnalysis, setDataAnalysis] = useState<RequiredDataAnalysis>();
  const [analysisDay, setAnalysisDay] = useState<number | undefined>();
  const [analysisTime, setAnalysisTime] = useState<DateTime>();
  const [analysisId, setAnalysisId] = useState<string | undefined>();
  const [message, setMessage] = useState<string>();

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { loading } = useRequestEffect({
    url: `/api/dsp/program/${programId}/recurring_analysis`,
    method: 'get',
    refetchOnChange: [programId],
    blockRequest: () => programId === undefined,
    onSuccess: (data: any) => {
      if (data) {
        let dataByType = data.find(
          (result: any) => result.service_type === serviceType
        );
        if (dataByType.analysis_time) {
          let [hour, minute, second] = dataByType.analysis_time.split(':');
          hour = parseInt(hour);
          minute = parseInt(minute);
          second = parseInt(second);
          const localTimeStr = DateTime.utc()
            .set({ hour, minute, second })
            .toLocal()
            .toFormat('HH:mm:ss');
          dataByType = { ...dataByType, analysis_time: localTimeStr };
        }
        setDataAnalysis(dataByType);
        setAnalysisId(dataByType.id);
      }
    },
    toast: {
      error: 'Could not load recurring analysis settings.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const requiredBody: RequiredDataAnalysis = {
    service_type: serviceType,
    analysis_day: analysisDay,
    analysis_time: analysisTime?.toISOTime(),
    include_pending_contracts: true,
    include_accepted_contracts: false,
  };

  const { makeRequest: analizeContracts, loading: configuring } = useRequest(
    `/api/dsp/program/${programId}/recurring_analysis`
  );

  const configSettings = async (field: Record<string, unknown>) => {
    const body: RequiredDataAnalysis = { ...requiredBody, ...field };
    if (!validateBody(body, errors, trigger)) {
      return;
    }
    await analizeContracts({
      method: 'post',
      body,
      blockRequest: () => !analysisDay && !analysisTime,
      onSuccess: (data: any) => {
        setMessage('Settings saved');
        setAnalysisId(data.id);
      },
      onError: () => setMessage('Error configuring settings'),
      toast: {
        error: (error: AxiosError<any>) => {
          if (error?.response?.data.message) {
            return `${error?.response?.data.message}`;
          }
          return 'Error configuring settings.';
        },
        success: 'Recurring analysis created',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { makeRequest: updateContracts, loading: updating } = useRequest(
    `/api/dsp/program/${programId}/recurring_analysis/${analysisId}`
  );

  const { makeRequest: updateGates, loading: updatingGates } = useRequest(
    `/api/dsp/program/${programId}`
  );

  const updateSettings = async (field: Record<string, unknown>) => {
    const body: RequiredDataAnalysis = { ...requiredBody, ...field };
    if (!validateBody(body, errors, trigger, analysisId)) {
      return;
    }

    await updateGates({
      method: 'patch',
      body: {
        procurement_gate_weekday: body.analysis_day,
        procurement_gate_time: body.analysis_time,
      },
      blockRequest: () => !analysisDay && !analysisTime,
      onError: () => setMessage('Error configuring settings'),
      toast: {
        error: (error) => {
          if (error?.response?.data.message) {
            return `${error?.response?.data.message}`;
          }
          return 'Error configuring settings.';
        },
        success: 'Procurement gates updated',
        settings: {
          autoDismiss: true,
        },
      },
    });
    await updateContracts({
      method: 'patch',
      body,
      blockRequest: () => !analysisDay && !analysisTime,
      onSuccess: () => setMessage('Settings updated'),
      onError: () => setMessage('Error configuring settings'),
      toast: {
        error: (error) => {
          if (error?.response?.data.message) {
            return `${error?.response?.data.message}`;
          }
          return 'Error configuring settings.';
        },
        success: 'Recurring analysis updated',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const setAnalizeData = (data: Record<string, unknown>) =>
    !analysisId ? configSettings(data) : updateSettings(data);

  return {
    dataAnalysis,
    analysisDay,
    analysisTime,
    message,
    setAnalysisDay,
    setAnalysisTime,
    register,
    errors,
    loading,
    configuring,
    updating: updating && updatingGates,
    setAnalizeData,
  };
};
