import React from 'react';
import cn from 'classnames';

import { useUserContext } from 'contexts/UserContext';

import { User } from 'types/user';

import Tooltip from 'components/Tooltip';
import IconButton from 'components/IconButton';

type RenderUserRowProps = {
  user: User;
  index: number;
  numUsers: number;
  removeUser: (userID: string) => void;
};

export function RenderUser({
  user,
  index,
  numUsers,
  removeUser,
}: RenderUserRowProps) {
  const { userIsDso } = useUserContext();
  const isDso = userIsDso();
  const { name, email, id } = user;

  return (
    <div
      className={cn('participant-users__table-row', {
        'participant-users__table-row--last': numUsers === index,
      })}
    >
      <div
        className={cn('participant-users__table-cell', {
          'participant-users__table-cell--even': index % 2 === 0,
        })}
      >
        <div className="participant-users__table-content">{index}</div>
      </div>
      <div
        className={cn('participant-users__table-cell', {
          'participant-users__table-cell--even': index % 2 === 0,
        })}
      >
        <div className="participant-users__table-content">
          <Tooltip theme="light" arrow content={name}>
            {name}
          </Tooltip>
        </div>
      </div>
      <div
        className={cn('participant-users__table-cell', {
          'participant-users__table-cell--even': index % 2 === 0,
        })}
      >
        <div className="participant-users__table-content">
          <Tooltip theme="light" arrow content={email}>
            {email}
          </Tooltip>
        </div>
      </div>
      <div
        className={cn('participant-users__table-cell', {
          'participant-users__table-cell--even': index % 2 === 0,
        })}
      >
        <div className="participant-users__table-content" />
      </div>
      <div
        className={cn('participant-users__table-cell', {
          'participant-users__table-cell--even': index % 2 === 0,
        })}
      >
        {isDso && (
          <div className="participant-users__table-content">
            <IconButton
              icon="Trash1Icon"
              fontSize="16px"
              customClasses={{
                customButtonClass: 'participant-users__trash',
              }}
              onClick={() => removeUser(id)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
