import React, { useMemo } from 'react';
import {
  Control,
  FieldValues,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import classNames from 'classnames';

import SVG from 'components/SVG';
import IconButton from 'components/IconButton';
import { FileSingle } from './FileSingle';
import { Fields } from '../BaselineImportForm.logic';
import { DerItem, FilesField } from '../BaselineImportForm.types';

function inputCanShow(fileWatcher: FilesField, id: string): boolean {
  return (
    !fileWatcher ||
    (fileWatcher && !fileWatcher[id]) ||
    (fileWatcher && fileWatcher[id] && fileWatcher[id].length < 1)
  );
}

type TenantDerProps = {
  der: DerItem;
  register: UseFormRegister<FieldValues>;
  removeFilesFromDer: (id: string) => void;
  control: Control<FieldValues, any>;
};

export const TenantDer = ({
  der,
  register,
  removeFilesFromDer,
  control,
}: TenantDerProps) => {
  const fileWatcher = useWatch({ name: Fields.Files, control });

  const showInput = useMemo(
    () => inputCanShow(fileWatcher, der.id),
    [fileWatcher, der.id]
  );

  return (
    <div className="baseline-import-form__row">
      <div className="baseline-import-form__half">
        <span className="baseline-import-form__form baseline-import-form__padding-right  baseline-import-form__text">
          {der.name}
        </span>
      </div>
      <div className="baseline-import-form__half">
        <label
          htmlFor={der.id}
          className={classNames({
            'baseline-import-form__form-label': showInput,
            'baseline-import-form__hide': !showInput,
          })}
        >
          {!der.status ? (
            <span className="baseline-import-form__form-nodata">
              No measurement data available
            </span>
          ) : showInput ? (
            <span className="baseline-import-form__form-nodata">
              No baseline data uploaded
            </span>
          ) : (
            ''
          )}
          <span
            className={classNames('baseline-import-form__form-icon-wrap', {
              'baseline-import-form__hide': !der.status,
            })}
          >
            <SVG
              icon="DownloadIcon"
              className="baseline-import-form__form-icon"
            />
          </span>
          <input
            className="baseline-import-form__form-input"
            id={der.id}
            {...register(`${Fields.Files}[${der.id}]`)}
            type="file"
            multiple
            accept=".csv"
            disabled={!der.status}
          />
        </label>
        <span className="baseline-import-form__file">
          {fileWatcher && fileWatcher[der.id]
            ? [...fileWatcher[der.id]].map((file: File) => (
                <FileSingle key={file.name} name={file.name} />
              ))
            : ''}
        </span>
        {!showInput && (
          <IconButton
            icon="DeleteIcon"
            customClasses={{
              customButtonClass: 'baseline-import-form__delete',
            }}
            fontSize="12px"
            onClick={() => removeFilesFromDer(der.id)}
          />
        )}
      </div>
    </div>
  );
};
