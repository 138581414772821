import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import {
  StyledEngineProvider,
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';

import { AuthContextProvider } from '@opusonesolutions/gridos-app-framework';
import { ProgramsProvider } from 'contexts/ProgramsContext';
import { UserProvider } from 'contexts/UserContext';

import App from './App';

import './index.scss';

let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '',
      main: '#0336A4',
      dark: '',
    },
    secondary: {
      main: '#E71864',
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiButton: {
      /*styleOverrides: {
        root: {
          padding: '8px 16px',
          fontSize: '14px',
          lineHeight: '16px',
          textTransform: 'none',
          fontWeight: '700',
        },
        text: {

        },
        outlined: {

        },
        containedPrimary: {
          '&:hover': {
            color: 'red',
            backgroundColor: 'white',
          },
        },
      },*/
    },
  },
});

ReactDOM.render(
  <Router>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ToastProvider autoDismiss autoDismissTimeout={6000}>
          <AuthContextProvider serviceName="te-ui">
            <UserProvider>
              <ProgramsProvider>
                <App />
              </ProgramsProvider>
            </UserProvider>
          </AuthContextProvider>
        </ToastProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Router>,
  document.getElementById('root')
);
