import React, { Component } from 'react';
import flatpickr from 'flatpickr';
import { Instance as FlatPickrInstance } from 'flatpickr/dist/types/instance';
import { Options as FlatPickrOptions } from 'flatpickr/dist/types/options';
import isEqual from 'lodash.isequal';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import IconButton from 'components/IconButton';

import {
  getProgramOffsetTimeMilliseconds,
  systemTimeToProgramTime,
} from 'helpers/time';

import './DatePickerCalendarInstance.scss';
import './DatePicker.scss';

type DatePickerProps = {
  date: DateTime | null;
  dateFormat?: string;
  defaultDate: DateTime | null | undefined;
  disabled?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  onClose: (date: DateTime) => void | null;
  options: FlatPickrOptions;
  onClear?: () => void;
  clearable?: boolean;
  showArrows?: boolean;
  useUTC?: boolean;
  placeholder?: string | undefined;
  isControlled?: boolean;
  customClasses?: {
    inputClass?: string;
    datePickerClass?: string;
  };
  error?: boolean;
  helperText?: string | undefined;
};

type DatePickerState = {
  date: DateTime;
};

/*
 * Creates a date picker instance that has forward and back buttons for the dates
 * and an input that triggers the calendar to open. The default is to allow selection
 * of both date and time
 */
class OldDatePicker extends Component<DatePickerProps, DatePickerState> {
  public static defaultProps = {
    date: null,
    defaultDate: undefined,
    disabled: false,
    options: {},
    clearable: false,
    showArrows: true,
    dateFormat: 'F j, Y',
    useUTC: true,
    placeholder: '',
    isControlled: true,
    customClasses: {
      inputClass: '',
      datePickerClass: '',
    },
  };

  calendar: FlatPickrInstance | null;
  dateInput: HTMLInputElement | null;

  showRegularRightArrow = true;
  showRegularLeftArrow = true;

  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      date: this.props.defaultDate || this.props.date || DateTime.local(),
    };

    this.calendar = null;
    this.dateInput = null;
    this.updateSelectionArrows(this.state.date);
  }

  componentDidMount() {
    this.createDateField(this.state.date, this.props.defaultDate);
  }

  componentDidUpdate(prevProps: DatePickerProps) {
    if (!this.props.isControlled) {
      return;
    }
    // If calendar is controlled, update the internal date state
    const { calendar, props } = this;
    const { date, options, maxDate, minDate } = props;
    if (
      date &&
      prevProps.date &&
      date.valueOf() !== prevProps.date.valueOf() &&
      calendar
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ date: date });
      calendar.setDate(date.toLocaleString());
      this.createDateField(date);
    }

    // this.createDateField may have recreated the calendar
    // Need to use this.calendar after this point
    if (!isEqual(prevProps.options, options) && this.calendar) {
      Object.keys(options).forEach((key) => {
        // @ts-ignore
        this.calendar.set(key, options[key]);
      });
      this.calendar.redraw();
    }

    if (date && this.calendar) {
      if (!isEqual(prevProps.options.maxDate, maxDate)) {
        this.calendar.set('maxDate', maxDate ? maxDate.toJSDate() : undefined);
      }
      if (!isEqual(prevProps.options.minDate, minDate)) {
        this.calendar.set('minDate', minDate ? minDate.toJSDate() : 'today');
      }
      this.calendar.redraw();
    }
  }

  componentWillUnmount() {
    if (this.calendar && this.calendar.destroy) {
      this.calendar.destroy();
    }
  }

  getMinOrMaxDate(date: DateTime, tzOffsetMinutes: number): number {
    if (this.props.useUTC) {
      return date.plus({ minutes: tzOffsetMinutes }).valueOf();
    }
    return date.valueOf();
  }

  getDefaultDateMilliseconds(date: DateTime): number {
    if (this.props.useUTC) {
      return date.valueOf();
    }
    return getProgramOffsetTimeMilliseconds(date);
  }

  onCloseDateMilliseconds(date: Date): DateTime {
    let time: number;
    if (this.props.useUTC) {
      time = date.getTime();
    } else {
      time = systemTimeToProgramTime(date);
    }
    return DateTime.fromMillis(time);
  }

  clockDefaultDate(): Date {
    const returnDate = new Date();
    returnDate.setHours(this.calendar?.hourElement?.valueAsNumber || 0);
    returnDate.setMinutes(0);
    returnDate.setSeconds(0);
    returnDate.setMilliseconds(0);
    return returnDate;
  }

  createDateField = (
    inputDate: DateTime,
    defaultDT?: DateTime | undefined | null
  ) => {
    let currentValue = inputDate;
    const now = inputDate.toJSDate();
    const timezoneOffsetInMinutes = now.getTimezoneOffset();

    const { calendar, dateInput, props } = this;
    let options = { ...props.options };
    const { maxDate, minDate } = props;
    const { dateFormat, useUTC } = props;

    if (useUTC) {
      currentValue = currentValue.plus({ minutes: timezoneOffsetInMinutes });
    }

    if (minDate) {
      options = {
        ...options,
        minDate: this.getMinOrMaxDate(minDate, timezoneOffsetInMinutes),
      };
    }
    if (maxDate) {
      options = {
        ...options,
        maxDate: this.getMinOrMaxDate(maxDate, timezoneOffsetInMinutes),
      };
    }

    const defaultDate =
      defaultDT !== null
        ? this.getDefaultDateMilliseconds(currentValue)
        : undefined;
    // @ts-ignore
    this.calendar = flatpickr(dateInput, {
      time_24hr: true,
      minuteIncrement: 1,
      dateFormat,
      onClose: (date: Date[]) => {
        // If it is a calendar, do nothing if user closed it without making selection
        // If it is a time selector only, create date at the beginning of current day
        const itIsACalendar = !this.calendar?.config.noCalendar;
        if (!date[0] && itIsACalendar) {
          return;
        }
        const newDate = this.onCloseDateMilliseconds(
          !date[0] && !itIsACalendar ? this.clockDefaultDate() : date[0]
        );
        this.handleDateChange(newDate);
      },
      defaultDate,
      ...options,
    });
    if (calendar && calendar.calendarContainer) {
      calendar.calendarContainer.classList.add('date-picker-calendar-instance');
    }
  };

  // Add one date to the current date
  addDate = () => {
    const newDate = this.state.date.plus({ days: 1 });
    const { maxDate } = this.props;

    // Ensure that we do not go past maxDate if set...
    if (
      maxDate !== undefined &&
      newDate.startOf('day') > maxDate.startOf('day')
    ) {
      return;
    }
    this.handleDateChange(newDate);
  };

  // Subtract one date from the current date
  subtractDate = () => {
    const newDate = this.state.date.plus({ days: -1 });
    const { minDate } = this.props;

    // Ensure that we do not go past minDate if set.
    if (
      minDate !== undefined &&
      newDate.startOf('day') < minDate.startOf('day')
    ) {
      return;
    }
    this.handleDateChange(newDate);
  };

  handleDateChange = (date: DateTime) => {
    this.updateSelectionArrows(date);
    this.setState({ date });
    this.props.onClose(date);
  };

  updateSelectionArrows = (date: DateTime) => {
    const { maxDate, minDate } = this.props;

    this.showRegularLeftArrow =
      minDate === undefined ||
      minDate.startOf('day').valueOf() !== date.startOf('day').valueOf();
    this.showRegularRightArrow =
      maxDate === undefined ||
      maxDate.startOf('day').valueOf() !== date.startOf('day').valueOf();
  };

  clearDate = () => {
    if (this.calendar) {
      this.calendar.clear();
    }
    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  render() {
    const {
      disabled,
      clearable,
      showArrows,
      placeholder,
      customClasses,
      helperText,
      error,
    } = this.props;

    return (
      <div className="date-picker__wrapper">
        <div
          className={classNames(
            'date-picker',
            customClasses ? `${customClasses.datePickerClass}` || '' : ''
          )}
        >
          {showArrows && (
            <button
              className={classNames('date-picker__btn', {
                'date-picker__btn-reached-limit': !this.showRegularLeftArrow,
              })}
              onClick={this.subtractDate}
              disabled={disabled}
              type="button"
            >
              <i className="material-icons" aria-hidden="true">
                keyboard_arrow_left
              </i>
            </button>
          )}
          <input
            className={classNames(
              'date-picker__input',
              customClasses ? `${customClasses.inputClass}` || '' : ''
            )}
            name="startDate"
            disabled={disabled}
            placeholder={placeholder}
            ref={(div) => {
              this.dateInput = div;
            }}
          />
          {clearable &&
            this.calendar?.selectedDates &&
            this.calendar.selectedDates.length > 0 && (
              <IconButton
                icon="XIcon"
                variant="nestedIconButton"
                onClick={this.clearDate}
                iconClass={'date-picker__clear-date-button-icon'}
                customClasses={{
                  customButtonClass: 'date-picker__clear-date-button',
                }}
              />
            )}
          {showArrows && (
            <button
              className={classNames('date-picker__btn', {
                'date-picker__btn-reached-limit': !this.showRegularRightArrow,
              })}
              onClick={this.addDate}
              disabled={disabled}
              type="button"
            >
              <i className="material-icons" aria-hidden="true">
                keyboard_arrow_right
              </i>
            </button>
          )}
        </div>
        {helperText && (
          <p
            className={classNames('date-picker__helper', 'date-picker__text', {
              'date-picker__text--error': error,
            })}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
}

export default OldDatePicker;
