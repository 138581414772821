import * as yup from 'yup';
import { DateTime } from 'luxon';

import { flexContract, SelectDays } from 'types/contract-managment';

import { isMidnight } from 'helpers/time';

export type UpdateContractProps = {
  open: boolean;
  onClose: () => void;
  programID: string;
  contract: flexContract;
  refreshTrigger: () => void;
  isDso: boolean;
};

export const schema = (
  startTime: DateTime,
  endTime: DateTime,
  quantity: number,
  isDso: boolean
) =>
  yup.object().shape({
    startDate: yup
      .date()
      .required('Start date is required.')
      .test(
        'in-range',
        'Select a start date within the contracts service period.',
        (rawStartDate): boolean => {
          if (!rawStartDate) {
            return false;
          }
          const startDate = DateTime.fromJSDate(rawStartDate).startOf('day');
          if (startDate < startTime.startOf('day')) {
            return false;
          }
          if (startDate > endTime.startOf('day')) {
            return false;
          }
          return true;
        }
      )
      .test(
        'before end',
        'Select a start date that occurs on or before the new end date.',
        (rawStartDate, ctx): boolean => {
          const rawEndDate = ctx.parent.endDate;
          if (!rawStartDate) {
            return false;
          }
          if (!rawEndDate) {
            return true;
          }
          const startDate = DateTime.fromJSDate(rawStartDate).startOf('day');
          const endDate = DateTime.fromJSDate(rawEndDate).startOf('day');
          if (startDate > endDate) {
            return false;
          }
          return true;
        }
      ),
    endDate: yup
      .date()
      .required('End date is required.')
      .test(
        'in-range',
        'Select an end date within the contracts service period.',
        (rawEndDate): boolean => {
          if (!rawEndDate) {
            return false;
          }
          const endDate = DateTime.fromJSDate(rawEndDate).startOf('day');
          if (endDate < startTime.startOf('day')) {
            return false;
          }
          if (
            endDate >
            endTime.startOf('day').minus({
              days: isMidnight(endTime) ? 1 : 0,
            })
          ) {
            return false;
          }
          return true;
        }
      )
      .test(
        'before start',
        'Select an end date that occurs on or after the new start date.',
        (rawEndDate, ctx): boolean => {
          const rawStartDate = ctx.parent.startDate;
          if (!rawEndDate) {
            return false;
          }
          if (!rawStartDate) {
            return true;
          }
          const startDate = DateTime.fromJSDate(rawStartDate).startOf('day');
          const endDate = DateTime.fromJSDate(rawEndDate).startOf('day');
          if (startDate > endDate) {
            return false;
          }
          return true;
        }
      ),
    quantity: yup
      .number()
      .typeError('Quantity must be a number.')
      .max(quantity > 0 ? quantity / 1000 : 0)
      .min(quantity > 0 ? 0 : quantity / 1000)
      .required('Enter the number of kilowatts required.')
      .test(
        'check-quantity',
        'Quantity value should be “0“ or original',
        (val): boolean => {
          return isDso || val === quantity / 1000 || val === 0;
        }
      ),
  });

// keys out of order when pulled from backend.
export const getWeekDays = (
  customPeakDays: SelectDays,
  serviceStart: DateTime,
  serviceEnd: DateTime
) => {
  let weekDays = '';
  if (customPeakDays) {
    if (customPeakDays['monday']) weekDays += `Mon, `;
    if (customPeakDays['tuesday']) weekDays += `Tue, `;
    if (customPeakDays['wednesday']) weekDays += `Wed, `;
    if (customPeakDays['thursday']) weekDays += `Thu, `;
    if (customPeakDays['friday']) weekDays += `Fri, `;
    if (customPeakDays['saturday']) weekDays += `Sat, `;
    if (customPeakDays['sunday']) weekDays += `Sun, `;
  }
  weekDays = weekDays.slice(0, weekDays.length - 2);
  const end = serviceEnd?.toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  });
  return `${weekDays} (${serviceStart?.toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  })} - ${end === '00:00' ? '24:00' : end})`;
};
