import { DateTime } from 'luxon';

import { Actions } from '../Calendar.logic';

export const setInit = (
  date: string | DateTime | null,
  disablePast?: boolean,
  maxDate?: DateTime | undefined | null,
  minDate?: DateTime | undefined | null,
  disabledDays?: Array<DateTime | undefined | null>,
  preselectedMonth?: string | DateTime | null
) => {
  const selectedDate = (() => {
    if (date) {
      return date;
    }
    if (minDate) {
      return minDate;
    }
    return DateTime.local();
  })();
  const selectedMonth = preselectedMonth ? preselectedMonth : selectedDate;

  const selectedYear = selectedDate;

  return {
    type: Actions.SET_INIT,
    payload: {
      selectedDate,
      selectedMonth,
      selectedYear,
      disablePast,
      maxDate,
      minDate,
      disabledDays,
    },
  };
};

export const setMonth = (month: DateTime) => ({
  type: Actions.SET_MONTH,
  payload: month,
});

export const setDate = (date: DateTime) => {
  return {
    type: Actions.SET_DATE,
    payload: date,
  };
};
