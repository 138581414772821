import React from 'react';
import classNames from 'classnames';

import './Light.scss';

interface LightParams {
  variant: 'off' | 'success' | 'error' | 'warning' | 'custom' | 'cancelled';
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
  className?: string;
}

const Light = ({ variant, size, style, className = '' }: LightParams) => (
  <div
    className={classNames(
      'light',
      { 'light--success': variant === 'success' },
      { 'light--warning': variant === 'warning' },
      { 'light--error': variant === 'error' },
      { 'light--cancelled': variant === 'cancelled' },
      { 'light--xsmall': size === 'xsmall' },
      { 'light--small': size === 'small' },
      { 'light--medium': size === 'medium' },
      { 'light--large': size === 'large' },
      `${className}`
    )}
    style={style}
  />
);

export default Light;
