import { useEffect, useState } from 'react';

import { DER } from 'hooks/useDERs';
import { Container } from 'hooks/useContainers';

type EnrollmentTableRow = {
  containerID: string;
  isContainer: boolean;
  enrolled: boolean;
  id: string;
  name: string;
  type: string;
  primary?: boolean;
};

export const useTableRows = (containers: Container[]) => {
  // To avoid a lot of sub-table problems (and making filtering complex)
  // we'll keep track of all the visible rows in the table as needed
  const [rows, setRows] = useState<EnrollmentTableRow[]>([]);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    // If the containers change, recreate our table rows
    const newRows: EnrollmentTableRow[] = [];

    containers.forEach((c) => {
      newRows.push({
        containerID: c.id,
        enrolled: c.enrolled,
        id: c.id,
        isContainer: true,
        name: c.name,
        type: c.type.charAt(0).toUpperCase() + c.type.substr(1).toLowerCase(),
      });

      // Gather associated primaries
      const primaries = !c.primaries?.length ? undefined : c.primaries;

      if (c.enrolled && expandedRows[c.id] && c.children) {
        // This is an expanded row. Include the DERs
        if (primaries) {
          primaries.forEach((primary) => {
            newRows.push({
              containerID: primary.id,
              enrolled: true,
              id: primary.id,
              isContainer: true,
              name: primary.name,
              type: 'Primary',
              primary: true,
            });
            if (expandedRows[primary.id]) {
              c.children
                ?.filter((der: DER) => der.primary_id === primary.id)
                .forEach((der) => {
                  newRows.push({
                    containerID: c.id,
                    enrolled: der.enrolled,
                    id: der.der_rdf_id,
                    isContainer: false,
                    name: `${der.name}`,
                    type: der.der_type,
                    primary: true,
                  });
                });
            }
          });
        }
        c.children
          .filter((der: DER) => !der.primary_id)
          .forEach((der) => {
            newRows.push({
              containerID: c.id,
              enrolled: der.enrolled,
              id: der.der_rdf_id,
              isContainer: false,
              name: der.name,
              type: der.der_type,
            });
          });
      }
    });

    setRows(newRows);
  }, [containers, expandedRows]);

  const expandContainer = (id: string) => {
    setExpandedRows({
      ...expandedRows,
      [id]: !expandedRows[id],
    });
  };

  return {
    rows,
    expandedRows,
    expandContainer,
  };
};
