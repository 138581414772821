import React, { useState } from 'react';
import check from 'check-types';
import { DateTime } from 'luxon';

import Card from 'components/Card';
import Button from 'components/Button';
import Select from 'components/OldSelect';
import NumberInput from 'components/NumberInput';

import { CardProps } from 'routes/Program/routes/Reports/Reports';

const CALENDAR_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MONTH_OPTIONS = CALENDAR_MONTHS.map((m, i) => ({
  label: m,
  value: i + 1,
}));

export const Settlements = ({
  downloading,
  downloadReport,
  programId,
}: CardProps) => {
  const now = DateTime.local();
  const [settlementMonth, setSettlementMonth] = useState(now.month - 1); // Luxon indexes months from 1, not 0
  const [settlementYear, setSettlementYear] = useState<number | undefined>(
    now.year
  );

  const downloadSettlementReport = () => {
    if (check.number(settlementYear)) {
      const url =
        `/api/dsp/program/${programId}/settlement/report` +
        `?year=${settlementYear}&month=${settlementMonth + 1}`;
      downloadReport(url);
    }
  };

  return (
    <Card
      className="settlement-card"
      renderFooter={() => (
        <Button
          disabled={downloading || check.undefined(settlementYear)}
          onClick={downloadSettlementReport}
        >
          Download
        </Button>
      )}
      showFooter
      title="Settlement Report"
    >
      <div className="card-content">
        <Select
          isClearable={false}
          isMulti={false}
          label="Month"
          options={MONTH_OPTIONS}
          onChange={(m) => setSettlementMonth(CALENDAR_MONTHS.indexOf(m.label))}
          row
          value={MONTH_OPTIONS[settlementMonth]}
        />
        <NumberInput
          className="year-input"
          id="settlement-number-input"
          label="Year"
          min={now.year - 50}
          max={now.year}
          onChange={(y) => setSettlementYear(y)}
          required
          value={settlementYear}
        />
      </div>
    </Card>
  );
};
