import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { TimePickerProps } from './TimePicker.types';
import { createTimeIntervals, getConvertedTime } from './TimePicker.logic';
import TimePickerInput from './components/TimePickerInput';
import { TimePickerPopover } from './components/TimePickerPopover';
import './TimePicker.scss';

export const defaultInputState = '--:--';

const TimePicker = ({
  value,
  onChange,
  disabled = false,
  helperText,
  error = false,
  label,
  onClose,
  disableTyping = false,
  disableMenu = false,
  maxTime,
  minTime,
  increments = 30,
  isClearable = false,
  styles,
  classNames,
}: TimePickerProps) => {
  const [anchor, setAnchor] = useState<(EventTarget & HTMLDivElement) | null>(
    null
  );
  const [inputState, setInputState] = useState(defaultInputState);
  const { isFullTimeEntered } = getConvertedTime(inputState);

  const setPreviousValueHandler = () => {
    if (!isFullTimeEntered && value) {
      setInputState(value.toFormat('HH:mm'));
    }
  };

  useEffect(() => {
    if (value) {
      setInputState(value.toFormat('HH:mm'));
    }
  }, [value]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      setAnchor(e.currentTarget);
    }
  };

  const handleChange = (e: DateTime) => {
    onChange(e);
  };

  const handleClose = (e: DateTime | undefined) => {
    setPreviousValueHandler();
    setAnchor(null);
    if (onClose && e) {
      onClose(e);
    }
  };

  const handleClear = () => {
    onChange(undefined);
    setInputState(defaultInputState);
  };

  const timeIntervals = useMemo(
    () => createTimeIntervals(increments, inputState, maxTime, minTime, value),
    [value, increments, maxTime, minTime, inputState]
  );

  const timeIntervalsRefs = useMemo(
    () =>
      timeIntervals.reduce((acc, value) => {
        acc[value.id] = React.createRef<HTMLDivElement>();
        return acc;
      }, {} as any),
    [timeIntervals]
  );

  useEffect(() => {
    if (anchor && isFullTimeEntered && value) {
      const currentTimeId = value.toISO();
      setTimeout(() => {
        timeIntervalsRefs[currentTimeId]?.current?.scrollIntoView({
          block: 'start',
        });
      }, 0);
    }
  }, [isFullTimeEntered, value, anchor, timeIntervalsRefs]);

  return (
    <div className="time-picker">
      <TimePickerInput
        inputState={inputState}
        setInputState={setInputState}
        onClick={handleClick}
        onClear={handleClear}
        value={value}
        onChange={onChange}
        label={label}
        disabled={disabled}
        helperText={helperText}
        error={error}
        disableTyping={disableTyping}
        disableMenu={disableMenu}
        increments={increments}
        isClearable={isClearable}
        styles={styles}
        classNames={classNames}
      />
      <TimePickerPopover
        anchor={anchor}
        value={value}
        onClose={handleClose}
        onChange={handleChange}
        timeIntervals={timeIntervals}
        timeIntervalsRefs={timeIntervalsRefs}
      />
    </div>
  );
};

export default TimePicker;
