import { serviceTypeRaw } from './contract-managment';

export type ResponseAsset = {
  label: string;
  value: string;
  curtailable: boolean;
  feederId: string;
};

export enum AssetControlStrategy {
  Scheduled = 'SCHEDULED',
  Curtailable = 'CURTAILABLE',
}

// dsp_datastore/services/standing_bid_offer.py Line number #32
export enum DERType {
  BESS = 'BESS',
  GenSet = 'GenSet',
  Hydro = 'Hydro',
  PhotoVoltaic = 'PhotoVoltaic',
  Wind = 'Wind',
  Shift = 'Load (Shifting)',
  Curtailing = 'Load (Curtailing)',
  undefined = '',
}

export function getDERTypeFromString(x: string): DERType | undefined {
  if (x === DERType.BESS) return DERType.BESS;
  if (x === DERType.Curtailing) return DERType.Curtailing;
  if (x === DERType.GenSet) return DERType.GenSet;
  if (x === DERType.Hydro) return DERType.Hydro;
  if (x === DERType.PhotoVoltaic) return DERType.PhotoVoltaic;
  if (x === DERType.Shift) return DERType.Shift;
  if (x === DERType.Wind) return DERType.Wind;
  return DERType.undefined;
}

export const DERIcons = {
  [DERType.BESS]: 'DERBESSIcon',
  [DERType.GenSet]: 'DERSynchIcon',
  [DERType.Hydro]: 'DERHydroIcon',
  [DERType.PhotoVoltaic]: 'DERSolarIcon',
  [DERType.Wind]: 'DERWindIcon',
  [DERType.Shift]: 'DERShiftingIcon',
  [DERType.Curtailing]: 'DERCurtailingIcon',
  [DERType.undefined]: '',
};

export type DER = {
  activated: boolean;
  enabled: boolean;
  label: string;
  id: string;
  info: {
    apparent_power: number;
    class: string;
    der_phases: string;
    der_rdf_id: string;
    der_type: DERType;
    feeder: {
      id: string;
      name: string;
    };
    gen_capacity: number;
    location: [string, string];
    name: string;
    phases: number;
    power_factor: number;
    rated_voltage: number;
    reactive_power: number;
    real_power: number;
    upper_power_limit: number;
    lower_power_limit: number;
  };
  control_strategy: AssetControlStrategy;
  operating_mode: 'UNCONSTRAINED' | 'BID_OFFER';
  rdf_id: string;
  permissions: Array<string>;
  connection_agreement_export_capacity: number | null;
  connection_agreement_import_capacity: number | null;
  tcvn_export_capacity: number | null;
  tcvn_import_capacity: number | null;
  enabled_services: Array<serviceTypeRaw>;
  remaining_capacity: number | null;
};
