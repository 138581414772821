import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import {
  useMeasurementsContext,
  TimeseriesTag,
} from 'contexts/MeasurementContext';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import FileForm from 'components/FileForm';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import SearchInput from 'components/SearchInput';
import TextInput from 'components/TextInput';

import fileExportSave from 'helpers/downloadFile';

import './SingleTimeseries.scss';
import 'components/Button/Button.scss';

const toHumanReadableMeasurementType = (type: string) => {
  return type
    .split('_')
    .map((w) => `${w.charAt(0).toUpperCase()}${w.substr(1).toLowerCase()}`)
    .join(' ');
};

const SingleTimeseries = () => {
  const { timeseriesID } = useParams<{ timeseriesID: string }>();
  const { getTimeseriesById, updateTimeseries, deleteTimeseriesTag } =
    useMeasurementsContext();
  const timeseries = timeseriesID ? getTimeseriesById(timeseriesID) : undefined;

  const [deleting, setDeleting] = useState(false);
  const [edited, setEdited] = useState(false);
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);

  // This state is used for the upload modal
  const [fileData, setFileData] = useState(new FormData());
  const [filename, setFilename] = useState('');
  const [hasUploadData, setHasUploadData] = useState(false);
  const [inUploadMode, setInUploadMode] = useState(false);
  const [uploadDataType, setUploadDataType] = useState<'measurements' | 'tags'>(
    'measurements'
  );
  const uploadFormRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (timeseries) {
      setName(timeseries.name);
    }
  }, [timeseries]);

  const {
    data: tags,
    loading,
    refetch: reloadTags,
  } = useRequestEffect<TimeseriesTag[]>({
    url: `/api/dsp/measurements/timeseries/${timeseriesID}/tags`,
    method: 'get',
    toast: {
      error: 'Failed to load timeseries tags',
    },
  });

  const cancelUpload = () => {
    setInUploadMode(false);
    setFileData(new FormData());
    setFilename('');
    setHasUploadData(false);

    if (uploadFormRef.current) {
      uploadFormRef.current.reset();
    }
  };

  const { loading: uploadingNewTags, makeRequest: runTagUpload } = useRequest(
    `/api/dsp/measurements/timeseries/${timeseriesID}/tags`
  );

  const uploadTagData = async () => {
    await runTagUpload({
      method: 'post',
      body: fileData,
      onSuccess: () => {
        reloadTags();
        cancelUpload(); // exits upload mode
      },
      toast: {
        error: (error) => {
          let message = 'Could not upload tag data.';
          const response = error.response;

          if (response && response.status === 400) {
            // Bad data provided by the user. Let's try and show the user a good message
            if (response.data && response.data.message) {
              // Error could be on any row. Pick the first we find
              const rowKeys = [...Object.keys(response.data.message)];

              if (rowKeys.length > 0) {
                const row = rowKeys[0];
                const rowData = response.data.message[row];
                const fieldKeys = [...Object.keys(rowData)];

                if (fieldKeys.length > 0) {
                  // Pick the first message for the first field
                  const field = fieldKeys[0];
                  message = `CSV validation failed at row ${row}. Error for field '${field}': '${rowData[field][0]}'`;
                }
              }
            }
          }

          return message;
        },
        success: 'Successfully uploaded tag data.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const {
    loading: uploadingMeasurementData,
    makeRequest: runMeasurementUpload,
  } = useRequest(
    `/api/dsp/measurements/timeseries/${timeseriesID}/measurement`
  );

  const uploadMeasurementData = async () => {
    await runMeasurementUpload({
      method: 'post',
      body: fileData,
      onSuccess: () => {
        cancelUpload(); // exits upload mode
      },
      toast: {
        error: (error) => {
          let message = 'Could not upload measurement data.';
          const response = error.response;

          if (response && response.status === 400) {
            // Bad data provided by the user. Let's try and show the user a good message
            if (response.data && response.data.message) {
              // Error could be on any row. Pick the first we find
              const rowKeys = [...Object.keys(response.data.message)];

              if (rowKeys.length > 0) {
                const row = rowKeys[0];
                const rowData = response.data.message[row];
                const fieldKeys = [...Object.keys(rowData)];

                if (fieldKeys.length > 0) {
                  // Pick the first message for the first field
                  const field = fieldKeys[0];
                  message = `CSV validation failed at row ${row}. Error for field '${field}': '${rowData[field][0]}'`;
                }
              }
            }
          }

          return message;
        },
        success: 'Successfully uploaded measurement data.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { loading: downloadingTags, makeRequest: runTagDownload } =
    useRequest<Blob>(
      `/api/dsp/measurements/timeseries/${timeseriesID}/tags/export`
    );

  const downloadTags = async () => {
    await runTagDownload({
      method: 'get',
      onSuccess: (data, headers) => {
        if (data) {
          fileExportSave(data, headers);
        }
      },
      toast: {
        error: 'Could not export tag data',
        settings: {
          autoDismiss: true,
        },
      },

      // Axios options
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const handleDelete = async (tagID: string, tagName: string) => {
    if (timeseriesID) {
      setDeleting(true);
      await deleteTimeseriesTag(timeseriesID, tagID, tagName);
      setDeleting(false);
      reloadTags();
    }
  };

  return (
    <HeaderLayout
      className="single-timeseries"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
            {
              to: '/measurements/timeseries',
              label: <h2 className="title">Timeseries</h2>,
            },
          ]}
          separator="/"
          currentHeader={`${timeseries?.name || ''} Metadata`}
        />
      }
      titleRightContent={
        <>
          <Button
            customClasses={{
              customButtonClass: 'upload-button',
            }}
            disabled={uploadingNewTags}
            onClick={() => {
              setUploadDataType('tags');
              setInUploadMode(true);
            }}
          >
            Upload Tags
          </Button>
          <Button
            customClasses={{
              customButtonClass: 'upload-button',
            }}
            disabled={uploadingMeasurementData}
            onClick={() => {
              setUploadDataType('measurements');
              setInUploadMode(true);
            }}
          >
            Upload Measurement Data
          </Button>
          <Button
            customClasses={{
              customButtonClass: 'upload-button',
            }}
            disabled={downloadingTags}
            loading={downloadingTags}
            onClick={() => downloadTags()}
          >
            Export Tags
          </Button>
          <Button
            disabled={!edited || saving}
            onClick={async () => {
              if (timeseriesID) {
                setSaving(true);
                await updateTimeseries(timeseriesID, {
                  id: timeseriesID,
                  name,
                });
                setEdited(false);
                setSaving(false);
              }
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <div className="input-container">
        <TextInput
          disabled={saving}
          id="name"
          label="Name"
          onChange={(value) => {
            setEdited(true);
            setName(value);
          }}
          placeholder="Name"
          required
          value={name}
        />
      </div>
      <h3 style={{ paddingLeft: 10 }}>Tags</h3>
      <ReactTable
        data={tags}
        loading={loading}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (props) => (
              <Link
                to={`/measurements/timeseries/${timeseriesID}/tag/${props.original.id}`}
              >
                {props.value}
              </Link>
            ),
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Tag Name"
              />
            ),
            sortable: true,
          },
          {
            Header: 'Measurement Type',
            accessor: (t) => toHumanReadableMeasurementType(t.measurement_type),
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Measurement Type"
              />
            ),
            id: 'mesurement_type',
            sortable: true,
          },
          {
            Header: 'Unit Symbol',
            accessor: 'unit_symbol',
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Unit Symbol"
              />
            ),
            sortable: true,
          },
          {
            Header: 'Unit Multiplier',
            accessor: 'unit_multiplier',
            sortable: true,
          },
          {
            Header: 'Phases',
            accessor: 'phases',
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Phases"
              />
            ),
            sortable: true,
          },
          {
            id: 'delete',
            Cell: (props) => (
              <span className="center-container">
                <IconButton
                  disabled={deleting}
                  icon="delete"
                  onClick={() =>
                    handleDelete(props.original.id, props.original.name)
                  }
                  tooltip="Delete Tag"
                />
              </span>
            ),
            width: 60,
            resizable: false,
          },
        ]}
        className="-striped -highlight"
        showPaginationBottom
      />
      <Modal
        title={
          uploadDataType === 'tags'
            ? 'Upload Timeseries Tags'
            : 'Upload Measurement Data'
        }
        width="300px"
        active={inUploadMode}
        hideClose
        cancelProps={{ disabled: uploadingNewTags }}
        confirmProps={{
          disabled: !hasUploadData || uploadingNewTags,
          label: uploadingNewTags ? (
            <i className="material-icons rotating-icon">refresh</i>
          ) : (
            'Save'
          ),
          onClick: () => {
            if (uploadDataType === 'tags') {
              uploadTagData();
            } else {
              uploadMeasurementData();
            }
          },
        }}
        height="250px"
        onClose={cancelUpload}
      >
        <div className="upload-form">
          <FileForm
            accept="*.csv"
            id="upload"
            onChange={(e) => {
              //@ts-ignore
              const { files } = e.target;

              // If there not exactly one files attached, do nothing
              if (files.length !== 1) return;

              // Only 1 file should exist / only the first file matters
              const file = files[0];

              // Assume .csv file is valid, allow API to reject
              const csvKey = 'weather.csv';
              fileData.set(csvKey, file);
              setFilename(file.name);
              setHasUploadData(true);
            }}
            createRef={(form) => (uploadFormRef.current = form)}
          >
            <Button
              customClasses={{
                customButtonClass: 'button--non-interactive',
              }}
            >
              Select file
            </Button>
          </FileForm>
          <div className="filename">{filename}</div>
        </div>
      </Modal>
    </HeaderLayout>
  );
};

export default SingleTimeseries;
