import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { DatePickerPopover } from './components/DatePickerPopover';
import KeyboardDateInput from './components/KeyboardDateInput';
import { DatePickerProps, InputState } from './DatePicker.types';

export const defaultInputState = '--/--/----';

const DatePicker = ({
  value,
  onChange,
  onClose,
  maxDate,
  minDate,
  disabled = false,
  disabledDays,
  disabledMonthPicker = false,
  selectedMonth,
  increments = 1,
  showArrows = true,
  dateFormat = 'MM/dd/yyyy',
  placeholder = 'Select date',
  disableTyping = false,
  id,
  label,
  helperText,
  styles,
  isClearable = false,
  classNames,
  error,
}: DatePickerProps) => {
  const [state, setState] = useState<InputState>({
    value: value,
    anchor: null,
  });
  const [inputState, setInputState] = useState<string>(defaultInputState);
  const inputRef = useRef(null);

  useEffect(() => {
    if (value) {
      setInputState(value.toFormat(dateFormat));
    }
  }, [value, dateFormat]);

  const handleClear = () => {
    onChange(undefined);
    setInputState(defaultInputState);
  };

  const handleClick = () => {
    if (!disabled) {
      setState((prev) => ({ ...prev, anchor: inputRef.current }));
    }
  };

  const handleChange = (e: DateTime) => {
    onChange(e);
  };

  const handleClose = (e: DateTime | null) => {
    setState((prev) => ({ ...prev, anchor: null }));
    if (e && onClose) {
      onClose(e);
    }
  };

  return (
    <>
      <KeyboardDateInput
        ref={inputRef}
        inputState={inputState}
        setInputState={setInputState}
        disabled={disabled}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        placeholder={placeholder}
        increments={increments}
        showArrows={showArrows}
        dateFormat={dateFormat}
        id={id}
        label={label}
        helperText={helperText}
        styles={styles}
        classNames={classNames}
        error={error}
        isOpen={state.anchor !== null}
        isClearable={isClearable}
        onClear={handleClear}
        disableTyping={disableTyping}
      />

      <DatePickerPopover
        anchor={state.anchor}
        value={value}
        onChange={handleChange}
        onClose={handleClose}
        maxDate={maxDate}
        minDate={minDate}
        disabledDays={disabledDays}
        disabledMonthPicker={disabledMonthPicker}
        selectedMonth={selectedMonth}
      />
    </>
  );
};

export default DatePicker;
