import React from 'react';

import RadioButtonGroup from 'components/RadioButtonGroup';
import NumberInput from 'components/NumberInput';
import Select from 'components/OldSelect';

import {
  MarketKey,
  marketObjective,
  SettingsCommonProps,
} from 'routes/Program/routes/Settings/Settings';

type FinnancialModelProps = {
  updateMarketObjectiveSettings: (value: marketObjective | undefined) => void;
  selectedMarketObjective: marketObjective | undefined;
  updateMarketInfoProp: (key: MarketKey, value: any) => void;
} & SettingsCommonProps;

const marketObjectiveOpts = [
  { label: 'Minimize Losses', value: 'LOSS' },
  { label: 'Minimize Costs', value: 'COST' },
  { label: 'Minimize PV Curtailments', value: 'PV' },
  { label: 'Minimize PV Curtailments via BESS', value: 'PV_BESS' },
];

const batteryControlOpts = [
  { label: 'Any', id: 'ANY' },
  { label: 'GridOS', id: 'GRIDOS' },
  { label: 'Participant', id: 'PARTICIPANT' },
];

const financialModelOpts = [
  { label: 'DLMP', id: 'DLMP' },
  { label: 'LMP+D', id: 'LMPD' },
  { label: 'Pay as bid', id: 'PAY_AS_BID' },
  { label: 'Pay as clear', id: 'PAY_AS_CLEAR' },
];

const FinancialModel = ({
  program,
  saving,
  updateProp,
  updateMarketObjectiveSettings,
  selectedMarketObjective,
  updateMarketInfoProp,
}: FinnancialModelProps) => {
  return (
    <>
      <div className="program-settings_column">
        <div className="program-settings_column-row">
          <Select
            isClearable
            isMulti={false}
            isDisabled={saving}
            label="Default Market Objectives"
            options={marketObjectiveOpts}
            onChange={(opt) => {
              if (opt) {
                updateMarketObjectiveSettings(
                  marketObjective[opt.value as keyof typeof marketObjective]
                );
              } else {
                updateMarketObjectiveSettings(undefined);
              }
            }}
            value={marketObjectiveOpts.find(
              ({ value }) => value === selectedMarketObjective
            )}
          />
        </div>
        <div className="program-settings_column-objective-settings">
          <div className="program-settings_column-row">
            <RadioButtonGroup
              id="bcontrol"
              listType="column"
              label="Battery Control"
              onChange={(value) => updateProp('battery_control', value)}
              options={batteryControlOpts}
              value={program.battery_control}
            />
          </div>
          <div className="program-settings_column-row">
            <RadioButtonGroup
              id="fmodel"
              listType="column"
              label="Financial Model"
              onChange={(value) => updateProp('financial_model', value)}
              options={financialModelOpts}
              value={program.financial_model}
            />
          </div>

          <div className="program-settings_column-row">
            <RadioButtonGroup
              id="market-control-strategy"
              listType="column"
              label="Market Control Strategy"
              onChange={(value) => updateProp('market_control_strategy', value)}
              options={[
                {
                  id: 'LOSS_OPTIMIZE_ENROLLED_ASSETS',
                  label: 'Loss-Optimize Enrolled Assets',
                },
                {
                  id: 'COST_OPTIMIZE_ENROLLED_ASSETS',
                  label: 'Cost-Optimize Enrolled Assets',
                },
                {
                  id: 'ECONOMIC_COST_OPTIMIZATION',
                  label: 'Economic Cost Optimization',
                },
              ]}
              value={program.market_control_strategy}
            />
          </div>
        </div>
      </div>
      {program.financial_model === 'LMPD' && (
        <div className="program-settings_column">
          <div className="program-settings_column-row">
            <NumberInput
              id="agcc"
              label="Avoided Generation Capacity Cost"
              onChange={(value) =>
                updateMarketInfoProp('avoided_generation_capacity_cost', value)
              }
              required
              unit={`${program.currency}/MW-yr`}
              value={
                program.system_market_info.avoided_generation_capacity_cost
              }
            />
          </div>
          <div className="program-settings_column-row">
            <NumberInput
              id="pk"
              label="Forecasted System Peak Events"
              onChange={(value) =>
                updateMarketInfoProp('num_forecasted_peak_events', value)
              }
              required
              value={program.system_market_info.num_forecasted_peak_events}
            />
          </div>
          <div className="program-settings_column-row">
            <NumberInput
              id="pk-load"
              label="Forecasted System Peak Load"
              onChange={(value) =>
                updateMarketInfoProp('system_peak_load', value)
              }
              required
              unit="MW"
              value={program.system_market_info.system_peak_load}
            />
          </div>
          <div className="program-settings_column-row">
            <NumberInput
              id="system-peak"
              label="System Peak"
              onChange={(value) =>
                updateMarketInfoProp('system_peak_threshold', value)
              }
              required
              unit="%"
              value={program.system_market_info.system_peak_threshold}
            />
          </div>
          <div className="program-settings_column-row">
            <NumberInput
              id="tx-loss"
              label="Transmission Loss"
              onChange={(value) =>
                updateMarketInfoProp('transmission_loss', value)
              }
              required
              unit="%"
              value={program.system_market_info.transmission_loss}
            />
          </div>
          <div className="program-settings_column-row">
            <NumberInput
              id="Rec"
              label="Renewable Energy Credit (REC)"
              onChange={(value) =>
                updateMarketInfoProp('renewable_energy_credit', value)
              }
              required
              unit={`${program.currency}/MWh`}
              value={program.system_market_info.renewable_energy_credit}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FinancialModel;
