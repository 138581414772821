import React, { useEffect, useRef, memo } from 'react';

import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';

import RadioButtonGroup from 'routes/RequestsResponses/components/RadioButtonGroup';
import { DatePicker } from 'components/DatePicker';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import Tooltip from 'components/Tooltip';
import SVG from 'components/SVG';
import FormLabel from 'components/FormLabel';
import DialogSection from 'components/Dialog/DialogSection';
import TimePicker from 'components/TimePicker/TimePicker';

import { CreateRequestSetupProps } from './CreateRequestSetup.types';
import {
  closeAfterOptions,
  quantityGenerationTypes,
  quantityGenerationTypesAccess,
  recurrenceOptions,
  schema,
  setCloseHandler,
} from './CreateRequestSetup.logic';

import {
  getIsForecastBased,
  getMaxSiaDate,
  isWSC,
} from '../CreateRequest.logic';

import './CreateRequestSetup.scss';

function CreateRequestSetup({
  program,
  onSubmit,
  data,
  isDso,
  onFieldChange,
  siaList,
}: CreateRequestSetupProps) {
  const todayRef = useRef<DateTime>(
    DateTime.local().setZone(program?.timezone)
  );

  const {
    openDate,
    closeDate,
    openTime,
    closeTime,
    closeAfter,
    recurrence,
    quantityGeneration,
  } = data;

  const today = todayRef.current;

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema(today, siaList)),
  });

  const quantityGenerationWatcher = useWatch({
    control,
    name: 'quantityGeneration',
  });
  const closeAfterWatcher = useWatch({
    control,
    name: 'closeAfter',
  });

  const isForecastBased = getIsForecastBased(
    quantityGenerationWatcher,
    quantityGeneration
  );
  const maxDate = isForecastBased && siaList && getMaxSiaDate(siaList);

  const defaultRecurrence = recurrenceOptions[0].id;

  useEffect(() => {
    if (isForecastBased) {
      setValue('recurrence', defaultRecurrence);
    }
  }, [isForecastBased, setValue, defaultRecurrence]);

  const disableClose = (closeAfter || closeAfterWatcher) !== 'Custom';

  const defaultCloseAfter = closeAfter || closeAfterOptions[0].id;

  const defaultStart =
    openDate?.startOf('day') ||
    DateTime.local().startOf('day').set({ hour: today.hour });

  const defaultClose =
    closeDate?.startOf('day') ||
    DateTime.local().startOf('day').plus({ days: 1 }).set({ hour: today.hour });

  const quantityGenerationTypesWithAccess = quantityGenerationTypes.map(
    (item, idx: number) => ({
      ...item,
      disabled:
        !quantityGenerationTypesAccess(isWSC)[isDso ? 'dso' : 'default'][idx],
    })
  );

  return (
    <form id="create-request-setup" onSubmit={handleSubmit(onSubmit)}>
      <DialogSection
        classNames={{ headingClass: 'create-request-setup__section-title' }}
        title="Quantity generation"
        icon="SlidersIcon"
      >
        <Grid item xs={12}>
          <FormLabel htmlFor="quantityGeneration">
            Quantity generation
          </FormLabel>
          <Controller
            name="quantityGeneration"
            control={control}
            defaultValue={quantityGeneration || quantityGenerationTypes[0].id}
            render={({ field: { onChange, value } }) => (
              <RadioButtonGroup
                id="quantityGeneration"
                listType="row"
                label=""
                onChange={(value) => {
                  onChange(value);
                  onFieldChange('quantityGeneration', value);
                }}
                options={quantityGenerationTypesWithAccess}
                value={value}
                classes={{
                  groupClass: 'create-request-quantity__radio-group',
                  btnClass: 'create-request-quantity__radio-button',
                }}
              />
            )}
          />
          <div className="create-request-setup__error">
            {errors?.quantityGeneration?.message}
          </div>
        </Grid>
      </DialogSection>
      <DialogSection
        isLast
        classNames={{ headingClass: 'create-request-setup__section-title' }}
        title="Repeat settings"
        icon="RefreshCWIcon"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel
              required
              styles={{ marginBottom: '8px', maxWidth: '82px' }}
              htmlFor="recurrence"
            >
              Recurrence
            </FormLabel>
            <Controller
              name="recurrence"
              control={control}
              defaultValue={recurrence || defaultRecurrence}
              render={({ field: { onChange, value } }) => (
                <RadioButtonGroup
                  id="ltcontractmodel1"
                  listType="row"
                  label=""
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('recurrence', value);
                  }}
                  options={recurrenceOptions}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormLabel required htmlFor="openDate">
              Post request on
              <Tooltip
                arrow
                content="Request will be posted on the selected date."
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="openDate"
              control={control}
              defaultValue={defaultStart}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  minDate={today.startOf('day')}
                  maxDate={maxDate || null}
                  onChange={(val: any) => {
                    onChange(val);
                    onFieldChange('openDate', val);
                    if (disableClose) {
                      setCloseHandler(defaultCloseAfter, getValues, setValue);
                    }
                  }}
                  value={value}
                />
              )}
            />
            <div className="create-request-setup__error">
              {errors?.openDate?.message}
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="openTime"></FormLabel>
            <Controller
              name="openTime"
              control={control}
              defaultValue={openTime || defaultStart}
              render={({ field: { onChange, value } }) => (
                <TimePicker value={value} onChange={onChange} />
              )}
            />
            <div className="create-request-setup__error">
              {errors?.openTime?.message}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormLabel styles={{ marginBottom: '8px' }} htmlFor="closeAfter">
              Close after
            </FormLabel>
            <Controller
              name="closeAfter"
              control={control}
              defaultValue={closeAfter || closeAfterOptions[0].id}
              render={({ field: { onChange, value } }) => (
                <RadioButtonGroup
                  id="ltcontractmodel"
                  listType="row"
                  label=""
                  onChange={(value) => {
                    onChange(value);
                    onFieldChange('closeAfter', value);
                    setCloseHandler(value, getValues, setValue);
                  }}
                  options={closeAfterOptions}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormLabel required htmlFor="closeDate">
              Close request on
              <Tooltip
                arrow
                content="Request will close on this day."
                theme="light"
              >
                <SVG
                  icon="InfoIcon"
                  fontSize="12px"
                  className="create-request-service__label-icon"
                />
              </Tooltip>
            </FormLabel>
            <Controller
              name="closeDate"
              control={control}
              defaultValue={defaultClose}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  minDate={today.startOf('day')}
                  maxDate={maxDate || null}
                  disabled={disableClose}
                  value={value}
                  onChange={(val: any) => {
                    onChange(val);
                    onFieldChange('closeDate', val);
                    if (disableClose) {
                      setCloseHandler(defaultCloseAfter, getValues, setValue);
                    }
                  }}
                />
              )}
            />
            <div className="create-request-setup__error">
              {errors?.closeDate?.message}
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="closeTime"></FormLabel>
            <Controller
              name="closeTime"
              control={control}
              defaultValue={closeTime || defaultClose}
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  value={value}
                  onChange={onChange}
                  disabled={disableClose}
                />
              )}
            />
            <div className="create-request-setup__error">
              {errors?.closeTime?.message}
            </div>
          </Grid>
        </Grid>
      </DialogSection>
    </form>
  );
}

export default memo(CreateRequestSetup);
