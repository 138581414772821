import React from 'react';

import { Tenant } from 'types/tenant';

import { sorter } from 'routes/ParticipantsAndDers/ParticipantsAndDers';

export const participantsAndDerRoutes = [
  {
    path: 'participants',
    label: 'Participants',
  },
  /*{
    path: 'ders',
    label: 'DERs',
  },*/
];

export const tenantRoutes = [
  {
    path: 'overview',
    label: 'Overview',
    useIsExact: false,
  },
  {
    path: 'users',
    label: 'Users',
    useIsExact: true,
  },
  {
    path: 'ders',
    label: 'DERS',
    useIsExact: true,
  },
];

export function placeHolderRow(numColumns: number, loading: boolean) {
  return (
    <tr className="participants-table__row">
      <td
        className="participants-table__cell participants__placeholder-text"
        colSpan={numColumns}
      >
        <div style={{ width: 'fit-content', margin: 'auto' }}>
          {!loading && 'There is no data to display.'}
        </div>
      </td>
    </tr>
  );
}

export const sortTenants = (ders: Tenant[] | undefined) =>
  ders?.sort((a, b) => sorter.compare(a.name, b.name));
