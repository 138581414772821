import * as yup from 'yup';

import { serviceType } from 'types/contract-managment';
import { FlexResponseFormFields } from './FlexResponseForm.types';

/**
 * CAIC Connection agreemnet import capacity
 * CAEC Connection agreement export capacity
 * 1. Sustain bid responses must:
 *     A. Q < CAIC (if not null) & Q < CAEC (if not null)
 * MAR-1521
 * 2. MIC
 *     A. If response to offer -> Q <= CAIC
 *     B. If response to bid -> Q <= CAIC
 *     C. For both Q <= TCVN Import Capacity
 *
 * 3. MEC
 *     A. If response to offer -> Q <= CAEC
 *     B. If response to bid -> Q <= CAEC
 *     C. For both Q <= TCVN Export Capacity
 */
export const flexResponseFormSchema = (
  service: serviceType,
  availabilityPrice: number,
  utilizationPrice: number,
  isDynamic: boolean
) =>
  yup.object().shape({
    [FlexResponseFormFields.Quantity]: yup
      .number()
      .typeError('Quantity is required.')
      .positive('Must be positive.')
      .required('Quantity is required.')
      .test(
        'quantity-remaining-capacity',
        'Quantity must be less than the remaining capacity.',
        (quantity, ctx): boolean => {
          const selectedDER = ctx.parent[FlexResponseFormFields.Asset];
          if (quantity && selectedDER) {
            const { remainingCapacity } = selectedDER;
            if (
              service === serviceType.SustainPeakManagement ||
              service === serviceType.SustainBilateral ||
              service === serviceType.SustainExportManagement ||
              service === serviceType.DynamicPeakManagement ||
              service === serviceType.SecurePeakManagement
            ) {
              // here quantity is kW, remaining Capacity is W
              if (remainingCapacity && quantity * 1000 > remainingCapacity) {
                return false;
              }
            }
          }
          return true;
        }
      )
      .test(
        'quantity-limits-CAIC',
        'Quanity must be less than or equal to the connection agreement import capacity',
        (quantity, ctx): boolean => {
          const selectedDER = ctx.parent[FlexResponseFormFields.Asset];
          if (quantity && selectedDER) {
            const { connectionImportCapacity: CAIC } = selectedDER;
            if (service === serviceType.MIC) {
              if (CAIC && quantity * 1000 > CAIC) {
                return false;
              }
            }
          }
          return true;
        }
      )
      .test(
        'quantity-limits-TCVNIC',
        'Quanity must be less than or equal to the TCVN import capacity',
        (quantity, ctx): boolean => {
          const selectedDER = ctx.parent[FlexResponseFormFields.Asset];
          if (quantity && selectedDER) {
            const { tcvnImportCapacity: TCVNImportCapacity } = selectedDER;
            if (service === serviceType.MIC) {
              if (TCVNImportCapacity && quantity * 1000 > TCVNImportCapacity) {
                return false;
              }
            }
          }
          return true;
        }
      )
      .test(
        'quantity-limits',
        'Quanity must be less than or equal to the connection agreement export capacity',
        (quantity, ctx): boolean => {
          const selectedDER = ctx.parent[FlexResponseFormFields.Asset];
          if (quantity && selectedDER) {
            const { connectionExportCapacity: CAEC } = selectedDER;
            if (service === serviceType.MEC) {
              if (CAEC && quantity * 1000 > CAEC) {
                return false;
              }
            }
          }
          return true;
        }
      )
      .test(
        'quantity-limits',
        'Quanity must be less than or equal to the TCVN export capacity',
        (quantity, ctx): boolean => {
          const selectedDER = ctx.parent[FlexResponseFormFields.Asset];
          if (quantity && selectedDER) {
            const { tcvnExportCapacity: TCVNExportCapacity } = selectedDER;
            if (service === serviceType.MEC) {
              if (TCVNExportCapacity && quantity * 1000 > TCVNExportCapacity) {
                return false;
              }
            }
          }
          return true;
        }
      ),
    ...(!isDynamic && {
      [FlexResponseFormFields.AvailabilityPrice]: yup
        .number()
        .nullable(true)
        .max(availabilityPrice),
    }),
    [FlexResponseFormFields.UtilizationPrice]: yup
      .number()
      .nullable(true)
      .max(utilizationPrice),
    [FlexResponseFormFields.Asset]: yup
      .object()
      .nullable(false)
      .required('DER is required.'),
    [FlexResponseFormFields.MaxEnergyDaily]: yup
      .number()
      .positive('Must be positive.')
      .nullable(),
  });

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: '#ECF3FA',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#54595E',
    fontSize: 12,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? '#d4dfe8' : '#54595E',
    fontSize: 12,
    display: 'flex',
    maxWidth: '210px',
  }),
};
