import React, { useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useLocation, useParams } from 'react-router-dom';
import {
  Request,
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import useInterval from 'hooks/useInterval';
import useQueryState, {
  getDateTimeFromParam,
  serializeDateTime,
} from 'hooks/useQueryState';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { CimJSON, NetworkModel } from 'types/cim';
import { MapMode } from 'types/map';

import { jsDateToDateTimeProgramTimezone } from 'helpers/time';

// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'components/OldDatePicker';
import LoadingSpinner from 'components/LoadingSpinner';
import Map from 'components/Map';
import Select from 'components/OldSelect';

import { convertCimToGeoJson } from './geo_json';
import Legend from './Legend';
import {
  PricingEventsResponse,
  PricingEvent,
  processPricingEvents,
} from './pricingEvents';
import AnalysisFailedOverlay from './AnalysisFailedOverlay';
import LeftRail from './LeftRail';

import './OperationalView.scss';

enum FinancialModel {
  'DLMP' = 'DLMP',
  'LMPD' = 'LMPD',
  'PAY_AS_BID' = 'PAY_AS_BID',
  'PAY_AS_CLEAR' = 'PAY_AS_CLEAR',
}

type Feeder = {
  id: string;
  name: string;
};

type Substation = {
  id: string;
  name: string;
  feeders: Array<Feeder>;
};

const MARKET_TYPES = [
  { label: 'Same Day', value: 'sameday' },
  { label: 'Day Ahead', value: 'dayahead' },
];

function isMapMode(value: string): value is keyof typeof MapMode {
  return value in MapMode;
}

const getMapMode = (mode: string | null) => {
  if (mode === null) {
    return null;
  }

  return isMapMode(mode) ? MapMode[mode] : null;
};

const OperationalView = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const { selectedProgram: program } = useProgramsContext();
  const { programID } = useParams<{ programID: string }>();
  // Keep track of which feeders we already loaded to reduce network calls
  const [cachedFeeders, setCachedFeeders] = useState<Set<string>>(new Set());

  const [eventStartTime, setEventStartTime] = useQueryState(
    getDateTimeFromParam(
      queryParams,
      'time',
      DateTime.local()
        .setZone(program?.timezone || 'UTC')
        .startOf('hour')
    ),
    'time',
    serializeDateTime
  );
  const [showAllIcons, setShowAllIcons] = useState(true);
  const [financialModel, setFinancialModel] = useState(FinancialModel.DLMP);
  const [mapMode, setMapMode] = useQueryState<MapMode>(
    getMapMode(queryParams.get('visualization')) || MapMode.VALUATION,
    'visualization'
  );
  const [market, setMarket] = useQueryState(
    queryParams.get('market') || 'sameday',
    'market'
  );
  const [overlayDataLoading, setOverlayDataLoading] = useState(false);
  const [networkModel, setNetworkModel] = useState<NetworkModel | null>(null);

  // Map overlay data is structured as follows:
  // When the map mode is MapMode.FORECAST
  // { [der_rdf_id]: <generation_forecast_in_watts> }
  //
  // When the map mode is MapMode.PWR
  // { [der_rdf_id]: { power: <float power in Watts> } }
  //
  // When the map mode is MapMode.LMPD
  // { [der_rdf_id]: { lmp: <float lmp>, d: <float d component>, lmpd: <float> } }
  //
  // When the map mode is MapMode.DLMP
  // { [der_rdf_id]: { dlmp: <float>, energy: <float>, loss: <float>, generation: <float>, congestion: <float>}}
  const [overlayData, setOverlayData] = useState({});
  const [selectedContainers, setSelectedContainers] = useState<string[]>([]);
  const [time, setTime] = useState(
    DateTime.local().setZone(program?.timezone || 'UTC')
  );

  useEffect(() => {
    if (program) {
      setEventStartTime((t) => t.setZone(program.timezone));
      setTime((t) => t.setZone(program.timezone));
    }
  }, [program, setEventStartTime, setTime]);

  useInterval(() => setTime(DateTime.local().setZone(program!.timezone)), 1000);

  const workspaceName = program?.workspace_name;

  const { data: containers, loading: containersLoading } = useRequestEffect<
    Substation[]
  >({
    url: `/api/dsp/program/${programID}/feeder`,
    method: 'get',
    initialData: [],
    refetchOnChange: [programID],
    dataTransform: (data: any) => {
      const subMap: { [key: string]: Substation } = {};

      data.substations.forEach((sub: any) => {
        subMap[sub.id] = {
          id: sub.id,
          name: sub.name,
          feeders: [],
        };
      });

      data.feeders.forEach((feeder: any) => {
        if (feeder.substation && subMap[feeder.substation]) {
          subMap[feeder.substation].feeders.push({
            id: feeder.id,
            name: feeder.name,
          });
        } else {
          // Feeder with no substation
          subMap[feeder.id] = {
            id: feeder.id,
            name: feeder.name,
            feeders: [],
          };
        }
      });

      const sorter = Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      return Object.values(subMap).sort((a, b) =>
        sorter.compare(a.name, b.name)
      );
    },
    toast: {
      error: 'Could not load substations and feeders.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  useEffect(() => {
    // Reset all the cached data
    setCachedFeeders(new Set());
    setNetworkModel(null);
    setSelectedContainers([]);
    setOverlayData({});
  }, [programID]);

  const { makeRequest: loadFeederData, loading: modelLoading } = useRequest(
    `/api/workspace/${workspaceName}/branch/master/feeder-content/stream`
  );

  const loadFeeder = async (feederID: string) => {
    if (program === null) {
      return;
    }

    await loadFeederData({
      method: 'get',
      // @ts-ignore
      params: {
        feeder: feederID,
      },
      toast: {
        error: `Could not load network model for feeder ${feederID}`,
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: (data: CimJSON | null) => {
        const geoJson = convertCimToGeoJson(data);

        if (networkModel === null) {
          setNetworkModel(geoJson);
        } else {
          // Merge our new GeoJSON with that of the existing model
          setNetworkModel({
            lines: { ...networkModel.lines, ...geoJson.lines },
            linkConnectors: {
              ...networkModel.linkConnectors,
              ...geoJson.linkConnectors,
            },
            linkIcons: { ...networkModel.linkIcons, ...geoJson.linkIcons },
            nodes: { ...networkModel.nodes, ...geoJson.nodes },
            nodeConnectors: {
              ...networkModel.nodeConnectors,
              ...geoJson.nodeConnectors,
            },
            nodeIcons: { ...networkModel.nodeIcons, ...geoJson.nodeIcons },
          });
        }
      },
    });

    setCachedFeeders(new Set([...cachedFeeders.values(), feederID]));
  };

  const loadFeederMarketData = async (
    feederID: string,
    marketType: string,
    startTime: DateTime
  ) => {
    const marketDataRequest = new Request(
      `/api/dsp/program/${programID}/pricing_events/feeder/${feederID}`
    );

    const {
      data,
    }: {
      data: PricingEventsResponse<PricingEvent>;
    } = await marketDataRequest.get({
      params: {
        market_type: marketType,
        start_time: startTime.toISO(),
      },
    });

    if (data.event_type === 'DLMP') {
      setFinancialModel(FinancialModel.DLMP);
    } else if (data.event_type === 'LMPD') {
      setFinancialModel(FinancialModel.LMPD);
    } else if (data.event_type === 'PAY_AS_BID') {
      setFinancialModel(FinancialModel.PAY_AS_BID);
    } else if (data.event_type === 'PAY_AS_CLEAR') {
      setFinancialModel(FinancialModel.PAY_AS_CLEAR);
    }

    return processPricingEvents(data);
  };

  const loadFeederForecastData = async (
    feederID: string,
    startTime: DateTime
  ) => {
    const forecastDataRequest = new Request(
      `/api/dsp/program/${programID}/feeder/${feederID}/generation_forecasts/market/${market}`
    );
    const { data } = await forecastDataRequest.get({
      params: {
        timestamp: startTime.toISO(),
      },
    });

    return data;
  };

  const reloadOverlayData = async (
    market_type: string,
    time: DateTime,
    mode: MapMode
  ) => {
    setOverlayData({}); // Reset
    setOverlayDataLoading(true);

    if (mode === MapMode.VALUATION || mode === MapMode.PWR) {
      const newPriceSet = await Promise.all(
        selectedContainers.map((id) =>
          loadFeederMarketData(id, market_type, time)
        )
      );
      let newPrices = {};
      newPriceSet.forEach((prices) => {
        newPrices = { ...newPrices, ...prices };
      });

      // Overwrite old overlay data in this case
      setOverlayData(newPrices);
    } else if (mode === MapMode.FORECAST) {
      const newForecastDataSet = await Promise.all(
        selectedContainers.map((id) => loadFeederForecastData(id, time))
      );
      let newForecastData = {};
      newForecastDataSet.forEach((forecasts) => {
        newForecastData = { ...newForecastData, ...forecasts };
      });

      // Overwrite old overlay data in this case
      setOverlayData(newForecastData);
    }

    setOverlayDataLoading(false);
  };

  const changeMapMode = (newMapMode: MapMode) => {
    setMapMode(newMapMode);
    reloadOverlayData(market, eventStartTime, newMapMode);
  };
  const showOPFFailedOverlay = useMemo(() => {
    if (mapMode === MapMode.FORECAST) {
      return false;
    }

    //Show for both Valuation & Power modes since those depend on Pricing Events
    return Object.values(overlayData).length === 0;
  }, [mapMode, overlayData]);

  return (
    <div className="operational-view">
      {program && (
        <div className="title-bar">
          <span className="real-time">{time.toFormat('HH:mm:ss ZZZZ')}</span>
          <div className="market-select">
            <Select
              isClearable={false}
              isMulti={false}
              label="Market"
              onChange={(opt) => {
                if (opt.value !== market) {
                  setMarket(opt.value);
                  const newTime = DateTime.local()
                    .setZone(program.timezone)
                    .startOf('hour');
                  setEventStartTime(newTime);
                  reloadOverlayData(opt.value, newTime, mapMode);
                }
              }}
              options={MARKET_TYPES}
              row
              value={market === 'sameday' ? MARKET_TYPES[0] : MARKET_TYPES[1]}
            />
          </div>
          <span
            className="display-label"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            Event:
          </span>
          <OldDatePicker
            date={eventStartTime}
            onClose={(newTime) => {
              setEventStartTime(newTime);
              reloadOverlayData(market, newTime, mapMode);
            }}
            options={{
              enableSeconds: false,
              enableTime: true,
              formatDate: (date) => {
                const dt = jsDateToDateTimeProgramTimezone(date);
                return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
              },
              minuteIncrement:
                market === 'sameday' ? program.sameday_event_duration / 60 : 60,
            }}
            useUTC={false}
          />
        </div>
      )}
      {program && (
        <div className="map-container">
          <div className="rail-container">
            <LeftRail
              loading={containersLoading}
              selected={selectedContainers}
              setSelectedContainer={async (id, selected) => {
                if (!selected) {
                  setSelectedContainers(
                    selectedContainers.filter((s) => s !== id)
                  );
                } else {
                  setSelectedContainers([...selectedContainers, id]);
                  if (!cachedFeeders.has(id)) {
                    // Loading a container we've never seen before
                    loadFeeder(id);

                    setOverlayDataLoading(true);

                    if (
                      mapMode === MapMode.VALUATION ||
                      mapMode === MapMode.PWR
                    ) {
                      const newPrices = await loadFeederMarketData(
                        id,
                        market,
                        eventStartTime
                      );
                      setOverlayData({ ...overlayData, ...newPrices });
                    } else if (mapMode === MapMode.FORECAST) {
                      const newForecasts = await loadFeederForecastData(
                        id,
                        eventStartTime
                      );
                      setOverlayData({ ...overlayData, ...newForecasts });
                    }

                    setOverlayDataLoading(false);
                  }
                }
              }}
              setShowAllIcons={setShowAllIcons}
              showAllIcons={showAllIcons}
              substations={containers || []}
            />
            {networkModel !== null && (
              <>
                <Map
                  containers={containers}
                  currency={program.currency}
                  locale={program.locale}
                  selectedContainers={selectedContainers}
                  financialModel={financialModel}
                  mapMode={mapMode}
                  networkModel={networkModel}
                  overlayData={overlayData}
                  showAllIcons={showAllIcons}
                >
                  {showOPFFailedOverlay && <AnalysisFailedOverlay />}
                  <Legend
                    changeMapMode={changeMapMode}
                    currency={program.currency}
                    financialModel={financialModel}
                    locale={program.locale}
                    mapMode={mapMode}
                  />
                </Map>
              </>
            )}
            {(modelLoading || overlayDataLoading) && (
              <div className="loading-overlay">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationalView;
