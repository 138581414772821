import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';
import { useUserContext } from 'contexts/UserContext';

import { flexContract, flexContractRaw } from 'types/contract-managment';
import { Tenant } from 'types/tenant';
import { CurrentUser } from 'types/user';

import { getTimeGateDateTime } from 'helpers/time';

import RouterSwitch from 'components/RouterSwitch';

import P2PContracts from './routes/P2PContracts';
import P2NContracts from './routes/P2NContracts';
import { processRawFlexContract } from './helpers/dataProcessers';

import './LongTermContracts.scss';

enum Routes {
  p2nPath = '/program/:programID/long-term-contracts/peer-to-network',
  p2pPath = '/program/:programID/long-term-contracts/peer-to-peer',
}

const LongTermContracts: React.FunctionComponent = () => {
  const { programID } = useParams<{ programID: string }>();
  const { selectedProgram } = useProgramsContext();
  const { user: currentUser, userHasTenant } = useUserContext();
  const program = selectedProgram || null;

  const { data: tenants } = useRequestEffect<Tenant[]>({
    url: '/api/dsp/tenants',
    method: 'get',
    toast: {
      error: 'Failed to load tenant list.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const {
    data: flexContracts,
    loading: loadingFlexContracts,
    refetch: refetchFlexContracts,
  } = useRequestEffect<flexContract[]>({
    url: `/api/dsp/program/${programID}/flex/contracts`,
    initialData: [],
    method: 'get',
    refetchOnChange: [programID, tenants],
    blockRequest: () => !tenants,
    dataTransform: (data) => {
      return data.map((contract: flexContractRaw) =>
        processRawFlexContract(contract, tenants, program)
      );
    },
  });

  if (!currentUser || !tenants || !userHasTenant() || loadingFlexContracts) {
    return <></>;
  }

  return (
    <div className="long-term-contracts">
      <RouterSwitch useRedirect404>
        <Route exact path={Routes.p2nPath}>
          <P2NContracts
            contracts={flexContracts}
            isLoading={loadingFlexContracts}
            loggedInUser={currentUser as CurrentUser}
            refetchContracts={refetchFlexContracts}
          />
        </Route>
        <Route exact path={Routes.p2pPath}>
          <P2PContracts
            gateTime={getTimeGateDateTime(program)}
            contracts={flexContracts}
            isLoading={loadingFlexContracts}
            loggedInUser={currentUser as CurrentUser}
            refetchContracts={refetchFlexContracts}
          />
        </Route>
      </RouterSwitch>
    </div>
  );
};

export default LongTermContracts;
