import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/Accordion';
import SVG from 'components/SVG';
import NotificationTable from './components/NotificationTable';
import NotificationButtons from './components/NotificationButtons';
import MessageForm from './components/MessageForm';

import {
  ModalProps,
  NotificationItemProp,
  NotificationRulesProp,
  NotificationsStateProp,
} from './EmailNotifications.types';
import {
  constructState,
  contentListTemplate,
  defaultModalState,
  emptyRow,
  modifyItemFromListById,
  getNotificationRules,
  notificationApiUrl,
  notificationRulesApiUrl,
  setNotificationRulesData,
  notificationUpdateApiUrl,
  notificationPreviewApiUrl,
  getChangedItems,
} from './EmailNotifications.logic';
import './EmailNotifications.scss';

const EmailNotifications = () => {
  const [state, setState] = useState<NotificationsStateProp[]>([]);
  const [changedNotifications, setChangedNotifications] = useState<any>();
  const [showModal, setShowModal] = useState<ModalProps>(defaultModalState);
  const [notificationRules, setNotificationRules] = useState<
    Record<string, NotificationRulesProp>
  >(getNotificationRules());

  const { programID } = useParams<{ programID: string }>();

  const newWindow = useRef(window);

  const { makeRequest: notificationRequest, loading: notificationLoading } =
    useRequest('');

  useRequestEffect({
    url: notificationRulesApiUrl,
    method: 'get',
    blockRequest: () => !!notificationRules,
    dataTransform: (data: Record<string, NotificationRulesProp>) => {
      if (data) {
        setNotificationRulesData(data);
        setNotificationRules(data);
      }
    },
    toast: {
      error: 'Could not load Notification rules',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { data: notificationList, refetch: notificationListRefetch } =
    useRequestEffect<NotificationItemProp[]>({
      url: notificationApiUrl(programID),
      blockRequest: () => !notificationRules,
      refetchOnChange: [notificationRules],
      dataTransform: (data) => {
        return data;
      },
      method: 'get',
      toast: {
        error: 'Could not load Notification rules list',
        settings: {
          autoDismiss: true,
        },
      },
    });

  useEffect(() => {
    if (notificationList) {
      setState(
        constructState(
          notificationRules,
          notificationList,
          contentListTemplate as any
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationList]);

  const clearChangedNotifications = () => setChangedNotifications({});

  const closeMessageModalHandler = () => {
    setShowModal(defaultModalState);
  };

  const onMessageShowHandler = (content: Record<string, any>) => {
    setShowModal({ isShow: true, content, type: 'message' });
  };

  const onPreviewHandler = (content: Record<string, any>) => {
    const { body, header } = content;
    notificationRequest({
      method: 'post',
      baseURL: notificationPreviewApiUrl,
      body: {
        body,
        header,
      },
      onSuccess: (data) => {
        newWindow.current = window.open(
          '',
          '',
          'width=600, height=600,left=400,top=200'
        ) as typeof window;
        const div = newWindow.current.document.createElement('div');
        const body = newWindow.current.document.body;
        div.innerHTML = data;
        body.insertBefore(div, body.firstChild);
      },
      toast: {
        error: 'Could not download preview template.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const onRemoveHandler = (id: string) => {
    notificationRequest({
      baseURL: notificationUpdateApiUrl(programID, id),
      method: 'delete',
      onSuccess: () => {
        notificationListRefetch();
        clearChangedNotifications();
      },
      toast: {
        success: 'Notification deleted',
        error: 'Could not delete notification.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const onNewNotificationHandler = (typeId: string) => {
    setState(
      modifyItemFromListById(
        state,
        typeId,
        {
          ...emptyRow,
          newId: new Date().getTime().toString(),
          triggering_event_type: typeId,
          program_id: programID,
        },
        'add'
      )
    );
  };

  const onSwitchChangeHandler = (body: NotificationItemProp) => {
    if (body.newId && !body.id) {
      return notificationRequest({
        method: 'post',
        baseURL: notificationApiUrl(programID),
        body: {
          ...body,
          active: true,
          service_type: body.service_type || null,
        },
        onSuccess: () => {
          notificationListRefetch();
          clearChangedNotifications();
        },
        toast: {
          success: 'New notification successfully created',
          error: 'Could not create new notification.',
          settings: {
            autoDismiss: true,
          },
        },
      });
    }
    return notificationRequest({
      method: 'patch',
      baseURL: notificationUpdateApiUrl(programID, body.id || ''),
      body: {
        ...body,
        active: !body.active,
      },
      onSuccess: () =>
        setState(
          modifyItemFromListById(
            state,
            body.triggering_event_type,
            { ...body, active: !body.active },
            'update'
          )
        ),
      toast: {
        success: `Notification successfully ${
          body.active ? 'disabled' : 'enabled'
        }`,
        error: `Could not ${body.active ? 'disable' : 'enable'} notification.`,
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const onFieldChangeHandler = (
    data: NotificationItemProp,
    typeId: string,
    value: string | boolean | Record<string, any>,
    name: string
  ) => {
    if (!data.newId) {
      setChangedNotifications((prev: any) => ({
        ...prev,
        [typeId]: prev?.[typeId]
          ? prev[typeId].add(data.id)
          : new Set().add(data.id),
      }));
    }
    setState(
      modifyItemFromListById(
        state,
        typeId,
        { ...data, [name]: value },
        'update'
      )
    );
  };

  const onSaveMessageHandler = (
    data: NotificationItemProp,
    subject: string,
    message: string
  ) => {
    if (data.newId) {
      return setState(
        modifyItemFromListById(
          state,
          data.triggering_event_type,
          { ...data, subject, body: message },
          'update'
        )
      );
    }
    return notificationRequest({
      method: 'patch',
      baseURL: notificationUpdateApiUrl(programID, data.id || ''),
      body: {
        ...data,
        subject,
        body: message,
        header: subject,
      },
      onSuccess: () => {
        notificationListRefetch();
        clearChangedNotifications();
      },
      toast: {
        success: 'Notification message successfully updated',
        error: 'Could not update notification message.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const onSaveButtonHandler = (items: Set<string>, typeId: string) => {
    const resArr: any[] = [];
    const changedArray = Array.from(items);
    state.forEach((i) =>
      i.items.forEach((j) =>
        j.notifications.forEach(
          (item) =>
            item.id && changedArray.includes(item.id) && resArr.push(item)
        )
      )
    );
    notificationRequest({
      method: 'put',
      baseURL: notificationApiUrl(programID),
      body: resArr,
      onSuccess: () => {
        setChangedNotifications((prev: any) => ({
          ...prev,
          [typeId]: prev?.[typeId] ? prev[typeId].clear() : undefined,
        }));
      },
      toast: {
        success: 'Notifications successfully updated',
        error: 'Could not update notifications.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  return (
    <Grid container>
      {state?.map((contentRow) => (
        <Grid item container key={contentRow.title} xs={12} spacing={2}>
          <Grid item xs={12}>
            <h3
              className="email-notifications__title"
              style={{
                marginTop: contentRow.title === 'General' ? '0px' : '56px',
              }}
            >
              {contentRow.title}
            </h3>
          </Grid>
          {contentRow.items.map((item, idx) => {
            const changedItem = getChangedItems(
              changedNotifications,
              item.value
            );
            return (
              <Grid item xs={12} key={item.title} paddingBottom={1}>
                <Accordion
                  id={`EmailNotifications-${idx}-${item.title}`}
                  elevation={0}
                  disableGutters
                  className="email-notifications"
                >
                  <AccordionSummary
                    expandIcon={
                      <SVG
                        round
                        icon="ChevronDownIcon"
                        fontSize="1.1rem"
                        color="#363B3F"
                      />
                    }
                    sx={{
                      borderBottom: '2px solid #f9fbfd',
                    }}
                    classNames={{
                      root: 'email-notifications__summary',
                      content: 'email-notifications__summary-content',
                    }}
                  >
                    <div>{item.title}</div>
                    <NotificationButtons
                      loading={notificationLoading}
                      showSave={!!changedItem}
                      onSave={() =>
                        onSaveButtonHandler(changedItem, item.value)
                      }
                      onNewCreate={() => onNewNotificationHandler(item?.value)}
                    />
                  </AccordionSummary>
                  <AccordionDetails className="email-notifications__details">
                    {!isEmpty(item.notifications) && (
                      <NotificationTable
                        data={item.notifications}
                        settings={item.settings}
                        onMessageShow={onMessageShowHandler}
                        onPreview={onPreviewHandler}
                        onRemove={onRemoveHandler}
                        onSwitchChange={onSwitchChangeHandler}
                        onFieldChange={onFieldChangeHandler}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      ))}
      <MessageForm
        active={showModal.type === 'message' && showModal.isShow}
        content={showModal.type === 'message' ? showModal.content : {}}
        onSave={onSaveMessageHandler}
        onClose={closeMessageModalHandler}
      />
    </Grid>
  );
};

export default EmailNotifications;
