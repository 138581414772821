import React from 'react';

import './Link.scss';

type Props = {
  href: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  rel?: string;
  className?: string;
  download?: boolean;
};

/** Standard Link
 */
const Link: React.FC<Props> = ({
  href,
  target = '_self',
  className = '',
  download = false,
  rel,
  children,
}) => (
  <a
    href={href}
    target={target}
    download={download}
    rel={rel}
    className={`link ${className}`}
  >
    {children}
  </a>
);

export default Link;
