import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { TimePickerPopoverProps } from '../TimePicker.types';
import '../TimePicker.scss';

export const TimePickerPopover = ({
  anchor,
  value,
  onClose,
  onChange,
  timeIntervals,
  timeIntervalsRefs,
}: TimePickerPopoverProps) => {
  const [currentValue, setValue] = useState(value);

  const onChangeHandler = (data: DateTime) => {
    onChange(data);
    setValue(data);
  };

  const onCloseHandler = () => {
    if (onClose) {
      onClose(currentValue);
    }
  };

  return (
    <Popover
      disableAutoFocus
      disableEnforceFocus
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onCloseHandler}
      style={{ maxHeight: 155 }}
    >
      <div className="time-picker-popover">
        {timeIntervals.map((i) => (
          <div
            ref={timeIntervalsRefs[i.id]}
            key={i.value.toISO()}
            className={classNames('time-picker-popover__item', {
              'time-picker-popover__item--disabled': i.isDisabled,
              'time-picker-popover__item--selected': i.isActive,
            })}
            onClick={() => onChangeHandler(i.value)}
          >
            {i.value.toFormat('HH:mm')}
          </div>
        ))}
      </div>
    </Popover>
  );
};
