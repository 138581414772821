import React, { useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import { DateTime } from 'luxon';
import { TableInstance } from 'react-table';

import { MarketType } from 'types';
import {
  PFAnalysisTypesRaw,
  PFAnalysisTypes,
  PFReportDetails,
  PFReportDownloadDetails,
} from '../../PowerFlowAnalysis.types';

import ReportFilters from 'routes/Program/routes/Reports/components/ReportFilters';
import Table from 'components/Table';
import IconButton from 'components/IconButton';
import LoadingSpinner from 'components/LoadingSpinner';

import './PFATable.scss';

export type PFATableColumns =
  | 'zone'
  | 'marketType'
  | 'analysisPeriod'
  | 'analysisType'
  | 'actions';

interface PFATableParams {
  data: PFReportDetails[];
  showColumns: PFATableColumns[];
  downloadReport: (reportsToDownload: PFReportDownloadDetails[]) => void;
  isLoading: boolean;
  setAnalysisDateRange: Dispatch<
    SetStateAction<{ startDate: DateTime; endDate: DateTime }>
  >;
}

export const PFATable = ({
  data,
  showColumns,
  downloadReport,
  isLoading,
  setAnalysisDateRange,
}: PFATableParams) => {
  function renderZone(cellInfo: any) {
    const { feeder_name } = cellInfo.row.original;
    return feeder_name;
  }

  function renderMarket(cellInfo: any) {
    const { value } = cellInfo.cell;
    let market = 'Day ahead';
    if (value === MarketType.SAMEDAY) market = 'Same day';
    return market;
  }

  function renderPeriod(cellInfo: any) {
    const { value } = cellInfo.cell;
    return DateTime.fromISO(value).toFormat('LLLL d yyyy');
  }

  function renderType(cellInfo: any) {
    const { value } = cellInfo.cell;
    let analysisType = PFAnalysisTypes.UTILIZATION;
    if (value === PFAnalysisTypesRaw.CONTRACT_SELECTION) {
      analysisType = PFAnalysisTypes.CONTRACT_SELECTION;
    }
    if (value === PFAnalysisTypesRaw.NEEDS) {
      analysisType = PFAnalysisTypes.NEEDS;
    }
    return `${analysisType} analysis`;
  }

  const renderActions = useCallback(
    (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const { feeder_name, market_type, analysis_name, analysis_time } =
        cellInfo.row.original;

      return (
        <IconButton
          icon="DownloadIcon"
          disabled={!value}
          tooltip={!value ? 'Report file location missing' : undefined}
          onClick={() =>
            downloadReport([
              {
                report_location: value,
                feeder_name,
                market_type,
                analysis_name,
                analysis_time,
              },
            ])
          }
        />
      );
    },
    [downloadReport]
  );

  const columns = useMemo(() => {
    const availableColumns = {
      zone: {
        Header: 'Zone',
        accessor: 'feeder_id',
        Cell: renderZone,
      },
      marketType: {
        Header: 'Market Type',
        accessor: 'market_type',
        Cell: renderMarket,
      },
      analysisPeriod: {
        Header: 'Analysis period',
        accessor: 'analysis_time',
        Cell: renderPeriod,
      },
      analysisType: {
        Header: 'Analysis type',
        accessor: 'analysis_name',
        Cell: renderType,
      },
      actions: {
        Header: ' ',
        accessor: 'report_location',
        Cell: renderActions,
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [renderActions, showColumns]);

  function renderFilters(instance: TableInstance) {
    return (
      <ReportFilters
        downloadPFAReports={downloadReport}
        instance={instance}
        setAnalysisDateRange={setAnalysisDateRange}
        data={data}
      />
    );
  }

  const placeHolderRow = () => {
    return isLoading ? (
      <LoadingSpinner />
    ) : (
      <div className="p-f-a-table__row">
        <div
          className="p-f-a-table__cell"
          style={{ gridColumn: '1 / 5', textAlign: 'center' }}
        >
          No reports available
        </div>
      </div>
    );
  };

  const TableStyles = {
    tableClass: 'p-f-a-table__table',
    tableHeaderRowClass: 'p-f-a-table__header',
    tableHeaderCellClass: 'p-f-a-table__header-cell',
    tableBodyRowClass: 'p-f-a-table__row',
    tableBodyCellClass: 'p-f-a-table__cell',
  };

  return (
    <Table
      disableSort
      data={data}
      columns={columns}
      customClasses={TableStyles}
      placeHolderRow={placeHolderRow}
      header={renderFilters}
    />
  );
};
