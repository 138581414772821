import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { serviceType, serviceTypeRaw } from 'types/contract-managment';
import { useParams } from 'react-router-dom';
import {
  useRequestEffect,
  useRequest,
} from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';

export type NeedsAnalysisType = {
  publish_request_after_hours: number;
  window_start_time: string;
  market_type: string;
  feeder_enrollments: string[];
  saturday: boolean;
  service_type: string;
  thursday: boolean;
  wednesday: boolean;
  close_request_after_hours: number;
  is_active: boolean;
  sunday: boolean;
  analysis_day: string;
  window_end_time: string;
  include_pending_contracts: boolean;
  monday: boolean;
  analysis_time: string;
  program_id: string;
  friday: boolean;
  procurement_volume: number;
  id: string;
  tuesday: boolean;
  include_accepted_contracts: boolean;
  publish_request_day: string;
  publish_request_time: string;
  close_request_date: DateTime | undefined;
  close_after_type: string;
};

export type TableData = {
  peerToNetwork: string;
  weekAhead: number;
  dayAhead: number;
  serviceType: string;
};

export enum MarketType {
  weekAhead = 'WEEKAHEAD',
  dayAhead = 'DAYAHEAD',
}

export type columns = 'peerToNetwork' | 'weekAhead' | 'dayAhead' | 'actions';

export const showColumns: columns[] = [
  'peerToNetwork',
  'weekAhead',
  'dayAhead',
  'actions',
];

export const placeHolderRow = (loading: boolean) => {
  return (
    <div className="needs-analysis-table__row">
      <div
        className="needs-analysis-tables__cell needs-analysis__placeholder-text"
        style={{ gridColumn: '1 / 5', textAlign: 'center' }}
      >
        {!loading && 'There is no data to display.'}
      </div>
    </div>
  );
};

const defaultDataTable: TableData[] = [
  {
    peerToNetwork: serviceType.SustainPeakManagement,
    serviceType: serviceTypeRaw.SustainPeakManagement,
    weekAhead: 0,
    dayAhead: 0,
  },
  {
    peerToNetwork: serviceType.SecurePeakManagement,
    serviceType: serviceTypeRaw.SecurePeakManagement,
    weekAhead: 0,
    dayAhead: 0,
  },
  {
    peerToNetwork: serviceType.SustainExportManagement,
    serviceType: serviceTypeRaw.SustainExportManagement,
    weekAhead: 0,
    dayAhead: 0,
  },
  {
    peerToNetwork: serviceType.DynamicPeakManagement,
    serviceType: serviceTypeRaw.DynamicPeakManagement,
    weekAhead: 0,
    dayAhead: 0,
  },
];

const transformNeedsAnalysisData = (data: NeedsAnalysisType[]) =>
  defaultDataTable.map((def: TableData) => {
    const weekAhead = data.find(
      (result: NeedsAnalysisType) =>
        result.market_type === MarketType.weekAhead &&
        result.service_type === def.serviceType
    );
    const dayAhead = data.find(
      (result: NeedsAnalysisType) =>
        result.market_type === MarketType.dayAhead &&
        result.service_type === def.serviceType
    );
    data.forEach((d: NeedsAnalysisType) => {
      if (d.service_type === def.serviceType) {
        def = {
          ...def,
          weekAhead: (weekAhead && weekAhead.procurement_volume) || 0,
          dayAhead: (dayAhead && dayAhead.procurement_volume) || 0,
        };
      }
    });
    return def;
  });

export const useNeedsAnalysis = (refetchTrigger: boolean) => {
  const { programID } = useParams<{ programID: string }>();
  const [data, setData] = useState<NeedsAnalysisType[] | undefined>([]);

  const { loading } = useRequestEffect({
    url: `/api/dsp/program/${programID}/recurring_needs_analysis`,
    method: 'get',
    refetchOnChange: [programID, refetchTrigger],
    blockRequest: () => programID === undefined,
    onSuccess: (data: NeedsAnalysisType[] | undefined) => setData(data),
    toast: {
      error: 'Could not load recurring analysis settings.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  return { loading, data };
};

export const useNeedsAnalysisOverview = () => {
  const { loading, data } = useNeedsAnalysis(false);
  const [dataService, setDataService] = useState<TableData[]>([]);

  useMemo(() => {
    return data
      ? setDataService(transformNeedsAnalysisData(data))
      : setDataService(defaultDataTable);
  }, [data]);

  return { loading, dataService };
};

export const useSubmitNeedsAnalysis = (analysis_id?: string) => {
  const { addToast } = useToasts();
  const { programID } = useParams<{ programID: string }>();
  const isNewAnalysis = !analysis_id || !(analysis_id.length > 0);

  const { makeRequest: submitRequest } = useRequest(
    `/api/dsp/program/${programID}/recurring_needs_analysis`
  );

  const { makeRequest: updateRequest } = useRequest(
    `/api/dsp/program/${programID}/recurring_needs_analysis/${analysis_id}`
  );

  const onNeedsAnalysisSubmit = (
    formData: NeedsAnalysisType,
    serviceType: string,
    setSaving: React.Dispatch<React.SetStateAction<boolean>>,
    refetchData: () => void,
    isAnalysisActive: boolean,
    reloadData: () => void,
    setChangesWereMade: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const bodyData = {
      program_id: programID,
      market_type: formData.market_type,
      service_type: serviceType,
      is_active: isAnalysisActive,
      feeder_enrollments: formData?.feeder_enrollments,
      procurement_volume: formData?.procurement_volume,
      include_accepted_contracts: formData?.include_accepted_contracts || false,
      include_pending_contracts: formData?.include_pending_contracts || false,
      analysis_day: formData?.analysis_day,
      publish_request_after_hours: formData?.publish_request_after_hours,
      close_request_after_hours: formData?.close_request_after_hours,
      analysis_time: formData?.analysis_time,
      window_start_time: formData?.window_start_time,
      window_end_time: formData?.window_end_time,
      monday: formData?.monday || false,
      tuesday: formData?.tuesday || false,
      wednesday: formData?.wednesday || false,
      thursday: formData?.thursday || false,
      friday: formData?.friday || false,
      saturday: formData?.saturday || false,
      sunday: formData?.sunday || false,
    };

    const marketTypeInHumanForm =
      (formData.market_type as MarketType) === MarketType.weekAhead
        ? 'Week ahead'
        : 'Day ahead';
    const serviceTypeInHumanForm = defaultDataTable.find(
      (val) => val.serviceType === (serviceType as serviceTypeRaw)
    )?.peerToNetwork;
    const requestOptions = {
      onSuccess: (data: any) => {
        addToast(
          `${marketTypeInHumanForm} ${serviceTypeInHumanForm} needs analysis ${
            isNewAnalysis ? 'saved' : 'updated'
          }.`,
          {
            appearance: 'success',
          }
        );
        setSaving(false);
        setChangesWereMade(false);
        refetchData();
        reloadData();
      },
      onError: (error: any) => {
        addToast(
          error?.response?.data.message ||
            `Error ${
              isNewAnalysis ? 'creating' : 'updating'
            } needs analysis. Please check that all fields are correct.`,
          { appearance: 'error', autoDismiss: true }
        );
        setSaving(false);
      },
    };

    if (!isNewAnalysis) {
      return updateRequest({
        method: 'patch',
        body: bodyData,
        ...requestOptions,
      });
    }

    submitRequest({
      method: 'post',
      body: bodyData,
      ...requestOptions,
    });
  };

  return { onNeedsAnalysisSubmit };
};
