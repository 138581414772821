import React from 'react';
import { DateTime } from 'luxon';

import { useProgramsContext } from 'contexts/ProgramsContext';

import {
  flexItemStatus,
  flexRequest,
  flexResponse,
  flexType,
  peakType,
  primaryData,
  SelectDays,
  serviceType,
  ServiceWindow,
} from 'types/contract-managment';
import { DERType, DERIcons } from 'types/der';
import { Feeder } from 'types/feeder';

import {
  getServiceTimeRangeDetails,
  getRemainingTime,
  isMidnight,
} from 'helpers/time';
import quantityConverter from 'helpers/quantityConverter';

import Avatar from 'components/Avatar';
import SVG from 'components/SVG';
import Tag from 'components/Tag';
import BidOfferTag from 'components/BidOfferTag';

import { getServiceHelperText } from 'routes/LongTermContracts/helpers/dataProcessers';

import './ViewFlexAuctionInfo.scss';

interface Props {
  type: 'request' | 'response';
  data: flexRequest | flexResponse;
  availabilityFormatted: string;
  utilizationFormatted: string;
  includeUser?: boolean;
}

const ViewFlexAuctionInfo: React.FC<Props> = ({
  type,
  data,
  availabilityFormatted,
  utilizationFormatted,
  includeUser = false,
}) => {
  const { feeders } = useProgramsContext();
  const tag = { tagClass: 'view-flex-auction-info__font' };
  const pill = { tagClass: 'view-flex-auction-info__pill' };
  const infoIcon = {
    tagClass: 'view-flex-auction-info__font',
    infoIconClass: 'view-flex-auction-info__info-icon',
  };

  function renderPerson(
    image: string | undefined,
    name: string,
    showTime = false,
    timeRemaining?: string
  ) {
    return (
      <div className="view-flex-auction-info__profile">
        <Avatar src={image} imageClass="view-flex-auction-info__avatar" />
        <div className="view-flex-auction-info__name">{name}</div>
        <div className="view-flex-auction-info__time">
          {showTime &&
            (timeRemaining
              ? `Request closing in ${timeRemaining}`
              : 'Request expired')}
        </div>
      </div>
    );
  }

  function renderZone(feederId: string, feeders: Feeder[]) {
    const feeder = feeders.find((feeder) => feeder.id === feederId);

    return (
      <span className="view-flex-auction-info__text view-flex-auction-info--no-border">
        {feeder?.name}
      </span>
    );
  }

  function renderPrimary(primary: primaryData | undefined) {
    return (
      <span className="view-flex-auction-info__text view-flex-auction-info--no-border">
        {primary?.name}
      </span>
    );
  }

  function renderType(type: flexType, status: flexItemStatus) {
    return <BidOfferTag classNames={tag} type={type} status={status} />;
  }

  function renderServiceType(service: serviceType) {
    return (
      <span className="view-flex-auction-info__text view-flex-auction-info--no-border">
        {service}
      </span>
    );
  }

  function renderQuantity(quantity: number) {
    const isPos = quantity >= 0 ? 'pos' : 'neg';
    const sign = isPos ? '+' : '-';

    return (
      <Tag
        content={`${sign}${quantityConverter(quantity) || 0} kW`}
        customClasses={pill}
        leftElement={
          <SVG
            fontSize="1rem"
            icon="bolt"
            className={`view-flex-auction-info--${isPos}`}
          />
        }
        round
      />
    );
  }

  function renderTextPill(text: string, units?: string) {
    return <Tag content={`${text}${units}`} round customClasses={pill} />;
  }

  function renderDateRange(
    startTime: DateTime,
    endTime: DateTime,
    duration: string
  ) {
    const endDisplay = endTime.minus({
      days: isMidnight(endTime) ? 1 : 0,
    });
    return (
      <span className="view-flex-auction-info__time-range">
        <Tag
          content={
            <>
              <span>{startTime?.toFormat('LLL dd, yyyy')}</span>
              <span style={{ marginLeft: '20px' }}>
                {endDisplay?.toFormat('LLL dd, yyyy')}
              </span>
            </>
          }
          customClasses={tag}
          round
        />
        <div className="view-flex-auction-info__range">{duration}</div>
      </span>
    );
  }

  function renderTimeRange(
    peak: peakType,
    customDays: SelectDays | null,
    serviceWindows: ServiceWindow[]
  ) {
    const { days, startTime, endTime, timeDuration } =
      getServiceTimeRangeDetails(
        peak,
        customDays,
        serviceWindows[0].startTime,
        serviceWindows[0].endTime
      );

    return (
      <span className="view-flex-auction-info__row">
        <Tag
          content={<span>{peak}</span>}
          info={getServiceHelperText(peak)}
          round
          customClasses={infoIcon}
        />
        <div
          className="view-flex-auction-info__time-range"
          style={{ marginLeft: '14px' }}
        >
          <span className="view-flex-auction-info__text">
            {days.join(' | ')}
          </span>
          <span
            style={{ justifySelf: 'start' }}
            className="view-flex-auction-info__text--light"
          >
            {`${days.length}/7 days`}
          </span>
          <span className="view-flex-auction-info__text">
            {`${startTime === '24:00' ? '00:00' : startTime} - ${
              endTime === '00:00' ? '24:00' : endTime
            }`}
          </span>
          <span
            style={{ justifySelf: 'start' }}
            className="view-flex-auction-info__text--light"
          >
            {timeDuration}
          </span>
        </div>
      </span>
    );
  }

  function renderAssetType(asset: DERType, name?: string) {
    return (
      <span className="view-flex-auction-info__row">
        <SVG icon={`${DERIcons[asset]}`} fontSize="18px" />
        <span
          style={{ margin: '3px 0 0 6px' }}
          className="view-flex-auction-info__text"
        >
          {name ? name : asset}
        </span>
      </span>
    );
  }

  function generateRequest(data: flexRequest) {
    const {
      flex,
      status,
      service,
      quantity,
      duration,
      requestorName,
      // requestorImage,
      peak,
      expiryDate,
      startTime,
      endTime,
      customPeakDays,
      serviceWindows,
      feederId,
      primary,
    } = data;

    const timeDiff = getRemainingTime(expiryDate, {
      days: ' days',
      hours: ' hours',
      minutes: ' minutes',
      seconds: ' seconds',
      expired: '',
    });

    return (
      <>
        {includeUser && (
          <>
            <div className="view-flex-auction-info__label">Requestor</div>
            {renderPerson(undefined, requestorName, true, timeDiff)}
          </>
        )}
        <div className="view-flex-auction-info__label">Zone</div>
        {renderZone(feederId, feeders)}
        <div className="view-flex-auction-info__label">Primary</div>
        {renderPrimary(primary)}
        <div className="view-flex-auction-info__label">Type</div>
        {renderType(flex, status)}
        <div className="view-flex-auction-info__label">Service Type</div>
        {renderServiceType(service)}
        <div className="view-flex-auction-info__label">Quantity</div>
        {renderQuantity(quantity)}
        <div className="view-flex-auction-info__label">Availability</div>
        {renderTextPill(availabilityFormatted, '/kW/h')}
        <div className="view-flex-auction-info__label">Utilization</div>
        {renderTextPill(utilizationFormatted, '/kWh')}
        <div className="view-flex-auction-info__label">Date Range</div>
        {renderDateRange(startTime, endTime, duration)}
        <div
          style={{ justifySelf: 'right', marginTop: '4px' }}
          className="view-flex-auction-info__text--light"
        >
          Time Range
        </div>
        {renderTimeRange(peak, customPeakDays, serviceWindows)}
      </>
    );
  }

  function generateResponse(data: flexResponse) {
    const { quantity, responderName, derType, derName } = data;
    return (
      <>
        <div className="view-flex-auction-info__label">Responder</div>
        {renderPerson(undefined, responderName)}
        <div className="view-flex-auction-info__label">Quantity</div>
        {renderQuantity(quantity)}
        <div className="view-flex-auction-info__label">Availability</div>
        {renderTextPill(availabilityFormatted, '/kW/h')}
        <div className="view-flex-auction-info__label">Utilization</div>
        {renderTextPill(utilizationFormatted, '/kWh')}
        <div className="view-flex-auction-info__label">Asset Type</div>
        {renderAssetType(derType, derName)}
      </>
    );
  }

  return (
    <div className="view-flex-auction-info">
      {type === 'request' && generateRequest(data as flexRequest)}
      {type === 'response' && generateResponse(data as flexResponse)}
    </div>
  );
};

export default ViewFlexAuctionInfo;
