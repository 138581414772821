import React, { useCallback, useEffect } from 'react';
import PopoverMUI, { PopoverOrigin } from '@mui/material/Popover';

interface PopoverProps {
  title: React.ReactNode | string;
  content: React.ReactNode;
  type?: 'default' | 'hover';
  transformOrigin?: PopoverOrigin;
  anchorOrigin?: PopoverOrigin;
  cancelTrigger?: boolean;
  onClose?: () => void;
}

const Popover = ({
  type = 'default',
  title,
  content,
  transformOrigin,
  anchorOrigin,
  cancelTrigger,
  onClose,
}: PopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const transformOriginProp = transformOrigin || {
    vertical: 'top',
    horizontal: 'left',
  };

  const anchorOriginProp = anchorOrigin || {
    vertical: 'bottom',
    horizontal: 'left',
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    handlePopoverClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelTrigger]);

  const open = Boolean(anchorEl);

  const typesList = {
    default: {
      onClick: handlePopoverOpen,
      onClose: handlePopoverClose,
    },
    hover: {
      onMouseEnter: handlePopoverOpen,
      onMouseLeave: handlePopoverClose,
    },
  };

  return (
    <>
      <span
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        {...typesList[type]}
      >
        {title}
      </span>
      <PopoverMUI
        id="mouse-over-popover"
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOriginProp}
        transformOrigin={transformOriginProp}
        onClose={handlePopoverClose}
        sx={
          type === 'hover'
            ? {
                pointerEvents: 'none',
              }
            : null
        }
      >
        {content}
      </PopoverMUI>
    </>
  );
};

export default Popover;
