import React from 'react';
import classes from 'classnames';

import SVG from 'components/SVG';

import './DialogSection.scss';

interface DialogSectionProps {
  title: string;
  icon: string;
  isLast?: boolean;
  classNames?: {
    sectionClass?: string;
    headingClass?: string;
    titleClass?: string;
    iconClass?: string;
    containerClass?: string;
    panelClass?: string;
  };
  children: React.ReactNode;
}

function DialogSection({
  title,
  icon,
  isLast = false,
  classNames = {},
  children,
}: DialogSectionProps) {
  const {
    sectionClass,
    headingClass,
    titleClass,
    iconClass,
    containerClass,
    panelClass,
  } = classNames;

  return (
    <div className={classes('dialog-section', sectionClass)}>
      <div className={classes('dialog-section__heading', headingClass)}>
        <h2 className={classes('dialog-section__title', titleClass)}>
          {title}
        </h2>
        <span
          className={classes('dialog-section__icon-container', containerClass)}
        >
          <SVG
            icon={icon}
            className={classes('dialog-section__icon', iconClass)}
          />
        </span>
      </div>
      <div
        className={classes(
          'dialog-section__panel',
          { 'dialog-section__panel--last': isLast },
          panelClass
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default DialogSection;
