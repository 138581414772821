import { flexContract, flexItemStatus } from 'types/contract-managment';

export function sortContractData(data: flexContract[]): flexContract[] {
  type sortOrderType = {
    [key in keyof typeof flexItemStatus]: {
      order: number;
      section: flexContract[];
    };
  };

  type sortedSectionsType = Record<string, flexContract[]>;

  const statusSortOrder: sortOrderType = {
    [flexItemStatus.processing]: { order: 1, section: [] },
    [flexItemStatus.pending]: { order: 2, section: [] },
    [flexItemStatus.accepted]: { order: 3, section: [] },
    [flexItemStatus.rejected]: { order: 4, section: [] },
    [flexItemStatus.expired]: { order: 5, section: [] },
    [flexItemStatus.cancelled]: { order: 6, section: [] },
  };

  // sort by status
  data.sort((a, b): number => {
    const statusA = statusSortOrder[a.status].order;
    const statusB = statusSortOrder[b.status].order;
    return statusA - statusB;
  });

  // slice into status sections
  let sectionStart = 0;
  let currentSection: flexItemStatus | undefined = undefined;
  data.forEach((contract, index) => {
    // first element condition
    if (currentSection === undefined) {
      currentSection = contract.status;
    }
    // get the section
    if (contract.status !== currentSection || data.length - 1 === index) {
      // on the last item
      if (data.length - 1 === index) {
        if (contract.status !== currentSection) {
          // 2 slices if the last item is no the same status as the previous
          statusSortOrder[currentSection].section = data.slice(
            sectionStart,
            index
          );
          statusSortOrder[contract.status].section = data.slice(index);
        } else {
          // 1 slice if last item status is same as previous
          statusSortOrder[contract.status].section = data.slice(sectionStart);
        }
      } else {
        statusSortOrder[currentSection].section = data.slice(
          sectionStart,
          index
        );
      }
      // update start and status values
      currentSection = contract.status;
      sectionStart = index;
    }
  });

  // sort sections by service period.
  Object.values(flexItemStatus).forEach((status) => {
    statusSortOrder[status].section.sort(
      (a: flexContract, b: flexContract): number => {
        const aStart = a.request.startTime.toMillis();
        const aEnd = a.request.endTime.toMillis();
        const bStart = b.request.startTime.toMillis();
        const bEnd = b.request.endTime.toMillis();
        if (aStart !== bStart) {
          return aStart - bStart;
        }
        if (aEnd !== bEnd) {
          return aEnd - bEnd;
        }
        return 0;
      }
    );
  });

  // combine sections into data set.
  const sortedSections: sortedSectionsType = {};
  // get the arrays in the right order
  Object.values(statusSortOrder).forEach((statusOrder) => {
    sortedSections[statusOrder.order] = statusOrder.section;
  });

  const sectionOrders = Object.keys(sortedSections)
    .map((key: string) => parseInt(key))
    .sort();

  let sortedData: flexContract[] = [];
  sectionOrders.forEach((sectionKey) => {
    sortedData = sortedData.concat(sortedSections[sectionKey]);
  });

  return sortedData;
}
