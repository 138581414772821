import React from 'react';
import {
  default as TextFieldMUI,
  TextFieldProps,
} from '@mui/material/TextField';
import './TextField.scss';

const TextField = ({ ...props }: TextFieldProps) => {
  return <TextFieldMUI {...props} classes={{ root: 'text-field' }} />;
};

export default TextField;
