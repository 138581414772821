import React from 'react';

import PageTitle from 'components/PageTitle';
import LoadingSpinner from 'components/LoadingSpinner';

import NeedsAnalysisTable from 'routes/Program/routes/Settings/routes/NeedsAnalysis/NeedsAnalysisTable';
import {
  showColumns,
  placeHolderRow,
  useNeedsAnalysisOverview,
} from './NeedsAnalysis.logic';

import './NeedsAnalysis.scss';

export const NeedsAnalysis = () => {
  const { loading, dataService } = useNeedsAnalysisOverview();

  return (
    <div className="needs-analysis">
      <PageTitle
        title="Needs analysis"
        classes={{ titleClass: 'needs-analysis__title' }}
      ></PageTitle>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <NeedsAnalysisTable
          data={dataService}
          placeHolderRow={() => placeHolderRow(loading)}
          showColumns={showColumns}
        />
      )}
    </div>
  );
};
