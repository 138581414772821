import React, { ReactNode } from 'react';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import DialogHeader from 'components/Dialog/DialogHeader';
import DialogFooter from 'components/Dialog/DialogFooter';
import DialogBody from 'components/Dialog/DialogBody';

import './ConfirmationDialog.scss';

export type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  body: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  cancelText?: ReactNode;
  confirmText?: ReactNode;
  type?: 'primary' | 'error';
};

export function ConfirmationDialog({
  open,
  title,
  body,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  onClose,
  onConfirm,
  type = 'error',
}: ConfirmationDialogProps) {
  return (
    <Dialog
      open={open}
      type={type}
      progress={100}
      onClose={onClose}
      classNames={{ contentClass: 'confirmation-dialog' }}
    >
      <DialogHeader title={title} onClose={onClose} />
      <DialogBody className="confirmation-dialog__body" padding="left-aligned">
        {body}
      </DialogBody>
      <DialogFooter>
        <Button
          variant="outlined"
          onClick={onClose}
          customClasses={{
            customButtonClass: 'confirmation-dialog__action',
          }}
        >
          {cancelText}
        </Button>
        <Button
          customClasses={{
            customButtonClass: 'confirmation-dialog__action',
          }}
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
