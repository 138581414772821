import React from 'react';

type FileSingleProps = {
  name: string;
};

export const FileSingle = ({ name }: FileSingleProps) => (
  <span key={name} className="baseline-import-form__file-single">
    {name}
    <br />
  </span>
);
