import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import {
  useAuthContext,
  ProtectedRoute,
} from '@opusonesolutions/gridos-app-framework';

import { useProgramsContext } from 'contexts/ProgramsContext';

import { env } from 'helpers/env';

import ErrorPage from 'components/ErrorPage';
import Header from 'components/Header';
import Navigation from 'components/Navigation/Navigation';
import TocModal from 'components/TocModal';

import Admin from 'routes/Admin';
import CreateProgram from 'routes/CreateProgram';
import DERs from 'routes/DERs';
import Home from 'routes/Home';
import ISOs from 'routes/ISOs';
import Login from 'routes/Login';
import Measurements from 'routes/Measurements';
// import Participants from 'routes/Participants';
import { ParticipantsAndDers } from 'routes/ParticipantsAndDers';
import Program from 'routes/Program';
import Settlement from 'routes/Settlement';

import './App.scss';

function App() {
  const { isLoading, isAuthenticated, hasAllPermissions } = useAuthContext();
  const { setCurrentProgram } = useProgramsContext();
  // If the program ID is in the URL, init it into the state
  // useRouteMatch returns null if no match
  const loginMatch = useRouteMatch('/login');
  const programMatch = useRouteMatch<{ programID: string }>(
    '/program/:programID'
  );
  const [programId, setProgramId] = useState(
    programMatch?.params?.programID || null
  );

  const history = useHistory();

  const enableCreateUtilityPrograms = hasAllPermissions([
    'create_utility_programs',
  ]);
  const enableISOPage = hasAllPermissions(['create_iso']);

  // Add listeners to catch errors in functions
  useEffect(() => {
    window.addEventListener('error', (event) => {
      history.push('/500');
    });
    window.addEventListener('unhandledrejection', (event) => {
      history.push('/500');
    });
  }, [history]);

  useEffect(() => {
    if (programMatch !== null) {
      // @ts-ignore
      const { programID } = programMatch.params;
      setCurrentProgram(programID);
      setProgramId(programID);
    } else {
      setCurrentProgram(null);
      setProgramId(null);
    }
  }, [programMatch, setCurrentProgram]);

  return (
    <div className="te">
      <Header
        onLoginPage={loginMatch !== null}
        programId={programId}
        setSelectedProgram={(newProgramId) => {
          setProgramId(newProgramId);

          if (newProgramId === null) {
            history.push('/');
          } else {
            // Keep relative paths when switching programs
            let newPath = `/program/${newProgramId}`;

            if (history.location.pathname.includes('/program/')) {
              // This happens when we're on a route like /program/0/settings. Want to retain
              // the subpath to a new program
              newPath = history.location.pathname.replace(
                `/program/${programId}`,
                newPath
              );
              // Want to strip off any program specific subroutes
              // (i.e. /program/:programId/containers/:feederId -> /program/:programId/containers)
              // @ts-ignore
              newPath = newPath.match(/\/program\/([^/]+\/?){1,2}/)[0];
            }
            history.push(newPath);
          }
        }}
      />
      <div className="container">
        <TocModal />
        {loginMatch === null && !isLoading && isAuthenticated && <Navigation />}
        <Switch>
          <ProtectedRoute exact path="/">
            <Home />
          </ProtectedRoute>
          {/*<ProtectedRoute path="/participants">
              <Participants />
            </ProtectedRoute>*/}
          {env.isNMF && (
            <ProtectedRoute path="/participants-and-ders">
              <ParticipantsAndDers />
            </ProtectedRoute>
          )}
          <ProtectedRoute path="/ders">
            <DERs />
          </ProtectedRoute>
          {!env.isNMF && enableISOPage && (
            <ProtectedRoute path="/isos">
              <ISOs />
            </ProtectedRoute>
          )}
          <Route path="/login">
            <Login />
          </Route>
          <ProtectedRoute path="/measurements">
            <Measurements />
          </ProtectedRoute>
          <ProtectedRoute path="/program/:programID">
            <Program />
          </ProtectedRoute>
          {enableCreateUtilityPrograms && (
            <ProtectedRoute path="/create-program">
              <CreateProgram />
            </ProtectedRoute>
          )}
          <ProtectedRoute path="/admin">
            <Admin />
          </ProtectedRoute>
          <ProtectedRoute>
            <Settlement />
          </ProtectedRoute>
          <ProtectedRoute exact path="/ninja">
            <ErrorPage type="ninja" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/500">
            <ErrorPage type="500" />
          </ProtectedRoute>
          <ProtectedRoute>
            <ErrorPage type="404" />
          </ProtectedRoute>
        </Switch>
      </div>
    </div>
  );
}

export default App;
