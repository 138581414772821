import React from 'react';

import {
  ContractModelOptions,
  serviceType as serviceTypeList,
  generationMethodRaw,
} from 'types/contract-managment';

// eslint-disable-next-line custom-rules/deprecated-component
import Grid from 'components/Grid';
// eslint-disable-next-line custom-rules/deprecated-component
import Divider from 'components/Divider';
import DialogSection from 'components/Dialog/DialogSection';
import { TimeRange } from './components/TimeRange';

import { processPeakType } from './CreateRequestReview.logic';
import { CreateRequestReviewProps } from './CreateRequestReview.types';

import './CreateRequestReview.scss';

function CreateRequestReview({
  data,
  currencySymbol,
}: CreateRequestReviewProps) {
  const {
    openDate,
    closeDate,
    contractModel,
    feeder,
    primary,
    startDate,
    endDate,
    serviceWindow,
    serviceType,
    bidOffer,
    availabilityPrice,
    utilizationPrice,
    quantityGeneration,
    quantity,
    requestQuantity,
    hours,
    recurrence,
    distributedResource,
    serviceDays,
  } = data;

  const isManual = quantityGeneration === generationMethodRaw.manual;
  const isSystemCalculated =
    quantityGeneration === generationMethodRaw.systemGenerated;
  const isForecastBased =
    quantityGeneration === generationMethodRaw.forecastBased;

  return (
    <>
      <DialogSection
        classNames={{ headingClass: 'create-request-service__section-title' }}
        title="Request setup"
        icon="RefreshCWIcon"
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="create-request-review__label">Recurrence</div>
            <div className="create-request-review__value">{recurrence}</div>
          </Grid>

          <Grid item xs={4} className="create-request-review__item">
            <div className="create-request-review__label">
              Create request on
            </div>
            <div className="create-request-review__value">
              {openDate?.toLocaleString({
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hourCycle: 'h23',
              })}
            </div>
          </Grid>
          <Grid item xs={4} className="create-request-review__item">
            <div className="create-request-review__label">Close request on</div>
            <div className="create-request-review__value">
              {closeDate?.toLocaleString({
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hourCycle: 'h23',
              })}
            </div>
          </Grid>
        </Grid>
      </DialogSection>
      <DialogSection
        classNames={{ headingClass: 'create-request-service__section-title' }}
        title="Service details"
        icon="ToolIcon"
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">
                Participant selection
              </div>
              <div className="create-request-review__value">
                {contractModel === ContractModelOptions.Pool
                  ? 'Pool'
                  : 'Direct'}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">Zone</div>
              <div className="create-request-review__value">
                {feeder?.label}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">Primary</div>
              <div className="create-request-review__value">
                {primary?.label}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">Service type</div>
              <div className="create-request-review__value">
                {serviceType?.label}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">Service window</div>
              <div className="create-request-review__value">
                {processPeakType(serviceWindow)}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">Service period</div>
              <div className="create-request-review__value">
                {`${startDate?.toLocaleString({
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                })} - ${endDate?.toLocaleString({
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                })}`}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">Service days</div>
              <div className="create-request-review__value">
                {serviceDays && (
                  <TimeRange
                    peak={processPeakType(serviceWindow)}
                    customDays={serviceDays}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="create-request-review__item">
              <div className="create-request-review__label">Request type</div>
              <div className="create-request-review__value">{bidOffer}</div>
            </div>
          </Grid>
          {distributedResource?.label && (
            <Grid item xs={4}>
              <div className="create-request-review__item">
                <div className="create-request-review__label">
                  Distributed energy resource
                </div>
                <div className="create-request-review__value">
                  {distributedResource?.label}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </DialogSection>
      <DialogSection
        classNames={{ headingClass: 'create-request-service__section-title' }}
        title="Price and quantity"
        icon="ClipboardIcon"
      >
        <Grid container spacing={2}>
          <Grid item xs={4} className="create-request-review__item">
            <div className="create-request-review__label">
              Availability price ceiling
            </div>
            <div className="create-request-review__value">{`${currencySymbol}${availabilityPrice} /kW/h`}</div>
          </Grid>
          {serviceType?.label !== serviceTypeList.MEC && (
            <Grid item xs={4} className="create-request-review__item">
              <div className="create-request-review__label">
                Utilization price ceiling
              </div>
              <div className="create-request-review__value">{`${currencySymbol}${utilizationPrice} /kWh`}</div>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={4} className="create-request-review__item">
            <div className="create-request-review__label">
              Quantity generation
            </div>
            <div className="create-request-review__value">
              {isManual
                ? 'Manual input'
                : isSystemCalculated
                ? 'System generated'
                : 'Forecast based'}
            </div>
          </Grid>
          <Grid item xs={4} className="create-request-review__item">
            <div className="create-request-review__label">
              Request quantity (kW)
            </div>
            <div className="create-request-review__value">
              {isForecastBased ? '-' : `${requestQuantity || quantity} kW`}
            </div>
          </Grid>
          <Grid item xs={4} className="create-request-review__item">
            <div className="create-request-review__label">
              Quantity duration
            </div>
            <div className="create-request-review__value">
              {isForecastBased ? '-' : `${hours} hours`}
            </div>
          </Grid>
        </Grid>
      </DialogSection>
    </>
  );
}

export default CreateRequestReview;
