import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { Cell } from 'react-table';
import { DateTime } from 'luxon';

import { Program } from 'contexts/ProgramsContext';

import useLocaleFormatter from 'hooks/useLocaleFormatter';

import { SettlementSummary, SettlementTrafficLight } from 'types/settlement';

import Table from 'components/Table';
import Tag from 'components/Tag';
import SVG from 'components/SVG';
import Tooltip from 'components/Tooltip';
import Light from 'components/Light';
import UserProfile from 'components/UserProfile';
// eslint-disable-next-line custom-rules/deprecated-component
import OldCheckbox from 'components/OldCheckbox';

import './SettlementSummaryTable.scss';

export type EventsTableColumns =
  | 'industryActor'
  | 'events'
  | 'hasMeasurements'
  | 'requested'
  | 'hasBaseline'
  | 'delivered'
  | 'cost'
  | 'penalty'
  | 'finalSettlement'
  | 'status'
  | 'clickIcon';

interface EventsTableProps {
  showColumns: EventsTableColumns[];
  data: SettlementSummary[];
  program: Program;
  selectedState: {
    selectedRows: string[];
    updateSelected: React.Dispatch<React.SetStateAction<string[]>>;
  };
  hideFilters?: boolean;
  hideCheckboxes?: boolean;
  startDate: DateTime;
}

function SettlementSummaryTable({
  showColumns,
  data,
  program,
  hideFilters,
  selectedState,
  hideCheckboxes,
  startDate,
}: EventsTableProps) {
  const { currencyFormatter, longCurrencyFormatter } = useLocaleFormatter(
    program?.currency,
    program?.locale
  );
  const history = useHistory();
  const { selectedRows, updateSelected } = selectedState;

  const rowCount = data.length;

  const onSelectAll = useMemo(
    () => (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      e.stopPropagation();
      if (selectedRows.length < rowCount) {
        const tenantIDs: string[] = [];
        data.forEach((row) => tenantIDs.push(`${row.tenantID}`));
        updateSelected(tenantIDs);
      } else if (selectedRows.length === rowCount) {
        updateSelected([]);
      }
    },
    [data, rowCount, selectedRows.length, updateSelected]
  );

  const onSelectOne = useMemo(
    () => (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const triggeredRow: string = e.currentTarget.id;
      const inArray = selectedRows.indexOf(triggeredRow);
      if (inArray >= 0) {
        const firstHalf = selectedRows.slice(0, inArray);
        const secondHalf = selectedRows.slice(inArray + 1, selectedRows.length);
        updateSelected([...firstHalf, ...secondHalf]);
      } else {
        updateSelected([...selectedRows, triggeredRow]);
      }
    },
    [selectedRows, updateSelected]
  );

  const onRowClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    rowData: any
  ) => {
    history.push(
      `/program/${program.program_id}/settlement/tenant/${
        rowData.tenantID
      }?start_date=${startDate.toUTC().toISO()}`
    );
  };

  /* RENDER FUNCTIONS */
  const renderRequested = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const sign = value >= 0 ? '+' : '-';
      return (
        <Tag
          content={`${sign}${(value / 1000).toFixed(2)} kWh`}
          leftElement={
            <SVG
              className="settlement-summary-table__bolt"
              fontSize="1rem"
              icon="bolt"
            />
          }
          round
          customClasses={{
            tagClass: 'settlement-summary-table__tag--darkened',
          }}
        />
      );
    },
    []
  );

  const renderDelivered = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const requested = cellInfo.row.original.requested;
      const percentDelivered = Math.round((value / requested) * 100);
      const sign = value >= 0 ? '+' : '-';
      return (
        <span className="settlement-summary-table__delivered">
          <Tag
            content={`${sign}${(value / 1000).toFixed(2)} kWh`}
            leftElement={
              <SVG
                className="settlement-summary-table__bolt"
                fontSize="1rem"
                icon="bolt"
              />
            }
            round
            customClasses={{
              tagClass: cn('settlement-summary-table__tag--darkened', {
                'settlement-summary-table__tag--error': percentDelivered < 90,
              }),
            }}
          />
          <span className="settlement-summary-table__text--light settlement-summary-table__percent">
            {`${percentDelivered}%`}
          </span>
        </span>
      );
    },
    []
  );

  const renderPrice = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      return (
        <Tooltip
          arrow
          content={`${longCurrencyFormatter.format(value)}`}
          theme="light"
        >
          <span className="settlement-summary-table__text">
            {`${currencyFormatter.format(value)}`}
          </span>
        </Tooltip>
      );
    },
    [currencyFormatter, longCurrencyFormatter]
  );

  const renderIA = useMemo(
    () => (cellInfo: any) => {
      const { tenantID, tenantName, tenantImage } = cellInfo.row.original;

      return (
        <span className="settlement-summary-table__select">
          {!hideCheckboxes && (
            <OldCheckbox
              id={tenantID}
              onClick={onSelectOne}
              checked={selectedRows.includes(`${tenantID}`)}
            />
          )}
          <UserProfile src={tenantImage} name={tenantName} size="small" />
        </span>
      );
    },
    [hideCheckboxes, onSelectOne, selectedRows]
  );

  const renderText = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      return <span className="settlement-summary-table__text">{value}</span>;
    },
    []
  );

  const renderLight = useMemo(
    () => (cellInfo: any) => {
      const { value } = cellInfo.cell;
      const col = cellInfo.column.id;
      const style =
        col === 'baseline' ? { marginLeft: '16%' } : { marginLeft: '30%' };

      let content = (
        <Tooltip
          content={`No ${col} data has been uploaded for this timeframe.`}
        >
          <Light size="xsmall" variant="error" />
        </Tooltip>
      );

      if (SettlementTrafficLight.GREEN === value) {
        content = (
          <Tooltip
            content={`All ${col} data has been uploaded for this timeframe.`}
          >
            <Light size="xsmall" variant="success" />
          </Tooltip>
        );
      }

      if (SettlementTrafficLight.ORANGE === value) {
        content = (
          <Tooltip
            content={`Partial ${col} data has been uploaded for this timeframe.`}
          >
            <Light size="xsmall" variant="warning" />
          </Tooltip>
        );
      }

      return <div style={style}>{content}</div>;
    },
    []
  );

  const renderClickIcon = useMemo(
    () => () => {
      return (
        <div className="settlement-summary-table__center">
          <SVG
            fontSize="1rem"
            icon="arrow_right"
            styles={{ marginLeft: '20px', color: 'black' }}
          />
        </div>
      );
    },
    []
  );

  /**
   * First part of this function defines all the possible columns and their logic.
   * Second part handles figuring out what columns to render.
   */
  const columns = useMemo(() => {
    const availableColumns = {
      industryActor: {
        Header: (
          <div>
            {!hideCheckboxes && (
              <OldCheckbox
                id="settlement-summary-all"
                onClick={onSelectAll}
                dash={selectedRows.length < rowCount && selectedRows.length > 0}
                checked={
                  selectedRows.length <= rowCount && selectedRows.length > 0
                }
              />
            )}
            <span>Industry Actor</span>
          </div>
        ),
        accessor: 'tenantName',
        Cell: renderIA,
      },
      events: {
        Header: 'Events',
        accessor: 'events',
        Cell: renderText,
      },
      hasMeasurements: {
        Header: 'Measurement',
        accessor: 'measurement',
        class: 80,
        Cell: renderLight,
      },
      requested: {
        Header: 'Requested',
        accessor: 'requested',
        Cell: renderRequested,
      },
      hasBaseline: {
        Header: 'Baseline',
        accessor: 'baseline',
        Cell: renderLight,
      },
      delivered: {
        Header: 'Delivered',
        accessor: 'delivered',
        Cell: renderDelivered,
      },
      cost: {
        Header: 'Cost',
        accessor: 'cost',
        Cell: renderPrice,
      },
      penalty: {
        Header: 'Penalty',
        accessor: 'penalty',
        Cell: renderPrice,
      },
      finalSettlement: {
        Header: 'Final Settlement',
        accessor: 'finalSettlement',
        Cell: renderPrice,
      },
      status: {
        Header: 'Status',
        accessor: 'settlementStatus',
        Cell: renderText,
      },
      clickIcon: {
        Header: '',
        accessor: ' ',
        Cell: renderClickIcon,
      },
    };

    const generateColumns: any[] = [];
    showColumns.forEach((accessor) => {
      if (availableColumns[accessor]) {
        generateColumns.push(availableColumns[accessor]);
      }
    });
    return generateColumns;
  }, [
    hideCheckboxes,
    onSelectAll,
    renderClickIcon,
    renderDelivered,
    renderIA,
    renderLight,
    renderPrice,
    renderRequested,
    renderText,
    rowCount,
    selectedRows.length,
    showColumns,
  ]);

  function placeHolder() {
    return (
      <tr className="settlement-summary-table__row">
        <td
          className="settlement-summarys-table__cell"
          colSpan={columns.length}
        >
          <div style={{ width: 'fit-content', margin: 'auto' }}>
            There is no data to display.
          </div>
        </td>
      </tr>
    );
  }

  /**
   * Custom function to handle cell rendering.
   * Handles the logic for coloring specific columns.
   */
  function renderCells(
    cell: Cell<any>,
    index: number,
    tableCellClass: string | undefined
  ) {
    const col: string = cell.column.id;
    const style: any = {
      hasMeasurements: { width: '90px' },
      hasBaseline: { width: '90px' },
      events: { width: '80px' },
      ' ': { width: '50px' },
    };

    return (
      <td
        {...cell.getCellProps()}
        key={index}
        className={cn(tableCellClass)}
        style={style[col]}
      >
        {cell.render('Cell')}
      </td>
    );
  }

  const TableStyles = {
    tableClass: 'settlement-summary-table',
    tableHeaderClass: 'settlement-summary-table__header',
    tableBodyClass: !hideFilters ? 'settlement-summary-table__body' : '',
    tableHeaderCellClass: 'settlement-summary-table__header-cell',
    tableBodyRowClass: 'settlement-summary-table__row',
    tableBodyCellClass: 'settlement-summary-table__cell',
  };

  return (
    <Table
      data={data}
      columns={columns}
      tableType="HTML"
      customClasses={TableStyles}
      placeHolderRow={placeHolder}
      customCellRenderFunction={renderCells}
      onRowClick={onRowClick}
      disableSort
    />
  );
}

export default SettlementSummaryTable;
