import React from 'react';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';

import SVG from 'components/SVG';
import Link from 'components/Link';
import { TenantDer } from './TenantDer';
import { ModalData } from '../BaselineImportForm.types';

type BaselineTenantsProps = {
  tenant: ModalData;
  register: UseFormRegister<FieldValues>;
  removeFilesFromDer: (id: string) => void;
  control: Control<FieldValues, any>;
};

export const BaselineTenants = ({
  tenant,
  register,
  removeFilesFromDer,
  control,
}: BaselineTenantsProps) => (
  <div className="baseline-import-form__wrapper">
    <div className="baseline-import-form__top-row">
      <div className="baseline-import-form__title">Industry actor</div>
      <div className="baseline-import-form__download">
        <Link
          href={`${process.env.PUBLIC_URL}/baseline-import-example.csv`}
          rel="noreferrer"
          download
          target="_blank"
          className="baseline-import-form__link"
        >
          <span className="baseline-import-form__form-icon-wrap">
            <SVG
              icon="DownloadIcon"
              className="baseline-import-form__form-icon-link"
            />
          </span>
          Download template file
        </Link>
      </div>
    </div>
    <div className="baseline-import-form__top-rows">
      <div className="baseline-import-form__name">{tenant.name}</div>
    </div>
    <div className="baseline-import-form__row">
      <div className="baseline-import-form__half">
        <div className="baseline-import-form__title">DERs</div>
      </div>
      <div className="baseline-import-form__half">
        <div className="baseline-import-form__title">File</div>
      </div>
    </div>
    {tenant.ders.map((der) => (
      <TenantDer
        key={der.id}
        der={der}
        register={register}
        removeFilesFromDer={removeFilesFromDer}
        control={control}
      />
    ))}
  </div>
);
