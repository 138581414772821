import { Chart } from 'chart.js';
import React, { FunctionComponent, useMemo, useRef, useState } from 'react';

import { DateTime } from 'luxon';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import ChartWrapper from 'components/ChartWrapper';
// eslint-disable-next-line custom-rules/deprecated-component
import OldDatePicker from 'components/OldDatePicker';
import FileForm from 'components/FileForm';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
// eslint-disable-next-line custom-rules/deprecated-component
import Modal from 'components/Modal';
import Select from 'components/OldSelect';

import fileExportSave from 'helpers/downloadFile';

import './LoadContribution.scss';
import 'components/Button/Button.scss';

type LoadingData = {
  timestamp: string;
  total_demand: number;
  net_loading: number;
  net_dispatch: number;
};

interface LoadContributionData {
  [key: string]: Array<LoadingData>;
}

type LoadContributionProps = {
  feederName: string;
  programID: string;
  rdf_id: string;
  timezone: string;
};

type LoadThresholdRepsonse = {
  load_threshold: number;
};

const MARKET_TYPES = [
  { label: 'Day Ahead', value: 'dayahead' },
  { label: 'Same Day', value: 'sameday' },
];

const LoadContribution: FunctionComponent<LoadContributionProps> = ({
  feederName,
  programID,
  rdf_id,
  timezone,
}) => {
  const [date, setDate] = useState(
    DateTime.local().setZone(timezone).startOf('day')
  );
  const [fileData, setFileData] = useState(new FormData());
  const [filename, setFilename] = useState('');
  const [hasData, setHasData] = useState(false);
  const [inUploadMode, setInUploadMode] = useState(false);
  const [loadThreshold, setLoadThreshold] = useState<number | null>(null);
  const [market_type, setMarketType] = useState('dayahead');
  const uploadFormRef = useRef<HTMLFormElement | null>(null);

  const {
    data: chartData,
    loading: loadingChartData,
    refetch: refetchChartData,
  } = useRequestEffect<LoadContributionData>({
    url: `/api/dsp/dashboard/program/${programID}/feeder/${rdf_id}/load_contribution`,
    method: 'get',
    refetchOnChange: [date],
    params: {
      start_time: date.toISO(),
      end_time: date.plus({ days: 1 }).startOf('day').toISO(),
    },
    toast: {
      error: 'Could not load feeder load contribution data',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const totalDemand = chartData
    ? chartData[market_type].map((loadingData) => {
        return {
          x: DateTime.fromISO(loadingData.timestamp).valueOf(),
          y: loadingData.total_demand,
        };
      })
    : [];

  const netLoading = chartData
    ? chartData[market_type].map((loadingData) => {
        return {
          x: DateTime.fromISO(loadingData.timestamp).valueOf(),
          y: loadingData.net_loading,
        };
      })
    : [];

  const netDispatch = chartData
    ? chartData[market_type].map((loadingData) => {
        return {
          x: DateTime.fromISO(loadingData.timestamp).valueOf(),
          y: loadingData.net_dispatch,
        };
      })
    : [];

  useRequestEffect<LoadThresholdRepsonse>({
    url: `/api/dsp/program/${programID}/feeder/${rdf_id}/load_threshold`,
    method: 'get',
    onSuccess: (data) => {
      if (data) {
        setLoadThreshold(data.load_threshold);
      }
    },
    toast: {
      error: 'Could not retrieve in load threshold for feeder.',
    },
  });

  const { makeRequest: runExport, loading: downloading } = useRequest(
    `/api/measurement/program/${programID}/forecasts/USER_UPLOADED_FORECAST/export`
  );

  const downloadReport = async () => {
    const timezone = date.zoneName || '';
    await runExport({
      method: 'get',
      body: undefined,
      blockRequest: undefined,
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(
          data,
          headers,
          `${feederName}-${date.toISODate()}-${market_type}-load-forecast.csv`
        );
      },
      onError: undefined,
      toast: {
        error: 'Could not export forecast.',
        settings: {
          autoDismiss: true,
        },
      },

      // Request options
      // @ts-ignore
      params: {
        start_date: date.toISO(),
        end_date: date.plus({ days: 1 }).startOf('day').toISO(),
        market_type: market_type,
        rdf_id,
        timezone,
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const { makeRequest: runUpload, loading: saving } = useRequest(
    `/api/measurement/program/${programID}/feeder/${rdf_id}/load_forecast/USER_UPLOADED_FORECAST/market/${market_type}`
  );

  const uploadForecastData = async () => {
    await runUpload({
      method: 'post',
      body: fileData,
      dataTransform: undefined,
      blockRequest: undefined,
      onSuccess: () => {
        refetchChartData();
        cancelUpload(); // exits upload mode
      },
      onError: undefined,
      toast: {
        error: 'Could not upload forecast data.',
        success: 'Successfully uploaded forecast data.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const cancelUpload = () => {
    setInUploadMode(false);
    setFileData(new FormData());
    setFilename('');
    setHasData(false);

    if (uploadFormRef.current) {
      uploadFormRef.current.reset();
    }
  };

  const drawLinePlugin = useMemo(() => {
    return {
      id: 'line',
      beforeDatasetsDraw: (chart: Chart) => {
        const ctx = chart.ctx;
        const { left, right } = chart.chartArea;
        const yAxis = chart.scales.y;
        // @ts-expect-error
        const y = yAxis.getPixelForValue(chart.options.plugins.line.lineValue);

        ctx.save();

        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(left, y);
        ctx.lineTo(right, y);
        ctx.stroke();

        ctx.fillStyle = 'black';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'bottom';
        ctx.fillText('Feeder Threshold', right, y);
        ctx.restore();
      },
    };
  }, []);

  return (
    <div className="load-contribution">
      <div className="load-contribution__header">
        <div className="load-contribution__market-selector">
          <Select
            isClearable={false}
            isMulti={false}
            onChange={(opt) => setMarketType(opt.value)}
            options={MARKET_TYPES}
            value={
              market_type === 'dayahead' ? MARKET_TYPES[0] : MARKET_TYPES[1]
            }
          />
        </div>
        <div className="load-contribution__date-picker">
          <OldDatePicker date={date} onClose={setDate} useUTC={false} />
        </div>
        <div className="load-contribution__right">
          <IconButton
            icon={inUploadMode ? 'close' : 'create'}
            onClick={() => {
              setInUploadMode(!inUploadMode);
              if (inUploadMode) {
                // We are exiting upload mode
                cancelUpload();
              }
            }}
            tooltip={inUploadMode ? 'Close' : 'Upload Forecast Data'}
          />
          <IconButton
            disabled={downloading}
            icon="get_app"
            onClick={() => downloadReport()}
            tooltip="Download Forecast"
          />
        </div>
      </div>
      {!loadingChartData && !inUploadMode && (
        <div className="load-contribution__charts">
          <ChartWrapper
            config={{
              type: 'line',
              data: {
                datasets: [
                  {
                    data: totalDemand,
                    label: `Total Demand (MW)`,
                    stepped: 'before',
                    backgroundColor: '#00467F',
                    borderColor: '#00467F',
                    fill: false,
                  },
                  {
                    data: netLoading,
                    label: `Net Loading (MW)`,
                    stepped: 'before',
                    backgroundColor: '#7f0046',
                    borderColor: '#7f0046',
                    fill: false,
                  },
                  {
                    data: netDispatch,
                    label: `Total Dispatch (MW)`,
                    backgroundColor: '#e14837',
                    borderColor: '#e14837',
                    type: 'bar',
                  },
                ],
                labels: [],
              },
              options: {
                maintainAspectRatio: false,
                plugins: {
                  // @ts-expect-error Ignore because this is extra data for line plugin
                  line: {
                    lineValue: loadThreshold,
                  },
                  tooltip: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x',
                    callbacks: {
                      title: (tooltipItems) => {
                        let title = '';
                        if (tooltipItems.length > 0) {
                          const item = tooltipItems[0];
                          const dateTime = DateTime.fromMillis(
                            item.parsed.x
                          ).setZone(timezone);
                          title = dateTime.toFormat('DDD hh:mm:ss a ZZ');
                        }

                        return title;
                      },
                      label: (tooltipItem) => {
                        const { parsed, label } = tooltipItem;
                        let value = parsed.y;
                        value = Math.round(value * 100) / 100 / 1e6;
                        return `${label}: ${value.toLocaleString()} MW`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    adapters: {
                      date: {
                        zone: timezone,
                      },
                    },
                    min: date.startOf('day').setZone(timezone).valueOf(),
                    max: date
                      .startOf('day')
                      .setZone(timezone)
                      .plus({ days: 1 })
                      .valueOf(),
                    type: 'time',
                    offset: true,
                    title: {
                      display: true,
                      text: `Time (${timezone})`,
                    },
                    ticks: {
                      major: {
                        enabled: true,
                      },
                      source: 'auto',
                      autoSkip: true,
                      autoSkipPadding: 75,
                      maxRotation: 0,
                      sampleSize: 100,
                    },
                  },
                  y: {
                    suggestedMin: 0,
                    suggestedMax: Math.max(1e6, loadThreshold || 0),
                    grid: {
                      drawBorder: false,
                    },
                    title: {
                      display: true,
                      text: 'Forecast (MW)',
                    },
                    ticks: {
                      // @ts-expect-error
                      callback: (value: number) =>
                        `${(value / 1e6).toFixed(2)}`,
                    },
                  },
                },
              },
              plugins: [drawLinePlugin],
            }}
          />
        </div>
      )}
      <Modal
        active={inUploadMode}
        hideClose
        cancelProps={{ disabled: saving }}
        confirmProps={{
          disabled: !hasData || saving,
          onClick: uploadForecastData,
          label: saving ? (
            <i className="material-icons load-contribution__rotating-icon">
              refresh
            </i>
          ) : (
            'Save'
          ),
        }}
        onClose={cancelUpload}
        height="250px"
        title="Upload Forecast Data"
      >
        <div className="load-contribution__upload-form">
          <FileForm
            accept="*.csv"
            createRef={(form) => (uploadFormRef.current = form)}
            id="upload"
            onChange={(e) => {
              //@ts-ignore
              const { files } = e.target;

              // If there not exactly one files attached, do nothing
              if (files.length !== 1) return;

              // Only 1 file should exist / only the first file matters
              const file = files[0];

              // Assume .csv file is valid, allow API to reject
              const csvKey = 'load_forecast_csv';
              fileData.set(csvKey, file);
              setFilename(file.name);
              setHasData(true);
            }}
          >
            <Button
              customClasses={{
                customButtonClass: 'button--non-interactive',
              }}
            >
              Select file
            </Button>
          </FileForm>
          <div className="filename">{filename}</div>
        </div>
      </Modal>
    </div>
  );
};

export default LoadContribution;
