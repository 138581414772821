import React from 'react';

import { StepperStep } from './index';

import Step from './Step';

import './Stepper.scss';

type StepperProps = {
  steps: StepperStep[];
  id: string;
  direction?: 'horizontal' | 'vertical';
  onStepClick?: (step: number) => void;
  classNames?: {
    stepperClass?: string;
    stepClass?: string;
    wrapperClass?: string;
    iconClass?: string;
    circleClass?: string;
    numberClass?: string;
    labelClass?: string;
    separatorClass?: string;
  };
  styles?: {
    stepperStyles?: React.CSSProperties;
    stepStyle?: React.CSSProperties;
    wrapperStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    circleStyle?: React.CSSProperties;
    numberStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    separatorStyle?: React.CSSProperties;
  };
};

const Stepper = ({
  steps,
  id,
  direction = 'horizontal',
  onStepClick,
  styles = {},
  classNames = {},
  children,
}: React.PropsWithChildren<StepperProps>) => {
  const totalSteps = steps.length;
  const {
    stepperStyles,
    stepStyle,
    wrapperStyle,
    iconStyle,
    circleStyle,
    numberStyle,
    labelStyle,
    separatorStyle,
  } = styles;
  const {
    stepperClass,
    stepClass,
    wrapperClass,
    iconClass,
    circleClass,
    numberClass,
    labelClass,
    separatorClass,
  } = classNames;

  return (
    <>
      <div
        className={`stepper--${direction} ${stepperClass}`}
        style={stepperStyles}
      >
        {steps.map((step, index) => {
          const {
            label,
            active,
            completed,
            onStepClickOverride,
            stylesOverrides = {},
            classNameOverrides = {},
          } = step;
          const {
            stepOverrideClass,
            wrapperOverrideClass,
            iconOverrideClass,
            circleOverrideClass,
            numberOverrideClass,
            labelOverrideClass,
            seperatorOverrideClass,
          } = classNameOverrides;
          const {
            stepOverrideStyle,
            wrapperOverrideStyle,
            iconOverrideStyle,
            circleOverrideStyle,
            numberOverrideStyle,
            labelOverrideStyle,
            seperatorOverrideStyle,
          } = stylesOverrides;
          const stepClassNames = {
            stepClass: stepOverrideClass ? stepOverrideClass : stepClass,
            wrapperClass: wrapperOverrideClass
              ? wrapperOverrideClass
              : wrapperClass,
            iconClass: iconOverrideClass ? iconOverrideClass : iconClass,
            circleClass: circleOverrideClass
              ? circleOverrideClass
              : circleClass,
            numberClass: numberOverrideClass
              ? numberOverrideClass
              : numberClass,
            labelClass: labelOverrideClass ? labelOverrideClass : labelClass,
            separatorClass: seperatorOverrideClass
              ? seperatorOverrideClass
              : separatorClass,
          };
          const stepStyles = {
            stepStyle: stepOverrideStyle ? stepOverrideStyle : stepStyle,
            wrapperStyle: wrapperOverrideStyle
              ? wrapperOverrideStyle
              : wrapperStyle,
            iconStyle: iconOverrideStyle ? iconOverrideStyle : iconStyle,
            circleStyle: circleOverrideStyle
              ? circleOverrideStyle
              : circleStyle,
            numberStyle: numberOverrideStyle
              ? numberOverrideStyle
              : numberStyle,
            labelStyle: labelOverrideStyle ? labelOverrideStyle : labelStyle,
            separatorStyle: seperatorOverrideStyle
              ? seperatorOverrideStyle
              : separatorStyle,
          };
          return (
            <Step
              key={`${id}-${label}-step-${index}`}
              step={index}
              numSteps={totalSteps}
              label={label}
              active={active || false}
              completed={completed || false}
              onClick={onStepClickOverride || onStepClick}
              direction={direction}
              classNames={stepClassNames}
              styles={stepStyles}
            />
          );
        })}
      </div>
      {children}
    </>
  );
};

export default Stepper;
