import React from 'react';
import { default as MUIAccordionSummary } from '@mui/material/AccordionSummary';

import { AccordionListSummary } from './interface';

export const AccordionSummary = ({
  expandIcon,
  sx,
  children,
  classNames,
  AccordionSummaryProps,
}: AccordionListSummary) => {
  return (
    <MUIAccordionSummary
      expandIcon={expandIcon}
      sx={sx}
      classes={{
        root: classNames?.root,
        content: classNames?.content,
      }}
      className={AccordionSummaryProps?.className}
    >
      {children}
    </MUIAccordionSummary>
  );
};
