import React from 'react';
import classNames from 'classnames';
import { default as MUIMenuItem } from '@mui/material/MenuItem';

import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';

import { MenuItemProps } from './MenuItem.types';
import BranchItem from '../BranchItem';

import './MenuItem.scss';

const MenuItem = ({
  children,
  autoFocus,
  dense,
  disableGutters,
  divider,
  onClick,
  onChange,
  disabled,
  selected = false,
  type = 'default',
  items = [],
  tooltip,
  icon,
  endAdornment,
  classes = {},
  customStyles,
}: MenuItemProps) => {
  const { rootClass } = classes;

  return (
    <Tooltip content={tooltip ? <>{tooltip}</> : ''}>
      <MUIMenuItem
        disabled={disabled}
        onClick={onClick}
        onChange={type !== 'checkbox' ? onChange : undefined}
        autoFocus={autoFocus}
        dense={dense}
        disableGutters={disableGutters}
        divider={divider}
        classes={{
          root: classNames('menu-item', rootClass, {
            'menu-item--selected': type !== 'checkbox' && selected,
            'menu-item--checkbox': type === 'checkbox',
          }),
        }}
        style={customStyles}
      >
        <div className="menu-item__wrapper">
          {icon && <span className="menu-item__icon">{icon}</span>}
          {type === 'default' && children}
          {type === 'checkbox' && (
            <Checkbox
              checked={selected}
              onChange={onChange}
              label={children as any}
              size="small"
              customStyles={{
                pointerEvents: 'none',
              }}
            />
          )}
          {type === 'branch' && (
            <BranchItem items={items}>{children}</BranchItem>
          )}
        </div>
        <span>{endAdornment}</span>
      </MUIMenuItem>
    </Tooltip>
  );
};

export default MenuItem;
