import React from 'react';
import classnames from 'classnames';

import './PageTitle.scss';

type Props = {
  title: string;
  classes?: {
    wrapperClass?: string;
    titleClass?: string;
    actionsClass?: string;
  };
};

const PageTitle: React.FC<Props> = ({ title, classes, children }) => (
  <div className={classnames('page-title', classes?.wrapperClass)}>
    <h1 className={classnames('page-title__title', classes?.titleClass)}>
      {title}
    </h1>
    <div className={classnames('page-title__actions', classes?.actionsClass)}>
      {children}
    </div>
  </div>
);

export default PageTitle;
