import React from 'react';
import { useParams } from 'react-router';

import NavButtons from 'components/NavButtons';
import PageTitle from 'components/PageTitle';

import './ContractsTableHeader.scss';

const contractRoutes = [
  {
    path: 'peer-to-network',
    label: 'Peer to network',
  },
  {
    path: 'peer-to-peer',
    label: 'Peer to peer',
  },
];

const ContractsTableHeader: React.FC = () => {
  const { programID } = useParams<{ programID: string }>();

  return (
    <div className="contracts-table-header__header">
      <PageTitle title="Contracts" />
      <NavButtons
        baseRoute={`/program/${programID}/long-term-contracts`}
        routes={contractRoutes}
        classNames={{
          navClass: 'contracts-table-header__nav',
        }}
      />
    </div>
  );
};

export default ContractsTableHeader;
